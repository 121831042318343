import React from 'react';
import { Grid } from '@mui/material';
import NSRSList from './components/NSRSList';
import NSRSStartModule from './components/NSRSStartModule';

export default function NSRS() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <NSRSStartModule />
            </Grid>
            <Grid item xs={12}>
                <NSRSList />
            </Grid>
        </Grid>
    );
}
