import { mailUrl } from '../../helpers/globalValues';

const requestAccessMail = data => {
    return `
  <!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="x-apple-disable-message-reformatting">
  <meta http-equiv="Content-Type" content="text/html charset=UTF-8">
  <title>Welcome</title>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
  <link
    href="https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&family=Open+Sans:wght@300;500&family=Yanone+Kaffeesatz:wght@300&display=swap"
    rel="stylesheet">
  <style>
    @media only screen and (max-width: 450px) {
      .logo {
        width: 40%;
      }
    }
  </style>
</head>

<body style="margin:0 20px;padding:20px;background-color: #f4f4f4;">
  <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#f4f4f4;">
    <tr>
      <td align="center" style="padding:0;">
        <table role="presentation" style="width:100%;border-collapse:collapse;text-align:left;">
          <tr>
            <td align="center" style="padding:15px;background:transparent">
              <img src="https://ecofric-images.s3.eu-west-1.amazonaws.com/logo.png" alt="Black Ecofric logo" style="width: 15%;
                border: 0;">
            </td>
          </tr>
          <tr>
            <td style="padding: 40px;
            background-color: #ffffff;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            margin: 20px;">
              <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                <tr>
                  <td>
                    <h1 style="font-family: 'Yanone Kaffeesatz', sans-serif;
                    text-align: center;">Hei! </h1>
                    <p style="font-family: 'Open Sans', sans-serif, Arial, Helvetica, sans-serif;
                    font-weight: 100;
                    font-size: 16px;
                    text-align: center;
                    margin: 0;
                    -webkit-text-size-adjust: none;
                    -ms-text-size-adjust: none;
                    line-height: 24px;
                    color: #333333;">
                      ${data.userEmail} har bedt om tilgang til ${
        data.orgName
    }, Brukeren har bekreftet sin e-post hos Ecofric. <br />
                      Som administrator kan du enten godkjenne eller avslå forespørselen fra brukeren <a
                      style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;text-decoration:underline;color:#000000;font-size:16px"
                      href=${mailUrl + 'profile'}>her</a>.
                    </p>
                    <br><br>
                    <p style="font-family: 'Open Sans', sans-serif, Arial, Helvetica, sans-serif;
                    font-weight: 100;
                    font-size: 16px;
                    text-align: center;
                    margin: 0;
                    -webkit-text-size-adjust: none;
                    -ms-text-size-adjust: none;
                    line-height: 24px;
                    color: #333333;">
                      Bærekraftig hilsen fra Ecofric 🌱
                    </p>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <a align="center" href="${mailUrl}profile" target="_blank" style="margin:auto;
                    margin-top: 30px;
                    width: 20%;
                    background-color: #F3CCD1;
                    padding: 20px;
                    border-radius: 10px;
                    text-align:center;
                    text-decoration: underline;
                    font-family: 'Open Sans Condensed', sans-serif, Arial, Helvetica, sans-serif;
                    font-weight: 200;
                    color: #333333;
                    display: block;">Godkjenn eller avslå forespørsel</a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <table cellpadding="0" cellspacing="0" align="center"
            style="border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top"
            role="presentation">
            <tr>
              <td align="center" style="padding:0;Margin:0">
                <table align="center" cellpadding="0" cellspacing="0"
                  style="border-collapse:collapse;border-spacing:0px;background-color:transparent;width:100%"
                  role="presentation">
                  <tr>
                    <td align="left"
                      style="Margin:0;padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px">
                      <table cellpadding="0" cellspacing="0" width="100%"
                        style="border-collapse:collapse;border-spacing:0px" role="presentation">
                        <tr>
                          <td align="left" style="padding:0;Margin:0;width: 100%">
                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                              style="border-collapse:collapse;border-spacing:0px">
                              <tr>
                                <td align="center" style="padding:0;Margin:0;padding-bottom:35px">
                                  <p
                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:18px;color:#333333;font-size:12px">
                                    Ecofric AS © 2021. All Rights Reserved.</p>
                                  <p
                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:18px;color:#333333;font-size:12px">
                                    Åsveien 2, 3475 Sætre. <br>Kontakt: <a href="mailto:hello@ecofric.com"
                                      style=" text-decoration: underline;color: #000000;">hello@ecofric.com</a></p>
                                </td>
                              </tr>
                              <tr>
                                <td style="padding:0;Margin:0">
                                  <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                    style="border-collapse:collapse;border-spacing:0px">
                                    <tr>
                                      <td align="center" valign="top" width="33.33%"
                                        style="Margin:0;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;border:0">
                                        <a target="_blank" href="https://www.ecofric.com/avtalevilkar/"
                                          style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;display:block;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#333333;font-size:12px; text-decoration: underline">Avtalevilkår</a>
                                      </td>
                                      <td align="center" valign="top" width="33.33%"
                                        style="Margin:0;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;border:0;border-left:1px solid #cccccc">
                                        <a target="_blank" href="https://www.ecofric.com/databehandleravtale/"
                                          style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;display:block;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#333333;font-size:12px; text-decoration: underline">Databehandleravtale</a>
                                      </td>
                                      <td align="center" valign="top" width="33.33%"
                                        style="Margin:0;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;border:0;border-left:1px solid #cccccc">
                                        <a target="_blank" href="https://www.ecofric.com/personvern/"
                                          style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;display:block;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#333333;font-size:12px; text-decoration: underline">Personvernerklæring</a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </table>
      </td>
    </tr>
  </table>
</body>

</html>
    `;
};

export default requestAccessMail;
