import React from "react";
import { Grid, Paper, Typography, Button } from "@mui/material";
import { useStyles } from "../ClimateStyles";
import { Climate, ArrowRight } from "../../../../components/Icons";
import useRouter from "../../../../hooks/useRouter";
import * as Text from "../../../../helpers/climateAccounting.json";

export default function StartModule(props) {
  const classes = useStyles();
  const router = useRouter();

  const sbg = "#FEF0D9";

  return (
    <Paper className={classes.modulePaper}>
      <Typography variant="h3" sx={{ backgroundColor: sbg }}>
        {Climate} Nøkkelinformasjon
      </Typography>
      <Grid item style={{ padding: "20px 20px 0px 20px" }}>
        <Typography variant="body1">{Text.KeyInformation.Short}</Typography>
        <br />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: sbg,
          justifyContent: "flex-end !important",
        }}
        className={classes.modulesFooter}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#fff",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#FDDFB0",
            },
          }}
          onClick={() =>
            router.push({
              pathname: "/climate/" + props.report.id + "/information",
              state: {
                report: props.report,
              },
            })
          }
        >
          Fortsett {ArrowRight}
        </Button>
      </Grid>
    </Paper>
  );
}
