import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    InputBase,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Checkbox,
    Button,
    Box,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SnackPopup from '../../../components/SnackPopup.jsx';
import { Loading } from '../../../components/Loading';
import { Mail, Key, Phone, User } from '../../../components/Icons.jsx';
import { useAuth } from '../../../auth/useAuth';
import useRouter from '../../../hooks/useRouter';
import useToggle from '../../../hooks/useToggle';
import { useStyles } from '../Register/RegisterStyle';
import { useHistory } from 'react-router';
import { theme } from '../PublicTheme.jsx';
import { ThemeProvider } from '@emotion/react';

export default function Register() {
    const classes = useStyles();
    const history = useHistory();
    const router = useRouter();
    const auth = useAuth();

    const [isLoading, setIsLoading] = useToggle();
    const [name, setName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNr, setPhoneNr] = useState('');
    const [tenantId, setTenantId] = useState('none');
    const [passwordValues, setPasswordValues] = useState({
        password: '',
        showPassword: false,
    });
    const [state, setState] = useState({
        checked: false,
    });

    const [popupState, setPopupState] = useState(false);
    const [popupMsg, setPopupMsg] = useState('');

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (router.query && router.query.invite === 'true') {
                if (router.query.email) {
                    setEmail(router.query.email);
                }

                if (router.query.id) {
                    setTenantId(router.query.id);
                }
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState(true);
    };

    const handleClosePopup = () => {
        setPopupState(false);
    };

    const handleClickShowPassword = () => {
        setPasswordValues({
            ...passwordValues,
            showPassword: !passwordValues.showPassword,
        });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const handleCheckChange = event => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleSignUp = event => {
        event.preventDefault();

        if (!state.checked) {
            showPopup('Du må godkjenne vilkårene for å fortsette');
            return;
        } else if (phoneNr.length !== 8) {
            showPopup('Mobilenummeret skal bestå av 8 tall og uten landskode.');
            return;
        }

        setIsLoading();

        auth.signUp(name, familyName, email, phoneNr, passwordValues.password, tenantId)
            .then(() => {
                router.push('/confirm');
            })
            .catch(err => {
                errorHandling(err.code);

                setIsLoading();
            });
    };

    // Error handling for signup
    const errorHandling = errCode => {
        if (errCode === 'InvalidPasswordException') {
            // Invalid password
            showPopup('Passordet må inneholde minst 8 tegn.');
        } else if (errCode === 'InvalidPhoneNumber' || errCode === 'InvalidParameterException') {
            // Invalid phone number
            showPopup('Mobilenummeret skal bestå av 8 tall og uten landskode.');
        } else if (errCode === 'AliasExistsException' || errCode === 'UsernameExistsException') {
            // A user with the same email already exists
            showPopup('Denne e-posten er brukt fra før. Trykk glemt passord for å resette passordet.');
        } else if (errCode === 'NotAuthorizedException' || errCode === 'NoUser') {
            // Random error or unauthorized
            showPopup('En ukjent feil har skjedd. Vennligst prøv på nytt. Kontakt support om du ikke kommer videre.');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.box} sx={{ backgroundColor: '#CEBEDD' }}>
                <Button variant="contained" disabled className={classes.topButton}>
                    Ny bruker
                </Button>
                <Grid item xs={12} className={classes.content}>
                    <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
                    <Typography variant="h1">Opprett en ny bruker</Typography>
                    <form autoComplete="on">
                        <InputBase
                            required
                            error
                            type="text"
                            name="firstName"
                            placeholder="Fornavn..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                            startAdornment={<InputAdornment position="start">{User}</InputAdornment>}
                        />
                        <InputBase
                            required
                            error
                            type="text"
                            name="lastName"
                            placeholder="Etternavn..."
                            value={familyName}
                            onChange={e => setFamilyName(e.target.value)}
                            startAdornment={<InputAdornment position="start">{User}</InputAdornment>}
                        />
                        <InputBase
                            required
                            error
                            type="email"
                            name="email"
                            placeholder="Epost..."
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            startAdornment={<InputAdornment position="start">{Mail}</InputAdornment>}
                        />
                        <InputBase
                            required
                            error
                            type="tel"
                            name="phoneNumber"
                            placeholder="Telefonnr..."
                            value={phoneNr}
                            onChange={e => setPhoneNr(e.target.value)}
                            startAdornment={<InputAdornment position="start">{Phone}</InputAdornment>}
                        />
                        <Grid item xs={12}>
                            <InputBase
                                required
                                error
                                id="password"
                                name="password"
                                type={passwordValues.showPassword ? 'text' : 'password'}
                                placeholder="Passord..."
                                value={passwordValues.password}
                                onChange={e =>
                                    setPasswordValues({
                                        ...passwordValues,
                                        password: e.target.value,
                                    })
                                }
                                startAdornment={<InputAdornment position="start">{Key}</InputAdornment>}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {passwordValues.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <Typography variant="body2">
                                <i>Passord: Minimum 8 tegn!</i>
                            </Typography>
                            <Grid item sx={{ margin: '10px 0px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            required
                                            checked={state.checked}
                                            onChange={handleCheckChange}
                                            name="checked"
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            Jeg godkjenner{' '}
                                            <a
                                                href="https://www.ecofric.com/databehandleravtale/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="a"
                                            >
                                                databehandleravtalen
                                            </a>
                                            ,{' '}
                                            <a
                                                href="https://www.ecofric.com/personvern/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="a"
                                            >
                                                personvernerklæringen
                                            </a>{' '}
                                            og{' '}
                                            <a
                                                href="https://www.ecofric.com/avtalevilkar/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="a"
                                            >
                                                avtalevilkårene
                                            </a>
                                            .
                                        </Typography>
                                    }
                                />
                            </Grid>
                            {isLoading ? (
                                <Loading color="primary" />
                            ) : (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={classes.signUp}
                                    onClick={e => handleSignUp(e)}
                                >
                                    Neste
                                </Button>
                            )}
                        </Grid>
                    </form>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            padding: '20px',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={() => router.push('/login')}
                            sx={{
                                marginBottom: '10px',
                                textDecoration: 'none',
                                fontSize: '14px',
                            }}
                        >
                            Allerede kunde?
                        </Button>
                        <Button className={classes.forwardLink} onClick={() => history.push('/login')}>
                            Logg inn på bærekraftsprotalen her!
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}
