import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Comment from './Comment';
import FileComment from './FileComment';
import { useAuth } from '../../../../../auth/useAuth';

import { API } from 'aws-amplify';
import { onTodoTaskCommentByCompanyIdAndTaskId } from '../../../../../graphql/subscriptions';

export default function Comments(props) {
    const auth = useAuth();

    useEffect(() => {
        let isMounted = false;
        let subscription;

        if (!isMounted) {
            // Subscribe to updates
            subscription = API.graphql({
                query: onTodoTaskCommentByCompanyIdAndTaskId,
                variables: {
                    companyId: auth.companyData.companyId,
                    todoTaskId: props.task.id,
                },
            }).subscribe({
                next: ({ provider, value }) => {
                    props.updateTask();
                },
                error: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(error);
                    }
                },
            });
        }

        return () => {
            isMounted = true;
            subscription.unsubscribe();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid item xs={12}>
            {props.task.comments.items &&
                props.task.comments.items
                    .sort((a, b) => {
                        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                    })
                    .map(comment => {
                        if (comment.type === 'COMMENT') {
                            return (
                                <Comment
                                    key={comment.id}
                                    comment={comment}
                                    updateTask={props.updateTask}
                                    users={props.users}
                                />
                            );
                        } else if (comment.type === 'FILE') {
                            return (
                                <FileComment
                                    key={comment.id}
                                    comment={comment}
                                    updateTask={props.updateTask}
                                    users={props.users}
                                />
                            );
                        } else {
                            return (
                                <Comment
                                    key={comment.id}
                                    comment={comment}
                                    updateTask={props.updateTask}
                                    users={props.users}
                                />
                            );
                        }
                    })}
        </Grid>
    );
}
