import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Avatar,
  Chip,
  Button,
  Pagination,
  Typography,
} from "@mui/material";
import { ArrowRight } from "../../../../components/Icons";
import { NavLink } from "react-router-dom";
import { useStyles } from "../IndexStyles";
import { SiteLoading } from "../../../../components/Loading";
import getDateFromTimestamp from "../../../../helpers/getDateFromTimestamp";
import SearchEngine from "./SearchEngine";
import { getBadgeFromTotalEarnedPoints } from "../../../../helpers/badgeFunctions";

import { API } from "aws-amplify";
import { listCompanyCertPublicProfiles } from "../../../../graphql/queries";

export default function Index() {
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      fetchAllPublicProfiles().then((items) => {
        setList(items);
        setData(items);
      });
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Publicly fetch all public profiles
  const fetchAllPublicProfiles = () => {
    return new Promise(async (resolve) => {
      await API.graphql({
        query: listCompanyCertPublicProfiles,
        variables: {
          filter: { status: { eq: "Completed" }, isVisible: { eq: true } },
        },
        authMode: "AWS_IAM",
      }).then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Fetched all visible public profiles: ", data);
        }

        resolve(data.data.listCompanyCertPublicProfiles.items);
      });
    });
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);

    let updatedList = data;

    setList([
      ...updatedList.filter((profile) => {
        return profile.companyName
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      }),
    ]);
  };

  const badgeGiven = (profile) => {
    if (getBadgeFromTotalEarnedPoints(profile.totalPercentScore) === "bronze") {
      return (
        <Avatar
          sx={{
            backgroundColor: "#E5C0A6",
            color: "#000",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          B
        </Avatar>
      );
    } else if (
      getBadgeFromTotalEarnedPoints(profile.totalPercentScore) === "silver"
    ) {
      return (
        <Avatar
          sx={{
            backgroundColor: "#DBDBDB",
            color: "#000",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          S
        </Avatar>
      );
    } else if (
      getBadgeFromTotalEarnedPoints(profile.totalPercentScore) === "gold"
    ) {
      return (
        <Avatar
          sx={{
            backgroundColor: "#F7D898",
            color: "#000",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          G
        </Avatar>
      );
    } else {
      return <Grid>N/A</Grid>;
    }
  };

  if (!data) {
    return <SiteLoading />;
  } else {
    return (
      <Grid>
        <SearchEngine handleSearch={handleSearch} input={searchInput} />
        <Paper sx={{ borderRadius: "0px 0px 5px 5px" }}>
          <Grid item xs={12} sx={{ padding: "50px" }}>
            {data.length !== 0 ? (
              <TableContainer>
                <Table>
                  <TableHead sx={{ borderBottom: "7px solid #CEBEDD" }}>
                    <TableRow>
                      <TableCell sx={{ width: "5%" }} align="center">
                        Valørmerke
                      </TableCell>
                      <TableCell sx={{ width: "20%" }}>Organisasjon</TableCell>
                      <TableCell sx={{ width: "20%" }}>Gyldig til</TableCell>
                      <TableCell sx={{ width: "22%" }}>ESG Score</TableCell>
                      <TableCell sx={{ width: "5%" }} align="center">
                        Totalt
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list &&
                      list
                        .sort((a, b) => {
                          return b.totalPercentScore - a.totalPercentScore;
                        })
                        .map((profile) => {
                          return (
                            <TableRow key={profile.id}>
                              <TableCell align="center">
                                {badgeGiven(profile)}
                              </TableCell>
                              <TableCell>{profile.companyName}</TableCell>
                              <TableCell>
                                {getDateFromTimestamp(profile.expDate)}
                              </TableCell>
                              <TableCell>
                                <Chip
                                  label={
                                    "E: " + profile.economyPercentScore + "%"
                                  }
                                  sx={{ backgroundColor: "#E1F4E8" }}
                                ></Chip>{" "}
                                <Chip
                                  label={
                                    "S: " + profile.socialPercentScore + "%"
                                  }
                                  sx={{ backgroundColor: "#F9E5E8" }}
                                ></Chip>{" "}
                                <Chip
                                  label={
                                    "G: " +
                                    profile.environmentalPercentScore +
                                    "%"
                                  }
                                  sx={{ backgroundColor: "#DDE5F2" }}
                                ></Chip>
                              </TableCell>
                              <TableCell align="center">
                                <b>{profile.totalPercentScore + "%"}</b>
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  variant="contained"
                                  sx={{ backgroundColor: "#CEBEDD" }}
                                  component={NavLink}
                                  to={{
                                    pathname: "/index/" + profile.id,
                                    data: profile,
                                  }}
                                  exact
                                >
                                  Se detaljer{ArrowRight}
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h2" sx={{ textAlign: "center" }}>
                Det er ingen offentlige sertifiserte akkurat nå!
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className={classes.paginationContent}>
            <Pagination
              count={1}
              shape="rounded"
              size="large"
              color="primary"
            />
          </Grid>
        </Paper>
      </Grid>
    );
  }
}
