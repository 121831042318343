import React, { Fragment, useState, useEffect } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
  Svg,
  Line,
} from "@react-pdf/renderer";
import Start from "../../../../assets/images/start.png";
import Bronze from "../../../../assets/images/Bronze.png";
import Silver from "../../../../assets/images/Silver.png";
import Gold from "../../../../assets/images/Gold.png";
import Ellipse1 from "../../../../assets/images/Ellipse1.png";
import Ellipse2 from "../../../../assets/images/Ellipse2.png";
import Ellipse3 from "../../../../assets/images/Ellipse3.png";
import { getBadgeFromTotalEarnedPoints } from "../../../../helpers/badgeFunctions";
import OpenSansTTF from "../../../../assets/fonts/OpenSans.ttf";
import OpenSansBold from "../../../../assets/fonts/OpenSansCondensed-Bold.ttf";
import Leaf from "../../../../assets/images/leaves_vb.png";
import RadioChecked from "../../../../assets/images/radiochecked.png";

Font.register({
  family: "Yanone",
  src:
    "https://fonts.gstatic.com/s/yanonekaffeesatz/v7/We_iSDqttE3etzfdfhuPRbq92v6XxU4pSv06GI0NsGc.ttf",
});
/* Font.register({
  family: "OpenSans",
  src:
    "http://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTS3USBnSvpkopQaUR-2r7iU.ttf",
}); */
Font.register({
  family: "OpenSans",
  format: "truetype",
  src: OpenSansTTF,
});
Font.register({
  family: "OpenSansCondensed",
  src:
    "https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xEMwSSh38KQVJx4ABtsZTnA.ttf",
});
Font.register({
  family: "OpenSansCondensedItalic",
  src:
    "https://fonts.gstatic.com/s/opensanscondensed/v10/jIXlqT1WKafUSwj6s9AzV4_LkTZ_uhAwfmGJ084hlvM.ttf",
});
/* Font.register({
  family: "OpenSansCondensedBold",
  src:
    "http://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xBEM87DM3yorPOrvA-vB930.ttf",
}); */
Font.register({
  family: "OpenSansCondensedBold",
  format: "truetype",
  src: OpenSansBold,
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    flexGrow: 1,
    padding: "20px",
    borderRight: "40px solid #F5F5F5",
    borderLeft: "40px solid #F5F5F5",
    position: "relative",
  },
  page2: {
    backgroundColor: "#F5F5F5",
  },
  section: {
    backgroundColor: "#FFFFFF",
    flexGrow: 1,
    marginRight: 40,
    marginLeft: 40,
    padding: "20px",
  },
  h1: {
    fontFamily: "Yanone",
    textAlign: "center",
    margin: "60px 0px 0px 48px",
    fontSize: 40,
    borderBottom: "5px solid #FDDFB0",
    fontWeight: 400,
    width: 400,
  },
  h2: {
    fontSize: "20px",
    textAlign: "left",
    fontFamily: "Yanone",
    borderBottom: "3px solid #FDDFB0",
    margin: "10px 0px",
  },
  h3: {
    fontSize: "16px",
    textAlign: "left",
    fontFamily: "Yanone",
    margin: "10px 0px 5px 0px",
    paddingBottom: "5px",
  },
  p: {
    fontFamily: "OpenSans",
    fontSize: "10px",
    padding: "0px 0px 0px 0px",
    lineHeight: "1.8px",
  },
  pageNb: {
    fontFamily: "OpenSansCondensed",
    fontSize: "12px",
    padding: "10px 50px 0px 0px",
  },
  italic: {
    fontFamily: "OpenSansCondensedItalic",
    fontSize: "11px",
    padding: "5px 0px 0px 0px",
  },
  bold: {
    fontFamily: "OpenSansCondensedBold",
    fontSize: "11px",
    padding: "5px 0px 0px 0px",
  },
  tableHead: {
    diplay: "flex",
    flexDirection: "row",
    flexGrow: 1,
    borderBottom: "1px solid #EBEBEB",
  },
  headCell: {
    fontFamily: "OpenSansCondensedBold",
    fontSize: "11px",
    padding: "0px 5px",
  },
  tableBody: {
    diplay: "flex",
    flexDirection: "row",
    flexGrow: 1,
    borderBottom: "1px solid #EBEBEB",
  },
  resultBody: {
    diplay: "flex",
    flexDirection: "row",
    flexGrow: 1,
    borderBottom: "1px solid #EBEBEB",
    borderTop: "1px solid #EBEBEB",
  },
  tableBody2: {
    diplay: "flex",
    flexDirection: "row",
    flexGrow: 1,
    borderBottom: "1px solid #EBEBEB",
    backgroundColor: "#F3F3F3",
  },
  summary: {
    diplay: "flex",
    flexDirection: "row",
    flexGrow: 1,
    borderBottom: "1px solid #EBEBEB",
    borderTop: "3px solid #EBEBEB",
  },
  infoCell: {
    fontFamily: "OpenSansCondensed",
    fontSize: "11px",
    padding: "10px 5px",
  },
  infoCell2: {
    fontFamily: "OpenSansCondensed",
    fontSize: "11px",
    padding: "10px 5px",
    borderLeft: "1px solid #EBEBEB",
  },
  bodyCell: {
    fontFamily: "OpenSansCondensed",
    fontSize: "11px",
    padding: "5px 5px",
  },
  bodyCell2: {
    fontFamily: "OpenSansCondensed",
    fontSize: "11px",
    padding: "5px 5px",
    borderLeft: "1px solid #EBEBEB",
  },
  resultCell: {
    fontFamily: "OpenSansCondensed",
    fontSize: "11px",
    padding: "15px 5px",
  },
  sumCell: {
    fontFamily: "OpenSansCondensedBold",
    fontSize: "11px",
    padding: "10px 5px",
    borderBottom: "1px solid #EBEBEB",
    marginBottom: "2px",
  },
  sumCell2: {
    fontFamily: "OpenSansCondensedBold",
    fontSize: "11px",
    padding: "10px 5px",
    borderLeft: "1px solid #EBEBEB",
    borderBottom: "1px solid #EBEBEB",
    marginBottom: "2px",
  },
  columns: {
    display: "flex",
    flexDirection: "row",
  },
  leftColumn: {
    marginRight: "5px",
  },
  rightColumn: {
    marginLeft: "5px",
  },
  badgeColumn: {
    width: "33%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ellipse: {
    width: "20%",
  },
  toppBar: {
    height: "40px",
    backgroundColor: "#E2EFF1",
  },
  footer: {
    height: "40px",
    backgroundColor: "#E2EFF1",
    display: "flex",
    alignItems: "flex-end",
  },
});

const mapOutSubcategory = (subcategory, respData) => {
  let number = 0;

  return subcategory.questions.items
    .sort((a, b) => {
      return a.order - b.order;
    })
    .map((question) => {
      if (question.pointValue !== 0) {
        let resp = respData.find((response) => {
          return response.questionId === question.id;
        });

        if (number % 2 === 0) {
          number = number + 1;

          return (
            <View key={question.id} style={styles.tableBody} wrap={false}>
              <Text
                style={[styles.bodyCell, { width: "10%", textAlign: "center" }]}
              >
                {question.question.substring(0, 6)}
              </Text>
              <Text
                style={[styles.bodyCell2, { width: "75%", textAlign: "left" }]}
              >
                {question.question.substring(6)}
              </Text>
              <Text
                style={[
                  styles.bodyCell2,
                  { width: "15%", textAlign: "center" },
                ]}
              >
                {resp && resp !== null
                  ? resp.pointsEarned > question.pointValue
                    ? question.pointValue
                    : resp.pointsEarned
                  : 0}{" "}
                av {question.pointValue}
              </Text>
            </View>
          );
        } else {
          number = number + 1;

          return (
            <View key={question.id} style={styles.tableBody2} wrap={false}>
              <Text
                style={[styles.bodyCell, { width: "10%", textAlign: "center" }]}
              >
                {question.question.substring(0, 6)}
              </Text>
              <Text
                style={[styles.bodyCell2, { width: "75%", textAlign: "left" }]}
              >
                {question.question.substring(6)}
              </Text>
              <Text
                style={[
                  styles.bodyCell2,
                  { width: "15%", textAlign: "center" },
                ]}
              >
                {resp && resp !== null
                  ? resp.pointsEarned > question.pointValue
                    ? question.pointValue
                    : resp.pointsEarned
                  : 0}{" "}
                av {question.pointValue}
              </Text>
            </View>
          );
        }
      } else {
        return <Fragment key={question.id}></Fragment>;
      }
    });
};

const mapOutCategory = (certData, respData) => {
  return certData.subcategories.items
    .sort((a, b) => {
      return a.order - b.order;
    })
    .map((subcategory) => {
      return (
        <Fragment key={subcategory.id}>
          <Text
            style={[styles.h3, { borderBottom: "2px solid #FDDFB0" }]}
            wrap={false}
          >
            {subcategory.name}
          </Text>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <Text style={[styles.headCell, { width: "10%" }]}></Text>
              <Text
                style={[styles.headCell, { width: "75%", textAlign: "left" }]}
              >
                Spørsmål
              </Text>
              <Text
                style={[styles.headCell, { width: "15%", textAlign: "center" }]}
              >
                Poeng
              </Text>
            </View>
            {mapOutSubcategory(subcategory, respData)}
          </View>
        </Fragment>
      );
    });
};

const mapOutRespSummary = (certData, respData) => {
  let number = 0;

  let totalQuestions = 0;
  let totalQuestionsAnswered = 0;
  let totalPoints = 0;
  let totalPointsEarned = 0;

  return (
    <Fragment>
      <View style={styles.table}>
        <View style={styles.tableHead}>
          <Text style={[styles.headCell, { width: "25%", textAlign: "left" }]}>
            Kategori
          </Text>
          <Text style={[styles.headCell, { width: "60%", textAlign: "left" }]}>
            Antall spørsmål besvart
          </Text>
          <Text
            style={[styles.headCell, { width: "15%", textAlign: "center" }]}
          >
            Poeng
          </Text>
        </View>
        {certData.subcategories.items
          .sort((a, b) => {
            return a.order - b.order;
          })
          .map((subcategory) => {
            let subcatQuestionsAmount = 0;
            let subcatQuestionsAnswered = 0;

            let subcatPointsPossible = 0;
            let subcatPointsEarned = 0;

            for (const question of subcategory.questions.items) {
              if (question.pointValue !== 0) {
                subcatQuestionsAmount = subcatQuestionsAmount + 1;
                subcatPointsPossible =
                  subcatPointsPossible + question.pointValue;

                let resp = respData.find((response) => {
                  return response.questionId === question.id;
                });

                if (resp && resp !== null) {
                  if (resp.isAnswered) {
                    subcatQuestionsAnswered = subcatQuestionsAnswered + 1;

                    if (resp.pointsEarned > question.pointValue) {
                      subcatPointsEarned =
                        subcatPointsEarned + question.pointValue;
                    } else {
                      subcatPointsEarned =
                        subcatPointsEarned + resp.pointsEarned;
                    }
                  }
                }
              }
            }

            totalQuestions = totalQuestions + subcatQuestionsAmount;
            totalQuestionsAnswered =
              totalQuestionsAnswered + subcatQuestionsAnswered;
            totalPoints = totalPoints + subcatPointsPossible;
            totalPointsEarned = totalPointsEarned + subcatPointsEarned;

            if (number % 2 === 0) {
              number = number + 1;

              return (
                <View
                  style={styles.tableBody}
                  key={subcategory.id}
                  wrap={false}
                >
                  <Text
                    style={[
                      styles.bodyCell,
                      { width: "25%", textAlign: "left" },
                    ]}
                  >
                    {subcategory.name}
                  </Text>
                  <Text
                    style={[
                      styles.bodyCell2,
                      { width: "60%", textAlign: "left" },
                    ]}
                  >
                    {subcatQuestionsAnswered} av {subcatQuestionsAmount}
                  </Text>
                  <Text
                    style={[
                      styles.bodyCell2,
                      { width: "15%", textAlign: "center" },
                    ]}
                  >
                    {subcatPointsEarned} av {subcatPointsPossible}
                  </Text>
                </View>
              );
            } else {
              number = number + 1;

              return (
                <View
                  style={styles.tableBody2}
                  key={subcategory.id}
                  wrap={false}
                >
                  <Text
                    style={[
                      styles.bodyCell,
                      { width: "25%", textAlign: "left" },
                    ]}
                  >
                    {subcategory.name}
                  </Text>
                  <Text
                    style={[
                      styles.bodyCell2,
                      { width: "60%", textAlign: "left" },
                    ]}
                  >
                    {subcatQuestionsAnswered} av {subcatQuestionsAmount}
                  </Text>
                  <Text
                    style={[
                      styles.bodyCell2,
                      { width: "15%", textAlign: "center" },
                    ]}
                  >
                    {subcatPointsEarned} av {subcatPointsPossible}
                  </Text>
                </View>
              );
            }
          })}
        <View style={styles.summary}>
          <Text style={[styles.sumCell, { width: "25%", textAlign: "left" }]}>
            Total:
          </Text>
          <Text style={[styles.sumCell2, { width: "60%", textAlign: "left" }]}>
            {totalQuestionsAnswered} av {totalQuestions}
          </Text>
          <Text
            style={[styles.sumCell2, { width: "15%", textAlign: "center" }]}
          >
            {totalPointsEarned} av {totalPoints}
          </Text>
        </View>
      </View>
      <Text style={[styles.h2, { margin: "20px 0px -5px 0px" }]}>
        Kriterier
      </Text>
    </Fragment>
  );
};

const badgeText = (totalPercentScore) => {
  if (getBadgeFromTotalEarnedPoints(totalPercentScore) === "bronze") {
    return "oppnåd bronsje";
  } else if (getBadgeFromTotalEarnedPoints(totalPercentScore) === "silver") {
    return "oppnåd sølv";
  } else if (getBadgeFromTotalEarnedPoints(totalPercentScore) === "gold") {
    return "oppnåd gull";
  } else {
    return "begynt";
  }
};

const badgeImage = (totalPercentScore) => {
  if (getBadgeFromTotalEarnedPoints(totalPercentScore) === "bronze") {
    return <Image src={Bronze}></Image>;
  } else if (getBadgeFromTotalEarnedPoints(totalPercentScore) === "silver") {
    return <Image src={Silver}></Image>;
  } else if (getBadgeFromTotalEarnedPoints(totalPercentScore) === "gold") {
    return <Image src={Gold}></Image>;
  } else {
    return <Image src={Start}></Image>;
  }
};

const displayRespNumber = (respData) => {
  if (!respData || !respData.isAnswered) {
    return "N/A";
  } else {
    return respData.number;
  }
};

const displayRespText = (respData) => {
  if (!respData || !respData.isAnswered) {
    return "N/A";
  } else {
    return respData.text;
  }
};

const displayIndustryCode = (respData) => {
  if (!respData || !respData.isAnswered) {
    return "N/A";
  } else {
    let data = JSON.parse(respData.multiRows);

    if (data.length === 1) {
      return data[0][0].value;
    } else if (data.length === 2) {
      return data[0][0].value + ", " + data[1][0].value;
    } else if (data.length === 3) {
      return (
        data[0][0].value + ", " + data[1][0].value + ", " + data[2][0].value
      );
    }
  }
};

const displayDailyLeader = (respData, inputValue) => {
  if (!respData || !respData.isAnswered) {
    return "N/A";
  } else {
    let data = JSON.parse(respData.multiText);

    if (inputValue === "name") {
      let index = data.findIndex((item) => {
        return item.id === "9fcb9097-474a-4d8f-8a82-f27573725381";
      });

      if (index !== -1) {
        return data[index].input;
      }
    } else if (inputValue === "number") {
      let index = data.findIndex((item) => {
        return item.id === "c55df3e0-0673-4f85-8e53-931c6b36bff5";
      });

      if (index !== -1) {
        return data[index].input;
      }
    } else if (inputValue === "email") {
      let index = data.findIndex((item) => {
        return item.id === "8e4d15e5-bccd-4acc-b66e-44c512768477";
      });

      if (index !== -1) {
        return data[index].input;
      }
    }
  }
};

const displayStaffLeader = (respData, inputValue) => {
  if (!respData || !respData.isAnswered) {
    return "N/A";
  } else if (respData.answers.length > 0) {
    return "Ingen styre";
  } else {
    let data = JSON.parse(respData.multiNumber);

    if (inputValue === "name") {
      let index = data.findIndex((item) => {
        return item.id === "8c625ade-dd48-4a4d-9b8f-bbe0ec330fc9";
      });

      if (index !== -1) {
        return data[index].input;
      }
    } else if (inputValue === "number") {
      let index = data.findIndex((item) => {
        return item.id === "06bbaa94-d121-4261-99fa-8d387e4cf2cc";
      });

      if (index !== -1) {
        return data[index].input;
      }
    } else if (inputValue === "email") {
      let index = data.findIndex((item) => {
        return item.id === "691078c2-3a95-4d51-87b9-ec7f26ba40bb";
      });

      if (index !== -1) {
        return data[index].input;
      }
    }
  }
};

const displayDailyLeaderField = (respData) => {
  if (!respData || !respData.isAnswered) {
    return <></>;
  } else {
    let data = JSON.parse(respData.multiText);

    let name = "";
    let number = "";
    let email = "";

    // Full name
    let index = data.findIndex((item) => {
      return item.id === "9fcb9097-474a-4d8f-8a82-f27573725381";
    });

    if (index !== -1) {
      name = data[index].input;
    }

    // Phone number
    index = data.findIndex((item) => {
      return item.id === "c55df3e0-0673-4f85-8e53-931c6b36bff5";
    });

    if (index !== -1) {
      number = data[index].input;
    }

    // Email
    index = data.findIndex((item) => {
      return item.id === "8e4d15e5-bccd-4acc-b66e-44c512768477";
    });

    if (index !== -1) {
      email = data[index].input;
    }

    return (
      <View style={[styles.leftColumn, { width: "50%" }]}>
        <Text style={[styles.italic, { marginBottom: "80px" }]}>Dato:</Text>
        <Svg height="10" width="250">
          <Line
            x1="0"
            y1="0"
            x2="230"
            y2="0"
            strokeWidth={5}
            stroke="#FDDFB0"
          />
        </Svg>
        <Svg height="10" width="250">
          <Line
            x1="0"
            y1="0"
            x2="230"
            y2="0"
            strokeWidth={3}
            stroke="#EBEBEB"
          />
        </Svg>
        <Text style={[styles.italic, { fontSize: "14px" }]}>
          {name}, Daglig leder
        </Text>
        <Text style={styles.italic}>{email}</Text>
        <Text style={styles.italic}>+47{number}</Text>
      </View>
    );
  }
};

const displayStaffLeaderField = (respData) => {
  if (!respData || !respData.isAnswered || respData.answers.length > 0) {
    return <></>;
  } else {
    let data = JSON.parse(respData.multiNumber);

    let name = "";
    let number = "";
    let email = "";

    // Full name
    let index = data.findIndex((item) => {
      return item.id === "8c625ade-dd48-4a4d-9b8f-bbe0ec330fc9";
    });

    if (index !== -1) {
      name = data[index].input;
    }

    // Phone number
    index = data.findIndex((item) => {
      return item.id === "06bbaa94-d121-4261-99fa-8d387e4cf2cc";
    });

    if (index !== -1) {
      number = data[index].input;
    }

    // Email
    index = data.findIndex((item) => {
      return item.id === "691078c2-3a95-4d51-87b9-ec7f26ba40bb";
    });

    if (index !== -1) {
      email = data[index].input;
    }

    return (
      <View style={[styles.rightColumn, { width: "50%" }]}>
        <Text style={[styles.italic, { marginBottom: "80px" }]}>Dato:</Text>
        <Svg height="10" width="250">
          <Line
            x1="0"
            y1="0"
            x2="230"
            y2="0"
            strokeWidth={5}
            stroke="#FDDFB0"
          />
        </Svg>
        <Svg height="10" width="250">
          <Line
            x1="0"
            y1="0"
            x2="230"
            y2="0"
            strokeWidth={3}
            stroke="#EBEBEB"
          />
        </Svg>
        <Text style={[styles.italic, { fontSize: "14px" }]}>
          {name}, Styrets leder
        </Text>
        <Text style={styles.italic}>{email}</Text>
        <Text style={styles.italic}>+47{number}</Text>
      </View>
    );
  }
};

const displaySustainVision = (respData) => {
  if (!respData || !respData.isAnswered) {
    return "Selskapet har ikke skrevet en bærekraftsvisjon.";
  } else {
    return respData.longText;
  }
};

const displaySustainGoals = (respData) => {
  if (!respData || !respData.isAnswered) {
    return (
      <Text style={styles.p}>
        Selskapet har ikke definert noen bærekraftsmål.
      </Text>
    );
  } else {
    let number = 0;

    let data = JSON.parse(respData.multiDropSelect);

    return (
      <View style={styles.table}>
        <View style={styles.tableHead}>
          <Text style={[styles.headCell, { width: "30%", textAlign: "left" }]}>
            Bærekraftsmål
          </Text>
          <Text style={[styles.headCell, { width: "70%", textAlign: "left" }]}>
            Beskrivelse
          </Text>
        </View>
        {data.map((item) => {
          if (number % 2 === 0) {
            number = number + 1;

            return (
              <View style={styles.tableBody} key={item.id} wrap={false}>
                <Text
                  style={[styles.bodyCell, { width: "30%", textAlign: "left" }]}
                >
                  {item.value}
                </Text>
                <Text
                  style={[
                    styles.bodyCell2,
                    { width: "70%", textAlign: "left" },
                  ]}
                >
                  {item.input}
                </Text>
              </View>
            );
          } else {
            number = number + 1;

            return (
              <View style={styles.tableBody2} key={item.id} wrap={false}>
                <Text
                  style={[styles.bodyCell, { width: "30%", textAlign: "left" }]}
                >
                  {item.value}
                </Text>
                <Text
                  style={[
                    styles.bodyCell2,
                    { width: "70%", textAlign: "left" },
                  ]}
                >
                  {item.input}
                </Text>
              </View>
            );
          }
        })}
      </View>
    );
  }
};

export const DetailReportDocument = (props) => (
  <Document>
    <Page size="A4" style={styles.page2} wrap={false}>
      <View style={styles.toppBar}></View>
      <View style={styles.section}>
        <Text
          style={[
            styles.h1,
            { borderBottom: "none", marginBottom: "0px", fontSize: "30px" },
          ]}
        >
          {props.auth.companyData.name}
        </Text>
        <Text style={[styles.h1, { marginTop: "0px" }]}>
          Statusrapport bærekraft
        </Text>
        <Text style={[styles.p, { textAlign: "center", marginTop: "20px" }]}>
          Dato:{" "}
          {new Date().getDate() +
            "." +
            (new Date().getMonth() + 1) +
            "." +
            new Date().getFullYear()}
        </Text>
        <Image
          src={Leaf}
          style={{
            width: "30%",
            marginTop: "150px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        ></Image>
      </View>
      <View style={styles.footer}></View>
    </Page>
    <Page size="A4" style={styles.page2}>
      <View style={styles.toppBar}></View>
      <View style={styles.section}>
        <Text style={[styles.h2, { marginTop: "0px" }]}>Nøkkelinformasjon</Text>
        <View style={styles.columns}>
          <View style={styles.leftColumn}>
            <View style={styles.table}>
              <View style={styles.tableBody} wrap={false}>
                <Text
                  style={[styles.infoCell, { width: "50%", textAlign: "left" }]}
                >
                  Selskapsnavn
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {displayRespText(
                    props.respData.find((response) => {
                      return (
                        response.questionId ===
                        "85cace3a-32f1-4e6c-beaa-cf7bd16c1126"
                      );
                    })
                  )}
                </Text>
              </View>
              <View style={styles.tableBody2}>
                <Text
                  style={[styles.infoCell, { width: "50%", textAlign: "left" }]}
                >
                  Organisasjonsnummer
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {displayRespNumber(
                    props.respData.find((response) => {
                      return (
                        response.questionId ===
                        "b6f6b02d-dced-4fc1-8880-299836da9e7f"
                      );
                    })
                  )}
                </Text>
              </View>
              <View style={styles.tableBody}>
                <Text
                  style={[styles.infoCell, { width: "50%", textAlign: "left" }]}
                >
                  Næringskode
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {displayIndustryCode(
                    props.respData.find((response) => {
                      return (
                        response.questionId ===
                        "d1126dd9-9d15-47d0-8e28-c6f8da8709f4"
                      );
                    })
                  )}
                </Text>
              </View>
              <View style={styles.tableBody2}>
                <Text
                  style={[styles.infoCell, { width: "50%", textAlign: "left" }]}
                >
                  Etableringsår
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {displayRespText(
                    props.respData.find((response) => {
                      return (
                        response.questionId ===
                        "8a1e1d76-3a3f-4abd-a3d5-4f651c4ee669"
                      );
                    })
                  )}
                </Text>
              </View>
              <View style={styles.tableBody}>
                <Text
                  style={[
                    styles.infoCell,
                    { width: "50%", textAlign: "left", display: "inline" },
                  ]}
                >
                  Omsetning
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {displayRespNumber(
                    props.respData.find((response) => {
                      return (
                        response.questionId ===
                        "377333eb-f211-4d6c-a5f0-023c6c967911"
                      );
                    })
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.rightColumn}>
            <View style={styles.table}>
              <View style={styles.tableBody}>
                <Text
                  style={[styles.infoCell, { width: "50%", textAlign: "left" }]}
                >
                  Daglig leder
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {displayDailyLeader(
                    props.respData.find((response) => {
                      return (
                        response.questionId ===
                        "e375a6fa-f508-450b-b943-13054c48584d"
                      );
                    }),
                    "name"
                  )}
                </Text>
              </View>
              <View style={styles.tableBody2}>
                <Text
                  style={[styles.infoCell, { width: "50%", textAlign: "left" }]}
                >
                  Styreleder
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {displayStaffLeader(
                    props.respData.find((response) => {
                      return (
                        response.questionId ===
                        "65e640ac-d423-454e-b66d-f60c378f9534"
                      );
                    }),
                    "name"
                  )}
                </Text>
              </View>
              <View style={styles.tableBody}>
                <Text
                  style={[styles.infoCell, { width: "50%", textAlign: "left" }]}
                >
                  Årsverk
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {displayRespNumber(
                    props.respData.find((response) => {
                      return (
                        response.questionId ===
                        "f88e71b2-bc8b-4c8b-9366-43054ba9ba53"
                      );
                    })
                  )}
                </Text>
              </View>
              <View style={styles.tableBody2}>
                <Text
                  style={[styles.infoCell, { width: "50%", textAlign: "left" }]}
                >
                  Ansvarlig for bærekraft
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {props.auth.companyData.companyData.certData.items[0]
                    .publicProfile.contactPerson || "N/A"}
                </Text>
              </View>
              <View style={styles.tableBody}>
                <Text
                  style={[styles.infoCell, { width: "50%", textAlign: "left" }]}
                >
                  Nettadresse
                </Text>
                <Text
                  style={[
                    styles.infoCell2,
                    { width: "50%", textAlign: "left" },
                  ]}
                >
                  {displayRespText(
                    props.respData.find((response) => {
                      return (
                        response.questionId ===
                        "04484fb2-cbd1-444d-9788-dc3910c2cf26"
                      );
                    })
                  )}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={[
            styles.h2,
            { borderBottom: "3px solid #FDDFB0", margin: "10px 0px" },
          ]}
        >
          Resultater
        </Text>
        <View style={styles.table}>
          <View style={styles.tableHead}>
            <Text style={[styles.headCell, { width: "10%" }]}></Text>
            <Text
              style={[styles.headCell, { width: "65%", textAlign: "left" }]}
            >
              Modul
            </Text>
            <Text
              style={[styles.headCell, { width: "15%", textAlign: "center" }]}
            >
              Poengsum
            </Text>
            <Text
              style={[styles.headCell, { width: "10%", textAlign: "center" }]}
            >
              Prosent
            </Text>
          </View>
          <View style={styles.resultBody}>
            <Text
              style={[
                styles.resultCell,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "#E1F4E8",
                },
              ]}
            >
              E
            </Text>
            <Text
              style={[styles.resultCell, { width: "65%", textAlign: "left" }]}
            >
              Miljø & Klima
            </Text>
            <Text
              style={[styles.resultCell, { width: "15%", textAlign: "center" }]}
            >
              {
                props.auth.companyData.companyData.certData.items[0]
                  .publicProfile.economyScore
              }
            </Text>
            <Text
              style={[
                styles.resultCell,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "#E1F4E8",
                },
              ]}
            >
              {
                props.auth.companyData.companyData.certData.items[0]
                  .publicProfile.economyPercentScore
              }
              %
            </Text>
          </View>
          <View style={[styles.resultBody, { margin: "10px 0px" }]}>
            <Text
              style={[
                styles.resultCell,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "#F9E5E8",
                },
              ]}
            >
              S
            </Text>
            <Text
              style={[styles.resultCell, { width: "65%", textAlign: "left" }]}
            >
              Sosiale forhold
            </Text>
            <Text
              style={[styles.resultCell, { width: "15%", textAlign: "center" }]}
            >
              {
                props.auth.companyData.companyData.certData.items[0]
                  .publicProfile.socialScore
              }
            </Text>
            <Text
              style={[
                styles.resultCell,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "#F9E5E8",
                },
              ]}
            >
              {
                props.auth.companyData.companyData.certData.items[0]
                  .publicProfile.socialPercentScore
              }
              %
            </Text>
          </View>
          <View style={styles.resultBody}>
            <Text
              style={[
                styles.resultCell,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "#DDE5F2",
                },
              ]}
            >
              G
            </Text>
            <Text
              style={[styles.resultCell, { width: "65%", textAlign: "left" }]}
            >
              Økonomi & Drift
            </Text>
            <Text
              style={[styles.resultCell, { width: "15%", textAlign: "center" }]}
            >
              {
                props.auth.companyData.companyData.certData.items[0]
                  .publicProfile.environmentalScore
              }
            </Text>
            <Text
              style={[
                styles.resultCell,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "#DDE5F2",
                },
              ]}
            >
              {
                props.auth.companyData.companyData.certData.items[0]
                  .publicProfile.environmentalPercentScore
              }
              %
            </Text>
          </View>
        </View>
        <Text
          style={[
            styles.h2,
            { borderBottom: "3px solid #FDDFB0", margin: "10px 0px" },
          ]}
        >
          Valørmerke
        </Text>
        <View style={styles.columns}>
          <View style={[styles.leftColumn, { width: "70%" }]}>
            <Text style={styles.p}>
              {props.auth.companyData.name} har{" "}
              {badgeText(
                props.auth.companyData.companyData.certData.items[0]
                  .publicProfile.totalPercentScore
              )}{" "}
              i Ecofrics bærekraftscore. Vi har totalt oppnådd{" "}
              {props.auth.companyData.companyData.certData.items[0]
                .publicProfile.economyScore +
                props.auth.companyData.companyData.certData.items[0]
                  .publicProfile.socialScore +
                props.auth.companyData.companyData.certData.items[0]
                  .publicProfile.environmentalScore}{" "}
              poeng av {props.totalPoints} mulige. Tildelingen er et bevis på at
              vi har et stort fokus på alle bærekraftens tre områder: Sosiale
              forhold, Miljø og Klima og Selskapsstyring.{" "}
              {props.auth.companyData.name} har integrert bærekraft i
              virksomheten, har fokus på kompetanseheving og har iverksatt flere
              tiltak innen de fleste deler av bærekraftens tre områder.
            </Text>
          </View>
          <View style={[styles.rightColumn, { width: "30%" }]}>
            {badgeImage(
              props.auth.companyData.companyData.certData.items[0].publicProfile
                .totalPercentScore
            )}
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text
          style={[styles.pageNb, { marginRight: "-20px" }]}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </View>
    </Page>
    <Page size="A4" style={styles.page} wrap>
      <View
        fixed={true}
        style={[styles.toppBar, { margin: "-20px -80px 10px -80px" }]}
      ></View>

      <Text
        style={[
          styles.h2,
          { borderBottom: "3px solid #FDDFB0", margin: "0px 0px 10px 0px" },
        ]}
      >
        Om oss
      </Text>
      <Text style={styles.p}>
        {
          props.auth.companyData.companyData.certData.items[0].publicProfile
            .description
        }
      </Text>
      <Text
        style={[
          styles.h2,
          { borderBottom: "3px solid #FDDFB0", margin: "10px 0px" },
        ]}
      >
        Vår bærekraftsvisjon
      </Text>
      <Text style={styles.p}>
        {displaySustainVision(
          props.respData.find((response) => {
            return (
              response.questionId === "5ac764cf-fefb-4f96-9876-f29bffe9f279"
            );
          })
        )}
      </Text>
      <Text
        style={[
          styles.h2,
          { borderBottom: "3px solid #FDDFB0", margin: "10px 0px" },
        ]}
      >
        Våre prioriterte bærekraftsmål
      </Text>
      {displaySustainGoals(
        props.respData.find((response) => {
          return response.questionId === "3cb1c6ce-aaa2-4c7c-a80f-4c4d5f501faf";
        })
      )}

      <View
        fixed={true}
        style={{ flexGrow: 1, backgroundColor: "#ffffff" }}
      ></View>
      <View
        fixed={true}
        style={[
          styles.footer,
          {
            margin: "10px -80px -20px -80px",
            position: "fixed",
            bottom: "0px",
            left: "0px",
          },
        ]}
      >
        <Text
          style={styles.pageNb}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </View>
    </Page>
    <Page size="A4" style={styles.page} wrap>
      <View
        fixed={true}
        style={[styles.toppBar, { margin: "-20px -80px 10px -80px" }]}
      ></View>
      <Text style={[styles.h2, { borderBottom: "3px solid #FDDFB0" }]}>
        Økonomi & drift
      </Text>
      <Text style={[styles.p, { marginBottom: "10px" }]}>
        I denne delen kartlegges det hvordan bedriften styres i dag og hva som
        er nåværende status på bedriftens bærekraftsarbeid. Kartleggingen gir
        innsikt i muligheter, utfordringer og risiko for virksomheten, hvor
        formålet er å identifisere fremtidige forbedringsområder og tiltak. God
        selskapsstyring er nødvendig for en bærekraftig utvikling til det beste
        for både eiere, miljø og samfunn.
      </Text>
      {mapOutRespSummary(props.ecoCertData, props.respData)}
      {mapOutCategory(props.ecoCertData, props.respData)}
      <Text style={[styles.h2, { borderBottom: "3px solid #FDDFB0" }]} break>
        Sosiale forhold
      </Text>
      <Text style={[styles.p, { marginBottom: "10px" }]}>
        I denne delen kartlegges sosiale forhold og hvordan bedriften ivaretar
        ansatte utover pålagte krav. Man får innsikt i hva bedriften gjør for
        sine ansatte, lokalsamfunn og andre berørte i verdikjeden. Dette
        synliggjør hvordan bedriften tar ansvar for sine omgivelser, hvordan de
        kan bidra til nye løsninger og bedre sosiale forhold, samt hvordan
        bedriften kan være med å bygge et sterkere lokalsamfunn.
      </Text>
      {mapOutRespSummary(props.socialCertData, props.respData)}
      {mapOutCategory(props.socialCertData, props.respData)}
      <Text style={[styles.h2, { borderBottom: "3px solid #FDDFB0" }]} break>
        Miljø & Klima
      </Text>
      <Text style={[styles.p, { marginBottom: "10px" }]}>
        I denne delen kartlegges bedriftens negative økologiske fotavtrykk. Det
        identifiseres tiltak som bidrar til å minimere fotavtrykkene og hva
        bedriften kan gjøre for å redusere sløsing. Bedriften får oversikt og
        kontroll over eget forbruk, utslipp og negative økologiske fotavtrykk,
        for videre å kunne jobbe mot gode løsninger. Målet er å ta vare på
        natur, klima og jorden som fornybar ressurs.
      </Text>
      {mapOutRespSummary(props.envCertData, props.respData)}
      {mapOutCategory(props.envCertData, props.respData)}
      <Text style={[styles.h2, { marginBottom: "0px" }]} break>
        Oppsummering
      </Text>
      <Text
        style={[
          styles.h3,
          { marginBottom: "5px", borderBottom: "2px solid #FDDFB0" },
        ]}
      >
        Om Ecofric egenerklæringen og bærekraftscoren
      </Text>
      <Text style={styles.p}>
        Informasjonen og resultatene i bærekraftsrapporten er hentet fra
        bedriften sin egenerklæring hos Ecofric. Løsningen er en digitalisert
        kartlegging av egen bedrift og egen verdikjede og er basert på ESG
        (Environmental, Social and Governance), altså selskapets evne til å
        ivareta miljømessige og sosiale forhold, samt selskapsstyring.
      </Text>
      <Text style={styles.p}>
        I egenerklæringen svarer bedriften på en rekke spørsmål innen de tre
        bærekraftsområdene - Økonomi og drift, sosiale forhold og klima og
        miljø. Totalt vurderes bedriftens bærekraftsarbeid ut ifra{" "}
        {props.totalQuestions} indikatorer. Dersom bedriften oppnår mer enn 50%
        av totalt oppnåelig poeng vil bedriften få en høy nok bærekraftscore til
        å oppnå et bronse, sølv eller gullmerke:
      </Text>
      <View style={[styles.columns, { marginTop: "10px" }]}>
        <View style={styles.badgeColumn}>
          <Image src={Ellipse3} style={styles.ellipse}></Image>
          <Text style={styles.bold}>Bronse: 50-69%</Text>
        </View>
        <View style={styles.badgeColumn}>
          <Image src={Ellipse2} style={styles.ellipse}></Image>
          <Text style={styles.bold}>Sølv: 70-89%</Text>
        </View>
        <View style={styles.badgeColumn}>
          <Image src={Ellipse1} style={styles.ellipse}></Image>
          <Text style={styles.bold}>Gull: 90%</Text>
        </View>
      </View>
      <Text
        style={[
          styles.h3,
          { marginBottom: "5px", borderBottom: "2px solid #FDDFB0" },
        ]}
      >
        Samtykke
      </Text>
      <View style={styles.columns}>
        <View
          style={[
            styles.leftColumn,
            {
              width: "10%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image src={RadioChecked} style={{ width: "25%" }}></Image>
        </View>
        <View style={[styles.rightColumn, { width: "90%" }]}>
          <Text style={styles.p}>
            {props.auth.companyData.name} bekrefter at opplysningene som gitt i
            denne egenerklæringen/rapporten er korrekte. Ecofric og annen
            tredjepart gjennomgår og kontrollerer {props.auth.companyData.name}
            resultater, og Ecofric og annen tredjepart kan anmode om revisjoner
            eller dokumentasjon for informasjonen som er lagt inn.
          </Text>
        </View>
      </View>
      <View style={styles.columns}>
        <View
          style={[
            styles.leftColumn,
            {
              width: "10%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image src={RadioChecked} style={{ width: "25%" }}></Image>
        </View>
        <View style={[styles.rightColumn, { width: "90%" }]}>
          <Text style={styles.p}>
            {props.auth.companyData.name} bekrefter at virksomheten drives i
            tråd med gjeldende lovverk, og er ikke involvert i noen form for
            korrupsjon eller hvitvasking. {props.auth.companyData.name} ivaretar
            vern av menneskerettigheter og tar avstand fra alle former for
            grønnvasking.
          </Text>
        </View>
      </View>
      <Text
        style={[
          styles.h3,
          { marginBottom: "20px", borderBottom: "2px solid #FDDFB0" },
        ]}
      >
        Signatur
      </Text>
      <View style={styles.columns}>
        {displayDailyLeaderField(
          props.respData.find((response) => {
            return (
              response.questionId === "e375a6fa-f508-450b-b943-13054c48584d"
            );
          })
        )}
        {displayStaffLeaderField(
          props.respData.find((response) => {
            return (
              response.questionId === "65e640ac-d423-454e-b66d-f60c378f9534"
            );
          })
        )}
      </View>
      <View
        fixed={true}
        style={{ flexGrow: 1, backgroundColor: "#ffffff" }}
      ></View>
      <View
        fixed={true}
        style={[
          styles.footer,
          {
            margin: "10px -80px -20px -80px",
            position: "fixed",
            bottom: "0px",
            left: "0px",
          },
        ]}
      >
        <Text
          style={styles.pageNb}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </View>
    </Page>
  </Document>
);

export function DetailReportPDFExport(props) {
  return (
    <DetailReportDocument
      ecoCertData={props.certData.find((category) => {
        return category.id === "economy";
      })}
      socialCertData={props.certData.find((category) => {
        return category.id === "social";
      })}
      envCertData={props.certData.find((category) => {
        return category.id === "environment";
      })}
      respData={props.respData}
      totalPoints={props.totalPoints}
      totalQuestions={props.totalQuestions}
      auth={props.auth}
    />
  );
}

export default function DetailReportPDF(props) {
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      let newTotalPoints = 0;
      let newTotalQuestions = 0;

      for (const category of props.certData) {
        for (const subcategory of category.subcategories.items) {
          newTotalQuestions =
            subcategory.questions.items.length + newTotalQuestions;

          for (const question of subcategory.questions.items) {
            newTotalPoints = question.pointValue + newTotalPoints;
          }
        }
      }

      setTotalPoints(newTotalPoints);
      setTotalQuestions(newTotalQuestions);
    }

    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PDFViewer width="100%" height="730">
      <DetailReportDocument
        ecoCertData={props.certData.find((category) => {
          return category.id === "economy";
        })}
        socialCertData={props.certData.find((category) => {
          return category.id === "social";
        })}
        envCertData={props.certData.find((category) => {
          return category.id === "environment";
        })}
        respData={props.respData}
        totalPoints={totalPoints}
        totalQuestions={totalQuestions}
        auth={props.auth}
      />
    </PDFViewer>
  );
}
