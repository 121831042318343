import React from 'react';
import { CircularProgress } from '@mui/material';

export function Loading(props) {
    if (props.determinate === true) {
        return <CircularProgress color={props.color} variant="determinate" value={props.value} size={props.size} />;
    } else {
        return <CircularProgress color={props.color} size={props.size} sx={props.sx} />;
    }
}

export function SiteLoading(props) {
    return (
        <div
            style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <CircularProgress size={100} color={props.color} />
        </div>
    );
}

export function ContentLoading(props) {
    return (
        <div
            style={{
                position: 'flex',
                justifyContent: 'center',
                marginLeft: '40%',
                marginTop: '20%',
            }}
        >
            <CircularProgress size={120} color={props.color} />
        </div>
    );
}
