import React, { useState } from 'react';
import {
    Grid,
    Button,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    FormControl,
    Checkbox,
    Switch,
    InputLabel,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { TextAreaInput, NumberInput } from '../../../../../components/InputForm';
import Question from './Question';

import { API, graphqlOperation } from 'aws-amplify';
import { createNSRSQuestion } from '../../../../../graphql/mutations';

export default function QuestionList(props) {
    const { showPopup, updateCategories, categories } = props;

    const [open, setOpen] = useToggle();

    const [categoryId, setCategoryId] = useState('');
    const [subcategoryId, setSubcategoryId] = useState('');
    const [code, setCode] = useState('');

    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [explanation, setExplanation] = useState('');
    const [filterTypes, setFilterTypes] = useState([]);
    const [industryCodes, setIndustryCodes] = useState([]);
    const [required, setRequired] = useState(false);
    const [order, setOrder] = useState(1);

    const saveNewQuestion = async () => {
        return new Promise(async (resolve, reject) => {
            // Input data for creating a new CompanyData object
            let inputData = {
                categoryId: categoryId,
                subcategoryId: subcategoryId,
                name: name,
                description: desc,
                explanation: explanation,
                filterTypes: filterTypes,
                industryCodes: industryCodes,
                required: required,
                order: order,
            };

            await API.graphql(graphqlOperation(createNSRSQuestion, { input: inputData }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new question: ', data.data.createNSRSQuestion);
                    }

                    resolve(data.data.createNSRSQuestion);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Failed to create new question: ', err);
                    }

                    reject(err);
                });
        });
    };

    const addCode = () => {
        if (industryCodes.includes(code)) {
            return;
        }

        if (code === '') {
            return;
        }

        let array = industryCodes;

        array.push(code);

        setIndustryCodes([...array]);
        setCode('');
    };

    const removeCode = index => {
        let array = industryCodes;

        array.splice(index, 1);

        setIndustryCodes([...array]);
    };

    const handleSelect = value => {
        if (value) {
            for (const category of categories) {
                for (const subcategory of category.subcategories.items) {
                    if (subcategory.id === value) {
                        setCategoryId(category.id);
                        setSubcategoryId(subcategory.id);
                    }
                }
            }
        }
    };

    const handleFilterChecked = value => {
        let newArr = filterTypes;

        if (newArr.includes(value)) {
            let index = newArr.indexOf(value);

            newArr.splice(index, 1);
        } else {
            newArr.push(value);
        }

        setFilterTypes([...newArr]);
    };

    const handleSaveNewQuestion = async () => {
        if (name === '' || desc === '' || explanation === '') {
            showPopup('Alle felter må være fylt ut!');
            return;
        }

        await saveNewQuestion().catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmålet');
            return;
        });

        showPopup('Spørsmålet har blitt lagret');

        await updateCategories();

        handleOpenDialog();
    };

    const handleOpenDialog = () => {
        setName('');
        setDesc('');
        setExplanation('');
        setFilterTypes([]);
        setIndustryCodes([]);
        setRequired(false);
        setOrder(1);

        setOpen();
    };

    const newQuestionDialog = () => {
        return (
            <Grid>
                <Dialog open={open} onClose={handleOpenDialog} fullWidth maxWidth="md">
                    <DialogTitle sx={{ fontSize: '2em' }}>Lag ny spørsmål</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>Lag en helt ny spørsmål.</DialogContentText>
                        <InputForm
                            label="Spørsmål"
                            placeholder="Skriv inn hva spørsmålet er..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Beskrivelse (Kort tekst)"
                            placeholder="Skriv inn beskrivelse av spørsmålet..."
                            value={desc}
                            onChange={e => setDesc(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Hjelpetekst (Lang tekst)"
                            placeholder="Skriv inn detaljert hjelptekst av spørsmålet..."
                            value={explanation}
                            onChange={e => setExplanation(e.target.value)}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={required} onChange={e => setRequired(e.target.checked)} />}
                                label="Er spørsmålet påkrevd?"
                            />
                        </FormGroup>
                        <InputLabel id="Type">
                            Filtreringstype (Velg kategoriene for selskapene som spørsmålet skal bli vist til, eller la
                            være tom hvis for alle)
                        </InputLabel>
                        <FormControl>
                            <FormGroup row defaultValue="None" onChange={e => handleFilterChecked(e.target.value)}>
                                <FormControlLabel
                                    value="Employees"
                                    control={<Checkbox checked={filterTypes.includes('Employees')} />}
                                    label="Flere ansatte"
                                />
                                <FormControlLabel
                                    value="Property"
                                    control={<Checkbox checked={filterTypes.includes('Property')} />}
                                    label="Bygg"
                                />
                                <FormControlLabel
                                    value="Production"
                                    control={<Checkbox checked={filterTypes.includes('Production')} />}
                                    label="Produsenter"
                                />
                                <FormControlLabel
                                    value="Trade"
                                    control={<Checkbox checked={filterTypes.includes('Trade')} />}
                                    label="Varehandel"
                                />
                            </FormGroup>
                        </FormControl>
                        <NumberInput
                            label="Listenummer (blir brukt for rekkefølge)"
                            placeholder="Skriv inn nummer..."
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                        <NumberInput
                            label="Næringskoder (Bare første 2 tall, la være tom hvis for alle)"
                            placeholder="Skriv og legg til næringskode..."
                            value={code}
                            onChange={e => setCode(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{
                                padding: '20px',
                                marginBottom: '20px',
                                width: '130px',
                            }}
                            onClick={addCode}
                        >
                            Legg til kode
                        </Button>
                        <Grid>
                            <b>Koder:</b>
                        </Grid>
                        {industryCodes &&
                            industryCodes.map((code, i) => {
                                return (
                                    <Grid key={code}>
                                        {code}
                                        <Button startIcon={<RemoveCircleOutlineIcon />} onClick={() => removeCode(i)} />
                                        <br />
                                    </Grid>
                                );
                            })}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleOpenDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleSaveNewQuestion}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid item xs={12}>
            {newQuestionDialog()}
            <h1>Liste over lagret NSRS spørsmål</h1>
            <Select value={subcategoryId} onChange={e => handleSelect(e.target.value)} label="Velg en underkategori">
                {categories.map(category => {
                    return (
                        category.subcategories &&
                        category.subcategories.items
                            .sort((a, b) => a.order - b.order)
                            .map(subcategory => {
                                return (
                                    <MenuItem value={subcategory.id} key={subcategory.id}>
                                        {category.order + '.' + subcategory.order + ' - ' + subcategory.name}
                                    </MenuItem>
                                );
                            })
                    );
                })}
            </Select>
            {subcategoryId !== '' ? (
                <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{ padding: '20px', marginBottom: '20px', width: '250px' }}
                    onClick={setOpen}
                >
                    Legg til ny NSRS spørsmål{' '}
                </Button>
            ) : (
                <></>
            )}
            <List>
                {categoryId !== '' &&
                subcategoryId !== '' &&
                categories
                    .find(category => category.id === categoryId)
                    .subcategories.items.find(subcategory => subcategory.id === subcategoryId).questions &&
                categories
                    .find(category => category.id === categoryId)
                    .subcategories.items.find(subcategory => subcategory.id === subcategoryId).questions.length ===
                    0 ? (
                    <>Det er ingen lagret NSRS spørsmål.</>
                ) : (
                    categoryId !== '' &&
                    subcategoryId !== '' &&
                    categories
                        .find(category => category.id === categoryId)
                        .subcategories.items.find(subcategory => subcategory.id === subcategoryId).questions &&
                    categories
                        .find(category => category.id === categoryId)
                        .subcategories.items.find(subcategory => subcategory.id === subcategoryId)
                        .questions.items.sort((a, b) => a.order - b.order)
                        .map(question => {
                            return (
                                <Question
                                    key={question.id}
                                    showPopup={showPopup}
                                    data={question}
                                    category={categories.find(category => category.id === categoryId)}
                                    subcategory={categories
                                        .find(category => category.id === categoryId)
                                        .subcategories.items.find(subcategory => subcategory.id === subcategoryId)}
                                    updateCategories={updateCategories}
                                />
                            );
                        })
                )}
            </List>
        </Grid>
    );
}
