import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  Button,
  Box,
  Collapse,
  Divider,
  InputLabel,
  InputBase,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Checkbox,
  TextField,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useStyles, CustomCell, CustomRow } from "../../ClimateStyles";
import { ArrowRight, Add, Delete } from "../../../../../components/Icons";
import * as Text from "../../../../../helpers/climateAccounting.json";
import DialogPopUp from "../../../../../components/DialogPopup.jsx";
import {
  newClimateResponse,
  updateClimateResponse,
} from "../../climateFunctions";
import { Loading } from "../../../../../components/Loading";
import { useAuth } from "../../../../../auth/useAuth";
import Chores from "../Chores";

export default function Investments(props) {
  const {
    CollapseColor,
    CheckedColor,
    IconChange,
    ApplicableCheckBox,
    updateReport,
    reportId,
    questionId,
    data,
    index,
    open,
    changeOpen,
    openNext,
    tasks,
    updateTasks,
    users,
  } = props;
  const classes = useStyles();
  const auth = useAuth();
  const [hasData, setHasData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isRelevant, setIsRelevant] = useState(true);
  const [company, setCompany] = useState("");
  const [branch, setBranch] = useState("");
  const [solution, setSolution] = useState("");
  const [dilligenceCheck, setDilligenceCheck] = useState(false);
  const [agenda, setAgenda] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      checkForData();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkForData = async () => {
    if (data !== null && data !== undefined) {
      let dataObj = JSON.parse(data.data);
      setAnswers(dataObj);

      if (!data.isRelevant) {
        setIsAnswered(true);
        setIsRelevant(false);
      } else if (dataObj.length !== 0) {
        setIsAnswered(true);
      }

      setHasData(true);
    } else {
      setHasData(false);
    }
  };

  const saveData = async (check) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    let newCheck = check;

    if (newCheck === null || newCheck === undefined) {
      newCheck = true;
    }

    if (hasData) {
      await updateClimateResponse(
        data.id,
        auth.companyData.companyId,
        reportId,
        questionId,
        3,
        answers,
        newCheck
      ).then(async () => {
        await updateReport();

        setIsSaving(false);
      });
    } else {
      await newClimateResponse(
        auth.companyData.companyId,
        reportId,
        questionId,
        3,
        answers,
        newCheck
      ).then(async () => {
        await updateReport();

        setHasData(true);
        setIsSaving(false);
      });
    }
  };

  const handleAddAnswer = async () => {
    let input = {
      company: company,
      branch: branch,
      solution: solution,
      dueDilligence: dilligenceCheck,
      agenda: agenda,
    };

    let newArr = answers;

    newArr.push(input);

    if (newArr.length !== 0) {
      setIsAnswered(true);
    }

    setAnswers([...newArr]);

    setCompany("");
    setBranch("");
    setSolution("");
    setDilligenceCheck(false);
    setAgenda("");

    await saveData();
  };

  const handleRemoveAnswer = async (index) => {
    let newArr = answers;

    newArr.splice(index, 1);

    if (newArr.length === 0) {
      setIsAnswered(false);
    }

    setAnswers([...newArr]);

    await saveData();
  };

  const handleCheck = async (e) => {
    setIsRelevant(!e.target.checked);

    if (e.target.checked) {
      setIsAnswered(true);
    } else {
      setIsAnswered(false);
    }

    await saveData(!e.target.checked);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSave = async () => {
    await saveData(isRelevant);

    openNext(index);
  };

  const dialogContent = <>{Text.readmore.four}</>;

  return (
    <React.Fragment>
      <TableRow
        id={questionId}
        className={classes.question}
        sx={{
          background: CollapseColor(isAnswered, open),
        }}
      >
        <TableCell
          className={classes.iconCell}
          style={{
            backgroundColor: CheckedColor(isAnswered),
          }}
          align="center"
          onClick={() => changeOpen(index, !open)}
        >
          {IconChange(isAnswered)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          className={classes.questionRow}
          onClick={() => changeOpen(index, !open)}
        >
          <Typography variant="h3">Investeringer</Typography>
        </TableCell>
        <TableCell className={classes.applicable}>
          {ApplicableCheckBox(isRelevant, handleCheck)}
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: "20px" }}>
              <Typography variant="body1">{Text.Scope3.Investments}</Typography>
              <Divider className={classes.divider} />
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid item xs={3} sx={{ marginRight: "10px" }}>
                  <InputLabel>Bedrift</InputLabel>
                  <InputBase
                    type="text"
                    name="company"
                    placeholder="Skriv her..."
                    onChange={(e) => setCompany(e.target.value)}
                    value={company}
                  />
                </Grid>
                <Grid item xs={3} sx={{ marginRight: "10px" }}>
                  <InputLabel>Bransje</InputLabel>
                  <InputBase
                    type="text"
                    name="field"
                    placeholder="Skriv her..."
                    onChange={(e) => setBranch(e.target.value)}
                    value={branch}
                  />
                </Grid>
                <Grid item xs={3} sx={{ marginRight: "10px" }}>
                  <InputLabel>Løsning</InputLabel>
                  <InputBase
                    type="text"
                    name="solution"
                    placeholder="Skriv her..."
                    onChange={(e) => setSolution(e.target.value)}
                    value={solution}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormGroup sx={{ marginTop: "30px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dilligenceCheck}
                          onChange={(e) => setDilligenceCheck(e.target.checked)}
                        />
                      }
                      label="Aktsomhetsvurdering utført "
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid item xs={12} sx={{ marginRight: "10px" }}>
                  <InputLabel>Agenda for investering</InputLabel>
                  <TextField
                    multiline
                    rows={5}
                    name="agenda"
                    style={{ width: "100%" }}
                    onChange={(e) => setAgenda(e.target.value)}
                    value={agenda}
                  />
                  <Typography variant="body2">
                    <i>Trykk "legg til" for å legge til i lista</i>
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginBottom: "32px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ width: "130px" }}
                    onClick={handleAddAnswer}
                    disabled={
                      company !== "" &&
                      branch !== "" &&
                      solution !== "" &&
                      agenda !== ""
                        ? false
                        : true
                    }
                  >
                    {Add}Legg til
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <CustomRow>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Bedrift
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Bransje
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Løsning
                        </CustomCell>
                        <CustomCell
                          align="center"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Aktso...
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Agenda
                        </CustomCell>
                        <CustomCell
                          align="center"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Slett
                        </CustomCell>
                      </CustomRow>
                    </TableHead>
                    <TableBody>
                      {answers &&
                        answers.map((answer, index) => {
                          return (
                            <CustomRow key={index}>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.company}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.branch}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.solution}
                              </CustomCell>
                              <CustomCell
                                align="center"
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                <FormGroup sx={{ alignContent: "center" }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={answer.dueDilligence}
                                      />
                                    }
                                    label=""
                                  />
                                </FormGroup>
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.agenda}
                              </CustomCell>
                              <CustomCell
                                align="center"
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                                onClick={() => handleRemoveAnswer(index)}
                              >
                                {Delete}
                              </CustomCell>
                            </CustomRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.content}
                sx={{ justifyContent: "space-between !important" }}
              >
                <DialogPopUp
                  openDialog={openDialog}
                  onClose={handleCloseDialog}
                  title="Les mer"
                  fullWidth={true}
                  text={dialogContent}
                  handleCloseDialog={handleCloseDialog}
                />
                <Button
                  variant="text"
                  onClick={handleOpenDialog}
                  sx={{ fontSize: "18px" }}
                >
                  Mer informasjon!
                </Button>
                {isSaving ? (
                  <Loading />
                ) : (
                  <Button
                    disabled={false}
                    variant="contained"
                    onClick={handleSave}
                  >
                    Lagre{ArrowRight}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <Divider sx={{ border: "1px solid #E1E1E1" }} />
                <Typography variant="h2" sx={{ marginTop: "10px" }}>
                  Tilknyttede gjøremål
                </Typography>
                <Chores
                  tasks={tasks}
                  updateTasks={updateTasks}
                  users={users}
                  id={questionId}
                  reportId={reportId}
                  scope={3}
                />
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: "20px" }} />
    </React.Fragment>
  );
}
