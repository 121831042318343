import React from 'react';
import { Slider, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomSlider = styled(Slider)({
    color: '#FDDFB0',
    height: 10,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        color: '#000',
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#FDDFB0',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

export default function SliderInput(props) {
    const { data, number, setNumber, setPointsEarned, setIsAnswered } = props;

    const handleInput = value => {
        if (value.length > 0) {
            setIsAnswered(true);
            setPointsEarned(data.pointValue);
        } else {
            setIsAnswered(false);
            setPointsEarned(0);
        }

        setNumber(value);
    };

    return (
        <Grid>
            <CustomSlider
                aria-label="Always visible"
                valueLabelDisplay="on"
                min={0}
                max={10}
                marks
                defaultValue={number}
                value={number}
                onChange={e => handleInput(e.target.value)}
                style={{ margin: '30px 0px 0px 0px' }}
            />
            <Typography variant="h3">Valgt verdi: {number}</Typography>
        </Grid>
    );
}
