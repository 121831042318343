import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "../TermsStyle";
import { useAuth } from "../../../../auth/useAuth";

export default function ExpiredTrial(props) {
  const classes = useStyles();
  const auth = useAuth();

  if (auth.isAdmin) {
    return (
      <Grid item xs={12} className="content">
        <Typography variant="h1">Perioden har utløpt</Typography>
        <Typography className="text" sx={{ marginBottom: "10px" }}>
          Din prøveperiode på 14 dager har utløpt. Ønsker du å fortsette med
          våre tjenester og få/forbli sertifisert trykker du på bli kunde.
        </Typography>
        <Typography className="text">
          Sto ikke portalen til forventningene eller har andre tilbakemeldiger
          kan du sende en epost til hello@ecofric.com
        </Typography>
        <Grid item sx={{ display: "flex", marginTop: "20px" }}>
          <Button
            variant="contained"
            onClick={() => props.setView("payment")}
            className={classes.buttonFullWidth}
          >
            Bli kunde
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} className="content">
        <Typography variant="h1">Perioden har utløpt</Typography>
        <Typography className="text" sx={{ marginBottom: "10px" }}>
          Din prøveperiode på 14 dager har utløpt. Ønsker du å fortsette med
          våre tjenester og få/forbli sertifisert, vennligst kontakt din admin
          og abonner til våre tjenester.
        </Typography>
        <Typography className="text">
          Sto ikke portalen til forventningene eller har andre tilbakemeldiger
          kan du sende en epost til hello@ecofric.com
        </Typography>
      </Grid>
    );
  }
}
