export const listCompanysWithoutData = /* GraphQL */ `
    query ListCompanys($filter: ModelCompanyFilterInput, $limit: Int, $nextToken: String) {
        listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                companyId
                name
                orgNumber
            }
        }
    }
`;
