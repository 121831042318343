import React, { useState } from 'react';
import { Paper, Grid, Typography, Button, Divider, IconButton, InputBase } from '@mui/material';
import useRouter from '../../../../hooks/useRouter';
import { Arrow, Edit, Save } from '../../../../components/Icons';
import { EditorState, RichUtils, CompositeDecorator, convertFromRaw, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { UnderlineButton, BoldButton, ItalicButton, BlockButton } from './CommentSection/DraftStyles';
import { useAuth } from '../../../../auth/useAuth';
import { useStyles } from '../ChoresStyle';

function getEntityStrategy(mutability) {
    return function(contentBlock, callback, contentState) {
        contentBlock.findEntityRanges(character => {
            const entityKey = character.getEntity();
            if (entityKey === null) {
                return false;
            }
            return contentState.getEntity(entityKey).getMutability() === mutability;
        }, callback);
    };
}

const TokenSpan = props => {
    console.log(props);
    return <span data-offset-key={props.offsetkey}>{props.children}</span>;
};

export default function ChoreTitleDescription(props) {
    const router = useRouter();
    const auth = useAuth();

    let { id } = router.query;
    let { task, updateTask, fetchTask } = props;
    const [editMode, setEditMode] = useState(false);
    const [editTitle, setEditTitle] = useState('');
    const classes = useStyles();
    const decorator = new CompositeDecorator([
        {
            strategy: getEntityStrategy('MUTABLE'),
            component: TokenSpan,
        },
    ]);

    const blocks = convertFromRaw(JSON.parse(task.description));

    const [editorState, setEditorState] = useState(() =>
        blocks !== null ? EditorState.createWithContent(blocks, decorator) : EditorState.createEmpty(),
    );

    const editor = React.useRef(null);
    function focusEditor() {
        editor.current.focus();
    }

    const handleOpenEdit = () => {
        setEditTitle(task.title);

        setEditMode(true);
    };

    const handleCloseEdit = async () => {
        let log = editorState.getCurrentContent();

        let newHistoryObj = {
            userId: auth.userData.id,
            action: 'TITLE_UPDATE',
            timestamp: new Date(),
            oldData: task.title,
            newData: editTitle,
        };

        let historyArr = JSON.parse(task.history);

        historyArr.push(newHistoryObj);

        newHistoryObj = {
            userId: auth.userData.id,
            action: 'DESCRIPTION_UPDATE',
            timestamp: new Date(),
            oldData: task.description,
            newData: JSON.stringify(convertToRaw(log)),
        };

        historyArr.push(newHistoryObj);

        let inputData = {
            id: id,
            title: editTitle,
            description: JSON.stringify(convertToRaw(log)),
            history: JSON.stringify(historyArr),
        };

        await updateTask(inputData).then(async () => {
            await fetchTask();
        });

        setEditMode(false);
    };

    const boldText = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
        setEditorState(nextState);
    };

    const underlineText = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE');
        setEditorState(nextState);
    };

    const italicText = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
        setEditorState(nextState);
    };

    const unorderedList = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleBlockType(editorState, 'unordered-list-item');
        setEditorState(nextState);
    };

    const orderedList = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleBlockType(editorState, 'ordered-list-item');
        setEditorState(nextState);
    };

    return (
        <Grid item xs={12}>
            <Paper>
                <Typography variant="h3" sx={{ padding: '3px 20px' }}>
                    <Button
                        startIcon={Arrow}
                        variant="text"
                        sx={{ fontSize: '15px' }}
                        onClick={() => router.push('/activities')}
                    >
                        Gå tilbake
                    </Button>
                </Typography>
                {!editMode ? (
                    <Grid item xs={12} sx={{ padding: '20px' }}>
                        <Typography variant="h2">
                            {task.title} <IconButton onClick={handleOpenEdit}>{Edit}</IconButton>
                        </Typography>
                        <Divider className={classes.descDivider} />
                        <Editor editorState={editorState} readOnly={true} onChange={setEditorState} />
                    </Grid>
                ) : (
                    <Grid item xs={12} sx={{ padding: '20px' }}>
                        <Grid sx={{ display: 'flex' }}>
                            <InputBase
                                type="text"
                                name="Title"
                                value={editTitle}
                                onChange={e => setEditTitle(e.target.value)}
                            />
                            <IconButton onClick={handleCloseEdit}>{Save}</IconButton>
                        </Grid>
                        <Divider className={classes.descDivider} />
                        <div className={classes.richEditGrid} onClick={focusEditor}>
                            <UnderlineButton variant="text" onMouseDown={underlineText}>
                                Understrek
                            </UnderlineButton>
                            <BoldButton variant="text" onMouseDown={boldText}>
                                Fet
                            </BoldButton>
                            <ItalicButton variant="text" onMouseDown={italicText}>
                                Kursiv
                            </ItalicButton>
                            <BlockButton variant="text" onMouseDown={unorderedList}>
                                Punkt liste
                            </BlockButton>
                            <BlockButton variant="text" onMouseDown={orderedList}>
                                Numerert liste
                            </BlockButton>
                            <Divider sx={{ marginBottom: '15px' }} />
                            <Editor
                                ref={editor}
                                editorState={editorState}
                                onChange={setEditorState}
                                placeholder="Aktiviteten har ingen beskrivelse"
                            />
                        </div>
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
}
