import React, { useState, useEffect } from 'react';
import {
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Button,
    Select,
    InputLabel,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import InputForm, { NumberInput } from '../../../../../components/InputForm';
import useToggle from '../../../../../hooks/useToggle';
import uuid from 'react-uuid';

export default function Option(props) {
    const { data, arrayData, updateQuestion, updateCategories, rowIndex, eleIndex, showPopup } = props;

    const [open, setOpen] = useToggle();
    const [dialogOpen, setDialogOpen] = useToggle();
    const [editDialogOpen, setEditDialogOpen] = useToggle();
    const [openedAltIndex, setOpenedAltIndex] = useState(0);

    const [order, setOrder] = useState(1);
    const [label, setLabel] = useState('');
    const [type, setType] = useState('');
    const [alternatives, setAlternatives] = useState([]);
    const [maxChars, setMaxChars] = useState(0);
    const [isRequired, setIsRequired] = useState(true);

    const [altOrder, setAltOrder] = useState(1);
    const [altLabel, setAltLabel] = useState('');
    const [altHasTextField, setAltHasTextField] = useState(false);
    const [altIsRequired, setAltIsRequired] = useState(true);
    const [altMaxChars, setAltMaxChars] = useState(0);

    const [altType, setAltType] = useState('Text');
    const [altChoice, setAltChoice] = useState('');
    const [altSelects, setAltSelects] = useState([]);

    const [altCustomCatText, setAltCustomCatText] = useState('');
    const [altCustomCategories, setAltCustomCategories] = useState([]);
    const [altCustomInfText, setAltCustomInfText] = useState('');
    const [altCustomInfluence, setAltCustomInfluence] = useState([]);

    const [editAltId, setEditAltId] = useState('');
    const [editAltOrder, setEditAltOrder] = useState(1);
    const [editAltLabel, setEditAltLabel] = useState('');
    const [editAltHasTextField, setEditAltHasTextField] = useState(false);
    const [editAltIsRequired, setEditAltIsRequired] = useState(true);
    const [editAltMaxChars, setEditAltMaxChars] = useState(0);

    const [editAltType, setEditAltType] = useState('Text');
    const [editAltChoice, setEditAltChoice] = useState('');
    const [editAltSelects, setEditAltSelects] = useState([]);

    const [editAltCustomCatText, setEditAltCustomCatText] = useState('');
    const [editAltCustomCategories, setEditAltCustomCategories] = useState([]);
    const [editAltCustomInfText, setEditAltCustomInfText] = useState('');
    const [editAltCustomInfluence, setEditAltCustomInfluence] = useState([]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (data) {
                if (data.order) {
                    setOrder(data.order);
                }

                if (data.label) {
                    setLabel(data.label);
                }

                if (data.type) {
                    setType(data.type);
                }

                if (data.alternatives) {
                    setAlternatives(data.alternatives);
                }

                if (data.maxChars) {
                    setMaxChars(data.maxChars);
                }

                if (data.isRequired !== null) {
                    setIsRequired(data.isRequired);
                }
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateElement = async () => {
        if (order === '' || type === '' || label === '') {
            showPopup('Alle felter må være fylt ut!');
            return;
        }

        let newObj = {
            id: data.id,
            order: order,
            label: label,
            type: type,
            alternatives: alternatives,
            maxChars: maxChars,
            isRequired: isRequired,
        };

        let newArr = arrayData;

        newArr[rowIndex].elements[eleIndex] = newObj;

        await updateQuestion(newArr).catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        await updateCategories();
    };

    const handleDeleteElement = async () => {
        let newArr = arrayData;

        newArr[rowIndex].elements.splice(eleIndex, 1);

        await updateQuestion(newArr).catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        await updateCategories();
    };

    const handleAddAlternative = async () => {
        let newArr = alternatives;

        let newObj = {
            id: uuid(),
            order: altOrder,
            label: altLabel,
        };

        if (
            data.type === 'MultipleChoice' ||
            data.type === 'RadioGroup' ||
            data.type === 'Select' ||
            data.type === 'MultipleSelect'
        ) {
            newObj.hasTextField = altHasTextField;
        }

        if (data.type === 'MultipleSelectsWithInputs') {
            newObj.type = altType;

            if (altType === 'Select') {
                newObj.choices = altSelects;
            }
        }

        if (data.type === 'MultipleSelectsWithInputs' || data.type === 'AddRows') {
            newObj.maxChars = parseInt(altMaxChars, 10);
            newObj.isRequired = altIsRequired;
        }

        if (data.type === 'CustomRisks' || data.type === 'CustomOpportunities') {
            newObj.categories = altCustomCategories;
            newObj.influences = altCustomInfluence;
        }

        newArr.push(newObj);

        setAlternatives([...newArr]);

        let newData = arrayData;

        newData[rowIndex].elements[eleIndex].alternatives = newArr;

        await updateQuestion(newData).catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        await updateCategories();

        handleCloseDialog();
    };

    const handleSaveAlternative = async () => {
        let newArr = alternatives;

        newArr[openedAltIndex].order = editAltOrder;
        newArr[openedAltIndex].label = editAltLabel;

        if (
            data.type === 'MultipleChoice' ||
            data.type === 'RadioGroup' ||
            data.type === 'Select' ||
            data.type === 'MultipleSelect'
        ) {
            newArr[openedAltIndex].hasTextField = editAltHasTextField;
        }

        if (data.type === 'MultipleSelectsWithInputs') {
            newArr[openedAltIndex].type = editAltType;

            if (altType === 'Select') {
                newArr[openedAltIndex].choices = editAltSelects;
            }
        }

        if (data.type === 'MultipleSelectsWithInputs' || data.type === 'AddRows') {
            newArr[openedAltIndex].maxChars = parseInt(editAltMaxChars, 10);
            newArr[openedAltIndex].isRequired = editAltIsRequired;
        }

        if (data.type === 'CustomRisks' || data.type === 'CustomOpportunities') {
            newArr[openedAltIndex].categories = editAltCustomCategories;
            newArr[openedAltIndex].influences = editAltCustomInfluence;
        }

        setAlternatives([...newArr]);

        let newData = arrayData;

        newData[rowIndex].elements[eleIndex].alternatives = newArr;

        await updateQuestion(newData).catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        await updateCategories();

        handleCloseEditDialog();
    };

    const handleRemoveAlternative = async () => {
        let newArr = alternatives;

        newArr.splice(openedAltIndex, 1);

        setAlternatives([...newArr]);

        let newData = arrayData;

        newData[rowIndex].elements[eleIndex].alternatives = newArr;

        await updateQuestion(newData).catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        await updateCategories();

        handleCloseEditDialog();
    };

    const handleAddSelect = () => {
        if (altSelects.includes(altChoice)) {
            return;
        }

        if (altChoice === '') {
            return;
        }

        let array = altSelects;

        array.push(altChoice);

        setAltSelects([...array]);
        setAltChoice('');
    };

    const handleRemoveSelect = index => {
        let array = altSelects;

        array.splice(index, 1);

        setAltSelects([...array]);
    };

    const handleAddCustCat = () => {
        if (altCustomCategories.includes(altCustomCatText)) {
            return;
        }

        if (altCustomCatText === '') {
            return;
        }

        let array = altCustomCategories;

        array.push(altCustomCatText);

        setAltCustomCategories([...array]);
        setAltCustomCatText('');
    };

    const handleRemoveCustCat = index => {
        let array = altCustomCategories;

        array.splice(index, 1);

        setAltCustomCategories([...array]);
    };

    const handleAddCustInf = () => {
        if (altCustomInfluence.includes(altCustomInfText)) {
            return;
        }

        if (altCustomInfText === '') {
            return;
        }

        let array = altCustomInfluence;

        array.push(altCustomInfText);

        setAltCustomInfluence([...array]);
        setAltCustomInfText('');
    };

    const handleRemoveCustInf = index => {
        let array = altCustomInfluence;

        array.splice(index, 1);

        setAltCustomInfluence([...array]);
    };

    const handleEditAddSelect = () => {
        if (editAltSelects.includes(editAltChoice)) {
            return;
        }

        if (editAltChoice === '') {
            return;
        }

        let array = editAltSelects;

        array.push(editAltChoice);

        setEditAltSelects([...array]);
        setEditAltChoice('');
    };

    const handleEditRemoveSelect = index => {
        let array = editAltSelects;

        array.splice(index, 1);

        setEditAltSelects([...array]);
    };

    const handleEditAddCustCat = () => {
        if (editAltCustomCategories.includes(editAltCustomCatText)) {
            return;
        }

        if (editAltCustomCatText === '') {
            return;
        }

        let array = editAltCustomCategories;

        array.push(editAltCustomCatText);

        setEditAltCustomCategories([...array]);
        setEditAltCustomCatText('');
    };

    const handleEditRemoveCustCat = index => {
        let array = editAltCustomCategories;

        array.splice(index, 1);

        setEditAltCustomCategories([...array]);
    };

    const handleEditAddCustInf = () => {
        if (editAltCustomInfluence.includes(editAltCustomInfText)) {
            return;
        }

        if (editAltCustomInfText === '') {
            return;
        }

        let array = editAltCustomInfluence;

        array.push(editAltCustomInfText);

        setEditAltCustomInfluence([...array]);
        setEditAltCustomInfText('');
    };

    const handleEditRemoveCustInf = index => {
        let array = editAltCustomInfluence;

        array.splice(index, 1);

        setEditAltCustomInfluence([...array]);
    };

    const handleCloseDialog = () => {
        setAltOrder(1);
        setAltLabel('');
        setAltHasTextField(false);

        setAltType('Text');
        setAltChoice('');
        setAltSelects([]);

        setAltMaxChars(0);
        setAltIsRequired(true);

        setAltCustomCatText('');
        setAltCustomCategories([]);
        setAltCustomInfText('');
        setAltCustomInfluence([]);

        setDialogOpen();
    };

    const handleOpenEditDialog = index => {
        setOpenedAltIndex(index);

        setEditAltId(alternatives[index].id);

        setEditAltOrder(alternatives[index].order);
        setEditAltLabel(alternatives[index].label);

        if (
            data.type === 'MultipleChoice' ||
            data.type === 'RadioGroup' ||
            data.type === 'Select' ||
            data.type === 'MultipleSelect'
        ) {
            setEditAltHasTextField(alternatives[index].hasTextField);
        }

        if (data.type === 'MultipleSelectsWithInputs') {
            setEditAltType(alternatives[index].type);

            if (alternatives[index].type === 'Select') {
                setEditAltSelects(alternatives[index].choices);
            }
        }

        if (data.type === 'MultipleSelectsWithInputs' || data.type === 'AddRows') {
            if (alternatives[index].maxChars) {
                setEditAltMaxChars(alternatives[index].maxChars);
            }

            if (alternatives[index].isRequired !== null) {
                setEditAltIsRequired(alternatives[index].isRequired);
            }
        }

        if (data.type === 'CustomRisks' || data.type === 'CustomOpportunities') {
            if (alternatives[index].categories) {
                setEditAltCustomCategories(alternatives[index].categories);
            }

            if (alternatives[index].influences) {
                setEditAltCustomInfluence(alternatives[index].influences);
            }
        }

        setEditDialogOpen();
    };

    const handleCloseEditDialog = () => {
        setOpenedAltIndex(0);

        setEditAltId('');

        setEditAltOrder(1);
        setEditAltLabel('');
        setEditAltHasTextField(false);

        setEditAltType('Text');
        setEditAltChoice('');
        setEditAltSelects([]);

        setEditAltMaxChars(0);
        setEditAltIsRequired(true);

        setEditAltCustomCatText('');
        setEditAltCustomCategories([]);
        setEditAltCustomInfText('');
        setEditAltCustomInfluence([]);

        setEditDialogOpen();
    };

    const newAltDialog = () => {
        return (
            <Grid>
                <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Legg til ny alternativ</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>Legg til ny alternativ.</DialogContentText>
                        <NumberInput
                            label="Listenummer (blir brukt for rekkefølge)"
                            placeholder="Skriv inn nummer..."
                            value={altOrder}
                            onChange={e => setAltOrder(e.target.value)}
                            min={1}
                        />
                        <InputForm
                            label="Hva er alternativet?"
                            placeholder="Skriv inn alternativet..."
                            value={altLabel}
                            onChange={e => setAltLabel(e.target.value)}
                        />
                        {data.type === 'MultipleChoice' ||
                        data.type === 'RadioGroup' ||
                        data.type === 'Select' ||
                        data.type === 'MultipleSelect' ? (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={altHasTextField}
                                            onChange={e => setAltHasTextField(e.target.checked)}
                                        />
                                    }
                                    label="Skal alternativet ha et tekstfelt som dukker opp?"
                                />
                            </FormGroup>
                        ) : (
                            <></>
                        )}
                        {data.type === 'MultipleSelectsWithInputs' ? (
                            <>
                                <InputLabel id="TypeAlt">Type alternativ</InputLabel>
                                <Select
                                    labelId="AltType"
                                    value={altType}
                                    label="Type alternativ"
                                    onChange={e => setAltType(e.target.value)}
                                >
                                    <MenuItem value={'Text'}>Tekstfelt</MenuItem>
                                    <MenuItem value={'Number'}>Nummerfelt</MenuItem>
                                    <MenuItem value={'Select'}>Select</MenuItem>
                                </Select>
                                {altType === 'Select' ? (
                                    <>
                                        <InputForm
                                            label="Hva er valget?"
                                            placeholder="Skriv inn valget..."
                                            value={altChoice}
                                            onChange={e => setAltChoice(e.target.value)}
                                        />
                                        <Button
                                            variant="contained"
                                            startIcon={<AddCircleOutlineIcon />}
                                            sx={{
                                                padding: '20px',
                                                marginBottom: '20px',
                                                width: '130px',
                                            }}
                                            onClick={handleAddSelect}
                                        >
                                            Legg til valg
                                        </Button>
                                        <Grid>
                                            <b>Valg for select (blir sortert alfabetisk):</b>
                                        </Grid>
                                        {altSelects &&
                                            altSelects.map((code, i) => {
                                                return (
                                                    <Grid key={code}>
                                                        {code}
                                                        <Button
                                                            startIcon={<RemoveCircleOutlineIcon />}
                                                            onClick={() => handleRemoveSelect(i)}
                                                        />
                                                        <br />
                                                    </Grid>
                                                );
                                            })}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        {(data.type === 'MultipleSelectsWithInputs' && altType === 'Text') ||
                        (data.type === 'MultipleSelectsWithInputs' && editAltType === 'Number') ||
                        data.type === 'AddRows' ? (
                            <>
                                <NumberInput
                                    label="Maks tegn (La være 0 hvis det ikke skal bli brukt)"
                                    placeholder="Skriv inn nummer..."
                                    value={altMaxChars}
                                    onChange={e => setAltMaxChars(e.target.value)}
                                    min={0}
                                />
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={altIsRequired}
                                                onChange={e => setAltIsRequired(e.target.checked)}
                                            />
                                        }
                                        label="Er det påkrevd å skrive i feltet?"
                                    />
                                </FormGroup>
                            </>
                        ) : (
                            <></>
                        )}
                        {data.type === 'CustomRisks' || data.type === 'CustomOpportunities' ? (
                            <>
                                <Grid
                                    sx={{
                                        marginBottom: '40px',
                                    }}
                                >
                                    <InputForm
                                        label="Hva er kategorien?"
                                        placeholder="Skriv inn kategori..."
                                        value={altCustomCatText}
                                        onChange={e => setAltCustomCatText(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        startIcon={<AddCircleOutlineIcon />}
                                        sx={{
                                            padding: '20px',
                                            marginBottom: '20px',
                                            width: '180px',
                                        }}
                                        onClick={handleAddCustCat}
                                    >
                                        Legg til kategori
                                    </Button>
                                    <Grid>
                                        <b>Kategorier (blir sortert alfabetisk):</b>
                                    </Grid>
                                    {altCustomCategories &&
                                        altCustomCategories.map((item, i) => {
                                            return (
                                                <Grid key={item}>
                                                    {item}
                                                    <Button
                                                        startIcon={<RemoveCircleOutlineIcon />}
                                                        onClick={() => handleRemoveCustCat(i)}
                                                    />
                                                    <br />
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                                <Grid>
                                    <InputForm
                                        label="Hva er påvirkningen?"
                                        placeholder="Skriv inn påvirkning..."
                                        value={altCustomInfText}
                                        onChange={e => setAltCustomInfText(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        startIcon={<AddCircleOutlineIcon />}
                                        sx={{
                                            padding: '20px',
                                            marginBottom: '20px',
                                            width: '180px',
                                        }}
                                        onClick={handleAddCustInf}
                                    >
                                        Legg til påvirkning
                                    </Button>
                                    <Grid>
                                        <b>Påvirkninger (blir sortert alfabetisk):</b>
                                    </Grid>
                                    {altCustomInfluence &&
                                        altCustomInfluence.map((item, i) => {
                                            return (
                                                <Grid key={item}>
                                                    {item}
                                                    <Button
                                                        startIcon={<RemoveCircleOutlineIcon />}
                                                        onClick={() => handleRemoveCustInf(i)}
                                                    />
                                                    <br />
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            </>
                        ) : (
                            <></>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleAddAlternative}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    const editAltDialog = () => {
        return (
            <Grid>
                <Dialog open={editDialogOpen} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Endre alternativ</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            <b>ID:</b> {editAltId}
                            <br />
                            <br />
                            Endre alternativ.
                        </DialogContentText>
                        <NumberInput
                            label="Listenummer (blir brukt for rekkefølge)"
                            placeholder="Skriv inn nummer..."
                            value={editAltOrder}
                            onChange={e => setEditAltOrder(e.target.value)}
                            min={1}
                        />
                        <InputForm
                            label="Hva er alternativet?"
                            placeholder="Skriv inn alternativet..."
                            value={editAltLabel}
                            onChange={e => setEditAltLabel(e.target.value)}
                        />
                        {data.type === 'MultipleChoice' ||
                        data.type === 'RadioGroup' ||
                        data.type === 'Select' ||
                        data.type === 'MultipleSelect' ? (
                            <>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={editAltHasTextField}
                                                onChange={e => setEditAltHasTextField(e.target.checked)}
                                            />
                                        }
                                        label="Skal alternativet ha et tekstfelt som dukker opp?"
                                    />
                                </FormGroup>
                            </>
                        ) : (
                            <></>
                        )}
                        {data.type === 'MultipleSelectsWithInputs' ? (
                            <>
                                <InputLabel id="TypeAlt">Type alternativ</InputLabel>
                                <Select
                                    labelId="AltType"
                                    value={editAltType}
                                    label="Type alternativ"
                                    onChange={e => setEditAltType(e.target.value)}
                                >
                                    <MenuItem value={'Text'}>Tekstfelt</MenuItem>
                                    <MenuItem value={'Number'}>Nummerfelt</MenuItem>
                                    <MenuItem value={'Select'}>Select</MenuItem>
                                </Select>
                                {editAltType === 'Select' ? (
                                    <>
                                        <InputForm
                                            label="Hva er valget?"
                                            placeholder="Skriv inn valget..."
                                            value={editAltChoice}
                                            onChange={e => setEditAltChoice(e.target.value)}
                                        />
                                        <Button
                                            variant="contained"
                                            startIcon={<AddCircleOutlineIcon />}
                                            sx={{
                                                padding: '20px',
                                                marginBottom: '20px',
                                                width: '130px',
                                            }}
                                            onClick={handleEditAddSelect}
                                        >
                                            Legg til valg
                                        </Button>
                                        <Grid>
                                            <b>Valg for select (blir sortert alfabetisk):</b>
                                        </Grid>
                                        {editAltSelects &&
                                            editAltSelects.map((code, i) => {
                                                return (
                                                    <Grid key={code}>
                                                        {code}
                                                        <Button
                                                            startIcon={<RemoveCircleOutlineIcon />}
                                                            onClick={() => handleEditRemoveSelect(i)}
                                                        />
                                                        <br />
                                                    </Grid>
                                                );
                                            })}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        {(data.type === 'MultipleSelectsWithInputs' && editAltType === 'Text') ||
                        (data.type === 'MultipleSelectsWithInputs' && editAltType === 'Number') ||
                        data.type === 'AddRows' ? (
                            <>
                                <NumberInput
                                    label="Maks tegn (La være 0 hvis det ikke skal bli brukt)"
                                    placeholder="Skriv inn nummer..."
                                    value={editAltMaxChars}
                                    onChange={e => setEditAltMaxChars(e.target.value)}
                                    min={0}
                                />
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={editAltIsRequired}
                                                onChange={e => setEditAltIsRequired(e.target.checked)}
                                            />
                                        }
                                        label="Er det påkrevd å skrive i feltet?"
                                    />
                                </FormGroup>
                            </>
                        ) : (
                            <></>
                        )}
                        {data.type === 'CustomRisks' || data.type === 'CustomOpportunities' ? (
                            <>
                                <Grid
                                    sx={{
                                        marginBottom: '40px',
                                    }}
                                >
                                    <InputForm
                                        label="Hva er kategorien?"
                                        placeholder="Skriv inn kategori..."
                                        value={editAltCustomCatText}
                                        onChange={e => setEditAltCustomCatText(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        startIcon={<AddCircleOutlineIcon />}
                                        sx={{
                                            padding: '20px',
                                            marginBottom: '20px',
                                            width: '180px',
                                        }}
                                        onClick={handleEditAddCustCat}
                                    >
                                        Legg til kategori
                                    </Button>
                                    <Grid>
                                        <b>Kategorier (blir sortert alfabetisk):</b>
                                    </Grid>
                                    {editAltCustomCategories &&
                                        editAltCustomCategories.map((item, i) => {
                                            return (
                                                <Grid key={item}>
                                                    {item}
                                                    <Button
                                                        startIcon={<RemoveCircleOutlineIcon />}
                                                        onClick={() => handleEditRemoveCustCat(i)}
                                                    />
                                                    <br />
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                                <Grid>
                                    <InputForm
                                        label="Hva er påvirkningen?"
                                        placeholder="Skriv inn påvirkning..."
                                        value={editAltCustomInfText}
                                        onChange={e => setEditAltCustomInfText(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        startIcon={<AddCircleOutlineIcon />}
                                        sx={{
                                            padding: '20px',
                                            marginBottom: '20px',
                                            width: '180px',
                                        }}
                                        onClick={handleEditAddCustInf}
                                    >
                                        Legg til påvirkning
                                    </Button>
                                    <Grid>
                                        <b>Påvirkninger (blir sortert alfabetisk):</b>
                                    </Grid>
                                    {editAltCustomInfluence &&
                                        editAltCustomInfluence.map((item, i) => {
                                            return (
                                                <Grid key={item}>
                                                    {item}
                                                    <Button
                                                        startIcon={<RemoveCircleOutlineIcon />}
                                                        onClick={() => handleEditRemoveCustInf(i)}
                                                    />
                                                    <br />
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            </>
                        ) : (
                            <></>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseEditDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleRemoveAlternative}>
                            Slett
                        </Button>
                        <Button variant="primary" onClick={handleSaveAlternative}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    const renderAlternatives = () => {
        if (
            data.type === 'RadioGroup' ||
            data.type === 'MultipleChoice' ||
            data.type === 'Select' ||
            data.type === 'MultipleSelect' ||
            data.type === 'AddRows' ||
            data.type === 'MultipleSelectsWithInputs'
        ) {
            return (
                <Grid>
                    <h3>Alternativer</h3>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ padding: '20px', width: '170px' }}
                        onClick={() => setDialogOpen()}
                    >
                        Legg til ny alternativ{' '}
                    </Button>
                    <List>
                        {alternatives &&
                            alternatives
                                .sort((a, b) => a.order - b.order)
                                .map((alt, index) => {
                                    if (
                                        data.type === 'MultipleChoice' ||
                                        data.type === 'RadioGroup' ||
                                        data.type === 'Select' ||
                                        data.type === 'MultipleSelect'
                                    ) {
                                        return (
                                            <ListItem disablePadding key={alt.id}>
                                                <ListItemButton onClick={() => handleOpenEditDialog(index)}>
                                                    <ListItemIcon>
                                                        <ModeEditOutlineIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            alt.order +
                                                            '. ' +
                                                            alt.label +
                                                            ' (' +
                                                            (alt.hasTextField
                                                                ? 'Har tekstfelt'
                                                                : 'Har ikke tekstfelt') +
                                                            ')'
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    } else {
                                        return (
                                            <ListItem disablePadding key={alt.id}>
                                                <ListItemButton onClick={() => handleOpenEditDialog(index)}>
                                                    <ListItemIcon>
                                                        <ModeEditOutlineIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={alt.order + '. ' + alt.label} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    }
                                })}
                    </List>
                </Grid>
            );
        }
        if (data.type === 'CustomRisks' || data.type === 'CustomOpportunities') {
            return (
                <Grid>
                    <h3>Områder</h3>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ padding: '20px', width: '170px' }}
                        onClick={() => setDialogOpen()}
                    >
                        Legg til ny område{' '}
                    </Button>
                    <List>
                        {alternatives &&
                            alternatives
                                .sort((a, b) => a.order - b.order)
                                .map((alt, index) => {
                                    return (
                                        <ListItem disablePadding key={alt.id}>
                                            <ListItemButton onClick={() => handleOpenEditDialog(index)}>
                                                <ListItemIcon>
                                                    <ModeEditOutlineIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={alt.order + '. ' + alt.label} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                    </List>
                </Grid>
            );
        }
    };

    return (
        <Grid>
            {newAltDialog()}
            {editAltDialog()}
            <ListItem disablePadding key={data.order}>
                <ListItemButton onClick={() => setOpen()}>
                    <ListItemIcon>
                        <ModeEditOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={data.order + '. ' + data.label + ' (' + data.type + ')'} />
                </ListItemButton>
            </ListItem>
            {open ? (
                <>
                    <Divider />
                    <Grid sx={{ margin: '20px' }}>
                        <b>ID:</b> {data.id}
                        <br />
                        <br />
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Grid item xs={4} sx={{ marginRight: '10px' }}>
                                <NumberInput
                                    label="Listenummer (blir brukt for rekkefølge)"
                                    placeholder="Skriv inn nummer..."
                                    min={1}
                                    value={order}
                                    onChange={e => setOrder(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4} sx={{ marginRight: '10px' }}>
                                <InputForm
                                    label="Navn på feltet"
                                    placeholder="Skriv inn hva feltet heter..."
                                    value={label}
                                    onChange={e => setLabel(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel id="Type">Type alternativ</InputLabel>
                                <Select
                                    labelId="Type"
                                    value={type}
                                    label="Type felt"
                                    onChange={e => setType(e.target.value)}
                                >
                                    <MenuItem value={'Text'}>Tekstfelt</MenuItem>
                                    <MenuItem value={'Textfield'}>Stor tekstfelt</MenuItem>
                                    <MenuItem value={'Number'}>Nummerfelt</MenuItem>
                                    <MenuItem value={'RadioGroup'}>Radio Gruppe</MenuItem>
                                    <MenuItem value={'MultipleChoice'}>Flervalg</MenuItem>
                                    <MenuItem value={'DateTime'}>Dato</MenuItem>
                                    <MenuItem value={'Select'}>Select med ett svar</MenuItem>
                                    <MenuItem value={'MultipleSelect'}>Select med flere svar</MenuItem>
                                    <MenuItem value={'MultipleSelectsWithInputs'}>
                                        Flere selects med støtte for input felter
                                    </MenuItem>
                                    <MenuItem value={'AddRows'}>'Legg til' svar med custom felter</MenuItem>
                                    <MenuItem value={'DisablingCheckbox'}>
                                        Svarboks som deaktiverer resten av alternativene
                                    </MenuItem>
                                    <MenuItem value={'CustomProductBrands'}>Custom: Produkt og varemerker</MenuItem>
                                    <MenuItem value={'CustomOpportunities'}>Custom: Klima Muligheter</MenuItem>
                                    <MenuItem value={'CustomRisks'}>Custom: Klima Risiko</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        {data.type === 'Text' || data.type === 'Textfield' || data.type === 'Number' ? (
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                <Grid item xs={6} sx={{ marginRight: '10px' }}>
                                    <NumberInput
                                        label="Maks tegn (La være 0 hvis det ikke skal bli brukt)"
                                        placeholder="Skriv inn nummer..."
                                        value={maxChars}
                                        onChange={e => setMaxChars(e.target.value)}
                                        min={0}
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{ marginTop: '35px' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={isRequired}
                                                    onChange={e => setIsRequired(e.target.checked)}
                                                />
                                            }
                                            label="Er det påkrevd å skrive i feltet?"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {data.type === 'DateTime' ? (
                            <Grid item xs={12} sx={{ display: 'flex', marginBottom: '10px' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isRequired}
                                                onChange={e => setIsRequired(e.target.checked)}
                                            />
                                        }
                                        label="Er det påkrevd å skrive i feltet?"
                                    />
                                </FormGroup>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', width: '200px' }}
                            onClick={handleUpdateElement}
                        >
                            Lagre{' '}
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{
                                padding: '20px',
                                width: '200px',
                                marginLeft: '10px',
                            }}
                            onClick={handleDeleteElement}
                        >
                            Slett{' '}
                        </Button>
                        {renderAlternatives()}
                    </Grid>
                    <Divider />
                </>
            ) : (
                <></>
            )}
        </Grid>
    );
}
