import React from 'react';
import { Grid } from '@mui/material';
import Logos from './components/logo';
import Badge from './components/Badge';
export default function MediaKit() {
    return (
        <Grid item xs={12}>
            <Grid container spacing={4} direction="row">
                <Badge />
                <Logos />
            </Grid>
        </Grid>
    );
}
