import React from 'react';
import { Grid, NativeSelect, InputBase } from '@mui/material';

export default function DropdownSelect(props) {
    const { data, answers, setAnswers, setPointsEarned, setIsAnswered } = props;

    const handleSelectOption = value => {
        let newArr = answers;

        const option = data.find(option => option.id === value);

        if (!option) {
            return;
        }

        if (newArr.length > 0) {
            newArr.splice(0, 1);
            setIsAnswered(false);
            setPointsEarned(0);
        }

        newArr.push(option.id);
        setIsAnswered(true);
        setPointsEarned(option.pointValue);

        setAnswers([...newArr]);
    };

    if (data.length > 0) {
        return (
            <NativeSelect
                value={answers[0] || ''}
                onChange={e => handleSelectOption(e.target.value)}
                input={<InputBase />}
                className="input"
                style={{ marginRight: 30 }}
            >
                <option value="" disabled>
                    Velg et alternativ
                </option>
                {data &&
                    data
                        .sort((a, b) => {
                            return a.order - b.order;
                        })
                        .map(option => {
                            return (
                                <option key={option.id} value={option.id}>
                                    {option.option}
                                </option>
                            );
                        })}
            </NativeSelect>
        );
    } else {
        return <Grid>Spørsmålet har ingen valgalternativer.</Grid>;
    }
}
