import React, { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import useToggle from '../../../../hooks/useToggle';
import useRouter from '../../../../hooks/useRouter';
import { useAuth } from '../../../../auth/useAuth';
import SnackPopup from '../../../../components/SnackPopup';
import { Loading } from '../../../../components/Loading';
import { requestCompanyAccess } from '../termsFunctions';
import { useStyles } from '../TermsStyle';

export default function JoinCompany(props) {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();
    const [popupState, setPopupState] = useToggle();
    const [popupMsg, setPopupMsg] = useState('');
    const [isLoading, setIsLoading] = useToggle();

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState();
    };

    const handleClosePopup = () => {
        setPopupState();
    };

    const handleRequestAccess = () => {
        setIsLoading();

        requestCompanyAccess(auth.userData, props.data.organisasjonsnummer)
            .then(() => {
                router.history.go(0);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to request access to company: ', err);
                }

                showPopup('En feil har oppstått, vennligst prøv igjen eller start prosessen på nytt');
                setIsLoading();
            });
    };

    return (
        <Grid item xs={12}>
            <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
            <Typography variant="h1" style={{ textAlign: 'left', fontSize: '36px' }}>
                {props.data.navn} er allerede registrert hos oss
            </Typography>
            <Typography variant="body1" style={{ padding: '10px 0px 25px 0px' }}>
                Ønsker du å be om tilgang til denne organisasjonen?
            </Typography>
            {isLoading ? (
                <Loading color="primary" />
            ) : (
                <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Button variant="contained" className={classes.fiftyButton} onClick={() => handleRequestAccess()}>
                        Be om tilgang
                    </Button>
                    <Button variant="contained" className={classes.greyButton} onClick={() => props.setView('select')}>
                        Valgt feil organisasjon?
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}
