import React from "react";
import { Grid, Paper, Button, Typography } from "@mui/material";
import Logo from "../../assets/images/Single_Logo.svg";
import { useHistory } from "react-router-dom";
import { useStyles } from "./PublicStyle";
import Bird from "../../assets/images/bird.svg";

export default function PublicRegisterView({ children }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Grid container className={classes.contentDisplay}>
        <Grid item xs={12}>
          <Paper className={classes.navbar}>
            <Grid item className={classes.customGrid}>
              <a href="http://www.ecofric.com">
                <img
                  src={Logo}
                  alt="First letter logo"
                  className={classes.logo}
                ></img>
              </a>
              <Button variant="text" onClick={() => history.goBack()}>
                Gå tilbake
              </Button>
            </Grid>
            <Grid item className={classes.customGrid}>
              <Typography variant="body2" sx={{ marginRight: "20px" }}>
                Allerede kunde?
              </Typography>
              <Button
                variant="contained"
                onClick={() => history.push("/login")}
                sx={{ backgroundColor: "#E0F3F5", fontSize: "1em" }}
              >
                Logg inn
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction="row" sx={{ padding: "40px" }}>
        <Grid item xs={12}>
          <Paper className={classes.regContent}>
            <Grid item xs={5} lg={5} className={classes.regRight}>
              <img
                src={Bird}
                className={classes.regIllustration}
                alt="Illustration of a bird"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={7}
              className={classes.regLeft}
            >
              {children}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.footer}>
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  fontSize: "19px",
                  marginBottom: "10px",
                  marginLeft: "-50px",
                }}
              >
                <b>Navigasjon</b>
              </Typography>
              <Typography variant="body1">
                <a
                  href="https://www.ecofric.com/"
                  style={{ color: "#000", textDecoration: "none" }}
                >
                  Hjem
                </a>
                <br />
                <a
                  href="https://www.ecofric.com/om-oss/kontakt-oss/"
                  style={{ color: "#000", textDecoration: "none" }}
                >
                  Kontakt oss
                </a>
                <br />
                <a
                  href="https://www.ecofric.com/personvern/"
                  style={{ color: "#000", textDecoration: "none" }}
                >
                  Personvernerklæring
                </a>
                <br />
                <a
                  href="https://www.ecofric.com/databehandleravtale/"
                  style={{ color: "#000", textDecoration: "none" }}
                >
                  Databehandleravtale
                </a>
                <br />
                <a
                  href="https://www.ecofric.com/avtalevilkar/"
                  style={{ color: "#000", textDecoration: "none" }}
                >
                  Avtalevilkår
                </a>
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  fontSize: "19px",
                  marginBottom: "10px",
                  marginLeft: "-60px",
                }}
              >
                <b>Ecofric AS</b>
              </Typography>
              <Typography variant="body1">
                Åsveien 2,
                <br />
                3475 Sætre
                <br />
                Org. nr: 924 828 099
                <br />
                hello@ecofric.com
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
