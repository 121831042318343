import React, { useState, useEffect } from 'react';
import {
    Grid,
    InputLabel,
    InputBase,
    IconButton,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import { Add, Delete } from '../../../../../components/Icons';
import { withStyles } from '@mui/styles';

export const CustomRow = withStyles(() => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f3f3f3',
            borderTop: '1px solid #EBEBEB',
            borderBottom: '1px solid #EBEBEB',
        },
    },
}))(TableRow);

export const CustomCell = withStyles(() => ({
    root: {
        padding: '0px 5px',
        borderBottom: '0px',
        fontSize: '14px',
        height: '50px',
    },
    head: {
        padding: '10px 10px 0px 10px',
        fontWeight: 'bold',
        fontSize: '15px',
    },
    body: {
        borderRight: '1px solid #E8E8E8',
        '&:last-child': {
            borderRight: '0px',
        },
    },
}))(TableCell);

export default function MultiRowInput(props) {
    const {
        questionId,
        data,
        points,
        multiRows,
        setMultiRows,
        pointsEarned,
        setPointsEarned,
        setIsAnswered,
        minChars,
        maxChars,
    } = props;

    const [inputs, setInputs] = useState([]);
    const [inputCheck, setInputCheck] = useState(false);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            let newArr = [];

            if (data) {
                for (const option of data.sort((a, b) => {
                    return a.order - b.order;
                })) {
                    let newObj = {
                        id: option.id,
                        input: '',
                        name: option.option,
                        order: option.order,
                    };

                    newArr.push(newObj);
                }
            }

            setInputs([...newArr]);
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            let reqInputChecks = 0;

            for (const item of inputs) {
                if (item.input.length !== 0 && item.input.length >= minChars) {
                    reqInputChecks++;
                }
            }

            if (reqInputChecks === inputs.length) {
                setInputCheck(false);
            } else {
                setInputCheck(true);
            }
        }

        return () => {
            isMounted = true;
        };
    }, [inputs]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInput = (option, value) => {
        let newArr = inputs;
        let index = newArr.findIndex(object => {
            return object.id === option.id;
        });

        if (index !== -1) {
            newArr[index].input = value;
        }

        setInputs([...newArr]);
    };

    const handleAddField = () => {
        let newArr = multiRows;

        let newDataArr = [];

        for (const input of inputs) {
            let newObj = {
                name: input.name,
                value: input.input,
                order: input.order,
            };

            input.input = '';

            newDataArr.push(newObj);
        }

        newArr.push(newDataArr);

        if (pointsEarned === 0) {
            setPointsEarned(points);
        }

        setInputCheck(true);

        if (
            questionId === 'c2e6eaf2-4d17-4a78-9715-0ec5b527005a' ||
            questionId === '9db2d018-7d5f-4dba-b934-e38959207584'
        ) {
            if (multiRows.length >= 5) {
                setIsAnswered(true);
            }
        } else {
            setIsAnswered(true);
        }

        setMultiRows([...newArr]);
    };

    const handleDeleteField = index => {
        let newArr = multiRows;

        newArr.splice(index, 1);

        if (
            questionId === 'c2e6eaf2-4d17-4a78-9715-0ec5b527005a' ||
            questionId === '9db2d018-7d5f-4dba-b934-e38959207584'
        ) {
            if (newArr.length < 5) {
                setPointsEarned(0);
                setIsAnswered(false);
            }
        } else if (newArr.length === 0) {
            setPointsEarned(0);
            setIsAnswered(false);
        }

        setMultiRows([...newArr]);
    };

    return (
        <Grid item xs={12}>
            <Grid item xs={12} sx={{ display: 'flex' }}>
                {data &&
                    inputs.length !== 0 &&
                    data
                        .sort((a, b) => {
                            return a.order - b.order;
                        })
                        .map(option => {
                            let inputData = inputs.find(input => {
                                return input.id === option.id;
                            });

                            return (
                                <Grid item xs={12} sx={{ marginRight: '10px' }} key={option.id}>
                                    <InputLabel id={props.id}>{option.option}</InputLabel>
                                    <InputBase
                                        id={option.id}
                                        type="text"
                                        name={option.option}
                                        value={inputData.input}
                                        onChange={e => handleInput(option, e.target.value)}
                                        placeholder="Skriv her..."
                                        inputProps={{ maxLength: maxChars }}
                                    />
                                </Grid>
                            );
                        })}
                <Grid item sx={{ marginTop: '32px' }}>
                    <Button variant="contained" onClick={handleAddField} sx={{ width: '130px' }} disabled={inputCheck}>
                        {Add}Legg til
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <CustomRow>
                                {data &&
                                    data
                                        .sort((a, b) => {
                                            return a.order - b.order;
                                        })
                                        .map(option => {
                                            return (
                                                <CustomCell
                                                    align="left"
                                                    key={option.id}
                                                    sx={{ borderBottom: '2px solid #FDDFB0 !important' }}
                                                >
                                                    {option.option}
                                                </CustomCell>
                                            );
                                        })}
                                <CustomCell align="center" sx={{ borderBottom: '2px solid #FDDFB0 !important' }}>
                                    Slett
                                </CustomCell>
                            </CustomRow>
                        </TableHead>
                        <TableBody>
                            {multiRows && multiRows.length !== 0 ? (
                                multiRows &&
                                multiRows.map((response, index) => {
                                    return (
                                        <CustomRow sx={{ borderTop: '1px solid #EBEBEB' }} key={index}>
                                            {response &&
                                                response
                                                    .sort((a, b) => {
                                                        return a.order - b.order;
                                                    })
                                                    .map(resp => {
                                                        return (
                                                            <CustomCell
                                                                align="left"
                                                                key={resp.order}
                                                                sx={{ padding: '0px 10px 0px 10px !important' }}
                                                            >
                                                                {resp.value}
                                                            </CustomCell>
                                                        );
                                                    })}
                                            <CustomCell align="center">
                                                <IconButton onClick={() => handleDeleteField(index)}>
                                                    {Delete}
                                                </IconButton>
                                            </CustomCell>
                                        </CustomRow>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
