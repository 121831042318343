import React, { useState, useEffect } from 'react';
import {
    InputLabel,
    Grid,
    NativeSelect,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    InputBase,
    IconButton,
} from '@mui/material';
import { Delete } from '../../../../../components/Icons';
import { withStyles } from '@mui/styles';

export const CustomRow = withStyles(() => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f3f3f3',
            borderTop: '1px solid #EBEBEB',
            borderBottom: '1px solid #EBEBEB',
        },
    },
}))(TableRow);

export const CustomCell = withStyles(() => ({
    root: {
        padding: '0px 5px',
        borderBottom: '0px',
    },
    head: {
        padding: '10px 10px 0px 10px',
        fontWeight: 'bold',
    },
    body: {
        borderRight: '1px solid #E8E8E8',
        '&:last-child': {
            borderRight: '0px',
        },
    },
}))(TableCell);

export default function MultipleSelect(props) {
    const { data, input, setInput, reportIsCompleted } = props;

    const [value, setValue] = useState('');
    const [textValue, setTextValue] = useState('');
    const [hasTextField, setHasTextField] = useState(false);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            let alt = data.alternatives.find(alt => alt.id === value);

            if (alt) {
                if (alt.hasTextField) {
                    setHasTextField(true);
                } else {
                    setHasTextField(false);
                }
            }
        }

        return () => {
            isMounted = true;
        };
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAdd = () => {
        if (value === '') {
            return;
        }

        let newArr = [];

        if (input[data.id]) {
            newArr = input[data.id];

            let newObj = {
                id: value,
                input: textValue,
            };

            newArr.push(newObj);
        } else {
            let newObj = {
                id: value,
                input: textValue,
            };

            newArr.push(newObj);
        }

        setInput({
            ...input,
            [data.id]: newArr,
        });

        setValue('');
        setTextValue('');
        setHasTextField(false);
    };

    const handleRemove = index => {
        let newArr = [];

        newArr = input[data.id];

        newArr.splice(index, 1);

        setInput({
            ...input,
            [data.id]: newArr,
        });
    };

    return (
        <Grid sx={{ marginBottom: '30px' }}>
            {!reportIsCompleted ? (
                <>
                    <InputLabel>{data.label}</InputLabel>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        <NativeSelect input={<InputBase />} value={value} onChange={e => setValue(e.target.value)}>
                            <option value="" disabled>
                                Velg ett alternativ
                            </option>
                            {data.alternatives &&
                                data.alternatives
                                    .sort((a, b) => {
                                        return a.order - b.order;
                                    })
                                    .map(alt => {
                                        return (
                                            <option key={alt.id} value={alt.id}>
                                                {alt.label}
                                            </option>
                                        );
                                    })}
                        </NativeSelect>
                        {!hasTextField ? (
                            <Button
                                variant="contained"
                                sx={{ marginLeft: '10px' }}
                                onClick={handleAdd}
                                disabled={value === '' ? true : false}
                            >
                                Legg til
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    {hasTextField ? (
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <InputBase
                                sx={{ padding: '5px' }}
                                type="text"
                                className="input"
                                placeholder="Skriv her..."
                                value={textValue}
                                onChange={e => setTextValue(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                sx={{ marginLeft: '10px' }}
                                onClick={handleAdd}
                                disabled={textValue === '' ? true : false}
                            >
                                Legg til
                            </Button>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <CustomRow>
                                <CustomCell sx={{ width: '90%' }}>{data.label}</CustomCell>
                                {!reportIsCompleted ? <CustomCell align="center">Slett</CustomCell> : <></>}
                            </CustomRow>
                        </TableHead>
                        <TableBody>
                            {input[data.id] && input[data.id].length > 0 ? (
                                input[data.id].map((item, index) => {
                                    let labelIndex = data.alternatives.findIndex(alt => alt.id === item.id);

                                    return (
                                        <CustomRow key={index} sx={{ borderTop: '1px solid #EBEBEB' }}>
                                            <CustomCell>
                                                {item.input.length > 0
                                                    ? item.input
                                                    : data.alternatives[labelIndex].label}
                                            </CustomCell>
                                            {!reportIsCompleted ? (
                                                <CustomCell align="center">
                                                    <IconButton onClick={() => handleRemove(index)}>
                                                        {Delete}
                                                    </IconButton>
                                                </CustomCell>
                                            ) : (
                                                <></>
                                            )}
                                        </CustomRow>
                                    );
                                })
                            ) : (
                                <CustomRow sx={{ borderTop: '1px solid #EBEBEB' }}>
                                    <CustomCell align="left" sx={{ padding: '10px 20px 10px 20px !important' }}>
                                        Ingen svar lagt til.
                                    </CustomCell>
                                </CustomRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
