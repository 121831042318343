import React from 'react';
import { InputBase, Grid, InputLabel } from '@mui/material';

export default function InputForm(props) {
    return (
        <Grid>
            <InputLabel id={props.id}>{props.label}</InputLabel>
            <InputBase
                id={props.id}
                type="text"
                className="input"
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
            />
        </Grid>
    );
}

export function TextAreaInput(props) {
    return (
        <Grid>
            <InputLabel id={props.id}>{props.label}</InputLabel>
            <InputBase
                multiline
                rows={props.rows}
                id={props.id}
                type="text"
                className="input"
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
            />
        </Grid>
    );
}

export function NumberInput(props) {
    return (
        <Grid>
            <InputLabel id={props.id}>{props.label}</InputLabel>
            <InputBase
                id={props.id}
                type="number"
                className="input"
                inputProps={{ min: props.min, max: props.max }}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
            />
        </Grid>
    );
}
