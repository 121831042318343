import React from 'react';
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

export default function DisablingCheckbox(props) {
    const { data, input, setInput, reportIsCompleted } = props;

    const handleChange = () => {
        if (input[data.id]) {
            setInput({
                ...input,
                [data.id]: !input[data.id],
            });
        } else {
            setInput({
                ...input,
                [data.id]: true,
            });
        }
    };

    return (
        <Grid>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={input[data.id] || false} disabled={reportIsCompleted} />}
                    label={data.label}
                    onChange={handleChange}
                    readOnly={reportIsCompleted}
                />
            </FormGroup>
        </Grid>
    );
}
