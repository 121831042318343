import React, { useState } from 'react';
import { Grid, Paper, Button, Typography } from '@mui/material';
import { List, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { NumberInput } from '../../../../../components/InputForm';
import SubcategoryRowView from './SubcategoryRowView';

import { API, graphqlOperation } from 'aws-amplify';
import { createCertSubcategory } from '../../../../../graphql/mutations';

export default function CategoryListView(props) {
    const [open, setOpen] = useToggle();

    const [categoryId, setCategoryId] = useState('');
    const [name, setName] = useState('');
    const [order, setOrder] = useState(1);

    const saveNewSubcategory = async () => {
        return new Promise(async (resolve, reject) => {
            // Input data for creating a new CompanyData object
            let inputData = {
                categoryId: categoryId,
                name: name,
                order: order,
            };

            await API.graphql(graphqlOperation(createCertSubcategory, { input: inputData }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new subcategory: ', data.data.createCertSubcategory);
                    }

                    resolve(data.data.createCertSubcategory);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Failed to create new subcategory: ', err);
                    }

                    reject(err);
                });
        });
    };

    const handleSaveNewSubcategory = async () => {
        if (name === '') {
            props.showPopup('Navn må være fylt ut!');
            return;
        }

        await saveNewSubcategory().catch(() => {
            props.showPopup('En feil oppsto ved lagring av underkategori');
            return;
        });

        props.showPopup('Underkategorien har blitt lagret');

        await props.updateCategories();

        setName('');
        setOrder(1);
        setOpen();
    };

    const handleOpenDialog = catId => {
        setCategoryId(catId);
        setName('');
        setOrder(1);
        setOpen();
    };

    const newSubcategoryDialog = () => {
        return (
            <Grid>
                <Dialog open={open} onClose={setOpen} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Lag ny underkategori</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Lag en helt ny underkategori til valgt hovedkategori.
                        </DialogContentText>
                        <InputForm
                            label="Navn på underkategori"
                            placeholder="Skriv inn navn på underkategorien"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <NumberInput
                            label="Rekkefølge (lavere nummer betyr høyere på listen)"
                            placeholder="Skriv inn nummer på rekkefølge"
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={setOpen}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleSaveNewSubcategory}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid item xs={12}>
            {newSubcategoryDialog()}
            <h1>Liste over lagret underkategorier</h1>
            {props.categories.length === 0 ? (
                <h2>Det er ingen lagret kategorier.</h2>
            ) : (
                props.categories &&
                props.categories.map(category => {
                    return (
                        <Grid key={category.id}>
                            <Paper
                                sx={{
                                    backgroundColor: '#FBFBFB',
                                    padding: '20px',
                                    marginBottom: '20px',
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        textAlign: 'left',
                                        margin: '10px 0px',
                                        fontSize: '30px',
                                    }}
                                >
                                    {category.name}
                                </Typography>
                                <Button
                                    variant="contained"
                                    startIcon={<AddCircleOutlineIcon />}
                                    sx={{ padding: '20px', marginBottom: '20px', width: '200px' }}
                                    onClick={() => handleOpenDialog(category.id)}
                                >
                                    Legg til ny underkategori{' '}
                                </Button>
                                <List>
                                    {category.subcategories.items && category.subcategories.items.length === 0 ? (
                                        <Grid sx={{ padding: '10px' }}>
                                            Det er ingen lagret underkategorier for {category.name}.
                                        </Grid>
                                    ) : (
                                        category.subcategories.items &&
                                        category.subcategories.items
                                            .sort((a, b) => {
                                                return a.order - b.order;
                                            })
                                            .map(subcategory => {
                                                return (
                                                    <SubcategoryRowView
                                                        key={subcategory.id}
                                                        showPopup={props.showPopup}
                                                        data={subcategory}
                                                        update={props.updateCategories}
                                                    />
                                                );
                                            })
                                    )}
                                </List>
                            </Paper>
                        </Grid>
                    );
                })
            )}
        </Grid>
    );
}
