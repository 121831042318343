import React, { useState, useEffect } from "react";
import { useAuth } from "../../../auth/useAuth";
import { ContentLoading } from "../../../components/Loading";
import {
  fetchFilteredCategories,
  getAllResponses,
} from "../../private/Certification/certificationFunctions";
import DetailReport from "./components/DetailReport";
import OverviewReport from "./components/OverviewReport";
import ClimateAccounting from "./components/ClimateAccounting";
import NSRSReport from "./components/NSRSReport";

export default function Documents(props) {
  const auth = useAuth();

  const [certData, setCertData] = useState(null);
  const [respData, setRespData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // Runs on render

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      fetchFilteredCategories(auth).then((fetchedCertData) => {
        setCertData(fetchedCertData);

        getAllResponses(auth.companyData.companyId).then((fetchedRespData) => {
          setRespData(fetchedRespData);

          setIsLoading(false);
        });
      });
    }

    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <ContentLoading />;
  } else if (props.state === "overview") {
    return <OverviewReport />;
  } else if (props.state === "detail") {
    return <DetailReport certData={certData} respData={respData} />;
  } else if (props.state === "climate") {
    return <ClimateAccounting />;
  } else if (props.state === "NSRS") {
    return <NSRSReport />;
  } else {
    return <OverviewReport />;
  }
}
