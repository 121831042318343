import React, { useState, useEffect } from "react";
import { Divider, Grid } from "@mui/material";
import { ContentLoading } from "../../../components/Loading";
import Graphs from "./components/Graphs";
import CompanyInfoModule from "./components/CompanyInfoModule";
import SocialModule from "./components/SocialModule";
import EnvironmentModule from "./components/EnvironmentModule";
import EconomyModule from "./components/EconomyModule";
import IndustrySpecific from "./components/IndustrySpecific";
import FinishCertModule from "./components/FinishCertModule";
import CompleteCertification from "./components/CompleteCertification";
import { useAuth } from "../../../auth/useAuth";
import useToggle from "../../../hooks/useToggle";
import {
  fetchFilteredCategories,
  getAllResponses,
} from "./certificationFunctions";

export default function Certification() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useToggle(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasAnsweredAll, setHasAnsweredAll] = useState(false);
  const [hasAnsweredReqCompany, setHasAnsweredReqCompany] = useState(false);
  const [categories, setCategories] = useState([]);
  const [responses, setResponses] = useState([]);
  const [remainingQuestions, setRemainingQuestions] = useState(0);
  const [remainingReq, setRemainingReq] = useState(0);
  const [percentComplete, setPercentComplete] = useState(0);
  const [ecoTotalPoints, setEcoTotalPoints] = useState(0);
  const [socialTotalPoints, setSocialTotalPoints] = useState(0);
  const [envTotalPoints, setEnvTotalPoints] = useState(0);
  const [ecoEarnedPoints, setEcoEarnedPoints] = useState(0);
  const [socialEarnedPoints, setSocialEarnedPoints] = useState(0);
  const [envEearnedPoints, setEnvEarnedPoints] = useState(0);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      fetchFilteredCategories(auth).then((catData) => {
        setCategories(catData);

        getAllResponses(auth.companyData.companyId).then((responseData) => {
          setResponses(responseData);

          setupAndCheckCert(catData, responseData).then((bool) => {
            if (bool) {
              if (process.env.NODE_ENV === "development") {
                console.log("Company has met all requirements!");
              }

              setHasAnsweredAll(true);
            } else {
              if (process.env.NODE_ENV === "development") {
                console.log("Company has not met all requirements.");
              }

              setHasAnsweredAll(false);
            }

            setIsLoading();
          });
        });
      });
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setupAndCheckCert = async (catData, respData) => {
    return new Promise(async (resolve) => {
      let totalQuestionsAmount = 0;
      let reqQuestionsAmount = 0;

      let reqCompanyQuestions = 0;
      let answeredReqCompanyQuestions = 0;

      let requiredQuestions = 0;
      let totalReqAnswered = 0;
      let totalQuestionsAnswered = 0;

      let totalEcoScore = 0;
      let totalSocialScore = 0;
      let totalEnvScore = 0;

      let economyPointsEarned = 0;
      let socialPointsEarned = 0;
      let envPointsEarned = 0;

      // Iterate through the questions
      for (const category of catData) {
        for (const subcategory of category.subcategories.items) {
          totalQuestionsAmount =
            totalQuestionsAmount + subcategory.questions.items.length;

          for (const question of subcategory.questions.items) {
            if (question.required) {
              // Add all the required questions together
              requiredQuestions = requiredQuestions + 1;
              reqQuestionsAmount = reqQuestionsAmount + 1;

              if (category.id === "company") {
                reqCompanyQuestions = reqCompanyQuestions + 1;
              }
            }

            if (category.id === "economy") {
              totalEcoScore = totalEcoScore + question.pointValue;
            } else if (category.id === "social") {
              totalSocialScore = totalSocialScore + question.pointValue;
            } else if (category.id === "environment") {
              totalEnvScore = totalEnvScore + question.pointValue;
            }

            let index = respData.findIndex(
              (response) => response.questionId === question.id
            );

            // If a response exists and it is answered, add it to the total count
            if (index !== -1) {
              if (respData[index].isAnswered) {
                if (question.required) {
                  reqQuestionsAmount = reqQuestionsAmount - 1;
                  totalReqAnswered = totalReqAnswered + 1;

                  if (category.id === "company") {
                    answeredReqCompanyQuestions =
                      answeredReqCompanyQuestions + 1;
                  }
                }

                if (respData[index].pointsEarned > question.pointValue) {
                  if (respData[index].categoryId === "economy") {
                    economyPointsEarned =
                      economyPointsEarned + question.pointValue;
                  } else if (respData[index].categoryId === "social") {
                    socialPointsEarned =
                      socialPointsEarned + question.pointValue;
                  } else if (respData[index].categoryId === "environment") {
                    envPointsEarned = envPointsEarned + question.pointValue;
                  }
                } else {
                  if (respData[index].categoryId === "economy") {
                    economyPointsEarned =
                      economyPointsEarned + respData[index].pointsEarned;
                  } else if (respData[index].categoryId === "social") {
                    socialPointsEarned =
                      socialPointsEarned + respData[index].pointsEarned;
                  } else if (respData[index].categoryId === "environment") {
                    envPointsEarned =
                      envPointsEarned + respData[index].pointsEarned;
                  }
                }

                totalQuestionsAnswered = totalQuestionsAnswered + 1;
              }
            }
          }
        }
      }

      let totalQuestionsPercent = 100 / totalQuestionsAmount;

      setRemainingReq(reqQuestionsAmount);
      setRemainingQuestions(totalQuestionsAmount - totalQuestionsAnswered);
      setPercentComplete(
        Math.ceil(totalQuestionsPercent * totalQuestionsAnswered)
      );

      setEcoTotalPoints(totalEcoScore);
      setSocialTotalPoints(totalSocialScore);
      setEnvTotalPoints(totalEnvScore);

      setEcoEarnedPoints(economyPointsEarned);
      setSocialEarnedPoints(socialPointsEarned);
      setEnvEarnedPoints(envPointsEarned);

      if (
        auth.companyData.companyData.certData.items[0].status !== "Completed"
      ) {
        if (answeredReqCompanyQuestions === reqCompanyQuestions) {
          setHasAnsweredReqCompany(true);
        }
      } else {
        setHasAnsweredReqCompany(true);
      }

      // If the answered questions is equal to the amount of required questions, then company has answered all of them
      if (totalReqAnswered === requiredQuestions) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  if (isLoading) {
    return <ContentLoading />;
  } else {
    return (
      <Grid container spacing={3}>
        <CompleteCertification
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          responses={responses}
        />
        <Grid item xs={12}>
          <Graphs
            ecoTotalPoints={ecoTotalPoints}
            socialTotalPoints={socialTotalPoints}
            envTotalPoints={envTotalPoints}
            ecoEarnedPoints={ecoEarnedPoints}
            socialEarnedPoints={socialEarnedPoints}
            envEearnedPoints={envEearnedPoints}
            isActive={hasAnsweredReqCompany}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {auth.companyData.companyData.certData.items[0].status ===
        "Completed" ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <FinishCertModule
              handleOpenDialog={handleOpenDialog}
              isCompleted={hasAnsweredAll}
              remainingQuestions={remainingQuestions}
              remainingObligatory={remainingReq}
              percentComplete={percentComplete}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <CompanyInfoModule
            data={categories.find((category) => {
              return category.id === "company";
            })}
            responses={responses.filter(
              (response) => response.categoryId === "company"
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <EconomyModule
            data={categories.find((category) => {
              return category.id === "economy";
            })}
            responses={responses.filter(
              (response) => response.categoryId === "economy"
            )}
            isActive={hasAnsweredReqCompany}
          />
        </Grid>
        <Grid item xs={6}>
          <SocialModule
            data={categories.find((category) => {
              return category.id === "social";
            })}
            responses={responses.filter(
              (response) => response.categoryId === "social"
            )}
            isActive={hasAnsweredReqCompany}
          />
        </Grid>
        <Grid item xs={6}>
          <EnvironmentModule
            data={categories.find((category) => {
              return category.id === "environment";
            })}
            responses={responses.filter(
              (response) => response.categoryId === "environment"
            )}
            isActive={hasAnsweredReqCompany}
          />
        </Grid>
        <Grid item xs={6}>
          <IndustrySpecific />
        </Grid>
      </Grid>
    );
  }
}
