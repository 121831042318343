import React from 'react';
import { useAuth } from '../../auth/useAuth';
import useToggle from '../../hooks/useToggle';
import {
    Hidden,
    Drawer,
    Toolbar,
    Grid,
    Avatar,
    Typography,
    MenuList,
    MenuItem,
    Divider,
    AppBar,
    ListItem,
    List,
    IconButton,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
    Home,
    Profile,
    ArrowRight,
    Hamburger,
    Facebook,
    Linkedin,
    Instagram,
    Certify,
    Admin,
    Document,
    Media,
    Climate,
    ChoresIcon,
    NSRSIcon,
    TemplatesIcon,
} from '../Icons';
import { useStyles } from './PrivateStyles';
import { adminCheck } from '../../helpers/globalValues';
import Notifications from './Notifications';
import amplitude from 'amplitude-js';

export default function Sidebar() {
    const classes = useStyles();
    const auth = useAuth();
    const [mobileOpen, setMobileOpen] = useToggle();

    const handleDrawerToggle = () => {
        setMobileOpen();
    };

    const handleAmplitudeClick = url => {
        if (process.env.NODE_ENV !== 'development') {
            amplitude.getInstance().logEvent('sidebar_clicked', { url: url });
        }
    };

    const adminMenuItem = () => {
        if (!adminCheck) {
            return (
                <MenuItem component={NavLink} to="/admin" exact activeClassName={classes.active}>
                    {Admin}Admin
                </MenuItem>
            );
        } else if (adminCheck && auth.companyData && auth.companyData.orgNumber === '924828099') {
            return (
                <MenuItem component={NavLink} to="/admin" exact activeClassName={classes.active}>
                    {Admin}Admin
                </MenuItem>
            );
        } else {
            return <Grid></Grid>;
        }
    };

    return (
        <Grid>
            <Hidden smDown>
                <Drawer variant="permanent" anchor="left">
                    <Grid item className={classes.centerGrid}>
                        <Avatar component={NavLink} to="/profile" exact className={classes.sidebarAvatar}>
                            {JSON.parse(auth.userData.name)
                                .fullName.match(/\b\w/g)
                                .join('')}
                        </Avatar>
                        <Typography variant="h1" className={classes.title}>
                            Hei {JSON.parse(auth.userData.name).name}!
                            <Notifications />
                        </Typography>
                        <Typography variant="h4" sx={{ textAlign: 'center', fontSize: '17px' }}>
                            Fra {auth.companyData.name}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ height: '100%', padding: '0px 20px' }}>
                        <MenuList>
                            <MenuItem
                                component={NavLink}
                                to="/"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('home')}
                            >
                                {Home}Hjem
                            </MenuItem>
                            <MenuItem
                                component={NavLink}
                                to="/declaration"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('declaration')}
                            >
                                {Certify}Egenerklæring
                            </MenuItem>
                            <MenuItem
                                component={NavLink}
                                to="/climate"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('climate')}
                            >
                                {Climate}
                                Klimaregnskap
                            </MenuItem>
                            <MenuItem
                                component={NavLink}
                                to="/nsrs"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('nsrs')}
                            >
                                {NSRSIcon}
                                NSRS
                            </MenuItem>
                            <MenuItem
                                component={NavLink}
                                to="/activities"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('activities')}
                            >
                                {ChoresIcon}Aktiviteter
                            </MenuItem>
                            <MenuItem
                                component={NavLink}
                                to="/documents"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('documents')}
                            >
                                {Document}Dokumentasjon og eksport
                            </MenuItem>
                            <MenuItem
                                component={NavLink}
                                to="/templates"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('templates')}
                            >
                                {TemplatesIcon}Maler og verktøy
                            </MenuItem>
                            <MenuItem
                                component={NavLink}
                                to="/mediakit"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('mediakit')}
                            >
                                {Media}Markedsføring
                            </MenuItem>
                        </MenuList>
                    </Grid>
                    <Grid item className={classes.botMenu}>
                        <MenuList>
                            <MenuItem
                                component={NavLink}
                                to="/profile"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('profile')}
                            >
                                {Profile}Profil
                            </MenuItem>
                            {adminMenuItem()}
                            <Divider />
                            <MenuItem
                                component={NavLink}
                                to="/contact"
                                exact
                                activeClassName={classes.active}
                                onClick={() => handleAmplitudeClick('contact')}
                            >
                                Kontakt oss
                            </MenuItem>
                            <MenuItem component={NavLink} to="/" variant="text" onClick={() => auth.signOut()}>
                                Logg ut{ArrowRight}
                            </MenuItem>
                        </MenuList>
                    </Grid>
                </Drawer>
            </Hidden>
            <Hidden smUp>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton onClick={() => handleDrawerToggle(true)}>{Hamburger}</IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onClick={() => handleDrawerToggle(false)}
                    onClose={() => handleDrawerToggle(false)}
                >
                    <MenuList>
                        <MenuItem component={NavLink} to="/" exact onClick={() => handleAmplitudeClick('home')}>
                            {Home}Hjem
                        </MenuItem>
                        <MenuItem
                            component={NavLink}
                            to="/declaration"
                            exact
                            onClick={() => handleAmplitudeClick('declaration')}
                        >
                            {Certify}Egenerklæring
                        </MenuItem>
                        <MenuItem
                            component={NavLink}
                            to="/climate"
                            exact
                            onClick={() => handleAmplitudeClick('climate')}
                        >
                            {Climate}
                            Klimaregnskap
                        </MenuItem>
                        <MenuItem component={NavLink} to="/nsrs" exact onClick={() => handleAmplitudeClick('nsrs')}>
                            {NSRSIcon}
                            NSRS
                        </MenuItem>
                        <MenuItem
                            component={NavLink}
                            to="/activities"
                            exact
                            onClick={() => handleAmplitudeClick('activities')}
                        >
                            Aktiviteter
                        </MenuItem>
                        <MenuItem
                            component={NavLink}
                            to="/documents"
                            exact
                            onClick={() => handleAmplitudeClick('documents')}
                        >
                            {Document}Dokumentasjon og eksport
                        </MenuItem>
                        <MenuItem
                            component={NavLink}
                            to="/mediakit"
                            exact
                            onClick={() => handleAmplitudeClick('mediakit')}
                        >
                            {Media}Markedsføring
                        </MenuItem>
                        <MenuItem
                            component={NavLink}
                            to="/profile"
                            exact
                            onClick={() => handleAmplitudeClick('profile')}
                        >
                            {Profile}Profil
                        </MenuItem>
                    </MenuList>
                    <List>
                        <ListItem onClick={() => handleAmplitudeClick('facebook')}>
                            <a href="https://www.facebook.com/Ecofric" rel="noopener noreferrer" target="_blank">
                                {Facebook}
                            </a>
                        </ListItem>
                        <ListItem onClick={() => handleAmplitudeClick('linkedin')}>
                            <a
                                href="https://www.linkedin.com/company/ecofric/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {Linkedin}
                            </a>
                        </ListItem>
                        <ListItem onClick={() => handleAmplitudeClick('instagram')}>
                            <a href="https://www.instagram.com/ecofric/" rel="noopener noreferrer" target="_blank">
                                {Instagram}
                            </a>
                        </ListItem>
                    </List>
                </Drawer>
            </Hidden>
        </Grid>
    );
}
