import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Button,
  Typography,
  Avatar,
  InputLabel,
  InputBase,
} from "@mui/material";
import {
  Arrow,
  UserHeader,
  MailUser,
  PhoneUser,
} from "../../../../components/Icons";
import { useStyles } from "../ProfileStyles";
import { useHistory } from "react-router";
import useToggle from "../../../../hooks/useToggle";
import { useAuth } from "../../../../auth/useAuth";
import { updateUserInfo } from "../profileFunctions";
import getDateFromTimestamp from "../../../../helpers/getDateFromTimestamp";

export default function UserView() {
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();

  const [editProfile, setEditProfile] = useToggle();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      setName(JSON.parse(auth.userData.name).name);
      setLastName(JSON.parse(auth.userData.name).lastName);
      setPosition(auth.userData.position);
    }

    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditProfile = () => {
    setName(JSON.parse(auth.userData.name).name);
    setLastName(JSON.parse(auth.userData.name).lastName);
    setPosition(auth.userData.position);

    setEditProfile();
  };

  const handleUpdateUserProfile = async () => {
    let nameObj = {
      name: name,
      lastName: lastName,
      fullName: name + " " + lastName,
    };

    let inputData = {
      id: auth.userData.id,
      position: position,
      name: JSON.stringify(nameObj),
    };

    await updateUserInfo(inputData).then(async () => {
      await auth.fetchUserInfo(auth.userData.id);
      setEditProfile();
    });
  };

  return (
    <Paper style={{ marginBottom: "20px" }}>
      <Grid item xs={12} className={classes.pageHeader}>
        <Button
          type="text"
          startIcon={Arrow}
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          Gå tilbake
        </Button>
        <Typography variant="h2" className={classes.profileheader}>
          {UserHeader}Profil
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ display: "flex" }} className={classes.spacer}>
        <Grid item xs={4} sm={4} md={4} lg={3} className={classes.rightSection}>
          <Avatar className={classes.profileImage}>
            {JSON.parse(auth.userData.name)
              .fullName.match(/\b\w/g)
              .join("")}
          </Avatar>
          <Typography
            variant="body1"
            style={{ fontSize: "15px", marginBottom: 5 }}
          >
            {MailUser} {auth.userData.email}
          </Typography>
          <Typography variant="body1" style={{ fontSize: "15px" }}>
            {PhoneUser} {auth.userData.phoneNumber}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={9} className={classes.leftSection}>
          {editProfile ? (
            <Grid item style={{ marginTop: 20 }}>
              <Typography
                variant="h2"
                style={{ justifyContent: "left", marginBottom: 10 }}
              >
                Rediger bruker info
              </Typography>
              <form autoComplete="on">
                <Grid item className={classes.editfield}>
                  <InputLabel className={classes.editlabel}>Navn:</InputLabel>
                  <InputBase
                    id="username"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item className={classes.editfield}>
                  <InputLabel className={classes.editlabel}>
                    Etternavn:
                  </InputLabel>
                  <InputBase
                    id="userfamilyname"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item className={classes.editfield}>
                  <InputLabel className={classes.editlabel}>
                    Stilling:
                  </InputLabel>
                  <InputBase
                    id="userposition"
                    type="text"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </Grid>
              </form>
              <Button
                variant="contained"
                className={classes.editbutton}
                onClick={handleUpdateUserProfile}
              >
                Lagre
              </Button>
              <Button
                variant="contained"
                className={classes.editbutton2}
                onClick={handleEditProfile}
              >
                Avbryt
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item>
                <Typography variant="h1" className={classes.title}>
                  Hei {JSON.parse(auth.userData.name).fullName}!
                </Typography>
                <Typography variant="body1" className={classes.info}>
                  Stilling: {auth.userData.position}
                </Typography>
                <Typography variant="body1" className={classes.info}>
                  Medlem siden: {getDateFromTimestamp(auth.userData.createdAt)}
                </Typography>
              </Grid>
              <Grid item sx={{ position: "absolute" }}>
                <Button
                  variant="contained"
                  className={classes.editbutton}
                  onClick={handleEditProfile}
                >
                  Rediger info
                </Button>
                {/*<Button variant="contained" className={classes.editbutton2}>
                                  Slett konto
                                </Button>*/}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
