import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  InputBase,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Mail, Key } from "../../../components/Icons.jsx";
import SnackPopup from "../../../components/SnackPopup.jsx";
import { Loading, SiteLoading } from "../../../components/Loading";
import { useAuth } from "../../../auth/useAuth";
import useRouter from "../../../hooks/useRouter.jsx";
import useToggle from "../../../hooks/useToggle";
import { useStyles } from "./LoginStyle";
import { theme } from "../PublicTheme.jsx";
import { ThemeProvider } from "@emotion/react";
import EmailConfirmed from "./components/EmailConfirmed";

export default function Login() {
  const classes = useStyles();
  const router = useRouter();
  const auth = useAuth();

  const [isLoadingLogin, setIsLoadingLogin] = useToggle();
  const [isLoading, setIsLoading] = useToggle(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useToggle();
  const [popupState, setPopupState] = useToggle();
  const [popupMsg, setPopupMsg] = useState("");
  const [isRememberChecked, setIsRememberChecked] = useToggle();
  const [username, setUsername] = useState("");
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });

  // Runs on render
  useEffect(() => {
    let isMounted = false;

    if (router.query.user && router.query.code) {
      if (!isMounted) {
        auth
          .confirmUser(router.query.user, router.query.code)
          .then(() => {
            setIsConfirmed(true);
            setIsLoading();
          })
          .catch(() => {
            showPopup(
              "Din bekreftelseslenke har utgått. Vennligst logg inn på nytt for å bli tilsendt en ny lenke."
            );
            setIsConfirmed(false);
            setIsLoading();
          });
      }
    } else if (router.query.reset === "true") {
      if (!isMounted) {
        setIsPasswordReset();
        setIsLoading();
      }
    } else {
      setIsLoading();
    }

    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPopup = (message) => {
    setPopupMsg(message);
    setPopupState();
  };

  const handleFinishConfirm = () => {
    router.push("/login");
    setIsConfirmed(false);
  };

  const handleClosePopup = () => {
    setPopupState();
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmitLogin = (event) => {
    event.preventDefault();

    setIsLoadingLogin();

    auth
      .signIn(username, passwordValues.password, isRememberChecked)
      .then((result) => {
        // Successful login
        if (result === "NewPassword") {
          if (process.env.NODE_ENV === "development") {
            console.log("User requires new password: ", result);
          }
        }
      })
      .catch((err) => {
        // If the user isn't confirmed yet, we send them to /confirm
        if (err.code === "UserNotConfirmedException") {
          auth
            .resendConfirmationLink(username)
            .then(() => {
              router.push({ pathname: "/confirm", state: { email: username } });
            })
            .catch((err) => {
              if (err.code) {
                showPopup(
                  "En feil har skjedd. Vennligst last siden inn på nytt og prøv igjen, eller ta kontakt med support."
                );
              }

              setIsLoadingLogin();
            });
        } else {
          // Failed login, returns an error (err.code and err.message)

          // Wrong email or password
          if (err.code === "NotAuthorizedException") {
            showPopup("E-post eller passord er feil.");
          } else if (err.code === "UserNotFoundException") {
            showPopup("Brukeren finnes ikke.");
          }
          setIsLoadingLogin();
        }
      });
  };

  if (isLoading) {
    return <SiteLoading color="primary" />;
  } else {
    if (isConfirmed) {
      return <EmailConfirmed handleFinishConfirm={handleFinishConfirm} />;
    } else {
      return (
        <ThemeProvider theme={theme}>
          <Box className={classes.box} sx={{ backgroundColor: "#BBCBE4" }}>
            <Button variant="contained" disabled className={classes.topButton}>
              Eksisterende bruker
            </Button>
            <Grid item xs={12} className={classes.content}>
              <SnackPopup
                message={popupMsg}
                open={popupState}
                handleClose={handleClosePopup}
              />
              <Typography variant="h1">Velkommen tilbake!</Typography>
              <form autoComplete="on">
                <InputBase
                  required
                  id="username"
                  type="email"
                  placeholder="E-post..."
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">{Mail}</InputAdornment>
                  }
                />
                <InputBase
                  required
                  id="password"
                  type={passwordValues.showPassword ? "text" : "password"}
                  placeholder="Passord..."
                  value={passwordValues.password}
                  onChange={(e) =>
                    setPasswordValues({
                      ...passwordValues,
                      password: e.target.value,
                    })
                  }
                  startAdornment={
                    <InputAdornment position="start">{Key}</InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {passwordValues.showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {isPasswordReset ? (
                  <Typography>
                    Ditt passord har blitt endret. Vennligst logg inn på nytt.
                  </Typography>
                ) : (
                  <></>
                )}
                <Grid
                  item
                  xs={12}
                  className={classes.flexCondition}
                  sx={{ margin: "10px 0px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={isRememberChecked}
                        onChange={(e) => setIsRememberChecked(e.target.checked)}
                      />
                    }
                    label={<Typography variant="body2">Husk meg</Typography>}
                  />
                  <Button
                    variant="text"
                    className={classes.textButton}
                    onClick={() => router.push("/forgot")}
                  >
                    Glemt passord
                  </Button>
                </Grid>
                {isLoadingLogin ? (
                  <Loading color="primary" />
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "#F3CCD1",
                    }}
                    onClick={(e) => handleSubmitLogin(e)}
                  >
                    Logg inn
                  </Button>
                )}
              </form>
              <Grid item xs={12} sx={{ padding: "20px", textAlign: "center" }}>
                <Button
                  variant="text"
                  onClick={() => router.push("/register")}
                  sx={{
                    marginBottom: "10px",
                    textDecoration: "none",
                    fontSize: "14px",
                  }}
                >
                  Ikke kunde?
                </Button>
                <Button
                  variant="text"
                  onClick={() => router.push("/register")}
                  sx={{
                    color: "#124089",
                  }}
                  className={classes.changeViewText}
                >
                  Bli kunde og prøv Ecofrics bærekraftsportal gratis i 14 dager!
                </Button>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      );
    }
  }
}
