import { TableCell, TableRow } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  squareCell: {
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  //Activities
  item: {
    backgroundColor: "#ffffff",
    boxShadow:
      "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
  },
  iconCell: {
    height: "50px",
    width: "50px",
  },
  iconContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  //Certification
  badgeContent: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
  },
  badge: {
    width: "226px",
  },
  form: {
    textAlign: "center",
    padding: "15px",
  },
  form2: {
    textAlign: "center",
    padding: "15px",
    backgroundColor: "#F3F3F3",
  },
  //Modules
  overlay: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "rgb(255,255,255,0.7)",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    top: "0px",
    left: "0px",
  },
  hiders: {
    backgroundColor: "#ffffff",
    width: "22px",
    height: "40px",
    position: "absolute",
    top: "0px",
    zIndex: 99,
  },
}));

export const ModuleCell = withStyles((theme) => ({
  root: {
    borderRight: "none",
  },
}))(TableCell);

export const ModuleRow = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #EBEBEB",
    borderTop: "1px solid #EBEBEB",
    "&:nth-of-type(even)": {
      backgroundColor: "#ffffff",
    },
  },
}))(TableRow);
