import React, { useState } from 'react';
import {
    Grid,
    Typography,
    Button,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Table,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Slide,
    FormControlLabel,
    Checkbox,
    FormGroup,
} from '@mui/material';
import { Arrow, Download } from '../../../../components/Icons';
import getDateFromTimestamp from '../../../../helpers/getDateFromTimestamp';
import { useStyles } from '../ClimateStyles';
import useRouter from '../../../../hooks/useRouter';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../../auth/useAuth';
import ClimateAccountingPDF, { ClimateAccounting } from '../../Documents/components/ClimateAccountingPDF';
import { updateClimateReport } from '../climateFunctions';
import { PDFDownloadLink } from '@react-pdf/renderer';
import amplitude from 'amplitude-js';
import Replacement from '../../../../assets/images/nocustomer_climate.svg';
import * as Text from '../../../../helpers/climateAccounting.json';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SessionOverview(props) {
    const router = useRouter();
    const classes = useStyles();
    const auth = useAuth();
    const [openDialog, setOpenDialog] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleChange = event => {
        setChecked(event.target.checked);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCompleteDocument = async () => {
        let newInfo = JSON.parse(props.report.infoData);

        let inputData = {
            id: props.report.id,
            status: 'Completed',
            infoData: JSON.stringify(newInfo),
        };

        await updateClimateReport(inputData).then(() => {
            if (process.env.NODE_ENV !== 'development') {
                amplitude.getInstance().logEvent('climate_report_completed');
            }

            router.push('/climate');
        });
    };

    const downloadDocument = () => {
        if (process.env.NODE_ENV !== 'development') {
            amplitude.getInstance().logEvent('climate_report_downloaded');
        }
    };

    const DialogPopUp = (
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" TransitionComponent={Transition} fullWidth>
            <DialogTitle className={classes.popupTitle}>Sikker på at du er ferdig?</DialogTitle>
            <DialogContent>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6} className={classes.reportDownload}>
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            <ClimateAccountingPDF
                                report={props.report}
                                companyName={auth.companyData.companyData.name}
                            />
                        ) : (
                            <img src={Replacement} alt="replacement for none customers"></img>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Typography variant="body1">
                            {Text.complete.p1}
                            <br />
                            <br />
                            {Text.complete.p2}
                            <br />
                            <br />
                            {Text.complete.p3}
                        </Typography>
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            <PDFDownloadLink
                                style={{ color: '#000', textDecoration: 'none' }}
                                document={
                                    <ClimateAccounting
                                        report={props.report}
                                        companyName={auth.companyData.companyData.name}
                                    />
                                }
                                fileName="Klimaregnskaprapport.pdf"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? (
                                        <Button variant="contained" disabled={true} className={classes.popupButton}>
                                            Laster inn dokument...
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={() => downloadDocument(blob)}
                                            className={classes.popupButton}
                                        >
                                            {Download} Last ned PDF
                                        </Button>
                                    )
                                }
                            </PDFDownloadLink>
                        ) : (
                            <Button
                                variant="contained"
                                onClick={() => router.push('/subscribe')}
                                className={classes.popupButton}
                            >
                                Bli kunde for å laste ned dokument
                            </Button>
                        )}
                        <br />
                        <br />
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={checked} onChange={handleChange} />}
                                label={
                                    auth.companyData.companyData.name +
                                    ' bekrefter at opplysningene som gitt i denne rapporten er korrekte og tar avstand fra alle former for grønnvasking.'
                                }
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" className={classes.close} autoFocus onClick={handleCloseDialog}>
                    Lukk
                </Button>
                <Button
                    variant="contained"
                    className={classes.complete}
                    autoFocus
                    onClick={handleCompleteDocument}
                    disabled={checked ? false : true}
                >
                    Fullfør
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Grid item xs={12}>
            <Typography variant="h3">
                <Button
                    startIcon={Arrow}
                    variant="text"
                    sx={{ fontSize: '15px' }}
                    onClick={() => router.push('/climate')}
                >
                    Gå tilbake
                </Button>
            </Typography>
            <Paper sx={{ padding: '20px' }}>
                <Grid container direction="row">
                    <Grid item xs={8}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Tittel:</TableCell>
                                        <TableCell>
                                            {props.report.title !== '' ? props.report.title : 'Ingen tittel'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Opprettet:</TableCell>
                                        <TableCell>{getDateFromTimestamp(props.report.createdAt)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Sist endret:</TableCell>
                                        <TableCell>{getDateFromTimestamp(props.report.updatedAt)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Opprettet av:</TableCell>
                                        <TableCell>{props.report.userCreatedBy}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={4} className={classes.overviewGrid}>
                        <Button
                            variant="contained"
                            startIcon={Arrow}
                            className={classes.sessionButton}
                            sx={{ backgroundColor: '#FEF0D9' }}
                            component={NavLink}
                            to={'/climate'}
                            exact
                        >
                            Tilbake til oversikt
                        </Button>
                        {props.report.status === 'Draft' ? (
                            <Button
                                variant="contained"
                                className={classes.sessionButton}
                                onClick={handleOpenDialog}
                                sx={{ backgroundColor: '#C1E6EB' }}
                            >
                                Fullfør klimaregnskapet
                            </Button>
                        ) : (
                            <></>
                        )}
                        {DialogPopUp}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
