import React from "react";
import { Paper, Typography, InputBase, InputAdornment } from "@mui/material";
import { Search } from "../../../../components/Icons";
import { useStyles } from "../IndexStyles";
export default function SearchEngine(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.searchContent}>
      <Typography variant="h1" sx={{ fontSize: "64px" }}>
        Ecofric-indeksen
      </Typography>
      <InputBase
        className={classes.searchInput}
        type="text"
        name="SearchEngine"
        placeholder="Søk..."
        value={props.input}
        onChange={(e) => props.handleSearch(e)}
        startAdornment={
          <InputAdornment position="start">{Search}</InputAdornment>
        }
      />
    </Paper>
  );
}
