import React from 'react';
import { Paper, Typography, Table, TableContainer, TableHead, TableBody, TableRow, Grid, Button } from '@mui/material';
import { Business1, Social1, Environment1, Profit1, Industry1, ArrowRight } from '../../../../components/Icons';
import ProgressBars from '../../../../components/ProgessBar';
import { useStyles, ModuleCell, ModuleRow } from '../DashboardStyles';
import { Module } from '../../../../components/Icons';
import { useAuth } from '../../../../auth/useAuth';
import useRouter from '../../../../hooks/useRouter';

export default function Modules() {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();

    const data = auth.companyData.companyData.certData.items[0].publicProfile;

    const changeText = percent => {
        if (percent === 100) {
            return 'Endre';
        } else if (percent === null || percent === undefined || percent === 0) {
            return 'Start';
        } else {
            return 'Fortsett';
        }
    };

    return (
        <Paper>
            <Typography variant="h3">{Module}Egenerklæring</Typography>
            <Grid item xs={12} sx={{ padding: '20px' }}>
                <TableContainer>
                    <Table>
                        <TableHead style={{ borderBottom: 'none' }}>
                            <TableRow>
                                <ModuleCell sx={{ width: '6%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '20%', fontSize: '17px' }}>Modul</ModuleCell>
                                <ModuleCell sx={{ width: '50%', fontSize: '17px' }}>
                                    Opptjent poengsum (av totalt mulige)
                                </ModuleCell>
                                <ModuleCell sx={{ width: '8%' }}></ModuleCell>
                                <ModuleCell align="center" sx={{ width: '8%', fontSize: '17px' }}>
                                    Poeng
                                </ModuleCell>
                                <ModuleCell align="center" sx={{ width: '10%' }}></ModuleCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ModuleRow>
                                <ModuleCell style={{ backgroundColor: '#C1E6EB' }}>
                                    <Grid className={classes.squareCell}>{Business1}</Grid>
                                </ModuleCell>
                                <ModuleCell>1.0 Bedriftsprofil</ModuleCell>
                                <ModuleCell>N/A</ModuleCell>
                                <ModuleCell></ModuleCell>
                                <ModuleCell className={classes.squareCell} style={{ backgroundColor: '#C1E6EB' }}>
                                    N/A
                                </ModuleCell>
                                <ModuleCell>
                                    <Button
                                        variant="contained"
                                        sx={{ width: '100px', backgroundColor: '#E4E4E4' }}
                                        onClick={() => router.push('/declaration/company')}
                                    >
                                        {changeText(100)}
                                        {ArrowRight}
                                    </Button>
                                </ModuleCell>
                            </ModuleRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table>
                        <TableHead style={{ borderBottom: 'none' }}>
                            <TableRow>
                                <ModuleCell sx={{ width: '6%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '20%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '50%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '8%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '8%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '10%' }}></ModuleCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ModuleRow>
                                <ModuleCell style={{ backgroundColor: '#BBCBE4' }}>
                                    <Grid className={classes.squareCell}>{Profit1}</Grid>
                                </ModuleCell>
                                <ModuleCell>2.0 Økonomi og drift</ModuleCell>
                                <ModuleCell>
                                    <Grid item xs={12}>
                                        <ProgressBars value={data.economyPercentScore} backgroundColor="#BBCBE4" />
                                    </Grid>
                                </ModuleCell>
                                <ModuleCell>
                                    <Typography variant="body2">{data.economyPercentScore}%</Typography>
                                </ModuleCell>
                                <ModuleCell
                                    align="center"
                                    className={classes.squareCell}
                                    style={{ backgroundColor: '#BBCBE4', lineHeight: '1' }}
                                >
                                    {data.economyScore}
                                </ModuleCell>
                                <ModuleCell>
                                    <Button
                                        variant="contained"
                                        sx={{ width: '100px', backgroundColor: '#E4E4E4' }}
                                        onClick={() => router.push('/declaration/economy')}
                                    >
                                        {changeText(data.economyPercentScore)}
                                        {ArrowRight}
                                    </Button>
                                </ModuleCell>
                            </ModuleRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table>
                        <TableHead style={{ borderBottom: 'none' }}>
                            <TableRow>
                                <ModuleCell sx={{ width: '6%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '20%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '50%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '8%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '8%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '10%' }}></ModuleCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ModuleRow>
                                <ModuleCell style={{ backgroundColor: '#F3CCD1' }}>
                                    <Grid className={classes.squareCell}>{Social1}</Grid>
                                </ModuleCell>
                                <ModuleCell>3.0 Sosiale forhold</ModuleCell>
                                <ModuleCell>
                                    <ProgressBars value={data.socialPercentScore} backgroundColor="#F3CCD1" />
                                </ModuleCell>
                                <ModuleCell>
                                    <Typography variant="body2">{data.socialPercentScore}%</Typography>
                                </ModuleCell>
                                <ModuleCell
                                    align="center"
                                    className={classes.squareCell}
                                    style={{ backgroundColor: '#F3CCD1', lineHeight: '1' }}
                                >
                                    {data.socialScore}
                                </ModuleCell>
                                <ModuleCell>
                                    <Button
                                        variant="contained"
                                        sx={{ width: '100px', backgroundColor: '#E4E4E4' }}
                                        onClick={() => router.push('/declaration/social')}
                                    >
                                        {changeText(data.socialPercentScore)}
                                        {ArrowRight}
                                    </Button>
                                </ModuleCell>
                            </ModuleRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table>
                        <TableHead style={{ borderBottom: 'none' }}>
                            <TableRow>
                                <ModuleCell sx={{ width: '6%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '20%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '50%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '8%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '8%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '10%' }}></ModuleCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ModuleRow>
                                <ModuleCell style={{ backgroundColor: '#C3E8D0' }}>
                                    <Grid className={classes.squareCell}>{Environment1}</Grid>
                                </ModuleCell>
                                <ModuleCell>4.0 Miljø og klima</ModuleCell>
                                <ModuleCell>
                                    <ProgressBars value={data.environmentalPercentScore} backgroundColor="#C3E8D0" />
                                </ModuleCell>
                                <ModuleCell>
                                    <Typography variant="body2">{data.environmentalPercentScore}%</Typography>
                                </ModuleCell>
                                <ModuleCell
                                    align="center"
                                    className={classes.squareCell}
                                    style={{ backgroundColor: '#C3E8D0', lineHeight: '1' }}
                                >
                                    {data.environmentalScore}
                                </ModuleCell>
                                <ModuleCell>
                                    <Button
                                        variant="contained"
                                        sx={{ width: '100px', backgroundColor: '#E4E4E4' }}
                                        onClick={() => router.push('/declaration/environment')}
                                    >
                                        {changeText(data.environmentalPercentScore)}
                                        {ArrowRight}
                                    </Button>
                                </ModuleCell>
                            </ModuleRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer sx={{ position: 'relative' }}>
                    <Grid className={classes.overlay}>
                        <Typography variant="h2">Kommer snart!</Typography>
                    </Grid>
                    <Table>
                        <TableHead style={{ borderBottom: 'none' }}>
                            <TableRow>
                                <ModuleCell sx={{ width: '6%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '20%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '50%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '8%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '8%' }}></ModuleCell>
                                <ModuleCell sx={{ width: '10%' }}></ModuleCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ModuleRow>
                                <ModuleCell style={{ backgroundColor: '#CEBEDD' }}>
                                    <Grid className={classes.squareCell}>{Industry1}</Grid>
                                </ModuleCell>
                                <ModuleCell>5.0 Bransjespesifikke kriterier</ModuleCell>
                                <ModuleCell>
                                    <ProgressBars value={0} backgroundColor="#CEBEDD" />
                                </ModuleCell>
                                <ModuleCell>
                                    <Typography variant="body2">0%</Typography>
                                </ModuleCell>
                                <ModuleCell className={classes.squareCell} style={{ backgroundColor: '#CEBEDD' }}>
                                    0
                                </ModuleCell>
                                <ModuleCell>
                                    <Button variant="contained" sx={{ width: '100px', backgroundColor: '#E4E4E4' }}>
                                        {changeText()}
                                        {ArrowRight}
                                    </Button>
                                </ModuleCell>
                            </ModuleRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Paper>
    );
}
