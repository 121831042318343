import React, { useState } from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { Firm } from "../../../../components/Icons";
import { useAuth } from "../../../../auth/useAuth";
import useToggle from "../../../../hooks/useToggle";
import SnackPopup from "../../../../components/SnackPopup";
import { Loading } from "../../../../components/Loading";
import axios from "axios";
import { checkForCompany, deleteAccount } from "../termsFunctions";
import { useStyles } from "../TermsStyle";

export default function DeclinedByCompany(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useToggle();
  const [popupState, setPopupState] = useToggle();
  const [popupMsg, setPopupMsg] = useState("");
  const [timerDelay, setTimerDelay] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const showPopup = (message) => {
    setPopupMsg(message);
    setPopupState();
  };

  const handleClosePopup = () => {
    setPopupState();
  };

  // Function that runs when autocomplete is typed into it
  const handleGetCompanies = (event) => {
    let searchText = event.currentTarget.value;

    if (timerDelay) {
      clearTimeout(timerDelay);
    }

    setTimerDelay(
      setTimeout(() => {
        handleFetchCompanies(searchText);
      }, 1000)
    );
  };

  // Fetches the options from Brreg that is displayed to the user upon autocomplete input
  const handleFetchCompanies = async (navn) => {
    let url =
      "https://data.brreg.no/enhetsregisteret/api/enheter?konkurs=false&underAvvikling=false&underTvangsavviklingEllerTvangsopplosning=false";

    if (navn) {
      url = url + `&navn="${navn}"`;
    } else {
      return;
    }

    await axios.get(url).then((res) => {
      if (
        res !== undefined &&
        res.data !== undefined &&
        res.data._embedded !== undefined &&
        res.data._embedded.enheter !== undefined
      ) {
        setCompanies([...res.data._embedded.enheter]);
      }
    });
  };

  const handleContinue = async (event) => {
    event.preventDefault();

    if (selectedCompany === "") {
      showPopup("Du må velge en organisasjon");
      return;
    }

    setIsLoading();

    // Send user to correct view based on if their selected company exists or not
    await checkForCompany(selectedCompany.organisasjonsnummer).then(
      (companyExists) => {
        if (companyExists) {
          props.updateBrregData(selectedCompany);
          props.setView("join");
        } else {
          props.updateBrregData(selectedCompany);
          props.setView("create");
        }
      }
    );
  };

  const handleDeleteAccount = async () => {
    setIsLoading();

    await deleteAccount(auth).catch(() => {
      showPopup("En feil oppsto ved sletting av konto, vennligst prøv igjen");
      setIsLoading();
    });
  };

  return (
    <Grid item xs={12} className="content">
      <SnackPopup
        message={popupMsg}
        open={popupState}
        handleClose={handleClosePopup}
      />
      <Typography variant="h1" style={{ textAlign: "left" }}>
        Kontoen din er ikke tilknyttet en organisasjon
      </Typography>
      <Typography variant="body1" style={{ padding: "15px 0px 10px 0px" }}>
        Vennligst velg en ny organisasjon, eller slett kontoen din.
      </Typography>
      <Autocomplete
        autoComplete={false}
        id="company-selector"
        forcePopupIcon={true}
        style={{ border: "none" }}
        options={companies}
        getOptionLabel={(option) =>
          option.navn + " (Org. nr. " + option.organisasjonsnummer + ")"
        }
        onChange={(e, value) => setSelectedCompany(value)}
        renderInput={(params) => (
          <TextField
            required
            error
            name="company"
            type="text"
            autoComplete="off"
            onChange={(e) => handleGetCompanies(e)}
            {...params}
            style={{ border: "none" }}
            placeholder="Organisasjon..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">{Firm}</InputAdornment>
              ),
            }}
          />
        )}
      />
      {isLoading ? (
        <Loading color="primary" />
      ) : (
        <Grid item xs={12} className={classes.buttonSection}>
          <Button
            variant="contained"
            className={classes.fiftyButton}
            onClick={(e) => handleContinue(e)}
          >
            Velg organisasjon
          </Button>
          <Button
            variant="contained"
            className={classes.greyButton}
            onClick={handleDeleteAccount}
          >
            Slett konto
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
