import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  Avatar,
  InputBase,
  InputLabel,
  Button,
} from "@mui/material";
import { useStyles } from "../ProfileStyles";
import { FirmHeader } from "../../../../components/Icons";
import useToggle from "../../../../hooks/useToggle";
import { useAuth } from "../../../../auth/useAuth";
import { updateCompanyInfo } from "../profileFunctions";
import getDateFromTimestamp from "../../../../helpers/getDateFromTimestamp";

export default function CompanyView() {
  const classes = useStyles();
  const auth = useAuth();

  const [isEditing, setIsEditing] = useToggle();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      setName(auth.companyData.name);
      setAddress(JSON.parse(auth.companyData.companyData.address).adresse);
      setZip(JSON.parse(auth.companyData.companyData.address).postnummer);
      setState(JSON.parse(auth.companyData.companyData.address).kommune);

      if (JSON.parse(auth.companyData.companyData.invoice).email) {
        setEmail(JSON.parse(auth.companyData.companyData.invoice).email);
      }
    }

    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditCompany = () => {
    setName(auth.companyData.name);
    setAddress(JSON.parse(auth.companyData.companyData.address).adresse);
    setZip(JSON.parse(auth.companyData.companyData.address).postnummer);
    setState(JSON.parse(auth.companyData.companyData.address).kommune);

    if (JSON.parse(auth.companyData.companyData.invoice).email) {
      setEmail(JSON.parse(auth.companyData.companyData.invoice).email);
    } else {
      setEmail("");
    }

    setIsEditing();
  };

  const handleEditCompanyProfile = async () => {
    let companyInput = {
      id: auth.companyData.id,
      name: name,
    };

    let addressObj = JSON.parse(auth.companyData.companyData.address);

    addressObj.adresse = address;
    addressObj.postnummer = zip;
    addressObj.kommune = state;

    let invoiceObj = JSON.parse(auth.companyData.companyData.invoice);

    invoiceObj.email = email;

    let companyDataInput = {
      id: auth.companyData.companyData.id,
      name: name,
      address: JSON.stringify(addressObj),
      invoice: JSON.stringify(invoiceObj),
    };

    await updateCompanyInfo(companyInput, companyDataInput).then(async () => {
      await auth.fetchCompanyInfo(auth.companyData.companyId);
      setIsEditing();
    });
  };

  return (
    <Paper style={{ marginBottom: "20px" }}>
      <Grid item xs={12} className={classes.pageHeader}>
        <Typography variant="h2">
          {FirmHeader} Profilen til {auth.companyData.name}
        </Typography>
      </Grid>
      <Grid item style={{ display: "flex" }} className={classes.spacer}>
        <Grid item xs={4} sm={4} md={4} lg={3} className={classes.rightSection}>
          <Avatar className={classes.companyImage}>
            {auth.companyData.name.slice(0, 3)}
          </Avatar>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={9} style={{ padding: "0px 20px" }}>
          {isEditing ? (
            <Grid item style={{ marginTop: 20 }}>
              <Typography
                variant="h2"
                style={{ justifyContent: "left", marginBottom: 10 }}
              >
                Rediger organisasjonens info
              </Typography>
              <form autoComplete="on">
                <Grid item className={classes.editfield}>
                  <InputLabel className={classes.editlabel}>
                    Bedriftsnavn:
                  </InputLabel>
                  <InputBase
                    className="input"
                    id="companyName"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item className={classes.editfield}>
                  <InputLabel className={classes.editlabel}>
                    Adresse:
                  </InputLabel>
                  <InputBase
                    className="input"
                    id="companyAddress"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
                <Grid item className={classes.editfield}>
                  <InputLabel className={classes.editlabel}>
                    Postkode:
                  </InputLabel>
                  <InputBase
                    className="input"
                    id="companyZipCode"
                    type="text"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                  />
                </Grid>
                <Grid item className={classes.editfield}>
                  <InputLabel className={classes.editlabel}>
                    Kommune:
                  </InputLabel>
                  <InputBase
                    className="input"
                    id="companyState"
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </Grid>
                <Grid item className={classes.editfield}>
                  <InputLabel className={classes.editlabel}>
                    Faktura e-post:
                  </InputLabel>
                  <InputBase
                    className="input"
                    id="companyEmail"
                    type="epost"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </form>
              <Button
                variant="contained"
                color="primary"
                className={classes.editbutton}
                onClick={handleEditCompanyProfile}
              >
                Lagre
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.editbutton2}
                onClick={handleEditCompany}
              >
                Avbryt
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Typography variant="body1" className={classes.info}>
                Bedrift: {auth.companyData.name}
              </Typography>
              <Typography variant="body1" className={classes.info}>
                Org. nr.:{" "}
                {auth.companyData.orgNumber.match(/.{1,3}/g).join(" ")}
              </Typography>
              <Typography variant="body1" className={classes.info}>
                Næringskode:{" "}
                {JSON.parse(auth.companyData.companyData.brregData)
                  .naeringskode1.kode +
                  " - " +
                  JSON.parse(auth.companyData.companyData.brregData)
                    .naeringskode1.beskrivelse}
              </Typography>
              <Typography variant="body1" className={classes.info}>
                Adresse:{" "}
                {JSON.parse(auth.companyData.companyData.address).adresse +
                  ", " +
                  JSON.parse(auth.companyData.companyData.address).postnummer +
                  " " +
                  JSON.parse(auth.companyData.companyData.address).kommune}
              </Typography>
              <Typography variant="body1" className={classes.info}>
                Faktura e-post:{" "}
                {JSON.parse(auth.companyData.companyData.invoice).email ? (
                  JSON.parse(auth.companyData.companyData.invoice).email
                ) : (
                  <>Ingen e-post er satt</>
                )}
              </Typography>
              <Typography variant="body1" className={classes.info}>
                Medlem siden: {getDateFromTimestamp(auth.companyData.createdAt)}
              </Typography>
              {auth.isAdmin ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 100 }}
                  className={classes.editbutton}
                  onClick={handleEditCompany}
                >
                  Rediger info
                </Button>
              ) : (
                <></>
              )}
              {/*<Button
                                variant="contained"
                                color="secondary"
                                className={classes.editbutton2}
                                style={{ width: 120 }}
                            >
                                Deaktiver firma
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.editbutton2}
                                style={{ width: 150 }}
                            >
                                Avslutt og slett firma
                            </Button>*/}
              <Typography variant="body2" sx={{ marginTop: "7px" }}>
                Admin-brukere kan oppdatere selskapsinformasjonen
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
