import React from "react";
import { Grid } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
export default function PrivateView({ children }) {
  return (
    <div className="root">
      <Sidebar />
      <Grid
        container
        sx={{ display: "flex", height: "100vh", justifyContent: "center" }}
      >
        <Grid
          item
          xs={12}
          sx={{ alignSelf: "flex-start", backgroundColor: "#f6f6f6" }}
        >
          <Header />
          <Grid
            item
            xs={12}
            sx={{
              padding: "20px 40px",
              maxWidth: "1280px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            {children}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ alignSelf: "flex-end", backgroundColor: "#f6f6f6" }}
        >
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}
