import React, { useState } from 'react';
import {
    Grid,
    NativeSelect,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    InputBase,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    InputLabel,
} from '@mui/material';
import { Delete, Add } from '../../../../../../components/Icons';
import { withStyles } from '@mui/styles';
import uuid from 'react-uuid';

const CustomRow = withStyles(() => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f3f3f3',
            borderTop: '1px solid #EBEBEB',
            borderBottom: '1px solid #EBEBEB',
        },
    },
}))(TableRow);

const CustomCell = withStyles(() => ({
    root: {
        padding: '0px 5px',
        borderBottom: '0px',
    },
    head: {
        padding: '10px 10px 0px 10px',
        fontWeight: 'bold',
    },
    body: {
        borderRight: '1px solid #E8E8E8',
        '&:last-child': {
            borderRight: '0px',
        },
    },
}))(TableCell);

export default function BrandsProductsServices(props) {
    const { data, input, setInput, reportIsCompleted } = props;

    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [legality, setLegality] = useState(false);
    const [description, setDescription] = useState('');

    const handleAdd = () => {
        if (type === '' || name === '' || description === '') {
            return;
        }

        let newArr = [];

        let newObj = {
            id: uuid(),
            type: type,
            name: name,
            legality: legality,
            description: description,
        };

        if (input[data.id]) {
            newArr = input[data.id];
        }

        newArr.push(newObj);

        setInput({
            ...input,
            [data.id]: newArr,
        });

        setType('');
        setName('');
        setLegality(false);
        setDescription('');
    };

    const handleRemove = index => {
        let newArr = [];

        newArr = input[data.id];

        newArr.splice(index, 1);

        setInput({
            ...input,
            [data.id]: newArr,
        });
    };

    return (
        <Grid container sx={{ marginBottom: '10px' }}>
            {!reportIsCompleted ? (
                <>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item xs={4} sx={{ paddingRight: '10px' }}>
                            <InputLabel>Velg varemerke, produkt eller tjeneste *</InputLabel>
                            <NativeSelect value={type} onChange={e => setType(e.target.value)} input={<InputBase />}>
                                <option value="" disabled>
                                    Velg alternativ
                                </option>
                                <option value="Brand">Varemerke</option>
                                <option value="Product">Produkt</option>
                                <option value="Service">Tjeneste</option>
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={5}>
                            <InputLabel>Navn *</InputLabel>
                            <InputBase
                                type="text"
                                name="name"
                                placeholder="Skriv her...."
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel>Legalitet</InputLabel>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        style={{ margin: '8px 0px' }}
                                        control={
                                            <Checkbox
                                                checked={legality}
                                                name="legality"
                                                onChange={e => setLegality(e.target.checked)}
                                            />
                                        }
                                        label="Forbudt i ulike markeder"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item xs={10}>
                            <InputLabel>Beskrivelse av varemerke, produktet eller tjenesten *</InputLabel>
                            <InputBase
                                type="text"
                                name="Description"
                                placeholder="Skriv her...."
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{ width: '130px', marginBottom: '10px' }}
                                onClick={handleAdd}
                            >
                                {Add}Legg til
                            </Button>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <></>
            )}
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <CustomRow
                                sx={{
                                    borderBottom: '2px solid #FDDFB0 !important',
                                }}
                            >
                                <CustomCell>Merke, produkt eller tjeneste</CustomCell>
                                <CustomCell>Navn</CustomCell>
                                <CustomCell>Forbudt i ulike markeder</CustomCell>
                                <CustomCell>Beskrivelse</CustomCell>
                                <CustomCell align="center">Slett</CustomCell>
                            </CustomRow>
                        </TableHead>
                        <TableBody>
                            {input[data.id] && input[data.id].length > 0 ? (
                                input[data.id].map((item, index) => {
                                    let typeLabel = '';

                                    if (item.type === 'Brand') {
                                        typeLabel = 'Varemerke';
                                    } else if (item.type === 'Product') {
                                        typeLabel = 'Produkt';
                                    } else if (item.type === 'Service') {
                                        typeLabel = 'Tjeneste';
                                    }

                                    let legalLabel = 'Nei';

                                    if (item.legality === true) {
                                        legalLabel = 'Ja';
                                    }

                                    return (
                                        <CustomRow key={item.id}>
                                            <CustomCell>{typeLabel}</CustomCell>
                                            <CustomCell>{item.name}</CustomCell>
                                            <CustomCell>{legalLabel}</CustomCell>
                                            <CustomCell>{item.description}</CustomCell>
                                            {!reportIsCompleted ? (
                                                <CustomCell align="center">
                                                    <IconButton onClick={() => handleRemove(index)}>
                                                        {Delete}
                                                    </IconButton>
                                                </CustomCell>
                                            ) : (
                                                <></>
                                            )}
                                        </CustomRow>
                                    );
                                })
                            ) : (
                                <CustomRow>
                                    <CustomCell
                                        align="left"
                                        sx={{
                                            padding: '10px 20px 10px 20px !important',
                                        }}
                                    >
                                        Ingen svar lagt til.
                                    </CustomCell>
                                </CustomRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
