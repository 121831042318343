import { API, graphqlOperation } from 'aws-amplify';

import { listUserNotifications } from '../../graphql/queries';
import { createUserNotification, updateUserNotification } from '../../graphql/mutations';

/** LOCAL FUNCTIONS **/
export async function fetchNotifications(companyId, userId, token) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listUserNotifications, {
                filter: { companyId: { eq: companyId }, userId: { eq: userId } },
                nextToken: token,
            }),
        )
            .then(data => {
                resolve(data.data.listUserNotifications);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/** EXPORTED FUNCTIONS **/

export async function fetchAllNotifications(companyId, userId) {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchNotifications(companyId, userId, nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching notifications from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched all user notifications: ', dataArr);
        }

        resolve(dataArr);
    });
}

export async function createNotification(inputData) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createUserNotification, { input: inputData }))
            .then(data => {
                resolve(data.data.createUserNotification);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function markNotificationAsRead(id) {
    let inputData = {
        id: id,
        markedAsRead: true,
    };

    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateUserNotification, { input: inputData }))
            .then(data => {
                resolve(data.data.updateUserNotification);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function archiveNotification(id) {
    let inputData = {
        id: id,
        archived: true,
    };

    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateUserNotification, { input: inputData }))
            .then(data => {
                resolve(data.data.updateUserNotification);
            })
            .catch(err => {
                reject(err);
            });
    });
}
