import React, { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    Button,
    TextField,
    Divider,
    InputBase,
    Chip,
    FormControl,
    Select,
    MenuItem,
    Box,
    Autocomplete,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useStyles, theme, autoCompletetheme } from '../ChoresStyle';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import nbLocale from 'date-fns/locale/nb';
import DialogPopUp from '../../../../components/DialogPopup';
import { useAuth } from '../../../../auth/useAuth';
import useRouter from '../../../../hooks/useRouter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function General(props) {
    const { users, task, updateTask } = props;

    const classes = useStyles();
    const router = useRouter();
    const auth = useAuth();

    const climateCriterias = [
        'Forbrenning og oppvarming',
        'Intern transport',
        '(Kjøle)gasser',
        'Strøm og fjernvarme',
        'Strømleverandør',
        'Vannforbruk',
        'Jobbreiser eksklusiv firma-kjøretøy',
        'Transport til og fra jobb',
        'Innkjøpt varetransport',
        'Innkjøpte materialer',
        'Innkjøp av inventar og utstyr',
        'Innleide produkter',
        'Solgte produkter',
        'Utleie av produkter',
        'Investeringer',
        'Restmaterialer og restråvarer',
        'Avfallsproduksjon',
        'Kjemikalier',
    ];
    const [openDialog, setOpenDialog] = useState(false);
    const [hasSubCriteria, setHasSubCriteria] = useState(false);
    const [criterias, setCriterias] = useState([]);
    const [subCriterias, setSubCriterias] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState(null);
    const [selectedSubCriteria, setSelectedSubCriteria] = useState(null);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            fetchCriteria();

            if (task.type === 'CLIMATE_CRITERIA') {
                setCriterias([...climateCriterias]);
            } else if (task.type === 'CERT_CRITERIA') {
                let newArr = [];

                for (const category of props.criterias) {
                    let categoryName = category.name;
                    let catId = category.id;

                    for (const sub of category.subcategories.items) {
                        for (const question of sub.questions.items) {
                            let newQuestion = question;
                            newQuestion.categoryName = categoryName;
                            newQuestion.catId = catId;

                            newArr.push(newQuestion);
                        }
                    }
                }

                setCriterias([...newArr]);
            }
        }

        return () => {
            isMounted = true;
        };
    }, [props.criterias]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderStatusChip = status => {
        if (status === 'Not Started') {
            return 'Ikke påbegynt';
        } else if (status === 'In Progress') {
            return 'Påbegynt';
        } else if (status === 'Completed') {
            return 'Fullført';
        }
    };

    const fetchCriteria = () => {
        if (task.type === 'CLIMATE_CRITERIA') {
            for (const crit of climateCriterias) {
                if (crit === task.climateCriteria) {
                    setSelectedCriteria(crit);
                }
            }
        } else if (task.type === 'CERT_CRITERIA') {
            for (const category of props.criterias) {
                for (const sub of category.subcategories.items) {
                    for (const question of sub.questions.items) {
                        if (question.id === task.criteria) {
                            setSelectedCriteria(question);

                            if (question.type === 'MultipleChoice' || question.type === 'RadioList') {
                                setSubCriterias(question.options.items);
                                setHasSubCriteria(true);

                                for (const sub of question.options.items) {
                                    if (sub.id === task.subCriteria) {
                                        setSelectedSubCriteria(sub);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    const getStatusColor = name => {
        if (name === 'Not Started') {
            return '#F3CCD1';
        } else if (name === 'In Progress') {
            return '#FDDFB0';
        } else if (name === 'Completed') {
            return '#C3E8D0';
        }
    };

    const handleUserChange = async event => {
        let newHistoryObj = {
            userId: auth.userData.id,
            action: 'USER_LIST_UPDATE',
            timestamp: new Date(),
            oldData: task.users,
            newData: event.target.value,
        };

        let historyArr = JSON.parse(task.history);

        historyArr.push(newHistoryObj);

        let inputData = {
            id: task.id,
            users: event.target.value,
            history: JSON.stringify(historyArr),
        };

        await updateTask(inputData);
    };

    const handleStatusChange = async event => {
        let newHistoryObj = {
            userId: auth.userData.id,
            action: 'STATUS_UPDATE',
            timestamp: new Date(),
            oldData: task.status,
            newData: event.target.value,
        };

        let historyArr = JSON.parse(task.history);

        historyArr.push(newHistoryObj);

        let inputData = {
            id: task.id,
            status: event.target.value,
            history: JSON.stringify(historyArr),
        };

        await updateTask(inputData);
    };

    const handleDueDateChange = async newDate => {
        let newHistoryObj = {
            userId: auth.userData.id,
            action: 'DUE_DATE_UPDATE',
            timestamp: new Date(),
            oldData: task.dueDate,
            newData: newDate,
        };

        let historyArr = JSON.parse(task.history);

        historyArr.push(newHistoryObj);

        let inputData = {
            id: task.id,
            dueDate: newDate,
            history: JSON.stringify(historyArr),
        };

        await updateTask(inputData);
    };

    const handleClimateCriteriaChange = async value => {
        let newHistoryObj = {
            userId: auth.userData.id,
            action: 'CLIMATE_CRITERIA_UPDATE',
            timestamp: new Date(),
            oldData: task.climateCriteria,
            newData: value,
        };

        let historyArr = JSON.parse(task.history);

        historyArr.push(newHistoryObj);

        let inputData = {
            id: task.id,
            climateCriteria: value,
            history: JSON.stringify(historyArr),
        };

        setSelectedCriteria(value);

        await updateTask(inputData);
    };

    const handleCriteriaChange = async value => {
        let inputData = null;

        if (!value) {
            let newHistoryObj = {
                userId: auth.userData.id,
                action: 'CERT_CRITERIA_UPDATE',
                timestamp: new Date(),
                oldData: task.criteria,
                newData: value,
            };

            let historyArr = JSON.parse(task.history);

            historyArr.push(newHistoryObj);

            inputData = {
                id: task.id,
                criteria: value,
                subCriteria: value,
                history: JSON.stringify(historyArr),
            };

            setHasSubCriteria(false);
            setSelectedCriteria(null);
            setSelectedSubCriteria(null);
        } else {
            let newHistoryObj = {
                userId: auth.userData.id,
                action: 'CERT_CRITERIA_UPDATE',
                timestamp: new Date(),
                oldData: task.criteria,
                newData: value.id,
            };

            let historyArr = JSON.parse(task.history);

            historyArr.push(newHistoryObj);

            inputData = {
                id: task.id,
                criteria: value.id,
                history: JSON.stringify(historyArr),
            };

            setSelectedCriteria(value);

            if (value.type === 'MultipleChoice' || value.type === 'RadioList') {
                setSubCriterias(value.options.items);
                setHasSubCriteria(true);
            }
        }

        await updateTask(inputData);
    };

    const handleSubCriteriaChange = async value => {
        let inputData = null;

        if (!value) {
            let newHistoryObj = {
                userId: auth.userData.id,
                action: 'SUB_CRITERIA_UPDATE',
                timestamp: new Date(),
                oldData: task.subCriteria,
                newData: value,
            };

            let historyArr = JSON.parse(task.history);

            historyArr.push(newHistoryObj);

            inputData = {
                id: task.id,
                subCriteria: value,
                history: JSON.stringify(historyArr),
            };

            setSelectedSubCriteria(null);
        } else {
            let newHistoryObj = {
                userId: auth.userData.id,
                action: 'SUB_CRITERIA_UPDATE',
                timestamp: new Date(),
                oldData: task.subCriteria,
                newData: value.id,
            };

            let historyArr = JSON.parse(task.history);

            historyArr.push(newHistoryObj);

            inputData = {
                id: task.id,
                subCriteria: value.id,
                history: JSON.stringify(historyArr),
            };

            setSelectedSubCriteria(value);
        }

        await updateTask(inputData);
    };

    const handleGoToCriteria = () => {
        console.log(selectedCriteria);

        if (task.type === 'CERT_CRITERIA') {
            router.push('/declaration/' + selectedCriteria.catId + '?criteria=' + selectedCriteria.id);
        } else if (task.type === 'CLIMATE_CRITERIA') {
            router.push(
                '/climate/' + task.climateReportId + '/scope' + task.climateScope + '?criteria=' + selectedCriteria,
            );
        }
    };

    return (
        <Grid item>
            <Grid item className={classes.formGrid}>
                <Typography variant="body2" className={classes.titles}>
                    Status:
                </Typography>
                <FormControl sx={{ width: 300 }}>
                    <Select
                        value={task.status}
                        sx={{
                            '& svg': {
                                display: 'none',
                            },
                        }}
                        onChange={handleStatusChange}
                        input={<InputBase className={classes.chipInput} label="Chip" />}
                        renderValue={selected => (
                            <Box>
                                <Chip
                                    label={renderStatusChip(selected)}
                                    sx={{
                                        width: '110px',
                                        backgroundColor: getStatusColor(selected),
                                        cursor: 'pointer',
                                    }}
                                />
                            </Box>
                        )}
                    >
                        <Chip
                            value={'Not Started'}
                            label={'Ikke påbegynt'}
                            sx={{
                                width: '110px',
                                backgroundColor: getStatusColor('Not Started'),
                            }}
                        />
                        <Chip
                            value={'In Progress'}
                            label={'Påbegynt'}
                            sx={{
                                width: '110px',
                                backgroundColor: getStatusColor('In Progress'),
                            }}
                        />
                        <Chip
                            value={'Completed'}
                            label={'Fullført'}
                            sx={{
                                width: '110px',
                                backgroundColor: getStatusColor('Completed'),
                            }}
                        />
                    </Select>
                </FormControl>
            </Grid>
            <Grid item className={classes.formGrid} sx={{ margin: '5px 0px' }}>
                <Typography variant="body2" className={classes.titles}>
                    Forfallsdato:
                </Typography>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={nbLocale}>
                        <DatePicker
                            mask="__.__.____"
                            clearable
                            renderInput={params => {
                                return (
                                    <TextField
                                        sx={{ width: '100%' }}
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: 'dd/mm/yyyy',
                                        }}
                                    />
                                );
                            }}
                            value={task.dueDate}
                            onChange={newValue => {
                                handleDueDateChange(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </Grid>
            {task.type === 'CERT_CRITERIA' ? (
                <Grid item className={classes.formGrid} sx={{ margin: '14px 0px' }}>
                    <Typography variant="body2" className={classes.titles}>
                        Kriterie:
                    </Typography>
                    <ThemeProvider theme={autoCompletetheme}>
                        <Autocomplete
                            sx={{ width: '100%' }}
                            options={criterias.sort((a, b) => a.question.localeCompare(b.question))}
                            groupBy={option => option.categoryName}
                            getOptionLabel={option => option.question}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            freeSolo
                            renderInput={params => (
                                <TextField {...params} label="" placeholder="Søk etter kriterie..." />
                            )}
                            value={selectedCriteria}
                            onChange={(e, value) => handleCriteriaChange(value)}
                        />
                    </ThemeProvider>
                </Grid>
            ) : (
                <Grid item className={classes.formGrid} sx={{ margin: '14px 0px' }}>
                    <Typography variant="body2" className={classes.titles}>
                        Klimakriterie:
                    </Typography>
                    <ThemeProvider theme={autoCompletetheme}>
                        <Autocomplete
                            sx={{ width: '100%' }}
                            options={criterias.sort((a, b) => a.localeCompare(b))}
                            getOptionLabel={option => option}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            freeSolo
                            renderInput={params => (
                                <TextField {...params} label="" placeholder="Søk etter klimakriterie..." />
                            )}
                            value={selectedCriteria}
                            onChange={(e, value) => handleClimateCriteriaChange(value)}
                        />
                    </ThemeProvider>
                </Grid>
            )}

            {hasSubCriteria ? (
                <Grid item className={classes.formGrid} sx={{ margin: '14px 0px' }}>
                    <Typography variant="body2" className={classes.titles}>
                        Underkriterie:
                    </Typography>
                    <ThemeProvider theme={autoCompletetheme}>
                        <Autocomplete
                            sx={{ width: '100%' }}
                            options={subCriterias.sort((a, b) => a.option.localeCompare(b.option))}
                            getOptionLabel={option => option.option}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            freeSolo
                            renderInput={params => (
                                <TextField {...params} label="" placeholder="Søk etter kriterie..." />
                            )}
                            value={selectedSubCriteria}
                            onChange={(e, value) => handleSubCriteriaChange(value)}
                        />
                    </ThemeProvider>
                </Grid>
            ) : (
                <></>
            )}
            <Divider className={classes.hr2} style={{ marginBottom: 10 }} />
            <Typography variant="body2" className={classes.titles}>
                Ansvarlig(e):
            </Typography>
            <Grid item>
                <Grid item className={classes.formGrid}>
                    <FormControl sx={{ width: '100%' }}>
                        <Select
                            multiple
                            value={task.users}
                            onChange={handleUserChange}
                            placeholder="Velg en eller flere ansvarlige..."
                            input={<InputBase placeholder="Velg en eller flere ansvarlige..." />}
                            MenuProps={MenuProps}
                        >
                            {users.map(user => (
                                <MenuItem key={user.id} value={user.id}>
                                    {JSON.parse(user.name).fullName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item style={{ marginTop: 30 }}>
                <Button variant="contained" color="primary" className={classes.deleteButton} onClick={handleOpenDialog}>
                    Slett aktiviteten
                </Button>
                {task.type === 'CERT_CRITERIA' ? (
                    selectedCriteria ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.deleteButton}
                            sx={{ backgroundColor: '#FDDFB0 !important' }}
                            onClick={handleGoToCriteria}
                        >
                            Gå til kriteriet
                        </Button>
                    ) : (
                        <></>
                    )
                ) : selectedCriteria && task.climateReportId ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.deleteButton}
                        sx={{ backgroundColor: '#FDDFB0 !important' }}
                        onClick={handleGoToCriteria}
                    >
                        Gå til kriteriet
                    </Button>
                ) : (
                    <></>
                )}
                <DialogPopUp
                    openDialog={openDialog}
                    handleCloseDialog={handleCloseDialog}
                    fullWidth={true}
                    maxWidth="sm"
                    title="Sikker på at du vil slette aktiviteten?"
                    text="Når aktiviteten er slettet vil du ikke kunne hente den igjen og vil forsvinne fra listen over aktiviteter."
                    addButton={
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                width: 130,
                                backgroundColor: '#F3CCD1',
                            }}
                            onClick={props.deleteTask}
                        >
                            Slett aktiviteten
                        </Button>
                    }
                />
            </Grid>
        </Grid>
    );
}
