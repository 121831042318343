import React, { useState, useEffect } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { ArrowRight, Business } from "../../../../components/Icons";
import ProgressBars from "../../../../components/ProgessBar";
import useRouter from "../../../../hooks/useRouter";
import { useStyles } from "../CertificationStyles";

export default function CompanyInfoModule(props) {
  const { data, responses } = props;
  const router = useRouter();
  const classes = useStyles();
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [percentFinished, setPercentFinished] = useState(0);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [obligRemaining, setObligRemaining] = useState(0);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      getAllValues();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Function that fetches and sets all required data
  const getAllValues = async () => {
    let questionAmount = 0;
    let obligAmount = 0;
    let totalPoints = 0;

    let questionsCompleted = 0;
    let points = 0;

    for (const subcategory of data.subcategories.items) {
      questionAmount = questionAmount + subcategory.questions.items.length;

      for (const question of subcategory.questions.items) {
        if (question.required) {
          obligAmount = obligAmount + 1;
        }

        totalPoints = totalPoints + question.pointValue;

        let index = responses.findIndex(
          (response) => response.questionId === question.id
        );

        if (index !== -1) {
          if (responses[index].isAnswered) {
            if (question.required) {
              obligAmount = obligAmount - 1;
            }

            questionsCompleted = questionsCompleted + 1;
          }

          if (responses[index].pointsEarned > question.pointValue) {
            points = points + question.pointValue;
          } else {
            points = points + responses[index].pointsEarned;
          }
        }
      }
    }

    let totalPercent = 100 / questionAmount;

    setTotalQuestions(questionAmount);
    setQuestionsAnswered(questionsCompleted);
    setObligRemaining(obligAmount);
    setPercentFinished(Math.ceil(totalPercent * questionsCompleted));
  };

  const cbg = "#C1E6EB";
  const pcbg = "#E0F3F5";

  return (
    <Paper className={classes.modulePaper}>
      <Typography variant="h3" sx={{ backgroundColor: cbg }}>
        {Business} {props.data.name}
      </Typography>
      <Grid item style={{ padding: "20px 20px 0px 20px" }}>
        <Typography variant="body1">{props.data.description}</Typography>
        <br />
      </Grid>
      <Grid item>
        <Grid item sx={{ padding: "0px 20px 20px 20px" }}>
          <Typography variant="h4" className={classes.progressionText}>
            {totalQuestions - questionsAnswered === 0 ? (
              <>Fullført</>
            ) : (
              <>
                {totalQuestions - questionsAnswered} spørsmål gjenstår (
                {obligRemaining} obligatoriske)
              </>
            )}
          </Typography>
          <ProgressBars value={percentFinished} backgroundColor={cbg} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: pcbg,
          }}
          className={classes.modulesFooter}
        >
          <Typography variant="body2"></Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: cbg,
              },
            }}
            onClick={() => router.push("/declaration/company")}
          >
            {totalQuestions - questionsAnswered === 0 ? (
              <>Endre</>
            ) : (
              <>Fortsett</>
            )}{" "}
            {ArrowRight}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
