import React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import ProgressBars from "../../../../components/ProgessBar";
import { ArrowRight } from "../../../../components/Icons";
import { useHistory } from "react-router";
import { Industry } from "../../../../components/Icons";
import { useStyles } from "../CertificationStyles";

export default function IndustrySpecific() {
  const classes = useStyles();
  const history = useHistory();

  const ibg = "#CEBEDD";
  const pibg = "#E7DEEE";

  return (
    <Paper className={classes.modulePaper}>
      <Typography variant="h3" sx={{ backgroundColor: ibg }}>
        {Industry} 5.0 Bransjespesifikke kriterier
      </Typography>
      <Grid item style={{ padding: "20px", position: "relative" }}>
        <Grid className={classes.overlay}>
          <Typography variant="h2">Kommer snart!</Typography>
        </Grid>
        <Typography variant="body1">
          I tillegg til standardiserte kriterier på tvers av bransjer, har flere
          bransjer egne rutiner og standarder som virksomheten må følge og
          rapportere på. Hensikten med denne delen av sertifiseringen er å guide
          virksomheten igjennom de til enhver tid gjeldende kriterier for egen
          bransje. Informasjonen vil komme som en egen del i tillegg til
          sertifiseringen.
        </Typography>
        <br />
        <Typography variant="h4" className={classes.progressionText}>
          23 spørsmål gjenstår
        </Typography>
        <ProgressBars value={10} backgroundColor={ibg} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: pibg,
          position: "relative",
        }}
        className={classes.modulesFooter}
      >
        <Grid className={classes.overlay}></Grid>
        <Typography variant="body2">Poeng: 8.5 av 21</Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#fff",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: ibg,
            },
          }}
          onClick={() => history.push("/questionaire")}
        >
          Fortsett {ArrowRight}
        </Button>
      </Grid>
    </Paper>
  );
}
