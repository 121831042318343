import { makeStyles } from "@mui/styles";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

export const useStyles = makeStyles((theme) => ({
  activeButton: {
    width: "170px",
    height: "50px",
    fontSize: "18px",
    borderRadius: "5px 5px 0px 0px",
    "&:hover": {
      backgroundColor: "#C1E6EB",
    },
  },
  unActiveButton: {
    width: "170px",
    height: "50px",
    fontSize: "18px",
    borderRadius: "5px 5px 0px 0px",
    opacity: 0.5,
    "&:hover": {
      opacity: 1,
      backgroundColor: "#C1E6EB",
    },
  },
  viewerSection: {
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
  pdfWarning: {
    color: "#FF0000",
    [breakpoints.up("lg")]: {
      display: "none",
    },
  },
  thumbStyle: {
    display: "inline-block",
    verticalAlign: "middle",
    transform: "perspective(1px) translateZ(0)",
    boxShadow: "0 0 1px transparent",
    transitionDuration: "0.3s",
    transitionProperty: "transform",
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.1)",
    },
  },
}));
