import React from 'react';
import { Grid } from '@mui/material';
import UserView from './components/UserView';
import UserInviteView from './components/UserInviteView';
import CompanyView from './components/CompanyView';
import CompanyUsersView from './components/CompanyUsersView';
import CompanySummary from './components/CompanySummary';
import { useAuth } from '../../../auth/useAuth';

export default function Profile() {
    const auth = useAuth();

    return (
        <Grid item xs={12}>
            <UserView />
            <CompanyView />
            {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? <CompanySummary /> : <></>}
            {auth.isAdmin ? <UserInviteView /> : <></>}
            <CompanyUsersView />
        </Grid>
    );
}
