import React, { useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    Typography,
    TableContainer,
    Table,
    TableBody,
    Button,
    Divider,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useStyles } from '../../ClimateStyles';
import useRouter from '../../../../../hooks/useRouter';
import Leaves from '../../../../../assets/images/leaves.svg';
import * as Text from '../../../../../helpers/climateAccounting.json';
import PowerHeat from './PowerHeat';
import PowerProvider from './PowerProvider';
import { Finished, UnFinished, ArrowRight } from '../../../../../components/Icons';

export default function Scope2(props) {
    const router = useRouter();

    const [openStates, setOpenStates] = useState(router.query.criteria ? [true, true] : [false, false]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (router.query.criteria) {
                if (router.query.criteria !== 'Strøm og fjernvarme') {
                    changeOpen(0, false);
                }

                if (router.query.criteria !== 'Strømleverandør') {
                    changeOpen(1, false);
                }

                const findPosition = obj => {
                    var currenttop = 0;
                    if (obj.offsetParent) {
                        do {
                            currenttop += obj.offsetTop;
                        } while ((obj = obj.offsetParent));
                        return [currenttop];
                    }
                };

                if (document.getElementById(router.query.criteria)) {
                    window.scrollTo(0, findPosition(document.getElementById(router.query.criteria)));
                }
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const ApplicableCheckBox = (check, handleCheck) => {
        return (
            <FormGroup sx={{ width: '125px' }}>
                <FormControlLabel
                    sx={{ fontSize: '13px !important', margin: '0px' }}
                    control={<Checkbox checked={!check} onChange={handleCheck} />}
                    label="Ikke aktuelt"
                />
            </FormGroup>
        );
    };

    const CollapseColor = (isAnswered, open) => {
        if (isAnswered) {
            return '#AFECE1';
        } else if (open) {
            return '#EBEBEB';
        } else {
            return '#FFFFFF';
        }
    };

    const CheckedColor = isAnswered => {
        if (isAnswered) {
            return '#AFECE1';
        } else {
            return '#EBEBEB';
        }
    };

    const IconChange = isAnswered => {
        if (isAnswered) {
            return Finished;
        } else {
            return UnFinished;
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const changeOpen = (index, state) => {
        let newArr = openStates;

        newArr[index] = state;

        setOpenStates([...newArr]);
    };

    const openNext = current => {
        let newArr = openStates;

        newArr[current] = false;
        newArr[current + 1] = true;

        setOpenStates([...newArr]);
    };

    const classes = useStyles();
    return (
        <Paper elevation={1}>
            <Grid item xs={12}>
                <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={2} className={classes.summaryContent}>
                        <img src={Leaves} alt="Yellow and blue leaves" className="summaryImage"></img>
                    </Grid>
                    <Grid item xs={10} className={classes.summaryDescription}>
                        <Typography variant="body1">{Text.Scope2.Summary}</Typography>
                    </Grid>
                </Paper>
            </Grid>
            <Grid>
                <Grid item xs={12}>
                    <Typography id="energy" variant="h1" className={classes.title}>
                        Energi
                    </Typography>
                </Grid>
                <TableContainer>
                    <Table aria-label="collapsible table">
                        <TableBody>
                            <PowerHeat
                                CollapseColor={CollapseColor}
                                CheckedColor={CheckedColor}
                                IconChange={IconChange}
                                ApplicableCheckBox={ApplicableCheckBox}
                                updateReport={props.updateReport}
                                reportId={props.report.id}
                                questionId={'Strøm og fjernvarme'}
                                factorId={'Strøm og fjernvarme'}
                                data={props.report.responses.items.find(response => {
                                    return response.questionId === 'Strøm og fjernvarme';
                                })}
                                index={0}
                                open={openStates[0]}
                                changeOpen={changeOpen}
                                openNext={openNext}
                                tasks={props.tasks}
                                updateTasks={props.updateTasks}
                                users={props.users}
                            />
                            <PowerProvider
                                CollapseColor={CollapseColor}
                                CheckedColor={CheckedColor}
                                IconChange={IconChange}
                                ApplicableCheckBox={ApplicableCheckBox}
                                updateReport={props.updateReport}
                                reportId={props.report.id}
                                questionId={'Strømleverandør'}
                                data={props.report.responses.items.find(response => {
                                    return response.questionId === 'Strømleverandør';
                                })}
                                index={1}
                                open={openStates[1]}
                                changeOpen={changeOpen}
                                openNext={openNext}
                                tasks={props.tasks}
                                updateTasks={props.updateTasks}
                                users={props.users}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Divider sx={{ margin: '30px 0px' }} />
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    sx={{ marginRight: '20px', backgroundColor: '#EBEBEB' }}
                    onClick={scrollToTop}
                    component={NavLink}
                    to={'/climate/' + props.report.id + '/scope1'}
                    exact
                >
                    Tilbake
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginRight: '20px', backgroundColor: '#FDDFB0' }}
                    onClick={scrollToTop}
                    component={NavLink}
                    to={'/climate/' + props.report.id + '/scope3'}
                    exact
                >
                    Neste {ArrowRight}
                </Button>
            </Grid>
        </Paper>
    );
}
