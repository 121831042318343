import React from 'react';
import { InputLabel, InputBase, Grid, NativeSelect } from '@mui/material';

export default function Select(props) {
    const { data, input, setInput, reportIsCompleted } = props;

    const handleChange = ({ target }) => {
        setInput({
            ...input,
            [target.id]: target.value,
        });
    };

    return (
        <Grid>
            <InputLabel>{data.label} *</InputLabel>
            <NativeSelect
                id={data.id}
                input={<InputBase />}
                className="input"
                style={{ marginRight: 30 }}
                value={input[data.id] || ''}
                onChange={handleChange}
                disabled={reportIsCompleted}
            >
                <option value="" disabled>
                    Velg ett alternativ
                </option>
                {data.alternatives &&
                    data.alternatives
                        .sort((a, b) => {
                            return a.order - b.order;
                        })
                        .map((alt) => {
                            return (
                                <option key={alt.id} value={alt.id}>
                                    {alt.label}
                                </option>
                            );
                        })}
            </NativeSelect>
        </Grid>
    );
}
