import { Box, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import SelectCompany from './components/SelectCompany';
import CreateCompany from './components/CreateCompany';
import JoinCompany from './components/JoinCompany';
import WaitingOnApproval from './components/WaitingOnApproval';
import DeclinedByCompany from './components/DeclinedByCompany';
import Trial from './components/Trial';
import ExpiredTrial from './components/ExpiredTrial';
/* import Subscription from "./components/Subscription"; */
import { Arrow } from '../../../components/Icons';
import { useAuth } from '../../../auth/useAuth';
import { useStyles } from './TermsStyle';

export default function Terms(props) {
    const classes = useStyles();
    const auth = useAuth();
    const [view, setView] = useState(props.view || 'select');
    const [brregData, setBrregData] = useState(null);

    const handleSetView = string => {
        setView(string);
    };

    const handleUpdateBrregData = data => {
        setBrregData(data);
    };

    // Logic for what views appear to the user based on URL criterias
    const checkForView = () => {
        if (view === 'create') {
            return <CreateCompany data={brregData} setView={handleSetView} />;
        } else if (view === 'join') {
            return <JoinCompany data={brregData} setView={handleSetView} />;
        } else if (view === 'waiting') {
            return <WaitingOnApproval setView={handleSetView} />;
        } else if (view === 'declined') {
            return <DeclinedByCompany setView={handleSetView} updateBrregData={handleUpdateBrregData} />;
        } else if (view === 'payment') {
            return <Trial />;
        } else if (view === 'expiredtrial') {
            return <ExpiredTrial setView={handleSetView} />;
        } else {
            return <SelectCompany setView={handleSetView} updateBrregData={handleUpdateBrregData} />;
        }
    };

    return (
        <Box className={classes.box} style={{ backgroundColor: '#BBCBE4' }}>
            <Button variant="contained" startIcon={Arrow} className={classes.topButton} onClick={() => auth.signOut()}>
                Logg ut
            </Button>
            <Grid item xs={12} className={classes.content}>
                {checkForView()}
            </Grid>
        </Box>
    );
}
