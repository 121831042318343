import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, Svg, Rect, Font, Image } from '@react-pdf/renderer';
import Bronze from '../../../../assets/images/Bronze.png';
import Silver from '../../../../assets/images/Silver.png';
import Gold from '../../../../assets/images/Gold.png';
import Start from '../../../../assets/images/start.png';
import Logo from '../../../../assets/images/logo.png';
//import Social from "../../../../assets/images/social.png";
//import Enviro from "../../../../assets/images/environ.png";
//import Eco from "../../../../assets/images/eco.png";

import getDateFromTimestamp from '../../../../helpers/getDateFromTimestamp';
import { getBadgeFromTotalEarnedPoints } from '../../../../helpers/badgeFunctions';

Font.register({
    family: 'Yanone',
    src: 'https://fonts.gstatic.com/s/yanonekaffeesatz/v7/We_iSDqttE3etzfdfhuPRbq92v6XxU4pSv06GI0NsGc.ttf',
});
Font.register({
    family: 'OpenSansCondensed',
    src: 'https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xEMwSSh38KQVJx4ABtsZTnA.ttf',
});
Font.register({
    family: 'OpenSansCondensedItalic',
    src: 'https://fonts.gstatic.com/s/opensanscondensed/v10/jIXlqT1WKafUSwj6s9AzV4_LkTZ_uhAwfmGJ084hlvM.ttf',
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#F4F4F4',
    },
    section: {
        flexGrow: 1,
    },
    sidebar: {
        backgroundColor: '#ffffff',
        width: '180px',
        height: '100%',
    },
    main: {
        width: '415px',
        height: '100%',
        display: 'flex',
    },
    mainSection: {
        padding: '10px 20px 10px 20px',
    },
    graphSection: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
    },
    graphBox: {
        backgroundColor: '#ffffff',
        width: '50%',
        borderRadius: '5px',
    },
    h1: {
        fontSize: '35px',
        textAlign: 'left',
        borderBottom: '3px solid #F3CCD1',
        fontFamily: 'Yanone',
    },
    h2: {
        fontSize: '25px',
        padding: '20px 20px 0px 20px',
        textAlign: 'center',
        fontFamily: 'Yanone',
    },
    h3: {
        fontFamily: 'Yanone',
        fontSize: '20px',
    },
    header: {
        fontFamily: 'Yanone',
        fontSize: '18px',
        padding: '5px 10px',
    },
    body: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '11px',
        padding: '0px 20px 20px 20px',
        textAlign: 'center',
    },
    body2: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '11px',
        padding: '10px 0px',
        textAlign: 'center',
    },
    body3: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '10px',
        padding: '10px 0px',
        textAlign: 'left',
    },
    stats: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    cell: {
        width: '50%',
        padding: '0px 20px',
    },
    cell2: {
        width: '50%',
        backgroundColor: '#E0F3F5',
        padding: '0px 20px',
    },
    badge: {
        width: '80%',
        display: 'flex',
        alignSelf: 'center',
    },
    footer: {
        backgroundColor: '#E0F3F5',
        fontSize: 12,
        position: 'absolute',
        bottom: 0,
        fontFamily: 'OpenSansCondensed',
        padding: '10px 50px 10px 50px',
        width: '100%',
    },
    logo: {
        width: '30%',
        display: 'flex',
        alignSelf: 'center',
    },
    logo2: {
        width: '70%',
        display: 'flex',
        alignSelf: 'center',
    },
    graph: {
        width: '70%',
        display: 'flex',
        alignSelf: 'center',
    },
});

const getBadgeImage = totalPercentScore => {
    if (getBadgeFromTotalEarnedPoints(totalPercentScore) === 'bronze') {
        return <Image src={Bronze} style={styles.badge} />;
    } else if (getBadgeFromTotalEarnedPoints(totalPercentScore) === 'silver') {
        return <Image src={Silver} style={styles.badge} />;
    } else if (getBadgeFromTotalEarnedPoints(totalPercentScore) === 'gold') {
        return <Image src={Gold} style={styles.badge} />;
    } else {
        return <Image src={Start} style={styles.badge} />;
    }
};

const getBadgeText = totalPercentScore => {
    if (getBadgeFromTotalEarnedPoints(totalPercentScore) === 'bronze') {
        return <Text style={styles.body2}>Tildelt valørmerke: Bronsje</Text>;
    } else if (getBadgeFromTotalEarnedPoints(totalPercentScore) === 'silver') {
        return <Text style={styles.body2}>Tildelt valørmerke: Sølv</Text>;
    } else if (getBadgeFromTotalEarnedPoints(totalPercentScore) === 'gold') {
        return <Text style={styles.body2}>Tildelt valørmerke: Gull</Text>;
    } else {
        return <Text style={styles.body2}>Tildelt valørmerke: Egenerklæring påbegynt.</Text>;
    }
};

// Create Document Component
export const OverviewDocument = props => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <View style={styles.sidebar}>
                    <Text style={styles.h2}>Ecofric bærekraftscore</Text>
                    {getBadgeImage(props.data.totalPercentScore)}
                    {getBadgeText(props.data.totalPercentScore)}
                    <Text style={[styles.body2, { backgroundColor: '#E0F3F5' }]}>
                        Egenerklæring oppdatert: {getDateFromTimestamp(props.data.expDate)}
                    </Text>
                    <Svg viewBox="0 0 220 20">
                        <Rect width="100%" height="4" fill="#F3CCD1" x="0" y="0" />
                    </Svg>
                    <Text style={styles.body}>{props.data.description}</Text>
                    <View style={styles.row}>
                        <View style={styles.cell}>
                            <Text style={styles.body3}>Nettsted</Text>
                        </View>
                        <View style={[styles.cell, { padding: '0px 0px' }]}>
                            <Text style={styles.body3}>{props.data.website}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cell2}>
                            <Text style={styles.body3}>Kontaktperson</Text>
                        </View>
                        <View style={[styles.cell2, { padding: '0px 0px' }]}>
                            <Text style={styles.body3}>{props.data.contactPerson}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cell}>
                            <Text style={styles.body3}>Telefon</Text>
                        </View>
                        <View style={[styles.cell, { padding: '0px 0px' }]}>
                            <Text style={styles.body3}>{props.data.phoneNumber}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cell2}>
                            <Text style={styles.body3}>Org.nr</Text>
                        </View>
                        <View style={[styles.cell2, { padding: '0px 0px' }]}>
                            <Text style={styles.body3}>{props.data.orgNumber}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.section}>
                <View style={styles.main}>
                    <Svg viewBox="0 0 220 20">
                        <Rect width="100%" height="20" fill="#E0F3F5" x="0" y="0" />
                    </Svg>
                    <View style={styles.mainSection}>
                        <Text style={styles.h1}>Oversiktsrapport</Text>
                        <Text style={[styles.h3, { marginBottom: '20px' }]}>For {props.data.companyName}</Text>
                        <View style={styles.graphSection}>
                            <View style={[styles.graphBox, { marginRight: '5px' }]}>
                                <Text style={[styles.header, { backgroundColor: '#BBCBE4' }]}>Økonomi og drift</Text>
                                {/* <Image src={Eco} style={styles.graph} /> */}
                                <Text style={[styles.body, { padding: '20px 20px 0px 20px' }]}>
                                    Organisasjonen har oppnådd:
                                </Text>
                                <Text style={[styles.stats, { paddingBottom: '10px' }]}>
                                    {props.data.economyPercentScore}% av 100%
                                </Text>
                                <Text style={styles.body}>
                                    I økonomi og drift kartlegges bedriftens selskapsstyring og bærekraftsarbeid. Dette
                                    omhandler muligheter, utfordringer og risiko for virksomheten og identifisering av
                                    fremtidige forbedringsområder og tiltak.
                                </Text>
                            </View>
                            <View style={[styles.graphBox, { marginLeft: '5px' }]}>
                                <Text style={[styles.header, { backgroundColor: '#F3CCD1' }]}>Sosiale forhold</Text>
                                {/* <Image src={Social} style={styles.graph} /> */}
                                <Text style={[styles.body, { padding: '20px 20px 0px 20px' }]}>
                                    Organisasjonen har oppnådd:
                                </Text>
                                <Text style={[styles.stats, { paddingBottom: '10px' }]}>
                                    {props.data.socialPercentScore}% av 100%
                                </Text>
                                <Text style={styles.body}>
                                    Under sosiale forhold kartlegges det hvordan bedriften ivaretar ansatte, andre
                                    berørte parter i verdikjeden og lokalmiljøet. Det synliggjøres hvordan bedriften tar
                                    ansvar for sine omgivelser og bidrar til bedre sosiale forhold.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.graphSection}>
                            <View style={[styles.graphBox, { marginRight: '5px' }]}>
                                <Text style={[styles.header, { backgroundColor: '#C3E8D0' }]}>Miljø og klima</Text>
                                {/* <Image src={Enviro} style={styles.graph} /> */}
                                <Text style={[styles.body, { padding: '20px 20px 0px 20px' }]}>
                                    Organisasjonen har oppnådd:
                                </Text>
                                <Text style={[styles.stats, { paddingBottom: '10px' }]}>
                                    {props.data.environmentalPercentScore}% av 100%
                                </Text>
                                <Text style={styles.body}>
                                    Gjennom kartlegging av bedriftens negative økologiske fotavtrykk kan det
                                    identifiseres tiltak som bidrar til å minimere fotavtrykkene og sløsing. Målet er å
                                    ta vare på natur, klima og jorden som fornybar ressurs.
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.footer}>
                        <Image src={Logo} style={styles.logo} />
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);

export default function OnePagePDF(props) {
    return (
        <PDFViewer width="100%" height="730">
            <OverviewDocument data={props.data} />
        </PDFViewer>
    );
}
