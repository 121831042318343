import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Chip,
    Button,
    IconButton,
    Hidden,
    Slide,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ArrowRight, Delete } from '../../../../components/Icons';
import useRouter from '../../../../hooks/useRouter';
import { useAuth } from '../../../../auth/useAuth';
import { fetchAllNSRSReports, deleteReport } from '../nsrsFunctions';
import getDateFromTimestamp from '../../../../helpers/getDateFromTimestamp';

export default function NSRSList() {
    const router = useRouter();
    const auth = useAuth();

    const [reports, setReports] = useState(null);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            fetchReports();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchReports = async () => {
        await fetchAllNSRSReports(auth.companyData.companyId).then(data => {
            setReports(data);
        });
    };

    const handleDeleteReport = async id => {
        await deleteReport(id).then(async () => {
            await fetchReports();
        });
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const DeleteDialog = ({ reportId, reportYear }) => {
        const [openDialog, setOpenDialog] = useState(false);
        const [isDeleting, setIsDeleting] = useState(false);

        const handleDialogDeleteReport = async () => {
            setIsDeleting(true);

            await deleteReport(reportId).then(async () => {
                await fetchReports().then(() => {
                    setIsDeleting(false);
                });
            });
        };

        const handleOpenDialog = () => {
            setOpenDialog(true);
        };

        const handleCloseDialog = () => {
            setOpenDialog(false);
        };

        return (
            <>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth="lg"
                    TransitionComponent={Transition}
                >
                    <DialogTitle sx={{ textAlign: 'left', fontSize: '30px' }}>
                        Sikker på at du vil slette NSRS rapporten?
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            Om du trykker slett vil NSRS rapporten: '{reportYear !== '' ? reportYear : 'Ingen tittel'}'
                            bli slettet permanent.
                        </Typography>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleCloseDialog}>
                            Lukk
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: '150px',
                                height: '50px',
                            }}
                            autoFocus
                            onClick={handleDialogDeleteReport}
                            disabled={isDeleting}
                        >
                            {isDeleting ? <CircularProgress size={36} sx={{ color: 'turquoise' }} /> : 'Slett'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <IconButton onClick={handleOpenDialog}>{Delete}</IconButton>
            </>
        );
    };

    return (
        <Paper>
            <Typography variant="h3">Liste over NSRS rapporter</Typography>
            <Grid item xs={12} sx={{ padding: '20px' }}>
                {reports && reports.length !== 0 ? (
                    <>
                        <Hidden lgDown>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ width: '10%' }}>
                                                Status
                                            </TableCell>
                                            <TableCell sx={{ width: '30%' }}>Rapporteringsperiode</TableCell>
                                            <TableCell sx={{ width: '20%' }}>Opprettet</TableCell>
                                            <TableCell sx={{ width: '20%' }}>Sist redigert</TableCell>
                                            <TableCell sx={{ width: '20%' }}>Opprettet av</TableCell>
                                            <TableCell sx={{ width: '10%' }} align="center">
                                                Slett
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }} align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reports &&
                                            reports.map(report => {
                                                return (
                                                    <TableRow key={report.id}>
                                                        <TableCell align="center">
                                                            {report.status === 'Draft' ? (
                                                                <Chip
                                                                    label={'Utkast'}
                                                                    sx={{
                                                                        backgroundColor: '#EBEBEB',
                                                                    }}
                                                                ></Chip>
                                                            ) : (
                                                                <Chip
                                                                    label={'Fullført'}
                                                                    sx={{
                                                                        backgroundColor: '#FDDFB0',
                                                                    }}
                                                                ></Chip>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>{report.reportYear}</TableCell>
                                                        <TableCell>{getDateFromTimestamp(report.createdAt)}</TableCell>
                                                        <TableCell>{getDateFromTimestamp(report.updatedAt)}</TableCell>
                                                        <TableCell>{report.userCreatedBy}</TableCell>
                                                        <TableCell align="center">
                                                            <DeleteDialog
                                                                reportId={report.id}
                                                                reportYear={report.reportYear}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: '#FDDFB0',
                                                                }}
                                                                onClick={() => router.push('/nsrs/' + report.id)}
                                                            >
                                                                Fortsett {ArrowRight}
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Hidden>
                        <Hidden lgUp>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ width: '10%' }}>
                                                Status
                                            </TableCell>
                                            <TableCell sx={{ width: '30%' }}>Rapporteringsperiode</TableCell>
                                            <TableCell sx={{ width: '30%' }}>Sist redigert</TableCell>
                                            <TableCell sx={{ width: '30%' }}>Opprettet av</TableCell>
                                            <TableCell sx={{ width: '10%' }} align="center">
                                                Slett
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }} align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reports &&
                                            reports.map(report => {
                                                return (
                                                    <TableRow key={report.id}>
                                                        <TableCell align="center">
                                                            <Chip
                                                                label={'Utkast'}
                                                                sx={{
                                                                    backgroundColor: '#EBEBEB',
                                                                }}
                                                            ></Chip>
                                                        </TableCell>
                                                        <TableCell>{report.reportYear}</TableCell>
                                                        <TableCell>{getDateFromTimestamp(report.updatedAt)}</TableCell>
                                                        <TableCell>{report.userCreatedBy}</TableCell>
                                                        <TableCell align="center">
                                                            <IconButton onClick={() => handleDeleteReport(report.id)}>
                                                                {Delete}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: '#FDDFB0',
                                                                }}
                                                                onClick={() => router.push('/nsrs/' + report.id)}
                                                            >
                                                                Fortsett {ArrowRight}
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Hidden>
                    </>
                ) : (
                    <Typography variant="h2" sx={{ textAlign: 'center' }}>
                        Ingen utkast av NSRS.
                    </Typography>
                )}
            </Grid>
        </Paper>
    );
}
