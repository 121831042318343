import React from "react";
import { Grid } from "@mui/material";
import Index from "./components/Index";

export default function CertificationIndex() {
  return (
    <Grid item xs={12}>
      <Index />
    </Grid>
  );
}
