import React, { useState, useEffect } from "react";
import { Paper, Grid, Typography, Button } from "@mui/material";
import ClimateOverview from "./components/ClimateOverview";
import { ArrowRight, Climate } from "../../../components/Icons";
import { useStyles } from "./ClimateStyles";
import useRouter from "../../../hooks/useRouter";
import { useAuth } from "../../../auth/useAuth";
import Leaf from "../../../assets/images/leafs_yc.svg";
import { ContentLoading } from "../../../components/Loading";
import * as Text from "../../../helpers/climateAccounting.json";
import {
  fetchClimateReports,
  createNewClimateAccReport,
} from "./climateFunctions";

export default function ClimateAccounting() {
  const classes = useStyles();
  const router = useRouter();
  const auth = useAuth();

  const [reports, setReports] = useState(null);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      fetchReports();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchReports = async () => {
    await fetchClimateReports(auth.companyData.companyId).then((data) => {
      setReports(data);
    });
  };

  const handleStartNewReport = async () => {
    await createNewClimateAccReport(
      auth.companyData.companyId,
      "",
      new Date().getFullYear(),
      JSON.parse(auth.userData.name).fullName
    ).then((data) => {
      router.push({
        pathname: "/climate/" + data.id,
        state: { report: data },
      });
    });
  };

  if (!reports) {
    return <ContentLoading />;
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <Typography variant="h3">{Climate}Klimaregnskap</Typography>
            <Grid item xs={12} className={classes.newSesh}>
              <Grid item xs={2} md={0} lg={0} className={classes.imageSection}>
                <img src={Leaf} alt="Decorative leaves"></img>
              </Grid>
              <Grid
                item
                xs={7}
                sm={9}
                md={9}
                lg={9}
                className={classes.section}
                sx={{ flexDirection: "column" }}
              >
                <Typography variant="h2" sx={{ alignSelf: "flex-start" }}>
                  Nytt klimaregnskap
                </Typography>
                <Typography variant="body1">
                  {Text.start.p1}
                  <br />
                  <br />
                  {Text.start.p2}
                  <br />
                  {Text.start.p3}
                  <br />
                  {Text.start.p4}
                  <br />
                  <br />
                  {Text.start.p5}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3} className={classes.section}>
                <Button
                  variant="contained"
                  className={classes.startButton}
                  onClick={handleStartNewReport}
                >
                  Start {ArrowRight}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <ClimateOverview reports={reports} updateReports={fetchReports} />
        </Grid>
      </Grid>
    );
  }
}
