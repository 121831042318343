import React, { useState } from 'react';
import {
    Paper,
    Grid,
    Typography,
    InputLabel,
    InputBase,
    Button,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    IconButton,
} from '@mui/material';
import { useStyles } from '../ProfileStyles';
import { FirmHeader, Delete } from '../../../../components/Icons';
import { useAuth } from '../../../../auth/useAuth';
import { updateCompanyInviteList, sendInviteUserMail } from '../profileFunctions';

export default function UserInviteView() {
    const classes = useStyles();
    const auth = useAuth();

    const [email, setEmail] = useState('');
    const [invitedUsersList, setInvitedUsersList] = useState(auth.companyData.companyData.invitedUsers || []);

    const handleInviteUser = async () => {
        if (invitedUsersList.some(item => email === item)) {
            setEmail('');
            return;
        }

        let newList = invitedUsersList;

        newList.push(email);

        setInvitedUsersList([...newList]);

        let inputData = {
            id: auth.companyData.companyId,
            invitedUsers: newList,
        };

        await updateCompanyInviteList(inputData).then(async () => {
            await auth.fetchCompanyInfo(auth.companyData.companyId);
            await sendInviteUserMail(auth.companyData.name, auth.companyData.companyId, email);
        });

        setEmail('');
    };

    const handleRemoveInvitedUser = async index => {
        let newList = invitedUsersList;

        newList.splice(index, 1);

        setInvitedUsersList([...newList]);

        let inputData = {
            id: auth.companyData.companyId,
            invitedUsers: newList,
        };

        await updateCompanyInviteList(inputData).then(async () => {
            await auth.fetchCompanyInfo(auth.companyData.companyId);
        });
    };

    return (
        <Paper style={{ marginBottom: '20px' }}>
            <Typography variant="h3">{FirmHeader} Inviter en kollega</Typography>
            <Grid item xs={12} className={classes.spacer}>
                <Typography variant="body1">
                    En invitert bruker vil kunne få direkte tilgang til ditt selskap etter registrering.
                </Typography>
                <InputLabel className={classes.editlabel}>Skriv inn epost</InputLabel>
                <InputBase id="inviteUser" type="text" onChange={e => setEmail(e.target.value)} value={email} />
                <Button variant="contained" color="secondary" className={classes.editbutton} onClick={handleInviteUser}>
                    Inviter
                </Button>
            </Grid>
            <Grid item xs={12} className={classes.spacer}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.TableHeadSize}>Inviterte brukere</TableCell>
                                {!auth.isAdmin ? (
                                    <></>
                                ) : (
                                    <TableCell align="center" className={classes.TableHeadLabel}>
                                        Fjern
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invitedUsersList ? (
                                invitedUsersList.map((user, index) => {
                                    return (
                                        <TableRow key={user}>
                                            <TableCell sx={{ width: '80%' }}>{user}</TableCell>
                                            {!auth.isAdmin ? (
                                                <></>
                                            ) : (
                                                <TableCell sx={{ width: '20%' }} align="center">
                                                    {!auth.isAdmin ? (
                                                        <></>
                                                    ) : (
                                                        <IconButton onClick={() => handleRemoveInvitedUser(index)}>
                                                            {Delete}
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Paper>
    );
}
