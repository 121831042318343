import React, { useState } from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    InputLabel,
    InputBase,
    DialogActions,
    FormControl,
    Slide,
    MenuItem,
    Select,
    TableContainer,
    TableBody,
    Avatar,
    Table,
    AvatarGroup,
    Divider,
    TextField,
} from '@mui/material';
import useRouter from '../../../../hooks/useRouter';
import { useAuth } from '../../../../auth/useAuth';
import { createTask } from '../../Chores/choresFunctions';
import { ArrowRight } from '../../../../components/Icons';
import { ChoreCell, ChoreRow, ChoreHead } from '../ClimateStyles';
import { createNotification } from '../../../../components/private/notificationsFunctions';
import { useStyles } from '../ClimateStyles';
import {
    UnderlineButton,
    BoldButton,
    ItalicButton,
    BlockButton,
} from '../../Chores/components/CommentSection/DraftStyles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import nbLocale from 'date-fns/locale/nb';
import { EditorState, RichUtils, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { ThemeProvider } from '@emotion/react';
import { dateTheme } from '../theme';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Chores(props) {
    const { id, users, updateTasks, tasks, reportId, scope } = props;

    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();

    const [openDialog, setOpenDialog] = useState(false);
    const [swapContent, setSwapContent] = useState(true);

    const [title, setTitle] = useState('');
    const [responsibleUsers, setResponsibleUsers] = useState([]);
    const [deadlineValue, setDeadlineValue] = useState(null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const editor = React.useRef(null);

    function focusEditor() {
        editor.current.focus();
    }

    const handleTabMinChange = () => {
        setSwapContent(true);
    };

    const handleTabAdChange = () => {
        setSwapContent(false);
    };

    const createDelegatedNotifs = async (userArray, task) => {
        for (const userId of userArray) {
            if (userId !== auth.userData.id) {
                let dataObj = {
                    link: '/activities/' + task.id,
                    title: task.title,
                    taggedBy: JSON.parse(auth.userData.name).fullName,
                };

                let inputData = {
                    companyId: auth.companyData.companyId,
                    userId: userId,
                    type: 'DELEGATED_NOTIF',
                    markedAsRead: false,
                    archived: false,
                    data: JSON.stringify(dataObj),
                };

                await createNotification(inputData);
            }
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);

        setTitle('');
        setResponsibleUsers([]);
    };

    const handleChange = event => {
        const {
            target: { value },
        } = event;
        setResponsibleUsers(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleCreateNewChore = async () => {
        let log = editorState.getCurrentContent();

        let newHistoryObj = {
            userId: auth.userData.id,
            action: 'CREATE',
            timestamp: new Date(),
        };

        let historyArr = [];

        historyArr.push(newHistoryObj);

        let inputData = {
            companyId: auth.companyData.companyId,
            type: 'CLIMATE_CRITERIA',
            status: 'Not Started',
            history: JSON.stringify(historyArr),
            title: title,
            description: JSON.stringify(convertToRaw(log)),
            users: responsibleUsers,
            climateReportId: reportId,
            climateCriteria: id,
            climateScope: scope,
        };

        if (deadlineValue) {
            inputData.dueDate = deadlineValue;
        }

        await createTask(inputData).then(async data => {
            await updateTasks();

            await createDelegatedNotifs(responsibleUsers, data);
        });

        handleCloseDialog();
    };

    const renderResponsible = users => {
        return (
            <AvatarGroup max={4} sx={{ justifyContent: 'flex-end' }}>
                {users.map(userId => {
                    let user = props.users.find(user => {
                        return userId === user.id;
                    });

                    let userName = '';

                    if (user) {
                        userName = JSON.parse(user.name)
                            .fullName.match(/\b\w/g)
                            .join('');
                    }

                    return (
                        <Avatar key={user.id} className={classes.avatar}>
                            {userName}
                        </Avatar>
                    );
                })}
            </AvatarGroup>
        );
    };

    const boldText = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
        setEditorState(nextState);
    };

    const underlineText = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE');
        setEditorState(nextState);
    };

    const italicText = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
        setEditorState(nextState);
    };

    const unorderedList = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleBlockType(editorState, 'unordered-list-item');
        setEditorState(nextState);
    };

    const orderedList = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleBlockType(editorState, 'ordered-list-item');
        setEditorState(nextState);
    };

    const dialogPopup = (
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" TransitionComponent={Transition} fullWidth>
            <DialogTitle className={classes.constNew}>Lag nytt gjøremål</DialogTitle>
            <DialogContent>
                <Button variant="contained" onClick={handleTabMinChange}>
                    Minimal utfyllelse
                </Button>
                <Button variant="contained" onClick={handleTabAdChange}>
                    Avansert utfyllelse
                </Button>
                {swapContent ? (
                    <>
                        <InputLabel>Tittel</InputLabel>
                        <InputBase
                            type="text"
                            name="title"
                            placeholder="Skriv inn tittel..."
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                        <InputLabel>
                            Ansvarlig(e) <i>(valgfritt)</i>
                        </InputLabel>
                        <FormControl sx={{ width: '100%' }}>
                            <Select
                                multiple
                                value={responsibleUsers}
                                onChange={handleChange}
                                placeholder="Velg en eller flere ansvarlige..."
                                input={<InputBase placeholder="Velg en eller flere ansvarlige..." />}
                                MenuProps={MenuProps}
                            >
                                {users.map(user => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {JSON.parse(user.name).fullName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                ) : (
                    <>
                        <InputLabel>Tittel</InputLabel>
                        <InputBase
                            type="text"
                            name="title"
                            placeholder="Skriv inn tittel..."
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                        <InputLabel>
                            Beskrivelse <i>(valgfritt)</i>
                        </InputLabel>
                        <div className={classes.richEditGrid} onClick={focusEditor}>
                            <UnderlineButton variant="text" onMouseDown={underlineText}>
                                Understrek
                            </UnderlineButton>
                            <BoldButton variant="text" onMouseDown={boldText}>
                                Fet
                            </BoldButton>
                            <ItalicButton variant="text" onMouseDown={italicText}>
                                Kursiv
                            </ItalicButton>
                            <BlockButton variant="text" onMouseDown={unorderedList}>
                                Punkt liste
                            </BlockButton>
                            <BlockButton variant="text" onMouseDown={orderedList}>
                                Numerert liste
                            </BlockButton>
                            <Divider sx={{ marginBottom: '15px' }} />
                            <Editor
                                ref={editor}
                                editorState={editorState}
                                onChange={setEditorState}
                                placeholder="Skriv beskrivelsen her..."
                            />
                        </div>
                        <InputLabel>
                            Forfallsdato <i>(valgfritt)</i>
                        </InputLabel>
                        <ThemeProvider theme={dateTheme}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={nbLocale}>
                                <DatePicker
                                    mask="__.__.____"
                                    clearable
                                    renderInput={params => {
                                        return (
                                            <TextField
                                                sx={{ width: '100%' }}
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: 'dd/mm/yyyy',
                                                }}
                                            />
                                        );
                                    }}
                                    value={deadlineValue}
                                    onChange={newValue => {
                                        setDeadlineValue(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                        <InputLabel>
                            Ansvarlig(e) <i>(valgfritt)</i>
                        </InputLabel>
                        <FormControl sx={{ width: '100%' }}>
                            <Select
                                multiple
                                value={responsibleUsers}
                                onChange={handleChange}
                                placeholder="Velg en eller flere ansvarlige..."
                                input={<InputBase placeholder="Velg en eller flere ansvarlige..." />}
                                MenuProps={MenuProps}
                            >
                                {props.users.map(user => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {JSON.parse(user.name).fullName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" className={classes.closePopUp} autoFocus onClick={handleCloseDialog}>
                    Lukk
                </Button>
                <Button
                    variant="contained"
                    sx={{ width: '150px', height: '50px' }}
                    autoFocus
                    onClick={handleCreateNewChore}
                    disabled={title.length === 0 ? true : false}
                >
                    Lagre
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Grid>
            {dialogPopup}
            <Button variant="contained" onClick={handleOpenDialog}>
                Legg til nytt gjøremål
            </Button>
            <TableContainer>
                <Table>
                    <ChoreHead>
                        <ChoreRow>
                            <ChoreCell sx={{ width: '25%' }}>Tittel</ChoreCell>
                            <ChoreCell sx={{ width: '10%' }}>Ansvarlig(e)</ChoreCell>
                            <ChoreCell sx={{ width: '10%' }}></ChoreCell>
                        </ChoreRow>
                    </ChoreHead>
                    <TableBody>
                        {tasks.length !== 0 &&
                            tasks.map(task => {
                                if (task.climateCriteria && task.climateCriteria === id) {
                                    return (
                                        <ChoreRow key={task.id}>
                                            <ChoreCell sx={{ width: '60%' }}>{task.title}</ChoreCell>
                                            <ChoreCell sx={{ width: '30%' }} align="left">
                                                {renderResponsible(task.users)}
                                            </ChoreCell>
                                            <ChoreCell sx={{ width: '10%' }}>
                                                <Button
                                                    variant="contained"
                                                    sx={{ width: '100px', backgroundColor: '#eaeaea' }}
                                                    onClick={() =>
                                                        router.push({
                                                            pathname: '/activities/' + task.id,
                                                            state: {
                                                                data: task,
                                                                users: users,
                                                            },
                                                        })
                                                    }
                                                >
                                                    {ArrowRight}
                                                </Button>
                                            </ChoreCell>
                                        </ChoreRow>
                                    );
                                } else {
                                    return <React.Fragment key={task.id}></React.Fragment>;
                                }
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}
