import * as AWS from 'aws-sdk/global';
import { identityPoolConfig } from '../helpers/globalValues';

// Required IDs for the Cognito Identity Pool in backend
// OBS: Remember to change values between production and development

export function setCredentials(session) {
    const { region, poolId, login } = identityPoolConfig;

    AWS.config.region = region;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: poolId,
        Logins: {
            [login]: session.getIdToken().getJwtToken(),
        },
    });
}

export function clearCredentials() {
    if (AWS.config.credentials) {
        const { poolId } = identityPoolConfig;
        const setting = 'aws.cognito.identity-id.' + poolId;

        localStorage.removeItem(setting);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({}); // this is the new instance after the clear
    }
}
