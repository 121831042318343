import React from "react";
import { Hidden, Grid } from "@mui/material";
import Tutorial from "./Tutorial";
import TrialCountDown from "./TrialCountDown";
import { useStyles } from "./PrivateStyles";

export default function Header() {
  const classes = useStyles();

  return (
    <Hidden xsDown>
      <Grid container className={classes.header}>
        <TrialCountDown />
        <Tutorial width="90px" color="#C1E6EB" />
      </Grid>
    </Hidden>
  );
}
