import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid, Typography, Divider, ListItem, List } from "@mui/material";
import Image1 from "../../../assets/images/kslide1.svg";
import Image2 from "../../../assets/images/kslide2.svg";
import Image3 from "../../../assets/images/kslide3.svg";
import Image4 from "../../../assets/images/kslide4.svg";
import Image5 from "../../../assets/images/kslide5.svg";
import Image6 from "../../../assets/images/kslide6.svg";
import * as Text from "../../../helpers/tutorial.json";

export default function ClimateSlide() {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={10000}
      centerMode={false}
      containerClass="container"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
        },
      }}
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image1}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.kslide1.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide1.p1}
          </Typography>
          <Typography>Nøkkelinformasjon:</Typography>
          <List>
            <ListItem>
              - Totalt antall kvm/samlede arealer for egne bygg
            </ListItem>
          </List>
          <Typography>Scope 1:</Typography>
          <List>
            <ListItem>
              - Oppvarming og energiproduksjon fra fossil forbrenning
            </ListItem>
            <ListItem>- Oversikt over egne kjøretøy</ListItem>
            <ListItem>- Brukte kjølegasser eller andre gasser</ListItem>
          </List>
          <Typography>Scope 2:</Typography>
          <List>
            <ListItem>- Strømforbruk</ListItem>
            <ListItem>- Fjernvarme</ListItem>
            <ListItem>- Vannforbruk</ListItem>
          </List>
          <Typography>Scope 3:</Typography>
          <List>
            <ListItem>- Oversikt over arbeidsreiser (reiseregninger)</ListItem>
            <ListItem>- Ansattes transportform til og fra jobb</ListItem>
            <ListItem>- Innkjøp varetransport for egne varer</ListItem>
            <ListItem>
              - Oversikt over bedriftens innkjøp for rapporteringsperioden
            </ListItem>
            <ListItem>
              - Oversikt over bedriftens solgte produkter for
              rapporteringsperioden
            </ListItem>
            <ListItem>- Investeringer dere har utført</ListItem>
            <ListItem>- Avfall og restprodukter dere har produsert</ListItem>
            <ListItem>- Kjemikalier dere har på arbeidsplassen</ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide1.p2}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image2}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.kslide2.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide2.p1}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide2.p2}
          </Typography>
          <List>
            <ListItem>- Nøkkelinformasjon</ListItem>
            <ListItem>- Scope 1 - egne direkte utslipp</ListItem>
            <ListItem>- Scope 2 - energi, varme og vannforbruk</ListItem>
            <ListItem>
              - Scope 3 - andre indirekte utslipp fra bedriften sin verdikjede
            </ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            Skjemaet er bygget opp på følgende enkle måte:
          </Typography>
          <List>
            <ListItem>
              - Et informasjonsfelt der blant annet forbruk, volum, og eventuelt
              annen input legges inn.{" "}
            </ListItem>
            <ListItem>
              - Hjelpetekst med forklaring på hvordan spørsmålene skal besvares.
            </ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide2.p3}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image3}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.kslide3.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide3.p1}
          </Typography>
          <List>
            <ListItem>
              - Tittel på klimaregnskapet. Eks; “Jensens klimaregnskap for
              2022”.
            </ListItem>
            <ListItem>
              - Perioden det skal rapporteres på. Vi anbefaler å følge et vanlig
              regnskapsår.
            </ListItem>
            <ListItem>
              - Totalt antall kvm/samlede arealer for de bygg det skal
              rapporteres på.
            </ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide3.p2}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image4}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.kslide4.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide4.p1}
          </Typography>
          <List>
            <ListItem>
              - Egne oppvarmingsmetoder som ikke er strøm, og andre former for
              fossil forbrenning.
            </ListItem>
            <ListItem>
              - Egne transportmidler, maskiner og redskaper som bedriften eier
              selv.
            </ListItem>
            <ListItem>- Bruk av kjølegasser eller andre gasser.</ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide4.p2}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image5}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.kslide5.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide5.p1}
          </Typography>
          <List>
            <ListItem>
              - Strøm og fjernvarme som er brukt i rapporteringsperioden.
            </ListItem>
            <ListItem>- Strømleverandør.</ListItem>
            <ListItem>- Eget vannforbruk i rapporteringsperioden.</ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide5.p2}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image6}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.kslide6.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide6.p1}
          </Typography>
          <List>
            <ListItem>
              - Alle arbeidsreiser i arbeidstiden som ikke er utført med
              bedriftens egne kjøretøy. Informasjonen kan eksempelvis innhentes
              fra reiseregninger.
            </ListItem>
            <ListItem>- Ansattes transportform til og fra jobb.</ListItem>
            <ListItem>- Innkjøp av varetransport for egne varer.</ListItem>
            <ListItem>- Bedriftens innkjøp av materialer.</ListItem>
            <ListItem>
              - Bedriftens innkjøp av varer, inventar og utstyr.
            </ListItem>
            <ListItem>- Bedriftens leie av varer, inventar og utstyr.</ListItem>
            <ListItem>- Bedriftens solgte varer.</ListItem>
            <ListItem>- Bedriftens utleie av varer.</ListItem>
            <ListItem>- Investeringer bedriften har utført. </ListItem>
            <ListItem>- Restprodukter bedriften har produsert. </ListItem>
            <ListItem>- Avfall bedriften har produsert. </ListItem>
            <ListItem>- Kjemikalier på arbeidsplassen.</ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide6.p2}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide6.p3}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.kslide6.p4}
          </Typography>
        </Grid>
      </Grid>
    </Carousel>
  );
}
