import React, { useState } from 'react';
import {
    Grid,
    InputBase,
    InputLabel,
    TextField,
    NativeSelect,
    Slider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    Button,
    IconButton,
    TableBody,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { Add, Delete } from '../../../../../../components/Icons';
import uuid from 'react-uuid';

const CustomSlider = styled(Slider)({
    color: '#FDDFB0',
    height: 10,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        color: '#000',
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#FDDFB0',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const CustomRow = withStyles(() => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f3f3f3',
            borderTop: '1px solid #EBEBEB',
            borderBottom: '1px solid #EBEBEB',
        },
    },
}))(TableRow);

const CustomCell = withStyles(() => ({
    root: {
        padding: '0px 5px',
        borderBottom: '0px',
    },
    head: {
        padding: '10px 10px 0px 10px',
        fontWeight: 'bold',
    },
    body: {
        borderRight: '1px solid #E8E8E8',
        '&:last-child': {
            borderRight: '0px',
        },
    },
}))(TableCell);

export default function Risks(props) {
    const { data, input, setInput, reportIsCompleted } = props;

    const [timeframe, setTimeframe] = useState('Shortterm');

    const [areaChoice, setAreaChoice] = useState('');

    const [categoriesArr, setCategoriesArr] = useState([]);
    const [categoryChoice, setCategoryChoice] = useState('');

    const [influencesArr, setInfluencesArr] = useState([]);
    const [influenceChoice, setInfluenceChoice] = useState('');

    const [knowledge, setKnowledge] = useState(1);
    const [relevance, setRelevance] = useState(1);
    const [influence, setInfluence] = useState(1);

    const [description, setDescription] = useState('');

    const handleAreaChoice = id => {
        setAreaChoice(id);

        let choice = data.alternatives.find(item => item.id === id);

        if (choice) {
            setCategoriesArr(choice.categories);
            setInfluencesArr(choice.influences);
        }
    };

    const handleAdd = () => {
        if (areaChoice === '' || categoryChoice === '' || influenceChoice === '' || description === '') {
            return;
        }

        let newArr = [];

        let newObj = {
            id: uuid(),
            description: description,
            timeframe: timeframe,
            area: areaChoice,
            category: categoryChoice,
            influence: influenceChoice,
            values: {
                knowledge: knowledge,
                relevance: relevance,
                influence: influence,
            },
        };

        if (input[data.id]) {
            newArr = input[data.id];
        }

        newArr.push(newObj);

        setInput({
            ...input,
            [data.id]: newArr,
        });

        setDescription('');
        setTimeframe('Shortterm');
        setAreaChoice('');
        setCategoryChoice('');
        setCategoriesArr([]);
        setInfluenceChoice('');
        setInfluencesArr([]);
        setKnowledge(1);
        setRelevance(1);
        setInfluence(1);
    };

    const handleRemove = index => {
        let newArr = [];

        newArr = input[data.id];

        newArr.splice(index, 1);

        setInput({
            ...input,
            [data.id]: newArr,
        });
    };

    return (
        <Grid item xs={12}>
            {!reportIsCompleted ? (
                <>
                    <Grid item xs={12}>
                        <InputLabel>Tidsperspektiv *</InputLabel>
                        <FormControl component="fieldset">
                            <RadioGroup
                                value={timeframe}
                                name="radio-buttons-group-timeframe"
                                onChange={e => setTimeframe(e.target.value)}
                                row
                            >
                                <FormControlLabel
                                    style={{ margin: '8px 0px' }}
                                    control={<Radio name="shortterm" value={'Shortterm'} />}
                                    label="Kortsiktig risiko (0 - 2 år)"
                                />
                                <FormControlLabel
                                    style={{ margin: '8px 0px' }}
                                    control={<Radio name="longterm" value={'Longterm'} />}
                                    label="Langsiktig risiko (3+ år)"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item xs={6} sx={{ marginRight: '5px' }}>
                            <InputLabel>Område for risiko *</InputLabel>
                            <NativeSelect
                                input={<InputBase />}
                                className="input"
                                style={{ marginRight: 30 }}
                                value={areaChoice}
                                onChange={e => handleAreaChoice(e.target.value)}
                            >
                                <option value="" disabled>
                                    Velg alternativ
                                </option>
                                {data.alternatives &&
                                    data.alternatives
                                        .sort((a, b) => {
                                            return a.order - b.order;
                                        })
                                        .map(alt => {
                                            return (
                                                <option key={alt.id} value={alt.id}>
                                                    {alt.label}
                                                </option>
                                            );
                                        })}
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={6} sx={{ marginRight: '5px' }}>
                            <InputLabel>Kategori *</InputLabel>
                            <NativeSelect
                                input={<InputBase />}
                                className="input"
                                style={{ marginRight: 30 }}
                                value={categoryChoice}
                                onChange={e => setCategoryChoice(e.target.value)}
                                disabled={areaChoice ? false : true}
                            >
                                <option value="" disabled>
                                    Velg alternativ
                                </option>
                                {categoriesArr &&
                                    categoriesArr
                                        .sort((a, b) => {
                                            return a - b;
                                        })
                                        .map(alt => {
                                            return (
                                                <option key={alt} value={alt}>
                                                    {alt}
                                                </option>
                                            );
                                        })}
                            </NativeSelect>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ marginRight: '5px' }}>
                        <InputLabel>Mulig finansiell påvirkning *</InputLabel>
                        <NativeSelect
                            input={<InputBase />}
                            className="input"
                            style={{ marginRight: 30 }}
                            value={influenceChoice}
                            onChange={e => setInfluenceChoice(e.target.value)}
                            disabled={areaChoice ? false : true}
                        >
                            <option value="" disabled>
                                Velg alternativ
                            </option>
                            {influencesArr &&
                                influencesArr
                                    .sort((a, b) => {
                                        return a - b;
                                    })
                                    .map(alt => {
                                        return (
                                            <option key={alt} value={alt}>
                                                {alt}
                                            </option>
                                        );
                                    })}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <InputLabel>Kunnskap *</InputLabel>
                            <CustomSlider
                                aria-label="Always visible"
                                valueLabelDisplay="on"
                                min={1}
                                max={10}
                                step={1}
                                marks
                                defaultValue={1}
                                value={knowledge}
                                onChange={e => setKnowledge(e.target.value)}
                                style={{ margin: '40px 0px 0px 0px' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Relevans *</InputLabel>
                            <CustomSlider
                                aria-label="Always visible"
                                valueLabelDisplay="on"
                                min={1}
                                max={10}
                                step={1}
                                marks
                                defaultValue={1}
                                value={relevance}
                                onChange={e => setRelevance(e.target.value)}
                                style={{ margin: '40px 0px 0px 0px' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Påvirkning *</InputLabel>
                            <CustomSlider
                                aria-label="Always visible"
                                valueLabelDisplay="on"
                                min={1}
                                max={10}
                                step={1}
                                marks
                                defaultValue={1}
                                value={influence}
                                onChange={e => setInfluence(e.target.value)}
                                style={{ margin: '40px 0px 10px 0px' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Beskrivelse *</InputLabel>
                        <TextField
                            multiline
                            rows={5}
                            name="statement"
                            placeholder="Skriv her..."
                            style={{ width: '100%' }}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '30px' }}>
                        <Button
                            variant="contained"
                            sx={{ width: '130px' }}
                            disabled={
                                areaChoice === '' ||
                                categoryChoice === '' ||
                                influenceChoice === '' ||
                                description === ''
                                    ? true
                                    : false
                            }
                            onClick={handleAdd}
                        >
                            {Add}Legg til
                        </Button>
                    </Grid>
                </>
            ) : (
                <></>
            )}
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <CustomRow
                                sx={{
                                    borderBottom: '2px solid #FDDFB0 !important',
                                }}
                            >
                                <CustomCell>Tid</CustomCell>
                                <CustomCell>Område</CustomCell>
                                <CustomCell>Kategori</CustomCell>
                                <CustomCell>Finansiell påvirkning</CustomCell>
                                <CustomCell>1-10</CustomCell>
                                <CustomCell>Beskrivelse</CustomCell>
                                {!reportIsCompleted ? <CustomCell align="center">Slett</CustomCell> : <></>}
                            </CustomRow>
                        </TableHead>
                        <TableBody>
                            {input[data.id] && input[data.id].length > 0 ? (
                                input[data.id].map((item, index) => {
                                    let timeframeLabel = '';

                                    if (item.timeframe === 'Shortterm') {
                                        timeframeLabel = 'Kortsiktig';
                                    } else {
                                        timeframeLabel = 'Langsiktig';
                                    }

                                    let areaLabel = '';

                                    areaLabel = data.alternatives.find(alt => alt.id === item.area);

                                    return (
                                        <CustomRow key={item.id}>
                                            <CustomCell>{timeframeLabel}</CustomCell>
                                            <CustomCell>{areaLabel.label}</CustomCell>
                                            <CustomCell>{item.category}</CustomCell>
                                            <CustomCell>{item.influence}</CustomCell>
                                            <CustomCell>
                                                Kunnskap: {item.values.knowledge} <br /> Relevans:{' '}
                                                {item.values.relevance} <br /> Påvirkning: {item.values.influence}
                                            </CustomCell>
                                            <CustomCell>{item.description}</CustomCell>
                                            {!reportIsCompleted ? (
                                                <CustomCell align="center">
                                                    <IconButton onClick={() => handleRemove(index)}>
                                                        {Delete}
                                                    </IconButton>
                                                </CustomCell>
                                            ) : (
                                                <></>
                                            )}
                                        </CustomRow>
                                    );
                                })
                            ) : (
                                <CustomRow>
                                    <CustomCell
                                        align="left"
                                        sx={{
                                            padding: '10px 20px 10px 20px !important',
                                        }}
                                    >
                                        Ingen svar lagt til.
                                    </CustomCell>
                                </CustomRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
