import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  Button,
  Box,
  Collapse,
  Divider,
  InputLabel,
  InputBase,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  InputAdornment,
} from "@mui/material";
import { useStyles, CustomCell, CustomRow } from "../../ClimateStyles";
import { ArrowRight, Add, Delete } from "../../../../../components/Icons";
import * as Text from "../../../../../helpers/climateAccounting.json";
import useRouter from "../../../../../hooks/useRouter";
import { updateClimateReport } from "../../climateFunctions";

export default function AreaInput(props) {
  const {
    updateReport,
    CollapseColor,
    CheckedColor,
    IconChange,
    index,
    open,
    changeOpen,
    openNext,
  } = props;
  const classes = useStyles();
  const router = useRouter();
  const [isAnswered, setIsAnswered] = useState(false);
  const [streetNr, setStreetNr] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [postOffice, setPostOffice] = useState("");
  const [sqMeters, setSqMeters] = useState("");
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      fetchHeatedAreas();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchHeatedAreas = () => {
    if (props.report && props.report.infoData) {
      let data = JSON.parse(props.report.infoData);

      if (data.heatedAreas) {
        setAnswers(data.heatedAreas);

        if (data.heatedAreas.length !== 0) {
          setIsAnswered(true);
        }
      }
    }
  };

  const saveData = async () => {
    let data = null;

    if (props.report && props.report.infoData) {
      data = JSON.parse(props.report.infoData);

      data.heatedAreas = answers;
    } else {
      data = {
        heatedAreas: answers,
      };
    }

    const input = {
      id: props.report.id,
      infoData: JSON.stringify(data),
    };

    await updateClimateReport(input).then(async (data) => {
      router.push({
        state: {
          report: data,
        },
      });

      await updateReport();
    });
  };

  const handleAddAnswer = async () => {
    let input = {
      streetNr: streetNr,
      zipCode: zipCode,
      postOffice: postOffice,
      sqMeters: sqMeters,
    };

    let newArr = answers;

    newArr.push(input);

    if (newArr.length !== 0) {
      setIsAnswered(true);
    }

    setAnswers([...newArr]);

    setStreetNr("");
    setZipCode("");
    setPostOffice("");
    setSqMeters("");

    await saveData();
  };

  const handleRemoveAnswer = async (index) => {
    let newArr = answers;

    newArr.splice(index, 1);

    if (newArr.length === 0) {
      setIsAnswered(false);
    }

    setAnswers([...newArr]);

    await saveData();
  };

  const handleSave = async () => {
    await saveData();

    openNext(index);
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.question}
        sx={{
          background: CollapseColor(isAnswered, open),
        }}
        onClick={() => changeOpen(index, !open)}
      >
        <TableCell
          className={classes.iconCell}
          style={{
            backgroundColor: CheckedColor(isAnswered),
            width: "7%",
          }}
          align="center"
        >
          {IconChange(isAnswered)}
        </TableCell>
        <TableCell component="th" scope="row" className={classes.questionRow}>
          <Typography variant="h3">Oppvarmet areal</Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: "20px 20px 0px 20px" }}>
              <Typography variant="body1">
                {Text.KeyInformation.Area}
              </Typography>
              <Divider className={classes.divider} />
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid item xs={12} sx={{ marginRight: "10px" }}>
                  <InputLabel>Gate og gatenr</InputLabel>
                  <InputBase
                    type="text"
                    name="road"
                    placeholder="Skriv her..."
                    value={streetNr}
                    onChange={(e) => setStreetNr(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginRight: "10px" }}>
                  <InputLabel>Postnummer</InputLabel>
                  <InputBase
                    type="number"
                    name="postnumber"
                    placeholder="Skriv her..."
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginRight: "10px" }}>
                  <InputLabel>Poststed</InputLabel>
                  <InputBase
                    type="text"
                    name="place"
                    placeholder="Skriv her..."
                    value={postOffice}
                    onChange={(e) => setPostOffice(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginRight: "10px" }}>
                  <InputLabel>Kvadratmeter som oppvarmes</InputLabel>
                  <InputBase
                    type="number"
                    name="m2"
                    placeholder="Skriv her..."
                    value={sqMeters}
                    onChange={(e) => setSqMeters(e.target.value)}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{ marginRight: "10px" }}
                      >
                        m2
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item sx={{ marginTop: "32px" }}>
                  <Button
                    variant="contained"
                    sx={{ width: "130px" }}
                    onClick={handleAddAnswer}
                    disabled={
                      (streetNr !== "") &
                      (zipCode !== "") &
                      (postOffice !== "") &
                      (sqMeters !== "")
                        ? false
                        : true
                    }
                  >
                    {Add}Legg til
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <CustomRow>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Gate og gatenr
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Postnummer
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Poststed
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Kvadratmeter som oppvarmes
                        </CustomCell>
                        <CustomCell
                          align="center"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Slett
                        </CustomCell>
                      </CustomRow>
                    </TableHead>
                    <TableBody>
                      {answers &&
                        answers.map((answer, index) => {
                          return (
                            <CustomRow key={index}>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.streetNr}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.zipCode}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.postOffice}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.sqMeters} m2
                              </CustomCell>
                              <CustomCell
                                align="center"
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                                onClick={() => handleRemoveAnswer(index)}
                              >
                                {Delete}
                              </CustomCell>
                            </CustomRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container direction="row" className={classes.content}>
                <Button
                  disabled={false}
                  variant="contained"
                  onClick={handleSave}
                >
                  Lagre{ArrowRight}
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "30px" }}></Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: "20px" }} />
    </React.Fragment>
  );
}
