import React, { useState, useEffect } from "react";
import { useAuth } from "../../auth/useAuth";
import {
  Grid,
  Avatar,
  Typography,
  Badge,
  Popover,
  Box,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Hidden,
} from "@mui/material";
/* import { NavLink } from "react-router-dom"; */
import { Alert, Archived, Bell } from "../Icons";
import { useStyles } from "./PrivateStyles";
import PropTypes from "prop-types";
import useRouter from "../../hooks/useRouter";

import { API } from "aws-amplify";
import {
  fetchAllNotifications,
  markNotificationAsRead,
  archiveNotification,
} from "./notificationsFunctions";
import { onUserNotificationByCompanyIdAndUserId } from "../../graphql/subscriptions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Notifications() {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();

  const [notifs, setNotifs] = useState([]);
  const [archivedNotifs, setArchivedNotifs] = useState([]);

  const [warningNumber, setWarningNumber] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    let isMounted = false;
    let subscription;

    if (!isMounted) {
      fetchAndUpdate();

      // Subscribe to updates
      subscription = API.graphql({
        query: onUserNotificationByCompanyIdAndUserId,
        variables: {
          companyId: auth.companyData.companyId,
          userId: auth.userData.id,
        },
      }).subscribe({
        next: ({ provider, value }) => {
          fetchAndUpdate();
        },
        error: (error) => {
          if (process.env.NODE_ENV === "development") {
            console.warn(error);
          }
        },
      });
    }

    return () => {
      isMounted = true;
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAndUpdate = async () => {
    fetchAllNotifications(auth.companyData.companyId, auth.userData.id).then(
      (data) => {
        let archivedArr = [];
        let notifsArr = [];

        for (const notif of data) {
          if (notif.archived) {
            archivedArr.push(notif);
          } else {
            notifsArr.push(notif);
          }
        }
        setNotifs([...notifsArr]);
        setArchivedNotifs([...archivedArr]);

        let warningTotal = 0;

        for (const notif of notifsArr) {
          if (!notif.markedAsRead) {
            warningTotal = warningTotal + 1;
          }
        }

        setWarningNumber(warningTotal);
      }
    );
  };

  const markAsRead = async () => {
    for (const notif of notifs) {
      if (!notif.markAsRead) {
        await markNotificationAsRead(notif.id);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);

    // Mark all notifs as read and set warning to 0
    await markAsRead();
    setWarningNumber(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleArchive = async (e, id) => {
    await archiveNotification(id);
    await fetchAndUpdate();
  };

  return (
    <>
      <Hidden mdDown>
        <Badge
          aria-describedby={id}
          color="secondary"
          badgeContent={warningNumber}
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          {Alert}
        </Badge>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{ width: "710px" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ width: "450px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              sx={{ borderBottom: "3px solid #FDDFB0" }}
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Inbox" {...a11yProps(0)} />
              <Tab label="Arkivert" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid container>
                {notifs.length !== 0 ? (
                  notifs
                    .sort((a, b) => {
                      return (
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime()
                      );
                    })
                    .reverse()
                    .map((notif) => {
                      if (!notif.archived) {
                        if (notif.type === "COMMENT_NOTIF") {
                          let data = JSON.parse(notif.data);
                          let date = new Date(notif.createdAt);

                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                padding: "10px",
                                borderBottom: "2px solid #FDDFB0",
                              }}
                              key={notif.id}
                            >
                              <Grid>
                                <Avatar className={classes.avatar}>
                                  {data.taggedBy.match(/\b\w/g).join("")}
                                </Avatar>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid sx={{ margin: "7px 0px 0px 10px" }}>
                                  <Typography variant="body2">
                                    <u>{data.taggedBy}</u> har tagget deg i
                                    aktiviteten:{" "}
                                    <u onClick={() => router.push(data.link)}>
                                      {data.title}
                                    </u>
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      margin: "0px 0px 7px 0px",
                                      opacity: 0.7,
                                    }}
                                  >
                                    {date.getDate()}/{date.getMonth() + 1}/
                                    {date.getFullYear()}{" "}
                                    {(date.getHours() < 10 ? "0" : "") +
                                      date.getHours()}
                                    :
                                    {(date.getMinutes() < 10 ? "0" : "") +
                                      date.getMinutes()}
                                  </Typography>
                                  {/*<Typography variant="body2">
                                        <u>@Marie Thoresen</u> kan du starte på denne oppgaven så fort som mulig, helst
                                        i dag da vi har dårlig tid.
                                    </Typography>*/}
                                </Grid>
                                <Grid sx={{ margin: "0px 10px" }}>
                                  <Tooltip title="Arkiver" arrow>
                                    <IconButton
                                      sx={{ padding: "7px 0px 0px 0px" }}
                                      onClick={(e) =>
                                        handleArchive(e, notif.id)
                                      }
                                    >
                                      {Archived}
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        } else if (notif.type === "DELEGATED_NOTIF") {
                          let data = JSON.parse(notif.data);
                          let date = new Date(notif.createdAt);

                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                padding: "10px",
                                borderBottom: "2px solid #FDDFB0",
                              }}
                              key={notif.id}
                            >
                              <Grid sx={{ margin: "7px 0px 0px 0px" }}>
                                <Avatar className={classes.avatar}>
                                  {data.taggedBy.match(/\b\w/g).join("")}
                                </Avatar>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid sx={{ margin: "7px 0px 0px 10px" }}>
                                  <Typography variant="body2">
                                    <u>{data.taggedBy}</u> har delegert deg som
                                    ansvarlig i en aktivitet:{" "}
                                    <u onClick={() => router.push(data.link)}>
                                      {data.title}
                                    </u>
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      margin: "0px 0px 7px 0px",
                                      opacity: 0.7,
                                    }}
                                  >
                                    {date.getDate()}/{date.getMonth() + 1}/
                                    {date.getFullYear()}{" "}
                                    {(date.getHours() < 10 ? "0" : "") +
                                      date.getHours()}
                                    :
                                    {(date.getMinutes() < 10 ? "0" : "") +
                                      date.getMinutes()}
                                  </Typography>
                                </Grid>
                                <Grid sx={{ margin: "0px 10px" }}>
                                  <Tooltip title="Arkiver" arrow>
                                    <IconButton
                                      sx={{ padding: "7px 0px 0px 0px" }}
                                      onClick={(e) =>
                                        handleArchive(e, notif.id)
                                      }
                                    >
                                      {Archived}
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        } else
                          return (
                            <React.Fragment key={notif.id}></React.Fragment>
                          );
                      } else
                        return <React.Fragment key={notif.id}></React.Fragment>;
                    })
                ) : (
                  <Grid>
                    <Typography variant="h2" sx={{ padding: "20px 15px" }}>
                      Du har ingen notifikasjoner!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container>
                {archivedNotifs.length !== 0 ? (
                  archivedNotifs
                    .sort((a, b) => {
                      return (
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime()
                      );
                    })
                    .reverse()
                    .map((notif) => {
                      if (notif.archived) {
                        if (notif.type === "COMMENT_NOTIF") {
                          let data = JSON.parse(notif.data);
                          let date = new Date(notif.createdAt);

                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                padding: "10px",
                                borderBottom: "2px solid #FDDFB0",
                              }}
                              key={notif.id}
                            >
                              <Grid sx={{ margin: "7px 0px 0px 0px" }}>
                                <Avatar className={classes.avatar}>
                                  {data.taggedBy.match(/\b\w/g).join("")}
                                </Avatar>
                              </Grid>
                              <Grid sx={{ margin: "7px 0px 0px 10px" }}>
                                <Typography variant="body2">
                                  <u>{data.taggedBy}</u> har tagget deg i
                                  aktiviteten:{" "}
                                  <u onClick={() => router.push(data.link)}>
                                    {data.title}
                                  </u>
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    margin: "0px 0px 7px 0px",
                                    opacity: 0.7,
                                  }}
                                >
                                  {date.getDate()}/{date.getMonth() + 1}/
                                  {date.getFullYear()}{" "}
                                  {(date.getHours() < 10 ? "0" : "") +
                                    date.getHours()}
                                  :
                                  {(date.getMinutes() < 10 ? "0" : "") +
                                    date.getMinutes()}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        } else if (notif.type === "DELEGATED_NOTIF") {
                          let data = JSON.parse(notif.data);
                          let date = new Date(notif.createdAt);

                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                padding: "10px",
                                borderBottom: "2px solid #FDDFB0",
                              }}
                              key={notif.id}
                            >
                              <Grid sx={{ margin: "7px 0px 0px 0px" }}>
                                <Avatar className={classes.avatar}>
                                  {data.taggedBy.match(/\b\w/g).join("")}
                                </Avatar>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid sx={{ margin: "7px 0px 0px 10px" }}>
                                  <Typography variant="body2">
                                    <u>{data.taggedBy}</u> har delegert deg som
                                    ansvarlig i en aktivitet:{" "}
                                    <u onClick={() => router.push(data.link)}>
                                      {data.title}
                                    </u>
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      margin: "0px 0px 7px 0px",
                                      opacity: 0.7,
                                    }}
                                  >
                                    {date.getDate()}/{date.getMonth() + 1}/
                                    {date.getFullYear()}{" "}
                                    {(date.getHours() < 10 ? "0" : "") +
                                      date.getHours()}
                                    :
                                    {(date.getMinutes() < 10 ? "0" : "") +
                                      date.getMinutes()}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        } else
                          return (
                            <React.Fragment key={notif.id}></React.Fragment>
                          );
                      } else
                        return <React.Fragment key={notif.id}></React.Fragment>;
                    })
                ) : (
                  <Grid>
                    <Typography variant="h2" sx={{ padding: "20px 15px" }}>
                      Arkivet er tomt!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </Box>
        </Popover>
      </Hidden>
      <Hidden mdUp>
        <Badge
          aria-describedby={id}
          color="secondary"
          badgeContent={warningNumber}
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          {Bell}
        </Badge>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{ width: "710px" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ width: "450px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              sx={{ borderBottom: "3px solid #FDDFB0" }}
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Inbox" {...a11yProps(0)} />
              <Tab label="Arkivert" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid container>
                {notifs.length !== 0 ? (
                  notifs
                    .sort((a, b) => {
                      return (
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime()
                      );
                    })
                    .reverse()
                    .map((notif) => {
                      if (!notif.archived) {
                        if (notif.type === "COMMENT_NOTIF") {
                          let data = JSON.parse(notif.data);
                          let date = new Date(notif.createdAt);

                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                padding: "10px",
                                borderBottom: "2px solid #FDDFB0",
                              }}
                              key={notif.id}
                            >
                              <Grid>
                                <Avatar className={classes.avatar}>
                                  {data.taggedBy.match(/\b\w/g).join("")}
                                </Avatar>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid sx={{ margin: "7px 0px 0px 10px" }}>
                                  <Typography variant="body2">
                                    <u>{data.taggedBy}</u> har tagget deg i
                                    aktiviteten:{" "}
                                    <u onClick={() => router.push(data.link)}>
                                      {data.title}
                                    </u>
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      margin: "0px 0px 7px 0px",
                                      opacity: 0.7,
                                    }}
                                  >
                                    {date.getDate()}/{date.getMonth() + 1}/
                                    {date.getFullYear()}{" "}
                                    {(date.getHours() < 10 ? "0" : "") +
                                      date.getHours()}
                                    :
                                    {(date.getMinutes() < 10 ? "0" : "") +
                                      date.getMinutes()}
                                  </Typography>
                                  {/*<Typography variant="body2">
                                        <u>@Marie Thoresen</u> kan du starte på denne oppgaven så fort som mulig, helst
                                        i dag da vi har dårlig tid.
                                    </Typography>*/}
                                </Grid>
                                <Grid sx={{ margin: "0px 10px" }}>
                                  <Tooltip title="Arkiver" arrow>
                                    <IconButton
                                      sx={{ padding: "7px 0px 0px 0px" }}
                                      onClick={(e) =>
                                        handleArchive(e, notif.id)
                                      }
                                    >
                                      {Archived}
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        } else if (notif.type === "DELEGATED_NOTIF") {
                          let data = JSON.parse(notif.data);
                          let date = new Date(notif.createdAt);

                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                padding: "10px",
                                borderBottom: "2px solid #FDDFB0",
                              }}
                              key={notif.id}
                            >
                              <Grid sx={{ margin: "7px 0px 0px 0px" }}>
                                <Avatar className={classes.avatar}>
                                  {data.taggedBy.match(/\b\w/g).join("")}
                                </Avatar>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid sx={{ margin: "7px 0px 0px 10px" }}>
                                  <Typography variant="body2">
                                    <u>{data.taggedBy}</u> har delegert deg som
                                    ansvarlig i en aktivitet:{" "}
                                    <u onClick={() => router.push(data.link)}>
                                      {data.title}
                                    </u>
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      margin: "0px 0px 7px 0px",
                                      opacity: 0.7,
                                    }}
                                  >
                                    {date.getDate()}/{date.getMonth() + 1}/
                                    {date.getFullYear()}{" "}
                                    {(date.getHours() < 10 ? "0" : "") +
                                      date.getHours()}
                                    :
                                    {(date.getMinutes() < 10 ? "0" : "") +
                                      date.getMinutes()}
                                  </Typography>
                                </Grid>
                                <Grid sx={{ margin: "0px 10px" }}>
                                  <Tooltip title="Arkiver" arrow>
                                    <IconButton
                                      sx={{ padding: "7px 0px 0px 0px" }}
                                      onClick={(e) =>
                                        handleArchive(e, notif.id)
                                      }
                                    >
                                      {Archived}
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        } else
                          return (
                            <React.Fragment key={notif.id}></React.Fragment>
                          );
                      } else
                        return <React.Fragment key={notif.id}></React.Fragment>;
                    })
                ) : (
                  <Grid>
                    <Typography variant="h2" sx={{ padding: "20px 15px" }}>
                      Du har ingen notifikasjoner!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container>
                {archivedNotifs.length !== 0 ? (
                  archivedNotifs
                    .sort((a, b) => {
                      return (
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime()
                      );
                    })
                    .reverse()
                    .map((notif) => {
                      if (notif.archived) {
                        if (notif.type === "COMMENT_NOTIF") {
                          let data = JSON.parse(notif.data);
                          let date = new Date(notif.createdAt);

                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                padding: "10px",
                                borderBottom: "2px solid #FDDFB0",
                              }}
                              key={notif.id}
                            >
                              <Grid sx={{ margin: "7px 0px 0px 0px" }}>
                                <Avatar className={classes.avatar}>
                                  {data.taggedBy.match(/\b\w/g).join("")}
                                </Avatar>
                              </Grid>
                              <Grid sx={{ margin: "7px 0px 0px 10px" }}>
                                <Typography variant="body2">
                                  <u>{data.taggedBy}</u> har tagget deg i
                                  aktiviteten:{" "}
                                  <u onClick={() => router.push(data.link)}>
                                    {data.title}
                                  </u>
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    margin: "0px 0px 7px 0px",
                                    opacity: 0.7,
                                  }}
                                >
                                  {date.getDate()}/{date.getMonth() + 1}/
                                  {date.getFullYear()}{" "}
                                  {(date.getHours() < 10 ? "0" : "") +
                                    date.getHours()}
                                  :
                                  {(date.getMinutes() < 10 ? "0" : "") +
                                    date.getMinutes()}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        } else if (notif.type === "DELEGATED_NOTIF") {
                          let data = JSON.parse(notif.data);
                          let date = new Date(notif.createdAt);

                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                padding: "10px",
                                borderBottom: "2px solid #FDDFB0",
                              }}
                              key={notif.id}
                            >
                              <Grid sx={{ margin: "7px 0px 0px 0px" }}>
                                <Avatar className={classes.avatar}>
                                  {data.taggedBy.match(/\b\w/g).join("")}
                                </Avatar>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid sx={{ margin: "7px 0px 0px 10px" }}>
                                  <Typography variant="body2">
                                    <u>{data.taggedBy}</u> har delegert deg som
                                    ansvarlig i en aktivitet:{" "}
                                    <u onClick={() => router.push(data.link)}>
                                      {data.title}
                                    </u>
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      margin: "0px 0px 7px 0px",
                                      opacity: 0.7,
                                    }}
                                  >
                                    {date.getDate()}/{date.getMonth() + 1}/
                                    {date.getFullYear()}{" "}
                                    {(date.getHours() < 10 ? "0" : "") +
                                      date.getHours()}
                                    :
                                    {(date.getMinutes() < 10 ? "0" : "") +
                                      date.getMinutes()}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        } else
                          return (
                            <React.Fragment key={notif.id}></React.Fragment>
                          );
                      } else
                        return <React.Fragment key={notif.id}></React.Fragment>;
                    })
                ) : (
                  <Grid>
                    <Typography variant="h2" sx={{ padding: "20px 15px" }}>
                      Arkivet er tomt!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </Box>
        </Popover>
      </Hidden>
    </>
  );
}
