import React from "react";
import { Typography, Grid, Paper, Link } from "@mui/material";
import BenchmarkChart from "./BenchmarkChart";
import {
  Environment,
  Profit,
  Social,
  Industry,
} from "../../../../components/Icons";
import { useStyles } from "../CertificationStyles";
import useRouter from "../../../../hooks/useRouter";

export default function Graphs(props) {
  const {
    ecoTotalPoints,
    socialTotalPoints,
    envTotalPoints,
    ecoEarnedPoints,
    socialEarnedPoints,
    envEearnedPoints,
    isActive,
  } = props;
  const classes = useStyles();
  const router = useRouter();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Link
          className={classes.links}
          onClick={
            isActive
              ? () => router.push("/declaration/economy")
              : () => router.push("/declaration/company")
          }
        >
          <Paper>
            <Typography variant="h3">{Profit}Økonomi og drift</Typography>
            <Grid item className={classes.graphGrid}>
              <BenchmarkChart
                color={"#BBCBE4"}
                points={ecoEarnedPoints}
                total={ecoTotalPoints}
              />
              <br />
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Du har oppnådd:
              </Typography>
              <Typography variant="h4" className={classes.graphPoints}>
                {ecoEarnedPoints} av {ecoTotalPoints} poeng
              </Typography>
            </Grid>
          </Paper>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Link
          className={classes.links}
          onClick={
            isActive
              ? () => router.push("/declaration/social")
              : () => router.push("/declaration/company")
          }
        >
          <Paper>
            <Typography variant="h3">{Social}Sosiale forhold</Typography>
            <Grid item className={classes.graphGrid}>
              <BenchmarkChart
                color={"#F3CCD1"}
                points={socialEarnedPoints}
                total={socialTotalPoints}
              />
              <br />
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Du har oppnådd:
              </Typography>
              <Typography variant="h4" className={classes.graphPoints}>
                {socialEarnedPoints} av {socialTotalPoints} poeng
              </Typography>
            </Grid>
          </Paper>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Link
          className={classes.links}
          onClick={
            isActive
              ? () => router.push("/declaration/environment")
              : () => router.push("/declaration/company")
          }
        >
          <Paper>
            <Typography variant="h3">{Environment}Miljø og klima</Typography>
            <Grid item className={classes.graphGrid}>
              <BenchmarkChart
                color={"#C3E8D0"}
                points={envEearnedPoints}
                total={envTotalPoints}
              />
              <br />
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Du har oppnådd:
              </Typography>
              <Typography variant="h4" className={classes.graphPoints}>
                {envEearnedPoints} av {envTotalPoints} poeng
              </Typography>
            </Grid>
          </Paper>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Paper>
          <Typography variant="h3">
            {Industry}Bransjespesifikke kriterier
          </Typography>
          <Grid
            item
            className={classes.graphGrid}
            style={{ position: "relative" }}
          >
            <Grid className={classes.overlay}>
              <Typography variant="h2">Kommer snart!</Typography>
            </Grid>
            <BenchmarkChart color={"#CEBEDD"} points={3} total={15} />
            <br />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              Du har oppnådd:
            </Typography>
            <Typography variant="h4" className={classes.graphPoints}>
              3 av 15 poeng
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
