import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Link,
} from "@mui/material";
import { useStyles } from "../IndexStyles";
import { useHistory } from "react-router-dom";

export default function OrganisationSummary(props) {
  const classes = useStyles();
  const history = useHistory();
  const { data } = props;

  const linkCheck = () => {
    let link = data.website;
    const newArray = link.slice(0, 7);
    console.log(newArray);
    if (newArray !== "http://") {
      return "http://" + data.website;
    } else {
      return data.website;
    }
  };
  return (
    <Grid item xs={9}>
      <Paper sx={{ padding: "40px", height: "80%" }}>
        <Grid item xs={12} className={classes.summaryHeader}>
          <Typography variant="h1" sx={{ fontSize: "64px" }}>
            {data.companyName}
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#E4E4E4" }}
            onClick={() => history.push("/index/report")}
          >
            Rapporter feil
          </Button>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={7}>
            <Typography variant="body1" sx={{ paddingTop: "20px" }}>
              {data.description}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow sx={{ height: "60px" }}>
                    <TableCell>Nettsted</TableCell>
                    {console.log(data.website)}
                    {console.log(linkCheck())}
                    <TableCell>
                      <Link
                        href={linkCheck()}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "#000000",
                          textDecorationColor: "rgba(0, 0, 0, 1",
                        }}
                      >
                        {data.website}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ height: "60px" }}>
                    <TableCell>Kontaktperson</TableCell>
                    <TableCell>{data.contactPerson}</TableCell>
                  </TableRow>
                  <TableRow sx={{ height: "60px" }}>
                    <TableCell>Telefon</TableCell>
                    <TableCell>{data.phoneNumber}</TableCell>
                  </TableRow>
                  <TableRow sx={{ height: "60px" }}>
                    <TableCell>Org.nr</TableCell>
                    <TableCell>{data.orgNumber}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
