import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { Arrow } from "../../../components/Icons";
import { useAuth } from "../../../auth/useAuth";
import { SiteLoading } from "../../../components/Loading";
import useRouter from "../../../hooks/useRouter";
import useToggle from "../../../hooks/useToggle";
import SnackPopup from "../../../components/SnackPopup.jsx";
import { useStyles } from "../Register/RegisterStyle";

export default function Confirm() {
  const classes = useStyles();
  const router = useRouter();
  const auth = useAuth();

  const [popupState, setPopupState] = useToggle();
  const [popupMsg, setPopupMsg] = useState("");
  const [email, setEmail] = useState("");
  const [hasEmail, setHasEmail] = useToggle();
  const [isLoading, setIsLoading] = useToggle(true);
  const [isConfirmed, setIsConfirmed] = useState(null);

  useEffect(() => {
    let isMounted = false;

    if (
      router.location.state !== undefined &&
      router.location.state &&
      router.location.state.email !== undefined &&
      hasEmail === false
    ) {
      if (!isMounted) {
        setEmail(router.location.state.email);
        setHasEmail();
        setIsLoading();
      }
    } else if (router.query.user && router.query.code) {
      if (!isMounted) {
        auth
          .confirmUser(router.query.user, router.query.code)
          .then(() => {
            setIsConfirmed(true);
            setIsLoading();
          })
          .catch(() => {
            setIsConfirmed(false);
            setIsLoading();
          });
      }
    } else {
      setIsLoading();
    }

    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPopup = (message) => {
    setPopupMsg(message);
    setPopupState();
  };

  const handleClosePopup = () => {
    setPopupState();
  };

  const handleResendConfirmationLink = (event) => {
    event.preventDefault();

    auth
      .resendConfirmationLink(email)
      .then(() => {
        showPopup("En ny lenke har blitt sendt til din e-post.");
      })
      .catch((err) => {
        errorHandling(err.code);
      });
  };

  // Error handling for confirm
  const errorHandling = (errCode) => {
    if (
      errCode === "AliasExistsException" ||
      errCode === "UsernameExistsException"
    ) {
      // User already exists
      showPopup("Brukeren finnes fra før av.");
    } else if (errCode === "UserNotFoundException") {
      // User doesn't exist
      showPopup("Brukeren finnes ikke.");
    } else if (errCode === "ExpiredCodeException") {
      // Confirmation code expired
      showPopup(
        "Koden for bekreftelse er utgått. Vennligst kjør prosessen på nytt."
      );
    } else if (errCode === "CodeMismatchException") {
      // Incorrect confirmation code
      showPopup("Koden stemmer ikke. Sjekk at den er skrevet riktig.");
    } else if (
      errCode === "LimitExceededException" ||
      errCode === "TooManyRequestsException"
    ) {
      // User request exceeded backend limits
      showPopup("Du har overskredet forespørselsgrensen. Prøv igjen senere.");
    } else if (errCode === "NotAuthorizedException") {
      // User is not authorized to do this
      showPopup("En feil har skjedd ved autorisering.");
    } else if (errCode === "InvalidParameterException") {
      // Incorrect value in a field
      showPopup("Ugyldig verdier. Sjekk om feltene er skrevet riktig.");
    } else if (
      errCode === "CodeDeliveryFailureException" ||
      errCode === "NoUser"
    ) {
      // AWS failing to deliver a code to the user
      showPopup(
        "Det har skjedd en feil ved levering av bekreftelseskode. Hvis dette forsetter, vennligst kontakt support."
      );
    }
  };

  if (isLoading) {
    return <SiteLoading />;
  } else {
    if (isConfirmed === true) {
      return (
        <Box className={classes.box} style={{ backgroundColor: "#CEBEDD" }}>
          <SnackPopup
            message={popupMsg}
            open={popupState}
            handleClose={handleClosePopup}
          />
          <Button
            variant="contained"
            startIcon={Arrow}
            style={{
              backgroundColor: "#fff",
              borderRadius: "5px 5px 0px 0px",
            }}
            onClick={() => router.push("/")}
          >
            Gå tilbake
          </Button>
          <Grid item xs={12} className={classes.content}>
            <Typography variant="h1" style={{ textAlign: "left" }}>
              Din bruker har blitt bekreftet
            </Typography>
            <form autoComplete="on">
              <Typography
                variant="body1"
                style={{ textAlign: "left", margin: "10px 0px" }}
              >
                Vennligst gå tilbake og logg på din nye bruker.
              </Typography>
            </form>
          </Grid>
        </Box>
      );
    } else if (isConfirmed === false) {
      return (
        <Box className={classes.box} style={{ backgroundColor: "#CEBEDD" }}>
          <SnackPopup
            message={popupMsg}
            open={popupState}
            handleClose={handleClosePopup}
          />
          <Button
            variant="contained"
            startIcon={Arrow}
            style={{
              backgroundColor: "#fff",
              borderRadius: "5px 5px 0px 0px",
            }}
            onClick={() => router.push("/")}
          >
            Gå tilbake
          </Button>
          <Grid item xs={12} className={classes.content}>
            <Typography variant="h1" style={{ textAlign: "left" }}>
              Din bekreftelseslenke har utgått
            </Typography>
            <form autoComplete="on">
              <Typography
                variant="h6"
                style={{ textAlign: "left", margin: "10px 0px" }}
              >
                Lenken har utgått. Vennligst gå tilbake og logg inn på nytt for
                å motta en ny epost.
              </Typography>
            </form>
          </Grid>
        </Box>
      );
    } else {
      return (
        <Box className={classes.box} style={{ backgroundColor: "#CEBEDD" }}>
          <SnackPopup
            message={popupMsg}
            open={popupState}
            handleClose={handleClosePopup}
          />
          <Button
            variant="contained"
            startIcon={Arrow}
            style={{
              backgroundColor: "#fff",
              borderRadius: "5px 5px 0px 0px",
            }}
            onClick={() => router.push("/")}
          >
            Gå tilbake
          </Button>
          <Grid item xs={12} className={classes.content}>
            <Typography variant="h1" style={{ textAlign: "left" }}>
              Bekreftelseslenke sendt til e-posten din
            </Typography>
            <form autoComplete="on">
              <Typography
                variant="h6"
                style={{ textAlign: "left", margin: "10px 0px" }}
              >
                Vennligst klikk på mottatt lenke for å bekrefte din bruker.
              </Typography>
              {hasEmail ? (
                <Button
                  variant="contained"
                  onClick={handleResendConfirmationLink}
                >
                  Send lenke på nytt
                </Button>
              ) : (
                <></>
              )}
            </form>
          </Grid>
        </Box>
      );
    }
  }
}
