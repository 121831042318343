import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TableRow,
    TableCell,
    Button,
    Box,
    Collapse,
    Divider,
    InputLabel,
    InputBase,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    Autocomplete,
    TextField,
    NativeSelect,
    InputAdornment,
} from '@mui/material';
import { useStyles, CustomCell, CustomRow } from '../../ClimateStyles';
import { ArrowRight, Add, Delete } from '../../../../../components/Icons';
import * as Text from '../../../../../helpers/climateAccounting.json';
import { fetchAllClimateFactors, newClimateResponse, updateClimateResponse } from '../../climateFunctions';
import { Loading } from '../../../../../components/Loading';
import { useAuth } from '../../../../../auth/useAuth';
import Chores from '../Chores';

export default function WasteProduction(props) {
    const {
        CollapseColor,
        CheckedColor,
        IconChange,
        ApplicableCheckBox,
        updateReport,
        reportId,
        questionId,
        factorId,
        data,
        index,
        open,
        changeOpen,
        openNext,
        tasks,
        updateTasks,
        users,
    } = props;

    const classes = useStyles();
    const auth = useAuth();

    const [hasData, setHasData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [resetValue, setResetValue] = useState(false);
    const [isAnswered, setIsAnswered] = useState(false);
    const [factors, setFactors] = useState([]);
    const [suffixes, setSuffixes] = useState([]);
    const [selectedFactor, setSelectedFactor] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [amount, setAmount] = useState(0);
    const [isRelevant, setIsRelevant] = useState(true);
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            checkForData();

            fetchAllClimateFactors(factorId).then(data => {
                let sortedArr = data.sort((a, b) => a.name.localeCompare(b.name));

                setFactors(sortedArr);
            });
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkForData = async () => {
        if (data !== null && data !== undefined) {
            let dataObj = JSON.parse(data.data);
            setAnswers(dataObj);

            if (!data.isRelevant) {
                setIsAnswered(true);
                setIsRelevant(false);
            } else if (dataObj.length !== 0) {
                setIsAnswered(true);
            }

            setHasData(true);
        } else {
            setHasData(false);
        }
    };

    const saveData = async check => {
        if (isSaving) {
            return;
        }

        setIsSaving(true);

        let newCheck = check;

        if (newCheck === null || newCheck === undefined) {
            newCheck = true;
        }

        if (hasData) {
            await updateClimateResponse(
                data.id,
                auth.companyData.companyId,
                reportId,
                questionId,
                3,
                answers,
                newCheck,
            ).then(async () => {
                await updateReport();

                setIsSaving(false);
            });
        } else {
            await newClimateResponse(auth.companyData.companyId, reportId, questionId, 3, answers, newCheck).then(
                async () => {
                    await updateReport();

                    setHasData(true);
                    setIsSaving(false);
                },
            );
        }
    };

    const handleSelectUnit = event => {
        setSelectedUnit(suffixes[event.target.value]);
        setSelectValue(event.target.value);
    };

    const handleSelectWaste = value => {
        if (value === null || value === undefined || value === '') {
            setSelectedFactor('');
            setSuffixes([]);
            setSelectedUnit('');
            setSelectValue('');
            setAmount(0);
        } else {
            setSelectedFactor(value);
            setSelectValue(0);
            setAmount('');

            console.log(value);

            if (value.types && value.types.items.length !== 0) {
                setSuffixes(value.types.items);
                setSelectedUnit(value.types.items[0]);
            } else {
                setSuffixes(['kg', 'tonn', 'liter', 'm3']);
                setSelectedUnit('kg');
            }
        }
    };

    const handleAddAnswer = async () => {
        let input = {
            factor: selectedFactor.name,
            factorId: selectedFactor.id,
            amount: amount,
            emission: 0,
        };

        if (selectedUnit.type) {
            input.type = selectedUnit.type;
            input.typeId = selectedUnit.id;
        } else {
            input.type = selectedUnit;
            input.typeId = selectedUnit;
        }

        if (selectedUnit.value) {
            input.emission = amount * selectedUnit.value;
        }

        let newArr = answers;

        newArr.push(input);

        if (newArr.length !== 0) {
            setIsAnswered(true);
        }

        setAnswers([...newArr]);

        setSelectedFactor('');
        setSuffixes([]);
        setSelectedUnit('');
        setSelectValue('');
        setAmount(0);

        setResetValue(!resetValue);

        await saveData();
    };

    const handleRemoveAnswer = async index => {
        let newArr = answers;

        newArr.splice(index, 1);

        if (newArr.length === 0) {
            setIsAnswered(false);
        }

        setAnswers([...newArr]);

        await saveData();
    };

    const handleCheck = async e => {
        setIsRelevant(!e.target.checked);

        if (e.target.checked) {
            setIsAnswered(true);
        } else {
            setIsAnswered(false);
        }

        await saveData(!e.target.checked);
    };

    const handleSave = async () => {
        await saveData(isRelevant);

        openNext(index);
    };

    const renderEmission = totalEmission => {
        if (!totalEmission) {
            return 'Feil ved beregning';
        }

        // If higher than 100, start displaying as ton instead of kg
        if (totalEmission >= 100) {
            let newTotalEmission = totalEmission / 1000;

            return newTotalEmission.toFixed(2) + ' tonn CO2e';
        } else {
            return totalEmission.toFixed(2) + ' kg CO2e';
        }
    };

    return (
        <React.Fragment>
            <TableRow
                id={questionId}
                className={classes.question}
                sx={{
                    background: CollapseColor(isAnswered, open),
                }}
            >
                <TableCell
                    className={classes.iconCell}
                    style={{
                        backgroundColor: CheckedColor(isAnswered),
                    }}
                    align="center"
                    onClick={() => changeOpen(index, !open)}
                >
                    {IconChange(isAnswered)}
                </TableCell>
                <TableCell
                    component="th"
                    scope="row"
                    className={classes.questionRow}
                    onClick={() => changeOpen(index, !open)}
                >
                    <Typography variant="h3">Avfallsproduksjon</Typography>
                </TableCell>
                <TableCell className={classes.applicable}>{ApplicableCheckBox(isRelevant, handleCheck)}</TableCell>
            </TableRow>
            <TableRow className={classes.row}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: '20px' }}>
                            <Typography variant="body1">{Text.Scope3.Waste}</Typography>
                            <br />
                            <Typography variant="body1">{Text.Scope3.Waste2}</Typography>
                            <Divider className={classes.divider} />
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                <Grid item xs={8} sx={{ marginRight: '10px' }}>
                                    <InputLabel>Type avfall</InputLabel>
                                    <Autocomplete
                                        id="waste-selector"
                                        key={resetValue}
                                        forcePopupIcon={true}
                                        options={factors}
                                        getOptionLabel={factor => factor.name}
                                        onChange={(e, value) => handleSelectWaste(value)}
                                        renderInput={params => (
                                            <TextField
                                                required
                                                error
                                                name="waste"
                                                type="text"
                                                autoComplete="off"
                                                value={selectedFactor}
                                                {...params}
                                                placeholder="Søk eller velg type materiale..."
                                            />
                                        )}
                                    />
                                    <Typography variant="body2">
                                        <i>Trykk "legg til" for å legge til i lista</i>
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ marginRight: '10px' }}>
                                    <InputLabel>Mengde</InputLabel>
                                    <TextField
                                        type="number"
                                        name="unit"
                                        placeholder="Skriv her..."
                                        disabled={suffixes.length > 0 ? false : true}
                                        onChange={e => setAmount(e.target.value)}
                                        value={amount}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <NativeSelect
                                                        className={classes.unitSelect}
                                                        value={selectValue}
                                                        onChange={handleSelectUnit}
                                                        disabled={suffixes.length > 0 ? false : true}
                                                        input={<InputBase />}
                                                    >
                                                        {suffixes &&
                                                            suffixes.map((suffix, index) => {
                                                                return (
                                                                    <option key={index} value={index}>
                                                                        {suffix.type || suffix}
                                                                    </option>
                                                                );
                                                            })}
                                                    </NativeSelect>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ marginTop: '32px' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ width: '130px' }}
                                        onClick={handleAddAnswer}
                                        disabled={amount > 0 && amount !== '' ? false : true}
                                    >
                                        {Add}Legg til
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <CustomRow>
                                                <CustomCell
                                                    align="left"
                                                    sx={{ borderBottom: '2px solid #FDDFB0 !important' }}
                                                >
                                                    Type avfall
                                                </CustomCell>
                                                <CustomCell
                                                    align="left"
                                                    sx={{ borderBottom: '2px solid #FDDFB0 !important' }}
                                                >
                                                    Mengde
                                                </CustomCell>
                                                <CustomCell
                                                    align="left"
                                                    sx={{ borderBottom: '2px solid #FDDFB0 !important' }}
                                                >
                                                    kgCO2
                                                </CustomCell>
                                                <CustomCell
                                                    align="center"
                                                    sx={{ borderBottom: '2px solid #FDDFB0 !important' }}
                                                >
                                                    Slett
                                                </CustomCell>
                                            </CustomRow>
                                        </TableHead>
                                        <TableBody>
                                            {answers &&
                                                answers.map((answer, index) => {
                                                    return (
                                                        <CustomRow key={answer.factor}>
                                                            <CustomCell
                                                                sx={{ padding: '0px 10px 0px 10px !important' }}
                                                            >
                                                                {answer.factor}
                                                            </CustomCell>
                                                            <CustomCell
                                                                sx={{ padding: '0px 10px 0px 10px !important' }}
                                                            >
                                                                {answer.amount} {answer.type}
                                                            </CustomCell>
                                                            <CustomCell
                                                                sx={{ padding: '0px 10px 0px 10px !important' }}
                                                            >
                                                                {!answer.emission || answer.emission === 0
                                                                    ? 'Ikke beregnet'
                                                                    : renderEmission(answer.emission)}
                                                            </CustomCell>
                                                            <CustomCell
                                                                align="center"
                                                                sx={{ padding: '0px 10px 0px 10px !important' }}
                                                                onClick={() => handleRemoveAnswer(index)}
                                                            >
                                                                {Delete}
                                                            </CustomCell>
                                                        </CustomRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid container direction="row" className={classes.content}>
                                {isSaving ? (
                                    <Loading />
                                ) : (
                                    <Button disabled={false} variant="contained" onClick={handleSave}>
                                        Lagre{ArrowRight}
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: '20px' }}>
                                <Divider sx={{ border: '1px solid #E1E1E1' }} />
                                <Typography variant="h2" sx={{ marginTop: '10px' }}>
                                    Tilknyttede gjøremål
                                </Typography>
                                <Chores
                                    tasks={tasks}
                                    updateTasks={updateTasks}
                                    users={users}
                                    id={questionId}
                                    reportId={reportId}
                                    scope={3}
                                />
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow style={{ height: '20px' }} />
        </React.Fragment>
    );
}
