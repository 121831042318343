import React, { useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    Button,
    Typography,
    Divider,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Autocomplete,
    TextField,
} from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useStyles } from '../DocumentsStyles';
import { useAuth } from '../../../../auth/useAuth';
import useRouter from '../../../../hooks/useRouter';
import Replacement from '../../../../assets/images/nocustomer_detail.svg';
import SimplePDF, { SimpleLayout } from './NSRS/SimpleLayout';
import ModernPDF, { ModernLayout } from './NSRS/ModernLayout';
import { Download } from '../../../../components/Icons';
import { fetchAllNSRSReports, fetchAllCategories } from '../../NSRS/nsrsFunctions';
import getDateFromTimestamp from '../../../../helpers/getDateFromTimestamp';
import NavigationBar from './NavigationBar';
import amplitude from 'amplitude-js';

export default function NSRSReport() {
    const classes = useStyles();
    const router = useRouter();
    const auth = useAuth();

    const [value, setValue] = useState(1);

    const [reportList, setReportList] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [nsrsData, setNsrsData] = useState(null);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            fetchAllNSRSReports(auth.companyData.companyId).then(data => {
                let newData = data;

                for (const item of newData) {
                    item.title = item.reportYear + ' (' + getDateFromTimestamp(item.createdAt) + ')';
                }

                setReportList(newData);
            });

            fetchAllCategories().then(data => {
                setNsrsData(data);
            });
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = event => {
        setValue(parseInt(event.target.value));
    };

    const RenderPDFViewer = () => {
        if (value === 0) {
            return <SimplePDF report={selectedReport} nsrsData={nsrsData} />;
        } else if (value === 1) {
            return <ModernPDF report={selectedReport} nsrsData={nsrsData} />;
        }
    };

    const downloadDocument = () => {
        if (process.env.NODE_ENV !== 'development') {
            amplitude.getInstance().logEvent('nsrs_report_downloaded');
        }
    };

    return (
        <Grid item xs={12}>
            <NavigationBar />
            <Paper sx={{ padding: '40px' }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Typography variant="body2" className={classes.pdfWarning}>
                            PDF forhåndvisning er foreløpig kun tilgjenglig på PC.
                        </Typography>
                        <Typography variant="h1" sx={{ textAlign: 'left' }}>
                            NSRS Rapport
                        </Typography>
                        <Divider sx={{ margin: '10px 0px' }} />
                        <Typography variant="body1">
                            Velg mal og hvilket rapport du vil laste ned i listen under. Til høyre ser dere en
                            forhåndsvisning av valgte NSRS rapport.
                            <br />
                            <br />
                            Når du har valgt en rapport, vennligst vent på innlastning av dokumentet. Enkelt mal er
                            midlertidig ikke tilgjengelig.
                        </Typography>
                        <Grid
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                margin: '10px 0px',
                            }}
                        >
                            <FormControl>
                                <RadioGroup row name="report-layout" value={value} onChange={handleChange}>
                                    <FormControlLabel value={0} control={<Radio disabled={true} />} label="Enkel Mal" />
                                    <FormControlLabel value={1} control={<Radio />} label="Moderne Mal" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            <>
                                <Autocomplete
                                    value={selectedReport}
                                    onChange={(e, value) => setSelectedReport(value)}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    options={reportList}
                                    getOptionLabel={option => option.title}
                                    freeSolo
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label=""
                                            placeholder="Søk etter år eller dato for fullførte NSRS rapporter..."
                                        />
                                    )}
                                />
                                {reportList.length !== 0 && selectedReport !== null && nsrsData !== null ? (
                                    <PDFDownloadLink
                                        style={{ color: '#000', textDecoration: 'none' }}
                                        document={
                                            value === 0 ? (
                                                <SimpleLayout report={selectedReport} nsrsData={nsrsData} />
                                            ) : (
                                                <ModernLayout report={selectedReport} nsrsData={nsrsData} />
                                            )
                                        }
                                        fileName="NSRSrapport.pdf"
                                    >
                                        {({ blob, url, loading, error }) =>
                                            loading ? (
                                                <Button
                                                    variant="contained"
                                                    disabled={true}
                                                    sx={{
                                                        height: '50px',
                                                        width: '100%',
                                                        marginTop: '30px',
                                                        color: '#000000',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    Laster inn dokument...
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    onClick={() => downloadDocument(blob)}
                                                    sx={{
                                                        height: '50px',
                                                        width: '100%',
                                                        marginTop: '30px',
                                                        color: '#000000',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    Last ned {Download}
                                                </Button>
                                            )
                                        }
                                    </PDFDownloadLink>
                                ) : (
                                    <Button
                                        variant="contained"
                                        disabled={true}
                                        sx={{
                                            height: '50px',
                                            width: '100%',
                                            marginTop: '30px',
                                            color: '#000000',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Velg en rapport
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{
                                    height: '50px',
                                    width: '100%',
                                    marginTop: '30px',
                                    color: '#000000',
                                    textDecoration: 'none',
                                }}
                                onClick={() => router.push('/subscribe')}
                            >
                                Bli kunde for å laste ned dokument
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={6} className={classes.viewerSection}>
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            reportList.length !== 0 && selectedReport !== null && nsrsData !== null ? (
                                RenderPDFViewer()
                            ) : (
                                <Typography variant="h2">
                                    Du har ikke valgt en rapport. Søk etter en i feltet til venstre.
                                </Typography>
                            )
                        ) : (
                            <img src={Replacement} alt="Replacement for non customers"></img>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
