import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Slide,
} from '@mui/material';
import { Arrow } from '../../../../components/Icons';
import { NavLink } from 'react-router-dom';
import { useStyles } from '../NSRSStyles';
import useRouter from '../../../../hooks/useRouter';
import getDateFromTimestamp from '../../../../helpers/getDateFromTimestamp';
import { finishReport, reopenReport } from '../nsrsFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReportOverview(props) {
    const { report, categories } = props;
    const classes = useStyles();
    const router = useRouter();

    const [openDialog, setOpenDialog] = useState(false);
    const [canComplete, setCanComplete] = useState(false);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (report.status === 'Draft') {
                checkIfReqMet();
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkEmployeeQuestion = (responses, question) => {
        if (!question.filterTypes.includes('Employees')) {
            return false;
        }

        let employeeResponse = responses.find(response => {
            return response.questionId === '5cd4c337-7d6f-4618-8f80-1b58a6b9dbd8';
        });

        if (employeeResponse && employeeResponse.data) {
            let answerData = JSON.parse(employeeResponse.data);

            if (answerData && answerData['545c4ef-77a-7a27-7f5d-20e24c4af6be'] === '0') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const climateSubcategoryCheck = (category, responses, subId) => {
        if (category.id !== 'climate' || subId === '13113522-0ba1-47de-ad5f-6a4cb6804f3f') {
            return true;
        }

        let findData = responses.find(response => {
            return response.questionId === '212ed41b-8647-4f90-9b02-4960eb2850a5';
        });

        if (findData) {
            let respData = JSON.parse(findData.data);

            if (respData['fe71025-061-87c-aa-d8ce70f2bb'] && respData['fe71025-061-87c-aa-d8ce70f2bb'].checked) {
                let climateCheckArr = respData['fe71025-061-87c-aa-d8ce70f2bb'].checked;

                // Materialer
                if (
                    climateCheckArr.includes('07c662-7c2-2bd7-5d1-a40ad43f58f3') &&
                    subId === '1d67ffdf-8f38-4446-9699-fd2beaf4aa91'
                ) {
                    return true;
                    // Avfall
                } else if (
                    climateCheckArr.includes('341c5f5-151a-176e-d05f-21640dfbdbb') &&
                    subId === 'cd3b3281-44cb-44f8-bf7e-76076d60ab22'
                ) {
                    return true;
                    // Energy
                } else if (
                    climateCheckArr.includes('7a087a-6ea5-2d0f-650d-473cf7bca1') &&
                    subId === 'a85f82bb-eb05-407a-a002-ce84ddc775d0'
                ) {
                    return true;
                    // Klima
                } else if (
                    climateCheckArr.includes('30ac6d0-1c3e-077b-a38-c1f0e4083e82') &&
                    subId === 'dd8ac392-6611-44c1-9dfe-7b53cbe29216'
                ) {
                    return true;
                    // EU-taksonomi
                } else if (
                    climateCheckArr.includes('28de7d1-aa77-616b-0e63-d6c58c01fc44') &&
                    subId === '8af23562-1361-4f41-a515-053049bd0578'
                ) {
                    return true;
                }
            }
        } else {
            return false;
        }
    };

    const checkIfReqMet = async () => {
        let responses = report.responses.items;

        let totalReqQuestions = 0;
        let totalReqAnswered = 0;

        for (const category of categories) {
            for (const sub of category.subcategories.items) {
                for (const question of sub.questions.items) {
                    if (
                        climateSubcategoryCheck(category, responses, sub.id) &&
                        !checkEmployeeQuestion(responses, question)
                    ) {
                        if (question.required) {
                            totalReqQuestions++;
                        }

                        let resp = responses.find(item => item.questionId === question.id);

                        if (resp && resp.isAnswered && question.required) {
                            totalReqAnswered++;
                        }
                    }
                }
            }
        }

        if (totalReqAnswered === totalReqQuestions) {
            setCanComplete(true);
        } else {
            setCanComplete(false);
        }
    };

    const completeNSRSReport = async () => {
        await finishReport(report.id).then(() => {
            router.go(0);
        });
    };

    const reopenNSRSReport = async () => {
        await reopenReport(report.id).then(() => {
            router.go(0);
        });
    };

    const CompleteDialog = () => (
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(!openDialog)}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
            TransitionComponent={Transition}
            fullWidth={true}
        >
            <DialogTitle sx={{ textAlign: 'left', fontSize: '30px' }}>Fullfør NSRS rapport</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: '#000000' }}>
                    Når rapporten blir fullført så vil dere ikke lengre ha tilgang til å endre noen av svarene, men vil
                    få tilgang til eksportere til en PDF fil. Ønsker dere å endre på rapporten, så må den gjenåpnes.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    sx={{ width: '120px', height: '40px' }}
                    autoFocus
                    onClick={() => setOpenDialog(!openDialog)}
                >
                    Lukk
                </Button>
                <Button
                    variant="contained"
                    sx={{ width: '120px', height: '40px' }}
                    autoFocus
                    onClick={completeNSRSReport}
                >
                    Fullfør
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Grid item xs={12}>
            <CompleteDialog />
            <Typography variant="h3">
                <Button startIcon={Arrow} variant="text" sx={{ fontSize: '15px' }} onClick={() => router.push('/nsrs')}>
                    Gå tilbake
                </Button>
            </Typography>
            <Paper sx={{ padding: '20px' }}>
                <Grid container direction="row">
                    <Grid item xs={8}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Rapporteringsperiode:</TableCell>
                                        <TableCell>{report.reportYear}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Opprettet:</TableCell>
                                        <TableCell>{getDateFromTimestamp(report.createdAt)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Sist endret:</TableCell>
                                        <TableCell>{getDateFromTimestamp(report.updatedAt)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Opprettet av:</TableCell>
                                        <TableCell>{report.userCreatedBy}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            startIcon={Arrow}
                            className={classes.sessionButton}
                            sx={{ backgroundColor: '#ebebeb' }}
                            component={NavLink}
                            to={'/nsrs'}
                            exact
                        >
                            Tilbake til oversikt
                        </Button>
                        {report.status === 'Draft' ? (
                            <Button
                                variant="contained"
                                className={classes.sessionButton}
                                sx={{ backgroundColor: '#FDDFB0' }}
                                onClick={() => setOpenDialog(true)}
                                disabled={canComplete ? false : true}
                            >
                                Fullfør rapporten
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                className={classes.sessionButton}
                                sx={{ backgroundColor: '#FDDFB0' }}
                                onClick={reopenNSRSReport}
                            >
                                Gjenåpne rapporten
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
