import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import {
    Select,
    MenuItem,
    InputLabel,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    FormGroup,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Radio,
    Switch,
} from '@mui/material';
import { Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import OptionListView from './OptionListView';

import InputForm, { NumberInput, TextAreaInput } from '../../../../../components/InputForm';
import useToggle from '../../../../../hooks/useToggle';

import { API, graphqlOperation } from 'aws-amplify';
import { updateCertQuestion, deleteCertQuestion } from '../../../../../graphql/mutations';

export default function CategoryRowView(props) {
    const [rowOpen, setRowOpen] = useToggle();
    const [dialogOpen, setDialogOpen] = useToggle();

    const [question, setQuestion] = useState('');
    const [description, setDescription] = useState('');
    const [explanation, setExplanation] = useState('');
    const [type, setType] = useState('Text');
    const [value, setValue] = useState(0);
    const [code, setCode] = useState('');
    const [industryCodes, setIndustryCodes] = useState([]);
    const [required, setRequired] = useState(true);
    const [filterType, setFilterType] = useState('None');
    const [order, setOrder] = useState(1);
    const [minChars, setMinChars] = useState(0);
    const [maxChars, setMaxChars] = useState(0);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            setQuestion(props.data.question);
            setDescription(props.data.description);
            setExplanation(props.data.explanation);
            setType(props.data.type);
            setValue(props.data.pointValue);
            setRequired(props.data.required);
            setOrder(props.data.order);

            if (props.data.industryCodes) {
                setIndustryCodes(props.data.industryCodes);
            }

            if (props.data.minChars) {
                setMinChars(props.data.minChars);
            }

            if (props.data.maxChars) {
                setMaxChars(props.data.maxChars);
            }

            if (props.data.filterType) {
                setFilterType(props.data.filterType);
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateQuestion = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateCertQuestion, {
                    input: {
                        id: props.data.id,
                        question: question,
                        description: description,
                        explanation: explanation,
                        type: type,
                        pointValue: value,
                        filterType: filterType,
                        industryCodes: industryCodes,
                        required: required,
                        order: order,
                        minChars: minChars,
                        maxChars: maxChars,
                    },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated question: ', data);
                }

                resolve();
            });
        });
    };

    const deleteQuestion = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(deleteCertQuestion, {
                    input: { id: props.data.id },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Deleted question: ', data);
                }

                resolve();
            });
        });
    };

    const handleUpdateQuestion = async () => {
        if (question === '' || value === '') {
            props.showPopup('Spørsmål og poengsum må være fylt ut!');
            return;
        }

        await updateQuestion().catch(() => {
            props.showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        props.showPopup('Spørsmålet har blitt lagret');

        await props.update();

        setDialogOpen();
    };

    const handleDeleteQuestion = async () => {
        if (props.data.options.items && props.data.options.items.length !== 0) {
            props.showPopup('Alle valgalternativer må bli fjernet før du kan slette!');
            return;
        }

        await deleteQuestion().catch(() => {
            props.showPopup('En feil oppsto ved sletting av spørsmål');
            return;
        });

        props.showPopup('Spørsmålet har blitt slettet');

        await props.update();
    };

    const handleCloseDialog = () => {
        setDialogOpen();
        setQuestion(props.data.question);
        setDescription(props.data.description);
        setExplanation(props.data.explanation);
        setType(props.data.type);
        setValue(props.data.pointValue);
        setRequired(props.data.required);
        setCode('');
        setOrder(props.data.order);

        if (props.data.industryCodes) {
            setIndustryCodes(props.data.industryCodes);
        }

        if (props.data.minChars) {
            setMinChars(props.data.minChars);
        }

        if (props.data.maxChars) {
            setMaxChars(props.data.maxChars);
        }

        if (props.data.filterType) {
            setFilterType(props.data.filterType);
        }
    };

    const addCode = () => {
        if (industryCodes.includes(code)) {
            return;
        }

        if (code === '') {
            return;
        }

        let array = industryCodes;

        array.push(code);

        setIndustryCodes([...array]);
        setCode('');
    };

    const removeCode = index => {
        let array = industryCodes;

        array.splice(index, 1);

        setIndustryCodes([...array]);
    };

    const showFilterType = () => {
        if (props.data.filterType) {
            let type = props.data.filterType;

            if (type === 'None') {
                return 'For alle';
            } else if (type === 'Employees') {
                return 'Flere ansatte';
            } else if (type === 'Property') {
                return 'Bygg';
            } else if (type === 'Production') {
                return 'Produksjon';
            } else if (type === 'Trade') {
                return 'Varehandel';
            }
        } else {
            return 'Ingen satt';
        }
    };

    const updateQuestionDialog = () => {
        return (
            <Grid>
                <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
                    <DialogTitle sx={{ fontSize: '2em' }}>Oppdater spørsmålet</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Oppdater verdiene til spørsmålet.
                        </DialogContentText>
                        <InputForm
                            label="Spørsmål"
                            placeholder="Skriv inn spørsmålet"
                            value={question}
                            onChange={e => setQuestion(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Beskrivelse"
                            placeholder="Skriv beskrivelsen til spørsmålet"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Hjelpetekst"
                            placeholder="Skriv hjelpeteksten til spørsmålet"
                            value={explanation}
                            onChange={e => setExplanation(e.target.value)}
                        />
                        <InputLabel id="Type">Type spørsmål</InputLabel>
                        <Select
                            labelId="Type"
                            value={type}
                            label="Type spørsmål"
                            onChange={e => setType(e.target.value)}
                        >
                            <MenuItem value={'MultipleChoice'}>Multiple Choice</MenuItem>
                            <MenuItem value={'RadioList'}>Radio List</MenuItem>
                            <MenuItem value={'DropdownSelect'}>Dropdown Select</MenuItem>
                            <MenuItem value={'Number'}>Number</MenuItem>
                            <MenuItem value={'MultipleNumber'}>Multiple Number</MenuItem>
                            <MenuItem value={'Text'}>Text</MenuItem>
                            <MenuItem value={'Textfield'}>Textfield</MenuItem>
                            <MenuItem value={'MultipleText'}>Multiple Text</MenuItem>
                            <MenuItem value={'Slider'}>Slider</MenuItem>
                            <MenuItem value={'MultiDropdownSelectWithInput'}>
                                Multiple Dropdown Select with inputs
                            </MenuItem>
                            <MenuItem value={'MultiRowInput'}>Multiple Rows Input</MenuItem>
                            <MenuItem value={'CustomMultiWithSelect'}>
                                Custom: Multiple med Select (For vannforbruk, sløsing, osv.)
                            </MenuItem>
                            <MenuItem value={'CustomCertifyDropDowns'}>Custom: Sertifisering/utmerkelser</MenuItem>
                        </Select>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={required} onChange={e => setRequired(e.target.checked)} />}
                                label="Er spørsmålet påkrevd?"
                            />
                        </FormGroup>
                        <InputLabel id="Type">Filtreringstype (Hvilke selskaper skal kunne se kriteriet)</InputLabel>
                        <FormControl>
                            <RadioGroup
                                row
                                defaultValue="None"
                                value={filterType}
                                onChange={e => setFilterType(e.target.value)}
                            >
                                <FormControlLabel value="None" control={<Radio />} label="For alle" />
                                <FormControlLabel value="Employees" control={<Radio />} label="Flere ansatte" />
                                <FormControlLabel value="Property" control={<Radio />} label="Bygg" />
                                <FormControlLabel value="Production" control={<Radio />} label="Produsenter" />
                                <FormControlLabel value="Trade" control={<Radio />} label="Varehandel" />
                            </RadioGroup>
                        </FormControl>
                        <NumberInput
                            label="Poengsum"
                            placeholder="Skriv inn hvor mye poeng spørsmålet kan gi"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            min={0}
                        />
                        <NumberInput
                            label="Rekkefølge (lavere nummer betyr høyere på listen)"
                            placeholder="Skriv inn nummer på rekkefølge"
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                        <NumberInput
                            label="Minimum tegn på svar"
                            placeholder="Skriv inn minimum tegn bruker må taste inn"
                            value={minChars}
                            onChange={e => setMinChars(e.target.value)}
                            min={0}
                        />
                        <NumberInput
                            label="Maks tegn på svar (0 er uendelig tegn)"
                            placeholder="Skriv inn maks tegn bruker må taste inn"
                            value={maxChars}
                            onChange={e => setMaxChars(e.target.value)}
                            min={0}
                        />
                        <NumberInput
                            label="Næringskoder - (La være tom hvis det er for alle)"
                            placeholder="Skriv og legg til næringskodene for de som skal se spørsmålet (Bare første 2 tall)"
                            value={code}
                            onChange={e => setCode(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{
                                padding: '20px',
                                marginBottom: '20px',
                                width: '130px',
                            }}
                            onClick={addCode}
                        >
                            Legg til kode
                        </Button>
                        <br />
                        <b>Koder:</b>
                        <br />
                        {industryCodes &&
                            industryCodes.map((code, i) => {
                                return (
                                    <Grid key={code}>
                                        {code}
                                        <Button startIcon={<RemoveCircleOutlineIcon />} onClick={() => removeCode(i)} />
                                        <br />
                                    </Grid>
                                );
                            })}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleUpdateQuestion}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid>
            {updateQuestionDialog()}
            <Paper sx={{ padding: '20px', marginBottom: '10px' }}>
                <ListItem disablePadding>
                    <ListItemButton onClick={setRowOpen}>
                        <ListItemIcon>
                            <DehazeIcon />
                        </ListItemIcon>
                        <ListItemText primary={props.data.question} />
                    </ListItemButton>
                </ListItem>
                {!rowOpen ? (
                    <></>
                ) : (
                    <Grid>
                        <Divider />
                        <Grid sx={{ margin: '10px' }}>
                            <b>ID:</b>
                            <br />
                            {props.data.id}
                            <br />
                            <br />
                            <b>Beskrivelse:</b>
                            <br />
                            {props.data.description}
                            <br />
                            <br />
                            <b>Hjelpetekst:</b>
                            <br />
                            {props.data.explanation}
                            <br />
                            <br />
                            <b>Type:</b> {props.data.type} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Poengsum:</b>{' '}
                            {props.data.pointValue}
                            <br />
                            <br />
                            <b>Rekkefølge:</b> {props.data.order} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Påkrevd:</b>{' '}
                            {props.data.required ? <>Ja</> : <>Nei</>} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                            <b>Filtreringstype: </b> {showFilterType()}
                            <br />
                            <br />
                            <b>Minimum tegn:</b> {props.data.minChars} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Maks tegn:</b>{' '}
                            {props.data.maxChars}
                            <br />
                        </Grid>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '200px' }}
                            onClick={setDialogOpen}
                        >
                            Oppdater spørsmål{' '}
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '200px' }}
                            onClick={handleDeleteQuestion}
                        >
                            Slett spørsmål{' '}
                        </Button>
                        <OptionListView
                            showPopup={props.showPopup}
                            update={props.update}
                            questionId={props.data.id}
                            data={props.data.options.items}
                        />
                        <Divider />
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
}
