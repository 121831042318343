import { withStyles, makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import "../../../../../index.css";

const OpenSans = "'Open Sans', sans-serif";

export const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#FDDFB0",
    fontSize: "20px",
    color: "#000000",
    width: "50px",
    height: "50px",
  },
  box: {
    backgroundColor: "#F4F4F4",
    padding: "10px",
    borderRadius: "5px",
    width: "100%",
  },
  //comment
  commentsGrid: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
  },
  singleComment: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "5px",
  },
  editorStyle: {
    cursor: "text",
    backgroundColor: "#F4F4F4",
    borderRadius: "5px 5px 5px 5px",
  },
  commentButton: {
    minWidth: "0px",
    padding: "0px",
    marginRight: "10px",
  },
  //Draft
  linkTitle: {
    textAlign: "left",
    fontSize: "25px",
    backgroundColor: "#E2EFF1",
    display: "flex",
    padding: "10px 24px",
  },
  writeButton: {
    width: "80px",
    backgroundColor: "#FEF0D9",
    borderRadius: "5px 5px 0px 0px",
  },
  richEditStyle: {
    minHeight: "6em",
    cursor: "text",
    padding: "5px 15px 15px 15px",
    backgroundColor: "#F4F4F4",
    borderRadius: "0px 5px 5px 5px",
  },
  bottomNav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: "50px",
  },
  //Filecomment
}));

export const UnderlineButton = withStyles(() => ({
  root: {
    fontFamily: OpenSans,
    fontSize: "14px",
    textDecoration: "underline",
    width: "78px",
    minWidth: "78px",
    fontWeight: 100,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#ffffff",
    },
  },
}))(Button);

export const BoldButton = withStyles(() => ({
  root: {
    fontFamily: OpenSans,
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: 700,
    width: "55px",
    minWidth: "55px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#ffffff",
    },
  },
}))(Button);

export const ItalicButton = withStyles(() => ({
  root: {
    fontFamily: OpenSans,
    textDecoration: "none",
    fontSize: "14px",
    fontStyle: "italic",
    width: "55px",
    minWidth: "55px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#ffffff",
    },
  },
}))(Button);

export const BlockButton = withStyles(() => ({
  root: {
    fontFamily: OpenSans,
    fontSize: "14px",
    textDecoration: "none",
    fontWeight: 100,
    width: "117px",
    minWidth: "117px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#ffffff",
    },
  },
}))(Button);

export const IconButton = withStyles(() => ({
  root: {
    width: "25px",
    minWidth: "25px",
    padding: "0px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#ffffff",
    },
  },
}))(Button);
