import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { useStyles } from '../../NSRSStyles';
import { ArrowRight, Business } from '../../../../../components/Icons';
import ProgressBars from '../../../../../components/ProgessBar';
import useRouter from '../../../../../hooks/useRouter';

export default function BusinessModule(props) {
    const classes = useStyles();
    const router = useRouter();

    const { report, category, categories } = props;

    const [progress, setProgress] = useState(0);
    const [remaining, setRemaining] = useState(0);
    const [obligRemaining, setObligRemaining] = useState(0);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            checkAndSetProgress();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkAndSetProgress = async () => {
        let responses = report.responses.items;

        let totalQuestions = 0;
        let remainingQuestions = 0;
        let obligRemainingQuestions = 0;

        let totalAnswered = 0;

        for (const sub of category.subcategories.items) {
            for (const question of sub.questions.items) {
                if (question.required) {
                    obligRemainingQuestions++;
                }

                totalQuestions++;

                let resp = responses.find(item => item.questionId === question.id);

                if (resp && resp.isAnswered) {
                    totalAnswered++;

                    if (question.required) {
                        obligRemainingQuestions--;
                    }
                }
            }
        }

        remainingQuestions = totalQuestions - totalAnswered;

        let totalPercent = 100 / totalQuestions;

        setRemaining(remainingQuestions);
        setObligRemaining(obligRemainingQuestions);
        setProgress(Math.ceil(totalPercent * totalAnswered));
    };

    return (
        <Paper
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Grid item>
                <Typography variant="h3" sx={{ backgroundColor: '#C1E6EB' }}>
                    {Business} {category.order}.0 {category.name}
                </Typography>
                <Grid item style={{ padding: '20px 20px 0px 20px' }}>
                    <Typography variant="body1">{category.description}</Typography>
                    <br />
                </Grid>
            </Grid>
            <Grid item>
                <Grid item style={{ padding: '0px 20px 20px 20px' }}>
                    <Typography variant="h4" className={classes.progressionText}>
                        {remaining} spørsmål gjenstår ({obligRemaining} obligatoriske)
                    </Typography>
                    <ProgressBars value={progress} backgroundColor="#C1E6EB" />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        backgroundColor: '#E0F3F5',
                    }}
                    className={classes.modulesFooter}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#fff',
                            '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#C1E6EB',
                            },
                        }}
                        onClick={() =>
                            router.push({
                                pathname: '/nsrs/' + report.id + '/' + category.id,
                                state: { report: report, category: category, categories: categories },
                            })
                        }
                    >
                        {report.status === 'Draft' ? 'Endre' : 'Gå til'}
                        {ArrowRight}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
