import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  /* Left side of view */
  left: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zindex: 1,
    padding: "80px",
  },
  /* Right side of view */
  right: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    position: "relative",
  },
  rightContent: {
    justifyContent: "center",
    alignItems: "center",
  },
  illustration: {
    padding: "0px 0px",
    position: "absolute",
    bottom: "-80px",
  },
  introduction: {
    textAlign: "center",
    padding: "10px 50px",
  },
  logo: {
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  backgroundImage: {
    position: "absolute",
    bottom: "0px",
  },
  box: {
    width: "520px",
    padding: "30px",
    boxShadow: "0px 4px 24px rgb(0 0 0 / 5%), 0px 4px 8px rgb(0 0 0 / 5%)",
    borderRadius: "5px",
  },
  content: {
    backgroundColor: "#fff",
    padding: "20px 40px",
    borderRadius: "0px 5px 5px 5px",
  },
  flexCondition: {
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    fontsize: "1em !important",
    background: "#f6f6f6",
    boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    width: "100%",
    border: "none",
    color: "#000000 !important",
    margin: "10px 0px",
    padding: "7px",
    fontFamily: '"Open Sans Condensed", sansserif !important',
  },
  textButton: {
    fontSize: "14px !important",
  },
  a: {
    color: "#000000",
  },
  button: {
    backgroundColor: "#bdccd4 !important",
  },
  topButton: {
    backgroundColor: "#fff !important",
    color: "#000 !important",
    borderRadius: "5px 5px 0px 0px",
    width: "150px",
  },
  changeViewText: {
    fontWeight: "bold",
    textDecoration: "underline",
  },
}));
