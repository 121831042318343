import React from "react";
import { TextField } from "@mui/material";

export default function TextFields(props) {
  return (
    <TextField
      id={props.id}
      multiline
      rows={5}
      name={props.label}
      value={props.value}
      onChange={props.onChange}
      style={{ width: "100%" }}
    />
  );
}
