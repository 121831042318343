import React, { useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    Typography,
    TableContainer,
    Table,
    TableBody,
    Button,
    Divider,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useStyles } from '../../ClimateStyles';
import useRouter from '../../../../../hooks/useRouter';
import Leaves from '../../../../../assets/images/leaves.svg';
import HeatFossilFuel from './HeatFossilFuel';
import VehiclesMachinesTools from './VehiclesMachinesTools';
import EmissionGas from './EmissionGas';
import * as Text from '../../../../../helpers/climateAccounting.json';
import { ArrowRight, Finished, UnFinished } from '../../../../../components/Icons';

export default function Scope1(props) {
    const classes = useStyles();
    const router = useRouter();

    const [openStates, setOpenStates] = useState(router.query.criteria ? [true, true, true] : [false, false, false]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (router.query.criteria) {
                if (router.query.criteria !== 'Forbrenning og oppvarming') {
                    changeOpen(0, false);
                }

                if (router.query.criteria !== 'Intern transport') {
                    changeOpen(1, false);
                }

                if (router.query.criteria !== '(Kjøle)gasser') {
                    changeOpen(2, false);
                }

                const findPosition = obj => {
                    var currenttop = 0;
                    if (obj.offsetParent) {
                        do {
                            currenttop += obj.offsetTop;
                        } while ((obj = obj.offsetParent));
                        return [currenttop];
                    }
                };

                if (document.getElementById(router.query.criteria)) {
                    window.scrollTo(0, findPosition(document.getElementById(router.query.criteria)));
                }
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const ApplicableCheckBox = (check, handleCheck) => {
        return (
            <FormGroup sx={{ width: '125px' }}>
                <FormControlLabel
                    sx={{ fontSize: '13px !important', margin: '0px' }}
                    control={<Checkbox checked={!check} onChange={handleCheck} />}
                    label="Ikke aktuelt"
                />
            </FormGroup>
        );
    };

    const CollapseColor = (isAnswered, open) => {
        if (isAnswered) {
            return '#DEF7F3';
        } else if (open) {
            return '#EBEBEB';
        } else {
            return '#FFFFFF';
        }
    };

    const CheckedColor = isAnswered => {
        if (isAnswered) {
            return '#DEF7F3';
        } else {
            return '#EBEBEB';
        }
    };

    const IconChange = isAnswered => {
        if (isAnswered) {
            return Finished;
        } else {
            return UnFinished;
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const changeOpen = (index, state) => {
        let newArr = openStates;

        newArr[index] = state;

        setOpenStates([...newArr]);
    };

    const openNext = current => {
        let newArr = openStates;

        newArr[current] = false;
        newArr[current + 1] = true;

        setOpenStates([...newArr]);
    };

    return (
        <Paper elevation={1}>
            <Grid item xs={12}>
                <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={2} className={classes.summaryContent}>
                        <img src={Leaves} alt="Yellow and blue leaves" className="summaryImage"></img>
                    </Grid>
                    <Grid item xs={10} className={classes.summaryDescription}>
                        <Typography variant="body1">{Text.Scope1.Summary}</Typography>
                    </Grid>
                </Paper>
            </Grid>
            <Grid>
                <Grid item xs={12}>
                    <Typography id="energy" variant="h1" className={classes.title}>
                        Energi
                    </Typography>
                </Grid>
                <TableContainer>
                    <Table aria-label="collapsible table">
                        <TableBody>
                            <HeatFossilFuel
                                CollapseColor={CollapseColor}
                                CheckedColor={CheckedColor}
                                IconChange={IconChange}
                                ApplicableCheckBox={ApplicableCheckBox}
                                updateReport={props.updateReport}
                                reportId={props.report.id}
                                questionId={'Forbrenning og oppvarming'}
                                factorId={'Forbrenning og oppvarming'}
                                data={props.report.responses.items.find(response => {
                                    return response.questionId === 'Forbrenning og oppvarming';
                                })}
                                index={0}
                                open={openStates[0]}
                                changeOpen={changeOpen}
                                openNext={openNext}
                                tasks={props.tasks}
                                updateTasks={props.updateTasks}
                                users={props.users}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12}>
                    <Typography id="transport" variant="h1" className={classes.title}>
                        Transport
                    </Typography>
                </Grid>
                <TableContainer>
                    <Table aria-label="collapsible table">
                        <TableBody>
                            <VehiclesMachinesTools
                                CollapseColor={CollapseColor}
                                CheckedColor={CheckedColor}
                                IconChange={IconChange}
                                ApplicableCheckBox={ApplicableCheckBox}
                                updateReport={props.updateReport}
                                reportId={props.report.id}
                                questionId={'Intern transport'}
                                factorId={'Intern transport'}
                                data={props.report.responses.items.find(response => {
                                    return response.questionId === 'Intern transport';
                                })}
                                index={1}
                                open={openStates[1]}
                                changeOpen={changeOpen}
                                openNext={openNext}
                                tasks={props.tasks}
                                updateTasks={props.updateTasks}
                                users={props.users}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12}>
                    <Typography id="other" variant="h1" className={classes.title}>
                        Annet
                    </Typography>
                </Grid>
                <TableContainer>
                    <Table aria-label="collapsible table">
                        <TableBody>
                            <EmissionGas
                                CollapseColor={CollapseColor}
                                CheckedColor={CheckedColor}
                                IconChange={IconChange}
                                ApplicableCheckBox={ApplicableCheckBox}
                                updateReport={props.updateReport}
                                reportId={props.report.id}
                                questionId={'(Kjøle)gasser'}
                                factorId={'(Kjøle)gasser'}
                                data={props.report.responses.items.find(response => {
                                    return response.questionId === '(Kjøle)gasser';
                                })}
                                index={2}
                                open={openStates[2]}
                                changeOpen={changeOpen}
                                tasks={props.tasks}
                                updateTasks={props.updateTasks}
                                users={props.users}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Divider sx={{ margin: '30px 0px' }} />
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    sx={{ marginRight: '20px', backgroundColor: '#EBEBEB' }}
                    onClick={scrollToTop}
                    component={NavLink}
                    to={'/climate/' + props.report.id + '/information'}
                    exact
                >
                    Tilbake
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginRight: '20px', backgroundColor: '#FDDFB0' }}
                    onClick={scrollToTop}
                    component={NavLink}
                    to={'/climate/' + props.report.id + '/scope2'}
                    exact
                >
                    Neste {ArrowRight}
                </Button>
            </Grid>
        </Paper>
    );
}
