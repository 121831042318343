import React, { useState } from 'react';
import {
    Hidden,
    Drawer,
    Toolbar,
    Grid,
    MenuList,
    MenuItem,
    AppBar,
    IconButton,
    Button,
    Typography,
    Avatar,
} from '@mui/material';
import { Arrow, Hamburger } from '../../../../components/Icons';
import useRouter from '../../../../hooks/useRouter';
import { HashLink as Link } from 'react-router-hash-link';
import { useStyles } from '../NSRSStyles';
import Tutorial from '../../../../components/private/Tutorial';

export default function Sidebar(props) {
    const classes = useStyles();
    const router = useRouter();

    const { report, category } = props;
    const { id, formId } = router.query;

    const [mobileOpen, setMobileOpen] = useState(false);

    const setBackgroundColor = () => {
        if (formId === 'information') {
            return '#FDDFB0';
        } else if (formId === 'company') {
            return '#C1E6EB';
        } else if (formId === 'economy') {
            return '#BBCBE4';
        } else if (formId === 'social') {
            return '#F3CCD1';
        } else if (formId === 'climate') {
            return '#C3E8D0';
        } else if (formId === 'risk') {
            return '#CEBEDD';
        }
    };

    const handleDrawerToggle = open => {
        setMobileOpen(open);
    };

    return (
        <Grid>
            <Hidden mdDown>
                <Drawer variant="permanent" anchor="left">
                    <Grid
                        item
                        xs={12}
                        style={{
                            backgroundColor: setBackgroundColor(),
                            padding: '20px',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Grid item xs={12}>
                            <Button
                                type="text"
                                startIcon={Arrow}
                                onClick={() =>
                                    router.push({
                                        pathname: '/nsrs/' + report.id,
                                        state: { report: report },
                                    })
                                }
                            >
                                Gå tilbake
                            </Button>
                            <Typography variant="h1">{category.name}</Typography>
                            <MenuList>
                                {category.subcategories &&
                                    category.subcategories.items
                                        .sort((a, b) => {
                                            return a.order - b.order;
                                        })
                                        .map((subcategory, i, row) => {
                                            if (i + 1 === row.length) {
                                                // Last one.
                                                return (
                                                    <Link
                                                        smooth
                                                        to={'/nsrs/' + id + '/' + formId + '#' + (i + 1)}
                                                        className={classes.link}
                                                        active={classes.active}
                                                        key={subcategory.id}
                                                    >
                                                        <MenuItem>
                                                            <Avatar>{i + 1}</Avatar>

                                                            {subcategory.name}
                                                        </MenuItem>
                                                    </Link>
                                                );
                                            } else {
                                                // Not last one.
                                                return (
                                                    <Grid key={subcategory.id}>
                                                        <Link
                                                            smooth
                                                            to={'/nsrs/' + id + '/' + formId + '#' + (i + 1)}
                                                            className={classes.link}
                                                            active={classes.active}
                                                        >
                                                            <MenuItem>
                                                                <Avatar>{i + 1}</Avatar>
                                                                {subcategory.name}
                                                            </MenuItem>
                                                        </Link>
                                                        <div className={classes.connector} />
                                                    </Grid>
                                                );
                                            }
                                        })}
                            </MenuList>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Tutorial width="150px" color="#ffffff" />
                        </Grid>
                    </Grid>
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <AppBar position="fixed">
                    <Toolbar
                        sx={{
                            minHeight: '40px !important',
                            backgroundColor: setBackgroundColor(),
                            padding: '10px 0px',
                        }}
                    >
                        <IconButton onClick={() => handleDrawerToggle(true)}>{Hamburger}</IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    anchor="left"
                    onClick={() => handleDrawerToggle(false)}
                    onClose={() => handleDrawerToggle(false)}
                >
                    <Grid item xs={12} style={{ backgroundColor: setBackgroundColor(), padding: '20px' }}>
                        <Button
                            type="text"
                            startIcon={Arrow}
                            onClick={() =>
                                router.push({
                                    pathname: '/nsrs/' + report.id,
                                    state: { report: report },
                                })
                            }
                        >
                            Gå tilbake
                        </Button>
                        <Typography variant="h1">{category.name}</Typography>
                        <MenuList>
                            {category.subcategories &&
                                category.subcategories.items
                                    .sort((a, b) => {
                                        return a.order - b.order;
                                    })
                                    .map((subcategory, i, row) => {
                                        if (i + 1 === row.length) {
                                            // Last one.
                                            return (
                                                <Link
                                                    smooth
                                                    to={'/nsrs/' + id + '/' + formId + '#' + (i + 1)}
                                                    className={classes.link}
                                                    active={classes.active}
                                                    key={subcategory.id}
                                                >
                                                    <MenuItem>
                                                        <Avatar>{i + 1}</Avatar>

                                                        {subcategory.name}
                                                    </MenuItem>
                                                </Link>
                                            );
                                        } else {
                                            // Not last one.
                                            return (
                                                <Grid key={subcategory.id}>
                                                    <Link
                                                        smooth
                                                        to={'/nsrs/' + id + '/' + formId + '#' + (i + 1)}
                                                        className={classes.link}
                                                        active={classes.active}
                                                    >
                                                        <MenuItem>
                                                            <Avatar>{i + 1}</Avatar>
                                                            {subcategory.name}
                                                        </MenuItem>
                                                    </Link>
                                                    <div className={classes.connector} />
                                                </Grid>
                                            );
                                        }
                                    })}
                        </MenuList>
                    </Grid>
                </Drawer>
            </Hidden>
        </Grid>
    );
}
