import React, { useState, useEffect } from 'react';
import { Grid, Button, ButtonGroup } from '@mui/material';
import CompaniesView from './components/companies/CompaniesView';
import CertificationView from './components/certification/CertificationView';
import ClimateView from './components/climate/ClimateView';
import NSRSView from './components/nsrs/NSRSView';
import TemplatesView from './components/templates/TemplatesView';
import useRouter from '../../../hooks/useRouter';
import { useAuth } from '../../../auth/useAuth';
import { adminCheck } from '../../../helpers/globalValues';

export default function Admin() {
    const router = useRouter();
    const auth = useAuth();

    const [view, setView] = useState('companies');

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (adminCheck && auth.companyData && auth.companyData.orgNumber !== '924828099') {
                router.push('/');
            }

            if (router.query.id) {
                if (router.query.id === 'companies') {
                    setView('companies');
                } else if (router.query.id === 'certification') {
                    setView('certification');
                } else if (router.query.id === 'climate') {
                    setView('climate');
                } else if (router.query.id === 'nsrs') {
                    setView('nsrs');
                } else if (router.query.id === 'templates') {
                    setView('templates');
                }
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSetView = string => {
        router.push('/admin?id=' + string);
        setView(string);
    };

    // Logic for what views appear to the user based on URL criterias
    const checkForView = () => {
        if (view === 'companies') {
            return <CompaniesView />;
        } else if (view === 'certification') {
            return <CertificationView />;
        } else if (view === 'climate') {
            return <ClimateView />;
        } else if (view === 'nsrs') {
            return <NSRSView />;
        } else if (view === 'templates') {
            return <TemplatesView />;
        }
    };

    return (
        <Grid item xs={12}>
            <ButtonGroup>
                <Button variant="contained" onClick={() => handleSetView('companies')}>
                    Selskaper
                </Button>
                <Button variant="contained" onClick={() => handleSetView('certification')}>
                    Sertifisering
                </Button>
                <Button variant="contained" onClick={() => handleSetView('climate')}>
                    Klimaregnskap
                </Button>
                <Button variant="contained" onClick={() => handleSetView('nsrs')}>
                    NSRS
                </Button>
                <Button variant="contained" onClick={() => handleSetView('templates')}>
                    Maler
                </Button>
            </ButtonGroup>
            {checkForView()}
        </Grid>
    );
}
