import React, { useState } from 'react';
import { Grid, Button, IconButton, Typography, List, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { NumberInput } from '../../../../../components/InputForm';
import OptionRowView from './OptionRowView';

import { API, graphqlOperation } from 'aws-amplify';
import { createCertQuestionOption } from '../../../../../graphql/mutations';

export default function OptionListView(props) {
    const [open, setOpen] = useToggle();

    const [option, setOption] = useState('');
    const [points, setPoints] = useState(0);
    const [order, setOrder] = useState(1);
    const [isOther, setIsOther] = useState(false);
    const [isCustomMultiSelectOption, setIsCustomMultiSelectOption] = useState(false);

    const saveNewOption = async () => {
        return new Promise(async (resolve, reject) => {
            // Input data for creating a new CompanyData object
            let inputData = {
                questionId: props.questionId,
                pointValue: points,
                option: option,
                order: order,
                isOtherOption: isOther,
                isCustomMultiSelectOption: isCustomMultiSelectOption,
            };

            await API.graphql(graphqlOperation(createCertQuestionOption, { input: inputData }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new option: ', data.data.createCertQuestionOption);
                    }

                    resolve(data.data.createCertQuestion);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Failed to create new option: ', err);
                    }

                    reject(err);
                });
        });
    };

    const handleSaveNewOption = async () => {
        if (option === '' || points === '') {
            props.showPopup('Svaret og verdien må være fylt ut!');
            return;
        }

        await saveNewOption().catch(() => {
            props.showPopup('En feil oppsto ved lagring av svaralternativ');
            return;
        });

        props.showPopup('Svaralternativet har blitt lagret');

        await props.update();

        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        setOption('');
        setPoints(0);
        setOrder(1);
        setIsOther(false);
        setIsCustomMultiSelectOption(false);
        setOpen();
    };

    const newOptionDialog = () => {
        return (
            <Grid>
                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Legg til nytt svaralternativ</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Legg til et nytt svaralternativ til spørsmålet.
                        </DialogContentText>
                        <InputForm
                            label="Svaralternativ"
                            placeholder="Skriv inn svaralternativet"
                            value={option}
                            onChange={e => setOption(e.target.value)}
                        />
                        <InputForm
                            label="Poengsum"
                            placeholder="Skriv inn hvor mye poeng valgalternativet gir"
                            value={points}
                            onChange={e => setPoints(e.target.value)}
                            min={0}
                        />
                        <NumberInput
                            label="Rekkefølge (lavere nummer betyr høyere på listen)"
                            placeholder="Skriv inn nummer på rekkefølge"
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={isOther} onChange={e => setIsOther(e.target.checked)} />}
                                label="Er alternativet av type 'Annet/Andre' og skal ha tekstfelt som dukker opp? (Multiple Choice og Select)"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isCustomMultiSelectOption}
                                        onChange={e => setIsCustomMultiSelectOption(e.target.checked)}
                                    />
                                }
                                label="Er alternativet en select for 'Custom: Multiple med Select'? (Custom: Multiple med Select)"
                            />
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleSaveNewOption}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid sx={{ margin: '20px' }}>
            {newOptionDialog()}
            <Typography>
                <b>Svaralternativer</b>
                <IconButton onClick={setOpen}>
                    <AddCircleOutlineOutlinedIcon />
                </IconButton>
            </Typography>
            <List>
                {props.data &&
                    props.data
                        .sort((a, b) => {
                            return a.order - b.order;
                        })
                        .map(item => {
                            return (
                                <OptionRowView
                                    data={item}
                                    key={item.id}
                                    showPopup={props.showPopup}
                                    update={props.update}
                                />
                            );
                        })}
            </List>
        </Grid>
    );
}
