import React from 'react';
import {
    InputLabel,
    InputBase,
    Grid,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
} from '@mui/material';

export default function RadGroup(props) {
    const { data, input, setInput, reportIsCompleted } = props;

    const handleChange = (dataId, altId) => {
        let newValue = {
            id: altId,
            input: '',
        };

        setInput({
            ...input,
            [dataId]: newValue,
        });
    };

    const handleChangeText = (dataId, altId, value) => {
        let newValue = {
            id: altId,
            input: value,
        };

        setInput({
            ...input,
            [dataId]: newValue,
        });
    };

    return (
        <Grid>
            <InputLabel>{data.label} *</InputLabel>
            <FormControl
                component="fieldset"
                sx={{ display: 'flex', flexDirection: 'column' }}
            >
                <RadioGroup
                    id={data.id}
                    name={data.label}
                    value={(input[data.id] && input[data.id].id) || ''}
                    onChange={(e) => handleChange(data.id, e.target.value)}
                >
                    {data.alternatives &&
                        data.alternatives
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map((alt) => {
                                return (
                                    <Grid key={alt.id}>
                                        <FormControlLabel
                                            id={alt.id}
                                            control={
                                                <Radio
                                                    disabled={reportIsCompleted}
                                                />
                                            }
                                            style={{ margin: '8px 0px' }}
                                            label={alt.label}
                                            value={alt.id}
                                            readOnly={reportIsCompleted}
                                        />
                                        {alt.hasTextField &&
                                        input[data.id] &&
                                        input[data.id].id === alt.id ? (
                                            <InputBase
                                                sx={{ padding: '5px' }}
                                                type="text"
                                                className="input"
                                                placeholder={
                                                    !reportIsCompleted
                                                        ? 'Skriv her...'
                                                        : ''
                                                }
                                                value={
                                                    input[data.id].input || ''
                                                }
                                                onChange={(e) =>
                                                    handleChangeText(
                                                        data.id,
                                                        alt.id,
                                                        e.target.value
                                                    )
                                                }
                                                readOnly={reportIsCompleted}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                );
                            })}
                </RadioGroup>
            </FormControl>
        </Grid>
    );
}
