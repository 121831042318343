import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useAuth } from "../../../../auth/useAuth";
import useRouter from "../../../../hooks/useRouter";
import {
  fetchFilteredCategories,
  finishCertification,
} from "../certificationFunctions";
import { getBadgeFromTotalEarnedPoints } from "../../../../helpers/badgeFunctions";
import { useStyles } from "../CertificationStyles";
import * as Text from "../../../../helpers/certification.json";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CompleteCertification(props) {
  const auth = useAuth();
  const router = useRouter();
  const classes = useStyles();
  const [noGreenWashCheck, setNoGreenWashCheck] = useState(false);
  const [controlResultsCheck, setControlResultsCheck] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalEarnedPoints, setTotalEarnedPoints] = useState(0);
  const [totalPercentEarned, setTotalPercentEarned] = useState(0);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      fetchAndSetScore();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAndSetScore = async () => {
    let categories = await fetchFilteredCategories(auth);

    let newTotalPoints = 0;
    let newTotalEarned = 0;

    for (const category of categories) {
      for (const subcategory of category.subcategories.items) {
        for (const question of subcategory.questions.items) {
          newTotalPoints = newTotalPoints + question.pointValue;

          let index = props.responses.findIndex(
            (response) => response.questionId === question.id
          );

          if (index !== -1) {
            if (props.responses[index].isAnswered) {
              if (props.responses[index].pointsEarned > question.pointValue) {
                newTotalEarned = newTotalEarned + question.pointValue;
              } else {
                newTotalEarned =
                  newTotalEarned + props.responses[index].pointsEarned;
              }
            }
          }
        }
      }
    }

    setTotalPoints(newTotalPoints);
    setTotalEarnedPoints(newTotalEarned);
    setTotalPercentEarned(Math.ceil((100 / newTotalPoints) * newTotalEarned));
  };

  const badgeCheck = () => {
    if (getBadgeFromTotalEarnedPoints(totalPercentEarned) === "bronze") {
      return " bronsje.";
    } else if (getBadgeFromTotalEarnedPoints(totalPercentEarned) === "silver") {
      return " sølv.";
    } else if (getBadgeFromTotalEarnedPoints(totalPercentEarned) === "gold") {
      return " gull.";
    } else {
      return ' status som "dere er i gang".';
    }
  };

  const completeCertification = async () => {
    await finishCertification(auth, isVisible)
      .then(() => {
        router.push("/");
        router.history.go(0);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.error(
            "Error while attempting to complete declaration: ",
            err
          );
        }
      });
  };

  return (
    <Dialog
      fullWidth={true}
      open={props.openDialog}
      onClose={props.handleCloseDialog}
      aria-labelledby="Complete-declaration"
      maxWidth="lg"
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.title}>
        Sikker på at du ønsker å fullføre?
      </DialogTitle>
      <DialogContent sx={{ padding: "40px" }}>
        <br />
        <Typography variant="h4">
          Bedriften har oppnådd totalt {totalEarnedPoints} poeng av totalt{" "}
          {totalPoints} mulige og oppnår
          {badgeCheck()}
        </Typography>
        <br />
        <Typography variant="body1">{Text.FinishModule.congrats}</Typography>
        <br />
        <Typography variant="body1">
          Sertifiseringen er gyldig i ett år.
        </Typography>
        <br />
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              style={{ margin: "8px 0px" }}
              control={<Checkbox name="one" />}
              checked={noGreenWashCheck}
              onChange={(e) => setNoGreenWashCheck(e.target.checked)}
              label={Text.FinishModule.noGreenWash}
            />
            <FormControlLabel
              style={{ margin: "8px 0px" }}
              control={<Checkbox name="one" />}
              checked={controlResultsCheck}
              onChange={(e) => setControlResultsCheck(e.target.checked)}
              label={Text.FinishModule.controlResults}
            />
            {JSON.parse(auth.companyData.companyData.invoice).status !==
            "Trial" ? (
              <FormControlLabel
                style={{ margin: "8px 0px" }}
                control={<Checkbox name="one" />}
                checked={isVisible}
                onChange={(e) => setIsVisible(e.target.checked)}
                label={Text.FinishModule.isVisible}
              />
            ) : (
              <></>
            )}
          </FormGroup>
        </FormControl>
        <br />
        <br />
        <Typography variant="body1">
          Takk for at bedriften deres bidrar, lykke til med bærekraftsarbeidet.
        </Typography>
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={classes.closeButton}
          autoFocus
          onClick={props.handleCloseDialog}
        >
          Avbryt
        </Button>
        <Button
          variant="contained"
          className={classes.completeButton}
          autoFocus
          onClick={completeCertification}
          disabled={!noGreenWashCheck || !controlResultsCheck ? true : false}
        >
          Fullfør
        </Button>
      </DialogActions>
    </Dialog>
  );
}
