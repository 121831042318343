import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TableRow,
    TableCell,
    Button,
    Box,
    Collapse,
    Divider,
    InputBase,
    NativeSelect,
    TextField,
    InputAdornment,
    InputLabel,
} from '@mui/material';
import { useStyles } from '../../ClimateStyles';
import { ArrowRight } from '../../../../../components/Icons';
import * as Text from '../../../../../helpers/climateAccounting.json';
import { newClimateResponse, updateClimateResponse } from '../../climateFunctions';
import { Loading } from '../../../../../components/Loading';
import { useAuth } from '../../../../../auth/useAuth';
import Chores from '../Chores';

export default function WaterConsumption(props) {
    const {
        CollapseColor,
        CheckedColor,
        IconChange,
        ApplicableCheckBox,
        updateReport,
        reportId,
        questionId,
        data,
        index,
        open,
        changeOpen,
        tasks,
        updateTasks,
        users,
    } = props;
    const classes = useStyles();
    const auth = useAuth();
    const [hasData, setHasData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isAnswered, setIsAnswered] = useState(false);
    const [isRelevant, setIsRelevant] = useState(true);
    const [unit, setUnit] = useState('m3');
    const [amount, setAmount] = useState('');
    const [recycledUnit, setRecycledUnit] = useState('m3');
    const [recycledAmount, setRecycledAmount] = useState('');
    const [repurpose, setRepurpose] = useState('');
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            checkForData();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkForData = async () => {
        if (data !== null && data !== undefined) {
            let dataObj = JSON.parse(data.data);
            setAnswers(dataObj);

            if (!data.isRelevant) {
                setIsAnswered(true);
                setIsRelevant(false);
            }

            if (dataObj[0]) {
                let checkAmount = 0;

                if (dataObj[0].amount) {
                    checkAmount = checkAmount + 1;

                    setAmount(dataObj[0].amount);
                    setUnit(dataObj[0].unit);
                }

                if (dataObj[0].recycledAmount) {
                    checkAmount = checkAmount + 1;

                    setRecycledAmount(dataObj[0].recycledAmount);
                    setRecycledUnit(dataObj[0].recycledUnit);
                }

                if (dataObj[0].repurpose) {
                    checkAmount = checkAmount + 1;

                    setRepurpose(dataObj[0].repurpose);
                }

                if (checkAmount === 3) {
                    setIsAnswered(true);
                }
            }

            setHasData(true);
        } else {
            setHasData(false);
        }
    };

    const saveData = async check => {
        if (isSaving) {
            return;
        }

        setIsSaving(true);

        let newCheck = check;

        if (newCheck === null || newCheck === undefined) {
            newCheck = true;
        }

        if (hasData) {
            await updateClimateResponse(
                data.id,
                auth.companyData.companyId,
                reportId,
                questionId,
                3,
                answers,
                newCheck,
            ).then(async () => {
                await updateReport();

                setIsSaving(false);
            });
        } else {
            await newClimateResponse(auth.companyData.companyId, reportId, questionId, 3, answers, newCheck).then(
                async () => {
                    await updateReport();

                    setHasData(true);
                    setIsSaving(false);
                },
            );
        }
    };

    const handleChangeAmount = value => {
        setAmount(value);

        let newArr = [];

        if (value !== '') {
            let input = {
                amount: value,
                unit: unit,
                recycledAmount: recycledAmount,
                recycledUnit: unit,
                repurpose: repurpose,
            };

            newArr.push(input);
        }

        setAnswers([...newArr]);

        if (value.length === 0 || value === null) {
            setIsAnswered(false);
        } else if (value.length !== 0 && recycledAmount.length !== 0 && repurpose.length !== 0) {
            setIsAnswered(true);
        }
    };

    const handleChangeRecycledAmount = value => {
        setRecycledAmount(value);

        let newArr = [];

        if (value !== '') {
            let input = {
                amount: amount,
                unit: unit,
                recycledAmount: value,
                recycledUnit: unit,
                repurpose: repurpose,
            };

            newArr.push(input);
        }

        setAnswers([...newArr]);

        if (value.length === 0 || value === null) {
            setIsAnswered(false);
        } else if (value.length !== 0 && amount.length !== 0 && repurpose.length !== 0) {
            setIsAnswered(true);
        }
    };

    const handleChangeRepurpose = value => {
        setRepurpose(value);

        let newArr = [];

        if (value !== '') {
            let input = {
                amount: amount,
                unit: unit,
                recycledAmount: recycledAmount,
                recycledUnit: unit,
                repurpose: value,
            };

            newArr.push(input);
        }

        setAnswers([...newArr]);

        if (value.length === 0 || value === null) {
            setIsAnswered(false);
        } else if (value.length !== 0 && recycledAmount.length !== 0 && amount.length !== 0) {
            setIsAnswered(true);
        }
    };

    const handleCheck = async e => {
        setIsRelevant(!e.target.checked);

        if (e.target.checked) {
            setIsAnswered(true);
        } else {
            setIsAnswered(false);
        }

        await saveData(!e.target.checked);
    };

    const handleSave = async () => {
        await saveData(isRelevant);

        changeOpen(index, false);
    };

    return (
        <React.Fragment>
            <TableRow
                id={questionId}
                className={classes.question}
                sx={{
                    background: CollapseColor(isAnswered, open),
                }}
            >
                <TableCell
                    className={classes.iconCell}
                    style={{
                        backgroundColor: CheckedColor(isAnswered),
                    }}
                    align="center"
                    onClick={() => changeOpen(index, !open)}
                >
                    {IconChange(isAnswered)}
                </TableCell>
                <TableCell
                    component="th"
                    scope="row"
                    className={classes.questionRow}
                    onClick={() => changeOpen(index, !open)}
                >
                    <Typography variant="h3">Vannforbruk</Typography>
                </TableCell>
                <TableCell className={classes.applicable}>{ApplicableCheckBox(isRelevant, handleCheck)}</TableCell>
            </TableRow>
            <TableRow className={classes.row}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: '20px' }}>
                            <Typography variant="body1">{Text.Scope2.Water}</Typography>
                            <Divider className={classes.divider} />
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                <Grid item xs={4} sx={{ marginRight: '10px' }}>
                                    <InputLabel>Vannforbruk</InputLabel>
                                    <TextField
                                        type="number"
                                        name="waterconsumption"
                                        placeholder="Skriv her..."
                                        value={amount}
                                        sx={{ width: '100%' }}
                                        onChange={e => handleChangeAmount(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <NativeSelect
                                                        value={unit}
                                                        className={classes.unitSelect}
                                                        onChange={e => setUnit(e.target.value)}
                                                        input={<InputBase />}
                                                    >
                                                        <option value="m3">m3</option>
                                                        <option value="liter">liter</option>
                                                    </NativeSelect>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{ marginRight: '10px' }}>
                                    <InputLabel>Gjenbrukt vann</InputLabel>
                                    <TextField
                                        type="number"
                                        name="recycledwater"
                                        placeholder="Skriv her..."
                                        value={recycledAmount}
                                        sx={{ width: '100%' }}
                                        onChange={e => handleChangeRecycledAmount(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <NativeSelect
                                                        value={recycledUnit}
                                                        className={classes.unitSelect}
                                                        onChange={e => setRecycledUnit(e.target.value)}
                                                        input={<InputBase />}
                                                    >
                                                        <option value="m3">m3</option>
                                                        <option value="liter">liter</option>
                                                    </NativeSelect>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel>Formål</InputLabel>
                                    <InputBase
                                        type="text"
                                        className="input"
                                        name="repurpose"
                                        placeholder="Skriv her..."
                                        value={repurpose}
                                        onChange={e => handleChangeRepurpose(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" className={classes.content}>
                                {isSaving ? (
                                    <Loading />
                                ) : (
                                    <Button disabled={false} variant="contained" onClick={handleSave}>
                                        Lagre{ArrowRight}
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: '20px' }}>
                                <Divider sx={{ border: '1px solid #E1E1E1' }} />
                                <Typography variant="h2" sx={{ marginTop: '10px' }}>
                                    Tilknyttede gjøremål
                                </Typography>
                                <Chores
                                    tasks={tasks}
                                    updateTasks={updateTasks}
                                    users={users}
                                    id={questionId}
                                    reportId={reportId}
                                    scope={3}
                                />
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow style={{ height: '20px' }} />
        </React.Fragment>
    );
}
