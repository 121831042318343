import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  Button,
  Box,
  Collapse,
  Divider,
  InputLabel,
  InputBase,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TextField,
  NativeSelect,
  FormControl,
  Select,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  useStyles,
  CustomCell,
  CustomRow,
  CustomMenuItem,
} from "../../ClimateStyles";
import { ArrowRight, Add, Delete } from "../../../../../components/Icons";
import * as Text from "../../../../../helpers/climateAccounting.json";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  fetchAllClimateFactors,
  newClimateResponse,
  updateClimateResponse,
} from "../../climateFunctions";
import { Loading } from "../../../../../components/Loading";
import { useAuth } from "../../../../../auth/useAuth";
import Chores from "../Chores";

const dangerClass = ["Fysisk fare", "Helsefare", "Miljøfare"];

const filter = createFilterOptions();

const MenuProps = {
  PaperProps: {
    style: {
      padding: "0px",
    },
  },
};

export default function Chemicals(props) {
  const {
    CollapseColor,
    CheckedColor,
    IconChange,
    ApplicableCheckBox,
    updateReport,
    reportId,
    questionId,
    data,
    index,
    open,
    changeOpen,
    tasks,
    updateTasks,
    users,
  } = props;
  const classes = useStyles();
  const auth = useAuth();
  const [hasData, setHasData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isRelevant, setIsRelevant] = useState(true);
  const [isAnswered, setIsAnswered] = useState(false);
  const [factors, setFactors] = useState([]);
  const [contamFactors, setContamFactors] = useState([]);
  const [suffixes, setSuffixes] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [selectedFactor, setSelectedFactor] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [productName, setProductName] = useState("");
  const [amount, setAmount] = useState(0);
  const [dangers, setDangers] = useState([]);
  const [contamination, setContamination] = useState("");
  const [exposure, setExposure] = useState(false);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      checkForData();

      fetchAllClimateFactors("Innkjøpt kjemikalier").then((data) => {
        let sortedArr = data.sort((a, b) => a.name.localeCompare(b.name));

        for (const item of sortedArr) {
          item.category = "Innkjøpt kjemikalier";
        }

        fetchAllClimateFactors("Industrielle kjemikalier").then((data) => {
          let newSortedArr = data.sort((a, b) => a.name.localeCompare(b.name));

          for (const item of newSortedArr) {
            item.category = "Kjemikalier oppstått i indistruelle prosesser";
          }

          setFactors(sortedArr.concat(newSortedArr));
        });
      });

      fetchAllClimateFactors("Forurensning").then((data) => {
        let newArr = data.sort((a, b) => a.name.localeCompare(b.name));

        setContamFactors(newArr);
      });
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkForData = async () => {
    if (data !== null && data !== undefined) {
      let dataObj = JSON.parse(data.data);
      setAnswers(dataObj);

      if (!data.isRelevant) {
        setIsAnswered(true);
        setIsRelevant(false);
      } else if (dataObj.length !== 0) {
        setIsAnswered(true);
      }

      setHasData(true);
    } else {
      setHasData(false);
    }
  };

  const saveData = async (check) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    let newCheck = check;

    if (newCheck === null || newCheck === undefined) {
      newCheck = true;
    }

    if (hasData) {
      await updateClimateResponse(
        data.id,
        auth.companyData.companyId,
        reportId,
        questionId,
        3,
        answers,
        newCheck
      ).then(async () => {
        await updateReport();

        setIsSaving(false);
      });
    } else {
      await newClimateResponse(
        auth.companyData.companyId,
        reportId,
        questionId,
        3,
        answers,
        newCheck
      ).then(async () => {
        await updateReport();

        setHasData(true);
        setIsSaving(false);
      });
    }
  };

  const handleSelectUnit = (event) => {
    setSelectedUnit(suffixes[event.target.value]);
    setSelectValue(event.target.value);
  };

  const handleAddAnswer = async () => {
    let input = {
      factor: selectedFactor.name,
      factorId: selectedFactor.id,
      productName: productName,
      type: selectedUnit.type,
      typeId: selectedUnit.id,
      amount: amount,
      dangers: dangers,
      contamination: contamination.name,
      exposure: exposure,
    };

    let newArr = answers;

    newArr.push(input);

    if (newArr.length !== 0) {
      setIsAnswered(true);
    }

    setAnswers([...newArr]);

    setSuffixes([]);
    setSelectedFactor("");
    setSelectedUnit("");
    setProductName("");
    setAmount(0);
    setDangers([]);
    setContamination("");
    setExposure(false);

    await saveData();
  };

  const handleRemoveAnswer = async (index) => {
    let newArr = answers;

    newArr.splice(index, 1);

    if (newArr.length === 0) {
      setIsAnswered(false);
    }

    setAnswers([...newArr]);

    await saveData();
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDangers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCheck = async (e) => {
    setIsRelevant(!e.target.checked);

    if (e.target.checked) {
      setIsAnswered(true);
    } else {
      setIsAnswered(false);
    }

    await saveData(!e.target.checked);
  };

  const displayDanger = (dangers) => {
    if (dangers.length === 1) {
      return dangers[0];
    } else if (dangers.length === 2) {
      return dangers[0] + ", " + dangers[1];
    } else if (dangers.length === 3) {
      return dangers[0] + ", " + dangers[1] + ", " + dangers[2];
    }
  };

  const handleSave = async () => {
    await saveData(isRelevant);

    changeOpen(index, !open);
  };

  return (
    <React.Fragment>
      <TableRow
        id={questionId}
        className={classes.question}
        sx={{
          background: CollapseColor(isAnswered, open),
        }}
      >
        <TableCell
          className={classes.iconCell}
          style={{
            backgroundColor: CheckedColor(isAnswered),
          }}
          align="center"
          onClick={() => changeOpen(index, !open)}
        >
          {IconChange(isAnswered)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          className={classes.questionRow}
          onClick={() => changeOpen(index, !open)}
        >
          <Typography variant="h3">Kjemikalier</Typography>
        </TableCell>
        <TableCell className={classes.applicable}>
          {ApplicableCheckBox(isRelevant, handleCheck)}
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: "20px" }}>
              <Typography variant="body1">{Text.Scope3.Chemicals}</Typography>
              <Divider className={classes.divider} />
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid item xs={6} sx={{ marginRight: "10px" }}>
                  <InputLabel>Type kjemikalie</InputLabel>
                  <Autocomplete
                    groupBy={(option) => option.category}
                    value={selectedFactor}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setSelectedFactor({
                          id: "other",
                          name: newValue,
                        });

                        setSuffixes([
                          { id: "other", type: "kg" },
                          { id: "other", type: "liter" },
                          { id: "other", type: "tonn" },
                          { id: "other", type: "m3" },
                        ]);

                        setSelectedUnit({ id: "other", type: "kg" });
                        setSelectValue("kg");
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setSelectedFactor({
                          id: "other",
                          name: newValue.inputValue,
                        });

                        setSuffixes([
                          { id: "other", type: "kg" },
                          { id: "other", type: "liter" },
                          { id: "other", type: "tonn" },
                          { id: "other", type: "m3" },
                        ]);

                        setSelectedUnit({ id: "other", type: "kg" });
                        setSelectValue("kg");
                      } else {
                        setSelectedFactor(newValue);

                        if (
                          newValue === null ||
                          newValue === undefined ||
                          newValue === ""
                        ) {
                          setSuffixes([]);
                        } else {
                          if (
                            newValue.category ===
                            "Kjemikalier oppstått i indistruelle prosesser"
                          ) {
                            setSuffixes([]);
                            setSelectedUnit("");
                            setAmount(0);
                          } else {
                            setSuffixes(newValue.types.items);

                            setSelectedUnit(newValue.types.items[0]);
                            setSelectValue(newValue.types.items[0].type);
                          }

                          setSelectedId(newValue.category);
                        }
                      }

                      if (
                        newValue === "" ||
                        newValue === undefined ||
                        newValue === null
                      ) {
                        setAmount(0);
                      } else {
                        setAmount("");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Legg til "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={factors}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder="Søk eller skriv inn type kjemikalie..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginRight: "10px" }}>
                  <InputLabel>Navn på produkt/kjemikalie</InputLabel>
                  <InputBase
                    type="text"
                    name="productname"
                    placeholder="Skriv her..."
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Mengde</InputLabel>
                  <TextField
                    type="number"
                    name="mass"
                    placeholder="Skriv her..."
                    disabled={
                      selectedFactor !== "" &&
                      selectedId === "Innkjøpt kjemikalier"
                        ? false
                        : true
                    }
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <NativeSelect
                            className={classes.unitSelect}
                            value={selectValue}
                            onChange={handleSelectUnit}
                            disabled={
                              selectedFactor !== "" &&
                              selectedId === "Innkjøpt kjemikalier"
                                ? false
                                : true
                            }
                            input={<InputBase />}
                          >
                            {suffixes &&
                              suffixes.map((suffix, index) => {
                                return (
                                  <option key={index} value={index}>
                                    {suffix.type}
                                  </option>
                                );
                              })}
                          </NativeSelect>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid item xs={4} sx={{ marginRight: "10px" }}>
                  <InputLabel>Fareklasse, velg en eller flere</InputLabel>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      displayEmpty
                      multiple
                      value={dangers}
                      onChange={handleChange}
                      input={<InputBase />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return "Velg fareklasse...";
                        }

                        return selected.join(", ");
                      }}
                      MenuProps={MenuProps}
                    >
                      <CustomMenuItem disabled value="">
                        Velg fareklasse...
                      </CustomMenuItem>
                      {dangerClass.map((data) => (
                        <CustomMenuItem
                          key={data}
                          value={data}
                          classes={{ selected: classes.selected }}
                        >
                          <Checkbox checked={dangers.indexOf(data) > -1} />
                          <ListItemText primary={data} />
                        </CustomMenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2">
                      <i>Trykk "legg til" for å legge til i lista</i>
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sx={{ marginRight: "10px" }}>
                  <InputLabel>Forurensning</InputLabel>
                  <Autocomplete
                    value={contamination}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setContamination({
                          id: "other",
                          name: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setContamination({
                          id: "other",
                          name: newValue.inputValue,
                        });
                      } else {
                        setContamination(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Legg til "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={contamFactors}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder="Søk eller skriv inn type forurensning..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sx={{ marginRight: "10px" }}>
                  <InputLabel>Eksponeringsmåling/undersøkelse</InputLabel>
                  <FormGroup
                    sx={{ marginTop: "13px", alignContent: "center" }}
                    value={exposure}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label=""
                      onChange={(e) => setExposure(e.target.checked)}
                    />
                  </FormGroup>
                </Grid>
                <Grid item sx={{ marginTop: "32px" }}>
                  <Button
                    variant="contained"
                    sx={{ width: "130px" }}
                    onClick={handleAddAnswer}
                    disabled={
                      amount !== "" &&
                      productName !== "" &&
                      dangers.length !== 0 &&
                      contamination !== ""
                        ? false
                        : true
                    }
                  >
                    {Add}Legg til
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <CustomRow>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Type kjemikalie
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Navn
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Mengde
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Fareklasse(r)
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Forurensning
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Ekspo...
                        </CustomCell>
                        <CustomCell
                          align="center"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Slett
                        </CustomCell>
                      </CustomRow>
                    </TableHead>
                    <TableBody>
                      {answers &&
                        answers.map((answer, index) => {
                          return (
                            <CustomRow key={answer.factor}>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.factor}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.productName}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.amount} {answer.type}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {displayDanger(answer.dangers)}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.contamination}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                <FormGroup sx={{ alignContent: "center" }}>
                                  <FormControlLabel
                                    control={<Checkbox checked={true} />}
                                    label=""
                                  />
                                </FormGroup>
                              </CustomCell>
                              <CustomCell
                                align="center"
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                                onClick={() => handleRemoveAnswer(index)}
                              >
                                {Delete}
                              </CustomCell>
                            </CustomRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container direction="row" className={classes.content}>
                {isSaving ? (
                  <Loading />
                ) : (
                  <Button
                    disabled={false}
                    variant="contained"
                    onClick={handleSave}
                  >
                    Lagre{ArrowRight}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <Divider sx={{ border: "1px solid #E1E1E1" }} />
                <Typography variant="h2" sx={{ marginTop: "10px" }}>
                  Tilknyttede gjøremål
                </Typography>
                <Chores
                  tasks={tasks}
                  updateTasks={updateTasks}
                  users={users}
                  id={questionId}
                  reportId={reportId}
                  scope={3}
                />
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: "20px" }} />
    </React.Fragment>
  );
}
