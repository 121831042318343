import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TableRow,
    TableCell,
    Button,
    Box,
    Collapse,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Slide,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { UnFinished, Finished, ArrowRight } from '../../../../components/Icons';
import { useStyles } from '../NSRSStyles';
import { useAuth } from '../../../../auth/useAuth';
import useRouter from '../../../../hooks/useRouter';
import useToggle from '../../../../hooks/useToggle';
import useDebounce from '../../../../hooks/useDebounce';
import QuestionLayout from './QuestionLayout';
import { createResponse, updateResponse } from '../nsrsFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Question({
    data,
    response,
    catOrder,
    subOrder,
    index,
    updateReport,
    reportIsCompleted,
    openState,
    handleOpenQuestion,
    handleOpenNextQuestion,
}) {
    const classes = useStyles();
    const router = useRouter();
    const auth = useAuth();

    const { id, formId } = router.query;

    const [openDialog, setOpenDialog] = useToggle();
    const [isLoading, setIsLoading] = useState(false);

    const [hasResponse, setHasResponse] = useState(false);
    const [isAnswered, setIsAnswered] = useState(response ? response.isAnswered : false);
    const [inputValues, setInputValues] = useState(response ? JSON.parse(response.data) : {});
    const [isInputsDisabled, setIsInputsDisabled] = useState(false);

    useDebounce(async () => await saveToBackend(inputValues), 1000, [inputValues]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (response) {
                setHasResponse(true);
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            checkForInputs(inputValues);

            let rows = JSON.parse(data.data);

            let disableCheckboxes = [];

            if (rows) {
                for (const row of rows) {
                    if (row) {
                        for (const element of row.elements) {
                            if (element.type === 'DisablingCheckbox') {
                                disableCheckboxes.push(element.id);
                            }
                        }
                    }
                }
            }

            let isDisabled = false;

            for (const id of disableCheckboxes) {
                if (inputValues[id] !== null && inputValues[id] === true) {
                    isDisabled = true;
                }
            }

            setIsInputsDisabled(isDisabled);
        }

        return () => {
            isMounted = true;
        };
    }, [inputValues]); // eslint-disable-line react-hooks/exhaustive-deps

    const checkForInputs = async inputData => {
        let rows = JSON.parse(data.data);

        let requiredElements = [];

        if (rows) {
            for (const row of rows) {
                if (row) {
                    for (const element of row.elements) {
                        if (element.type === 'DisablingCheckbox') {
                            if (inputData[element.id] !== null && inputData[element.id] === true) {
                                setIsAnswered(true);
                                return true;
                            }
                        } else if (
                            element.isRequired ||
                            element.type === 'AddRows' ||
                            element.type === 'MultipleSelectsWithInputs' ||
                            element.type === 'RadioGroup' ||
                            element.type === 'MultipleChoice'
                        ) {
                            requiredElements.push(element);
                        }
                    }
                }
            }
        }

        let checkedReq = 0;

        for (const element of requiredElements) {
            if (element.type === 'DateTime') {
                if (inputData[element.id] && inputData[element.id] !== ('' || null || undefined)) {
                    checkedReq++;
                }
            } else if (
                (inputData[element.id] && inputData[element.id].length > 0) ||
                (inputData[element.id] && inputData[element.id].checked && inputData[element.id].checked.length > 0) ||
                (inputData[element.id] && inputData[element.id].id)
            ) {
                checkedReq++;
            }
        }

        // Leverandører
        if (data.id === '8a39f65f-1bf0-4cef-b0f6-40044f5df0eb') {
            if (
                inputData['0367da7-8226-8d84-8010-7a86ffa52664'] &&
                inputData['0367da7-8226-8d84-8010-7a86ffa52664'].length >= 5
            ) {
                setIsAnswered(true);
                return true;
            } else {
                setIsAnswered(false);
                return false;
            }
            // Interessenter
        } else if (data.id === '01856bdd-63d1-4e57-880f-4a4497f962a3') {
            if (
                inputData['d4c1bfa-5868-3384-42c0-48753b0703d8'] &&
                inputData['d4c1bfa-5868-3384-42c0-48753b0703d8'].length >= 5
            ) {
                setIsAnswered(true);
                return true;
            } else {
                setIsAnswered(false);
                return false;
            }
        }

        if (checkedReq === requiredElements.length) {
            setIsAnswered(true);
            return true;
        } else {
            setIsAnswered(false);
            return false;
        }
    };

    const saveToBackend = async newData => {
        setIsLoading(true);

        let answer = await checkForInputs(newData);

        if (!hasResponse) {
            await createResponse(
                auth.companyData.companyId,
                id,
                data.categoryId,
                data.subcategoryId,
                data.id,
                answer,
                newData,
            );

            setHasResponse(true);
        } else {
            await updateResponse(response.id, answer, newData);
        }

        await updateReport().then(() => {
            setIsLoading(false);
        });
    };

    const ExplanationDialog = () => (
        <Dialog
            open={openDialog}
            onClose={setOpenDialog}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
            TransitionComponent={Transition}
            fullWidth={true}
        >
            <DialogTitle sx={{ textAlign: 'left', fontSize: '30px' }}>Hvorfor spør vi om dette?</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: '#000000' }}>{data.explanation}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ width: '120px', height: '40px' }} autoFocus onClick={setOpenDialog}>
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    );

    const CollapseColor = () => {
        if (isAnswered) {
            if (formId === 'information') {
                return '#FDDFB0';
            } else if (formId === 'company') {
                return '#C1E6EB';
            } else if (formId === 'economy') {
                return '#BBCBE4';
            } else if (formId === 'social') {
                return '#F3CCD1';
            } else if (formId === 'climate') {
                return '#C3E8D0';
            } else if (formId === 'risk') {
                return '#CEBEDD';
            }
        } else if (openState && openState.state) {
            return '#EBEBEB';
        } else {
            return '#FFFFFF';
        }
    };

    const CheckedColor = () => {
        if (isAnswered) {
            if (formId === 'information') {
                return '#FDDFB0';
            } else if (formId === 'company') {
                return '#C1E6EB';
            } else if (formId === 'economy') {
                return '#BBCBE4';
            } else if (formId === 'social') {
                return '#F3CCD1';
            } else if (formId === 'climate') {
                return '#C3E8D0';
            } else if (formId === 'risk') {
                return '#CEBEDD';
            }
        } else {
            return '#EBEBEB';
        }
    };

    const IconChange = () => {
        if (isAnswered) {
            return Finished;
        } else {
            return UnFinished;
        }
    };

    return (
        <>
            <ExplanationDialog />
            <TableRow
                id={'criteria' + index}
                className={classes.question}
                sx={{
                    background: CollapseColor(),
                }}
                onClick={() => handleOpenQuestion(data.id)}
            >
                <TableCell
                    className={classes.iconCell}
                    style={{
                        backgroundColor: CheckedColor(),
                    }}
                    align="center"
                >
                    {IconChange()}
                </TableCell>
                <TableCell component="th" scope="row" className={classes.questionRow}>
                    <Typography variant="h3" className={classes.questionTitle}>
                        {catOrder + '.' + subOrder + '.' + data.order} {data.name}{' '}
                        {data.required ? (
                            <>*</>
                        ) : (
                            <>
                                <i style={{ fontSize: '18px' }}>(Valgfritt)</i>
                            </>
                        )}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow className={classes.row}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openState && openState.state} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: '20px' }}>
                            <Typography variant="body1">{data.description}</Typography>
                            <Button variant="text" className={classes.readmore} onClick={setOpenDialog}>
                                Hvorfor spør vi om dette?
                            </Button>
                            <Divider
                                style={{
                                    margin: '10px 0px 10px 0px',
                                    border: '1px solid #E1E1E1',
                                }}
                            />
                            <QuestionLayout
                                data={JSON.parse(data.data)}
                                input={inputValues}
                                setInput={setInputValues}
                                isInputsDisabled={isInputsDisabled}
                                reportIsCompleted={reportIsCompleted}
                            />
                            {!reportIsCompleted ? (
                                <Grid container direction="row" className={classes.content}>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleOpenNextQuestion(data.id)}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <CircularProgress size={36} sx={{ color: 'turquoise' }} />
                                        ) : (
                                            <>Lagre{ArrowRight}</>
                                        )}
                                    </Button>
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow style={{ height: '20px' }} />
        </>
    );
}
