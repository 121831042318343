/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCertResponseByCompanyIdAndCategoryId = /* GraphQL */ `
  subscription OnCertResponseByCompanyIdAndCategoryId(
    $companyId: ID!
    $categoryId: ID!
  ) {
    onCertResponseByCompanyIdAndCategoryId(
      companyId: $companyId
      categoryId: $categoryId
    ) {
      id
      companyId
      companyCertDataId
      categoryId
      questionId
      pointsEarned
      isAnswered
      answers
      text
      longText
      multiText
      multiDropSelect
      number
      multiNumber
      multiRows
      otherField
      createdAt
      updatedAt
    }
  }
`;
export const onTodoTaskByIdAndCompanyId = /* GraphQL */ `
  subscription OnTodoTaskByIdAndCompanyId($id: ID!, $companyId: ID!) {
    onTodoTaskByIdAndCompanyId(id: $id, companyId: $companyId) {
      id
      companyId
      type
      status
      history
      dueDate
      users
      criteria
      subCriteria
      climateReportId
      climateCriteria
      climateScope
      title
      description
      result
      comments {
        items {
          id
          companyId
          todoTaskId
          userId
          type
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTodoTaskCommentByCompanyIdAndTaskId = /* GraphQL */ `
  subscription OnTodoTaskCommentByCompanyIdAndTaskId(
    $companyId: ID!
    $todoTaskId: ID!
  ) {
    onTodoTaskCommentByCompanyIdAndTaskId(
      companyId: $companyId
      todoTaskId: $todoTaskId
    ) {
      id
      companyId
      todoTaskId
      userId
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUserNotificationByCompanyIdAndUserId = /* GraphQL */ `
  subscription OnUserNotificationByCompanyIdAndUserId(
    $companyId: ID!
    $userId: ID!
  ) {
    onUserNotificationByCompanyIdAndUserId(
      companyId: $companyId
      userId: $userId
    ) {
      id
      companyId
      userId
      type
      markedAsRead
      archived
      data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($id: String) {
    onCreateUser(id: $id) {
      id
      companyId
      username
      status
      name
      email
      phoneNumber
      position
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: String) {
    onUpdateUser(id: $id) {
      id
      companyId
      username
      status
      name
      email
      phoneNumber
      position
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($id: String) {
    onDeleteUser(id: $id) {
      id
      companyId
      username
      status
      name
      email
      phoneNumber
      position
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      companyId
      name
      orgNumber
      companyData {
        id
        status
        betaOptIn
        name
        orgNumber
        address
        invoice
        brregData
        certData {
          items {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            completedAt
            expDate
            history {
              items {
                id
                companyId
                companyCertDataId
                action
                timestamp
                userId
                description
                categoryId
                questionId
                newData
                oldData
                economyScore
                economyPercentScore
                socialScore
                socialPercentScore
                environmentalScore
                environmentalPercentScore
                totalScore
                totalPercentScore
                createdAt
                updatedAt
              }
              nextToken
            }
            responses {
              items {
                id
                companyId
                companyCertDataId
                categoryId
                questionId
                pointsEarned
                isAnswered
                answers
                text
                longText
                multiText
                multiDropSelect
                number
                multiNumber
                multiRows
                otherField
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            publicProfile {
              id
              companyId
              status
              certType
              certFilterTypes
              employeeAmount
              industryCodes
              isVisible
              completedAt
              expDate
              companyName
              orgNumber
              description
              contactPerson
              phoneNumber
              website
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        invitedUsers
        admins
        users {
          items {
            id
            companyId
            username
            status
            name
            email
            phoneNumber
            position
            createdAt
            updatedAt
          }
          nextToken
        }
        todoTasks {
          items {
            id
            companyId
            type
            status
            history
            dueDate
            users
            criteria
            subCriteria
            climateReportId
            climateCriteria
            climateScope
            title
            description
            result
            comments {
              items {
                id
                companyId
                todoTaskId
                userId
                type
                data
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      companyId
      name
      orgNumber
      companyData {
        id
        status
        betaOptIn
        name
        orgNumber
        address
        invoice
        brregData
        certData {
          items {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            completedAt
            expDate
            history {
              items {
                id
                companyId
                companyCertDataId
                action
                timestamp
                userId
                description
                categoryId
                questionId
                newData
                oldData
                economyScore
                economyPercentScore
                socialScore
                socialPercentScore
                environmentalScore
                environmentalPercentScore
                totalScore
                totalPercentScore
                createdAt
                updatedAt
              }
              nextToken
            }
            responses {
              items {
                id
                companyId
                companyCertDataId
                categoryId
                questionId
                pointsEarned
                isAnswered
                answers
                text
                longText
                multiText
                multiDropSelect
                number
                multiNumber
                multiRows
                otherField
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            publicProfile {
              id
              companyId
              status
              certType
              certFilterTypes
              employeeAmount
              industryCodes
              isVisible
              completedAt
              expDate
              companyName
              orgNumber
              description
              contactPerson
              phoneNumber
              website
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        invitedUsers
        admins
        users {
          items {
            id
            companyId
            username
            status
            name
            email
            phoneNumber
            position
            createdAt
            updatedAt
          }
          nextToken
        }
        todoTasks {
          items {
            id
            companyId
            type
            status
            history
            dueDate
            users
            criteria
            subCriteria
            climateReportId
            climateCriteria
            climateScope
            title
            description
            result
            comments {
              items {
                id
                companyId
                todoTaskId
                userId
                type
                data
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      companyId
      name
      orgNumber
      companyData {
        id
        status
        betaOptIn
        name
        orgNumber
        address
        invoice
        brregData
        certData {
          items {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            completedAt
            expDate
            history {
              items {
                id
                companyId
                companyCertDataId
                action
                timestamp
                userId
                description
                categoryId
                questionId
                newData
                oldData
                economyScore
                economyPercentScore
                socialScore
                socialPercentScore
                environmentalScore
                environmentalPercentScore
                totalScore
                totalPercentScore
                createdAt
                updatedAt
              }
              nextToken
            }
            responses {
              items {
                id
                companyId
                companyCertDataId
                categoryId
                questionId
                pointsEarned
                isAnswered
                answers
                text
                longText
                multiText
                multiDropSelect
                number
                multiNumber
                multiRows
                otherField
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            publicProfile {
              id
              companyId
              status
              certType
              certFilterTypes
              employeeAmount
              industryCodes
              isVisible
              completedAt
              expDate
              companyName
              orgNumber
              description
              contactPerson
              phoneNumber
              website
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        invitedUsers
        admins
        users {
          items {
            id
            companyId
            username
            status
            name
            email
            phoneNumber
            position
            createdAt
            updatedAt
          }
          nextToken
        }
        todoTasks {
          items {
            id
            companyId
            type
            status
            history
            dueDate
            users
            criteria
            subCriteria
            climateReportId
            climateCriteria
            climateScope
            title
            description
            result
            comments {
              items {
                id
                companyId
                todoTaskId
                userId
                type
                data
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompanyData = /* GraphQL */ `
  subscription OnCreateCompanyData($admins: String) {
    onCreateCompanyData(admins: $admins) {
      id
      status
      betaOptIn
      name
      orgNumber
      address
      invoice
      brregData
      certData {
        items {
          id
          companyId
          status
          certType
          certFilterTypes
          employeeAmount
          industryCodes
          completedAt
          expDate
          history {
            items {
              id
              companyId
              companyCertDataId
              action
              timestamp
              userId
              description
              categoryId
              questionId
              newData
              oldData
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
            nextToken
          }
          responses {
            items {
              id
              companyId
              companyCertDataId
              categoryId
              questionId
              pointsEarned
              isAnswered
              answers
              text
              longText
              multiText
              multiDropSelect
              number
              multiNumber
              multiRows
              otherField
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          publicProfile {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            isVisible
            completedAt
            expDate
            companyName
            orgNumber
            description
            contactPerson
            phoneNumber
            website
            economyScore
            economyPercentScore
            socialScore
            socialPercentScore
            environmentalScore
            environmentalPercentScore
            totalScore
            totalPercentScore
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      invitedUsers
      admins
      users {
        items {
          id
          companyId
          username
          status
          name
          email
          phoneNumber
          position
          createdAt
          updatedAt
        }
        nextToken
      }
      todoTasks {
        items {
          id
          companyId
          type
          status
          history
          dueDate
          users
          criteria
          subCriteria
          climateReportId
          climateCriteria
          climateScope
          title
          description
          result
          comments {
            items {
              id
              companyId
              todoTaskId
              userId
              type
              data
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompanyData = /* GraphQL */ `
  subscription OnUpdateCompanyData($admins: String) {
    onUpdateCompanyData(admins: $admins) {
      id
      status
      betaOptIn
      name
      orgNumber
      address
      invoice
      brregData
      certData {
        items {
          id
          companyId
          status
          certType
          certFilterTypes
          employeeAmount
          industryCodes
          completedAt
          expDate
          history {
            items {
              id
              companyId
              companyCertDataId
              action
              timestamp
              userId
              description
              categoryId
              questionId
              newData
              oldData
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
            nextToken
          }
          responses {
            items {
              id
              companyId
              companyCertDataId
              categoryId
              questionId
              pointsEarned
              isAnswered
              answers
              text
              longText
              multiText
              multiDropSelect
              number
              multiNumber
              multiRows
              otherField
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          publicProfile {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            isVisible
            completedAt
            expDate
            companyName
            orgNumber
            description
            contactPerson
            phoneNumber
            website
            economyScore
            economyPercentScore
            socialScore
            socialPercentScore
            environmentalScore
            environmentalPercentScore
            totalScore
            totalPercentScore
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      invitedUsers
      admins
      users {
        items {
          id
          companyId
          username
          status
          name
          email
          phoneNumber
          position
          createdAt
          updatedAt
        }
        nextToken
      }
      todoTasks {
        items {
          id
          companyId
          type
          status
          history
          dueDate
          users
          criteria
          subCriteria
          climateReportId
          climateCriteria
          climateScope
          title
          description
          result
          comments {
            items {
              id
              companyId
              todoTaskId
              userId
              type
              data
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompanyData = /* GraphQL */ `
  subscription OnDeleteCompanyData($admins: String) {
    onDeleteCompanyData(admins: $admins) {
      id
      status
      betaOptIn
      name
      orgNumber
      address
      invoice
      brregData
      certData {
        items {
          id
          companyId
          status
          certType
          certFilterTypes
          employeeAmount
          industryCodes
          completedAt
          expDate
          history {
            items {
              id
              companyId
              companyCertDataId
              action
              timestamp
              userId
              description
              categoryId
              questionId
              newData
              oldData
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
            nextToken
          }
          responses {
            items {
              id
              companyId
              companyCertDataId
              categoryId
              questionId
              pointsEarned
              isAnswered
              answers
              text
              longText
              multiText
              multiDropSelect
              number
              multiNumber
              multiRows
              otherField
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          publicProfile {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            isVisible
            completedAt
            expDate
            companyName
            orgNumber
            description
            contactPerson
            phoneNumber
            website
            economyScore
            economyPercentScore
            socialScore
            socialPercentScore
            environmentalScore
            environmentalPercentScore
            totalScore
            totalPercentScore
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      invitedUsers
      admins
      users {
        items {
          id
          companyId
          username
          status
          name
          email
          phoneNumber
          position
          createdAt
          updatedAt
        }
        nextToken
      }
      todoTasks {
        items {
          id
          companyId
          type
          status
          history
          dueDate
          users
          criteria
          subCriteria
          climateReportId
          climateCriteria
          climateScope
          title
          description
          result
          comments {
            items {
              id
              companyId
              todoTaskId
              userId
              type
              data
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserNotification = /* GraphQL */ `
  subscription OnCreateUserNotification {
    onCreateUserNotification {
      id
      companyId
      userId
      type
      markedAsRead
      archived
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserNotification = /* GraphQL */ `
  subscription OnUpdateUserNotification {
    onUpdateUserNotification {
      id
      companyId
      userId
      type
      markedAsRead
      archived
      data
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserNotification = /* GraphQL */ `
  subscription OnDeleteUserNotification {
    onDeleteUserNotification {
      id
      companyId
      userId
      type
      markedAsRead
      archived
      data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTodoTask = /* GraphQL */ `
  subscription OnCreateTodoTask {
    onCreateTodoTask {
      id
      companyId
      type
      status
      history
      dueDate
      users
      criteria
      subCriteria
      climateReportId
      climateCriteria
      climateScope
      title
      description
      result
      comments {
        items {
          id
          companyId
          todoTaskId
          userId
          type
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTodoTask = /* GraphQL */ `
  subscription OnUpdateTodoTask {
    onUpdateTodoTask {
      id
      companyId
      type
      status
      history
      dueDate
      users
      criteria
      subCriteria
      climateReportId
      climateCriteria
      climateScope
      title
      description
      result
      comments {
        items {
          id
          companyId
          todoTaskId
          userId
          type
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTodoTask = /* GraphQL */ `
  subscription OnDeleteTodoTask {
    onDeleteTodoTask {
      id
      companyId
      type
      status
      history
      dueDate
      users
      criteria
      subCriteria
      climateReportId
      climateCriteria
      climateScope
      title
      description
      result
      comments {
        items {
          id
          companyId
          todoTaskId
          userId
          type
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTodoTaskComment = /* GraphQL */ `
  subscription OnCreateTodoTaskComment($userId: String) {
    onCreateTodoTaskComment(userId: $userId) {
      id
      companyId
      todoTaskId
      userId
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTodoTaskComment = /* GraphQL */ `
  subscription OnUpdateTodoTaskComment($userId: String) {
    onUpdateTodoTaskComment(userId: $userId) {
      id
      companyId
      todoTaskId
      userId
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTodoTaskComment = /* GraphQL */ `
  subscription OnDeleteTodoTaskComment($userId: String) {
    onDeleteTodoTaskComment(userId: $userId) {
      id
      companyId
      todoTaskId
      userId
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDiscountCode = /* GraphQL */ `
  subscription OnCreateDiscountCode {
    onCreateDiscountCode {
      id
      code
      percentDiscount
      durationInMonths
      codeExpDate
      isTimeBased
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDiscountCode = /* GraphQL */ `
  subscription OnUpdateDiscountCode {
    onUpdateDiscountCode {
      id
      code
      percentDiscount
      durationInMonths
      codeExpDate
      isTimeBased
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDiscountCode = /* GraphQL */ `
  subscription OnDeleteDiscountCode {
    onDeleteDiscountCode {
      id
      code
      percentDiscount
      durationInMonths
      codeExpDate
      isTimeBased
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompanyCertData = /* GraphQL */ `
  subscription OnCreateCompanyCertData {
    onCreateCompanyCertData {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      completedAt
      expDate
      history {
        items {
          id
          companyId
          companyCertDataId
          action
          timestamp
          userId
          description
          categoryId
          questionId
          newData
          oldData
          economyScore
          economyPercentScore
          socialScore
          socialPercentScore
          environmentalScore
          environmentalPercentScore
          totalScore
          totalPercentScore
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          companyId
          companyCertDataId
          categoryId
          questionId
          pointsEarned
          isAnswered
          answers
          text
          longText
          multiText
          multiDropSelect
          number
          multiNumber
          multiRows
          otherField
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      publicProfile {
        id
        companyId
        status
        certType
        certFilterTypes
        employeeAmount
        industryCodes
        isVisible
        completedAt
        expDate
        companyName
        orgNumber
        description
        contactPerson
        phoneNumber
        website
        economyScore
        economyPercentScore
        socialScore
        socialPercentScore
        environmentalScore
        environmentalPercentScore
        totalScore
        totalPercentScore
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateCompanyCertData = /* GraphQL */ `
  subscription OnUpdateCompanyCertData {
    onUpdateCompanyCertData {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      completedAt
      expDate
      history {
        items {
          id
          companyId
          companyCertDataId
          action
          timestamp
          userId
          description
          categoryId
          questionId
          newData
          oldData
          economyScore
          economyPercentScore
          socialScore
          socialPercentScore
          environmentalScore
          environmentalPercentScore
          totalScore
          totalPercentScore
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          companyId
          companyCertDataId
          categoryId
          questionId
          pointsEarned
          isAnswered
          answers
          text
          longText
          multiText
          multiDropSelect
          number
          multiNumber
          multiRows
          otherField
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      publicProfile {
        id
        companyId
        status
        certType
        certFilterTypes
        employeeAmount
        industryCodes
        isVisible
        completedAt
        expDate
        companyName
        orgNumber
        description
        contactPerson
        phoneNumber
        website
        economyScore
        economyPercentScore
        socialScore
        socialPercentScore
        environmentalScore
        environmentalPercentScore
        totalScore
        totalPercentScore
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteCompanyCertData = /* GraphQL */ `
  subscription OnDeleteCompanyCertData {
    onDeleteCompanyCertData {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      completedAt
      expDate
      history {
        items {
          id
          companyId
          companyCertDataId
          action
          timestamp
          userId
          description
          categoryId
          questionId
          newData
          oldData
          economyScore
          economyPercentScore
          socialScore
          socialPercentScore
          environmentalScore
          environmentalPercentScore
          totalScore
          totalPercentScore
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          companyId
          companyCertDataId
          categoryId
          questionId
          pointsEarned
          isAnswered
          answers
          text
          longText
          multiText
          multiDropSelect
          number
          multiNumber
          multiRows
          otherField
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      publicProfile {
        id
        companyId
        status
        certType
        certFilterTypes
        employeeAmount
        industryCodes
        isVisible
        completedAt
        expDate
        companyName
        orgNumber
        description
        contactPerson
        phoneNumber
        website
        economyScore
        economyPercentScore
        socialScore
        socialPercentScore
        environmentalScore
        environmentalPercentScore
        totalScore
        totalPercentScore
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateCompanyCertHistoryData = /* GraphQL */ `
  subscription OnCreateCompanyCertHistoryData {
    onCreateCompanyCertHistoryData {
      id
      companyId
      companyCertDataId
      action
      timestamp
      userId
      description
      categoryId
      questionId
      newData
      oldData
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompanyCertHistoryData = /* GraphQL */ `
  subscription OnUpdateCompanyCertHistoryData {
    onUpdateCompanyCertHistoryData {
      id
      companyId
      companyCertDataId
      action
      timestamp
      userId
      description
      categoryId
      questionId
      newData
      oldData
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompanyCertHistoryData = /* GraphQL */ `
  subscription OnDeleteCompanyCertHistoryData {
    onDeleteCompanyCertHistoryData {
      id
      companyId
      companyCertDataId
      action
      timestamp
      userId
      description
      categoryId
      questionId
      newData
      oldData
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCertResponse = /* GraphQL */ `
  subscription OnCreateCertResponse {
    onCreateCertResponse {
      id
      companyId
      companyCertDataId
      categoryId
      questionId
      pointsEarned
      isAnswered
      answers
      text
      longText
      multiText
      multiDropSelect
      number
      multiNumber
      multiRows
      otherField
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCertResponse = /* GraphQL */ `
  subscription OnUpdateCertResponse {
    onUpdateCertResponse {
      id
      companyId
      companyCertDataId
      categoryId
      questionId
      pointsEarned
      isAnswered
      answers
      text
      longText
      multiText
      multiDropSelect
      number
      multiNumber
      multiRows
      otherField
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCertResponse = /* GraphQL */ `
  subscription OnDeleteCertResponse {
    onDeleteCertResponse {
      id
      companyId
      companyCertDataId
      categoryId
      questionId
      pointsEarned
      isAnswered
      answers
      text
      longText
      multiText
      multiDropSelect
      number
      multiNumber
      multiRows
      otherField
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompanyClimateAccReport = /* GraphQL */ `
  subscription OnCreateCompanyClimateAccReport {
    onCreateCompanyClimateAccReport {
      id
      companyId
      status
      title
      year
      userCreatedBy
      infoData
      responses {
        items {
          id
          companyId
          climateAccReportId
          questionId
          scope
          data
          isRelevant
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompanyClimateAccReport = /* GraphQL */ `
  subscription OnUpdateCompanyClimateAccReport {
    onUpdateCompanyClimateAccReport {
      id
      companyId
      status
      title
      year
      userCreatedBy
      infoData
      responses {
        items {
          id
          companyId
          climateAccReportId
          questionId
          scope
          data
          isRelevant
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompanyClimateAccReport = /* GraphQL */ `
  subscription OnDeleteCompanyClimateAccReport {
    onDeleteCompanyClimateAccReport {
      id
      companyId
      status
      title
      year
      userCreatedBy
      infoData
      responses {
        items {
          id
          companyId
          climateAccReportId
          questionId
          scope
          data
          isRelevant
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateClimateAccResponse = /* GraphQL */ `
  subscription OnCreateClimateAccResponse {
    onCreateClimateAccResponse {
      id
      companyId
      climateAccReportId
      questionId
      scope
      data
      isRelevant
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateClimateAccResponse = /* GraphQL */ `
  subscription OnUpdateClimateAccResponse {
    onUpdateClimateAccResponse {
      id
      companyId
      climateAccReportId
      questionId
      scope
      data
      isRelevant
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteClimateAccResponse = /* GraphQL */ `
  subscription OnDeleteClimateAccResponse {
    onDeleteClimateAccResponse {
      id
      companyId
      climateAccReportId
      questionId
      scope
      data
      isRelevant
      createdAt
      updatedAt
    }
  }
`;
export const onCreateClimateEmissionFactor = /* GraphQL */ `
  subscription OnCreateClimateEmissionFactor {
    onCreateClimateEmissionFactor {
      id
      questionId
      name
      scope
      types {
        items {
          id
          type
          value
          emissionFactorID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateClimateEmissionFactor = /* GraphQL */ `
  subscription OnUpdateClimateEmissionFactor {
    onUpdateClimateEmissionFactor {
      id
      questionId
      name
      scope
      types {
        items {
          id
          type
          value
          emissionFactorID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteClimateEmissionFactor = /* GraphQL */ `
  subscription OnDeleteClimateEmissionFactor {
    onDeleteClimateEmissionFactor {
      id
      questionId
      name
      scope
      types {
        items {
          id
          type
          value
          emissionFactorID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateClimateEmissionFactorType = /* GraphQL */ `
  subscription OnCreateClimateEmissionFactorType {
    onCreateClimateEmissionFactorType {
      id
      type
      value
      emissionFactorID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateClimateEmissionFactorType = /* GraphQL */ `
  subscription OnUpdateClimateEmissionFactorType {
    onUpdateClimateEmissionFactorType {
      id
      type
      value
      emissionFactorID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteClimateEmissionFactorType = /* GraphQL */ `
  subscription OnDeleteClimateEmissionFactorType {
    onDeleteClimateEmissionFactorType {
      id
      type
      value
      emissionFactorID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompanyNSRSReport = /* GraphQL */ `
  subscription OnCreateCompanyNSRSReport {
    onCreateCompanyNSRSReport {
      id
      companyId
      status
      reportYear
      userCreatedBy
      responses {
        items {
          id
          companyId
          reportId
          categoryId
          subcategoryId
          questionId
          isAnswered
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompanyNSRSReport = /* GraphQL */ `
  subscription OnUpdateCompanyNSRSReport {
    onUpdateCompanyNSRSReport {
      id
      companyId
      status
      reportYear
      userCreatedBy
      responses {
        items {
          id
          companyId
          reportId
          categoryId
          subcategoryId
          questionId
          isAnswered
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompanyNSRSReport = /* GraphQL */ `
  subscription OnDeleteCompanyNSRSReport {
    onDeleteCompanyNSRSReport {
      id
      companyId
      status
      reportYear
      userCreatedBy
      responses {
        items {
          id
          companyId
          reportId
          categoryId
          subcategoryId
          questionId
          isAnswered
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNSRSResponse = /* GraphQL */ `
  subscription OnCreateNSRSResponse {
    onCreateNSRSResponse {
      id
      companyId
      reportId
      categoryId
      subcategoryId
      questionId
      isAnswered
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNSRSResponse = /* GraphQL */ `
  subscription OnUpdateNSRSResponse {
    onUpdateNSRSResponse {
      id
      companyId
      reportId
      categoryId
      subcategoryId
      questionId
      isAnswered
      data
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNSRSResponse = /* GraphQL */ `
  subscription OnDeleteNSRSResponse {
    onDeleteNSRSResponse {
      id
      companyId
      reportId
      categoryId
      subcategoryId
      questionId
      isAnswered
      data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompanyCertPublicProfile = /* GraphQL */ `
  subscription OnCreateCompanyCertPublicProfile {
    onCreateCompanyCertPublicProfile {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      isVisible
      completedAt
      expDate
      companyName
      orgNumber
      description
      contactPerson
      phoneNumber
      website
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompanyCertPublicProfile = /* GraphQL */ `
  subscription OnUpdateCompanyCertPublicProfile {
    onUpdateCompanyCertPublicProfile {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      isVisible
      completedAt
      expDate
      companyName
      orgNumber
      description
      contactPerson
      phoneNumber
      website
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompanyCertPublicProfile = /* GraphQL */ `
  subscription OnDeleteCompanyCertPublicProfile {
    onDeleteCompanyCertPublicProfile {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      isVisible
      completedAt
      expDate
      companyName
      orgNumber
      description
      contactPerson
      phoneNumber
      website
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCertCategory = /* GraphQL */ `
  subscription OnCreateCertCategory {
    onCreateCertCategory {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              subcategoryId
              question
              description
              explanation
              type
              pointValue
              filterType
              industryCodes
              required
              order
              minChars
              maxChars
              createdAt
              updatedAt
              options {
                items {
                  id
                  questionId
                  pointValue
                  order
                  option
                  isOtherOption
                  isCustomMultiSelectOption
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCertCategory = /* GraphQL */ `
  subscription OnUpdateCertCategory {
    onUpdateCertCategory {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              subcategoryId
              question
              description
              explanation
              type
              pointValue
              filterType
              industryCodes
              required
              order
              minChars
              maxChars
              createdAt
              updatedAt
              options {
                items {
                  id
                  questionId
                  pointValue
                  order
                  option
                  isOtherOption
                  isCustomMultiSelectOption
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCertCategory = /* GraphQL */ `
  subscription OnDeleteCertCategory {
    onDeleteCertCategory {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              subcategoryId
              question
              description
              explanation
              type
              pointValue
              filterType
              industryCodes
              required
              order
              minChars
              maxChars
              createdAt
              updatedAt
              options {
                items {
                  id
                  questionId
                  pointValue
                  order
                  option
                  isOtherOption
                  isCustomMultiSelectOption
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateCertSubcategory = /* GraphQL */ `
  subscription OnCreateCertSubcategory {
    onCreateCertSubcategory {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          subcategoryId
          question
          description
          explanation
          type
          pointValue
          filterType
          industryCodes
          required
          order
          minChars
          maxChars
          createdAt
          updatedAt
          options {
            items {
              id
              questionId
              pointValue
              order
              option
              isOtherOption
              isCustomMultiSelectOption
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCertSubcategory = /* GraphQL */ `
  subscription OnUpdateCertSubcategory {
    onUpdateCertSubcategory {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          subcategoryId
          question
          description
          explanation
          type
          pointValue
          filterType
          industryCodes
          required
          order
          minChars
          maxChars
          createdAt
          updatedAt
          options {
            items {
              id
              questionId
              pointValue
              order
              option
              isOtherOption
              isCustomMultiSelectOption
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCertSubcategory = /* GraphQL */ `
  subscription OnDeleteCertSubcategory {
    onDeleteCertSubcategory {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          subcategoryId
          question
          description
          explanation
          type
          pointValue
          filterType
          industryCodes
          required
          order
          minChars
          maxChars
          createdAt
          updatedAt
          options {
            items {
              id
              questionId
              pointValue
              order
              option
              isOtherOption
              isCustomMultiSelectOption
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateCertQuestion = /* GraphQL */ `
  subscription OnCreateCertQuestion {
    onCreateCertQuestion {
      id
      subcategoryId
      question
      description
      explanation
      type
      pointValue
      filterType
      industryCodes
      required
      order
      minChars
      maxChars
      createdAt
      updatedAt
      options {
        items {
          id
          questionId
          pointValue
          order
          option
          isOtherOption
          isCustomMultiSelectOption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCertQuestion = /* GraphQL */ `
  subscription OnUpdateCertQuestion {
    onUpdateCertQuestion {
      id
      subcategoryId
      question
      description
      explanation
      type
      pointValue
      filterType
      industryCodes
      required
      order
      minChars
      maxChars
      createdAt
      updatedAt
      options {
        items {
          id
          questionId
          pointValue
          order
          option
          isOtherOption
          isCustomMultiSelectOption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCertQuestion = /* GraphQL */ `
  subscription OnDeleteCertQuestion {
    onDeleteCertQuestion {
      id
      subcategoryId
      question
      description
      explanation
      type
      pointValue
      filterType
      industryCodes
      required
      order
      minChars
      maxChars
      createdAt
      updatedAt
      options {
        items {
          id
          questionId
          pointValue
          order
          option
          isOtherOption
          isCustomMultiSelectOption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateCertQuestionOption = /* GraphQL */ `
  subscription OnCreateCertQuestionOption {
    onCreateCertQuestionOption {
      id
      questionId
      pointValue
      order
      option
      isOtherOption
      isCustomMultiSelectOption
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCertQuestionOption = /* GraphQL */ `
  subscription OnUpdateCertQuestionOption {
    onUpdateCertQuestionOption {
      id
      questionId
      pointValue
      order
      option
      isOtherOption
      isCustomMultiSelectOption
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCertQuestionOption = /* GraphQL */ `
  subscription OnDeleteCertQuestionOption {
    onDeleteCertQuestionOption {
      id
      questionId
      pointValue
      order
      option
      isOtherOption
      isCustomMultiSelectOption
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNSRSCategory = /* GraphQL */ `
  subscription OnCreateNSRSCategory {
    onCreateNSRSCategory {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              categoryId
              subcategoryId
              name
              description
              explanation
              filterTypes
              industryCodes
              required
              order
              data
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateNSRSCategory = /* GraphQL */ `
  subscription OnUpdateNSRSCategory {
    onUpdateNSRSCategory {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              categoryId
              subcategoryId
              name
              description
              explanation
              filterTypes
              industryCodes
              required
              order
              data
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteNSRSCategory = /* GraphQL */ `
  subscription OnDeleteNSRSCategory {
    onDeleteNSRSCategory {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              categoryId
              subcategoryId
              name
              description
              explanation
              filterTypes
              industryCodes
              required
              order
              data
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateNSRSSubcategory = /* GraphQL */ `
  subscription OnCreateNSRSSubcategory {
    onCreateNSRSSubcategory {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          categoryId
          subcategoryId
          name
          description
          explanation
          filterTypes
          industryCodes
          required
          order
          data
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateNSRSSubcategory = /* GraphQL */ `
  subscription OnUpdateNSRSSubcategory {
    onUpdateNSRSSubcategory {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          categoryId
          subcategoryId
          name
          description
          explanation
          filterTypes
          industryCodes
          required
          order
          data
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteNSRSSubcategory = /* GraphQL */ `
  subscription OnDeleteNSRSSubcategory {
    onDeleteNSRSSubcategory {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          categoryId
          subcategoryId
          name
          description
          explanation
          filterTypes
          industryCodes
          required
          order
          data
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateNSRSQuestion = /* GraphQL */ `
  subscription OnCreateNSRSQuestion {
    onCreateNSRSQuestion {
      id
      categoryId
      subcategoryId
      name
      description
      explanation
      filterTypes
      industryCodes
      required
      order
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNSRSQuestion = /* GraphQL */ `
  subscription OnUpdateNSRSQuestion {
    onUpdateNSRSQuestion {
      id
      categoryId
      subcategoryId
      name
      description
      explanation
      filterTypes
      industryCodes
      required
      order
      data
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNSRSQuestion = /* GraphQL */ `
  subscription OnDeleteNSRSQuestion {
    onDeleteNSRSQuestion {
      id
      categoryId
      subcategoryId
      name
      description
      explanation
      filterTypes
      industryCodes
      required
      order
      data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTemplateFile = /* GraphQL */ `
  subscription OnCreateTemplateFile {
    onCreateTemplateFile {
      id
      title
      description
      accessLevel
      fileName
      filePath
      fileSize
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTemplateFile = /* GraphQL */ `
  subscription OnUpdateTemplateFile {
    onUpdateTemplateFile {
      id
      title
      description
      accessLevel
      fileName
      filePath
      fileSize
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTemplateFile = /* GraphQL */ `
  subscription OnDeleteTemplateFile {
    onDeleteTemplateFile {
      id
      title
      description
      accessLevel
      fileName
      filePath
      fileSize
      fileType
      createdAt
      updatedAt
    }
  }
`;
