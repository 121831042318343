import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import HubspotContactForm from "./Form";
export default function ContactUs() {
  return (
    <Grid item xs={12}>
      <Paper>
        <Typography variant="h3">Kontakt oss</Typography>
        <Grid item xs={12} sx={{ padding: "40px" }}>
          {HubspotContactForm()}
        </Grid>
      </Paper>
    </Grid>
  );
}
