import React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import nbLocale from 'date-fns/locale/nb';
import { Grid, TextField, InputLabel } from '@mui/material';

export default function DateTime(props) {
    const { data, input, setInput, isInputsDisabled, reportIsCompleted } = props;

    const handleChange = (value, id) => {
        setInput({
            ...input,
            [id]: value,
        });
    };

    return (
        <Grid>
            <InputLabel>
                {data.label} {data.isRequired ? '*' : <></>}
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={nbLocale}>
                <DatePicker
                    mask="__.__.____"
                    id={data.id}
                    clearable
                    renderInput={params => {
                        return (
                            <TextField
                                sx={{ width: '100%' }}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: !reportIsCompleted ? 'dd/mm/yyyy' : '',
                                }}
                            />
                        );
                    }}
                    value={input[data.id] || ''}
                    onChange={value => handleChange(value, data.id)}
                    disabled={isInputsDisabled}
                    readOnly={reportIsCompleted}
                />
            </LocalizationProvider>
        </Grid>
    );
}
