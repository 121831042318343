import React, { useState } from "react";
import {
  Hidden,
  Drawer,
  Toolbar,
  Grid,
  MenuList,
  MenuItem,
  AppBar,
  IconButton,
  Button,
  Typography,
  Avatar,
} from "@mui/material";
import { Arrow, Hamburger } from "../../../../components/Icons";
import { HashLink as Link } from "react-router-hash-link";
import { useStyles } from "../ClimateStyles";
import useRouter from "../../../../hooks/useRouter";

export default function Sidebar(props) {
  const classes = useStyles();
  const router = useRouter();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (open) => {
    setMobileOpen(open);
  };

  const swapColor = (form) => {
    if (form === "keyInfo") {
      return "#FEF0D9";
    } else if (form === "scope1") {
      return "#DEF7F3";
    } else if (form === "scope2") {
      return "#AFECE1";
    } else if (form === "scope3") {
      return "#7BE0CD";
    }
  };

  const swapTitle = (form) => {
    if (form === "keyInfo") {
      return "Nøkkelinformasjon";
    } else if (form === "scope1") {
      return "Scope 1";
    } else if (form === "scope2") {
      return "Scope 2";
    } else if (form === "scope3") {
      return "Scope 3";
    }
  };
  const keyMenu = (
    <MenuList>
      <Link
        smooth
        to={"/climate/" + props.id + "/information#keyinformation"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>1</Avatar>
          Nøkkelinformasjon
        </MenuItem>
      </Link>
    </MenuList>
  );
  const s1Menu = (
    <MenuList>
      <Link
        smooth
        to={"/climate/" + props.id + "/scope1#energy"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>1</Avatar>
          Energi
        </MenuItem>
      </Link>
      <div className={classes.connector} />
      <Link
        smooth
        to={"/climate/" + props.id + "/scope1#transport"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>2</Avatar>
          Transport
        </MenuItem>
      </Link>
      <div className={classes.connector} />
      <Link
        smooth
        to={"/climate/" + props.id + "/scope1#other"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>3</Avatar>
          Annet
        </MenuItem>
      </Link>
    </MenuList>
  );

  const s2Menu = (
    <MenuList>
      <Link
        smooth
        to={"/climate/" + props.id + "/scope2#energy"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>1</Avatar>
          Energi
        </MenuItem>
      </Link>
    </MenuList>
  );

  const s3Menu = (
    <MenuList>
      <Link
        smooth
        to={"/climate/" + props.id + "/scope3#transport"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>1</Avatar>
          Transport
        </MenuItem>
      </Link>
      <div className={classes.connector} />
      <Link
        smooth
        to={"/climate/" + props.id + "/scope3#purchase"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>2</Avatar>
          Innkjøp
        </MenuItem>
      </Link>
      <div className={classes.connector} />
      <Link
        smooth
        to={"/climate/" + props.id + "/scope3#sale"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>3</Avatar>
          Salg
        </MenuItem>
      </Link>
      <div className={classes.connector} />
      <Link
        smooth
        to={"/climate/" + props.id + "/scope3#investments"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>4</Avatar>
          Investeringer
        </MenuItem>
      </Link>
      <div className={classes.connector} />
      <Link
        smooth
        to={"/climate/" + props.id + "/scope3#residual"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>5</Avatar>
          Restprodukter
        </MenuItem>
      </Link>
      <div className={classes.connector} />
      <Link
        smooth
        to={"/climate/" + props.id + "/scope3#waste"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>6</Avatar>
          Avfall
        </MenuItem>
      </Link>
      <div className={classes.connector} />
      <Link
        smooth
        to={"/climate/" + props.id + "/scope3#chemicals"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>7</Avatar>
          Kjemikalier
        </MenuItem>
      </Link>
      <div className={classes.connector} />
      <Link
        smooth
        to={"/climate/" + props.id + "/scope3#water"}
        className={classes.link}
        active={classes.active}
      >
        <MenuItem className={classes.menuItem}>
          <Avatar>8</Avatar>
          Vann
        </MenuItem>
      </Link>
    </MenuList>
  );

  const setMenuItems = (form) => {
    if (form === "keyInfo") {
      return keyMenu;
    } else if (form === "scope1") {
      return s1Menu;
    } else if (form === "scope2") {
      return s2Menu;
    } else if (form === "scope3") {
      return s3Menu;
    }
  };
  return (
    <Grid>
      <Hidden mdDown>
        <Drawer variant="permanent" anchor="left">
          <Grid
            item
            xs={12}
            style={{ backgroundColor: swapColor(props.form), padding: "20px" }}
          >
            <Button
              type="text"
              startIcon={Arrow}
              onClick={() => router.push("/climate/" + props.report.id)}
            >
              Gå tilbake
            </Button>
            <Typography variant="h1">{swapTitle(props.form)}</Typography>
            {setMenuItems(props.form)}
          </Grid>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <AppBar position="fixed">
          <Toolbar
            sx={{
              minHeight: "40px !important",
              backgroundColor: swapColor(props.form),
              padding: "10px 0px",
            }}
          >
            <IconButton onClick={() => handleDrawerToggle(true)}>
              {Hamburger}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          anchor="left"
          onClick={() => handleDrawerToggle(false)}
          onClose={() => handleDrawerToggle(false)}
        >
          <Grid
            item
            xs={12}
            style={{ backgroundColor: swapColor(props.form), padding: "20px" }}
          >
            <Button
              type="text"
              startIcon={Arrow}
              onClick={() => router.push("/climate/" + props.report.id)}
            >
              Gå tilbake
            </Button>
            <Typography variant="h1">{swapTitle(props.form)}</Typography>
            {setMenuItems(props.form)}
          </Grid>
        </Drawer>
      </Hidden>
    </Grid>
  );
}
