import React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { ArrowRight, Certify } from "../../../../components/Icons";
import { useStyles } from "../CertificationStyles";
import ProgressBars from "../../../../components/ProgessBar";
import * as Text from "../../../../helpers/certification.json";

export default function FinishCertModule(props) {
  const classes = useStyles();

  return (
    <>
      {props.isCompleted ? (
        <Paper
          sx={{
            height: "100%",
          }}
        >
          <Typography variant="h3" sx={{ backgroundColor: "#E2EFF1" }}>
            {Certify}Oversikt
          </Typography>
          <Typography
            variant="h1"
            sx={{ padding: "20px 0px 0px 20px", textAlign: "left" }}
          >
            Gratulerer!
          </Typography>
          <Grid item style={{ padding: "0px 20px", position: "relative" }}>
            <Typography variant="body1">
              {Text.FinishModule.Finished}
            </Typography>
          </Grid>
          <Grid item style={{ padding: "0px 20px 20px 20px" }}>
            <Typography variant="h4" className={classes.progressionText}>
              {props.remainingQuestions} spørsmål gjenstår (
              {props.remainingObligatory} obligatoriske)
            </Typography>
            <ProgressBars
              value={props.percentComplete}
              backgroundColor="#BDCCD4"
            />
          </Grid>
          <Grid item xs={12} className={classes.finishFooter}>
            <Typography variant="body2"></Typography>
            <Button
              variant="contained"
              className={classes.finishButton}
              onClick={props.handleOpenDialog}
            >
              Fullfør {ArrowRight}
            </Button>
          </Grid>
        </Paper>
      ) : (
        <Paper
          sx={{
            height: "100%",
          }}
        >
          <Typography variant="h3" sx={{ backgroundColor: "#E2EFF1" }}>
            {Certify}Oversikt
          </Typography>
          <Grid item style={{ padding: "20px", position: "relative" }}>
            <Typography variant="body1">
              {Text.FinishModule.UnFinished}
            </Typography>
          </Grid>
          <Grid item style={{ padding: "0px 20px 20px 20px" }}>
            <Typography variant="h4" className={classes.progressionText}>
              {props.remainingQuestions} spørsmål gjenstår (
              {props.remainingObligatory} obligatoriske)
            </Typography>
            <ProgressBars
              value={props.percentComplete}
              backgroundColor="#BDCCD4"
            />
          </Grid>
          <Grid item xs={12} className={classes.finishFooter}>
            <Button
              variant="contained"
              disabled
              className={classes.finishButton}
              onClick={props.handleOpenDialog}
            >
              Fullfør {ArrowRight}
            </Button>
          </Grid>
        </Paper>
      )}
    </>
  );
}
