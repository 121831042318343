import React, { useState, useEffect } from 'react';
import {
    InputLabel,
    Grid,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    InputBase,
    IconButton,
    NativeSelect,
    Typography,
} from '@mui/material';
import { Add, Delete } from '../../../../../components/Icons';
import { withStyles } from '@mui/styles';
import { useStyles } from './../../NSRSStyles';
import naturalCompare from '../../../../../helpers/naturalCompare';

export const CustomRow = withStyles(() => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f3f3f3',
            borderTop: '1px solid #EBEBEB',
            borderBottom: '1px solid #EBEBEB',
        },
    },
}))(TableRow);

export const CustomCell = withStyles(() => ({
    root: {
        padding: '0px 5px',
        borderBottom: '0px',
    },
    head: {
        padding: '10px 10px 0px 10px',
        fontWeight: 'bold',
    },
    body: {
        borderRight: '1px solid #E8E8E8',
        '&:last-child': {
            borderRight: '0px',
        },
    },
}))(TableCell);

export default function MultipleSelectsWithInputs(props) {
    const { data, input, setInput, reportIsCompleted } = props;
    const classes = useStyles();

    const [rowInputs, setRowInputs] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            let newArr = [];

            if (data) {
                for (const alt of data.alternatives.sort((a, b) => {
                    return a.order - b.order;
                })) {
                    let newObj = {
                        id: alt.id,
                        input: '',
                        label: alt.label,
                        order: parseInt(alt.order),
                    };

                    newArr.push(newObj);
                }
            }

            setRowInputs([...newArr]);
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            checkIfRowInputsFilled();
        }

        return () => {
            isMounted = true;
        };
    }, [rowInputs]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (alt, value) => {
        if (alt.type === 'Number') {
            if (alt.maxChars !== 0) {
                if (value.length > alt.maxChars) {
                    value = value.slice(0, alt.maxChars);
                }
            }
        }

        let newArr = rowInputs;

        let index = newArr.findIndex(item => {
            return item.id === alt.id;
        });

        if (index !== -1) {
            newArr[index].input = value;
        }

        setRowInputs([...newArr]);
    };

    const checkIfRowInputsFilled = () => {
        let inputsFilled = 0;

        for (const input of rowInputs) {
            if (input.input && input.input !== '') {
                inputsFilled = inputsFilled + 1;
            }
        }

        if (inputsFilled === rowInputs.length) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };

    const handleAdd = () => {
        let newArr = [];

        if (input[data.id]) {
            newArr = input[data.id];
        }

        let newDataArr = [];

        for (const item of rowInputs) {
            let newObj = {
                id: item.id,
                label: item.label,
                value: item.input,
                order: parseInt(item.order),
            };

            item.input = '';

            newDataArr.push(newObj);
        }

        newArr.push(newDataArr);

        setInput({
            ...input,
            [data.id]: newArr,
        });
    };

    const handleDelete = index => {
        let newArr = input[data.id];

        newArr.splice(index, 1);

        setInput({
            ...input,
            [data.id]: newArr,
        });
    };

    return (
        <Grid sx={{ marginBottom: '30px' }}>
            {!reportIsCompleted ? (
                <>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        {data.alternatives &&
                            rowInputs.length !== 0 &&
                            data.alternatives
                                .sort((a, b) => {
                                    return a.order - b.order;
                                })
                                .map(alt => {
                                    let inputData = rowInputs.find(rowInput => {
                                        return rowInput.id === alt.id;
                                    });

                                    if (alt.type === 'Text') {
                                        return (
                                            <Grid key={alt.id} item xs={12} sx={{ marginRight: '10px' }}>
                                                <InputLabel>
                                                    {alt.label} {alt.isRequired ? '*' : <></>}
                                                </InputLabel>
                                                <InputBase
                                                    type="text"
                                                    name={alt.label}
                                                    placeholder="Skriv her..."
                                                    value={inputData.input}
                                                    onChange={e => handleChange(alt, e.target.value)}
                                                    inputProps={{
                                                        maxLength:
                                                            alt.maxChars && alt.maxChars !== 0 ? alt.maxChars : 2000,
                                                    }}
                                                />
                                                {alt.maxChars && alt.maxChars !== 0 ? (
                                                    <Typography variant="body2" className={classes.countText}>
                                                        {(inputData.input && inputData.input.length) || 0}/
                                                        {alt.maxChars}
                                                    </Typography>
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                        );
                                    } else if (alt.type === 'Number') {
                                        return (
                                            <Grid key={alt.id} item xs={12} sx={{ marginRight: '10px' }}>
                                                <InputLabel>
                                                    {alt.label} {alt.isRequired ? '*' : <></>}
                                                </InputLabel>
                                                <InputBase
                                                    type="number"
                                                    name={alt.label}
                                                    placeholder="Skriv her..."
                                                    value={inputData.input}
                                                    onChange={e => handleChange(alt, e.target.value)}
                                                />
                                                {alt.maxChars && alt.maxChars !== 0 ? (
                                                    <Typography variant="body2" className={classes.countText}>
                                                        {(inputData.input && inputData.input.length) || 0}/
                                                        {alt.maxChars}
                                                    </Typography>
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                        );
                                    } else if (alt.type === 'Select') {
                                        return (
                                            <Grid key={alt.id} item xs={12} sx={{ marginRight: '10px' }}>
                                                <InputLabel>
                                                    {alt.label} {alt.isRequired ? '*' : <></>}
                                                </InputLabel>
                                                <NativeSelect
                                                    input={<InputBase />}
                                                    value={inputData.input}
                                                    onChange={e => handleChange(alt, e.target.value)}
                                                >
                                                    <option value="" disabled>
                                                        Velg ett alternativ
                                                    </option>
                                                    {alt.choices &&
                                                        alt.choices.sort(naturalCompare).map(choice => {
                                                            return (
                                                                <option key={choice} value={choice}>
                                                                    {choice}
                                                                </option>
                                                            );
                                                        })}
                                                </NativeSelect>
                                            </Grid>
                                        );
                                    } else {
                                        return <React.Fragment key={alt.id}></React.Fragment>;
                                    }
                                })}
                        <Grid item sx={{ marginTop: '30px' }}>
                            <Button
                                variant="contained"
                                sx={{ width: '130px' }}
                                onClick={handleAdd}
                                disabled={isDisabled}
                            >
                                {Add}Legg til
                            </Button>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <></>
            )}
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <CustomRow>
                                {data.alternatives &&
                                    data.alternatives
                                        .sort((a, b) => {
                                            return a.order - b.order;
                                        })
                                        .map(alt => {
                                            return (
                                                <CustomCell
                                                    key={alt.id}
                                                    align="left"
                                                    sx={{ borderBottom: '2px solid #FDDFB0 !important' }}
                                                >
                                                    {alt.label}
                                                </CustomCell>
                                            );
                                        })}
                                {!reportIsCompleted ? (
                                    <CustomCell align="center" sx={{ borderBottom: '2px solid #FDDFB0 !important' }}>
                                        Slett
                                    </CustomCell>
                                ) : (
                                    <></>
                                )}
                            </CustomRow>
                        </TableHead>
                        <TableBody>
                            {input[data.id] && input[data.id].length > 0 ? (
                                input[data.id]
                                    .sort((a, b) => {
                                        return a.order - b.order;
                                    })
                                    .map((answer, index) => {
                                        return (
                                            <CustomRow key={index} sx={{ borderTop: '1px solid #EBEBEB' }}>
                                                {answer &&
                                                    answer
                                                        .sort((a, b) => {
                                                            return a.order - b.order;
                                                        })
                                                        .map(alt => {
                                                            return (
                                                                <CustomCell
                                                                    key={alt.id}
                                                                    align="left"
                                                                    sx={{
                                                                        padding: '0px 10px 0px 10px !important',
                                                                        flexWrap: 'wrap !important',
                                                                    }}
                                                                >
                                                                    {alt.value}
                                                                </CustomCell>
                                                            );
                                                        })}
                                                {!reportIsCompleted ? (
                                                    <CustomCell align="center">
                                                        <IconButton onClick={() => handleDelete(index)}>
                                                            {Delete}
                                                        </IconButton>
                                                    </CustomCell>
                                                ) : (
                                                    <></>
                                                )}
                                            </CustomRow>
                                        );
                                    })
                            ) : (
                                <CustomRow sx={{ borderTop: '1px solid #EBEBEB' }}>
                                    <CustomCell align="left" sx={{ padding: '10px 20px 10px 20px !important' }}>
                                        Ingen svar lagt til.
                                    </CustomCell>
                                </CustomRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
