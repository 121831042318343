import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, Divider, ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputForm, { NumberInput } from '../../../../../components/InputForm';

import useToggle from '../../../../../hooks/useToggle';

import EmissionFactorTypeListView from './EmissionFactorTypeListView';

import { API, graphqlOperation } from 'aws-amplify';
import { updateClimateEmissionFactor, deleteClimateEmissionFactor } from '../../../../../graphql/mutations';

export default function EmissionFactorRowView(props) {
    const [rowOpen, setRowOpen] = useToggle();
    const [dialogOpen, setDialogOpen] = useToggle();

    const [questionId, setQuestionId] = useState('');
    const [name, setName] = useState('');
    const [scope, setScope] = useState(1);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            setQuestionId(props.data.questionId);
            setName(props.data.name);
            setScope(props.data.scope);
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateFactor = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateClimateEmissionFactor, {
                    input: {
                        id: props.data.id,
                        questionId: questionId,
                        name: name,
                        scope: scope,
                    },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated factor: ', data);
                }

                resolve();
            });
        });
    };

    const deleteFactor = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(deleteClimateEmissionFactor, {
                    input: { id: props.data.id },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Deleted factor: ', data);
                }

                resolve();
            });
        });
    };

    const handleUpdateFactor = async () => {
        if (questionId === '' || name === '') {
            props.showPopup('ID og navn må være fylt ut!');
            return;
        }

        await updateFactor().catch(() => {
            props.showPopup('En feil oppsto ved lagring av faktor');
            return;
        });

        props.showPopup('Faktoren har blitt lagret');

        await props.update();

        handleDialog();
    };

    const handleDeleteFactor = async () => {
        await deleteFactor().catch(() => {
            props.showPopup('En feil oppsto ved sletting av utslippsfaktoren');
            return;
        });

        props.showPopup('Faktoren har blitt slettet');

        await props.update();
    };

    const handleDialog = () => {
        setDialogOpen();

        setQuestionId(props.data.questionId);
        setName(props.data.name);
        setScope(props.data.scope);
    };

    const updateFactorDialog = () => {
        return (
            <Grid>
                <Dialog open={dialogOpen} onClose={handleDialog} fullWidth maxWidth="md">
                    <DialogTitle sx={{ fontSize: '2em' }}>Oppdater spørsmålet</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Oppdater verdiene til utslippsfaktoren.
                        </DialogContentText>
                        <InputForm
                            label="ID til spørsmål"
                            placeholder="Skriv inn ID..."
                            value={questionId}
                            onChange={e => setQuestionId(e.target.value)}
                        />
                        <InputForm
                            label="Navn på utslippsfaktoren"
                            placeholder="Skriv inn navn..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <NumberInput
                            label="Hvilke scope tilhører faktoren"
                            placeholder="Skriv inn nummer på scope..."
                            value={scope}
                            onChange={e => setScope(e.target.value)}
                            min={1}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleUpdateFactor}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid>
            {updateFactorDialog()}
            <Paper sx={{ padding: '20px', marginBottom: '10px' }}>
                <ListItem disablePadding key={props.data.id}>
                    <ListItemButton onClick={setRowOpen}>
                        <ListItemIcon>
                            <DehazeIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                'Scope ' + props.data.scope + ' (' + props.data.questionId + '): ' + props.data.name
                            }
                        />
                    </ListItemButton>
                </ListItem>
                {!rowOpen ? (
                    <></>
                ) : (
                    <Grid>
                        <Divider />
                        <Grid sx={{ margin: '15px' }}>
                            <b>Spørsmål ID:</b>
                            <br /> {props.data.questionId}
                            <br />
                            <br />
                            <b>Scope:</b>
                            <br /> {props.data.scope}
                        </Grid>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '200px' }}
                            onClick={setDialogOpen}
                        >
                            Oppdater utslippsfaktor{' '}
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '200px' }}
                            onClick={handleDeleteFactor}
                        >
                            Slett utslippsfaktor{' '}
                        </Button>
                        <EmissionFactorTypeListView
                            showPopup={props.showPopup}
                            update={props.update}
                            factorId={props.data.id}
                            data={props.data.types.items}
                        />
                        <Divider />
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
}
