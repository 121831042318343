import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  Button,
  Box,
  Collapse,
  Divider,
  TextField,
} from "@mui/material";
import { useStyles } from "../../ClimateStyles";
import useRouter from "../../../../../hooks/useRouter";
import { ArrowRight } from "../../../../../components/Icons";
import * as Text from "../../../../../helpers/climateAccounting.json";
import { updateClimateReport } from "../../climateFunctions";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

export default function YearPeriodInput(props) {
  const {
    updateReport,
    CollapseColor,
    CheckedColor,
    IconChange,
    index,
    open,
    changeOpen,
    openNext,
  } = props;
  const classes = useStyles();
  const router = useRouter();
  const [isAnswered, setIsAnswered] = useState(false);
  const yearOptions = ["2022", "2021", "2020", "2019", "2018", "2017"];
  const [year, setYear] = useState("");

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      fetchYear();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchYear = () => {
    if (props.report && props.report.year) {
      setYear(props.report.year.toString());

      setIsAnswered(true);
    }
  };

  const handleSave = async () => {
    const input = {
      id: props.report.id,
      year: year * 1,
    };

    await updateClimateReport(input).then(async (data) => {
      router.push({
        state: {
          report: data,
        },
      });

      await updateReport();

      openNext(index);
    });
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.question}
        sx={{
          background: CollapseColor(isAnswered, open),
        }}
        onClick={() => changeOpen(index, !open)}
      >
        <TableCell
          className={classes.iconCell}
          style={{
            backgroundColor: CheckedColor(isAnswered),
            width: "7%",
          }}
          align="center"
        >
          {IconChange(isAnswered)}
        </TableCell>
        <TableCell component="th" scope="row" className={classes.questionRow}>
          <Typography variant="h3">Periode for klimaregnskapet</Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: "20px 20px 0px 20px" }}>
              <Typography variant="body1">
                {Text.KeyInformation.Year}
              </Typography>
              <Divider className={classes.divider} />
              <Autocomplete
                value={year}
                onChange={(event, newValue) => {
                  if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setYear(newValue.inputValue);
                  } else {
                    setYear(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(inputValue);
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={yearOptions}
                getOptionLabel={(option) => {
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }

                  // Regular option
                  return option;
                }}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Søk årstall eller skriv inn egendefinert navn..."
                  />
                )}
              />
              <Grid container direction="row" className={classes.content}>
                <Button variant="contained" onClick={handleSave}>
                  Lagre{ArrowRight}
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "30px" }}></Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: "20px" }} />
    </React.Fragment>
  );
}
