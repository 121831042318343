import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Link,
  Divider,
  Button,
  InputBase,
  InputLabel,
} from "@mui/material";
import { useAuth } from "../../../../auth/useAuth";
import useToggle from "../../../../hooks/useToggle";
import { useStyles } from "../ProfileStyles";
import TextFields from "../../../../components/TextField";
import { FirmHeader } from "../../../../components/Icons";
import { updatePublicProfile } from "../profileFunctions";

import amplitude from "amplitude-js";

export default function CompanySummary() {
  const classes = useStyles();
  const auth = useAuth();

  const [isVisible, setIsVisible] = useState(false);
  const [isEditingInfo, setIsEditingInfo] = useToggle();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [contact, setContact] = useState("");
  const [phoneNr, setPhoneNr] = useState("");
  const [orgNr, setOrgNr] = useState("");

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      let publicProfile =
        auth.companyData.companyData.certData.items[0].publicProfile;

      if (publicProfile) {
        if (publicProfile.website) {
          setWebsite(publicProfile.website);
        }

        setName(publicProfile.companyName);
        setDescription(publicProfile.description);
        setContact(publicProfile.contactPerson);
        setPhoneNr(publicProfile.phoneNumber);
        setOrgNr(publicProfile.orgNumber);
      }

      setIsVisible(publicProfile.isVisible);
    }

    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetIsPublic = async (event) => {
    let inputData = {
      id: auth.companyData.companyData.certData.items[0].publicProfile.id,
      isVisible: event.target.checked,
    };

    setIsVisible(event.target.checked);

    await updatePublicProfile(inputData);

    if (process.env.NODE_ENV !== "development") {
      amplitude.getInstance().logEvent("company_toggle_visibility", {
        visibility: event.target.checked,
      });
    }
  };

  const changeLabel = () => {
    if (isVisible) {
      return "Du er synlig";
    } else {
      return "Du er ikke synlig";
    }
  };

  const handleEditCompany = () => {
    let publicProfile =
      auth.companyData.companyData.certData.items[0].publicProfile;

    if (publicProfile) {
      if (publicProfile.website) {
        setWebsite(publicProfile.website);
      }

      setName(publicProfile.companyName);
      setDescription(publicProfile.description);
      setContact(publicProfile.contactPerson);
      setPhoneNr(publicProfile.phoneNumber);
      setOrgNr(publicProfile.orgNumber);
    }

    setIsEditingInfo();
  };

  const handleSavePublicInfo = async () => {
    let inputData = {
      id: auth.companyData.companyData.certData.items[0].publicProfile.id,
      companyName: name,
      description: description,
      contactPerson: contact,
      phoneNumber: phoneNr,
      orgNumber: orgNr,
      website: website,
    };

    await updatePublicProfile(inputData).then(async () => {
      await auth.fetchCompanyInfo(auth.companyData.companyId);
      setIsEditingInfo();
    });
  };

  return (
    <Grid item xs={12} sx={{ marginBottom: "20px" }}>
      <Typography variant="h3">{FirmHeader}Offentlig index</Typography>

      <Paper sx={{ padding: "30px" }}>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ marginBottom: "5px" }}>
            Ønsker {auth.companyData.name} å være synlig i{" "}
            <a
              href="https://www.app.ecofric.com/index/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000" }}
            >
              Ecofric-indexen
            </a>{" "}
            ?
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  inputProps={{ "aria-label": "controlled" }}
                  color="warning"
                />
              }
              label={changeLabel()}
              checked={isVisible}
              onChange={handleSetIsPublic}
            />
          </FormGroup>
        </Grid>
        {isVisible ? (
          <Grid>
            <Typography variant="body1" sx={{ marginTop: "10px" }}>
              Området under viser hvordan {auth.companyData.name} sin offentlige
              index side ser ut. Informasjonen som vises og hvorvidt dere ønsker
              å være synlig kan endres her.
            </Typography>
            <Divider sx={{ border: "2px solid #F3CCD1", margin: "20px 0px" }} />
            {!isEditingInfo ? (
              <Grid container direction="row" spacing={4}>
                <Grid item xs={7}>
                  <Typography variant="h1" sx={{ textAlign: "left" }}>
                    {
                      auth.companyData.companyData.certData.items[0]
                        .publicProfile.name
                    }
                  </Typography>
                  <Typography variant="body1">
                    {
                      auth.companyData.companyData.certData.items[0]
                        .publicProfile.description
                    }
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow sx={{ height: "60px" }}>
                          <TableCell>Nettsted</TableCell>
                          <TableCell>
                            {!auth.companyData.companyData.certData.items[0]
                              .publicProfile.website ||
                            auth.companyData.companyData.certData.items[0]
                              .publicProfile.website === "" ? (
                              <>Ingen nettside er satt</>
                            ) : (
                              <Link
                                href={
                                  auth.companyData.companyData.certData.items[0]
                                    .publicProfile.website
                                }
                                target="_blank"
                                sx={{ textDecoration: "underline" }}
                              >
                                {
                                  auth.companyData.companyData.certData.items[0]
                                    .publicProfile.website
                                }
                              </Link>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ height: "60px" }}>
                          <TableCell>Kontaktperson</TableCell>
                          <TableCell>
                            {
                              auth.companyData.companyData.certData.items[0]
                                .publicProfile.contactPerson
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ height: "60px" }}>
                          <TableCell>Telefon</TableCell>
                          <TableCell>
                            {
                              auth.companyData.companyData.certData.items[0]
                                .publicProfile.phoneNumber
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ height: "60px" }}>
                          <TableCell>Org. nr</TableCell>
                          <TableCell>
                            {
                              auth.companyData.companyData.certData.items[0]
                                .publicProfile.orgNumber
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: 100 }}
                    className={classes.editbutton}
                    onClick={handleEditCompany}
                  >
                    Rediger info
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item style={{ marginTop: 20 }}>
                <Typography
                  variant="h2"
                  style={{ justifyContent: "left", marginBottom: 10 }}
                >
                  Rediger offentlig informasjon
                </Typography>
                <form autoComplete="on">
                  <Grid item xs={12}>
                    <InputLabel className={classes.editlabel}>
                      Vist bedriftsnavn:
                    </InputLabel>
                    <InputBase
                      id="companyAddress"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className={classes.editlabel}>
                      Bedriftsbeskrivelse:
                    </InputLabel>
                    <TextFields
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.editfield}>
                    <Grid item xs={6} sx={{ marginRight: "10px" }}>
                      <InputLabel className={classes.editlabel}>
                        Nettsted:
                      </InputLabel>
                      <InputBase
                        id="companyAddress"
                        type="text"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ marginLeft: "10px" }}>
                      <InputLabel className={classes.editlabel}>
                        Kontaktperson:
                      </InputLabel>
                      <InputBase
                        id="companyEmail"
                        type="epost"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.editfield}>
                    <Grid item xs={6} sx={{ marginRight: "10px" }}>
                      <InputLabel className={classes.editlabel}>
                        Telefon:
                      </InputLabel>
                      <InputBase
                        id="companyAddress"
                        type="tel"
                        value={phoneNr}
                        onChange={(e) => setPhoneNr(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ marginLeft: "10px" }}>
                      <InputLabel className={classes.editlabel}>
                        Org. nummer:
                      </InputLabel>
                      <InputBase
                        id="companyEmail"
                        type="number"
                        value={orgNr}
                        onChange={(e) => setOrgNr(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid item className={classes.editfield}></Grid>
                </form>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.editbutton}
                  onClick={handleSavePublicInfo}
                >
                  Lagre
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.editbutton2}
                  onClick={handleEditCompany}
                >
                  Avbryt
                </Button>
              </Grid>
            )}
          </Grid>
        ) : (
          <></>
        )}
      </Paper>
    </Grid>
  );
}
