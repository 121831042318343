import React, { useState } from 'react';
import {
    Paper,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    Chip,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    Hidden,
    DialogActions,
} from '@mui/material';
import { ArrowRight, Delete, Download } from '../../../../components/Icons';
import useRouter from '../../../../hooks/useRouter';
import getDateFromTimestamp from '../../../../helpers/getDateFromTimestamp';
import { deleteClimateReport, reopenReport } from '../climateFunctions';
import { ClimateAccounting } from '../../Documents/components/ClimateAccountingPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useAuth } from '../../../../auth/useAuth';
import amplitude from 'amplitude-js';
import { useStyles } from '../ClimateStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteDialog = props => {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);

    const handleDeleteReport = async () => {
        await deleteClimateReport(props.id).then(() => {
            props.updateReports();
        });
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
                TransitionComponent={Transition}
            >
                <DialogTitle sx={{ textAlign: 'left', fontSize: '30px' }}>
                    Sikker på at du vil slette klimaregnskapet?
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Om du trykker slett vil klimaregnskapet: {props.title !== '' ? props.title : 'Ingen tittel'} bli
                        slettet permanent.
                    </Typography>
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className={classes.closePopUp} autoFocus onClick={handleCloseDialog}>
                        Lukk
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            width: '150px',
                            height: '50px',
                        }}
                        autoFocus
                        onClick={handleDeleteReport}
                    >
                        Slett
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton onClick={handleOpenDialog}>{Delete}</IconButton>
        </>
    );
};

export default function ClimateOverview(props) {
    const auth = useAuth();
    const router = useRouter();
    const classes = useStyles();

    const getScopeTotalEmission = (responses, scope) => {
        let total = 0;

        for (const item of responses) {
            if (item.scope === scope) {
                let data = JSON.parse(item.data);

                if (data) {
                    for (const dataItem of data) {
                        if (dataItem && dataItem.emission) {
                            total = total + dataItem.emission;
                        }
                    }
                }
            }
        }

        return total;
    };

    const renderEmission = (responses, scope) => {
        let totalEmission = getScopeTotalEmission(responses, scope);

        // If higher than 100, start displaying as ton instead of kg
        if (totalEmission >= 100) {
            let newTotalEmission = totalEmission / 1000;

            return newTotalEmission.toFixed(2) + ' tonn CO2e';
        } else {
            return totalEmission.toFixed(2) + ' kg CO2e';
        }
    };

    const ChipColor = report => {
        if (report.status === 'Draft') {
            return '#FDDFB0';
        } else {
            return '#AFECE1';
        }
    };

    const downloadDocument = () => {
        if (process.env.NODE_ENV !== 'development') {
            amplitude.getInstance().logEvent('climate_report_downloaded');
        }
    };

    const getLatestUpdateDate = (responses, updatedAt) => {
        if (!responses) {
            return getDateFromTimestamp(updatedAt);
        }

        let updateDate = new Date(updatedAt);

        for (const resp of responses) {
            if (new Date(resp.updatedAt).getTime() > updateDate.getTime()) {
                updateDate = new Date(resp.updatedAt);
            }
        }

        return getDateFromTimestamp(updateDate);
    };

    const handleReopenReport = async report => {
        await reopenReport(report.id).then(() => {
            router.push({
                pathname: '/climate/' + report.id,
                state: {
                    report: report,
                },
            });
        });
    };

    return (
        <Paper>
            <Typography variant="h3">Tidligere klimaregnskap</Typography>
            <Grid item xs={12} sx={{ padding: '20px' }}>
                {props.reports && props.reports.length !== 0 ? (
                    <>
                        <Hidden lgDown>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ width: '10%' }}>
                                                Status
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }}>Tittel</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Opprettet</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Sist redigert</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Scope 1</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Scope 2</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Scope 3</TableCell>
                                            <TableCell sx={{ width: '10%' }} align="center">
                                                Slett
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }} align="right"></TableCell>
                                            <TableCell sx={{ width: '10%' }} align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reports &&
                                            props.reports
                                                .sort((a, b) => b.status.localeCompare(a.status))
                                                .map(report => {
                                                    return (
                                                        <TableRow key={report.id}>
                                                            <TableCell align="center">
                                                                <Chip
                                                                    label={
                                                                        report.status === 'Draft'
                                                                            ? 'Utkast'
                                                                            : 'Fullført'
                                                                    }
                                                                    sx={{
                                                                        backgroundColor: ChipColor(report),
                                                                    }}
                                                                ></Chip>
                                                            </TableCell>
                                                            <TableCell>
                                                                {report.title !== '' ? report.title : 'Ingen tittel'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {getDateFromTimestamp(report.createdAt)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {getLatestUpdateDate(
                                                                    report.responses.items,
                                                                    report.updatedAt,
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {renderEmission(report.responses.items, 1)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {renderEmission(report.responses.items, 2)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {renderEmission(report.responses.items, 3)}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <DeleteDialog
                                                                    id={report.id}
                                                                    title={report.title}
                                                                    updateReports={props.updateReports}
                                                                />
                                                            </TableCell>
                                                            {report.status === 'Completed' ? (
                                                                <TableCell align="center">
                                                                    <Button
                                                                        onClick={() => handleReopenReport(report)}
                                                                        variant="contained"
                                                                        sx={{
                                                                            backgroundColor: '#FDDFB0',
                                                                        }}
                                                                    >
                                                                        Gjenåpne {ArrowRight}
                                                                    </Button>
                                                                </TableCell>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <TableCell>
                                                                {report.status === 'Draft' ? (
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={{
                                                                            backgroundColor: '#FDDFB0',
                                                                        }}
                                                                        onClick={() =>
                                                                            router.push({
                                                                                pathname: '/climate/' + report.id,
                                                                                state: {
                                                                                    report: report,
                                                                                },
                                                                            })
                                                                        }
                                                                    >
                                                                        Fortsett {ArrowRight}
                                                                    </Button>
                                                                ) : JSON.parse(auth.companyData.companyData.invoice)
                                                                      .status !== 'Trial' ? (
                                                                    <PDFDownloadLink
                                                                        style={{
                                                                            color: '#000',
                                                                            textDecoration: 'none',
                                                                        }}
                                                                        document={
                                                                            <ClimateAccounting
                                                                                report={report}
                                                                                companyName={
                                                                                    auth.companyData.companyData.name
                                                                                }
                                                                            />
                                                                        }
                                                                        fileName="Klimaregnskaprapport.pdf"
                                                                    >
                                                                        {({ blob, url, loading, error }) =>
                                                                            loading ? (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    disabled={true}
                                                                                    className={classes.download}
                                                                                >
                                                                                    Laster inn dokument...
                                                                                </Button>
                                                                            ) : (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    onClick={() =>
                                                                                        downloadDocument(blob)
                                                                                    }
                                                                                    className={classes.download}
                                                                                >
                                                                                    Last ned PDF {Download}
                                                                                </Button>
                                                                            )
                                                                        }
                                                                    </PDFDownloadLink>
                                                                ) : (
                                                                    <Button
                                                                        onClick={() => router.push('/subscribe')}
                                                                        variant="contained"
                                                                        className={classes.download}
                                                                    >
                                                                        Bli kunde for å laste ned
                                                                    </Button>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Hidden>
                        <Hidden lgUp>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ width: '10%' }}>
                                                Status
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }}>Tittel</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Scope 1</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Scope 2</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Scope 3</TableCell>
                                            <TableCell sx={{ width: '10%' }} align="center">
                                                Slett
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }} align="right"></TableCell>
                                            <TableCell sx={{ width: '20%' }} align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reports &&
                                            props.reports
                                                .sort((a, b) => b.status.localeCompare(a.status))
                                                .map(report => {
                                                    return (
                                                        <TableRow key={report.id}>
                                                            <TableCell align="center">
                                                                <Chip
                                                                    label={
                                                                        report.status === 'Draft'
                                                                            ? 'Utkast'
                                                                            : 'Fullført'
                                                                    }
                                                                    sx={{
                                                                        backgroundColor: ChipColor(report),
                                                                    }}
                                                                ></Chip>
                                                            </TableCell>
                                                            <TableCell>
                                                                {report.title !== '' ? report.title : 'Ingen tittel'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {report.responses.items.length !== 0
                                                                    ? getScopeTotalEmission(report.responses.items, 1) +
                                                                      ' kg CO2e'
                                                                    : '0 kgCO2e'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {report.responses.items.length !== 0
                                                                    ? getScopeTotalEmission(report.responses.items, 2) +
                                                                      ' kg CO2e'
                                                                    : '0 kgCO2e'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {report.responses.items.length !== 0
                                                                    ? getScopeTotalEmission(report.responses.items, 3) +
                                                                      ' kg CO2e'
                                                                    : '0 kgCO2e'}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <DeleteDialog
                                                                    id={report.id}
                                                                    title={report.title}
                                                                    updateReports={props.updateReports}
                                                                />
                                                            </TableCell>
                                                            {report.status === 'Completed' ? (
                                                                <TableCell align="center">
                                                                    <Button
                                                                        onClick={() => handleReopenReport(report)}
                                                                        variant="contained"
                                                                        sx={{
                                                                            backgroundColor: '#FDDFB0',
                                                                        }}
                                                                    >
                                                                        Gjenåpne {ArrowRight}
                                                                    </Button>
                                                                </TableCell>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <TableCell>
                                                                {report.status === 'Draft' ? (
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={{
                                                                            backgroundColor: '#FDDFB0',
                                                                        }}
                                                                        onClick={() =>
                                                                            router.push({
                                                                                pathname: '/climate/' + report.id,
                                                                                state: {
                                                                                    report: report,
                                                                                },
                                                                            })
                                                                        }
                                                                    >
                                                                        Fortsett {ArrowRight}
                                                                    </Button>
                                                                ) : JSON.parse(auth.companyData.companyData.invoice)
                                                                      .status !== 'Trial' ? (
                                                                    <PDFDownloadLink
                                                                        style={{
                                                                            color: '#000',
                                                                            textDecoration: 'none',
                                                                        }}
                                                                        document={
                                                                            <ClimateAccounting
                                                                                report={report}
                                                                                companyName={
                                                                                    auth.companyData.companyData.name
                                                                                }
                                                                            />
                                                                        }
                                                                        fileName="Klimaregnskaprapport.pdf"
                                                                    >
                                                                        {({ blob, url, loading, error }) =>
                                                                            loading ? (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    disabled={true}
                                                                                    className={classes.download}
                                                                                >
                                                                                    Laster inn dokument...
                                                                                </Button>
                                                                            ) : (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    onClick={() =>
                                                                                        downloadDocument(blob)
                                                                                    }
                                                                                    className={classes.download}
                                                                                >
                                                                                    Last ned PDF {Download}
                                                                                </Button>
                                                                            )
                                                                        }
                                                                    </PDFDownloadLink>
                                                                ) : (
                                                                    <Button
                                                                        onClick={() => router.push('/subscribe')}
                                                                        variant="contained"
                                                                        className={classes.download}
                                                                    >
                                                                        Bli kunde for å laste ned
                                                                    </Button>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Hidden>
                    </>
                ) : (
                    <Typography variant="h2" sx={{ textAlign: 'center' }}>
                        Ingen utkast av klimaregnskap.
                    </Typography>
                )}
            </Grid>
        </Paper>
    );
}
