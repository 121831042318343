import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';

import useToggle from '../../../../../hooks/useToggle';
import useIsMounted from '../../../../../hooks/useIsMounted';
import SnackPopup from '../../../../../components/SnackPopup';

import NewTemplate from './NewTemplate';
import TemplatesList from './TemplatesList';

import { API, graphqlOperation } from 'aws-amplify';
import { listTemplateFiles } from '../../../../../graphql/queries';

export default function TemplatesView() {
    const isMounted = useIsMounted();

    const [popupState, setPopupState] = useToggle();
    const [popupMsg, setPopupMsg] = useState('');

    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        fetchAllTemplates().then(data => {
            if (isMounted.current) {
                setTemplates(data);
            }
        });
    }, [isMounted]);

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState();
    };

    const handleClosePopup = () => {
        setPopupState();
    };

    const fetchAllTemplates = () => {
        return new Promise(async resolve => {
            await API.graphql(graphqlOperation(listTemplateFiles)).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Fetched all template files: ', data);
                }

                resolve(data.data.listTemplateFiles.items);
            });
        });
    };

    const updateLocalTemplates = async () => {
        await fetchAllTemplates().then(data => {
            setTemplates(data);
        });
    };

    return (
        <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
            <NewTemplate showPopup={showPopup} updateAllTemplates={updateLocalTemplates} />
            <TemplatesList showPopup={showPopup} templates={templates} updateAllTemplates={updateLocalTemplates} />
        </Grid>
    );
}
