import React, { useState, useEffect } from 'react';
import useToggle from '../../../../../hooks/useToggle';
import useIsMounted from '../../../../../hooks/useIsMounted';
import { Grid, Paper, Button, ButtonGroup } from '@mui/material';
import { ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { API, graphqlOperation } from 'aws-amplify';
import { listCertResponses } from '../../../../../graphql/queries';

export default function CertificationCheckDialog(props) {
    const isMounted = useIsMounted();

    const [respData, setRespData] = useState(null);
    const [currCategory, setCurrCategory] = useState(null);

    useEffect(() => {
        getAllResponses().then(data => {
            if (isMounted.current) {
                setRespData(data);
            }
        });

        setCurrCategory(
            props.categories.find(category => {
                return category.id === 'company';
            }),
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Fetches all responses
    const fetchResponses = async token => {
        return new Promise(async (resolve, reject) => {
            await API.graphql(
                graphqlOperation(listCertResponses, {
                    filter: { companyId: { eq: props.company.id } },
                    nextToken: token,
                }),
            )
                .then(async data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    // Fetches all responses from company
    const getAllResponses = async () => {
        return new Promise(async (resolve, reject) => {
            let dataArr = [];

            let nextToken = null;

            do {
                try {
                    const resp = await fetchResponses(nextToken);
                    const data = resp.data.listCertResponses.items;
                    nextToken = resp.data.listCertResponses.nextToken;

                    data.forEach(item => {
                        dataArr.push(item);
                    });
                } catch (err) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error while fetching all responses from backend: ', err);
                    }

                    reject(err);
                }
            } while (nextToken !== null);

            if (process.env.NODE_ENV === 'development') {
                console.log('Fetched all responses: ', dataArr);
            }

            resolve(dataArr);
        });
    };

    const QuestionView = props => {
        const [questionOpen, setQuestionOpen] = useToggle();

        let response = respData.find(response => {
            return response.questionId === props.question.id;
        });

        const displayAnswer = (resp, question) => {
            if (!resp || !resp.isAnswered) {
                return (
                    <Grid sx={{ padding: '20px', marginTop: '10px' }}>
                        Ingen svar funnet. Er ikke relevant eller selskapet har ikke svart.
                    </Grid>
                );
            } else if (question.type === 'DropdownSelect') {
                const option = question.options.items.find(option => option.id === resp.answers[0]);

                return <Grid sx={{ padding: '20px', marginTop: '10px' }}>{option.option}</Grid>;
            } else if (question.type === 'MultipleChoice') {
                return (
                    resp.answers &&
                    resp.answers.map(answer => {
                        const option = question.options.items.find(option => option.id === answer);

                        if (option.isOtherOption) {
                            return (
                                <Grid sx={{ padding: '10px', marginTop: '5px' }} key={answer}>
                                    {option.option}: {resp.otherField}
                                </Grid>
                            );
                        } else {
                            return (
                                <Grid sx={{ padding: '10px', marginTop: '5px' }} key={answer}>
                                    {option.option}
                                </Grid>
                            );
                        }
                    })
                );
            } else if (question.type === 'RadioList') {
                const option = question.options.items.find(option => option.id === resp.answers[0]);

                if (option.isOtherOption) {
                    return (
                        <Grid sx={{ padding: '20px', marginTop: '10px' }}>
                            {option.option}: {resp.otherField}
                        </Grid>
                    );
                } else {
                    return <Grid sx={{ padding: '20px', marginTop: '10px' }}>{option.option}</Grid>;
                }
            } else if (question.type === 'Number') {
                return <Grid sx={{ padding: '20px', marginTop: '10px' }}>{resp.number}</Grid>;
            } else if (question.type === 'MultipleNumber') {
                const data = JSON.parse(resp.multiNumber);

                return (
                    data &&
                    data.map(answer => {
                        const option = question.options.items.find(option => option.id === answer.id);

                        return (
                            <Grid sx={{ padding: '10px', marginTop: '5px' }} key={answer.id}>
                                {option.option}: {answer.input}
                            </Grid>
                        );
                    })
                );
            } else if (question.type === 'Text') {
                return <Grid sx={{ padding: '20px', marginTop: '10px' }}>{resp.text}</Grid>;
            } else if (question.type === 'Textfield') {
                return <Grid sx={{ padding: '20px', marginTop: '10px' }}>{resp.longText}</Grid>;
            } else if (question.type === 'MultipleText') {
                const data = JSON.parse(resp.multiText);

                return (
                    data &&
                    data.map(answer => {
                        const option = question.options.items.find(option => option.id === answer.id);

                        return (
                            <Grid sx={{ padding: '10px', marginTop: '5px' }} key={answer.id}>
                                {option.option}: {answer.input}
                            </Grid>
                        );
                    })
                );
            } else if (question.type === 'Slider') {
                return <Grid sx={{ padding: '20px', marginTop: '10px' }}>{resp.number}</Grid>;
            } else if (question.type === 'MultiDropdownSelectWithInput') {
                const data = JSON.parse(resp.multiDropSelect);

                return (
                    data &&
                    data.map(answer => {
                        const option = question.options.items.find(option => option.id === answer.id);

                        return (
                            <Grid sx={{ padding: '10px', marginTop: '5px' }} key={answer.id}>
                                {option.option}: {answer.input}
                            </Grid>
                        );
                    })
                );
            } else if (question.type === 'MultiRowInput') {
                console.log(resp.multiRows);

                const data = JSON.parse(resp.multiRows);

                return (
                    data &&
                    data.map(answer => {
                        return (
                            <Grid sx={{ padding: '10px', marginTop: '5px' }} key={answer.id}>
                                {answer.map(item => {
                                    return (
                                        <React.Fragment key={item.name}>
                                            {item.name}: {item.value},{' '}
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                        );
                    })
                );
            } else if (question.type === 'CustomMultiWithSelect') {
                if (resp.answers && resp.answers.length > 0) {
                    const option = question.options.items.find(option => option.id === resp.answers[0]);

                    return <Grid sx={{ padding: '20px', marginTop: '10px' }}>{option.option}</Grid>;
                } else {
                    const data = JSON.parse(resp.multiNumber);

                    return (
                        data &&
                        data.map(answer => {
                            const option = question.options.items.find(option => option.id === answer.id);

                            return (
                                <Grid sx={{ padding: '10px', marginTop: '5px' }} key={answer.id}>
                                    {option.option}: {answer.input}
                                </Grid>
                            );
                        })
                    );
                }
            }
        };

        return (
            <Paper sx={{ padding: '20px', marginBottom: '10px', marginTop: '10px' }}>
                <ListItem disablePadding>
                    <ListItemButton onClick={setQuestionOpen}>
                        <ListItemIcon>
                            {response && response.isAnswered ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon />}
                        </ListItemIcon>
                        <ListItemText primary={props.question.question} />
                    </ListItemButton>
                </ListItem>
                {questionOpen ? displayAnswer(response, props.question) : <></>}
            </Paper>
        );
    };

    const SubcategoryView = props => {
        const [subOpen, setSubOpen] = useToggle();

        return (
            <Paper sx={{ padding: '20px', marginBottom: '10px', marginTop: '10px' }}>
                <ListItem disablePadding>
                    <ListItemButton onClick={setSubOpen}>
                        <ListItemIcon>
                            <DehazeIcon />
                        </ListItemIcon>
                        <ListItemText primary={props.subcategory.name} />
                    </ListItemButton>
                </ListItem>
                {subOpen ? (
                    props.subcategory.questions.items
                        .sort((a, b) => {
                            return a.order - b.order;
                        })
                        .map(question => {
                            return <QuestionView question={question} key={question.id} />;
                        })
                ) : (
                    <></>
                )}
            </Paper>
        );
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth="xl">
            <DialogTitle sx={{ fontSize: '2em' }}>Sertifiseringen til {props.company.name}</DialogTitle>
            <DialogContent sx={{ height: '1000px' }}>
                <Grid item xs={12}>
                    <Grid>
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    setCurrCategory(
                                        props.categories.find(category => {
                                            return category.id === 'company';
                                        }),
                                    )
                                }
                            >
                                Bedritsprofil
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    setCurrCategory(
                                        props.categories.find(category => {
                                            return category.id === 'economy';
                                        }),
                                    )
                                }
                            >
                                Økonomi og drift
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    setCurrCategory(
                                        props.categories.find(category => {
                                            return category.id === 'social';
                                        }),
                                    )
                                }
                            >
                                Sosiale forhold
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    setCurrCategory(
                                        props.categories.find(category => {
                                            return category.id === 'environment';
                                        }),
                                    )
                                }
                            >
                                Miljø og klima
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid>
                        {currCategory &&
                            currCategory.subcategories.items &&
                            currCategory.subcategories.items
                                .sort((a, b) => {
                                    return a.order - b.order;
                                })
                                .map(subcategory => {
                                    return <SubcategoryView subcategory={subcategory} key={subcategory.id} />;
                                })}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="primary" onClick={props.handleClose}>
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    );
}
