import React from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { useStyles } from '../ClimateStyles';
import { Climate, ArrowRight } from '../../../../components/Icons';
import useRouter from '../../../../hooks/useRouter';
import * as Text from '../../../../helpers/climateAccounting.json';

export default function Scope1Module(props) {
    const classes = useStyles();
    const router = useRouter();

    const getScopeTotalEmission = () => {
        if (props.report.responses.items.length === 0) {
            return 0;
        }

        let total = 0;

        for (const item of props.report.responses.items) {
            if (item.scope === 1) {
                let data = JSON.parse(item.data);

                if (data) {
                    for (const dataItem of data) {
                        if (dataItem && dataItem.emission) {
                            total = total + dataItem.emission;
                        }
                    }
                }
            }
        }

        return total;
    };

    const renderEmission = () => {
        let totalEmission = getScopeTotalEmission();

        // If higher than 100, start displaying as ton instead of kg
        if (totalEmission >= 100) {
            let newTotalEmission = totalEmission / 1000;

            return newTotalEmission.toFixed(2) + ' tonn CO2e';
        } else {
            return totalEmission.toFixed(2) + ' kg CO2e';
        }
    };

    return (
        <Paper className={classes.modulePaper}>
            <Typography variant="h3" sx={{ backgroundColor: '#DEF7F3' }}>
                {Climate} Scope 1 - Egne direkte utslipp
            </Typography>
            <Grid item style={{ padding: '20px 20px 0px 20px' }}>
                <Typography variant="body1">{Text.Scope1.Short}</Typography>
                <br />
            </Grid>
            <Grid item>
                <Grid
                    item
                    xs={12}
                    sx={{
                        backgroundColor: '#DEF7F3',
                    }}
                    className={classes.modulesFooter}
                >
                    <Typography variant="body2">Utslipp: {renderEmission()}</Typography>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#fff',
                            '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#AFECE1',
                            },
                        }}
                        onClick={() =>
                            router.push({
                                pathname: '/climate/' + props.report.id + '/scope1',
                                state: {
                                    report: props.report,
                                },
                            })
                        }
                    >
                        Fortsett {ArrowRight}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
