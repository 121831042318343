import React, { useState, useEffect } from 'react';
import useToggle from '../../../../hooks/useToggle';
import { useAuth } from '../../../../auth/useAuth';
import useRouter from '../../../../hooks/useRouter';
import {
    Grid,
    Typography,
    TableRow,
    TableCell,
    Button,
    Box,
    Collapse,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Slide,
    Chip,
} from '@mui/material';
import { UnFinished, Finished, ArrowRight } from '../../../../components/Icons';
import {
    DropdownSelect,
    MultipleChoice,
    Number,
    MultipleNumber,
    RadioList,
    Slider,
    Text,
    Textfield,
    MultipleText,
    MultiDropdownSelectWithInput,
    MultiRowInput,
    CustomMultiWithSelect,
    CustomCertifyDropDowns,
} from '../components/types/index';
import { Loading } from '../../../../components/Loading';
import {
    createNewResponse,
    saveResponse,
    updateEmployeeAmount,
    updateCertFilterTypes,
} from './../certificationFunctions';
import { useStyles } from '../CertificationStyles';
import Chores from './Chores';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormQuestion(props) {
    const {
        data,
        response,
        catId,
        colorCode,
        openState,
        openQuestion,
        openNextQuestion,
        addNewResponse,
        users,
        updateTasks,
        tasks,
    } = props;
    const auth = useAuth();
    const router = useRouter();
    const classes = useStyles();

    // Boolean states
    const [isLoadingData, setIsLoadingData] = useToggle(true);
    const [isSaving, setIsSaving] = useToggle();
    const [openDialog, setOpenDialog] = useState(false);

    // Data fields
    const [isAnswered, setIsAnswered] = useState(false);
    const [pointsEarned, setPointsEarned] = useState(0.0);
    const [answers, setAnswers] = useState([]);
    const [text, setText] = useState('');
    const [longText, setLongText] = useState('');
    const [multiText, setMultiText] = useState([]);
    const [multiDropSelect, setMultiDropSelect] = useState([]);
    const [number, setNumber] = useState('');
    const [multiNumber, setMultiNumber] = useState([]);
    const [multiRows, setMultiRows] = useState([]);
    const [otherField, setOtherField] = useState('');

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const loadResponseData = async () => {
        setIsAnswered(response.isAnswered);
        setPointsEarned(response.pointsEarned);
        setAnswers(response.answers);
        setText(response.text);
        setLongText(response.longText);
        setMultiText(JSON.parse(response.multiText));
        setMultiDropSelect(JSON.parse(response.multiDropSelect));
        setMultiNumber(JSON.parse(response.multiNumber));
        setMultiRows(JSON.parse(response.multiRows));
        setOtherField(response.otherField);

        if (number !== null) {
            setNumber(response.number);
        } else {
            setNumber('');
        }
    };

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (response) {
                loadResponseData().then(() => {
                    setIsLoadingData();
                });
            } else {
                setIsLoadingData();
            }

            const findPosition = obj => {
                var currenttop = 0;
                if (obj.offsetParent) {
                    do {
                        currenttop += obj.offsetTop;
                    } while ((obj = obj.offsetParent));
                    return [currenttop];
                }
            };

            if (router.query.criteria) {
                if (document.getElementById(router.query.criteria)) {
                    window.scrollTo(0, findPosition(document.getElementById(router.query.criteria)));
                }
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isLoadingData) {
            const timeOutId = setTimeout(() => handleSaveResponse(), 800);

            return () => clearTimeout(timeOutId);
        }
    }, [isAnswered, answers, text, longText, number, otherField, multiText, multiDropSelect, multiNumber, multiRows]); // eslint-disable-line react-hooks/exhaustive-deps,

    const runSpecificQuestionTask = async () => {
        if (data.id === 'f88e71b2-bc8b-4c8b-9366-43054ba9ba53') {
            // Employees
            await updateEmployeeAmount(auth, parseInt(number));
        } else if (data.id === '632e55d7-da57-43b9-8408-14455cde3a37') {
            // Property
            if (answers.includes('367fe4ad-a76c-4681-9c1e-7eb17fc2cfee') || !isAnswered) {
                await updateCertFilterTypes(auth, 'Property', false);
            } else {
                await updateCertFilterTypes(auth, 'Property', true);
            }
        } else if (data.id === 'f69bd374-e67d-42a3-8ce8-0e584de9416f') {
            // Production
            if (answers.includes('7495b4ce-357f-4bee-9298-895b6fcfa70c') || !isAnswered) {
                await updateCertFilterTypes(auth, 'Production', false);
            } else {
                await updateCertFilterTypes(auth, 'Production', true);
            }
        } else if (data.id === 'f0a23065-3f60-4ed8-9e5c-dd9d2599a508') {
            // Trade
            if (answers.includes('61951148-0603-46c6-b869-e00f39b30ca8') || !isAnswered) {
                await updateCertFilterTypes(auth, 'Trade', false);
            } else {
                await updateCertFilterTypes(auth, 'Trade', true);
            }
        }
    };

    // Handles saving of response. If a saved response doesn't exist, create it.
    const handleSaveResponse = async () => {
        let dataObj = {
            companyId: auth.companyData.companyId,
            companyCertDataId: auth.companyData.companyData.certData.items[0].id,
            categoryId: catId,
            questionId: data.id,
            pointsEarned: pointsEarned,
            isAnswered: isAnswered,
            answers: answers,
            text: text,
            longText: longText,
            multiText: JSON.stringify(multiText),
            multiDropSelect: JSON.stringify(multiDropSelect),
            number: parseInt(number),
            multiNumber: JSON.stringify(multiNumber),
            multiRows: JSON.stringify(multiRows),
            otherField: otherField,
        };

        if (!isSaving) {
            setIsSaving(true);

            if (response && response !== undefined && response !== null) {
                dataObj.id = response.id;

                await runSpecificQuestionTask();

                await saveResponse(auth, dataObj, response).then(async respData => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Saved response: ', respData);
                    }

                    setIsSaving();
                });
            } else {
                await runSpecificQuestionTask();

                await createNewResponse(auth, dataObj, response).then(async respData => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new response: ', respData);
                    }

                    await addNewResponse(respData.data.createCertResponse);

                    setIsSaving();
                });
            }
        }
    };

    const handleSaving = async () => {
        await handleSaveResponse();

        openNextQuestion(data.id);
    };

    // Checks for dictating the color of the question bars
    const CollapseColor = () => {
        if (isAnswered) {
            return colorCode;
        } else if (openState.state === true) {
            return '#EBEBEB';
        } else {
            return '#FFFFFF';
        }
    };

    const CheckedColor = () => {
        if (isAnswered) {
            return colorCode;
        } else {
            return '#EBEBEB';
        }
    };

    const IconChange = () => {
        if (isAnswered) {
            return Finished;
        } else {
            return UnFinished;
        }
    };

    const chipColor = () => {
        if (isAnswered) {
            return '#FFFFFF';
        } else {
            return '#EBEBEB';
        }
    };

    // Check that shows the user if a question is being saved
    const PointSaveChange = () => {
        if (isSaving) {
            return (
                <Typography className={classes.points}>
                    Lagrer... <Loading size={15} color={'info'} />
                </Typography>
            );
        } else if (catId !== 'company' && data.pointValue !== 0) {
            return (
                <Typography className={classes.points}>
                    Poengsum tjent: {pointsEarned > data.pointValue ? data.pointValue : pointsEarned} av{' '}
                    {data.pointValue}
                </Typography>
            );
        } else {
            return <Typography></Typography>;
        }
    };

    // Returns text based on if question has max/min length
    const DisplayTextReq = () => {
        if (data.maxChars && data.maxChars !== 0 && data.minChars && data.minChars !== 0) {
            return '(Minimum tegn: ' + data.minChars + ', Maks tegn: ' + data.maxChars + ')';
        } else if (data.maxChars && data.maxChars !== 0) {
            return '(Maks tegn: ' + data.maxChars + ')';
        } else if (data.minChars && data.minChars !== 0) {
            return '(Minimum tegn: ' + data.minChars + ')';
        } else {
            return '';
        }
    };

    // Returns the correct type of question depending on type given by data
    const TypeCheck = () => {
        if (data.type === 'DropdownSelect') {
            return (
                <DropdownSelect
                    data={data.options.items}
                    answers={answers}
                    setAnswers={setAnswers}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                />
            );
        } else if (data.type === 'MultipleChoice') {
            return (
                <MultipleChoice
                    data={data.options.items}
                    answers={answers}
                    setAnswers={setAnswers}
                    otherField={otherField}
                    setOtherField={setOtherField}
                    pointsEarned={pointsEarned}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'RadioList') {
            return (
                <RadioList
                    data={data.options.items}
                    answers={answers}
                    setAnswers={setAnswers}
                    otherField={otherField}
                    setOtherField={setOtherField}
                    pointsEarned={pointsEarned}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'Number') {
            return (
                <Number
                    min={0}
                    data={data}
                    number={number}
                    setNumber={setNumber}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'MultipleNumber') {
            return (
                <MultipleNumber
                    min={0}
                    data={data.options.items}
                    multiNumber={multiNumber}
                    setMultiNumber={setMultiNumber}
                    pointsEarned={pointsEarned}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'Text') {
            return (
                <Text
                    data={data}
                    text={text}
                    setText={setText}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'Textfield') {
            return (
                <Textfield
                    data={data}
                    longText={longText}
                    setLongText={setLongText}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'MultipleText') {
            return (
                <MultipleText
                    data={data.options.items}
                    multiText={multiText}
                    setMultiText={setMultiText}
                    pointsEarned={pointsEarned}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'Slider') {
            return <Slider data={data} number={number} setNumber={setNumber} setPointsEarned={setPointsEarned} />;
        } else if (data.type === 'MultiDropdownSelectWithInput') {
            return (
                <MultiDropdownSelectWithInput
                    questionId={data.id}
                    data={data.options.items}
                    multiDropSelect={multiDropSelect}
                    setMultiDropSelect={setMultiDropSelect}
                    pointsEarned={pointsEarned}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'MultiRowInput') {
            return (
                <MultiRowInput
                    questionId={data.id}
                    data={data.options.items}
                    points={data.pointValue}
                    multiRows={multiRows}
                    setMultiRows={setMultiRows}
                    pointsEarned={pointsEarned}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'CustomMultiWithSelect') {
            return (
                <CustomMultiWithSelect
                    min={0}
                    data={data.options.items}
                    multiNumber={multiNumber}
                    setMultiNumber={setMultiNumber}
                    answers={answers}
                    setAnswers={setAnswers}
                    pointsEarned={pointsEarned}
                    setPointsEarned={setPointsEarned}
                    setIsAnswered={setIsAnswered}
                    minChars={data.minChars !== null && data.minChars !== 0 ? data.minChars : 0}
                    maxChars={data.maxChars !== null && data.maxChars !== 0 ? data.maxChars : 2000}
                />
            );
        } else if (data.type === 'CustomCertifyDropDowns') {
            return (
                <CustomCertifyDropDowns
                    data={data.options.items}
                    multiDropSelect={multiDropSelect}
                    setMultiDropSelect={setMultiDropSelect}
                    setIsAnswered={setIsAnswered}
                />
            );
        }
    };

    return (
        <React.Fragment>
            <TableRow
                id={data.id}
                className={classes.question}
                sx={{
                    background: CollapseColor(),
                }}
                onClick={() => openQuestion(data.id)}
            >
                <TableCell
                    className={classes.iconCell}
                    style={{
                        backgroundColor: CheckedColor(),
                    }}
                    align="center"
                >
                    {IconChange()}
                </TableCell>
                <TableCell component="th" scope="row" className={classes.questionRow}>
                    <Typography variant="h3" className={classes.questionTitle}>
                        {data.question}{' '}
                        {data.required ? (
                            <>*</>
                        ) : (
                            <>
                                <i style={{ fontSize: '18px' }}>(Valgfritt)</i>
                            </>
                        )}
                    </Typography>
                </TableCell>
                <TableCell className={classes.ChipCell} align="center">
                    {data.pointValue !== null && data.pointValue !== 0 ? (
                        <Chip
                            sx={{ backgroundColor: chipColor() }}
                            label={response ? response.pointsEarned + '/' + data.pointValue : 0 + '/' + data.pointValue}
                        ></Chip>
                    ) : (
                        <></>
                    )}
                </TableCell>
            </TableRow>
            <TableRow className={classes.row}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openState.state} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: '20px' }}>
                            <Typography variant="body1">{data.explanation}</Typography>
                            {data.description ? (
                                <>
                                    <Button variant="text" className={classes.readmore} onClick={handleOpenDialog}>
                                        Hvorfor spør vi om dette?
                                    </Button>
                                    <Dialog
                                        open={openDialog}
                                        onClose={handleDialogClose}
                                        aria-labelledby="responsive-dialog-title"
                                        maxWidth="sm"
                                        TransitionComponent={Transition}
                                        fullWidth={true}
                                    >
                                        <DialogTitle sx={{ textAlign: 'left', fontSize: '30px' }}>
                                            Hvorfor spør vi om dette?
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText sx={{ color: '#000000' }}>
                                                {data.description}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                variant="contained"
                                                sx={{ width: '120px', height: '40px' }}
                                                autoFocus
                                                onClick={handleDialogClose}
                                            >
                                                Lukk
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>
                            ) : (
                                <></>
                            )}
                            <Divider className={classes.descDivider} />
                            <Typography variant="body2" className={classes.textReq}>
                                {DisplayTextReq()}
                            </Typography>
                            {TypeCheck()}
                            <Grid container direction="row" className={classes.content}>
                                {PointSaveChange()}
                                <Button disabled={false} variant="contained" onClick={handleSaving}>
                                    {/*<Link smooth to={link}>Neste</Link>*/}
                                    Lagre{ArrowRight}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: '30px' }}>
                                <Divider sx={{ border: '1px solid #E1E1E1' }} />
                                <Typography variant="h2" sx={{ marginTop: '10px' }}>
                                    Tilknyttede gjøremål
                                </Typography>
                                <Chores
                                    question={data}
                                    users={users}
                                    catId={catId}
                                    updateTasks={updateTasks}
                                    tasks={tasks}
                                />
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow style={{ height: '20px' }} />
        </React.Fragment>
    );
}
