import React, { useEffect } from 'react';
import { Grid, FormControl, FormControlLabel, RadioGroup, Radio, InputBase } from '@mui/material';

export default function RadioList(props) {
    const {
        data,
        answers,
        setAnswers,
        otherField,
        setOtherField,
        setPointsEarned,
        setIsAnswered,
        minChars,
        maxChars,
    } = props;

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (answers.length === 1) {
                let otherOptionCheck = false;

                for (const answer of answers) {
                    let newOption = data.find(item => {
                        return item.id === answer;
                    });

                    if (newOption && newOption.isOtherOption) {
                        otherOptionCheck = true;
                    }
                }

                if (otherOptionCheck) {
                    if (otherField.length >= minChars && otherField.length !== 0) {
                        setIsAnswered(true);
                    } else {
                        setIsAnswered(false);
                    }
                }
            } else {
                setIsAnswered(false);
            }
        }

        return () => {
            isMounted = true;
        };
    }, [otherField, answers]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectOption = option => {
        let newArr = answers;

        if (newArr.length > 0) {
            newArr.splice(0, 1);
            setPointsEarned(0);
        }

        newArr.push(option.id);
        setPointsEarned(option.pointValue);

        if (option.isOtherOption) {
            setIsAnswered(false);
        } else {
            setOtherField('');
            setIsAnswered(true);
        }

        setAnswers([...newArr]);
    };

    if (data.length > 0) {
        return (
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
                <RadioGroup name={data.option}>
                    {data &&
                        data
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map(option => {
                                return (
                                    <Grid key={option.id}>
                                        <FormControlLabel
                                            checked={answers.includes(option.id)}
                                            control={<Radio />}
                                            style={{ margin: '8px 0px' }}
                                            label={option.option}
                                            onChange={() => handleSelectOption(option)}
                                        />
                                        {option.isOtherOption &&
                                        option.isOtherOption === true &&
                                        answers.includes(option.id) ? (
                                            <InputBase
                                                sx={{ padding: '5px' }}
                                                id={option.id}
                                                type="text"
                                                className="input"
                                                name={option.option}
                                                value={otherField}
                                                onChange={e => setOtherField(e.target.value)}
                                                placeholder="Skriv her..."
                                                inputProps={{ maxLength: maxChars }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                );
                            })}
                </RadioGroup>
            </FormControl>
        );
    } else {
        return <Grid>Spørsmålet har ingen valgalternativer.</Grid>;
    }
}
