import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper } from '@mui/material';
import { ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import InputForm, { TextAreaInput, NumberInput } from '../../../../../components/InputForm';
import useToggle from '../../../../../hooks/useToggle';

import { API, graphqlOperation } from 'aws-amplify';
import { updateNSRSCategory, deleteNSRSCategory } from '../../../../../graphql/mutations';

export default function Category(props) {
    const { data, showPopup, updateCategories } = props;

    const [rowOpen, setRowOpen] = useToggle();
    const [dialogOpen, setDialogOpen] = useToggle();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [order, setOrder] = useState(1);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            setName(data.name);
            setDescription(data.description);
            setOrder(data.order);
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateCategory = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateNSRSCategory, {
                    input: {
                        id: data.id,
                        name: name,
                        description: description,
                        order: order,
                    },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated category: ', data);
                }

                resolve();
            });
        });
    };

    const deleteCategory = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(deleteNSRSCategory, {
                    input: { id: data.id },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Deleted category: ', data);
                }

                resolve();
            });
        });
    };

    const handleUpdateCategory = async () => {
        if (name === '' || description === '') {
            showPopup('Alle felter må være fylt ut!');
            return;
        }

        await updateCategory().catch(() => {
            showPopup('En feil oppsto ved lagring av kategori');
            return;
        });

        showPopup('Kategorien har blitt lagret');

        await updateCategories();

        setDialogOpen();
    };

    const handleCloseDialog = () => {
        setDialogOpen();
        setName(data.name);
        setDescription(data.description);
        setOrder(data.order);
    };

    const handleDeleteCategory = async () => {
        if (data.subcategories.items && data.subcategories.items.length !== 0) {
            showPopup('Alle underkategorier må bli fjernet før du kan slette!');
            return;
        }

        await deleteCategory().catch(() => {
            showPopup('En feil oppsto ved sletting av kategori');
            return;
        });

        showPopup('Kategorien har blitt slettet');

        await updateCategories();
    };

    const updateCategoryDialog = () => {
        return (
            <Grid>
                <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Oppdater kategorien</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Oppdater feltene til kategorien.
                        </DialogContentText>
                        <InputForm
                            label="Navn"
                            placeholder="Skriv inn navn..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Beskrivelse"
                            placeholder="Skriv inn beskrivelse..."
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                        <NumberInput
                            label="Listenummer (blir brukt for rekkefølge)"
                            placeholder="Skriv inn nummer..."
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleUpdateCategory}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid>
            {updateCategoryDialog()}
            <Paper sx={{ padding: '20px', marginBottom: '10px' }}>
                <ListItem disablePadding key={data.id}>
                    <ListItemButton onClick={setRowOpen}>
                        <ListItemIcon>
                            <DehazeIcon />
                        </ListItemIcon>
                        <ListItemText primary={data.order + ' - ' + data.name} />
                    </ListItemButton>
                </ListItem>
                {!rowOpen ? (
                    <></>
                ) : (
                    <Grid>
                        <Divider />
                        <Grid sx={{ margin: '15px' }}>
                            <Grid sx={{ marginBottom: '15px' }}>
                                <b>ID:</b>
                                <br /> {data.id}
                            </Grid>
                            <Grid sx={{ marginBottom: '15px' }}>
                                <b>Beskrivelse:</b>
                                <br /> {data.description}
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '200px' }}
                            onClick={setDialogOpen}
                        >
                            Oppdater kategori{' '}
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '200px' }}
                            onClick={handleDeleteCategory}
                        >
                            Slett kategori{' '}
                        </Button>
                        <Divider />
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
}
