import React from "react";
import { Grid, Paper, Typography, Button, Divider } from "@mui/material";
import BronzeBadge from "../../../../assets/images/Bronze.svg";
import BronzePackage from "../../../../assets/downloads/Bronze_Badge.zip";
import SilverBadge from "../../../../assets/images/Silver.svg";
import SilverPackage from "../../../../assets/downloads/Silver_Badge.zip";
import GoldBadge from "../../../../assets/images/Gold.svg";
import GoldPackage from "../../../../assets/downloads/Gold_Badge.zip";
import StartBadge from "../../../../assets/images/start.svg";
import StartPackage from "../../../../assets/downloads/Start_Badge.zip";
import { Certificate } from "../../../../components/Icons";
import { useAuth } from "../../../../auth/useAuth";
import useRouter from "../../../../hooks/useRouter";
import { getBadgeFromTotalEarnedPoints } from "../../../../helpers/badgeFunctions";
import Replacement from "../../../../assets/images/nocustomer.svg";

export default function Badge() {
  const router = useRouter();
  const auth = useAuth();

  const data = auth.companyData.companyData.certData.items[0].publicProfile;

  const badgeText = () => {
    if (getBadgeFromTotalEarnedPoints(data.totalPercentScore) === "bronze") {
      return (
        "Gratulerer! Virksomheten har oppnådd bronse i Ecofrics bærekraftsscore. Dere har totalt oppnådd " +
        (data.totalPercentScore ? data.totalPercentScore : 0) +
        "% av 100%. Tildelingen er et bevis på at bedriften har startet med bærekraftsarbeidet, og at bedriften er villig til å sette fokus på bærekraftens tre områder; klima, sosiale forhold og selskapsstyring. Virksomheten har begynt prosessen med å integrere bærekraft i virksomheten, iverksatt tiltak på kompetanseheving og har startet noen tiltak innenfor flere deler av bærekraftens tre områder. Last gjerne ned din poengvalør eller logoen til Ecofric og bruk dette som bevis for deres bærekraftarbeid i egne nettsider, i sosiale medier og i markedsføringen ellers, for å synliggjøre og bygge stolthet over arbeidet som utføres, samt inspirere andre til å bidra til en mer bærekraftig utvikling."
      );
    } else if (
      getBadgeFromTotalEarnedPoints(data.totalPercentScore) === "silver"
    ) {
      return (
        "Gratulerer! Virksomheten har oppnådd sølv i Ecofrics bærekraftscore. Dere har totalt oppnådd " +
        (data.totalPercentScore ? data.totalPercentScore : 0) +
        "% av 100%. Tildelingen er et bevis på at bedriften har et stort fokus på alle bærekraftens tre områder; sosiale forhold, klima og selskapsstyring. Virksomheten har integrert bærekraft i virksomheten, har fokus på kompetanseheving og har iverksatt flere tiltak innenfor de fleste deler av bærekraftens tre områder."
      );
    } else if (
      getBadgeFromTotalEarnedPoints(data.totalPercentScore) === "gold"
    ) {
      return (
        "Gratulerer! Virksomheten har oppnådd gull i Ecofrics bærekraftscore. Dere har totalt oppnådd " +
        (data.totalPercentScore ? data.totalPercentScore : 0) +
        "% av 100%. Kun et fåtall bedrifter oppnår denne tildelingen, noe som synliggjør at bedriften har et sterkt fokus på samtlige av bærekraftens tre områder; klima, sosiale forhold og selskapsstyring. Bærekraft er integrert i virksomheten, det er fokus på kompetanseheving og det er iverksatt konkrete tiltak innenfor alle deler av de tre områder."
      );
    } else {
      return (
        "Dere er i gang med å oppnå en poengvalør og er på god vei. Dere har totalt oppnådd " +
        (data.totalPercentScore ? data.totalPercentScore : 0) +
        "% av 100%."
      );
    }
  };

  const badgeDownload = () => {
    if (JSON.parse(auth.companyData.companyData.invoice).status === "Trial") {
      return (
        <Grid item xs={4} sx={{ padding: "20px" }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Replacement}
              style={{
                width: "200px",
                marginTop: "20px",
              }}
              alt="No customer badge"
            ></img>
          </Grid>
          <Grid item xs={12} sx={{ padding: "20px" }}>
            <Button
              variant="contained"
              sx={{ width: "100%", backgroundColor: "#BBCBE4" }}
              onClick={() => router.push("/subscribe")}
            >
              Bli kunde
            </Button>
          </Grid>
        </Grid>
      );
    }

    if (getBadgeFromTotalEarnedPoints(data.totalPercentScore) === "bronze") {
      return (
        <Grid item xs={4} sx={{ padding: "20px" }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <a href={BronzePackage} download>
              <img
                src={BronzeBadge}
                style={{
                  width: "200px",
                  marginTop: "20px",
                }}
                alt="Logo black download"
              ></img>
            </a>
          </Grid>
          <Grid item xs={12} sx={{ padding: "20px" }}>
            <a
              href={BronzePackage}
              download
              style={{ color: "#000000", textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{ width: "100%", backgroundColor: "#BBCBE4" }}
              >
                Last ned
              </Button>
            </a>
          </Grid>
        </Grid>
      );
    } else if (
      getBadgeFromTotalEarnedPoints(data.totalPercentScore) === "silver"
    ) {
      return (
        <Grid item xs={4} sx={{ padding: "20px" }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <a href={SilverPackage} download>
              <img
                src={SilverBadge}
                style={{
                  width: "200px",
                  marginTop: "20px",
                }}
                alt="Logo black download"
              ></img>
            </a>
          </Grid>
          <Grid item xs={12} sx={{ padding: "20px" }}>
            <a
              href={SilverPackage}
              download
              style={{ color: "#000000", textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{ width: "100%", backgroundColor: "#BBCBE4" }}
              >
                Last ned
              </Button>
            </a>
          </Grid>
        </Grid>
      );
    } else if (
      getBadgeFromTotalEarnedPoints(data.totalPercentScore) === "gold"
    ) {
      return (
        <Grid item xs={4} sx={{ padding: "20px" }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <a href={GoldPackage} download>
              <img
                src={GoldBadge}
                style={{
                  width: "200px",
                  marginTop: "20px",
                }}
                alt="Logo black download"
              ></img>
            </a>
          </Grid>
          <Grid item xs={12} sx={{ padding: "20px" }}>
            <a
              href={GoldPackage}
              download
              style={{ color: "#000000", textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{ width: "100%", backgroundColor: "#BBCBE4" }}
              >
                Last ned
              </Button>
            </a>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={4} sx={{ padding: "20px" }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <a href={StartPackage} download>
              <img
                src={StartBadge}
                style={{
                  width: "200px",
                  marginTop: "20px",
                }}
                alt="Logo black download"
              ></img>
            </a>
          </Grid>
          <Grid item xs={12} sx={{ padding: "20px" }}>
            <a
              href={StartPackage}
              download
              style={{ color: "#000000", textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{ width: "100%", backgroundColor: "#BBCBE4" }}
              >
                Last ned
              </Button>
            </a>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Grid item xs={12}>
      <Paper>
        <Typography variant="h3">
          {Certificate}Ecofric bærekraftscore
        </Typography>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={8}>
            <Typography
              variant="h1"
              sx={{ padding: "20px 0px 0px 40px", textAlign: "left" }}
            >
              Din poengvalør
            </Typography>
            <Divider sx={{ margin: "10px 40px" }} />
            <Typography variant="body1" sx={{ padding: "0px 40px 20px 40px" }}>
              {badgeText()}
            </Typography>
          </Grid>
          {badgeDownload()}
        </Grid>
      </Paper>
    </Grid>
  );
}
