import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Hidden } from '@mui/material';
import Draft from './CommentSection/Draft';
import General from './General';
import ChoreTitleDescription from './ChoreTitleDescription';
import { Actions, Generals } from '../../../../components/Icons';
import { ContentLoading } from '../../../../components/Loading';
import { useAuth } from '../../../../auth/useAuth';
import useRouter from '../../../../hooks/useRouter';
import useIsMounted from '../../../../hooks/useIsMounted';

import {
    fetchCompanyUsers,
    fetchFilteredCategories,
    fetchTask,
    updateTask,
    deleteTask,
    deleteComment,
} from '../choresFunctions';
import { onTodoTaskByIdAndCompanyId } from '../../../../graphql/subscriptions';

import AWS from 'aws-sdk';
import { API } from 'aws-amplify';
import { s3Bucket, s3Region } from '../../../../helpers/globalValues';

export default function ChoreDetail() {
    const auth = useAuth();
    const router = useRouter();
    const isMounted = useIsMounted();

    let { id } = router.query;

    const [task, setTask] = useState(null);
    const [users, setUsers] = useState(null);
    const [criterias, setCriterias] = useState(null);

    // Create a new AWS.S3 object with bucket and region params that will be used in all file-related functions
    const myBucket = new AWS.S3({
        params: { Bucket: s3Bucket },
        region: s3Region,
    });

    // GraphQL subscription to task
    useEffect(() => {
        let subscription;

        // Subscribe to updates
        subscription = API.graphql({
            query: onTodoTaskByIdAndCompanyId,
            variables: {
                id: id,
                companyId: auth.companyData.companyId,
            },
        }).subscribe({
            next: ({ provider, value }) => {
                fetchTaskUpdate();
            },
            error: error => {
                if (process.env.NODE_ENV === 'development') {
                    console.warn(error);
                }
            },
        });

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTask(null);

        if (router.location.state) {
            if (router.location.state.data) {
                setTask(router.location.state.data);
            } else {
                fetchTask(id)
                    .then(data => {
                        if (isMounted.current) {
                            if (!data) {
                                router.push('/activities');
                            } else {
                                setTask(data);
                            }
                        }
                    })
                    .catch(() => {
                        if (isMounted.current) {
                            router.push('/activities');
                        }
                    });
            }

            if (router.location.state.users) {
                setUsers(router.location.state.users);
            } else {
                fetchCompanyUsers(auth.companyData.companyId).then(data => {
                    if (isMounted.current) {
                        setUsers([...data]);
                    }
                });
            }

            if (router.location.state.criterias) {
                setCriterias(router.location.state.criterias);
            } else {
                fetchFilteredCategories(auth).then(data => {
                    if (isMounted.current) {
                        setCriterias([...data]);
                    }
                });
            }
        } else {
            fetchTask(id)
                .then(data => {
                    if (isMounted.current) {
                        if (!data) {
                            router.push('/activities');
                        } else {
                            setTask(data);
                        }
                    }
                })
                .catch(() => {
                    if (isMounted.current) {
                        router.push('/activities');
                    }
                });

            fetchCompanyUsers(auth.companyData.companyId).then(data => {
                if (isMounted.current) {
                    setUsers([...data]);
                }
            });

            fetchFilteredCategories(auth).then(data => {
                if (isMounted.current) {
                    setCriterias([...data]);
                }
            });
        }
    }, [router.location]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateTaskData = async input => {
        await updateTask(input).then(data => {
            setTask(data);

            router.push({
                state: {
                    data: data,
                    users: users,
                    criterias: criterias,
                },
            });
        });
    };

    const fetchTaskUpdate = async () => {
        await fetchTask(id).then(data => {
            if (!data) {
                router.push('/activities');
                return;
            }

            setTask(data);

            router.push({
                state: {
                    data: data,
                    users: users,
                    criterias: criterias,
                },
            });
        });
    };

    const deleteFile = async fileKey => {
        // Fetch saved credentials for authentication
        AWS.config.credentials.get(err => {
            if (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                    console.error(err);
                }

                return;
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                }
            }

            const params = {
                Bucket: s3Bucket,
                Key: fileKey,
            };

            // Deletes the file from the backend
            myBucket.deleteObject(params, (err, data) => {
                if (err) {
                    console.log(err);
                } else {
                    console.log(data);
                }
            });
        });
    };

    const handleDeleteFileComment = async comment => {
        await deleteFile(JSON.parse(comment.data).filePath + JSON.parse(comment.data).fileName);

        await deleteComment(comment.id);
    };

    const handleDeleteComment = async comment => {
        await deleteComment(comment.id);
    };

    const handleDeleteTask = async () => {
        await fetchTask(id).then(async data => {
            setTask(data);

            router.push({
                state: {
                    data: data,
                    users: users,
                    criterias: criterias,
                },
            });

            if (data.comments) {
                for (const comment of data.comments.items) {
                    if (comment.type === 'FILE') {
                        await handleDeleteFileComment(comment);
                    } else if (comment.type === 'COMMENT') {
                        await handleDeleteComment(comment);
                    }
                }
            }

            await deleteTask(id).then(() => {
                router.push('/activities');
            });
        });
    };

    if (!task || !users || !criterias) {
        return <ContentLoading />;
    } else {
        return (
            <Grid container spacing={3}>
                <Hidden lgDown>
                    <Grid item xs={8}>
                        <Grid container spacing={3}>
                            <ChoreTitleDescription
                                task={task}
                                updateTask={updateTaskData}
                                fetchTask={fetchTaskUpdate}
                            />
                            <Grid item xs={12}>
                                <Paper>
                                    <Typography variant="h3">{Actions}Aktivitet</Typography>
                                    <Grid item xs={12} sx={{ padding: '20px' }}>
                                        <Draft task={task} updateTask={fetchTaskUpdate} users={users} />
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper>
                                    <Typography variant="h3">{Generals}Generelt</Typography>
                                    <Grid item xs={12} sx={{ padding: '20px' }}>
                                        <General
                                            users={users}
                                            criterias={criterias}
                                            task={task}
                                            updateTask={updateTaskData}
                                            deleteTask={handleDeleteTask}
                                        />
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden lgUp>
                    <Grid item xs={12}>
                        <ChoreTitleDescription task={task} updateTask={updateTaskData} fetchTask={fetchTaskUpdate} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <Typography variant="h3">{Generals}Generelt</Typography>
                            <Grid item xs={12} sx={{ padding: '20px' }}>
                                <General
                                    users={users}
                                    criterias={criterias}
                                    task={task}
                                    updateTask={updateTaskData}
                                    deleteTask={handleDeleteTask}
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <Typography variant="h3">{Actions}Aktivitet</Typography>
                            <Grid item xs={12} sx={{ padding: '20px' }}>
                                <Draft task={task} updateTask={fetchTaskUpdate} users={users} />
                            </Grid>
                        </Paper>
                    </Grid>
                </Hidden>
            </Grid>
        );
    }
}
