import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Terms from '../../pages/private/Terms/Terms';
import PublicLoginView from '../public/PublicLoginView';

function PrivateRoute({ children, auth, betaOnly, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (auth.isAuthenticated && auth.userStatus === 'ExistingUser') {
                    if (auth.companyStatus !== 'ExpiredTrial') {
                        if (betaOnly === true && auth.companyData.companyData.betaOptIn === true) {
                            return children;
                        } else if (betaOnly === false) {
                            return children;
                        } else {
                            return <div>No page found.</div>;
                        }
                    } else {
                        return (
                            <PublicLoginView>
                                <Terms view="expiredtrial" />
                            </PublicLoginView>
                        );
                    }
                } else if (auth.isAuthenticated && auth.userStatus === 'RequestingAccess') {
                    return (
                        <PublicLoginView>
                            <Terms view="waiting" />
                        </PublicLoginView>
                    );
                } else if (
                    auth.isAuthenticated &&
                    (auth.userStatus === 'DeclinedByCompany' || auth.userStatus === 'RemovedByCompany')
                ) {
                    return (
                        <PublicLoginView>
                            <Terms view="declined" />
                        </PublicLoginView>
                    );
                } else if (auth.isAuthenticated && auth.userStatus === 'NewUser') {
                    return (
                        <PublicLoginView>
                            <Terms view="select" />
                        </PublicLoginView>
                    );
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location },
                            }}
                        />
                    );
                }
            }}
        />
    );
}

export default PrivateRoute;
