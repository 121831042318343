import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import {
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { NumberInput } from '../../../../../components/InputForm';

import { API, graphqlOperation } from 'aws-amplify';
import { updateCertQuestionOption, deleteCertQuestionOption } from '../../../../../graphql/mutations';

export default function CategoryRowView(props) {
    const [dialogOpen, setDialogOpen] = useToggle();

    const [option, setOption] = useState('');
    const [points, setPoints] = useState(0);
    const [order, setOrder] = useState(1);
    const [isOther, setIsOther] = useState(false);
    const [isCustomMultiSelectOption, setIsCustomMultiSelectOption] = useState(false);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            setOption(props.data.option);
            setPoints(props.data.pointValue);
            setOrder(props.data.order);

            if (props.data.isOtherOption) {
                setIsOther(props.data.isOtherOption);
            } else {
                setIsOther(false);
            }

            if (props.data.isCustomMultiSelectOption) {
                setIsCustomMultiSelectOption(props.data.isCustomMultiSelectOption);
            } else {
                setIsCustomMultiSelectOption(false);
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateOption = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateCertQuestionOption, {
                    input: {
                        id: props.data.id,
                        pointValue: points,
                        option: option,
                        order: order,
                        isOtherOption: isOther,
                        isCustomMultiSelectOption: isCustomMultiSelectOption,
                    },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated option: ', data);
                }

                resolve();
            });
        });
    };

    const deleteOption = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(deleteCertQuestionOption, {
                    input: { id: props.data.id },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Deleted option: ', data);
                }

                resolve();
            });
        });
    };

    const handleUpdateOption = async () => {
        if (option === '' || points === '') {
            props.showPopup('Valgalternativ og poengsum må være fylt ut!');
            return;
        }

        await updateOption().catch(() => {
            props.showPopup('En feil oppsto ved lagring av valgalternativ');
            return;
        });

        props.showPopup('Valgalternativet har blitt lagret');

        await props.update();

        setDialogOpen();
    };

    const handleDeleteOption = async () => {
        await deleteOption().catch(() => {
            props.showPopup('En feil oppsto ved sletting av valgalternativ');
            return;
        });

        props.showPopup('Valgalternativet har blitt slettet');

        await props.update();
    };

    const optionDialog = () => {
        return (
            <Grid>
                <Dialog open={dialogOpen} onClose={setDialogOpen} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Oppdater valgalternativ</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Oppdater spørsmålet. Dette blir reflektert i sertifiseringen.
                            <br />
                            <br />
                            <b>ID:</b> {props.data.id}
                        </DialogContentText>
                        <InputForm
                            label="Valgalternativ"
                            placeholder="Skriv inn valgalternativet"
                            value={option}
                            onChange={e => setOption(e.target.value)}
                        />
                        <InputForm
                            label="Poengsum"
                            placeholder="Skriv inn hvor mye poeng valgalternativet gir"
                            value={points}
                            onChange={e => setPoints(e.target.value)}
                            min={0}
                        />
                        <NumberInput
                            label="Rekkefølge (lavere nummer betyr høyere på listen)"
                            placeholder="Skriv inn nummer på rekkefølge"
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={isOther} onChange={e => setIsOther(e.target.checked)} />}
                                label="Er alternativet av type 'Annet/Andre' og skal ha tekstfelt som dukker opp? (Multiple Choice og Select)"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isCustomMultiSelectOption}
                                        onChange={e => setIsCustomMultiSelectOption(e.target.checked)}
                                    />
                                }
                                label="Er alternativet en select for 'Custom: Multiple med Select'? (Custom: Multiple med Select)"
                            />
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={setDialogOpen}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleDeleteOption}>
                            Slett
                        </Button>
                        <Button variant="primary" onClick={handleUpdateOption}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <ListItem disablePadding>
            {optionDialog()}
            <ListItemButton onClick={setDialogOpen}>
                <ListItemIcon>
                    <ModeEditOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={props.data.option + ' - ' + props.data.pointValue + ' poeng'} />
            </ListItemButton>
        </ListItem>
    );
}
