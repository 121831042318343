import React, { useEffect } from "react";

const HubspotContactForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "25393137",
          formId: "0d131f8b-5566-430a-807a-082aeaaf988f",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return <div id="hubspotForm"></div>;
};

export default HubspotContactForm;
