import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { useStyles } from '../../NSRSStyles';
import { ArrowRight, Environment } from '../../../../../components/Icons';
import ProgressBars from '../../../../../components/ProgessBar';
import useRouter from '../../../../../hooks/useRouter';

export default function EnvironemntModule(props) {
    const classes = useStyles();
    const router = useRouter();

    const { report, category, categories } = props;

    const [progress, setProgress] = useState(0);
    const [remaining, setRemaining] = useState(0);
    const [obligRemaining, setObligRemaining] = useState(0);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            checkAndSetProgress();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const climateSubcategoryCheck = (responses, subId) => {
        if (category.id !== 'climate' || subId === '13113522-0ba1-47de-ad5f-6a4cb6804f3f') {
            return true;
        }

        let findData = responses.find(response => {
            return response.questionId === '212ed41b-8647-4f90-9b02-4960eb2850a5';
        });

        if (findData) {
            let respData = JSON.parse(findData.data);

            if (respData['fe71025-061-87c-aa-d8ce70f2bb'] && respData['fe71025-061-87c-aa-d8ce70f2bb'].checked) {
                let climateCheckArr = respData['fe71025-061-87c-aa-d8ce70f2bb'].checked;

                // Materialer
                if (
                    climateCheckArr.includes('07c662-7c2-2bd7-5d1-a40ad43f58f3') &&
                    subId === '1d67ffdf-8f38-4446-9699-fd2beaf4aa91'
                ) {
                    return true;
                    // Avfall
                } else if (
                    climateCheckArr.includes('341c5f5-151a-176e-d05f-21640dfbdbb') &&
                    subId === 'cd3b3281-44cb-44f8-bf7e-76076d60ab22'
                ) {
                    return true;
                    // Energy
                } else if (
                    climateCheckArr.includes('7a087a-6ea5-2d0f-650d-473cf7bca1') &&
                    subId === 'a85f82bb-eb05-407a-a002-ce84ddc775d0'
                ) {
                    return true;
                    // Klima
                } else if (
                    climateCheckArr.includes('30ac6d0-1c3e-077b-a38-c1f0e4083e82') &&
                    subId === 'dd8ac392-6611-44c1-9dfe-7b53cbe29216'
                ) {
                    return true;
                    // EU-taksonomi
                } else if (
                    climateCheckArr.includes('28de7d1-aa77-616b-0e63-d6c58c01fc44') &&
                    subId === '8af23562-1361-4f41-a515-053049bd0578'
                ) {
                    return true;
                }
            }
        } else {
            return false;
        }
    };

    const checkAndSetProgress = async () => {
        let responses = report.responses.items;

        let totalQuestions = 0;
        let remainingQuestions = 0;
        let obligRemainingQuestions = 0;

        let totalAnswered = 0;

        for (const sub of category.subcategories.items) {
            for (const question of sub.questions.items) {
                if (climateSubcategoryCheck(responses, sub.id)) {
                    if (question.required) {
                        obligRemainingQuestions++;
                    }

                    totalQuestions++;

                    let resp = responses.find(item => item.questionId === question.id);

                    if (resp && resp.isAnswered) {
                        totalAnswered++;

                        if (question.required) {
                            obligRemainingQuestions--;
                        }
                    }
                }
            }
        }

        remainingQuestions = totalQuestions - totalAnswered;

        let totalPercent = 100 / totalQuestions;

        setRemaining(remainingQuestions);
        setObligRemaining(obligRemainingQuestions);
        setProgress(Math.ceil(totalPercent * totalAnswered));
    };

    return (
        <Paper
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Grid item>
                <Typography variant="h3" sx={{ backgroundColor: '#C3E8D0' }}>
                    {Environment} {category.order}.0 {category.name}
                </Typography>
                <Grid item style={{ padding: '20px 20px 0px 20px' }}>
                    <Typography variant="body1">{category.description}</Typography>
                    <br />
                </Grid>
            </Grid>
            <Grid item>
                <Grid item style={{ padding: '0px 20px 20px 20px' }}>
                    <Typography variant="h4" className={classes.progressionText}>
                        {remaining} spørsmål gjenstår ({obligRemaining} obligatoriske)
                    </Typography>
                    <ProgressBars value={progress} backgroundColor="#C3E8D0" />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        backgroundColor: '#E1F4E8',
                    }}
                    className={classes.modulesFooter}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#fff',
                            '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#C3E8D0',
                            },
                        }}
                        onClick={() =>
                            router.push({
                                pathname: '/nsrs/' + report.id + '/' + category.id,
                                state: {
                                    report: report,
                                    category: category,
                                    categories: categories,
                                },
                            })
                        }
                    >
                        {report.status === 'Draft' ? 'Endre' : 'Gå til'}
                        {ArrowRight}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
