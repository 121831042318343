import React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import LogoBlack from "../../../../assets/images/black_thumb.svg";
import LogoGreen from "../../../../assets/images/green_thumb.svg";
import LogoWhite from "../../../../assets/images/white_thumb.svg";
import LogoPackBlack from "../../../../assets/downloads/Ecofric_Logo_Svart.zip";
import LogoPackGreen from "../../../../assets/downloads/Ecofric_Logo_Grønn.zip";
import LogoPackWhite from "../../../../assets/downloads/Ecofric_Logo_Hvit.zip";
import { useStyles } from "../MediaKitStyles";

export default function Logos() {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={4}>
        <Paper>
          <Typography variant="h3">Logo pakke (svart)</Typography>
          <a href={LogoPackBlack} download>
            <img
              src={LogoBlack}
              style={{ width: "100%" }}
              alt="Logo black download"
            ></img>
          </a>
          <Grid item xs={12} sx={{ padding: "20px" }}>
            <a href={LogoPackBlack} download className={classes.link}>
              <Button variant="contained" className={classes.button}>
                Last ned
              </Button>
            </a>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper>
          <Typography variant="h3">Logo pakke (Grønn)</Typography>
          <a href={LogoPackGreen} download>
            <img
              src={LogoGreen}
              style={{ width: "100%" }}
              alt="Logo Green download"
            ></img>
          </a>
          <Grid item xs={12} sx={{ padding: "20px" }}>
            <a href={LogoPackGreen} download className={classes.link}>
              <Button variant="contained" className={classes.button}>
                Last ned
              </Button>
            </a>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper>
          <Typography variant="h3">Logo pakke (Hvit)</Typography>
          <a href={LogoPackWhite} download>
            <img
              src={LogoWhite}
              style={{ width: "100%" }}
              alt="Logo White download"
            ></img>
          </a>
          <Grid item xs={12} sx={{ padding: "20px" }}>
            <a href={LogoPackWhite} download className={classes.link}>
              <Button variant="contained" className={classes.button}>
                Last ned
              </Button>
            </a>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
