import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
  Text,
} from "@react-pdf/renderer";
import Lato from "../../../../../assets/fonts/Lato-Regular.ttf";

Font.register({
  family: "OpenSansCondensed",
  src:
    "https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xEMwSSh38KQVJx4ABtsZTnA.ttf",
});

Font.register({
  family: "OpenSansCondensedBold",
  src:
    "http://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xBEM87DM3yorPOrvA-vB930.ttf",
});

Font.register({
  family: "Lato",
  format: "truetype",
  src: Lato,
});

// Create styles
const styles = StyleSheet.create({
  title: {
    fontFamily: "OpenSansCondensed",
    fontSize: "25px",
  },
  page: {
    backgroundColor: "#ffffff",
    padding: "0px 30px",
  },
  toppBar: {
    height: "30px",
    backgroundColor: "#ffffff",
  },
  footer: {
    height: "30px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  pageNb: {
    fontFamily: "OpenSansCondensed",
    fontSize: "12px",
    padding: "10px 50px 0px 0px",
  },
  titles: {
    fontFamily: "LobsterTwo",
    fontSize: "25px",
    margin: "-20px 0px 0px 10px",
    padding: "0px 10px",
  },
  p: {
    fontFamily: "Lato",
    padding: "10px 20px 20px 20px",
    fontSize: "12px",
  },
  h2: {
    fontFamily: "OpenSansCondensedBold",
    fontSize: "14px",
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F0F0F0",
  },
  IndexCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid #BCBCBC",
    backgroundColor: "#E3E3E3",
    width: "15%",
    borderBottom: "1px solid #BCBCBC",
  },
  HeaderCell: {
    backgroundColor: "#D6D6D6",
    borderBottom: "1px solid #BCBCBC",
    padding: "10px",
    width: "85%",
  },
  MainCell: {
    backgroundColor: "#F1F1F1",
    borderBottom: "1px solid #BCBCBC",
    width: "85%",
    display: "flex",
    flexDirection: "row",
  },
  MainCellColumn: {
    backgroundColor: "#F1F1F1",
    borderBottom: "1px solid #BCBCBC",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  IndexColumns: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
  },
  cellTitle: {
    fontFamily: "OpenSansCondensedBold",
    fontSize: "12px",
    textAlign: "center",
  },
  cellText: {
    fontFamily: "Lato",
    fontSize: "10px",
    textAlign: "center",
  },
  cell: {
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid #BCBCBC",
  },
  dueCells: {
    display: "flex",
    flexDirection: "column",
  },
  numberCells: {
    width: "55%",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#E3E3E3",
  },
});

// Create Document Component
export const SimpleLayout = () => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <View fixed={true} style={styles.toppBar}></View>
        {/* NSRS klimaforpliktelser */}
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>NSRS Index</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 1</Text>
              <Text style={styles.cellText}>NSRS Klimaforpliktelse</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>0-1-2</Text>
            </View>
            <View style={styles.IndexColumns}>
              <View style={[styles.MainCellColumn]}>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "45%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    },
                  ]}
                >
                  <Text style={[styles.cellText, { textAlign: "left" }]}>
                    Klimamål:
                  </Text>
                </View>
                <View
                  style={[
                    styles.cell,
                    {
                      backgroundColor: "#ffffff",
                      alignItems: "flex-start",
                      borderRight: "0px",
                      width: "55%",
                    },
                  ]}
                >
                  <Text style={styles.cellText}>N/A</Text>
                </View>
              </View>
              <View style={styles.MainCellColumn}>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "45%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    },
                  ]}
                >
                  <Text style={[styles.cellText, { textAlign: "left" }]}>
                    År for måloppnåelse:
                  </Text>
                </View>
                <View
                  style={[
                    styles.cell,
                    {
                      backgroundColor: "#ffffff",
                      width: "55%",
                      alignItems: "flex-start",
                      borderRight: "0px",
                    },
                  ]}
                >
                  <Text style={styles.cellText}>N/A</Text>
                </View>
              </View>
              <View style={styles.MainCellColumn}>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "45%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    },
                  ]}
                >
                  <Text style={[styles.cellText, { textAlign: "left" }]}>
                    Basisår for oppstart måling:
                  </Text>
                </View>
                <View
                  style={[
                    styles.cell,
                    {
                      backgroundColor: "#ffffff",
                      width: "55%",
                      alignItems: "flex-start",
                      borderRight: "0px",
                    },
                  ]}
                >
                  <Text style={styles.cellText}>N/A</Text>
                </View>
              </View>
              <View style={styles.MainCellColumn}>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "45%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    },
                  ]}
                >
                  <Text style={[styles.cellText, { textAlign: "left" }]}>
                    Beskrivelse
                  </Text>
                </View>
                <View
                  style={[
                    styles.cell,
                    {
                      backgroundColor: "#ffffff",
                      width: "55%",
                      alignItems: "flex-start",
                      borderRight: "0px",
                    },
                  ]}
                >
                  <Text style={styles.cellText}>N/A</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Profil */}
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 2</Text>
              <Text style={styles.cellText}>Profil</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-2-1</Text>
            </View>
            <View style={styles.IndexColumns}>
              <View style={[styles.MainCellColumn]}>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "45%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    },
                  ]}
                >
                  <Text style={[styles.cellText, { textAlign: "left" }]}>
                    Organisasjonsnavn
                  </Text>
                </View>
                <View
                  style={[
                    styles.cell,
                    {
                      backgroundColor: "#ffffff",
                      alignItems: "flex-start",
                      borderRight: "0px",
                      width: "55%",
                    },
                  ]}
                >
                  <Text style={styles.cellText}>N/A</Text>
                </View>
              </View>
              <View style={styles.MainCellColumn}>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "45%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    },
                  ]}
                >
                  <Text style={[styles.cellText, { textAlign: "left" }]}>
                    Organisasjonens webside
                  </Text>
                </View>
                <View
                  style={[
                    styles.cell,
                    {
                      backgroundColor: "#ffffff",
                      width: "55%",
                      alignItems: "flex-start",
                      borderRight: "0px",
                    },
                  ]}
                >
                  <Text style={styles.cellText}>N/A</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-2-2</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Aktiviteter, varemerker, produkter og tjenester
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Varemerker</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>#</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Produkter</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>#</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Tjenester</Text>
                  </View>
                  <View style={[styles.cell, { backgroundColor: "#fff" }]}>
                    <Text style={styles.cellText}>#</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Beskrivelse</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        borderRight: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Beskrivelse</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        borderRight: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Beskrivelse</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderRight: "0px", backgroundColor: "#fff" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-2-2-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Hovedaktiviteter klassifisert under NACE-koder
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <View
                  style={{
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 3 }}>•</Text>
                    <Text style={[styles.cellText, { marginTop: "3px" }]}>
                      N/A
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-2-2-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Primære varemerker, produkter og tjenester, inkludert
                  forklaring av produkter og tjenester som er forbudt i visse
                  markeder
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-2-3</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Hovedkontorets lokasjon
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-2-5</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Eierskap og juridisk form
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Organisasjonsnummer</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Juridisk form</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-2-7</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Organisasjonens størrelse
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={[styles.MainCell, { backgroundColor: "#ffffff" }]}>
              <View style={[styles.cell, { width: "30%" }]}>
                <Text style={[styles.cellText, { color: "#ffffff" }]}>.</Text>
              </View>
              <View style={[styles.numberCells, { width: "75%" }]}>
                <View
                  style={[
                    styles.duoCells,
                    { width: "35%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Leverandør</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "35%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Beskrivelse</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>Produkt/Tjeneste</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-2-9</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "30%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Hovedleverandører (fem viktigste)
                </Text>
              </View>
              <View style={[styles.numberCells, { width: "75%" }]}>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Leverandør 1</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Leverandør 2</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Leverandør 3</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Leverandør 4</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Leverandør 5</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "35%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "30%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Interessenter */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 3</Text>
              <Text style={styles.cellText}>Inkludering av interessenter</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-3-1</Text>
            </View>
            <View style={styles.IndexColumns}>
              <View style={styles.MainCellColumn}>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "45%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    },
                  ]}
                >
                  <Text style={[styles.cellText, { textAlign: "left" }]}>
                    En liste over interne interessenter (sorter etter viktighet)
                  </Text>
                </View>
                <View style={[styles.numberCells]}>
                  <View style={[styles.duoCells, { width: "50%" }]}>
                    <View
                      style={[
                        styles.cell,
                        { borderBottom: "1px solid #BCBCBC" },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>Intern interessent 1</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        { borderBottom: "1px solid #BCBCBC" },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>Intern interessent 2</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        { borderBottom: "1px solid #BCBCBC" },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>Intern interessent 3</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        { borderBottom: "1px solid #BCBCBC" },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>Intern interessent 4</Text>
                    </View>
                    <View style={[styles.cell, {}]} wrap={false}>
                      <Text style={styles.cellText}>Intern interessent 5</Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.duoCells,
                      { width: "50%", backgroundColor: "#ffffff" },
                    ]}
                  >
                    <View
                      style={[
                        styles.cell,
                        {
                          borderBottom: "1px solid #BCBCBC",
                          borderRight: "0px",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        {
                          borderBottom: "1px solid #BCBCBC",
                          borderRight: "0px",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        {
                          borderBottom: "1px solid #BCBCBC",
                          borderRight: "0px",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        {
                          borderBottom: "1px solid #BCBCBC",
                          borderRight: "0px",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                    <View
                      style={[styles.cell, { borderRight: "0px" }]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.MainCellColumn}>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "45%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    },
                  ]}
                >
                  <Text style={[styles.cellText, { textAlign: "left" }]}>
                    En liste over eksterne interessenter (sorter etter
                    viktighet)
                  </Text>
                </View>
                <View style={[styles.numberCells]}>
                  <View style={[styles.duoCells, { width: "50%" }]}>
                    <View
                      style={[
                        styles.cell,
                        { borderBottom: "1px solid #BCBCBC" },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>Ekstern interessent 1</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        { borderBottom: "1px solid #BCBCBC" },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>Ekstern interessent 2</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        { borderBottom: "1px solid #BCBCBC" },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>Ekstern interessent 3</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        { borderBottom: "1px solid #BCBCBC" },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>Ekstern interessent 4</Text>
                    </View>
                    <View style={[styles.cell, {}]} wrap={false}>
                      <Text style={styles.cellText}>Ekstern interessent 5</Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.duoCells,
                      { width: "50%", backgroundColor: "#ffffff" },
                    ]}
                  >
                    <View
                      style={[
                        styles.cell,
                        {
                          borderBottom: "1px solid #BCBCBC",
                          borderRight: "0px",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        {
                          borderBottom: "1px solid #BCBCBC",
                          borderRight: "0px",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        {
                          borderBottom: "1px solid #BCBCBC",
                          borderRight: "0px",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        {
                          borderBottom: "1px solid #BCBCBC",
                          borderRight: "0px",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                    <View
                      style={[styles.cell, { borderRight: "0px" }]}
                      wrap={false}
                    >
                      <Text style={styles.cellText}>N/A</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Klimaeffekter */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 4</Text>
              <Text style={styles.cellText}>Vesentlighetsvurdering</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4-2-a</Text>
            </View>
            <View style={[styles.MainCell, { backgroundColor: "#ffffff" }]}>
              <View style={[styles.cell, { width: "50%" }]}>
                <Text style={styles.cellText}>
                  Liste over vesentlige emner (fokusområder) dekket i denne
                  rapporten. Velg "Ja" og "Nei" i rullegardinmenyen for hvert
                  vesentlige emne.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View
                  style={[
                    styles.duoCells,
                    { backgroundColor: "#ffffff", width: "20%" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { height: "45px", borderBottom: "1px solid #BCBCBC" },
                    ]}
                  >
                    <Text style={styles.cellText}>Materialer</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { backgroundColor: "#ffffff", width: "20%" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { height: "45px", borderBottom: "1px solid #BCBCBC" },
                    ]}
                  >
                    <Text style={styles.cellText}>Avfall</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { backgroundColor: "#ffffff", width: "20%" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { height: "45px", borderBottom: "1px solid #BCBCBC" },
                    ]}
                  >
                    <Text style={styles.cellText}>Energi</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { backgroundColor: "#ffffff", width: "20%" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { height: "45px", borderBottom: "1px solid #BCBCBC" },
                    ]}
                  >
                    <Text style={styles.cellText}>Klimagassutslipp</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { backgroundColor: "#ffffff", width: "20%" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        height: "45px",
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>EU taksonomi</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Fokusområde 1: Materialer</Text>
              <Text style={styles.cellText}>
                Materialer er råstoffene - dvs. treverk, plast, metall, glass
                eller tøystoff - som et produkt er laget av. Ressursutvinning
                for materialbruk er ansvarlig for halvparten av verdens
                karbonutslipp.
              </Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={[styles.MainCell, { backgroundColor: "#ffffff" }]}>
              <View style={[styles.cell, { width: "50%" }]}>
                <Text style={[styles.cellText, { color: "#ffffff" }]}>.</Text>
              </View>
              <View style={[styles.numberCells]}>
                <View
                  style={[
                    styles.duoCells,
                    { width: "35%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Kategori</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Vekt</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "35%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>Mål</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-1-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Total vekt eller volum av materialer som brukes til å
                  produsere og pakke organisasjonens primære produkter og
                  tjenester i løpet av rapporteringsperioden, herav:
                </Text>
                <Text
                  style={[
                    styles.cellText,
                    { marginTop: "10px", textAlign: "left" },
                  ]}
                >
                  i. ikke-fornybare materialer som brukes
                </Text>
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  ii. fornybare materialer som brukes
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      Ikke-fornybare materialer
                    </Text>
                  </View>
                  <View style={[styles.cell, { height: "47px" }]}>
                    <Text style={styles.cellText}>Fornybare materialer</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { height: "47px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                        height: "47px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>Tonn</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderRight: "0px", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Tonn</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-1-a</Text>
            </View>
            <View style={[styles.MainCell, {}]}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={styles.cellText}>
                  Total vekt eller volum av materialer
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Total vekt</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "30%" }]}>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>0</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-2</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Prosent av fornybare og ikke-fornybare materialer som brukes
                  til å produsere organisasjonens primære produkter og
                  tjenester.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      Ikke-fornybare materialer
                    </Text>
                  </View>
                  <View style={[styles.cell, { height: "47px" }]}>
                    <Text style={styles.cellText}>Fornybare materialer</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { height: "47px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                        height: "47px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>Prosent</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderRight: "0px", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Prosent</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-1-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv metoden som brukes for å hente dataene som er samlet
                  inn
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-1-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv eventuell usikkerhet i forbindelse med dataene som er
                  samlet inn
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Fokusområde 2: Avfall</Text>
              <Text style={styles.cellText}>
                Avfall er uønskede eller ubrukelige materialer, vanligvis kastet
                etter primær bruk. Eksempler inkluderer matavfall, farlig avfall
                og avløpsvann. Matavfall alene er ansvarlig for 6% av totale
                globale klimagasser
              </Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={[styles.MainCell, { backgroundColor: "#ffffff" }]}>
              <View style={[styles.cell, { width: "30%" }]}>
                <Text style={[styles.cellText, { color: "#ffffff" }]}>.</Text>
              </View>
              <View style={[styles.numberCells, { width: "70%" }]}>
                <View
                  style={[
                    styles.duoCells,
                    { width: "20%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Kategori</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "20%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Mengde</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "25%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Opphav</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "35%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>Beskrivelse</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.2-6-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "30%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Total vekt av avfall som kastes etter bruk i
                  rapporteringsperioden
                </Text>
              </View>
              <View style={[styles.numberCells, { width: "70%" }]}>
                <View style={[styles.duoCells, { width: "20%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "20%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "25%", backgroundColor: "#fff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "35%", backgroundColor: "#fff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.2-6-a</Text>
            </View>
            <View style={[styles.MainCell, {}]}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "30%",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={styles.cellText}>Total vekt av avfall</Text>
              </View>
              <View style={[styles.numberCells, { width: "70%" }]}>
                <View style={[styles.duoCells, { width: "20%" }]}>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Total vekt</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "20%" }]}>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>0</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "25%" }]}>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.2-6-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv metoden som brukes for å hente de innsamlede dataene
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.2-6-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Begrunnelsen for valg av kategorier
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.2-6-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv eventuell usikkerhet i forbindelse med dataene som er
                  samlet inn
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Fokusområde 3: Energi</Text>
              <Text style={styles.cellText}>
                Energiforbruk refererer til all energien som brukes til å utføre
                en handling, produsere noe eller bare bo i en bygning. Eksempler
                inkluderer fossilt brensel, elektrisitet, vann og gass. Alle
                typer energiproduksjon står for 72 prosent av de totale globale
                utslippene.
              </Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={[styles.MainCell, { backgroundColor: "#ffffff" }]}>
              <View style={[styles.cell, { width: "50%" }]}>
                <Text style={[styles.cellText, { color: "#ffffff" }]}>.</Text>
              </View>
              <View style={[styles.numberCells]}>
                <View
                  style={[
                    styles.duoCells,
                    { width: "35%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Kategori</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Forbruk</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "35%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>Mål</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.3-11-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Totalt energiforbruk og/eller produksjon fra fornybare og
                  ikke-fornybare kilder
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Ikke-fornybare kilder</Text>
                  </View>
                  <View style={[styles.cell, { height: "47px" }]}>
                    <Text style={styles.cellText}>Fornybare kilder</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { height: "47px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                        height: "45px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>KWh</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderRight: "0px", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>kWh</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.3-11-a</Text>
            </View>
            <View style={[styles.MainCell, {}]}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={styles.cellText}>Totalt forbruk energi</Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Totalt forbruk</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "30%" }]}>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>0</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>kWh</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.3-11-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Total bruk av ikke-fornybare og fornybare kilder i prosent.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Ikke-fornybare kilder</Text>
                  </View>
                  <View style={[styles.cell, { height: "47px" }]}>
                    <Text style={styles.cellText}>Fornybare kilder</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { height: "47px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                        height: "45px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>Prosent</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderRight: "0px", height: "47px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Prosent</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.3-11-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv metoden som brukes for å hente dataene som er samlet
                  inn
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.3-11-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv eventuell usikkerhet i forbindelse med dataene som er
                  samlet inn
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>
                Fokusområde 4: Klimagassutslipp
              </Text>
              <Text style={styles.cellText}>
                Klimagasser (GHG) som karbondioksid og metan, er gasser som
                fanger varme eller langbølget stråling i atmosfæren.
                Akkumuleringen av klimagasser siden den industrielle
                revolusjonen har akselerert drivhuseffekten, forårsaket global
                oppvarming og klimaendringer.
              </Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.4-15-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Scope 1: Direkte klimagassutslipp
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "120px" },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      Direkte klimagassutslipp fra stasjonære forbrenningskilder
                      som eies eller kontrolleres av selskapet
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "110px" },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      Direkte klimagassutslipp fra mobilie forbrenningskilder
                      som eies eller kontrolleres av selskapet
                    </Text>
                  </View>
                  <View style={[styles.cell, { height: "40px" }]}>
                    <Text style={styles.cellText}>Totale utslipp Scope 1</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "120px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "110px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { height: "40px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                        height: "120px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      (Klimagassutslipp) kg CO2e
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        height: "110px",
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      (Klimagassutslipp) kg CO2e
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderRight: "0px", height: "40px" },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      (Klimagassutslipp) kg CO2e
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-2</Text>
            </View>
            <View style={[styles.MainCell, {}]}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Scope 2: Indirekte klimagassutslipp fra generering av ervervet
                  og konsumert elektrisitet, damp, varme eller kjøling (samlet
                  omtalt som "elektrisitet")
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "80px" },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      Indirekte klimagassutslipp fra forbrukt elektrisitet
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { height: "90px", borderBottom: "1px solid #BCBCBC" },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      Indirekte klimagassutslipp fra forbrukt damp, oppvarming
                      eller kjøling
                    </Text>
                  </View>
                  <View style={[styles.cell, { height: "40px" }]}>
                    <Text style={styles.cellText}>Totale utslipp Scope 2</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "80px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", height: "90px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { height: "40px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "35%" }]}>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        borderBottom: "1px solid #BCBCBC",
                        height: "80px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      (Klimagassutslipp) kg CO2e
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderRight: "0px",
                        height: "90px",
                        borderBottom: "1px solid #BCBCBC",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      (Klimagassutslipp) kg CO2e
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderRight: "0px", height: "40px" },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      (Klimagassutslipp) kg CO2e
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-1-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv metoden som brukes for å hente dataene som er samlet
                  inn
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-1-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv eventuell usikkerhet i forbindelse med dataene som er
                  samlet inn
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Fokusområde 5: EUs Taksonomi</Text>
              <Text style={styles.cellText}>
                EU Taksonomi er et nytt klassifiseringsverktøy for bærekraftig
                virksomhet. Ved å gi et sett med bransjespesifikke tekniske
                screeningskriterier, dikterer taksonomien om en bestemt
                aktivitet er bærekraftig eller ikke.
              </Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-2</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Er organisasjonen berørt av EUs Taksonomi?
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-1-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Dersom den er påvirket av taksonomien, gi en oversikt over
                  klassifiseringene som påvirker organisasjonens
                  kjerneaktiviteter
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.1-1-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Dersom den er påvirket av taksonomien, gi en skriftlig
                  beskrivelse med refleksjoner om potensielle implikasjoner EUs
                  taksonomi kan ha på organisasjonen
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    width: "55%",
                    alignItems: "flex-start",
                    borderRight: "0px",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        {/* Sosialt */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Sosialt og økonomisk</Text>
              <Text style={styles.cellText}>Sosialt</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={[styles.MainCell, { backgroundColor: "#ffffff" }]}>
              <View style={[styles.cell, { width: "45%" }]}>
                <Text style={[styles.cellText, { color: "#ffffff" }]}>.</Text>
              </View>
              <View style={[styles.numberCells]}>
                <View
                  style={[
                    styles.duoCells,
                    { width: "33%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Kvinner</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "34%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Menn</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "33%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>Annet</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-1a</Text>
            </View>
            <View style={styles.MainCell}>
              <View style={[styles.cell, { width: "45%" }]}>
                <Text style={styles.cellText}>
                  Antall heltidsekvivalente ansatte per kjønn
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View
                  style={[
                    styles.duoCells,
                    { backgroundColor: "#ffffff", width: "33%" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { backgroundColor: "#ffffff", width: "34%" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { backgroundColor: "#ffffff", width: "33%" },
                  ]}
                >
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-1b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv tiltak som er tatt for å bedre likestilling. Beskriv
                  også alderssammensetningen av arbeidsstyrken og tiltak for å
                  utvide aldersbredden.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <View
                  style={{
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 3 }}>•</Text>
                    <Text style={[styles.cellText, { marginTop: "3px" }]}>
                      N/A
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-2</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv andre tiltak for å øke mangfoldet i arbeidsstyrken,
                  for eksempel inkludering av minoriteter eller sårbare grupper.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <View
                  style={{
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 3 }}>•</Text>
                    <Text style={[styles.cellText, { marginTop: "3px" }]}>
                      N/A
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-3</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv hvordan du vurderer verdikjeden din for å minimere
                  risiko for sosiale problemer hos leverandører og kunder.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={[styles.MainCell, { backgroundColor: "#ffffff" }]}>
              <View style={[styles.cell, { width: "45%" }]}>
                <Text style={[styles.cellText, { color: "#ffffff" }]}>.</Text>
              </View>
              <View style={[styles.numberCells]}>
                <View
                  style={[
                    styles.duoCells,
                    { width: "100%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>
                      Legg inn forhold (=lønn for kvinner/ lønn for menn). Ved
                      kun ett kjønn i katergorien skriv "ikke aktuelt"
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-4</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Forhold mellom grunnlønn og godtgjørelse for kvinner og menn
                  for hver kategori arbeidstakere.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Ledere</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Mellom ledere</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Erfarne ansatte</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Junior ansatte</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={[styles.MainCell, { backgroundColor: "#ffffff" }]}>
              <View style={[styles.cell, { width: "45%" }]}>
                <Text style={[styles.cellText, { color: "#ffffff" }]}>.</Text>
              </View>
              <View style={[styles.numberCells]}>
                <View
                  style={[
                    styles.duoCells,
                    { width: "33%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { height: "45px" }]}>
                    <Text style={styles.cellText}>Arbeidsrelatert</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "34%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { height: "45px" }]}>
                    <Text style={styles.cellText}>Ikke arbeidsrelatert</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "33%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderRight: "0px", height: "45px" },
                    ]}
                  >
                    <Text style={styles.cellText}>
                      Overordnede mål prosent sykefravær
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-5</Text>
            </View>
            <View style={styles.MainCell}>
              <View style={[styles.cell, { width: "45%" }]}>
                <Text style={styles.cellText}>
                  Prosent sykefravær i rapporteringsperioden
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View
                  style={[
                    styles.duoCells,
                    { width: "33%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "34%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "33%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-6</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Antall ansatte som har deltatt på HMS-kurs siste tre år.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-7</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Attraktive vilkår rundt velferdspermisjoner,
                  foreldrepermisjoner mv
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <View style={{ flexDirection: "row", marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 3 }}>•</Text>
                  <Text style={[styles.cellText, { marginTop: "3px" }]}>
                    N/A
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-8</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Antall timer med ansatteopplæring og videreutdanning for
                  rapporteringsperioden.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Opplæring</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Videreutdanning</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Økonomisk */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellText}>Økonomisk</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-9</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv balansen mellom kortsiktige og langsiktige
                  beslutninger for å sikre en bærekraftig økonomisk fremtid for
                  selskapet.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-10</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv hvordan du vurderer verdikjeden din for å sikre
                  minimal materialbruk, avfall, energiforbruk etc.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4-6-11</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Identifiserte områder der virksomheten din kan spare penger
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Område</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>#</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Beskrivelse</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "0px",
                        borderRight: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-12</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv tiltak som er iverksatt for å sikre tilstrekkelig
                  likviditet og stabile økonomiske forhold.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-4.6-13</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Selskapet skal rapportere betydelige risikoer knyttet til
                  korrupsjon identifisert gjennom risikovurderingen.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        {/* Klimarelaterte risikoer */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 5</Text>
              <Text style={styles.cellText}>Klimarelaterte risikoer</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-2</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En liste over klimarelaterte risikoer identifisert
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-1-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En beskrivelse av egenoppfattede sentrale klimarelaterte
                  risikoer
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-2</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Del opp de identifiserte risikoene på kort og lang sikt
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-2</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Bryt ned risikoen på fysiske og overgangsrisikoer
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Risikoer kategorisert etter egenoppfattet påvirkning (lav til
                  høy)
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Risikoer kategorisert etter organisasjonens egenoppfattede
                  kunnskapsnivå på hvert av temaene (mye til lite).
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        {/* Klimarelaterte muligheter */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellText}>Klimarelaterte muligheter</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-2</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En liste over klimarelaterte risikoer identifisert
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-1-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En beskrivelse av egenoppfattede sentrale klimarelaterte
                  risikoer
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-2</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Del opp de identifiserte risikoene på kort og lang sikt
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Risikoer kategorisert etter egenoppfattet påvirkning (lav til
                  høy)
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>1-5-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Risikoer kategorisert etter organisasjonens egenoppfattede
                  kunnskapsnivå på hvert av temaene (mye til lite).
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        {/* Styringsprofil */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 6</Text>
              <Text style={styles.cellText}>Styringsprofil</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-6-1</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En beskrivelse av organisasjonens verdier, prinsipper,
                  standarder og normer for atferd.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-6-3-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Styringsstrukturen for organisasjonen, inkludert komiteer i
                  det øverste styringsorganet.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Styringsstruktur</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>
                      Komiteer i det øverste styringsorganet
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-6-3-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Komiteer som er ansvarlige for beslutninger om økonomiske,
                  miljømessige og sosiale temaer.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Komitè</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>#</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>#</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Beskrivelse</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        borderRight: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "none",
                        borderRight: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-6-5-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Dersom organisasjonen har etablert en stilling på ledende nivå
                  eller stillinger med ansvar for økonomiske, miljømessige og
                  sosiale temaer.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Navn</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Tittel</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Email</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-6-11</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Andre bærekraftssertifiseringer. Dersom organisasjonen har
                  andre gyldige bærekraftsorienterte merker og/eller
                  sertifiseringer fra tidligere, kan du legge til en beskrivelse
                  av den relevante sertifiseringen/merkingen. (Vi anbefaler å
                  laste opp et bilde når bærekraftsrapporten er visualisert)
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "30%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Sertifisering</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>#</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>#</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>#</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Beskrivelse</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "none",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "20%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>ID</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        borderRight: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        borderRight: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "none",
                        borderRight: "0px",
                        backgroundColor: "#fff",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-6-15</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Den høyeste komiteen eller stillingen som formelt gjennomgår
                  og godkjenner organisasjonens bærekraftsrapport og sikrer at
                  alle vesentlige temaer dekkes.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Navn</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Tittel</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Epost</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-6-20</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Hvis organisasjonen har en sirkulær forretningsmodell, beskriv
                  hvordan. Hvis organisasjonen ikke har en sirkulær
                  forretningsmodell, forklar hvorfor.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        {/* Ledelses fremgangmåte */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 7</Text>
              <Text style={styles.cellText}>Ledelses fremgangsmåte</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    backgroundColor: "#D6D6D6",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Tema A: Materialer
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    alignItems: "center",
                    borderRight: "0px",
                    width: "55%",
                    backgroundColor: "#D6D6D6",
                  },
                ]}
              >
                <Text style={styles.cellText}>Materialer</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Målet i tall
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "40%" }]}>
                  <View style={[styles.cell, { borderBottom: "0px" }]}>
                    <Text style={styles.cellText}>Numerisk verdi</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderBottom: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "30%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "0px", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Tonn</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En beskrivelse av målet
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En forklaring på hvordan organisasjonen forvalter temaet. En
                  utdypning av fremdriftsplanen.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          {/*  */}
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    backgroundColor: "#D6D6D6",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Tema B: Avfall
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    alignItems: "center",
                    borderRight: "0px",
                    width: "55%",
                    backgroundColor: "#D6D6D6",
                  },
                ]}
              >
                <Text style={styles.cellText}>Avfall</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Målet i tall
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "40%" }]}>
                  <View style={[styles.cell, { borderBottom: "0px" }]}>
                    <Text style={styles.cellText}>Numerisk verdi</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderBottom: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "30%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "0px", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>Tonn</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En beskrivelse av målet
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En forklaring på hvordan organisasjonen forvalter temaet. En
                  utdypning av fremdriftsplanen.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          {/*  */}
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    backgroundColor: "#D6D6D6",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Tema C: Energi
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    alignItems: "center",
                    borderRight: "0px",
                    width: "55%",
                    backgroundColor: "#D6D6D6",
                  },
                ]}
              >
                <Text style={styles.cellText}>Energi</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Målet i tall
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "40%" }]}>
                  <View style={[styles.cell, { borderBottom: "0px" }]}>
                    <Text style={styles.cellText}>Numerisk verdi</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderBottom: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "30%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "0px", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>kWh</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En beskrivelse av målet
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En forklaring på hvordan organisasjonen forvalter temaet. En
                  utdypning av fremdriftsplanen.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          {/*  */}
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    backgroundColor: "#D6D6D6",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Tema D: Utslipp i klimagasser
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    alignItems: "center",
                    borderRight: "0px",
                    width: "55%",
                    backgroundColor: "#D6D6D6",
                  },
                ]}
              >
                <Text style={styles.cellText}>Utslipp i klimagasser</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Målet i tall
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "40%" }]}>
                  <View style={[styles.cell, { borderBottom: "0px" }]}>
                    <Text style={[styles.cellText, { color: "#E3E3E3" }]}>
                      .
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "30%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View style={[styles.cell, { borderBottom: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
                <View style={[styles.duoCells, { width: "30%" }]}>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "0px", borderRight: "0px" },
                    ]}
                  >
                    <Text style={[styles.cellText, { color: "#E3E3E3" }]}>
                      .
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-c</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En beskrivelse av målet
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-7-1-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  En forklaring på hvordan organisasjonen forvalter temaet. En
                  utdypning av fremdriftsplanen.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
        </View>
        {/* Integrert rapportering */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 8</Text>
              <Text style={styles.cellText}>Integrert rapportering</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-8-1</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Dersom organisasjonen har inkludert ikke-finansielle
                  opplysninger i de finansielle rapportene, legg til i listen de
                  ikke-finansielle opplysninger som er inkludert i de
                  finansielle rapportene. (Sorter etter viktighet)
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>#1</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>#2</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>#3</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>#4</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>#5</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Fullføring */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 9</Text>
              <Text style={styles.cellText}>Fullføring</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-9-1</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Uttalelse fra ledende beslutningstaker (Vi anbefaler å laste
                  opp et bilde nær bærekraftsrapporten er visualisert)
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Overskrift</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Erklæringen</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>
                      Navn til administrende direktør
                    </Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>
                      Tittel til "administrerende direktør"
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-9-6</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Rapporteringssyklus
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-9-7</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Kontaktpunktet for spørsmål eller tilbakemeldinger angående
                  rapporten eller dets innhold.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Navn til kontakt person</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Telefonnummer</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Email</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-9-11</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Ved rapportering i henhold til NSRS Nivå 1 er områdene for FNs
                  bærekratig utvikling (SDGs) forhåndsbestemt. Områdene for FNs
                  bærekraft utvikling (SDGs) ble vedtatt av alle FNs medlemsland
                  i 2015. De gir en handlingsplan for å håndtere verdens mest
                  presserende utfordringer.
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "2px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Rimelig og ren energi</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>
                      Anstendig arbeid og økonomisk vekst
                    </Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>
                      Anstendig arbeid og økonomisk vekst
                    </Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "2px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>
                      Anstendig arbeid og økonomisk vekst
                    </Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>
                      Ansvarlig forbruk og produksjon
                    </Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>
                      Ansvarlig forbruk og produksjon
                    </Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "2px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>
                      Ansvarlig forbruk og produksjon
                    </Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Klimainnsats</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Klimainnsats</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "2px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        borderRight: "0px",
                        height: "35px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        borderRight: "0px",
                        height: "35px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "2px solid #BCBCBC",
                        borderRight: "0px",
                        height: "36px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        borderRight: "0px",
                        height: "35px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "1px solid #BCBCBC",
                        borderRight: "0px",
                        height: "35px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        borderBottom: "2px solid #BCBCBC",
                        borderRight: "0px",
                        height: "36px",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-9-12</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Skriv at rapporten er utarbeidet i samsvar med NSRS rapport
                  Index
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>
                  Denne bærekraftsrapporten er utarbeidet i samsvar med Nordic
                  Sustainability Reporting Standard - NSRS Nivå 1. Alle
                  rettigheter forbeholdt. Les mer på www.nsrs.eu.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>Anbefalt</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Beskriv rammene som NSRS har vedtatt. Dette kan være
                  verdifullt for interessentene å vite.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>
                  Denne rapporten er tilpasset Global Reporting Initiative
                  (GRI), direktivet om ikke-finansiell rapportering (NFRD) og
                  Task-Force on Climate-Related Disclosures (TCFD). Dette betyr
                  ikke at rapporten er i tråd med disse rammene. Les mer på
                  www.nsrs.eu.
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* Evaluering */}
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={[styles.cellText, { color: "#E3E3E3" }]}>.</Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text style={styles.cellTitle}>Steg 10</Text>
              <Text style={styles.cellText}>Evaluering</Text>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-11-1-a</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Skriv en uttalelse på slutten av bærekraftsrapporten (eller
                  kopier og lim inn det forhåndsutfylte eksemplet) der du
                  inviterer alle lesere av rapporten til å dele sine
                  tilbakemeldinger.
                </Text>
              </View>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: "#ffffff",
                    alignItems: "flex-start",
                    borderRight: "0px",
                    width: "55%",
                  },
                ]}
              >
                <Text style={styles.cellText}>N/A</Text>
              </View>
            </View>
          </View>
          <View style={styles.row} wrap={false}>
            <View style={styles.IndexCell}>
              <Text style={styles.cellText}>2-11-1-b</Text>
            </View>
            <View style={styles.MainCell}>
              <View
                style={[
                  styles.cell,
                  {
                    width: "45%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.cellText, { textAlign: "left" }]}>
                  Navnet og kontantinformasjonen til en person eller et team som
                  er ansvarlig for å håndtere tilbakemeldingen
                </Text>
              </View>
              <View style={[styles.numberCells]}>
                <View style={[styles.duoCells, { width: "50%" }]}>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Navn</Text>
                  </View>
                  <View
                    style={[styles.cell, { borderBottom: "1px solid #BCBCBC" }]}
                  >
                    <Text style={styles.cellText}>Telefonnummer</Text>
                  </View>
                  <View style={[styles.cell, {}]}>
                    <Text style={styles.cellText}>Email</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.duoCells,
                    { width: "50%", backgroundColor: "#ffffff" },
                  ]}
                >
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { borderBottom: "1px solid #BCBCBC", borderRight: "0px" },
                    ]}
                  >
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                  <View style={[styles.cell, { borderRight: "0px" }]}>
                    <Text style={styles.cellText}>N/A</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View fixed={true} style={{ flexGrow: 1 }}></View>
        <View
          fixed={true}
          style={[
            styles.footer,
            {
              margin: "0px -30px",
              position: "fixed",
              bottom: "0px",
              left: "0px",
            },
          ]}
        >
          <Text
            style={[
              styles.pageNb,
              { marginRight: "-20px", marginTop: "-10px" },
            ]}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  );
};

export default function SimplePDF(props) {
  return (
    <PDFViewer width="100%" height="730">
      <SimpleLayout report={props.report} />
    </PDFViewer>
  );
}
