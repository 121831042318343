import React, { useEffect } from 'react';
import { Grid, InputLabel, InputBase } from '@mui/material';

export default function MultipleNumber(props) {
    const {
        data,
        multiNumber,
        setMultiNumber,
        pointsEarned,
        setPointsEarned,
        setIsAnswered,
        minChars,
        maxChars,
    } = props;

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (multiNumber.length > 0) {
                let reqInputChecks = 0;

                for (const item of multiNumber) {
                    if (item.hasEarnedPoints) {
                        reqInputChecks++;
                    }
                }

                if (reqInputChecks === data.length) {
                    setIsAnswered(true);
                } else {
                    setIsAnswered(false);
                }
            } else {
                setIsAnswered(false);
            }
        }

        return () => {
            isMounted = true;
        };
    }, [multiNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (option, value) => {
        let newArr = multiNumber;

        let index = multiNumber.findIndex(object => {
            return object.id === option.id;
        });

        if (index === -1 && value.length !== 0) {
            let earnedPointsCheck = false;

            if (value.length >= minChars && value.length !== 0) {
                earnedPointsCheck = true;
                setPointsEarned(pointsEarned + option.pointValue);
            }

            let newObj = {
                id: option.id,
                input: value,
                hasEarnedPoints: earnedPointsCheck,
            };

            newArr.push(newObj);
        } else {
            newArr[index].input = value;

            if (value.length === 0) {
                newArr.splice(index, 1);
                setPointsEarned(pointsEarned - option.pointValue);
            } else if (!newArr[index].hasEarnedPoints && value.length >= minChars) {
                newArr[index].hasEarnedPoints = true;
                setPointsEarned(pointsEarned + option.pointValue);
            }
        }

        setMultiNumber([...newArr]);
    };

    return (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            {data &&
                data
                    .sort((a, b) => {
                        return a.order - b.order;
                    })
                    .map(option => {
                        let answerData = multiNumber.find(answer => {
                            return answer.id === option.id;
                        });

                        if (!answerData) {
                            answerData = {
                                id: option.id,
                                input: '',
                                hasEarnedPoints: false,
                            };
                        }

                        return (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                key={option.id}
                            >
                                <InputLabel id={option.id}>{option.option}</InputLabel>
                                <InputBase
                                    sx={{ height: '50px' }}
                                    id={option.id}
                                    type="number"
                                    name={option.option}
                                    value={answerData.input}
                                    onChange={e => handleChange(option, e.target.value)}
                                    placeholder="Skriv her..."
                                    inputProps={{ min: props.min, maxLength: maxChars }}
                                />
                            </Grid>
                        );
                    })}
        </Grid>
    );
}
