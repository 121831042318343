import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    InputBase,
    IconButton,
    TextField,
    Typography,
    InputLabel,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Delete } from '../../../../../../components/Icons';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import nbLocale from 'date-fns/locale/nb';
import { withStyles } from '@mui/styles';
import { ThemeProvider } from '@emotion/react';
import { dateTheme } from '../../../theme';

const filter = createFilterOptions();

export const CustomRow = withStyles(() => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f3f3f3',
            borderTop: '1px solid #EBEBEB',
            borderBottom: '1px solid #EBEBEB',
        },
    },
}))(TableRow);

export const CustomCell = withStyles(() => ({
    root: {
        padding: '0px 5px',
        borderBottom: '0px',
    },
    head: {
        padding: '10px 10px 0px 10px',
        fontWeight: 'bold',
    },
    body: {
        borderRight: '1px solid #E8E8E8',
        '&:last-child': {
            borderRight: '0px',
        },
    },
}))(TableCell);

export default function CustomCertifyDropDowns(props) {
    const { data, multiDropSelect, setMultiDropSelect, setIsAnswered } = props;

    const [selectedOption, setSelectedOption] = useState(null);
    const [certDate, setCertDate] = useState(new Date());
    const [expDate, setExpDate] = useState(new Date());
    const [certId, setCertId] = useState('');

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (multiDropSelect.length > 0) {
                setIsAnswered(true);
            } else {
                setIsAnswered(false);
            }
        }

        return () => {
            isMounted = true;
        };
    }, [multiDropSelect]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddAnswer = () => {
        let newArr = [];

        if (multiDropSelect.length !== 0) {
            newArr = multiDropSelect;
        }

        let newObj = {
            selectedOption: selectedOption.option,
            certDate: certDate,
            expDate: expDate,
            certId: certId,
        };

        newArr.push(newObj);

        setMultiDropSelect([...newArr]);

        setSelectedOption(null);
        setCertDate(new Date());
        setExpDate(new Date());
        setCertId('');
    };

    const handleRemoveAnswer = index => {
        let newArr = multiDropSelect;

        newArr.splice(index, 1);

        setMultiDropSelect([...newArr]);
    };

    return (
        <Grid item xs={12}>
            <Grid item xs={12} sx={{ display: 'flex' }}>
                <Grid item xs={4} sx={{ marginRight: '10px' }}>
                    <InputLabel>Sertifisering</InputLabel>
                    <Autocomplete
                        value={selectedOption}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setSelectedOption({
                                    id: 'other',
                                    option: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setSelectedOption({
                                    id: 'other',
                                    option: newValue.inputValue,
                                });
                            } else {
                                setSelectedOption(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(option => inputValue === option.option);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    option: `Legg til "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={data}
                        getOptionLabel={option => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.option;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.option}</li>}
                        freeSolo
                        renderInput={params => <TextField {...params} label="" placeholder="Søk eller legg til..." />}
                    />
                    <Typography variant="body2">
                        <i>Trykk "legg til" for å legge til i lista</i>
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={{ marginRight: '10px' }}>
                    <InputLabel>Dato for sertifisering</InputLabel>
                    <ThemeProvider theme={dateTheme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={nbLocale}>
                            <DatePicker
                                mask="__.__.____"
                                clearable
                                renderInput={params => {
                                    return (
                                        <TextField
                                            sx={{ width: '100%' }}
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: 'dd/mm/yyyy',
                                            }}
                                        />
                                    );
                                }}
                                value={certDate}
                                onChange={newValue => {
                                    setCertDate(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={2} sx={{ marginRight: '10px' }}>
                    <InputLabel>Utløpsdato</InputLabel>
                    <ThemeProvider theme={dateTheme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={nbLocale}>
                            <DatePicker
                                mask="__.__.____"
                                clearable
                                renderInput={params => {
                                    return (
                                        <TextField
                                            sx={{ width: '100%' }}
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: 'dd/mm/yyyy',
                                            }}
                                        />
                                    );
                                }}
                                value={expDate}
                                onChange={newValue => {
                                    setExpDate(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={4} sx={{ marginRight: '10px' }}>
                    <InputLabel>Sertifisering-ID</InputLabel>
                    <InputBase
                        id="cert-id"
                        type="text"
                        className="input"
                        name="certId"
                        placeholder="Skriv in ID"
                        value={certId}
                        onChange={e => setCertId(e.target.value)}
                    />
                </Grid>
                <Grid item sx={{ marginTop: '32px' }}>
                    <Button
                        variant="contained"
                        sx={{ width: '130px' }}
                        onClick={handleAddAnswer}
                        disabled={certId === '' || selectedOption === null ? true : false}
                    >
                        Legg til
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <CustomRow>
                                <CustomCell sx={{ width: '30%' }}>Sertifisering</CustomCell>
                                <CustomCell sx={{ width: '20%' }}>Dato for sertifisering</CustomCell>
                                <CustomCell sx={{ width: '20%' }}>Utløpsdato</CustomCell>
                                <CustomCell sx={{ width: '30%' }}>Sertifisering-ID</CustomCell>
                                <CustomCell align="center">Slett</CustomCell>
                            </CustomRow>
                        </TableHead>
                        <TableBody>
                            {multiDropSelect && multiDropSelect.length !== 0 ? (
                                multiDropSelect.map((answer, index) => {
                                    let certDate = new Date(answer.certDate);
                                    let expDate = new Date(answer.expDate);

                                    return (
                                        <CustomRow sx={{ borderTop: '1px solid #EBEBEB' }} key={index}>
                                            <CustomCell>{answer.selectedOption}</CustomCell>
                                            <CustomCell>
                                                {certDate.getDate()}/{certDate.getMonth() + 1}/{certDate.getFullYear()}
                                            </CustomCell>
                                            <CustomCell>
                                                {expDate.getDate()}/{expDate.getMonth() + 1}/{expDate.getFullYear()}
                                            </CustomCell>
                                            <CustomCell>{answer.certId}</CustomCell>
                                            <CustomCell align="center">
                                                <IconButton onClick={() => handleRemoveAnswer(index)}>
                                                    {Delete}
                                                </IconButton>
                                            </CustomCell>
                                        </CustomRow>
                                    );
                                })
                            ) : (
                                <CustomRow sx={{ marginTop: '10px' }}>
                                    <CustomCell>Du har ikke lagt til noen alternativ.</CustomCell>
                                </CustomRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
