import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import {
    Grid,
    Paper,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Button,
    Divider,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    IconButton,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { NumberInput } from '../../../../../components/InputForm';
import Option from './Option';

import { API, graphqlOperation } from 'aws-amplify';
import { updateNSRSQuestion } from '../../../../../graphql/mutations';

export default function QuestionLayoutEdit(props) {
    const { id, formData, showPopup, updateCategories } = props;

    const [rowOpen, setRowOpen] = useToggle();
    const [optionDialogOpen, setOptionDialogOpen] = useToggle();

    const [data, setData] = useState([]);

    const [openedIndex, setOpenedIndex] = useState(0);

    const [label, setLabel] = useState('');
    const [type, setType] = useState('');
    const [order, setOrder] = useState(1);
    const [maxChars, setMaxChars] = useState(0);
    const [isRequired, setIsRequired] = useState(true);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (formData) {
                setData(JSON.parse(formData));
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateQuestion = newData => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateNSRSQuestion, {
                    input: {
                        id: id,
                        data: JSON.stringify(newData),
                    },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated question: ', data);
                }

                resolve();
            });
        });
    };

    const handleAddNewRow = async () => {
        let newArr = data;

        let newObj = {
            id: uuid(),
            order: newArr.length + 1,
            elements: [],
        };

        newArr.push(newObj);

        setData([...newArr]);

        await updateQuestion(newArr).catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        await updateCategories();
    };

    const handleRemoveRow = async index => {
        let newArr = data;

        for (const row of newArr) {
            if (row.order >= index + 1) {
                row.order = row.order - 1;
            }
        }

        newArr.splice(index, 1);

        setData([...newArr]);

        await updateQuestion(newArr).catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        await updateCategories();
    };

    const handleAddNewElement = async () => {
        if (type === '' || label === '') {
            showPopup('Alle felter må være fylt ut!');
            return;
        }

        let newObj = {
            id: uuid(),
            order: order,
            type: type,
            label: label,
            maxChars: maxChars,
            isRequired: isRequired,
        };

        let newArr = data;

        data[openedIndex].elements.push(newObj);

        setData([...newArr]);

        await updateQuestion(newArr).catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        await updateCategories();

        handleCloseNewOptionDialog();
    };

    const handleOpenOptionDialog = index => {
        setOpenedIndex(index);

        setOptionDialogOpen();
    };

    const handleCloseNewOptionDialog = () => {
        setOpenedIndex(0);
        setLabel('');
        setType('');
        setOrder(1);
        setMaxChars(0);
        setIsRequired(true);

        setOptionDialogOpen();
    };

    const newOptionDialog = () => {
        return (
            <Grid>
                <Dialog open={optionDialogOpen} onClose={handleCloseNewOptionDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Legg til nytt svarfelt</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Legg til ny svarfelt basert på type.
                        </DialogContentText>
                        <NumberInput
                            label="Listenummer (blir brukt for rekkefølge)"
                            placeholder="Skriv inn nummer..."
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                        <InputForm
                            label="Navn på feltet"
                            placeholder="Skriv inn hva feltet heter..."
                            value={label}
                            onChange={e => setLabel(e.target.value)}
                        />
                        <InputLabel id="Type">Type alternativ</InputLabel>
                        <Select labelId="Type" value={type} label="Type felt" onChange={e => setType(e.target.value)}>
                            <MenuItem value={'Text'}>Tekstfelt</MenuItem>
                            <MenuItem value={'Textfield'}>Stor tekstfelt</MenuItem>
                            <MenuItem value={'Number'}>Nummerfelt</MenuItem>
                            <MenuItem value={'RadioGroup'}>Radio Gruppe</MenuItem>
                            <MenuItem value={'MultipleChoice'}>Flervalg</MenuItem>
                            <MenuItem value={'DateTime'}>Dato</MenuItem>
                            <MenuItem value={'Select'}>Select med ett svar</MenuItem>
                            <MenuItem value={'MultipleSelect'}>Select med flere svar</MenuItem>
                            <MenuItem value={'MultipleSelectsWithInputs'}>
                                Flere selects med støtte for input felter
                            </MenuItem>
                            <MenuItem value={'AddRows'}>'Legg til' svar med custom felter</MenuItem>
                            <MenuItem value={'DisablingCheckbox'}>
                                Svarboks som deaktiverer resten av alternativene
                            </MenuItem>
                            <MenuItem value={'CustomProductBrands'}>Custom: Produkt og varemerker</MenuItem>
                            <MenuItem value={'CustomOpportunities'}>Custom: Muligheter</MenuItem>
                            <MenuItem value={'CustomRisks'}>Custom: Risiko</MenuItem>
                        </Select>
                        {type === 'Text' || type === 'Textfield' || type === 'Number' ? (
                            <NumberInput
                                label="Maks tegn (La være 0 hvis det ikke skal bli brukt)"
                                placeholder="Skriv inn nummer..."
                                value={maxChars}
                                onChange={e => setMaxChars(e.target.value)}
                                min={0}
                            />
                        ) : (
                            <></>
                        )}
                        {type === 'Text' || type === 'Textfield' || type === 'Number' ? (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch checked={isRequired} onChange={e => setIsRequired(e.target.checked)} />
                                    }
                                    label="Er det påkrevd å skrive i feltet?"
                                />
                            </FormGroup>
                        ) : (
                            <></>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseNewOptionDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleAddNewElement}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid>
            {newOptionDialog()}
            <Paper sx={{ padding: '20px', marginBottom: '10px' }}>
                <ListItem disablePadding>
                    <ListItemButton onClick={setRowOpen}>
                        <ListItemIcon>
                            <DehazeIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Endre oppsett'} />
                    </ListItemButton>
                </ListItem>
                {!rowOpen ? (
                    <></>
                ) : (
                    <Grid>
                        <Divider />
                        {data &&
                            data
                                .sort((a, b) => a.order - b.order)
                                .map((row, rowIndex) => {
                                    return (
                                        <Grid sx={{ margin: '20px' }} key={row.id}>
                                            <Typography>
                                                <b>Rad {row.order}</b>
                                                {row.elements && row.elements.length !== 3 ? (
                                                    <IconButton onClick={() => handleOpenOptionDialog(rowIndex)}>
                                                        <AddCircleOutlineOutlinedIcon />
                                                    </IconButton>
                                                ) : (
                                                    <></>
                                                )}
                                                <IconButton
                                                    onClick={() => handleRemoveRow(rowIndex)}
                                                    sx={{ marginLeft: '-5px' }}
                                                >
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </Typography>
                                            <List>
                                                {row.elements &&
                                                    row.elements
                                                        .sort((a, b) => a.order - b.order)
                                                        .map((element, eleIndex) => {
                                                            return (
                                                                <Option
                                                                    key={element.id}
                                                                    data={element}
                                                                    arrayData={data}
                                                                    updateQuestion={updateQuestion}
                                                                    updateCategories={updateCategories}
                                                                    rowIndex={rowIndex}
                                                                    eleIndex={eleIndex}
                                                                    showPopup={showPopup}
                                                                />
                                                            );
                                                        })}
                                            </List>
                                        </Grid>
                                    );
                                })}
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{
                                padding: '20px',
                                margin: '10px',
                                width: '200px',
                            }}
                            onClick={handleAddNewRow}
                        >
                            Legg til ny rad{' '}
                        </Button>
                        <Divider />
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
}
