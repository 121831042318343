import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { TextAreaInput, NumberInput } from '../../../../../components/InputForm';
import Category from './Category';

import { API, graphqlOperation } from 'aws-amplify';
import { createNSRSCategory } from '../../../../../graphql/mutations';

export default function CategoryList(props) {
    const { showPopup, updateCategories, categories } = props;

    const [open, setOpen] = useToggle();

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [order, setOrder] = useState(1);

    const saveNewCategory = async () => {
        return new Promise(async (resolve, reject) => {
            // Input data for creating a new CompanyData object
            let inputData = {
                id: id,
                name: name,
                description: description,
                order: order,
            };

            await API.graphql(graphqlOperation(createNSRSCategory, { input: inputData }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new category: ', data.data.createNSRSCategory);
                    }

                    resolve(data.data.createNSRSCategory);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Failed to create new category: ', err);
                    }

                    reject(err);
                });
        });
    };

    const handleSaveNewCategory = async () => {
        if (id === '' || name === '' || description === '') {
            showPopup('Alle felter må være fylt ut!');
            return;
        }

        await saveNewCategory().catch(() => {
            showPopup('En feil oppsto ved lagring av kategori');
            return;
        });

        showPopup('Kategorien har blitt lagret');

        await updateCategories();

        handleOpenDialog();
    };

    const handleOpenDialog = () => {
        setId('');
        setName('');
        setDescription('');
        setOrder(1);

        setOpen();
    };

    const newCategoryDialog = () => {
        return (
            <Grid>
                <Dialog open={open} onClose={handleOpenDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Lag ny kategori</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>Lag en helt ny kategori.</DialogContentText>
                        <InputForm
                            label="ID - Kan ikke redigeres senere!"
                            placeholder="Skriv inn ID..."
                            value={id}
                            onChange={e => setId(e.target.value)}
                        />
                        <InputForm
                            label="Navn"
                            placeholder="Skriv inn navn..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Beskrivelse"
                            placeholder="Skriv inn beskrivelse..."
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                        <NumberInput
                            label="Listenummer (blir brukt for rekkefølge)"
                            placeholder="Skriv inn nummer..."
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleOpenDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleSaveNewCategory}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid item xs={12}>
            {newCategoryDialog()}
            <h1>Liste over lagret NSRS kategorier</h1>
            <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                sx={{ padding: '20px', marginBottom: '20px', width: '200px' }}
                onClick={setOpen}
            >
                Legg til ny NSRS kategori{' '}
            </Button>
            <List>
                {categories.length === 0 ? (
                    <>Det er ingen lagret NSRS kategorier.</>
                ) : (
                    categories &&
                    categories
                        .sort((a, b) => a.order - b.order)
                        .map(category => {
                            return (
                                <Category
                                    key={category.id}
                                    showPopup={showPopup}
                                    data={category}
                                    updateCategories={updateCategories}
                                />
                            );
                        })
                )}
            </List>
        </Grid>
    );
}
