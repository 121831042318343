import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, TableContainer, Table, TableBody, Button, Divider } from '@mui/material';
import { NavLink } from 'react-router-dom';
import useRouter from '../../../../hooks/useRouter';
import Summary from './Summary';
import FormQuestion from './FormQuestion';
import { useAuth } from '../../../../auth/useAuth';
import { useStyles } from '../CertificationStyles';
import { API } from 'aws-amplify';
import { onCertResponseByCompanyIdAndCategoryId } from '../../../../graphql/subscriptions';
import { ArrowRight, Arrow } from '../../../../components/Icons';
import CompleteCertification from './CompleteCertification';

export default function Form(props) {
    const { categories, responses, fetchResponses, colorCode, addNewResponse, users, updateTasks, tasks } = props;

    const router = useRouter();
    const auth = useAuth();
    const classes = useStyles();

    const [openStates, setOpenStates] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    useEffect(() => {
        let isMounted = false;
        let subscription;

        if (!isMounted) {
            // Subscribe to updates
            subscription = API.graphql({
                query: onCertResponseByCompanyIdAndCategoryId,
                variables: {
                    companyId: auth.companyData.companyId,
                    categoryId: categories.id,
                },
            }).subscribe({
                next: ({ provider, value }) => {
                    fetchResponses();
                },
                error: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(error);
                    }
                },
            });
        }

        return () => {
            isMounted = true;
            subscription.unsubscribe();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            setStates();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const setStates = async () => {
        let newArr = [];

        for (const subcategory of categories.subcategories.items.sort((a, b) => {
            return a.order - b.order;
        })) {
            for (const question of subcategory.questions.items.sort((a, b) => {
                return a.order - b.order;
            })) {
                let newObj = {
                    id: question.id,
                    state: false,
                };

                if (router.query.criteria) {
                    if (router.query.criteria === question.id) {
                        newObj.state = true;
                    }
                }

                newArr.push(newObj);
            }
        }

        setOpenStates([...newArr]);
    };

    const handleOpenQuestion = id => {
        let index = openStates.findIndex(state => state.id === id);
        let newArr = openStates;

        newArr[index].state = !newArr[index].state;

        setOpenStates([...newArr]);
    };

    const handleNextQuestion = id => {
        let index = openStates.findIndex(state => state.id === id);
        let newArr = openStates;

        newArr[index].state = !newArr[index].state;

        if (index + 1 !== openStates.length) {
            newArr[index + 1].state = !newArr[index + 1].state;
        }

        setOpenStates([...newArr]);
    };

    const handleNextButton = () => {
        if (categories.id === 'company') {
            router.push('/declaration/economy');
            router.history.go(0);
        } else if (categories.id === 'economy') {
            router.push('/declaration/social');
            router.history.go(0);
        } else if (categories.id === 'social') {
            router.push('/declaration/environment');
            router.history.go(0);
        } else if (categories.id === 'environment') {
            return handleOpenDialog();
        }
    };

    const handlePreviousButton = () => {
        if (categories.id === 'economy') {
            router.push('/declaration/company');
            router.history.go(0);
        } else if (categories.id === 'social') {
            router.push('/declaration/economy');
            router.history.go(0);
        } else if (categories.id === 'environment') {
            router.push('/declaration/social');
            router.history.go(0);
        }
    };

    return (
        <Paper elevation={1}>
            <CompleteCertification
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                responses={responses}
            />
            <Summary data={categories} responses={responses} />
            {categories.subcategories &&
                categories.subcategories.items
                    .sort((a, b) => {
                        return a.order - b.order;
                    })
                    .map((subcategory, index) => {
                        return (
                            <Grid key={subcategory.id}>
                                <Grid item xs={12}>
                                    <Typography variant="h1" className={classes.subName} id={index + 1}>
                                        {subcategory.name}
                                    </Typography>
                                </Grid>
                                <TableContainer>
                                    <Table aria-label="collapsible table">
                                        <TableBody>
                                            {subcategory.questions.items &&
                                                openStates.length !== 0 &&
                                                subcategory.questions.items
                                                    .sort((a, b) => {
                                                        return a.order - b.order;
                                                    })
                                                    .map(question => {
                                                        return (
                                                            <FormQuestion
                                                                key={question.id}
                                                                data={question}
                                                                response={responses.find(response => {
                                                                    return response.questionId === question.id;
                                                                })}
                                                                catId={categories.id}
                                                                colorCode={colorCode}
                                                                openState={openStates.find(state => {
                                                                    return state.id === question.id;
                                                                })}
                                                                openQuestion={handleOpenQuestion}
                                                                openNextQuestion={handleNextQuestion}
                                                                fetchResponses={fetchResponses}
                                                                addNewResponse={addNewResponse}
                                                                users={users}
                                                                updateTasks={updateTasks}
                                                                tasks={tasks}
                                                            />
                                                        );
                                                    })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        );
                    })}
            <Divider sx={{ margin: '30px 0px' }} />
            <Grid item xs={12} className={classes.bottomNav}>
                {categories.id !== 'environment' ? (
                    <>
                        <Button
                            variant="contained"
                            className={classes.back}
                            component={NavLink}
                            to="/declaration"
                            exact
                        >
                            Tilbake til dashboard
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            variant="contained"
                            className={classes.back}
                            component={NavLink}
                            to="/declaration"
                            exact
                        >
                            Tilbake til dashboard
                        </Button>
                    </>
                )}
                {categories.id === 'company' ? (
                    <></>
                ) : (
                    <Button
                        variant="contained"
                        sx={{ marginRight: '20px' }}
                        className={classes.nextButton}
                        onClick={() => handlePreviousButton()}
                    >
                        {Arrow}
                        <Grid sx={{ marginLeft: '8px' }}>Forrige</Grid>
                    </Button>
                )}
                {auth.companyData.companyData.certData.items[0].status === 'Completed' &&
                categories.id === 'environment' ? (
                    <></>
                ) : categories.id === 'environment' ? (
                    <>
                        <Button
                            variant="contained"
                            className={classes.saveButton}
                            component={NavLink}
                            to="/declaration"
                            exact
                        >
                            Lagre og avslutt
                        </Button>
                    </>
                ) : (
                    <Button variant="contained" className={classes.nextButton} onClick={() => handleNextButton()}>
                        Neste
                        {ArrowRight}
                    </Button>
                )}
            </Grid>
        </Paper>
    );
}
