import React, { useState, useEffect } from 'react';
import {
    Paper,
    Grid,
    Typography,
    Avatar,
    IconButton,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    Chip,
    TableRow,
    Button,
} from '@mui/material';
import { useStyles } from '../ProfileStyles';
import { Delete, FirmHeader } from '../../../../components/Icons';
import { useAuth } from '../../../../auth/useAuth';
import { fetchCompanyUsers, approveUser, removeUser } from '../profileFunctions';
import useToggle from '../../../../hooks/useToggle';
import SnackPopup from '../../../../components/SnackPopup';

export default function CompanyUsersView() {
    const classes = useStyles();
    const auth = useAuth();

    const [popupState, setPopupState] = useToggle();
    const [popupMsg, setPopupMsg] = useState('');
    const [users, setUsers] = useState([]);

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState();
    };

    const handleClosePopup = () => {
        setPopupState();
    };

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            fetchCompanyUsers(auth.companyData.companyId).then(data => {
                setUsers([...data]);
            });
        }

        return () => {
            isMounted = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleApproveUser = async (event, userData) => {
        event.preventDefault();

        await approveUser(userData, auth.companyData)
            .then(() => {
                showPopup(JSON.parse(userData.name).fullName + ' har blitt godkjent');

                fetchCompanyUsers(auth.companyData.companyId).then(data => {
                    setUsers([...data]);
                });
            })
            .catch(() => {
                showPopup('En feil har oppstått, vennligst prøv igjen eller kontakt support om feilen gjenstår');
            });
    };

    const handleRemoveUser = async (event, userData) => {
        event.preventDefault();

        await removeUser(userData, auth.companyData)
            .then(() => {
                showPopup(JSON.parse(userData.name).fullName + ' har blitt fjernet');

                fetchCompanyUsers(auth.companyData.companyId).then(data => {
                    setUsers([...data]);
                });
            })
            .catch(() => {
                showPopup('En feil har oppstått, vennligst prøv igjen eller kontakt support om feilen gjenstår');
            });
    };

    return (
        <Paper style={{ marginBottom: '20px' }}>
            <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
            <Grid item xs={12} className={classes.pageHeader}>
                <Typography variant="h2">{FirmHeader} Brukere</Typography>
            </Grid>
            <Grid item xs={12} className={classes.spacer}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.TableHeadLabel}>
                                    Avatar
                                </TableCell>
                                <TableCell className={classes.TableHeadSize}>Navn</TableCell>
                                <TableCell className={classes.TableHeadSize}>Stilling</TableCell>
                                <TableCell className={classes.TableHeadSize}>Email</TableCell>
                                <TableCell className={classes.TableHeadSize}>Nummer</TableCell>
                                <TableCell align="center" className={classes.TableHeadLabel}>
                                    Rettigheter
                                </TableCell>
                                <TableCell align="center" style={{ width: '10%', fontSize: '1.1em' }}>
                                    Status
                                </TableCell>
                                {!auth.isAdmin ? (
                                    <></>
                                ) : (
                                    <TableCell align="center" className={classes.TableHeadLabel}>
                                        Slett
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users &&
                                users.map(user => {
                                    return (
                                        <TableRow key={user.id}>
                                            <TableCell>
                                                <Avatar className={classes.avatar}>
                                                    {JSON.parse(user.name)
                                                        .fullName.match(/\b\w/g)
                                                        .join('')}
                                                </Avatar>
                                            </TableCell>
                                            <TableCell>{JSON.parse(user.name).fullName}</TableCell>
                                            <TableCell>{user.position}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.phoneNumber}</TableCell>
                                            <TableCell>
                                                {auth.companyData.companyData.admins.includes(user.id) ? (
                                                    <Chip label="Admin" sx={{ backgroundColor: '#F3CCD1' }}></Chip>
                                                ) : (
                                                    <Chip label="Bruker" sx={{ backgroundColor: '#C1E6EB' }}></Chip>
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {user.status === 'RequestingAccess' ? (
                                                    !auth.isAdmin ? (
                                                        <Chip
                                                            label="Ikke godkjent"
                                                            style={{ width: '90px', fontSize: '1.1em' }}
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            sx={{ backgroundColor: '#CEBEDD' }}
                                                            onClick={e => handleApproveUser(e, user)}
                                                        >
                                                            Godkjenn bruker?
                                                        </Button>
                                                    )
                                                ) : (
                                                    <Chip label="Godkjent" sx={{ backgroundColor: '#C3E8D0' }} />
                                                )}
                                            </TableCell>
                                            {!auth.isAdmin ? (
                                                <></>
                                            ) : (
                                                <TableCell align="center">
                                                    {auth.companyData.companyData.admins.includes(user.id) ? (
                                                        <></>
                                                    ) : (
                                                        <IconButton onClick={e => handleRemoveUser(e, user)}>
                                                            {Delete}
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Paper>
    );
}
