import * as React from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function ProgressBars(props) {
  return (
    <LinearProgress
      sx={{
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: "#EDEDED",
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: props.backgroundColor,
        },
      }}
      variant="determinate"
      value={props.value}
    />
  );
}
