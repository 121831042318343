import React, { useState } from 'react';
import { Grid, Typography, Box, InputBase, InputAdornment, Button, IconButton } from '@mui/material';
import { Mail, Key, Arrow } from '../../../components/Icons';
import { useAuth } from '../../../auth/useAuth';
import useRouter from '../../../hooks/useRouter';
import useToggle from '../../../hooks/useToggle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SnackPopup from '../../../components/SnackPopup.jsx';
import { Loading } from '../../../components/Loading';
import { useStyles } from '../Login/LoginStyle';
import { theme } from '../PublicTheme.jsx';
import { ThemeProvider } from '@emotion/react';

export default function ForgotPassword() {
    const classes = useStyles();
    const router = useRouter();
    const auth = useAuth();
    const [popupState, setPopupState] = useToggle();
    const [popupMsg, setPopupMsg] = useState('');
    const [isLoading, setIsLoading] = useToggle();
    const [state, setState] = useToggle(true);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [passwordValues, setPasswordValues] = useState({
        password: '',
        newPassword: '',
        showPassword: false,
    });

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState();
    };

    const handleClosePopup = () => {
        setPopupState();
    };

    const handleClickShowPassword = () => {
        setPasswordValues({
            ...passwordValues,
            showPassword: !passwordValues.showPassword,
        });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const handleStartResetPasswordFlow = event => {
        event.preventDefault();

        setIsLoading();

        auth.startPasswordReset(email)
            .then(() => {
                showPopup('En kode har blitt sendt til din e-post.');
                setIsLoading();
                setState();
            })
            .catch(err => {
                // Failed to send email with new password code
                errorHandling(err.code);
                setIsLoading();
            });
    };

    const handleFinishResetPassword = event => {
        event.preventDefault();

        if (passwordValues.password !== passwordValues.newPassword) {
            showPopup('Passordene stemmer ikke.');
            return;
        }

        setIsLoading();

        auth.finishPasswordReset(email, code, passwordValues.password)
            .then(() => {
                router.push('/login?reset=true');
            })
            .catch(err => {
                // Failed to send email with new password code
                errorHandling(err.code);
                setIsLoading();
            });
    };

    // Error handling for forgot password
    const errorHandling = errCode => {
        if (errCode === 'PasswordMismatch') {
            // Password fields don't match
            showPopup('Passordene må være like.');
        } else if (errCode === 'ExpiredCodeException') {
            // Confirmation code expired
            showPopup('Koden for bekreftelse har utgått. Vennligst kjør prosessen på nytt.');
        } else if (errCode === 'CodeMismatchException') {
            // Incorrect confirmation code
            showPopup('Koden stemmer ikke. Bekreft at den er skrevet riktig.');
        } else if (errCode === 'LimitExceededException' || errCode === 'TooManyRequestsException') {
            // User request exceeded backend limits
            showPopup('Du har overskredet forespørselsgrensen. Prøv igjen senere.');
        } else if (errCode === 'NotAuthorizedException') {
            // User is not authorized to do this
            showPopup('En feil har skjedd ved autorisering.');
        } else if (errCode === 'InvalidPasswordException') {
            // Invalid password
            showPopup('Ugyldig passord. Den må inneholde minst 8 tegn.');
        } else if (errCode === 'InvalidParameterException') {
            // Incorrect value in a field
            showPopup('Ugyldig verdier. Sjekk om feltene er riktig skrevet.');
        } else if (errCode === 'CodeDeliveryFailureException' || errCode === 'NoUser') {
            // AWS failing to deliver a code to the user
            showPopup(
                'Det har skjedd en feil ved levering av bekreftelseskode. Hvis dette fortsetter, vennligst kontakt support.',
            );
        }
    };

    const emailView = (
        <Grid>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
                Tast inn emailen til brukeren du ønsker å endre passord for
            </Typography>
            <form autoComplete="on">
                <InputBase
                    required
                    error
                    id="Username"
                    type="email"
                    placeholder="Epost..."
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    startAdornment={<InputAdornment position="start">{Mail}</InputAdornment>}
                />
                {isLoading ? (
                    <Loading color="primary" />
                ) : (
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            backgroundColor: '#F3CCD1',
                            margin: '10px 0px',
                        }}
                        onClick={handleStartResetPasswordFlow}
                    >
                        Ok
                    </Button>
                )}
            </form>
        </Grid>
    );

    const passwordView = (
        <form autoComplete="off">
            <InputBase
                required
                autoComplete="one-time-code"
                autoFocus={false}
                error
                id="NewCode"
                type="number"
                placeholder="Kode..."
                value={code}
                onChange={e => setCode(e.target.value)}
                startAdornment={<InputAdornment position="start">{Key}</InputAdornment>}
            />
            <InputBase
                required
                error
                autoComplete="new-password-1"
                id="NewPassword"
                type={passwordValues.showPassword ? 'text' : 'password'}
                placeholder="Nytt passord..."
                value={passwordValues.password}
                onChange={e =>
                    setPasswordValues({
                        ...passwordValues,
                        password: e.target.value,
                    })
                }
                startAdornment={<InputAdornment position="start">{Key}</InputAdornment>}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {passwordValues.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <InputBase
                required
                error
                autoComplete="new-password-2"
                id="Confirm"
                type={passwordValues.showPassword ? 'text' : 'password'}
                placeholder="Bekreft nytt passord..."
                value={passwordValues.newPassword}
                onChange={e =>
                    setPasswordValues({
                        ...passwordValues,
                        newPassword: e.target.value,
                    })
                }
                startAdornment={<InputAdornment position="start">{Key}</InputAdornment>}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {passwordValues.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {isLoading ? (
                <Loading color="primary" />
            ) : (
                <Button
                    type="submit"
                    variant="contained"
                    style={{
                        backgroundColor: '#F3CCD1',
                        margin: '10px 0px',
                    }}
                    onClick={handleFinishResetPassword}
                >
                    Ok
                </Button>
            )}
        </form>
    );

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.box} style={{ backgroundColor: '#BBCBE4' }}>
                <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
                <Button
                    variant="contained"
                    startIcon={Arrow}
                    className={classes.topButton}
                    onClick={() => router.push('/')}
                >
                    Gå tilbake
                </Button>
                <Grid item xs={12} className={classes.content}>
                    <Typography variant="h1">Nytt passord</Typography>
                    {state ? emailView : passwordView}
                </Grid>
            </Box>
        </ThemeProvider>
    );
}
