import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import { Grid } from '@mui/material';
import { AuthProvider } from './auth/useAuth';
import Router from './components/router/Router';

import { poolWebClientId, amplitudeBool } from './helpers/globalValues';

// Amplitude tracking
import amplitude from 'amplitude-js';

import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';
Amplify.configure(awsmobile);

Amplify.configure({
    Auth: {
        // OBS: Edit webclient to match existing user pool
        userPoolWebClientId: poolWebClientId,
    },
});

if (process.env.NODE_ENV !== 'development' && amplitudeBool) {
    amplitude.getInstance().init('71b3f59f21847ef0841a34f7ea3404aa');
}

export default function App() {
    return (
        <Grid container className="root">
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <Router />
                </ThemeProvider>
            </AuthProvider>
        </Grid>
    );
}
