import { API, graphqlOperation } from 'aws-amplify';

import { getCertCategory, listCertCategorys, listCertResponses } from '../../../graphql/queries';
import {
    createCertResponse,
    createCompanyCertHistoryData,
    updateCertResponse,
    updateCompanyCertPublicProfile,
    updateCompanyCertData,
} from '../../../graphql/mutations';

import amplitude from 'amplitude-js';

/** LOCAL FUNCTIONS **/

// Fetches all responses
const fetchResponses = async (companyId, token) => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listCertResponses, {
                filter: { companyId: { eq: companyId } },
                nextToken: token,
            }),
        )
            .then(async data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Fetches all responses for a specific category
const fetchCategoryResponses = (categoryId, companyId, token) => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listCertResponses, {
                filter: { categoryId: { eq: categoryId }, companyId: { eq: companyId } },
                nextToken: token,
            }),
        )
            .then(async data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Updates public profile
const updatePublicProfile = async inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateCompanyCertPublicProfile, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Updates the company's certification data
const updateCompanyCertificationData = async inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateCompanyCertData, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Creates new history object
const createHistoryObject = inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createCompanyCertHistoryData, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Creates a new response
const createResponse = async inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createCertResponse, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Updates an existing response
const updateResponse = inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateCertResponse, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

/** EXPORTED FUNCTIONS **/

// Fetches the category based on ID
export async function fetchCategory(catId) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(getCertCategory, { id: catId }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully fetched category from backend: ', data.data.getCertCategory);
                }

                resolve(data.data.getCertCategory);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching category from backend: ', err);
                }

                reject(err);
            });
    });
}

// Fetches the category based on ID
export async function fetchFilteredCategory(catId, auth) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(getCertCategory, { id: catId }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully fetched category from backend: ', data.data.getCertCategory);
                }

                let catData = data.data.getCertCategory;
                let companyTypes = auth.companyData.companyData.certData.items[0].certFilterTypes;
                let employeeAmount = auth.companyData.companyData.certData.items[0].employeeAmount;

                for (let i = catData.subcategories.items.length - 1; i >= 0; i--) {
                    let subcatHasType = false;

                    for (let j = catData.subcategories.items[i].questions.items.length - 1; j >= 0; j--) {
                        let questionHasType = false;

                        if (
                            catData.subcategories.items[i].questions.items[j].filterType === 'None' ||
                            (catData.subcategories.items[i].questions.items[j].filterType !== 'None' &&
                                companyTypes.includes(catData.subcategories.items[i].questions.items[j].filterType)) ||
                            (catData.subcategories.items[i].questions.items[j].filterType === 'Employees' &&
                                employeeAmount > 3)
                        ) {
                            subcatHasType = true;
                            questionHasType = true;
                        }

                        if (!questionHasType) {
                            catData.subcategories.items[i].questions.items.splice(j, 1);
                        }
                    }

                    if (!subcatHasType) {
                        catData.subcategories.items.splice(i, 1);
                    }
                }

                resolve(catData);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching category from backend: ', err);
                }

                reject(err);
            });
    });
}

// Fetches a list of all categories from backend
export async function fetchAllCategories() {
    return new Promise(async resolve => {
        await API.graphql(graphqlOperation(listCertCategorys)).then(data => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Fetched all categories: ', data);
            }

            resolve(data.data.listCertCategorys.items);
        });
    });
}

// Fetches a list of all categories and sorts them out based on the company's certification filter types
export async function fetchFilteredCategories(auth) {
    return new Promise(async resolve => {
        await API.graphql(graphqlOperation(listCertCategorys)).then(data => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Fetched all filtered categories: ', data);
            }

            let catData = data.data.listCertCategorys.items;
            let companyTypes = auth.companyData.companyData.certData.items[0].certFilterTypes;
            let employeeAmount = auth.companyData.companyData.certData.items[0].employeeAmount;

            for (const category of catData) {
                for (let i = category.subcategories.items.length - 1; i >= 0; i--) {
                    let subcatHasType = false;

                    for (let j = category.subcategories.items[i].questions.items.length - 1; j >= 0; j--) {
                        let questionHasType = false;

                        if (
                            category.subcategories.items[i].questions.items[j].filterType === 'None' ||
                            (category.subcategories.items[i].questions.items[j].filterType !== 'None' &&
                                companyTypes.includes(category.subcategories.items[i].questions.items[j].filterType)) ||
                            (category.subcategories.items[i].questions.items[j].filterType === 'Employees' &&
                                employeeAmount > 3)
                        ) {
                            subcatHasType = true;
                            questionHasType = true;
                        }

                        if (!questionHasType) {
                            category.subcategories.items[i].questions.items.splice(j, 1);
                        }
                    }

                    if (!subcatHasType) {
                        category.subcategories.items.splice(i, 1);
                    }
                }
            }

            resolve(catData);
        });
    });
}

// Fetches all responses from company
export async function getAllResponses(companyId) {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchResponses(companyId, nextToken);
                const data = resp.data.listCertResponses.items;
                nextToken = resp.data.listCertResponses.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching all responses from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched all responses: ', dataArr);
        }

        resolve(dataArr);
    });
}

// Checks if data exists for the given question, if it does, fetch it
export async function getCategoryResponses(categoryId, companyId) {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchCategoryResponses(categoryId, companyId, nextToken);
                const data = resp.data.listCertResponses.items;
                nextToken = resp.data.listCertResponses.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching category responses from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched category responses: ', dataArr);
        }

        resolve(dataArr);
    });
}

// Updates the amount of employees displayed in cert data and public profile
export async function updateEmployeeAmount(auth, employeeAmount) {
    return new Promise(async (resolve, reject) => {
        let number = employeeAmount;
        let certDataId = auth.companyData.companyData.certData.items[0].id;
        let publicProfileId = auth.companyData.companyData.certData.items[0].publicProfile.id;

        if (isNaN(number) || number === '' || number === null || number === undefined) {
            number = 0;
        }

        let certDataInput = {
            id: certDataId,
            employeeAmount: number,
        };

        let publicProfileDataInput = {
            id: publicProfileId,
            employeeAmount: number,
        };

        await updateCompanyCertificationData(certDataInput)
            .then(async () => {
                await updatePublicProfile(publicProfileDataInput).then(async () => {
                    await auth.fetchCompanyInfo(auth.companyData.companyId).then(() => {
                        resolve();
                    });
                });
            })
            .catch(err => {
                reject(err);
            });
    });
}

// Updates the filter types in cert data and public profile
export async function updateCertFilterTypes(auth, type, bool) {
    return new Promise(async (resolve, reject) => {
        let certDataId = auth.companyData.companyData.certData.items[0].id;
        let publicProfileId = auth.companyData.companyData.certData.items[0].publicProfile.id;

        let certFilterTypes = auth.companyData.companyData.certData.items[0].certFilterTypes;
        let publicFilterTypes = auth.companyData.companyData.certData.items[0].publicProfile.certFilterTypes;

        if (bool) {
            if (certFilterTypes.includes(type) && publicFilterTypes.includes(type)) {
                resolve();
            } else {
                certFilterTypes.push(type);
                publicFilterTypes.push(type);
            }
        } else if (!bool) {
            if (certFilterTypes.includes(type) || publicFilterTypes.includes(type)) {
                certFilterTypes.splice(certFilterTypes.indexOf(type), 1);
                publicFilterTypes.splice(publicFilterTypes.indexOf(type), 1);
            } else {
                resolve();
            }
        }

        let certDataInput = {
            id: certDataId,
            certFilterTypes: certFilterTypes,
        };

        let publicProfileDataInput = {
            id: publicProfileId,
            certFilterTypes: publicFilterTypes,
        };

        await updateCompanyCertificationData(certDataInput)
            .then(async () => {
                await updatePublicProfile(publicProfileDataInput).then(async () => {
                    await auth.fetchCompanyInfo(auth.companyData.companyId).then(() => {
                        resolve();
                    });
                });
            })
            .catch(err => {
                reject(err);
            });
    });
}

// Checks if data exists for the given question, if it does, fetch it
export async function createNewResponse(auth, inputData, oldRespData) {
    return new Promise(async (resolve, reject) => {
        let userId = auth.userData.id;
        let publicProfileId = auth.companyData.companyData.certData.items[0].publicProfile.id;

        await createResponse(inputData)
            .then(async data => {
                if (process.env.NODE_ENV !== 'development') {
                    amplitude.getInstance().logEvent('criteria_answered', { questionId: inputData.questionId });
                }

                // Calculate total points earned for each category with the updated response
                let responses = await getAllResponses(auth.companyData.companyId);
                let categories = await fetchFilteredCategories(auth);

                let economyScore = 0;
                let socialScore = 0;
                let environmentalScore = 0;

                let totalEcoScore = 0;
                let totalSocialScore = 0;
                let totalEnvScore = 0;

                for (const category of categories) {
                    for (const subcategory of category.subcategories.items) {
                        for (const question of subcategory.questions.items) {
                            if (subcategory.categoryId === 'economy') {
                                totalEcoScore = totalEcoScore + question.pointValue;
                            } else if (subcategory.categoryId === 'social') {
                                totalSocialScore = totalSocialScore + question.pointValue;
                            } else if (subcategory.categoryId === 'environment') {
                                totalEnvScore = totalEnvScore + question.pointValue;
                            }

                            let index = responses.findIndex(response => response.questionId === question.id);

                            if (index !== -1) {
                                if (responses[index].isAnswered) {
                                    if (responses[index].pointsEarned > question.pointValue) {
                                        if (responses[index].categoryId === 'economy') {
                                            economyScore = economyScore + question.pointValue;
                                        } else if (responses[index].categoryId === 'social') {
                                            socialScore = socialScore + question.pointValue;
                                        } else if (responses[index].categoryId === 'environment') {
                                            environmentalScore = environmentalScore + question.pointValue;
                                        }
                                    } else {
                                        if (responses[index].categoryId === 'economy') {
                                            economyScore = economyScore + responses[index].pointsEarned;
                                        } else if (responses[index].categoryId === 'social') {
                                            socialScore = socialScore + responses[index].pointsEarned;
                                        } else if (responses[index].categoryId === 'environment') {
                                            environmentalScore = environmentalScore + responses[index].pointsEarned;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                let totalEarnedScore = economyScore + socialScore + environmentalScore;

                let economyPercentScore = Math.ceil((100 / totalEcoScore) * economyScore);
                let socialPercentScore = Math.ceil((100 / totalSocialScore) * socialScore);
                let envPercentScore = Math.ceil((100 / totalEnvScore) * environmentalScore);

                let totalScore = totalEcoScore + totalSocialScore + totalEnvScore;
                let totalPercentScore = Math.ceil((100 / totalScore) * totalEarnedScore);

                // Update public profile with the calculated new points
                let publicProfileInputData = {
                    id: publicProfileId,
                    economyScore: economyScore,
                    economyPercentScore: economyPercentScore,
                    socialScore: socialScore,
                    socialPercentScore: socialPercentScore,
                    environmentalScore: environmentalScore,
                    environmentalPercentScore: envPercentScore,
                    totalScore: totalEarnedScore,
                    totalPercentScore: totalPercentScore,
                };

                await updatePublicProfile(publicProfileInputData).then(async () => {
                    // Create new history object for the user change
                    let newHistoryObj = {
                        // Required
                        companyId: inputData.companyId,
                        companyCertDataId: inputData.companyCertDataId,
                        action: 'CREATE_RESPONSE',
                        timestamp: new Date(),

                        // Optional
                        userId: userId,
                        description: 'Created new response for the first time for a question within a main category.',
                        categoryId: inputData.categoryId,
                        questionId: inputData.questionId,
                        newData: JSON.stringify(inputData),
                        oldData: JSON.stringify(oldRespData),
                        economyScore: economyScore,
                        economyPercentScore: economyPercentScore,
                        socialScore: socialScore,
                        socialPercentScore: socialPercentScore,
                        environmentalScore: environmentalScore,
                        environmentalPercentScore: envPercentScore,
                        totalScore: totalEarnedScore,
                        totalPercentScore: totalPercentScore,
                    };

                    await createHistoryObject(newHistoryObj).then(async () => {
                        await auth.fetchCompanyInfo(auth.companyData.companyId).then(() => {
                            resolve(data);
                        });
                    });
                });
            })
            .catch(err => {
                reject(err);
            });
    });
}

// Checks if data exists for the given question, if it does, fetch it
export async function saveResponse(auth, inputData, oldRespData) {
    return new Promise(async (resolve, reject) => {
        let userId = auth.userData.id;
        let publicProfileId = auth.companyData.companyData.certData.items[0].publicProfile.id;

        await updateResponse(inputData)
            .then(async data => {
                if (process.env.NODE_ENV !== 'development') {
                    amplitude.getInstance().logEvent('criteria_answered', { questionId: inputData.questionId });
                }

                // Calculate total points earned for each category with the updated response
                let responses = await getAllResponses(auth.companyData.companyId);
                let categories = await fetchFilteredCategories(auth);

                let economyScore = 0;
                let socialScore = 0;
                let environmentalScore = 0;

                let totalEcoScore = 0;
                let totalSocialScore = 0;
                let totalEnvScore = 0;

                for (const category of categories) {
                    for (const subcategory of category.subcategories.items) {
                        for (const question of subcategory.questions.items) {
                            if (subcategory.categoryId === 'economy') {
                                totalEcoScore = totalEcoScore + question.pointValue;
                            } else if (subcategory.categoryId === 'social') {
                                totalSocialScore = totalSocialScore + question.pointValue;
                            } else if (subcategory.categoryId === 'environment') {
                                totalEnvScore = totalEnvScore + question.pointValue;
                            }

                            let index = responses.findIndex(response => response.questionId === question.id);

                            if (index !== -1) {
                                if (responses[index].isAnswered) {
                                    if (responses[index].pointsEarned > question.pointValue) {
                                        if (responses[index].categoryId === 'economy') {
                                            economyScore = economyScore + question.pointValue;
                                        } else if (responses[index].categoryId === 'social') {
                                            socialScore = socialScore + question.pointValue;
                                        } else if (responses[index].categoryId === 'environment') {
                                            environmentalScore = environmentalScore + question.pointValue;
                                        }
                                    } else {
                                        if (responses[index].categoryId === 'economy') {
                                            economyScore = economyScore + responses[index].pointsEarned;
                                        } else if (responses[index].categoryId === 'social') {
                                            socialScore = socialScore + responses[index].pointsEarned;
                                        } else if (responses[index].categoryId === 'environment') {
                                            environmentalScore = environmentalScore + responses[index].pointsEarned;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                let totalEarnedScore = economyScore + socialScore + environmentalScore;

                let economyPercentScore = Math.ceil((100 / totalEcoScore) * economyScore);
                let socialPercentScore = Math.ceil((100 / totalSocialScore) * socialScore);
                let envPercentScore = Math.ceil((100 / totalEnvScore) * environmentalScore);

                let totalScore = totalEcoScore + totalSocialScore + totalEnvScore;
                let totalPercentScore = Math.ceil((100 / totalScore) * totalEarnedScore);

                // Update public profile with the calculated new points
                let publicProfileInputData = {
                    id: publicProfileId,
                    economyScore: economyScore,
                    economyPercentScore: economyPercentScore,
                    socialScore: socialScore,
                    socialPercentScore: socialPercentScore,
                    environmentalScore: environmentalScore,
                    environmentalPercentScore: envPercentScore,
                    totalScore: totalEarnedScore,
                    totalPercentScore: totalPercentScore,
                };

                await updatePublicProfile(publicProfileInputData).then(async () => {
                    // Create new history object for the user change
                    let newHistoryObj = {
                        // Required
                        companyId: inputData.companyId,
                        companyCertDataId: inputData.companyCertDataId,
                        action: 'UPDATED_RESPONSE',
                        timestamp: new Date(),

                        // Optional
                        userId: userId,
                        description: 'Created new response for the first time for a question within a main category.',
                        categoryId: inputData.categoryId,
                        questionId: inputData.questionId,
                        newData: JSON.stringify(inputData),
                        oldData: JSON.stringify(oldRespData),
                        economyScore: economyScore,
                        economyPercentScore: economyPercentScore,
                        socialScore: socialScore,
                        socialPercentScore: socialPercentScore,
                        environmentalScore: environmentalScore,
                        environmentalPercentScore: envPercentScore,
                        totalScore: totalEarnedScore,
                        totalPercentScore: totalPercentScore,
                    };

                    await createHistoryObject(newHistoryObj).then(async () => {
                        await auth.fetchCompanyInfo(auth.companyData.companyId).then(() => {
                            resolve(data);
                        });
                    });
                });
            })
            .catch(err => {
                reject(err);
            });
    });
}

// Checks if data exists for the given question, if it does, fetch it
export async function finishCertification(auth, isVisibleBool) {
    return new Promise(async (resolve, reject) => {
        // Add a year for the cert's expiration date
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var expDate = new Date(year + 1, month, day);

        // Update public profile
        let profileInputData = {
            id: auth.companyData.companyData.certData.items[0].publicProfile.id,
            isVisible: isVisibleBool,
            status: 'Completed',
            completedAt: new Date(),
            expDate: expDate,
        };

        // Update company cert data
        let certInputData = {
            id: auth.companyData.companyData.certData.items[0].id,
            status: 'Completed',
            completedAt: new Date(),
            expDate: expDate,
        };

        // Create new history object for the user change
        let newHistoryObj = {
            // Required
            companyId: auth.companyData.companyId,
            companyCertDataId: auth.companyData.companyData.certData.items[0].id,
            action: 'COMPLETED_CERTIFICATION',
            timestamp: new Date(),

            // Optional
            userId: auth.userData.id,
            description: 'Completed and published the certification for the company.',
        };

        await createHistoryObject(newHistoryObj);

        await updatePublicProfile(profileInputData)
            .then(async () => {
                if (process.env.NODE_ENV !== 'development') {
                    amplitude.getInstance().logEvent('company_toggle_visibility', { visibility: isVisibleBool });
                }

                await updateCompanyCertificationData(certInputData)
                    .then(() => {
                        if (process.env.NODE_ENV !== 'development') {
                            amplitude
                                .getInstance()
                                .logEvent('company_certification_completed', { companyId: auth.companyData.companyId });
                        }

                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            })
            .catch(err => {
                reject(err);
            });
    });
}
