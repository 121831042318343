import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  box: {
    width: "520px",
    padding: "30px",
    boxShadow: "0px 4px 24px rgb(0 0 0 / 5%), 0px 4px 8px rgb(0 0 0 / 5%)",
    borderRadius: "5px",
  },
  content: {
    backgroundColor: "#fff",
    padding: "20px 40px",
    borderRadius: "0px 5px 5px 5px",
  },
  topButton: {
    backgroundColor: "#fff !important",
    color: "#000 !important",
    borderRadius: "5px 5px 0px 0px",
    width: "150px",
  },
  buttonSection: {
    display: "flex",
    marginTop: "20px",
  },
  fiftyButton: {
    backgroundColor: "#F3CCD1",
    width: "50%",
    height: "50px",
    marginRight: "10px",
  },
  greyButton: {
    backgroundColor: "#E4E4E4",
    width: "50%",
    height: "50px",
    marginLeft: "10px",
  },
  buttonFullWidth: {
    backgroundColor: "#F3CCD1",
    width: "100%",
    height: "50px",
  },
  editfield: {},
  editlabel: {
    width: "120px",
    fontSize: "15px",
  },
}));
