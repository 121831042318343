// PRODUCTION VALUES
export const prodCheck = true;
export const amplitudeBool = true;
export const adminCheck = true;
export const mailUrl = 'https://app.ecofric.com/';

export const identityPoolId = 'eu-west-1:87d1a29e-83e8-4ed0-ac8c-5a8837761c2f';
export const poolWebClientId = '6epqrna6i53ncl3o6pgui4gbta';

export const identityPoolConfig = {
    region: 'eu-west-1',
    poolId: 'eu-west-1:87d1a29e-83e8-4ed0-ac8c-5a8837761c2f',
    login: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_6FQa8kUkY',
};

export const userPoolConfig = {
    UserPoolId: 'eu-west-1_6FQa8kUkY',
    ClientId: '6epqrna6i53ncl3o6pgui4gbta',
};

export const userPool = 'eu-west-1_6FQa8kUkY';

export const companyDataTableName = 'CompanyData-6lkw4iecardile6zhi4llonw5y-production';
export const userTableName = 'User-6lkw4iecardile6zhi4llonw5y-production';

export const s3Bucket = 'ecofric-production-user-storage';
export const s3Region = 'eu-west-1';

export const s3TemplateBucket = 'ecofric-templates';

// DEVELOPMENT VALUES
/*export const prodCheck = false;
export const amplitudeBool = false;
export const adminCheck = false;
export const mailUrl = 'https://development.d2znfn0choghw9.amplifyapp.com/';

export const identityPoolId = 'eu-west-1:9337701b-7c6a-46f1-ac54-e4af0cd6aefa';
export const poolWebClientId = '3pfn8as0j3id61disanrugqpv0';

export const identityPoolConfig = {
    region: 'eu-west-1',
    poolId: 'eu-west-1:9337701b-7c6a-46f1-ac54-e4af0cd6aefa',
    login: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_tshgv9K1G',
};

export const userPoolConfig = {
    UserPoolId: 'eu-west-1_tshgv9K1G',
    ClientId: '3pfn8as0j3id61disanrugqpv0',
};

export const userPool = 'eu-west-1_tshgv9K1G';

export const companyDataTableName = 'CompanyData-uy3gnzzrnjg27lmauhdmgqytoy-newdev';
export const userTableName = 'User-uy3gnzzrnjg27lmauhdmgqytoy-newdev';

export const s3Bucket = 'ecofric-user-storage';
export const s3Region = 'eu-west-1';

export const s3TemplateBucket = 'ecofric-public-templates';
*/
