/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      companyId
      username
      status
      name
      email
      phoneNumber
      position
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        username
        status
        name
        email
        phoneNumber
        position
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      companyId
      name
      orgNumber
      companyData {
        id
        status
        betaOptIn
        name
        orgNumber
        address
        invoice
        brregData
        certData {
          items {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            completedAt
            expDate
            history {
              items {
                id
                companyId
                companyCertDataId
                action
                timestamp
                userId
                description
                categoryId
                questionId
                newData
                oldData
                economyScore
                economyPercentScore
                socialScore
                socialPercentScore
                environmentalScore
                environmentalPercentScore
                totalScore
                totalPercentScore
                createdAt
                updatedAt
              }
              nextToken
            }
            responses {
              items {
                id
                companyId
                companyCertDataId
                categoryId
                questionId
                pointsEarned
                isAnswered
                answers
                text
                longText
                multiText
                multiDropSelect
                number
                multiNumber
                multiRows
                otherField
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            publicProfile {
              id
              companyId
              status
              certType
              certFilterTypes
              employeeAmount
              industryCodes
              isVisible
              completedAt
              expDate
              companyName
              orgNumber
              description
              contactPerson
              phoneNumber
              website
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        invitedUsers
        admins
        users {
          items {
            id
            companyId
            username
            status
            name
            email
            phoneNumber
            position
            createdAt
            updatedAt
          }
          nextToken
        }
        todoTasks {
          items {
            id
            companyId
            type
            status
            history
            dueDate
            users
            criteria
            subCriteria
            climateReportId
            climateCriteria
            climateScope
            title
            description
            result
            comments {
              items {
                id
                companyId
                todoTaskId
                userId
                type
                data
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        name
        orgNumber
        companyData {
          id
          status
          betaOptIn
          name
          orgNumber
          address
          invoice
          brregData
          certData {
            items {
              id
              companyId
              status
              certType
              certFilterTypes
              employeeAmount
              industryCodes
              completedAt
              expDate
              history {
                items {
                  id
                  companyId
                  companyCertDataId
                  action
                  timestamp
                  userId
                  description
                  categoryId
                  questionId
                  newData
                  oldData
                  economyScore
                  economyPercentScore
                  socialScore
                  socialPercentScore
                  environmentalScore
                  environmentalPercentScore
                  totalScore
                  totalPercentScore
                  createdAt
                  updatedAt
                }
                nextToken
              }
              responses {
                items {
                  id
                  companyId
                  companyCertDataId
                  categoryId
                  questionId
                  pointsEarned
                  isAnswered
                  answers
                  text
                  longText
                  multiText
                  multiDropSelect
                  number
                  multiNumber
                  multiRows
                  otherField
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              publicProfile {
                id
                companyId
                status
                certType
                certFilterTypes
                employeeAmount
                industryCodes
                isVisible
                completedAt
                expDate
                companyName
                orgNumber
                description
                contactPerson
                phoneNumber
                website
                economyScore
                economyPercentScore
                socialScore
                socialPercentScore
                environmentalScore
                environmentalPercentScore
                totalScore
                totalPercentScore
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invitedUsers
          admins
          users {
            items {
              id
              companyId
              username
              status
              name
              email
              phoneNumber
              position
              createdAt
              updatedAt
            }
            nextToken
          }
          todoTasks {
            items {
              id
              companyId
              type
              status
              history
              dueDate
              users
              criteria
              subCriteria
              climateReportId
              climateCriteria
              climateScope
              title
              description
              result
              comments {
                items {
                  id
                  companyId
                  todoTaskId
                  userId
                  type
                  data
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyData = /* GraphQL */ `
  query GetCompanyData($id: ID!) {
    getCompanyData(id: $id) {
      id
      status
      betaOptIn
      name
      orgNumber
      address
      invoice
      brregData
      certData {
        items {
          id
          companyId
          status
          certType
          certFilterTypes
          employeeAmount
          industryCodes
          completedAt
          expDate
          history {
            items {
              id
              companyId
              companyCertDataId
              action
              timestamp
              userId
              description
              categoryId
              questionId
              newData
              oldData
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
            nextToken
          }
          responses {
            items {
              id
              companyId
              companyCertDataId
              categoryId
              questionId
              pointsEarned
              isAnswered
              answers
              text
              longText
              multiText
              multiDropSelect
              number
              multiNumber
              multiRows
              otherField
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          publicProfile {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            isVisible
            completedAt
            expDate
            companyName
            orgNumber
            description
            contactPerson
            phoneNumber
            website
            economyScore
            economyPercentScore
            socialScore
            socialPercentScore
            environmentalScore
            environmentalPercentScore
            totalScore
            totalPercentScore
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      invitedUsers
      admins
      users {
        items {
          id
          companyId
          username
          status
          name
          email
          phoneNumber
          position
          createdAt
          updatedAt
        }
        nextToken
      }
      todoTasks {
        items {
          id
          companyId
          type
          status
          history
          dueDate
          users
          criteria
          subCriteria
          climateReportId
          climateCriteria
          climateScope
          title
          description
          result
          comments {
            items {
              id
              companyId
              todoTaskId
              userId
              type
              data
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyDatas = /* GraphQL */ `
  query ListCompanyDatas(
    $filter: ModelCompanyDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        betaOptIn
        name
        orgNumber
        address
        invoice
        brregData
        certData {
          items {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            completedAt
            expDate
            history {
              items {
                id
                companyId
                companyCertDataId
                action
                timestamp
                userId
                description
                categoryId
                questionId
                newData
                oldData
                economyScore
                economyPercentScore
                socialScore
                socialPercentScore
                environmentalScore
                environmentalPercentScore
                totalScore
                totalPercentScore
                createdAt
                updatedAt
              }
              nextToken
            }
            responses {
              items {
                id
                companyId
                companyCertDataId
                categoryId
                questionId
                pointsEarned
                isAnswered
                answers
                text
                longText
                multiText
                multiDropSelect
                number
                multiNumber
                multiRows
                otherField
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            publicProfile {
              id
              companyId
              status
              certType
              certFilterTypes
              employeeAmount
              industryCodes
              isVisible
              completedAt
              expDate
              companyName
              orgNumber
              description
              contactPerson
              phoneNumber
              website
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        invitedUsers
        admins
        users {
          items {
            id
            companyId
            username
            status
            name
            email
            phoneNumber
            position
            createdAt
            updatedAt
          }
          nextToken
        }
        todoTasks {
          items {
            id
            companyId
            type
            status
            history
            dueDate
            users
            criteria
            subCriteria
            climateReportId
            climateCriteria
            climateScope
            title
            description
            result
            comments {
              items {
                id
                companyId
                todoTaskId
                userId
                type
                data
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserNotification = /* GraphQL */ `
  query GetUserNotification($id: ID!) {
    getUserNotification(id: $id) {
      id
      companyId
      userId
      type
      markedAsRead
      archived
      data
      createdAt
      updatedAt
    }
  }
`;
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications(
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        userId
        type
        markedAsRead
        archived
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTodoTask = /* GraphQL */ `
  query GetTodoTask($id: ID!) {
    getTodoTask(id: $id) {
      id
      companyId
      type
      status
      history
      dueDate
      users
      criteria
      subCriteria
      climateReportId
      climateCriteria
      climateScope
      title
      description
      result
      comments {
        items {
          id
          companyId
          todoTaskId
          userId
          type
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTodoTasks = /* GraphQL */ `
  query ListTodoTasks(
    $filter: ModelTodoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodoTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        type
        status
        history
        dueDate
        users
        criteria
        subCriteria
        climateReportId
        climateCriteria
        climateScope
        title
        description
        result
        comments {
          items {
            id
            companyId
            todoTaskId
            userId
            type
            data
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTodoTaskComment = /* GraphQL */ `
  query GetTodoTaskComment($id: ID!) {
    getTodoTaskComment(id: $id) {
      id
      companyId
      todoTaskId
      userId
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const listTodoTaskComments = /* GraphQL */ `
  query ListTodoTaskComments(
    $filter: ModelTodoTaskCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodoTaskComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        todoTaskId
        userId
        type
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDiscountCode = /* GraphQL */ `
  query GetDiscountCode($id: ID!) {
    getDiscountCode(id: $id) {
      id
      code
      percentDiscount
      durationInMonths
      codeExpDate
      isTimeBased
      createdAt
      updatedAt
    }
  }
`;
export const listDiscountCodes = /* GraphQL */ `
  query ListDiscountCodes(
    $filter: ModelDiscountCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscountCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        percentDiscount
        durationInMonths
        codeExpDate
        isTimeBased
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyCertData = /* GraphQL */ `
  query GetCompanyCertData($id: ID!) {
    getCompanyCertData(id: $id) {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      completedAt
      expDate
      history {
        items {
          id
          companyId
          companyCertDataId
          action
          timestamp
          userId
          description
          categoryId
          questionId
          newData
          oldData
          economyScore
          economyPercentScore
          socialScore
          socialPercentScore
          environmentalScore
          environmentalPercentScore
          totalScore
          totalPercentScore
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          companyId
          companyCertDataId
          categoryId
          questionId
          pointsEarned
          isAnswered
          answers
          text
          longText
          multiText
          multiDropSelect
          number
          multiNumber
          multiRows
          otherField
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      publicProfile {
        id
        companyId
        status
        certType
        certFilterTypes
        employeeAmount
        industryCodes
        isVisible
        completedAt
        expDate
        companyName
        orgNumber
        description
        contactPerson
        phoneNumber
        website
        economyScore
        economyPercentScore
        socialScore
        socialPercentScore
        environmentalScore
        environmentalPercentScore
        totalScore
        totalPercentScore
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCompanyCertDatas = /* GraphQL */ `
  query ListCompanyCertDatas(
    $filter: ModelCompanyCertDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyCertDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        status
        certType
        certFilterTypes
        employeeAmount
        industryCodes
        completedAt
        expDate
        history {
          items {
            id
            companyId
            companyCertDataId
            action
            timestamp
            userId
            description
            categoryId
            questionId
            newData
            oldData
            economyScore
            economyPercentScore
            socialScore
            socialPercentScore
            environmentalScore
            environmentalPercentScore
            totalScore
            totalPercentScore
            createdAt
            updatedAt
          }
          nextToken
        }
        responses {
          items {
            id
            companyId
            companyCertDataId
            categoryId
            questionId
            pointsEarned
            isAnswered
            answers
            text
            longText
            multiText
            multiDropSelect
            number
            multiNumber
            multiRows
            otherField
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        publicProfile {
          id
          companyId
          status
          certType
          certFilterTypes
          employeeAmount
          industryCodes
          isVisible
          completedAt
          expDate
          companyName
          orgNumber
          description
          contactPerson
          phoneNumber
          website
          economyScore
          economyPercentScore
          socialScore
          socialPercentScore
          environmentalScore
          environmentalPercentScore
          totalScore
          totalPercentScore
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCompanyCertHistoryData = /* GraphQL */ `
  query GetCompanyCertHistoryData($id: ID!) {
    getCompanyCertHistoryData(id: $id) {
      id
      companyId
      companyCertDataId
      action
      timestamp
      userId
      description
      categoryId
      questionId
      newData
      oldData
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyCertHistoryDatas = /* GraphQL */ `
  query ListCompanyCertHistoryDatas(
    $filter: ModelCompanyCertHistoryDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyCertHistoryDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        companyCertDataId
        action
        timestamp
        userId
        description
        categoryId
        questionId
        newData
        oldData
        economyScore
        economyPercentScore
        socialScore
        socialPercentScore
        environmentalScore
        environmentalPercentScore
        totalScore
        totalPercentScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCertResponse = /* GraphQL */ `
  query GetCertResponse($id: ID!) {
    getCertResponse(id: $id) {
      id
      companyId
      companyCertDataId
      categoryId
      questionId
      pointsEarned
      isAnswered
      answers
      text
      longText
      multiText
      multiDropSelect
      number
      multiNumber
      multiRows
      otherField
      createdAt
      updatedAt
    }
  }
`;
export const listCertResponses = /* GraphQL */ `
  query ListCertResponses(
    $filter: ModelCertResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        companyCertDataId
        categoryId
        questionId
        pointsEarned
        isAnswered
        answers
        text
        longText
        multiText
        multiDropSelect
        number
        multiNumber
        multiRows
        otherField
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyClimateAccReport = /* GraphQL */ `
  query GetCompanyClimateAccReport($id: ID!) {
    getCompanyClimateAccReport(id: $id) {
      id
      companyId
      status
      title
      year
      userCreatedBy
      infoData
      responses {
        items {
          id
          companyId
          climateAccReportId
          questionId
          scope
          data
          isRelevant
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyClimateAccReports = /* GraphQL */ `
  query ListCompanyClimateAccReports(
    $filter: ModelCompanyClimateAccReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyClimateAccReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        status
        title
        year
        userCreatedBy
        infoData
        responses {
          items {
            id
            companyId
            climateAccReportId
            questionId
            scope
            data
            isRelevant
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClimateAccResponse = /* GraphQL */ `
  query GetClimateAccResponse($id: ID!) {
    getClimateAccResponse(id: $id) {
      id
      companyId
      climateAccReportId
      questionId
      scope
      data
      isRelevant
      createdAt
      updatedAt
    }
  }
`;
export const listClimateAccResponses = /* GraphQL */ `
  query ListClimateAccResponses(
    $filter: ModelClimateAccResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClimateAccResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        climateAccReportId
        questionId
        scope
        data
        isRelevant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClimateEmissionFactor = /* GraphQL */ `
  query GetClimateEmissionFactor($id: ID!) {
    getClimateEmissionFactor(id: $id) {
      id
      questionId
      name
      scope
      types {
        items {
          id
          type
          value
          emissionFactorID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listClimateEmissionFactors = /* GraphQL */ `
  query ListClimateEmissionFactors(
    $filter: ModelClimateEmissionFactorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClimateEmissionFactors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionId
        name
        scope
        types {
          items {
            id
            type
            value
            emissionFactorID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClimateEmissionFactorType = /* GraphQL */ `
  query GetClimateEmissionFactorType($id: ID!) {
    getClimateEmissionFactorType(id: $id) {
      id
      type
      value
      emissionFactorID
      createdAt
      updatedAt
    }
  }
`;
export const listClimateEmissionFactorTypes = /* GraphQL */ `
  query ListClimateEmissionFactorTypes(
    $filter: ModelClimateEmissionFactorTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClimateEmissionFactorTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        value
        emissionFactorID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyNSRSReport = /* GraphQL */ `
  query GetCompanyNSRSReport($id: ID!) {
    getCompanyNSRSReport(id: $id) {
      id
      companyId
      status
      reportYear
      userCreatedBy
      responses {
        items {
          id
          companyId
          reportId
          categoryId
          subcategoryId
          questionId
          isAnswered
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyNSRSReports = /* GraphQL */ `
  query ListCompanyNSRSReports(
    $filter: ModelCompanyNSRSReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyNSRSReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        status
        reportYear
        userCreatedBy
        responses {
          items {
            id
            companyId
            reportId
            categoryId
            subcategoryId
            questionId
            isAnswered
            data
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNSRSResponse = /* GraphQL */ `
  query GetNSRSResponse($id: ID!) {
    getNSRSResponse(id: $id) {
      id
      companyId
      reportId
      categoryId
      subcategoryId
      questionId
      isAnswered
      data
      createdAt
      updatedAt
    }
  }
`;
export const listNSRSResponses = /* GraphQL */ `
  query ListNSRSResponses(
    $filter: ModelNSRSResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNSRSResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        reportId
        categoryId
        subcategoryId
        questionId
        isAnswered
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyCertPublicProfile = /* GraphQL */ `
  query GetCompanyCertPublicProfile($id: ID!) {
    getCompanyCertPublicProfile(id: $id) {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      isVisible
      completedAt
      expDate
      companyName
      orgNumber
      description
      contactPerson
      phoneNumber
      website
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyCertPublicProfiles = /* GraphQL */ `
  query ListCompanyCertPublicProfiles(
    $filter: ModelCompanyCertPublicProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyCertPublicProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        status
        certType
        certFilterTypes
        employeeAmount
        industryCodes
        isVisible
        completedAt
        expDate
        companyName
        orgNumber
        description
        contactPerson
        phoneNumber
        website
        economyScore
        economyPercentScore
        socialScore
        socialPercentScore
        environmentalScore
        environmentalPercentScore
        totalScore
        totalPercentScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCertCategory = /* GraphQL */ `
  query GetCertCategory($id: ID!) {
    getCertCategory(id: $id) {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              subcategoryId
              question
              description
              explanation
              type
              pointValue
              filterType
              industryCodes
              required
              order
              minChars
              maxChars
              createdAt
              updatedAt
              options {
                items {
                  id
                  questionId
                  pointValue
                  order
                  option
                  isOtherOption
                  isCustomMultiSelectOption
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listCertCategorys = /* GraphQL */ `
  query ListCertCategorys(
    $filter: ModelCertCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        order
        createdAt
        updatedAt
        subcategories {
          items {
            id
            categoryId
            name
            order
            createdAt
            updatedAt
            questions {
              items {
                id
                subcategoryId
                question
                description
                explanation
                type
                pointValue
                filterType
                industryCodes
                required
                order
                minChars
                maxChars
                createdAt
                updatedAt
                options {
                  items {
                    id
                    questionId
                    pointValue
                    order
                    option
                    isOtherOption
                    isCustomMultiSelectOption
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCertSubcategory = /* GraphQL */ `
  query GetCertSubcategory($id: ID!) {
    getCertSubcategory(id: $id) {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          subcategoryId
          question
          description
          explanation
          type
          pointValue
          filterType
          industryCodes
          required
          order
          minChars
          maxChars
          createdAt
          updatedAt
          options {
            items {
              id
              questionId
              pointValue
              order
              option
              isOtherOption
              isCustomMultiSelectOption
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listCertSubcategorys = /* GraphQL */ `
  query ListCertSubcategorys(
    $filter: ModelCertSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertSubcategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        name
        order
        createdAt
        updatedAt
        questions {
          items {
            id
            subcategoryId
            question
            description
            explanation
            type
            pointValue
            filterType
            industryCodes
            required
            order
            minChars
            maxChars
            createdAt
            updatedAt
            options {
              items {
                id
                questionId
                pointValue
                order
                option
                isOtherOption
                isCustomMultiSelectOption
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCertQuestion = /* GraphQL */ `
  query GetCertQuestion($id: ID!) {
    getCertQuestion(id: $id) {
      id
      subcategoryId
      question
      description
      explanation
      type
      pointValue
      filterType
      industryCodes
      required
      order
      minChars
      maxChars
      createdAt
      updatedAt
      options {
        items {
          id
          questionId
          pointValue
          order
          option
          isOtherOption
          isCustomMultiSelectOption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCertQuestions = /* GraphQL */ `
  query ListCertQuestions(
    $filter: ModelCertQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subcategoryId
        question
        description
        explanation
        type
        pointValue
        filterType
        industryCodes
        required
        order
        minChars
        maxChars
        createdAt
        updatedAt
        options {
          items {
            id
            questionId
            pointValue
            order
            option
            isOtherOption
            isCustomMultiSelectOption
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCertQuestionOption = /* GraphQL */ `
  query GetCertQuestionOption($id: ID!) {
    getCertQuestionOption(id: $id) {
      id
      questionId
      pointValue
      order
      option
      isOtherOption
      isCustomMultiSelectOption
      createdAt
      updatedAt
    }
  }
`;
export const listCertQuestionOptions = /* GraphQL */ `
  query ListCertQuestionOptions(
    $filter: ModelCertQuestionOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertQuestionOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionId
        pointValue
        order
        option
        isOtherOption
        isCustomMultiSelectOption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNSRSCategory = /* GraphQL */ `
  query GetNSRSCategory($id: ID!) {
    getNSRSCategory(id: $id) {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              categoryId
              subcategoryId
              name
              description
              explanation
              filterTypes
              industryCodes
              required
              order
              data
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listNSRSCategorys = /* GraphQL */ `
  query ListNSRSCategorys(
    $filter: ModelNSRSCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNSRSCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        order
        createdAt
        updatedAt
        subcategories {
          items {
            id
            categoryId
            name
            order
            createdAt
            updatedAt
            questions {
              items {
                id
                categoryId
                subcategoryId
                name
                description
                explanation
                filterTypes
                industryCodes
                required
                order
                data
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getNSRSSubcategory = /* GraphQL */ `
  query GetNSRSSubcategory($id: ID!) {
    getNSRSSubcategory(id: $id) {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          categoryId
          subcategoryId
          name
          description
          explanation
          filterTypes
          industryCodes
          required
          order
          data
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listNSRSSubcategorys = /* GraphQL */ `
  query ListNSRSSubcategorys(
    $filter: ModelNSRSSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNSRSSubcategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        name
        order
        createdAt
        updatedAt
        questions {
          items {
            id
            categoryId
            subcategoryId
            name
            description
            explanation
            filterTypes
            industryCodes
            required
            order
            data
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getNSRSQuestion = /* GraphQL */ `
  query GetNSRSQuestion($id: ID!) {
    getNSRSQuestion(id: $id) {
      id
      categoryId
      subcategoryId
      name
      description
      explanation
      filterTypes
      industryCodes
      required
      order
      data
      createdAt
      updatedAt
    }
  }
`;
export const listNSRSQuestions = /* GraphQL */ `
  query ListNSRSQuestions(
    $filter: ModelNSRSQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNSRSQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        subcategoryId
        name
        description
        explanation
        filterTypes
        industryCodes
        required
        order
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTemplateFile = /* GraphQL */ `
  query GetTemplateFile($id: ID!) {
    getTemplateFile(id: $id) {
      id
      title
      description
      accessLevel
      fileName
      filePath
      fileSize
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const listTemplateFiles = /* GraphQL */ `
  query ListTemplateFiles(
    $filter: ModelTemplateFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplateFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        accessLevel
        fileName
        filePath
        fileSize
        fileType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
