import { makeStyles, withStyles } from "@mui/styles";
import { TableCell, TableRow, TableHead } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

export const useStyles = makeStyles((theme) => ({
  //Sidebar
  active: {
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
    fontSize: "16px",
  },
  connector: {
    marginLeft: "30px",
    height: "14px",
    borderLeft: "1px dashed #000",
  },
  //Modules
  modulePaper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  modulesFooter: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  progressionText: {
    padding: "10px",
    textAlign: "center",
  },
  overlay: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "rgb(255,255,255,0.9)",
    borderRadius: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    top: "0px",
    left: "0px",
  },
  //Summary
  stats: {
    borderLeft: "1px solid #DCDCDC",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  statsBorder: {
    borderBottom: "1px solid #DCDCDC",
    padding: "20px 20px",
  },
  summaryContent: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
  summaryPoints: {
    backgroundColor: "#F2F2F2",
    padding: "10px",
    borderRadius: 100,
    textAlign: "center",
  },
  summaryDescription: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [breakpoints.down("lg")]: {
      padding: "30px",
    },
  },
  summaryImage: {
    width: "100%",
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
  //Form
  formContainer: {
    display: "flex",
    backgroundColor: "#F1F1F1",
    [breakpoints.down("md")]: {
      marginTop: "60px",
    },
  },
  mainContainer: {
    padding: "40px",
    [breakpoints.down("lg")]: {
      padding: "0px",
    },
  },
  iconCell: {
    borderBottom: "none",
    width: "60px",
    height: "60px",
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    [breakpoints.down("lg")]: {
      width: "55px",
      height: "55px",
    },
  },
  questionRow: {
    width: "100%",
    padding: "0px 10px",
    [breakpoints.down("lg")]: {
      padding: "0px 10px",
    },
  },
  ChipCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 10px",
    position: "relative",
    top: "-8px",
  },
  questionTitle: {
    padding: "0px",
    [breakpoints.down("lg")]: {
      fontSize: "18px",
    },
  },
  row: {
    background: "#fff",
    boxShadow:
      "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "25px 0px 0px 0px",
  },
  points: {
    backgroundColor: "#F2F2F2",
    padding: "10px",
    borderRadius: 100,
  },
  question: {
    boxShadow:
      "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
  },
  nextButton: {
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FEF0D9",
    },
  },
  readmore: {
    fontSize: "18px",
    padding: "0px",
  },
  aDivider: {
    marginBottom: "20px",
    border: "2px solid #FDDFB0",
  },
  avatar: {
    backgroundColor: "#FDDFB0",
    color: "#000000",
    fontSize: "14px",
    marginRight: "0px",
    height: "40px",
    width: "40px",
  },
  descDivider: {
    margin: "10px 0px 10px 0px",
    border: "1px solid #E1E1E1",
  },
  textReq: {
    margin: "0px 0px 25px 0px",
    fontsize: "16px",
  },
  subName: {
    textAlign: "left",
    margin: "40px 0px 20px 0px",
    borderBottom: "none",
  },
  bottomNav: {
    display: "flex",
    justifyContent: "flex-end",
  },
  back: {
    marginRight: "20px",
    backgroundColor: "#EBEBEB",
  },
  saveButton: {
    marginRight: "20px",
    width: "200px",
    backgroundColor: "#BDCCD4",
  },
  // Graphs
  graphGrid: {
    padding: "20px 40px 40px 40px",
    [breakpoints.down("lg")]: {
      padding: "20px 80px 40px 80px",
    },
  },
  links: {
    border: "none",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  graphPoints: {
    textAlign: "center",
    fontWeight: "bold",
    textDecoration: "none",
  },
  // Finish module
  finishFooter: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    backgroundColor: "#E2EFF1",
    justifyContent: "flex-end !important",
  },
  finishButton: {
    backgroundColor: "#BDCCD4",
    width: "150px",
    height: "40px",
    fontSize: "16px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#ffffff",
    },
  },
  title: {
    textAlign: "left",
    fontSize: "30px",
    backgroundColor: "#E2EFF1",
  },
  closeButton: {
    width: "150px",
    height: "50px",
    backgroundColor: "#F5F5F5",
  },
  completeButton: {
    width: "250px",
    height: "50px",
  },
  //Chores
  choreTitle: {
    textAlign: "left",
    fontSize: "25px",
    backgroundColor: "#E2EFF1",
    height: "30px",
    padding: "10px 25px 20px 25px",
    boxShadow:
      "0px 4px 5px rgba(0, 0, 0, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05)",
    zIndex: 9999,
  },
  BoxStyle: {
    marginBottom: "10px",
  },
  closeChore: {
    width: "100px",
    height: "50px",
    backgroundColor: "#EBEBEB",
  },
  richEditGrid: {
    minHeight: "6em",
    cursor: "text",
    padding: "5px 15px 15px 15px",
    backgroundColor: "#F4F4F4",
    borderRadius: "5px",
    boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
  },
}));

export const ChoreRow = withStyles(() => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f3f3f3",
      borderTop: "1px solid #EBEBEB",
      borderBottom: "1px solid #EBEBEB",
    },
  },
}))(TableRow);

export const ChoreCell = withStyles(() => ({
  root: {
    padding: 10,
    borderBottom: "0px",
  },
  head: {
    padding: "10px 10px 0px 10px",
  },
  body: {
    borderRight: "1px solid #E8E8E8",
    "&:last-child": {
      borderRight: "0px",
    },
  },
}))(TableCell);

export const ChoreHead = withStyles(() => ({
  root: {
    borderBottom: "4px solid #FDDFB0",
  },
}))(TableHead);
