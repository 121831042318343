import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { s3Region, s3TemplateBucket } from '../../../../../helpers/globalValues';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { TextAreaInput } from '../../../../../components/InputForm';

import AWS from 'aws-sdk';
import { API, graphqlOperation } from 'aws-amplify';
import { createTemplateFile } from '../../../../../graphql/mutations';

export default function NewTemplate({ showPopup, updateAllTemplates }) {
    const [open, setOpen] = useToggle();

    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');

    const myBucket = new AWS.S3({
        params: { Bucket: s3TemplateBucket },
        region: s3Region,
    });

    const handleFileInput = async e => {
        setFile(e.target.files[0]);
    };

    const uploadFile = async () => {
        if (!file) {
            showPopup('Vennligst velg en fil å laste opp.');
            return;
        }

        if (title === '' || desc === '') {
            showPopup('Alle felter må være fylt ut!');
            return;
        }

        // Fetch saved credentials for authentication
        AWS.config.credentials.get(async err => {
            if (err) {
                console.log(err);

                showPopup('En feil skjedde ved opplastning av fil til backend. Last siden på nytt og prøv igjen.');
                return;
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                }
            }

            let filePath = 'public/';

            const params = {
                Body: file,
                Bucket: s3TemplateBucket,
                Key: filePath + file.name,
            };

            // Upload file to backend based on protection level
            myBucket.putObject(params).send(async err => {
                // File successfully uploaded
                if (err) {
                    console.log(err);
                    showPopup('En feil skjedde ved opplastning av fil til backend. Last siden på nytt og prøv igjen.');
                    return;
                } else {
                    await createTemplate(filePath).then(async () => {
                        await updateAllTemplates();
                        handleDialog();
                    });
                }
            });
        });
    };

    const createTemplate = async filePath => {
        return new Promise(async (resolve, reject) => {
            // Input data for creating a new CompanyData object
            let inputData = {
                title: title,
                description: desc,
                accessLevel: 'public',
                fileName: file.name,
                filePath: filePath,
                fileSize: file.size,
                fileType: file.type,
            };

            await API.graphql(graphqlOperation(createTemplateFile, { input: inputData }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new template file: ', data.data.createTemplateFile);
                    }

                    resolve(data.data.createTemplateFile);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Failed to create new template file: ', err);
                    }

                    reject(err);
                });
        });
    };

    const handleDialog = () => {
        setOpen();

        setFile(null);
        setTitle('');
        setDesc('');
    };

    const newTemplateDialog = () => {
        return (
            <Grid>
                <Dialog open={open} onClose={handleDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Legg til ny mal</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Last opp fil og skriv inn navn og beskrivelse for å legge til en ny mal.
                        </DialogContentText>
                        <Grid sx={{ marginBottom: '10px' }}>
                            <input
                                id="raised-button-file"
                                type="file"
                                accept=".doc,.docx,.jpg,.jpeg,.png,.pdf,.ppt,.xls,.xlsx"
                                onChange={handleFileInput}
                            />
                        </Grid>
                        <InputForm
                            label="Tittel"
                            placeholder="Skriv inn tittel..."
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Beskrivelse"
                            placeholder="Skriv inn beskrivelse..."
                            value={desc}
                            onChange={e => setDesc(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={uploadFile}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid item xs={12}>
            {newTemplateDialog()}
            <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                sx={{ padding: '20px', marginBottom: '20px', width: '200px' }}
                onClick={handleDialog}
            >
                Legg til ny mal
            </Button>
        </Grid>
    );
}
