import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../theme';
import KeyInformationForm from './KeyInformation/KeyInformationForm';
import Scope1 from './Scope1/Scope1';
import Scope2 from './Scope2/Scope2';
import Scope3 from './Scope3/Scope3';
import Sidebar from './Sidebar';
import useRouter from '../../../../hooks/useRouter';
import { useAuth } from '../../../../auth/useAuth';
import { SiteLoading } from '../../../../components/Loading';
import { fetchSingleClimateReport } from '../climateFunctions.js';
import {
    fetchCompanyUsers,
    fetchAllCompanyTasksForClimate,
} from '../../Chores/choresFunctions';
import { useStyles } from '../ClimateStyles';

export default function ClimateScopes(props) {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();
    const { id } = router.query;

    const [report, setReport] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            fetchReport();

            fetchCompanyUsers(auth.companyData.companyId).then((data) => {
                setUsers([...data]);
            });

            updateTasks();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateTasks = async () => {
        await fetchAllCompanyTasksForClimate(
            auth.companyData.companyId,
            id
        ).then((data) => {
            setTasks([...data]);
        });
    };

    const fetchReport = async () => {
        if (router.location.state) {
            setReport(router.location.state.report);
        } else {
            fetchSingleClimateReport(id).then((data) => {
                setReport(data);
            });
        }
    };

    const updateReport = async () => {
        fetchSingleClimateReport(id).then((data) => {
            setReport(data);

            if (router.location.state) {
                router.push({
                    state: {
                        report: data,
                    },
                });
            }
        });
    };

    const displayForm = (form) => {
        if (form === 'keyInfo') {
            return (
                <KeyInformationForm
                    report={report}
                    updateReport={updateReport}
                />
            );
        } else if (form === 'scope1') {
            return (
                <Scope1
                    report={report}
                    updateReport={updateReport}
                    tasks={tasks}
                    updateTasks={updateTasks}
                    users={users}
                />
            );
        } else if (form === 'scope2') {
            return (
                <Scope2
                    report={report}
                    updateReport={updateReport}
                    tasks={tasks}
                    updateTasks={updateTasks}
                    users={users}
                />
            );
        } else if (form === 'scope3') {
            return (
                <Scope3
                    report={report}
                    updateReport={updateReport}
                    tasks={tasks}
                    updateTasks={updateTasks}
                    users={users}
                />
            );
        }
    };

    if (!report) {
        return <SiteLoading />;
    } else {
        return (
            <div className="root">
                <ThemeProvider theme={theme}>
                    <Sidebar report={report} form={props.form} id={id} />
                    <Grid container className={classes.mainContainer}>
                        <Grid item xs={12} className={classes.mainSection}>
                            {displayForm(props.form)}
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </div>
        );
    }
}
