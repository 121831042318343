import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid, Typography, Divider, List, ListItem } from "@mui/material";
import Image1 from "../../../assets/images/cslide1.svg";
import Image2 from "../../../assets/images/cslide2.svg";
import Image3 from "../../../assets/images/cslide3.svg";
import Image4 from "../../../assets/images/cslide4.svg";
import Image5 from "../../../assets/images/cslide5.svg";
import Image6 from "../../../assets/images/cslide6.svg";
import Image7 from "../../../assets/images/cslide7.svg";
import * as Text from "../../../helpers/tutorial.json";

export default function CertifySlide() {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      centerMode={false}
      autoPlaySpeed={10000}
      containerClass="container"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
        },
      }}
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image1}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.cslide1.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide1.p1}
          </Typography>
          <List>
            <ListItem>
              - Informasjon om selskapet; organisasjonsnummer og registre hvor
              bedriften er registrert, eierstruktur.
            </ListItem>
            <ListItem>
              - Informasjon om de ansatte og personalpolitikk og kjønnspolitikk.
            </ListItem>
            <ListItem>
              - Informasjon om bærekraftsarbeidet dere gjør i dag.
            </ListItem>
            <ListItem>- Vannforbruk.</ListItem>
            <ListItem>- Strømforbruk.</ListItem>
            <ListItem>- Leverandørinformasjon.</ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide1.p2}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide1.p3}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image2}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.cslide2.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide2.p1}
          </Typography>
          <List>
            <ListItem>- Kriterie som brukes til vurdering.</ListItem>
            <ListItem>
              - Hjelpetekst med forklaring på hvordan kriterier besvares.
            </ListItem>
            <ListItem>
              - Hvorfor som forklarer formålet med å inkludere kriteriet.
            </ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide2.p2}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image3}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.cslide3.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide3.p1}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide3.p2}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide3.p3}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide3.p4}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image4}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.cslide4.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide4.p1}
          </Typography>
          <List>
            <ListItem>1. Flervalg</ListItem>
            <ListItem>2. Enkeltvalg</ListItem>
            <ListItem>3. Tekst/tall</ListItem>
          </List>
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide4.p2}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image5}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.cslide5.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide5.p1}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide5.p2}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide5.p3}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image6}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.cslide6.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide6.p1}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image7}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.cslide7.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.cslide7.p1}
          </Typography>
        </Grid>
      </Grid>
    </Carousel>
  );
}
