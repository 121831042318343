import React from 'react';
import { Grid, Paper, Table, TableContainer, TableBody, TableRow, TableCell } from '@mui/material';
import BronzeBadge from '../../../../assets/images/Bronze.svg';
import SilverBadge from '../../../../assets/images/Silver.svg';
import GoldBadge from '../../../../assets/images/Gold.svg';
import getDateFromTimestamp from '../../../../helpers/getDateFromTimestamp';
import { getBadgeFromTotalEarnedPoints } from '../../../../helpers/badgeFunctions';

export default function CertificationGiven(props) {
    const getBadgeText = () => {
        if (getBadgeFromTotalEarnedPoints(props.data.totalPercentScore) === 'bronze') {
            return ' Bronse';
        } else if (getBadgeFromTotalEarnedPoints(props.data.totalPercentScore) === 'silver') {
            return ' Sølv';
        } else if (getBadgeFromTotalEarnedPoints(props.data.totalPercentScore) === 'gold') {
            return ' Gull';
        } else {
            return ' Ingen';
        }
    };

    const getBadgeImage = () => {
        if (getBadgeFromTotalEarnedPoints(props.data.totalPercentScore) === 'bronze') {
            return (
                <img src={BronzeBadge} alt="Certification badge" style={{ width: '226px', marginTop: '10px' }}></img>
            );
        } else if (getBadgeFromTotalEarnedPoints(props.data.totalPercentScore) === 'silver') {
            return (
                <img src={SilverBadge} alt="Certification badge" style={{ width: '226px', marginTop: '10px' }}></img>
            );
        } else if (getBadgeFromTotalEarnedPoints(props.data.totalPercentScore) === 'gold') {
            return <img src={GoldBadge} alt="Certification badge" style={{ width: '226px', marginTop: '10px' }}></img>;
        } else {
            return <Grid></Grid>;
        }
    };

    return (
        <Grid item xs={3}>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                {getBadgeImage()}
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow sx={{ height: '60px' }}>
                                <TableCell align="center">Oppnådd poengvalør: {getBadgeText()}</TableCell>
                            </TableRow>
                            <TableRow sx={{ height: '60px' }}>
                                <TableCell align="center">
                                    Egenerklæring oppdatert: {getDateFromTimestamp(props.data.updatedAt)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    );
}
