import React from 'react';
import { Grid, Divider } from '@mui/material';
import Text from './types/Text';
import Textfield from './types/Textfield';
import Number from './types/Number';
import RadGroup from './types/RadioGroup';
import MultipleChoice from './types/MultipleChoice';
import Select from './types/Select';
import MultipleSelect from './types/MultipleSelect';
import MultipleSelectsWithInputs from './types/MultipleSelectsWithInputs';
import AddRows from './types/AddRows';
import DisablingCheckbox from './types/DisablingCheckbox';
import DateTime from './types/DateTime';
import BrandsProductsServices from './types/Customs/BrandsProductsServices';
import Risks from './types/Customs/Risks';
import Opportunities from './types/Customs/Opportunities';

export default function QuestionLayout(props) {
    const {
        data,
        input,
        setInput,
        isInputsDisabled,
        reportIsCompleted,
    } = props;

    const renderQuestion = () => {
        let rowData = data.sort((a, b) => a.order - b.order);

        return rowData.map((row) => {
            if (row.elements && row.elements.length === 2) {
                return (
                    <Grid key={row.id} item xs={12} sx={{ display: 'flex' }}>
                        {row.elements
                            .sort((a, b) => a.order - b.order)
                            .map((element, index) => {
                                if (index + 1 === row.elements.length) {
                                    return (
                                        <Grid key={element.id} item xs={6}>
                                            {questionElement(element)}
                                        </Grid>
                                    );
                                } else {
                                    return (
                                        <Grid
                                            key={element.id}
                                            item
                                            xs={6}
                                            sx={{ marginRight: '10px' }}
                                        >
                                            {questionElement(element)}
                                        </Grid>
                                    );
                                }
                            })}
                        <Divider />
                    </Grid>
                );
            } else if (row.elements && row.elements.length === 3) {
                return (
                    <Grid key={row.id} item xs={12} sx={{ display: 'flex' }}>
                        {row.elements
                            .sort((a, b) => a.order - b.order)
                            .map((element, index) => {
                                if (index + 1 === row.elements.length) {
                                    return (
                                        <Grid key={element.id} item xs={4}>
                                            {questionElement(element)}
                                        </Grid>
                                    );
                                } else {
                                    return (
                                        <Grid
                                            key={element.id}
                                            item
                                            xs={4}
                                            sx={{ marginRight: '10px' }}
                                        >
                                            {questionElement(element)}
                                        </Grid>
                                    );
                                }
                            })}
                        <Divider />
                    </Grid>
                );
            } else {
                return (
                    <Grid key={row.id} item xs={12} sx={{ display: 'flex' }}>
                        {row.elements
                            .sort((a, b) => a.order - b.order)
                            .map((element, index) => {
                                if (index + 1 === row.elements.length) {
                                    return (
                                        <Grid key={element.id} item xs={12}>
                                            {questionElement(element)}
                                        </Grid>
                                    );
                                } else {
                                    return (
                                        <Grid
                                            key={element.id}
                                            item
                                            xs={12}
                                            sx={{ marginRight: '10px' }}
                                        >
                                            {questionElement(element)}
                                        </Grid>
                                    );
                                }
                            })}
                        <Divider />
                    </Grid>
                );
            }
        });
    };

    const questionElement = (eleData) => {
        if (eleData.type === 'Text') {
            return (
                <Text
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'Textfield') {
            return (
                <Textfield
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'Number') {
            return (
                <Number
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'RadioGroup') {
            return (
                <RadGroup
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'MultipleChoice') {
            return (
                <MultipleChoice
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'Select') {
            return (
                <Select
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'MultipleSelect') {
            return (
                <MultipleSelect
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'MultipleSelectsWithInputs') {
            return (
                <MultipleSelectsWithInputs
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'AddRows') {
            return (
                <AddRows
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'DisablingCheckbox') {
            return (
                <DisablingCheckbox
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'DateTime') {
            return (
                <DateTime
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'CustomProductBrands') {
            return (
                <BrandsProductsServices
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'CustomOpportunities') {
            return (
                <Opportunities
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else if (eleData.type === 'CustomRisks') {
            return (
                <Risks
                    data={eleData}
                    input={input}
                    setInput={setInput}
                    isInputsDisabled={isInputsDisabled}
                    reportIsCompleted={reportIsCompleted}
                />
            );
        } else {
            return <Grid></Grid>;
        }
    };

    if (!data) {
        return <Grid>Trenger oppsett for spørsmål!</Grid>;
    } else {
        return renderQuestion();
    }
}
