import * as AWS from 'aws-sdk';
import { API, graphqlOperation } from 'aws-amplify';
import { listUsers } from '../../../graphql/queries';
import {
    updateUser,
    updateCompany,
    updateCompanyData,
    updateCompanyCertPublicProfile,
} from '../../../graphql/mutations';

import { userPool } from '../../../helpers/globalValues';

import welcomeApprovedUserMail from '../../../assets/emails/welcomeApprovedUserMail';
import declinedUserMail from '../../../assets/emails/declinedUserMail';
import inviteMail from '../../../assets/emails/inviteMail';

// OBS: Remember to change values between production and development

/** LOCAL FUNCTIONS **/

// Admin function for updating tenantId
const updateUserTenantIdAttribute = async (userId, value) => {
    return new Promise((resolve, reject) => {
        let cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

        // Data input
        let params = {
            UserAttributes: [
                {
                    Name: 'custom:tenantId',
                    Value: value,
                },
            ],
            UserPoolId: userPool,
            Username: userId,
        };

        cognitoIdentityServiceProvider.adminUpdateUserAttributes(params, (err, data) => {
            if (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Error when attempting to update the user's tenantId custom attribute: ", err);
                }

                reject(err);
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Updated the user's tenantId attribute: ", data);
                }

                resolve(data);
            }
        });
    });
};

// Email promise for welcoming an approved user to the company
const sendApprovedUserMail = async (userName, orgName, userEmail) => {
    return new Promise(async (resolve, reject) => {
        // E-mail data
        let mailData = {
            name: userName,
            orgName: orgName,
        };

        // Params for sending a welcome email
        const params = {
            Destination: {
                ToAddresses: [userEmail],
            },
            Message: {
                Subject: {
                    Charset: 'UTF-8',
                    Data: 'Du har nå fått tilgang til bærekraftsportalen',
                },
                Body: {
                    Html: {
                        Charset: 'UTF-8',
                        Data: welcomeApprovedUserMail(mailData),
                    },
                },
            },
            Source: '"Ecofric" <hello@ecofric.com>',
        };

        const sendPromise = new AWS.SES().sendEmail(params).promise();

        // Wait for email to be sent
        await sendPromise.then(data => resolve(data)).catch(err => reject(err));
    });
};

// Email promise for when the user is declined by company
const sendDeclinedUserMail = async (userName, orgName, userEmail) => {
    return new Promise(async (resolve, reject) => {
        // E-mail data
        let mailData = {
            name: userName,
            orgName: orgName,
        };

        // Params for sending a welcome email
        const params = {
            Destination: {
                ToAddresses: [userEmail],
            },
            Message: {
                Subject: {
                    Charset: 'UTF-8',
                    Data: 'Din forespørsel ble avslått',
                },
                Body: {
                    Html: {
                        Charset: 'UTF-8',
                        Data: declinedUserMail(mailData),
                    },
                },
            },
            Source: '"Ecofric" <hello@ecofric.com>',
        };

        const sendPromise = new AWS.SES().sendEmail(params).promise();

        // Wait for email to be sent
        await sendPromise.then(data => resolve(data)).catch(err => reject(err));
    });
};

export async function fetchUsers(companyId, token) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(listUsers, { filter: { companyId: { eq: companyId } }, nextToken: token }))
            .then(data => {
                resolve(data.data.listUsers);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/** EXPORTED FUNCTIONS **/

// Fetches all available users for the company
export async function fetchCompanyUsers(companyId) {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchUsers(companyId, nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching users from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched users: ', dataArr);
        }

        resolve(dataArr);
    });
}

// Function that allows admins to approve users and update their tenantId
export async function approveUser(userData, companyData) {
    return new Promise(async (resolve, reject) => {
        await updateUserTenantIdAttribute(userData.id, companyData.companyId).catch(err => {
            reject(err);
        });

        await API.graphql(
            graphqlOperation(updateUser, {
                input: { id: userData.id, companyId: companyData.companyId, status: 'ExistingUser' },
            }),
        ).catch(err => {
            reject(err);
        });

        await sendApprovedUserMail(JSON.parse(userData.name).name, companyData.name, userData.email).catch(err => {
            reject(err);
        });

        resolve();
    });
}

// Function that allows admins to approve users and update their tenantId
export async function removeUser(userData, companyData) {
    return new Promise(async (resolve, reject) => {
        if (userData.status === 'RequestingAccess') {
            // If declining a user which is requesting access
            // Update the user's fields in DB to remove them from company and put them back into the user flow
            await API.graphql(
                graphqlOperation(updateUser, {
                    input: { id: userData.id, status: 'DeclinedByCompany', companyId: null },
                }),
            ).catch(err => {
                reject(err);
            });

            await sendDeclinedUserMail(JSON.parse(userData.name).name, companyData.name, userData.email).catch(err => {
                reject(err);
            });

            if (process.env.NODE_ENV === 'development') {
                console.log("Declined the user's request to join company.");
            }

            resolve();
        } else if (userData.status === 'ExistingUser') {
            // If declining a user which is a member of the company
            // Update the user's fields in DB to remove them from company and put them back into the user flow, and update their tenantId
            await updateUserTenantIdAttribute(userData.id, 'none').catch(err => {
                reject(err);
            });

            await API.graphql(
                graphqlOperation(updateUser, {
                    input: { id: userData.id, status: 'RemovedByCompany', companyId: null },
                }),
            ).catch(err => {
                reject(err);
            });

            if (process.env.NODE_ENV === 'development') {
                console.log('Removed user from company.');
            }

            resolve();
        } else {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error trying to remove the user.');
            }

            reject();
        }
    });
}

export async function updateUserInfo(inputData) {
    return new Promise(async (resolve, reject) => {
        // Update the user's fields in backend
        await API.graphql(
            graphqlOperation(updateUser, {
                input: inputData,
            }),
        )
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully updated user info.', data);
                }

                resolve();
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error trying to update user info.', err);
                }

                reject();
            });
    });
}

export async function updateCompanyInfo(companyInput, companyDataInput) {
    return new Promise(async (resolve, reject) => {
        // Update the user's fields in backend
        await API.graphql(
            graphqlOperation(updateCompany, {
                input: companyInput,
            }),
        )
            .then(async data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully updated company info.', data);
                }

                await API.graphql(
                    graphqlOperation(updateCompanyData, {
                        input: companyDataInput,
                    }),
                )
                    .then(data => {
                        if (process.env.NODE_ENV === 'development') {
                            console.log('Successfully updated company info.', data);
                        }

                        resolve();
                    })
                    .catch(err => {
                        if (process.env.NODE_ENV === 'development') {
                            console.error('Error trying to update company info.', err);
                        }

                        reject();
                    });
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error trying to update company info.', err);
                }

                reject();
            });
    });
}

export async function updatePublicProfile(inputData) {
    return new Promise(async (resolve, reject) => {
        // Update the user's fields in backend
        await API.graphql(
            graphqlOperation(updateCompanyCertPublicProfile, {
                input: inputData,
            }),
        )
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully updated cert public profile:', data);
                }

                resolve();
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error trying to update cert public profile:', err);
                }

                reject();
            });
    });
}

export async function updateCompanyInviteList(inputData) {
    return new Promise(async (resolve, reject) => {
        // Update the user's fields in backend
        await API.graphql(
            graphqlOperation(updateCompanyData, {
                input: inputData,
            }),
        )
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully updated company:', data);
                }

                resolve();
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error trying to update company:', err);
                }

                reject();
            });
    });
}

// Email promise for when the user is declined by company
export async function sendInviteUserMail(orgName, companyId, userEmail) {
    return new Promise(async (resolve, reject) => {
        // E-mail data
        let mailData = {
            orgName: orgName,
            companyId: companyId,
            email: userEmail,
        };

        // Params for sending a welcome email
        const params = {
            Destination: {
                ToAddresses: [userEmail],
            },
            Message: {
                Subject: {
                    Charset: 'UTF-8',
                    Data: 'Din har blitt invitert til å bli med ' + orgName + ' hos Ecofric',
                },
                Body: {
                    Html: {
                        Charset: 'UTF-8',
                        Data: inviteMail(mailData),
                    },
                },
            },
            Source: '"Ecofric" <hello@ecofric.com>',
        };

        const sendPromise = new AWS.SES().sendEmail(params).promise();

        // Wait for email to be sent
        await sendPromise.then(data => resolve(data)).catch(err => reject(err));
    });
}
