import React, { useState } from 'react';
import { Grid, Paper, Button } from '@mui/material';
import {
    List,
    Select,
    MenuItem,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    FormGroup,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Radio,
    Switch,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { TextAreaInput, NumberInput } from '../../../../../components/InputForm';
import QuestionRowView from './QuestionRowView';

import { API, graphqlOperation } from 'aws-amplify';
import { createCertQuestion } from '../../../../../graphql/mutations';

export default function QuestionListView(props) {
    const [open, setOpen] = useToggle();

    const [subcategoryId, setSubcategoryId] = useState('');
    const [question, setQuestion] = useState('');
    const [description, setDescription] = useState('');
    const [explanation, setExplanation] = useState('');
    const [type, setType] = useState('Text');
    const [value, setValue] = useState(0);
    const [code, setCode] = useState('');
    const [industryCodes, setIndustryCodes] = useState([]);
    const [required, setRequired] = useState(true);
    const [filterType, setFilterType] = useState('None');
    const [order, setOrder] = useState(1);
    const [minChars, setMinChars] = useState(0);
    const [maxChars, setMaxChars] = useState(0);

    const saveNewQuestion = async () => {
        return new Promise(async (resolve, reject) => {
            // Input data for creating a new CompanyData object
            let inputData = {
                subcategoryId: subcategoryId,
                question: question,
                description: description,
                explanation: explanation,
                type: type,
                pointValue: value,
                filterType: filterType,
                industryCodes: industryCodes,
                required: required,
                order: order,
                minChars: minChars,
                maxChars: maxChars,
            };

            await API.graphql(graphqlOperation(createCertQuestion, { input: inputData }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new question: ', data.data.createCertQuestion);
                    }

                    resolve(data.data.createCertQuestion);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Failed to create new question: ', err);
                    }

                    reject(err);
                });
        });
    };

    const handleSaveNewQuestion = async () => {
        if (question === '' || value === '') {
            props.showPopup('Spørsmålet og poengsum må være fylt ut!');
            return;
        }

        await saveNewQuestion().catch(() => {
            props.showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        props.showPopup('Spørsmålet har blitt lagret');

        await props.updateCategories();

        setQuestion('');
        setDescription('');
        setExplanation('');
        setType('Text');
        setValue(0);
        setCode('');
        setIndustryCodes([]);
        setRequired(true);
        setFilterType('None');
        setOrder(1);
        setMinChars(0);
        setMaxChars(0);
        setOpen();
    };

    const handleOpenDialog = catId => {
        setSubcategoryId(catId);
        setQuestion('');
        setDescription('');
        setExplanation('');
        setType('Text');
        setValue(0);
        setCode('');
        setIndustryCodes([]);
        setRequired(true);
        setFilterType('None');
        setOrder(1);
        setMinChars(0);
        setMaxChars(0);
        setOpen();
    };

    const addCode = () => {
        if (industryCodes.includes(code)) {
            return;
        }

        if (code === '') {
            return;
        }

        let array = industryCodes;

        array.push(code);

        setIndustryCodes([...array]);
        setCode('');
    };

    const removeCode = index => {
        let array = industryCodes;

        array.splice(index, 1);

        setIndustryCodes([...array]);
    };

    const newQuestionDialog = () => {
        return (
            <Grid>
                <Dialog open={open} onClose={setOpen} fullWidth maxWidth="md">
                    <DialogTitle sx={{ fontSize: '2em' }}>Lag nytt spørsmål</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Lag en helt ny spørsmål til valgt underkategori.
                        </DialogContentText>
                        <InputForm
                            label="Spørsmål"
                            placeholder="Skriv inn spørsmålet"
                            value={question}
                            onChange={e => setQuestion(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Beskrivelse"
                            placeholder="Skriv beskrivelsen til spørsmålet"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Hjelpetekst"
                            placeholder="Skriv hjelpeteksten til spørsmålet"
                            value={explanation}
                            onChange={e => setExplanation(e.target.value)}
                        />
                        <InputLabel id="Type">Type spørsmål</InputLabel>
                        <Select
                            labelId="Type"
                            value={type}
                            label="Type spørsmål"
                            onChange={e => setType(e.target.value)}
                        >
                            <MenuItem value={'MultipleChoice'}>Multiple Choice</MenuItem>
                            <MenuItem value={'RadioList'}>Radio List</MenuItem>
                            <MenuItem value={'DropdownSelect'}>Dropdown Select</MenuItem>
                            <MenuItem value={'Number'}>Number</MenuItem>
                            <MenuItem value={'MultipleNumber'}>Multiple Number</MenuItem>
                            <MenuItem value={'Text'}>Text</MenuItem>
                            <MenuItem value={'Textfield'}>Textfield</MenuItem>
                            <MenuItem value={'MultipleText'}>Multiple Text</MenuItem>
                            <MenuItem value={'Slider'}>Slider</MenuItem>
                            <MenuItem value={'MultiDropdownSelectWithInput'}>
                                Multiple Dropdown Select with inputs
                            </MenuItem>
                            <MenuItem value={'MultiRowInput'}>Multiple Rows Input</MenuItem>
                            <MenuItem value={'CustomMultiWithSelect'}>
                                Custom: Multiple med Select (For vannforbruk, sløsing, osv.)
                            </MenuItem>
                            <MenuItem value={'CustomCertifyDropDowns'}>Custom: Sertifisering/utmerkelser</MenuItem>
                        </Select>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={required} onChange={e => setRequired(e.target.checked)} />}
                                label="Er spørsmålet påkrevd?"
                            />
                        </FormGroup>
                        <InputLabel id="Type">Filtreringstype (Hvilke selskaper skal kunne se kriteriet)</InputLabel>
                        <FormControl>
                            <RadioGroup
                                row
                                defaultValue="None"
                                value={filterType}
                                onChange={e => setFilterType(e.target.value)}
                            >
                                <FormControlLabel value="None" control={<Radio />} label="For alle" />
                                <FormControlLabel value="Employees" control={<Radio />} label="Flere ansatte" />
                                <FormControlLabel value="Property" control={<Radio />} label="Bygg" />
                                <FormControlLabel value="Production" control={<Radio />} label="Produsenter" />
                                <FormControlLabel value="Trade" control={<Radio />} label="Varehandel" />
                            </RadioGroup>
                        </FormControl>
                        <NumberInput
                            label="Poengsum"
                            placeholder="Skriv inn hvor mye poeng spørsmålet kan gi"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            min={0}
                        />
                        <NumberInput
                            label="Rekkefølge (lavere nummer betyr høyere på listen)"
                            placeholder="Skriv inn nummer på rekkefølge"
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                        <NumberInput
                            label="Minimum tegn på svar"
                            placeholder="Skriv inn minimum tegn bruker må taste inn"
                            value={minChars}
                            onChange={e => setMinChars(e.target.value)}
                            min={0}
                        />
                        <NumberInput
                            label="Maks tegn på svar (0 er uendelig tegn)"
                            placeholder="Skriv inn maks tegn bruker må taste inn"
                            value={maxChars}
                            onChange={e => setMaxChars(e.target.value)}
                            min={0}
                        />
                        <NumberInput
                            label="Næringskoder - (La være tom hvis det er for alle)"
                            placeholder="Skriv og legg til næringskodene for de som skal se spørsmålet (Bare første 2 tall)"
                            value={code}
                            onChange={e => setCode(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{
                                padding: '20px',
                                marginBottom: '20px',
                                width: '130px',
                            }}
                            onClick={addCode}
                        >
                            Legg til kode
                        </Button>
                        <br />
                        <b>Koder:</b>
                        <br />
                        {industryCodes &&
                            industryCodes.map((code, i) => {
                                return (
                                    <Grid key={code}>
                                        {code}
                                        <Button startIcon={<RemoveCircleOutlineIcon />} onClick={() => removeCode(i)} />
                                        <br />
                                    </Grid>
                                );
                            })}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={setOpen}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleSaveNewQuestion}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid item xs={12}>
            {newQuestionDialog()}
            <h1>Liste over lagret spørsmål</h1>
            {props.categories.length === 0 ? (
                <h2>Det er ingen lagret kategorier.</h2>
            ) : (
                props.categories &&
                props.categories.map(category => {
                    return (
                        <Grid key={category.id}>
                            <Paper
                                sx={{
                                    backgroundColor: '#FBFBFB',
                                    padding: '20px',
                                    marginBottom: '20px',
                                }}
                            >
                                <h2>{category.name}</h2>
                                {category.subcategories.items && category.subcategories.items.length === 0 ? (
                                    <Paper sx={{ padding: '20px' }}>
                                        Det er ingen lagret underkategorier for {category.name}.
                                    </Paper>
                                ) : (
                                    category.subcategories.items &&
                                    category.subcategories.items
                                        .sort((a, b) => {
                                            return a.order - b.order;
                                        })
                                        .map(subcategory => {
                                            return (
                                                <Grid key={subcategory.id}>
                                                    <Typography
                                                        variant="h1"
                                                        sx={{
                                                            textAlign: 'left',
                                                            margin: '10px 0px',
                                                            fontSize: '25px',
                                                        }}
                                                    >
                                                        {subcategory.name}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<AddCircleOutlineIcon />}
                                                        sx={{
                                                            padding: '20px',
                                                            marginBottom: '20px',
                                                            width: '200px',
                                                        }}
                                                        onClick={() => handleOpenDialog(subcategory.id)}
                                                    >
                                                        Legg til nytt spørsmål{' '}
                                                    </Button>
                                                    <br />
                                                    <List>
                                                        {subcategory.questions.items &&
                                                        subcategory.questions.items.length === 0 ? (
                                                            <Grid sx={{ marginBottom: '20px' }}>
                                                                Det er ingen lagret spørsmål for {subcategory.name}.
                                                            </Grid>
                                                        ) : (
                                                            subcategory.questions.items &&
                                                            subcategory.questions.items
                                                                .sort((a, b) => {
                                                                    return a.order - b.order;
                                                                })
                                                                .map(question => {
                                                                    return (
                                                                        <QuestionRowView
                                                                            key={question.id}
                                                                            showPopup={props.showPopup}
                                                                            data={question}
                                                                            update={props.updateCategories}
                                                                        />
                                                                    );
                                                                })
                                                        )}
                                                    </List>
                                                </Grid>
                                            );
                                        })
                                )}
                            </Paper>
                        </Grid>
                    );
                })
            )}
        </Grid>
    );
}
