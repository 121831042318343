import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  //General styles
  contentDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  navbar: {
    height: "100px",
    padding: "0px 40px",
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    width: "45px",
    padding: "20px",
  },
  customGrid: {
    display: "flex",
    alignItems: "center",
  },
  footer: {
    height: "200px",
    padding: "0px 40px",
    display: "flex",
    justifyContent: "space-between",
  },
  searchContent: {
    backgroundColor: "#E0F3F5",
    padding: "40px 0px 40px 0px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px 5px 0px 0px",
  },
  searchInput: {
    width: "800px",
    backgroundColor: "#fff",
  },
  paginationContent: {
    backgroundColor: "#E0F3F5",
    padding: "20px 0px 20px 0px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  summaryHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #E8E8E8",
  },
  //climate chart
  climateContent: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "rgb(255,255,255,0.9)",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    top: "0px",
    left: "0px",
  },
}));
