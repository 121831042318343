import React, { useState } from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { NSRSIcon, ArrowRight } from '../../../../components/Icons';
import NSRSLogo from '../../../../assets/images/nsrs.png';
import * as Text from '../../../../helpers/NSRS.json';

import useRouter from '../../../../hooks/useRouter';
import { useAuth } from '../../../../auth/useAuth';
import { useStyles } from '../NSRSStyles';
import { createNewNSRSReport, createResponse, fetchQuestionDataBasedOnNumber } from '../nsrsFunctions';

export default function NSRSStartModule() {
    const classes = useStyles();
    const router = useRouter();
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(false);

    const createPrefilledResponses = async reportId => {
        let brregData = JSON.parse(auth.companyData.companyData.brregData);
        let addressData = JSON.parse(auth.companyData.companyData.address);

        let input = null;

        // 1.1.1 - rapporteringsperiode
        input = {
            '8a51bc6-ab3d-882e-a54b-28bda41e876': new Date().getFullYear().toString(),
        };

        await createResponse(
            auth.companyData.companyId,
            reportId,
            'information',
            '74c30b6a-83ae-4ef3-8a0e-67899114b853',
            '557a93de-e843-4d5f-8934-4fd8b1d38e37',
            true,
            input,
        );

        // 1.2.1 - påstand
        input = {
            'aee22-f8ba-08db-a3e2-8bd11b834f16':
                'Dette er vårt aller første skritt mot å jobbe med bærekraft. Etter hvert som vi får erfaring med tid og lærer av prosessen, vil vi også heve ambisjonene våre. Vi tar gjerne imot tilbakemeldinger, innspill eller ideer du måtte ha.',
        };

        await createResponse(
            auth.companyData.companyId,
            reportId,
            'information',
            '69db75f0-9067-4b86-9a45-bbd838a7c568',
            'b931f54e-ca88-4fb5-a381-b3fbdca8cefd',
            true,
            input,
        );

        // 2.1.1 - Bedriftsnavn
        if (auth.companyData.name && auth.companyData.name.length !== 0) {
            input = {
                '0473f31-453d-703a-e037-3d22d5018dc': auth.companyData.name,
            };

            await createResponse(
                auth.companyData.companyId,
                reportId,
                'company',
                '5ba1de97-5d9c-4bdf-bc4d-a17d59491586',
                '6acc64ce-f77e-4917-9213-3b3510f8b657',
                true,
                input,
            );
        }

        // 2.1.2 - Org. nummer
        if (auth.companyData.orgNumber && auth.companyData.orgNumber.length !== 0) {
            input = {
                'df7ffe4-bf87-bb03-658f-2eab450f48': auth.companyData.orgNumber,
            };

            await createResponse(
                auth.companyData.companyId,
                reportId,
                'company',
                '5ba1de97-5d9c-4bdf-bc4d-a17d59491586',
                '71629657-58b3-411b-b77a-11783accd6fb',
                true,
                input,
            );
        }

        // 2.1.3 - Organisasjonsform
        if (brregData.organisasjonsform) {
            let formString = brregData.organisasjonsform.beskrivelse + ' (' + brregData.organisasjonsform.kode + ')';
            let fetchedData = await fetchQuestionDataBasedOnNumber(2, 1, 3);
            let questionData = JSON.parse(fetchedData.data);
            let dataId = '';

            for (const alt of questionData[0].elements[0].alternatives) {
                if (alt.label === formString) {
                    dataId = alt.id;
                    break;
                }
            }

            input = {
                'fa4c0b-5cfc-13bd-0815-736f37ac42b2': dataId,
            };

            await createResponse(
                auth.companyData.companyId,
                reportId,
                'company',
                '5ba1de97-5d9c-4bdf-bc4d-a17d59491586',
                '8c6beeef-b615-4aeb-9e3f-e292746e1435',
                true,
                input,
            );
        }

        // 2.1.4 - Nettside
        if (brregData.hjemmeside && brregData.hjemmeside.length !== 0) {
            input = {
                '6f6171f-803c-e604-c7f8-e12fba7c825': brregData.hjemmeside,
            };

            await createResponse(
                auth.companyData.companyId,
                reportId,
                'company',
                '5ba1de97-5d9c-4bdf-bc4d-a17d59491586',
                'e6ad4a06-3709-4d70-8ea5-787a5144d1b2',
                true,
                input,
            );
        }

        // 2.1.5 - hovedlokasjon
        if (addressData) {
            input = {
                '2c51234-4c-056-af85-bedcc87f7832': addressData.adresse[0],
                '2e3337e-2144-3b21-1c7-b52d257aea': addressData.postnummer,
                'd00101-b20-183d-740f-2116cfdb37d': addressData.poststed,
                'e8be612-ed3-f3fb-ff0e-55b7aaf445f1': addressData.kommune,
                '4dc821e-b183-10d6-dae-33a7f5cfa2': addressData.land,
            };

            await createResponse(
                auth.companyData.companyId,
                reportId,
                'company',
                '5ba1de97-5d9c-4bdf-bc4d-a17d59491586',
                '75917bea-199c-4498-992b-8a6ed0129a17',
                true,
                input,
            );
        }

        // 2.2.1 - antall ansatte
        if (brregData.antallAnsatte && brregData.antallAnsatte.length !== 0) {
            input = {
                '545c4ef-77a-7a27-7f5d-20e24c4af6be': brregData.antallAnsatte.toString(),
            };

            await createResponse(
                auth.companyData.companyId,
                reportId,
                'company',
                'fb6a96d8-4d5d-4a4c-baab-580b6b255ddf',
                '5cd4c337-7d6f-4618-8f80-1b58a6b9dbd8',
                true,
                input,
            );
        }

        // 2.3.1 - NACE koder
        if (brregData.naeringskode1) {
            input = {
                'ccf4f3-4a5-515-b2e0-b6c02e010be': [
                    [
                        {
                            id: '7ec0c5a-5032-e014-f64c-be7627fc67d4',
                            label: 'Nacekode',
                            value: brregData.naeringskode1.kode,
                            order: 1,
                        },
                        {
                            id: '6f3037-5bdb-3a67-a831-22b2e8cdd54',
                            label: 'Beskrivelse',
                            value: brregData.naeringskode1.beskrivelse,
                            order: 2,
                        },
                    ],
                ],
            };

            await createResponse(
                auth.companyData.companyId,
                reportId,
                'company',
                '83fefb1c-a99e-4b82-bf4d-6f5edddfadcc',
                '899a227c-d04b-4534-840d-31fb57f53100',
                true,
                input,
            );
        }
    };

    const handleCreateReport = async () => {
        setIsLoading(true);

        await createNewNSRSReport(
            auth.companyData.companyId,
            new Date().getFullYear(),
            JSON.parse(auth.userData.name).fullName,
        ).then(async data => {
            await createPrefilledResponses(data.id);
            setIsLoading(false);

            router.push({
                pathname: '/nsrs/' + data.id,
                state: { report: data },
            });
        });
    };

    return (
        <Paper>
            <Typography variant="h3">{NSRSIcon}NSRS</Typography>
            <Grid item xs={12} className={classes.newSesh}>
                <Grid item xs={2} className={classes.logoSection}>
                    <img src={NSRSLogo} alt="NSRS logo" style={{ width: '100%' }}></img>
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={7} className={classes.section} sx={{ flexDirection: 'column' }}>
                    <Typography variant="h2" sx={{ alignSelf: 'flex-start' }}>
                        Ny NSRS rapport
                    </Typography>
                    <Typography variant="body1">{Text.Summary.text}</Typography>
                </Grid>
                <Grid item xs={3} className={classes.section}>
                    {/*dialogPopUp*/}
                    <Button
                        variant="contained"
                        className={classes.startButton}
                        onClick={handleCreateReport}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <CircularProgress size={36} sx={{ color: 'turquoise' }} />
                        ) : (
                            <>Start {ArrowRight}</>
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
