import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import KeyInfo from './modules/KeyInfo';
import ReportOverview from './ReportOverview';
import BusinessModule from './modules/Business';
import EconomyModule from './modules/Economy';
import SocialModule from './modules/Social';
import EnvironemntModule from './modules/Environment';
import RiskModule from './modules/Risk';
import useRouter from '../../../../hooks/useRouter';
import { ContentLoading } from '../../../../components/Loading';
import { fetchSingleReport, fetchAllCategories } from '../nsrsFunctions';

export default function NSRSOverview() {
    const router = useRouter();
    const { id } = router.query;

    const [report, setReport] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            fetchReport();
            fetchCategories();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchReport = async () => {
        fetchSingleReport(id)
            .then(data => {
                if (!data) {
                    router.push('/nsrs');
                    return;
                }

                setReport(data);
            })
            .catch(() => {
                router.push('/nsrs');
            });
    };

    const fetchCategories = async () => {
        await fetchAllCategories().then(data => {
            setCategories(data);
        });
    };

    if (!report || !categories) {
        return <ContentLoading />;
    } else {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ReportOverview report={report} categories={categories} />
                </Grid>
                <Grid item xs={6}>
                    <KeyInfo
                        report={report}
                        category={categories.find(category => category.id === 'information')}
                        categories={categories}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BusinessModule
                        report={report}
                        category={categories.find(category => category.id === 'company')}
                        categories={categories}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EconomyModule
                        report={report}
                        category={categories.find(category => category.id === 'economy')}
                        categories={categories}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SocialModule
                        report={report}
                        category={categories.find(category => category.id === 'social')}
                        categories={categories}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EnvironemntModule
                        report={report}
                        category={categories.find(category => category.id === 'climate')}
                        categories={categories}
                    />
                </Grid>
                <Grid item xs={6}>
                    <RiskModule
                        report={report}
                        category={categories.find(category => category.id === 'risk')}
                        categories={categories}
                    />
                </Grid>
            </Grid>
        );
    }
}
