import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import OrganisationSummary from "./OrganisationSummary";
import CertificationGiven from "./CertificationGiven";
import Graphs from "./Graphs";
import useRouter from "../../../../hooks/useRouter";
import { SiteLoading } from "../../../../components/Loading";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { getCompanyCertPublicProfile } from "../../../../graphql/queries";

export default function Details() {
  const router = useRouter();
  const history = useHistory();
  let { id } = router.query;

  const [data, setData] = useState(null);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      if (router.location && router.location.data) {
        setData(router.location.data);
      } else {
        fetchPublicProfile().then((fetchedData) => {
          if (fetchedData === null) {
            router.push("/index");
          } else {
            setData(fetchedData);
          }
        });
      }
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Publicly fetch public profile for this company
  const fetchPublicProfile = () => {
    return new Promise(async (resolve) => {
      await API.graphql({
        query: getCompanyCertPublicProfile,
        variables: {
          id: id,
          filter: { status: { eq: "Completed" }, isVisible: { eq: true } },
        },
        authMode: "AWS_IAM",
      }).then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Fetched public profile: ", data);
        }

        resolve(data.data.getCompanyCertPublicProfile);
      });
    });
  };

  if (!data) {
    return <SiteLoading />;
  } else {
    return (
      <Grid container>
        <Button
          variant="contained"
          sx={{ fontSize: "18px" }}
          onClick={() => history.goBack()}
        >
          Gå tilbake
        </Button>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={4}>
            <OrganisationSummary data={data} />
            <CertificationGiven data={data} />
            <Graphs data={data} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
