import React, { useState, useEffect } from "react";
import {
  EditorState,
  Editor,
  convertFromRaw,
  CompositeDecorator,
  convertToRaw,
} from "draft-js";
import { Grid, Avatar, Box, Typography, Button } from "@mui/material";
import { useStyles } from "./DraftStyles";
import { updateComment, deleteComment } from "../../choresFunctions";
import { useAuth } from "../../../../../auth/useAuth";

function getEntityStrategy(mutability) {
  return function(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      if (entityKey === null) {
        return false;
      }
      return contentState.getEntity(entityKey).getMutability() === mutability;
    }, callback);
  };
}

const TokenSpan = (props) => {
  console.log(props);
  return <span data-offset-key={props.offsetkey}>{props.children}</span>;
};

export default function Comment(props) {
  const auth = useAuth();
  const classes = useStyles();

  const [readOnly, setReadOnly] = useState(true);

  const blocks = convertFromRaw(JSON.parse(props.comment.data));

  const Link = ({ entityKey, contentState, children }) => {
    let { url } = contentState.getEntity(entityKey).getData();
    return (
      <a
        style={{ color: "#000000", textDecoration: "underline" }}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  };

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
    {
      strategy: getEntityStrategy("MUTABLE"),
      component: TokenSpan,
    },
  ]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(blocks, decorator)
  );

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      setEditorState(() =>
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(props.comment.data)),
          decorator
        )
      );
    }

    return () => {
      isMounted = true;
    };
  }, [props.comment.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const editor = React.useRef(null);
  function focusEditor() {
    editor.current.focus();
  }

  const addVisibility = () => {
    if (readOnly === true) {
      return "none";
    } else {
      return "inset 1px 1px 4px rgba(0, 0, 0, 0.25)";
    }
  };

  const handleUpdateComment = async () => {
    let log = editorState.getCurrentContent();

    let inputData = {
      id: props.comment.id,
      data: JSON.stringify(convertToRaw(log)),
    };

    await updateComment(inputData).then(() => {
      props.updateTask();

      setReadOnly(true);
    });
  };

  const handleDeleteComment = async () => {
    await deleteComment(props.comment.id).then(() => {
      props.updateTask();
    });
  };

  const renderName = () => {
    let user = props.users.find((user) => {
      return props.comment.userId === user.id;
    });

    let userName = "";

    if (user) {
      userName = JSON.parse(user.name).fullName;
    }

    return userName;
  };

  const renderShortenedName = () => {
    let user = props.users.find((user) => {
      return props.comment.userId === user.id;
    });

    let userName = "";

    if (user) {
      userName = JSON.parse(user.name)
        .fullName.match(/\b\w/g)
        .join("");
    }

    return userName;
  };

  const renderCreatedDate = () => {
    let date = new Date(props.comment.createdAt);

    return (
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " " +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes()
    );
  };

  return (
    <Grid item xs={12} className={classes.commentsGrid}>
      <Grid className={classes.singleComment}>
        <Avatar className={classes.avatar}>{renderShortenedName()}</Avatar>
        <Typography
          variant="body2"
          sx={{ fontSize: "13px", textAlign: "center" }}
        >
          {renderCreatedDate()}
        </Typography>
      </Grid>
      <Box className={classes.box} sx={{ boxShadow: addVisibility() }}>
        <Typography variant="body1">{renderName()}</Typography>
        <div className={classes.editorStyle} onClick={focusEditor}>
          <Editor
            ref={editor}
            editorState={editorState}
            readOnly={readOnly}
            onChange={setEditorState}
            placeholder="Kommentaren er tom!"
          />
        </div>
        {auth.userData.id === props.comment.userId ? (
          <>
            {readOnly ? (
              <Button
                variant="text"
                className={classes.commentButton}
                onClick={() => setReadOnly(false)}
              >
                Rediger
              </Button>
            ) : (
              <Button
                variant="text"
                className={classes.commentButton}
                onClick={handleUpdateComment}
              >
                Lagre
              </Button>
            )}
            <Button
              variant="text"
              className={classes.commentButton}
              onClick={handleDeleteComment}
            >
              Slett
            </Button>
          </>
        ) : (
          <></>
        )}
      </Box>
    </Grid>
  );
}
