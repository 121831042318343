import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { Button, Tooltip } from '@mui/material';
import { useAuth } from '../../../../../auth/useAuth';
import useToggle from '../../../../../hooks/useToggle';
import { s3Bucket, s3Region } from '../../../../../helpers/globalValues';
/* import bytesToSize from "../../../helpers/bytesToSize"; */
import { Attach } from '../../../../../components/Icons';
import { createComment } from '../../choresFunctions';
import SnackPopup from '../../../../../components/SnackPopup';

export default function FileUpload(props) {
    const auth = useAuth();

    const [inputKey, setInputKey] = useState(false);

    const [popupState, setPopupState] = useToggle();
    const [popupMsg, setPopupMsg] = useState('');

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState();
    };

    const handleClosePopup = () => {
        setPopupState();
    };

    const handleFileInput = async e => {
        let file = e.target.files[0];

        await uploadFile(file, 'private');
    };

    // Create a new AWS.S3 object with bucket and region params that will be used in all file-related functions
    const myBucket = new AWS.S3({
        params: { Bucket: s3Bucket },
        region: s3Region,
    });

    const uploadFile = async (file, protLevel) => {
        if (!file) {
            return;
        }

        let isVerified = true;

        var ext = file.name.match(/\.([^]+)$/)[1];
        switch (ext) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'pdf':
            case 'doc':
            case 'docx':
            case 'ppt':
            case 'xls':
            case 'xlsx':
                isVerified = true;
                break;
            default:
                isVerified = false;
                break;
        }

        let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

        if (sizeInMB > 10 || !isVerified) {
            showPopup('Maks størrelse er 10MB og kun standard filtyper (jpg, png, doc, ppt, xls) er støttet.');
            return;
        }

        // Fetch saved credentials for authentication
        AWS.config.credentials.get(err => {
            if (err) {
                return console.log(err);
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                }
            }

            let filePath = '';

            // Since we're not using Amplify, we add a customised process for public/protected/private folders
            if (protLevel === 'public') {
                filePath = 'public/';
            } else if (protLevel === 'protected') {
                filePath = 'protected/' + auth.companyData.companyId + '/';
            } else if (protLevel === 'private') {
                filePath = 'private/' + auth.companyData.companyId + '/';
            } else {
                return;
            }

            const params = {
                Body: file,
                Bucket: s3Bucket,
                Key: filePath + file.name,
            };

            // Upload file to backend based on protection level
            myBucket
                .putObject(params)
                .on('httpUploadProgress', evt => {
                    // Fetches file upload progress
                    //setProgress(Math.round((evt.loaded / evt.total) * 100));
                })
                .send(async err => {
                    // File successfully uploaded
                    if (err) {
                        console.log(err);
                    }

                    setInputKey(!inputKey);
                    //setProgress(0);

                    let fileData = {
                        fileName: file.name,
                        filePath: filePath,
                        size: file.size,
                        lastModified: file.lastModified,
                        type: file.type,
                        level: 'private',
                    };

                    let commentData = {
                        companyId: auth.companyData.companyId,
                        todoTaskId: props.task.id,
                        userId: auth.userData.id,
                        type: 'FILE',
                        data: JSON.stringify(fileData),
                    };

                    await createComment(commentData).then(() => {
                        props.updateTask();
                    });
                });
        });
    };

    /*const fetchFiles = async protLevel => {
        // Fetch saved credentials for authentication
        AWS.config.credentials.get(async err => {
            if (err) {
                return console.log(err);
            } else {
                console.log(AWS.config.credentials);
            }

            let filePath = '';

            // Since we're not using Amplify, we add a customised process for public/protected/private folders
            if (protLevel === 'public') {
                filePath = 'public/';
            } else if (protLevel === 'protected') {
                filePath = 'protected/' + auth.companyData.companyId + '/';
            } else if (protLevel === 'private') {
                filePath = 'private/' + auth.companyData.companyId + '/';
            } else {
                return null;
            }

            // Prefix is the path where we fetch files from
            const params = {
                Bucket: s3Bucket,
                Prefix: filePath,
            };

            let result = await myBucket.listObjectsV2(params).promise();

            console.log(result);

            setFiles(result);
        });
    };*/

    return (
        <div>
            <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
            <input
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                key={inputKey}
                onChange={handleFileInput}
                accept=".doc,.docx,.jpg,.jpeg,.png,.pdf,.ppt,.xls,.xlsx"
            />
            <label htmlFor="raised-button-file">
                <Tooltip title="Last opp fil" arrow>
                    <Button
                        variant="raised"
                        component="span"
                        sx={{ minWidth: '0px', padding: '0px', marginRight: '10px' }}
                    >
                        {Attach}
                    </Button>
                </Tooltip>
            </label>
        </div>
    );
}
