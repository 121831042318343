import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  Button,
  Box,
  Collapse,
  Divider,
  InputLabel,
  InputBase,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TextField,
  InputAdornment,
  NativeSelect,
} from "@mui/material";
import { useStyles, CustomCell, CustomRow } from "../../ClimateStyles";
import { ArrowRight, Add, Delete } from "../../../../../components/Icons";
import * as Text from "../../../../../helpers/climateAccounting.json";
import {
  newClimateResponse,
  updateClimateResponse,
} from "../../climateFunctions";
import { Loading } from "../../../../../components/Loading";
import { useAuth } from "../../../../../auth/useAuth";
import Chores from "../Chores";

export default function InventoryEquipment(props) {
  const {
    CollapseColor,
    CheckedColor,
    IconChange,
    ApplicableCheckBox,
    updateReport,
    reportId,
    questionId,
    data,
    index,
    open,
    changeOpen,
    openNext,
    tasks,
    updateTasks,
    users,
  } = props;
  const classes = useStyles();
  const auth = useAuth();
  const [hasData, setHasData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isRelevant, setIsRelevant] = useState(true);
  const [selectedFactor, setSelectedFactor] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("stk");
  const [amount, setAmount] = useState("");
  const [origin, setOrigin] = useState("Gjenbruk");
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      checkForData();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkForData = async () => {
    if (data !== null && data !== undefined) {
      let dataObj = JSON.parse(data.data);
      setAnswers(dataObj);

      if (!data.isRelevant) {
        setIsAnswered(true);
        setIsRelevant(false);
      } else if (dataObj.length !== 0) {
        setIsAnswered(true);
      }

      setHasData(true);
    } else {
      setHasData(false);
    }
  };

  const saveData = async (check) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    let newCheck = check;

    if (newCheck === null || newCheck === undefined) {
      newCheck = true;
    }

    if (hasData) {
      await updateClimateResponse(
        data.id,
        auth.companyData.companyId,
        reportId,
        questionId,
        3,
        answers,
        newCheck
      ).then(async () => {
        await updateReport();

        setIsSaving(false);
      });
    } else {
      await newClimateResponse(
        auth.companyData.companyId,
        reportId,
        questionId,
        3,
        answers,
        newCheck
      ).then(async () => {
        await updateReport();

        setHasData(true);
        setIsSaving(false);
      });
    }
  };

  const handleAddAnswer = async () => {
    let input = {
      factor: selectedFactor,
      type: selectedUnit,
      amount: amount,
      origin: origin,
    };

    let newArr = answers;

    newArr.push(input);

    if (newArr.length !== 0) {
      setIsAnswered(true);
    }

    setAnswers([...newArr]);

    setSelectedFactor("");
    setSelectedUnit("stk");
    setResetValue(!resetValue);
    setAmount("");
    setOrigin("Gjenbruk");

    await saveData();
  };

  const handleRemoveAnswer = async (index) => {
    let newArr = answers;

    newArr.splice(index, 1);

    if (newArr.length === 0) {
      setIsAnswered(false);
    }

    setAnswers([...newArr]);

    await saveData();
  };

  const handleCheck = async (e) => {
    setIsRelevant(!e.target.checked);

    if (e.target.checked) {
      setIsAnswered(true);
    } else {
      setIsAnswered(false);
    }

    await saveData(!e.target.checked);
  };

  const handleSave = async () => {
    await saveData(isRelevant);

    openNext(index);
  };

  return (
    <React.Fragment>
      <TableRow
        id={questionId}
        className={classes.question}
        sx={{
          background: CollapseColor(isAnswered, open),
        }}
      >
        <TableCell
          className={classes.iconCell}
          style={{
            backgroundColor: CheckedColor(isAnswered),
          }}
          align="center"
          onClick={() => changeOpen(index, !open)}
        >
          {IconChange(isAnswered)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          className={classes.questionRow}
          onClick={() => changeOpen(index, !open)}
        >
          <Typography variant="h3">Innkjøp av inventar og utstyr</Typography>
        </TableCell>
        <TableCell className={classes.applicable}>
          {ApplicableCheckBox(isRelevant, handleCheck)}
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: "20px" }}>
              <Typography variant="body1">{Text.Scope3.Inventory}</Typography>
              <Divider className={classes.divider} />
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid item xs={6} sx={{ marginRight: "10px" }}>
                  <InputLabel>Type/navn</InputLabel>
                  <InputBase
                    type="text"
                    name="type"
                    placeholder="Skriv inn type/navn..."
                    onChange={(e) => setSelectedFactor(e.target.value)}
                    value={selectedFactor}
                  />
                  <Typography variant="body2">
                    <i>Trykk "legg til" for å legge til i lista</i>
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{ marginRight: "10px" }}>
                  <InputLabel>Mengde/antall</InputLabel>
                  <TextField
                    type="number"
                    name="unit"
                    placeholder={selectedFactor !== "" ? "Skriv her..." : "0"}
                    disabled={selectedFactor !== "" ? false : true}
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <NativeSelect
                            className={classes.unitSelect}
                            value={selectedUnit}
                            onChange={(e) => setSelectedUnit(e.target.value)}
                            disabled={selectedFactor !== "" ? false : true}
                            input={<InputBase />}
                          >
                            <option value="stk">stk</option>
                            <option value="liter">liter</option>
                            <option value="kg">kg</option>
                          </NativeSelect>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginRight: "10px" }}>
                  <InputLabel>Opphav</InputLabel>
                  <NativeSelect
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                    input={<InputBase />}
                    style={{ marginRight: 30 }}
                  >
                    <option value="Gjenbruk">Gjenbruk</option>
                    <option value="Nytt">Nytt</option>
                    <option value="Laget av resirkulert materiale">
                      Laget av resirkulert materiale
                    </option>
                    <option value="Laget av nedbrytbart materiale">
                      Laget av nedbrytbart materiale
                    </option>
                  </NativeSelect>
                </Grid>
                <Grid item sx={{ marginTop: "32px" }}>
                  <Button
                    variant="contained"
                    sx={{ width: "130px" }}
                    onClick={handleAddAnswer}
                    disabled={amount > 0 && amount !== "" ? false : true}
                  >
                    {Add}Legg til
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <CustomRow>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Type/navn
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Mengde/antall
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Opphav
                        </CustomCell>
                        <CustomCell
                          align="center"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Slett
                        </CustomCell>
                      </CustomRow>
                    </TableHead>
                    <TableBody>
                      {answers &&
                        answers.map((answer, index) => {
                          return (
                            <CustomRow key={answer.factor}>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.factor}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.amount} {answer.type}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.origin}
                              </CustomCell>
                              <CustomCell
                                align="center"
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                                onClick={() => handleRemoveAnswer(index)}
                              >
                                {Delete}
                              </CustomCell>
                            </CustomRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container direction="row" className={classes.content}>
                {isSaving ? (
                  <Loading />
                ) : (
                  <Button
                    disabled={false}
                    variant="contained"
                    onClick={handleSave}
                  >
                    Lagre{ArrowRight}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <Divider sx={{ border: "1px solid #E1E1E1" }} />
                <Typography variant="h2" sx={{ marginTop: "10px" }}>
                  Tilknyttede gjøremål
                </Typography>
                <Chores
                  tasks={tasks}
                  updateTasks={updateTasks}
                  users={users}
                  id={questionId}
                  reportId={reportId}
                  scope={3}
                />
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: "20px" }} />
    </React.Fragment>
  );
}
