import * as AWS from 'aws-sdk';
import { API, graphqlOperation } from 'aws-amplify';
import { listDiscountCodes, getCertQuestion } from '../../../graphql/queries';
import {
    createCompanyData,
    createCompany,
    createCompanyCertData,
    createCompanyCertHistoryData,
    createCompanyCertPublicProfile,
    updateCompanyData,
    updateUser,
    deleteUser,
    createCertResponse,
} from '../../../graphql/mutations';
import { listCompanysWithoutData } from '../../../graphql/custom/customQueries';
import { companyDataTableName, userTableName } from '../../../helpers/globalValues';

import welcomeFirstUserMail from '../../../assets/emails/welcomeFirstUserMail';
import requestAccessMail from '../../../assets/emails/requestAccessMail';
import deletedUserMail from '../../../assets/emails/deletedUserMail';
import newCustomerMail from '../../../assets/emails/newCustomerMail';
import ecofricCustomerMail from '../../../assets/emails/ecofricCustomerMail';

import getDateFromTimestamp from '../../../helpers/getDateFromTimestamp';

import amplitude from 'amplitude-js';

// OBS: Remember to change values between production and development

/** LOCAL FUNCTIONS **/

// Fetch company directly from dynamoDB table(doesn't require cognito access)
const fetchCompanyAdminsFromDB = async companyId => {
    return await new Promise((resolve, reject) => {
        let docClient = new AWS.DynamoDB.DocumentClient();

        let dbParams = {
            TableName: companyDataTableName,
            FilterExpression: '#company = :company_val',
            ExpressionAttributeNames: {
                '#company': 'id',
            },
            ExpressionAttributeValues: { ':company_val': companyId },
        };

        docClient.scan(dbParams, (err, data) => {
            if (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Unable to scan Dynamo DB for company data: ', err);
                }

                reject(null);
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Fetched company data from Dynamo DB: ', data);
                }

                resolve(data.Items[0].admins);
            }
        });
    });
};

// Fetch users directly from dynamoDB table (doesn't require cognito access)
const fetchUsersFromDB = async companyId => {
    return await new Promise((resolve, reject) => {
        let docClient = new AWS.DynamoDB.DocumentClient();

        let dbParams = {
            TableName: userTableName,
            FilterExpression: '#user_company = :user_company_val',
            ExpressionAttributeNames: {
                '#user_company': 'companyId',
            },
            ExpressionAttributeValues: { ':user_company_val': companyId },
        };

        docClient.scan(dbParams, (err, data) => {
            if (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Unable to scan Dynamo DB for user data: ', err);
                }

                reject(null);
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Fetched user data from Dynamo DB: ', data);
                }

                resolve(data.Items);
            }
        });
    });
};

// Email promise for welcoming the first user of the created company
const sendWelcomeMailToUser = async (userName, userEmail, orgName) => {
    return new Promise(async (resolve, reject) => {
        // E-mail data
        let mailData = {
            name: userName,
            orgName: orgName,
        };

        // Params for sending a welcome email
        const params = {
            Destination: {
                ToAddresses: [userEmail],
            },
            Message: {
                Subject: {
                    Charset: 'UTF-8',
                    Data: 'Velkommen til Ecofric',
                },
                Body: {
                    Html: {
                        Charset: 'UTF-8',
                        Data: welcomeFirstUserMail(mailData),
                    },
                },
            },
            Source: '"Ecofric" <hello@ecofric.com>',
        };

        const sendPromise = new AWS.SES().sendEmail(params).promise();

        // Wait for email to be sent
        await sendPromise.then(data => resolve(data)).catch(err => reject(err));
    });
};

// Email promise for sending request emails to all admins of a company
const sendRequestMailToAdmins = async (adminList, userName, userLastName, userEmail, orgName) => {
    return new Promise(async (resolve, reject) => {
        // E-mail data
        let mailData = {
            name: userName,
            lastName: userLastName,
            userEmail: userEmail,
            orgName: orgName,
        };

        // Params for sending a welcome email
        const params = {
            Destination: {
                ToAddresses: adminList,
            },
            Message: {
                Subject: {
                    Charset: 'UTF-8',
                    Data: 'Noen ber om tilgang til organisasjonen din',
                },
                Body: {
                    Html: {
                        Charset: 'UTF-8',
                        Data: requestAccessMail(mailData),
                    },
                },
            },
            Source: '"Ecofric" <hello@ecofric.com>',
        };

        const sendPromise = new AWS.SES().sendEmail(params).promise();

        // Wait for email to be sent
        await sendPromise.then(data => resolve(data)).catch(err => reject(err));
    });
};

// Email promise for sending request emails to all admins of a company
const sendDeletedUserMail = async (email, name) => {
    return new Promise(async (resolve, reject) => {
        // E-mail data
        let mailData = {
            name: name,
        };

        // Params for sending a welcome email
        const params = {
            Destination: {
                ToAddresses: [email],
            },
            Message: {
                Subject: {
                    Charset: 'UTF-8',
                    Data: 'Din bruker hos Ecofric er nå slettet',
                },
                Body: {
                    Html: {
                        Charset: 'UTF-8',
                        Data: deletedUserMail(mailData),
                    },
                },
            },
            Source: '"Ecofric" <hello@ecofric.com>',
        };

        const sendPromise = new AWS.SES().sendEmail(params).promise();

        // Wait for email to be sent
        await sendPromise.then(data => resolve(data)).catch(err => reject(err));
    });
};

// Email promise for welcoming the first user of the created company
const sendNewCustomerMailToUser = async (userName, userEmail, orgName) => {
    return new Promise(async (resolve, reject) => {
        // E-mail data
        let mailData = {
            name: userName,
            orgName: orgName,
        };

        // Params for sending a welcome email
        const params = {
            Destination: {
                ToAddresses: [userEmail],
            },
            Message: {
                Subject: {
                    Charset: 'UTF-8',
                    Data: 'Velkommen til Ecofric',
                },
                Body: {
                    Html: {
                        Charset: 'UTF-8',
                        Data: newCustomerMail(mailData),
                    },
                },
            },
            Source: '"Ecofric" <hello@ecofric.com>',
        };

        const sendPromise = new AWS.SES().sendEmail(params).promise();

        // Wait for email to be sent
        await sendPromise.then(data => resolve(data)).catch(err => reject(err));
    });
};

// Email promise for sending Ecofric a notification with info about which companies turned into customers
const sendEmailToEcofricAboutCustomer = async (auth, invoiceData) => {
    return new Promise(async (resolve, reject) => {
        let discountText = '';
        let expDateText = '';

        let addressData = JSON.parse(auth.companyData.companyData.address);
        let addressText = addressData.adresse[0] + ', ' + addressData.postnummer + ' ' + addressData.kommune;

        if (invoiceData.discountCode === null) {
            discountText = 'Ingen kode brukt';
        } else {
            discountText = invoiceData.discountCode;
        }

        if (invoiceData.codeExpDate === null) {
            expDateText = 'Ingen kode brukt';
        } else {
            expDateText = getDateFromTimestamp(invoiceData.codeExpDate);
        }

        // E-mail data
        let mailData = {
            orgName: auth.companyData.name,
            orgNr: auth.companyData.orgNumber,
            companyId: auth.companyData.companyId,
            date: getDateFromTimestamp(invoiceData.startDate),
            discountCode: discountText,
            codeExpDate: expDateText,
            price: invoiceData.price,
            priceWithDiscount: invoiceData.priceWithDiscount,
            invoiceEmail: invoiceData.email,
            address: addressText,
        };

        // Params for sending a welcome email
        const params = {
            Destination: {
                ToAddresses: ['hello@ecofric.com'],
            },
            Message: {
                Subject: {
                    Charset: 'UTF-8',
                    Data: auth.companyData.name + ' har blitt kunde - Ecofric',
                },
                Body: {
                    Html: {
                        Charset: 'UTF-8',
                        Data: ecofricCustomerMail(mailData),
                    },
                },
            },
            Source: 'webmaster@ecofric.com',
        };

        const sendPromise = new AWS.SES().sendEmail(params).promise();

        // Wait for email to be sent
        await sendPromise.then(data => resolve(data)).catch(err => reject(err));
    });
};

// Creates a new response
const createResponse = async inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createCertResponse, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Get specific cert question
const getQuestion = async id => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(getCertQuestion, { id: id }))
            .then(data => {
                resolve(data.data.getCertQuestion);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Create new company-based response
const createCompanyResponse = async (brregData, companyId, certDataId, questionId, userId) => {
    return new Promise(async (resolve, reject) => {
        let addressData = brregData.forretningsadresse;

        let dataObj = {
            companyId: companyId,
            companyCertDataId: certDataId,
            categoryId: 'company',
            questionId: questionId,
            pointsEarned: 0,
            isAnswered: true,
            answers: [],
            text: '',
            longText: '',
            multiText: JSON.stringify([]),
            multiDropSelect: JSON.stringify([]),
            number: parseInt(''),
            multiNumber: JSON.stringify([]),
            multiRows: JSON.stringify([]),
            otherField: '',
        };

        if (brregData) {
            // Bedriftsnavn
            if (questionId === '85cace3a-32f1-4e6c-beaa-cf7bd16c1126') {
                if (brregData.navn) {
                    dataObj.text = brregData.navn;
                }

                // Org. nr
            } else if (questionId === 'b6f6b02d-dced-4fc1-8880-299836da9e7f') {
                if (brregData.organisasjonsnummer) {
                    dataObj.number = brregData.organisasjonsnummer;
                }

                // Selskapsform
            } else if (questionId === '2ce48ab8-c561-4bbe-bc22-00075a9ea7e8') {
                if (brregData.organisasjonsform) {
                    let questionData = await getQuestion('2ce48ab8-c561-4bbe-bc22-00075a9ea7e8');

                    let newArr = [];
                    let newValue = '';

                    newValue = brregData.organisasjonsform.beskrivelse + ' (' + brregData.organisasjonsform.kode + ')';

                    const option = questionData.options.items.find(option => option.option === newValue);

                    if (option) {
                        newArr.push(option.id);

                        dataObj.answers = [...newArr];
                    }
                }

                // Næringskode
            } else if (questionId === 'd1126dd9-9d15-47d0-8e28-c6f8da8709f4') {
                let newArr = [];

                if (brregData.naeringskode1) {
                    let newDataArr = [];

                    let dataObj = {
                        name: 'Næringskode',
                        value: brregData.naeringskode1.kode,
                        order: 1,
                    };

                    newDataArr.push(dataObj);
                    newArr.push(newDataArr);
                }

                if (brregData.naeringskode2) {
                    let newDataArr = [];

                    let dataObj = {
                        name: 'Næringskode',
                        value: brregData.naeringskode2.kode,
                        order: 2,
                    };

                    newDataArr.push(dataObj);
                    newArr.push(newDataArr);
                }

                if (brregData.naeringskode3) {
                    let newDataArr = [];

                    let dataObj = {
                        name: 'Næringskode',
                        value: brregData.naeringskode3.kode,
                        order: 3,
                    };

                    newDataArr.push(dataObj);
                    newArr.push(newDataArr);
                }

                dataObj.multiRows = JSON.stringify([...newArr]);

                // Adresser
            } else if (questionId === '704a4c33-3536-4225-9d2f-eb293dc3d1d1') {
                if (addressData) {
                    let newArr = [];

                    for (const address of addressData.adresse) {
                        let newDataArr = [];

                        let addressObj = {
                            name: 'Gate og gatenr',
                            value: address,
                            order: 1,
                        };

                        let zipCodeObj = {
                            name: 'Postnummer',
                            value: addressData.postnummer,
                            order: 2,
                        };

                        let postOfficeObj = {
                            name: 'Poststed',
                            value: addressData.poststed,
                            order: 3,
                        };

                        let stateObj = {
                            name: 'Kommune',
                            value: addressData.kommune,
                            order: 4,
                        };

                        newDataArr.push(addressObj);
                        newDataArr.push(zipCodeObj);
                        newDataArr.push(postOfficeObj);
                        newDataArr.push(stateObj);

                        newArr.push(newDataArr);
                    }

                    dataObj.multiRows = JSON.stringify([...newArr]);
                }
            } else if (questionId === 'f88e71b2-bc8b-4c8b-9366-43054ba9ba53') {
                if (brregData.antallAnsatte) {
                    dataObj.number = brregData.antallAnsatte;
                }
            }

            // Create new historic object for their certification
            let historyDataInput = {
                companyId: companyId,
                companyCertDataId: certDataId,
                action: 'CREATE_RESPONSE',
                timestamp: new Date(),

                // Optional
                userId: userId,
                description: 'Created new response for the first time for a question within a main category.',
                categoryId: 'company',
                questionId: questionId,
                newData: JSON.stringify(dataObj),
                economyScore: 0,
                economyPercentScore: 0,
                socialScore: 0,
                socialPercentScore: 0,
                environmentalScore: 0,
                environmentalPercentScore: 0,
            };

            await API.graphql(graphqlOperation(createCompanyCertHistoryData, { input: historyDataInput }))
                .then(async () => {
                    await createResponse(dataObj)
                        .then(() => {
                            resolve();
                        })
                        .catch(err => {
                            reject(err);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        }
    });
};

/** EXPORTED FUNCTIONS **/

// Checks if a company based on the org. number exists and returns true or false
export function checkForCompany(orgNr) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listCompanysWithoutData, {
                filter: { orgNumber: { eq: orgNr } },
            }),
        )
            .then(data => {
                if (data.data.listCompanys.items.length === 1) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}

// Creates the company in backend and sets the user as admin
export function createNewCompany(userData, brregData, auth) {
    return new Promise(async (resolve, reject) => {
        // Set up that the company is a trial account
        let startDate = new Date();
        let trialExpDate = new Date();
        trialExpDate.setDate(trialExpDate.getDate() + 14);

        // Invoice data
        let invoiceObj = {
            status: 'Trial',
            email: null,
            startDate: startDate,
            expDate: trialExpDate,
            discountCode: null,
            codeExpDate: null,
            price: 250,
            priceWithDiscount: null,
        };

        // Input data for creating a new CompanyData object
        let companyDataInput = {
            name: brregData.navn,
            orgNumber: brregData.organisasjonsnummer,
            address: JSON.stringify(brregData.forretningsadresse),
            invoice: JSON.stringify(invoiceObj),
            admins: [userData.id],
            status: 'Active',
            betaOptIn: false,
            brregData: JSON.stringify(brregData),
        };

        let companyData = await API.graphql(graphqlOperation(createCompanyData, { input: companyDataInput }))
            .then(data => {
                return data.data.createCompanyData;
            })
            .catch(err => {
                reject(err);
            });

        // Input data for creating a new Company object
        let companyInput = {
            name: brregData.navn,
            companyId: companyData.id,
            orgNumber: brregData.organisasjonsnummer,
            companyCompanyDataId: companyData.id,
        };

        await API.graphql(graphqlOperation(createCompany, { input: companyInput })).catch(err => {
            reject(err);
        });

        // Update the tenant ID for the user in Cognito
        await auth.updateTenantId(userData.id, companyData.id).catch(err => {
            reject(err);
        });

        // Update the user's status and company ID
        await API.graphql(
            graphqlOperation(updateUser, {
                input: {
                    id: userData.id,
                    status: 'ExistingUser',
                    companyId: companyData.id,
                },
            }),
        ).catch(err => {
            reject(err);
        });

        let industryCodes = [];

        if (brregData.naeringskode1) {
            industryCodes.push(parseInt(brregData.naeringskode1.kode));
        }

        if (brregData.naeringskode2) {
            industryCodes.push(parseInt(brregData.naeringskode2.kode));
        }

        if (brregData.naeringskode3) {
            industryCodes.push(parseInt(brregData.naeringskode3.kode));
        }

        // Create new data object for the company's public profile
        let companyCertPublicProfileData = {
            companyId: companyData.id,
            status: 'Draft',
            certType: 'ESG',
            certFilterTypes: [],
            employeeAmount: brregData.antallAnsatte,
            industryCodes: industryCodes,
            isVisible: false,
            companyName: brregData.navn,
            orgNumber: brregData.organisasjonsnummer,
            description: 'Dette selskapet har ingen beskrivelse.',
            contactPerson: JSON.parse(userData.name).fullName,
            phoneNumber: userData.phoneNumber,
            website: '',
            economyScore: 0,
            economyPercentScore: 0,
            socialScore: 0,
            socialPercentScore: 0,
            environmentalScore: 0,
            environmentalPercentScore: 0,
        };

        let publicProfileData = await API.graphql(
            graphqlOperation(createCompanyCertPublicProfile, { input: companyCertPublicProfileData }),
        )
            .then(data => {
                return data.data.createCompanyCertPublicProfile;
            })
            .catch(err => {
                reject(err);
            });

        // Input data for creating a new Company cert data object
        let companyCertDataInput = {
            companyId: companyData.id,
            status: 'Draft',
            certType: 'ESG',
            certFilterTypes: [],
            employeeAmount: brregData.antallAnsatte,
            industryCodes: industryCodes,
            companyCertDataPublicProfileId: publicProfileData.id,
        };

        let companyCertData = await API.graphql(
            graphqlOperation(createCompanyCertData, { input: companyCertDataInput }),
        )
            .then(data => {
                return data.data.createCompanyCertData;
            })
            .catch(err => {
                reject(err);
            });

        // Create new historic object for their certification
        let historyDataInput = {
            // Required
            companyId: companyData.id,
            companyCertDataId: companyCertData.id,
            action: 'CREATE_CERTIFICATION_SIGNUP',
            timestamp: new Date(),

            // Optional
            userId: userData.id,
            description: 'Created new certification at signup.',
        };

        await API.graphql(graphqlOperation(createCompanyCertHistoryData, { input: historyDataInput })).catch(err => {
            reject(err);
        });

        // Create all the brregData responses in advance
        // Bedriftsnavn
        await createCompanyResponse(
            brregData,
            companyData.id,
            companyCertData.id,
            '85cace3a-32f1-4e6c-beaa-cf7bd16c1126',
            userData.id,
        );
        // Org nr.
        await createCompanyResponse(
            brregData,
            companyData.id,
            companyCertData.id,
            'b6f6b02d-dced-4fc1-8880-299836da9e7f',
            userData.id,
        );
        // Selskapsform
        await createCompanyResponse(
            brregData,
            companyData.id,
            companyCertData.id,
            '2ce48ab8-c561-4bbe-bc22-00075a9ea7e8',
            userData.id,
        );
        // Næringskode
        await createCompanyResponse(
            brregData,
            companyData.id,
            companyCertData.id,
            'd1126dd9-9d15-47d0-8e28-c6f8da8709f4',
            userData.id,
        );
        // Adresser
        await createCompanyResponse(
            brregData,
            companyData.id,
            companyCertData.id,
            '704a4c33-3536-4225-9d2f-eb293dc3d1d1',
            userData.id,
        );
        // Ansatte
        await createCompanyResponse(
            brregData,
            companyData.id,
            companyCertData.id,
            'f88e71b2-bc8b-4c8b-9366-43054ba9ba53',
            userData.id,
        );

        await sendWelcomeMailToUser(JSON.parse(userData.name).name, userData.email, brregData.navn)
            .then(() => {
                if (process.env.NODE_ENV !== 'development') {
                    amplitude.getInstance().logEvent('new_company_created', { companyId: companyData.id });
                }

                resolve();
            })
            .catch(err => {
                reject(err);
            });
    });
}

// Sends a request asking for permission to join to all the company's admins
export function requestCompanyAccess(userData, orgNr) {
    return new Promise(async (resolve, reject) => {
        const companyData = await API.graphql(
            graphqlOperation(listCompanysWithoutData, {
                filter: { orgNumber: { eq: orgNr } },
            }),
        )
            .then(data => {
                return data.data.listCompanys.items[0];
            })
            .catch(err => {
                reject(err);
            });

        await API.graphql(
            graphqlOperation(updateUser, {
                input: {
                    id: userData.id,
                    status: 'RequestingAccess',
                    companyId: companyData.companyId,
                },
            }),
        ).catch(err => {
            reject(err);
        });

        const users = await fetchUsersFromDB(companyData.companyId)
            .then(async dbUsers => {
                return dbUsers;
            })
            .catch(err => {
                reject(err);
            });

        const admins = await fetchCompanyAdminsFromDB(companyData.companyId)
            .then(async admins => {
                return admins;
            })
            .catch(err => {
                reject(err);
            });

        let adminEmailList = [];

        for (const user of users) {
            if (admins.includes(user.id)) {
                adminEmailList.push(user.email);
            }
        }

        await sendRequestMailToAdmins(
            adminEmailList,
            JSON.parse(userData.name).name,
            JSON.parse(userData.name).lastName,
            userData.email,
            companyData.name,
        )
            .then(() => {
                if (process.env.NODE_ENV !== 'development') {
                    amplitude
                        .getInstance()
                        .logEvent('user_requesting_company_access', { companyId: companyData.companyId });
                }

                resolve();
            })
            .catch(err => {
                reject(err);
            });
    });
}

// Deletes the user's account from cognito and DynamoDB
export function deleteAccount(auth) {
    return new Promise(async (resolve, reject) => {
        let userEmail = auth.userData.email;
        let userId = auth.userData.id;
        let userName = JSON.parse(auth.userData.name).name;

        await API.graphql(graphqlOperation(deleteUser, { input: { id: userId } })).catch(err => {
            reject(err);
        });

        await auth.deleteUser().catch(err => {
            if (process.env.NODE_ENV === 'development') {
                console.error("Failed to delete the user's account: ", err);
            }

            reject(err);
        });

        await sendDeletedUserMail(userEmail, userName).then(() => {
            auth.clearSession();
            resolve();
        });
    });
}

// Fetches data of a valid discount code from backend. If it doesn't exist, returns catch error
export function fetchDiscountCode(code) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listDiscountCodes, {
                filter: { code: { eq: code } },
            }),
        )
            .then(data => {
                resolve(data.data.listDiscountCodes.items);
            })
            .catch(err => {
                reject(err);
            });
    });
}

// Updates the company's invoice email
export function updateCompanyInvoiceEmail(email, auth) {
    return new Promise(async resolve => {
        let invoiceObj = JSON.parse(auth.companyData.companyData.invoice);

        invoiceObj.email = email;

        await API.graphql(
            graphqlOperation(updateCompanyData, {
                input: {
                    invoice: JSON.stringify(invoiceObj),
                    id: auth.companyData.companyData.id,
                },
            }),
        ).then(data => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Updated invoice: ', data);
            }

            resolve();
        });
    });
}

// Updates the company's address and invoice email
export function updateCompanyAddress(address, zipcode, state, auth) {
    return new Promise(async resolve => {
        let addressObj = JSON.parse(auth.companyData.companyData.address);

        addressObj.adresse[0] = address;
        addressObj.postnummer = zipcode;
        addressObj.poststed = state;

        await API.graphql(
            graphqlOperation(updateCompanyData, {
                input: {
                    address: JSON.stringify(addressObj),
                    id: auth.companyData.companyData.id,
                },
            }),
        ).then(data => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Updated address: ', data);
            }

            resolve();
        });
    });
}

// Updates company data to turn them from a Trial subscription to an active customer subscription
export function updateCompanyToCustomer(invoiceData, auth) {
    return new Promise(async resolve => {
        await API.graphql(
            graphqlOperation(updateCompanyData, {
                input: {
                    invoice: JSON.stringify(invoiceData),
                    id: auth.companyData.companyData.id,
                },
            }),
        ).then(async data => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Updated invoice and set company as subscribing customer: ', data);
            }

            await sendNewCustomerMailToUser(
                JSON.parse(auth.userData.name).name,
                auth.userData.email,
                auth.companyData.name,
            ).then(async () => {
                if (process.env.NODE_ENV !== 'development') {
                    amplitude
                        .getInstance()
                        .logEvent('company_becomes_customer', { companyId: auth.companyData.companyData.id });

                    await sendEmailToEcofricAboutCustomer(auth, invoiceData).then(() => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        });
    });
}
