import React, { useState, useEffect } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { NumberInput } from '../../../../../components/InputForm';

import { API, graphqlOperation } from 'aws-amplify';
import { updateClimateEmissionFactorType, deleteClimateEmissionFactorType } from '../../../../../graphql/mutations';

export default function EmissionFactorTypeRowView(props) {
    const [dialogOpen, setDialogOpen] = useToggle();

    const [type, setType] = useState('');
    const [value, setValue] = useState(0.0);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            setType(props.data.type);
            setValue(props.data.value);
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateType = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateClimateEmissionFactorType, {
                    input: {
                        id: props.data.id,
                        type: type,
                        value: value,
                    },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated emission factor type: ', data);
                }

                resolve();
            });
        });
    };

    const deleteType = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(deleteClimateEmissionFactorType, {
                    input: { id: props.data.id },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Deleted emission factor type: ', data);
                }

                resolve();
            });
        });
    };

    const handleUpdateType = async () => {
        if (type === '' || value === '') {
            props.showPopup('Type og verdi må være fylt ut!');
            return;
        }

        await updateType().catch(() => {
            props.showPopup('En feil oppsto ved lagring av utslippstype');
            return;
        });

        props.showPopup('Utslippstype har blitt lagret');

        await props.update();

        setDialogOpen();
    };

    const handleDeleteType = async () => {
        await deleteType().catch(() => {
            props.showPopup('En feil oppsto ved sletting av utslippstype');
            return;
        });

        props.showPopup('Utslippstype har blitt slettet');

        await props.update();
    };

    const typeDialog = () => {
        return (
            <Grid>
                <Dialog open={dialogOpen} onClose={setDialogOpen} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Oppdater utslippstype</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>Oppdater utslippstype.</DialogContentText>
                        <InputForm
                            label="Type"
                            placeholder="Skriv inn..."
                            value={type}
                            onChange={e => setType(e.target.value)}
                        />
                        <NumberInput
                            label="Verdi til faktoren"
                            placeholder="Skriv inn..."
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            min={0.0}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={setDialogOpen}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleDeleteType}>
                            Slett
                        </Button>
                        <Button variant="primary" onClick={handleUpdateType}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <ListItem disablePadding>
            {typeDialog()}
            <ListItemButton onClick={setDialogOpen}>
                <ListItemIcon>
                    <ModeEditOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={props.data.type + ' - ' + props.data.value} />
            </ListItemButton>
        </ListItem>
    );
}
