import React, { useState } from 'react';
import useToggle from '../../../../hooks/useToggle';
import useRouter from '../../../../hooks/useRouter';
import { useAuth } from '../../../../auth/useAuth';
import SnackPopup from '../../../../components/SnackPopup';
import { Loading } from '../../../../components/Loading';
import { Grid, Typography, Button } from '@mui/material';
//import Discount from "./Discount";
import * as Strings from '../../../../helpers/strings';
import { createNewCompany } from '../termsFunctions';
import { useStyles } from '../TermsStyle';

export default function CreateCompany(props) {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();
    const [popupState, setPopupState] = useToggle();
    const [popupMsg, setPopupMsg] = useState('');
    const [isLoading, setIsLoading] = useToggle();

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState();
    };

    const handleClosePopup = () => {
        setPopupState();
    };

    const handleCreateNewCompany = () => {
        setIsLoading();

        createNewCompany(auth.userData, props.data, auth)
            .then(() => {
                router.history.go(0);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to create new company: ', err);
                }

                showPopup('En feil har oppstått, vennligst prøv igjen eller start prosessen på nytt');
                setIsLoading();
            });
    };

    return (
        <Grid item xs={12}>
            <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
            <Typography variant="h1">{Strings.createCompanyStrings.createTitle}</Typography>
            <Typography className="text">{Strings.createCompanyStrings.createDescription}</Typography>
            <Typography className="text" sx={{ marginTop: '10px' }}>
                Sertifiseringen er gyldig i et år og vil være på 250,- kr per måned.
            </Typography>
            {/*<Typography variant="h4">Har du en rabattkode?</Typography>
            <Discount />*/}
            <Grid item sx={{ marginTop: '20px' }}>
                {isLoading ? (
                    <Loading color="primary" />
                ) : (
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <Button
                            variant="contained"
                            className={classes.fiftyButton}
                            onClick={() => handleCreateNewCompany()}
                        >
                            Start prøveperiode
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.greyButton}
                            onClick={() => props.setView('select')}
                        >
                            Valgt feil organisasjon?
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
