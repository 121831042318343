import { API, graphqlOperation } from 'aws-amplify';

import {
    listCompanyClimateAccReports,
    listCompanyNSRSReports,
    getCompanyNSRSReport,
    getNSRSCategory,
    listNSRSCategorys,
    listNSRSResponses,
} from '../../../graphql/queries';
import {
    createCompanyNSRSReport,
    updateCompanyNSRSReport,
    deleteCompanyNSRSReport,
    createNSRSResponse,
    updateNSRSResponse,
    deleteNSRSResponse,
} from '../../../graphql/mutations';

/** LOCAL FUNCTIONS **/

// Creates a new report
const createNSRSReport = async inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createCompanyNSRSReport, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Fetches list of reports
const fetchReports = (companyId, token) => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listCompanyNSRSReports, {
                filter: { companyId: { eq: companyId } },
                nextToken: token,
            }),
        )
            .then(data => {
                resolve(data.data.listCompanyNSRSReports);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Fetches categories
const fetchCategories = token => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listNSRSCategorys, {
                nextToken: token,
            }),
        )
            .then(data => {
                resolve(data.data.listNSRSCategorys);
            })
            .catch(err => {
                reject(err);
            });
    });
};

const updateReport = inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateCompanyNSRSReport, { input: inputData }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully updated NSRS report: ', data);
                }

                resolve(data.data.updateCompanyNSRSReport);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to update NSRS report: ', err);
                }

                reject(err);
            });
    });
};

/** EXPORTED FUNCTIONS **/

export async function fetchSingleReport(reportId) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(getCompanyNSRSReport, { id: reportId }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully fetched NSRS report: ', data.data.getCompanyNSRSReport);
                }

                resolve(data.data.getCompanyNSRSReport);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function fetchClimateReports(companyId) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listCompanyClimateAccReports, {
                filter: { companyId: { eq: companyId } },
            }),
        )
            .then(data => {
                resolve(data.data.listCompanyClimateAccReports.items);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function createNewNSRSReport(companyId, year, userName) {
    return new Promise(async (resolve, reject) => {
        let input = {
            companyId: companyId,
            status: 'Draft',
            reportYear: year,
            userCreatedBy: userName,
        };

        await createNSRSReport(input)
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully created new NSRS report: ', data);
                }

                resolve(data.data.createCompanyNSRSReport);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to create new NSRS report: ', err);
                }

                reject(err);
            });
    });
}

export async function deleteReport(id) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(deleteCompanyNSRSReport, {
                input: { id: id },
            }),
        )
            .then(async data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Succesfully deleted NSRS report: ', data);
                }

                let responses = data.data.deleteCompanyNSRSReport.responses.items;

                if (responses && responses.length !== 0) {
                    for (const resp of responses) {
                        await API.graphql(
                            graphqlOperation(deleteNSRSResponse, {
                                input: { id: resp.id },
                            }),
                        ).then(async respData => {
                            if (process.env.NODE_ENV === 'development') {
                                console.log('Succesfully deleted NSRS response: ', respData);
                            }
                        });
                    }
                }
                resolve();
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to delete NSRS report: ', err);
                }

                reject(err);
            });
    });
}

export async function fetchSingleCategory(categoryId) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(getNSRSCategory, { id: categoryId }))
            .then(data => {
                resolve(data.data.getNSRSCategory);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function fetchAllNSRSReports(companyId) {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchReports(companyId, nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching NSRS reports from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched all NSRS reports: ', dataArr);
        }

        resolve(dataArr);
    });
}

export async function fetchAllCategories() {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchCategories(nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching NSRS categories from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched all NSRS categories: ', dataArr);
        }

        resolve(dataArr);
    });
}

export async function fetchResponse(reportId, questionId) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listNSRSResponses, {
                filter: { reportId: { eq: reportId }, questionId: { eq: questionId } },
            }),
        )
            .then(data => {
                console.log(data);
                resolve(data.data.listNSRSResponses.items[0]);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function createResponse(companyId, reportId, catId, subId, questionId, isAnswered, data) {
    let input = {
        companyId: companyId,
        reportId: reportId,
        categoryId: catId,
        subcategoryId: subId,
        questionId: questionId,
        isAnswered: isAnswered,
        data: JSON.stringify(data),
    };

    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createNSRSResponse, { input: input }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully created new NSRS response: ', data);
                }

                resolve(data.data.createNSRSResponse);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to create new NSRS response: ', err);
                }

                reject(err);
            });
    });
}

export async function updateResponse(id, isAnswered, data) {
    let input = {
        id: id,
        isAnswered: isAnswered,
        data: JSON.stringify(data),
    };

    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateNSRSResponse, { input: input }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully updated NSRS response: ', data);
                }

                resolve(data.data.updateNSRSResponse);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to update NSRS response: ', err);
                }

                reject(err);
            });
    });
}

export async function finishReport(id) {
    return new Promise(async (resolve, reject) => {
        let inputData = {
            id: id,
            status: 'Completed',
        };

        await updateReport(inputData)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function reopenReport(id) {
    return new Promise(async (resolve, reject) => {
        let inputData = {
            id: id,
            status: 'Draft',
        };

        await updateReport(inputData)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function fetchQuestionDataBasedOnNumber(catNumber, subNumber, questionNumber) {
    return new Promise(async (resolve, reject) => {
        await fetchAllCategories()
            .then(data => {
                for (const category of data) {
                    if (category.order === catNumber) {
                        for (const subcategory of category.subcategories.items) {
                            if (subcategory.order === subNumber) {
                                for (const question of subcategory.questions.items) {
                                    if (question.order === questionNumber) {
                                        resolve(question);
                                    }
                                }
                            }
                        }
                    }
                }

                reject('Successfully fetched categories, but error finding given question.');
            })
            .catch(err => {
                reject(err);
            });
    });
}
