import React, { useEffect } from "react";
import { Grid, Paper, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const HubspotContactForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "25393137",
          formId: "94a90acc-3cfd-4198-8fd2-2c0cc4011110",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return <div id="hubspotForm"></div>;
};

export default function Report() {
  const history = useHistory();
  return (
    <Grid item xs={12}>
      <Button
        variant="contained"
        sx={{ fontSize: "18px" }}
        onClick={() => history.goBack()}
      >
        Gå tilbake
      </Button>
      <Paper sx={{ padding: "40px" }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "left", marginBottom: "20px" }}
        >
          Rapportering
        </Typography>
        <Typography variant="body1">
          Her kan du varsle Ecofric om innhold og beskrivelser fra bedrifter som
          du syns virker feil. Beskriv så godt du klarer, så tar våre eksperter
          tak i det så raskt som mulig.
        </Typography>
        <br />
        {HubspotContactForm()}
      </Paper>
    </Grid>
  );
}
