import React, { useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    Typography,
} from '@mui/material';
import CompanyInfo from './CompanyInfo';
import { useAuth } from '../../../../../auth/useAuth';
import useRouter from '../../../../../hooks/useRouter';
import useIsMounted from '../../../../../hooks/useIsMounted';
import { adminCheck } from '../../../../../helpers/globalValues';
import { CSVLink } from 'react-csv';

import { fetchCategories, fetchAllUsers, fetchAllCompanyData } from './companiesFunctions';
//import { fetchClimateReports } from '../../../Climate/climateFunctions';
//import { fetchAllCompanyTasks } from '../../../Chores/choresFunctions';

export default function CompaniesView() {
    const isMounted = useIsMounted();

    const router = useRouter();
    const auth = useAuth();

    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);

    const [csvUsersData, setCsvUsersData] = useState([]);
    //const [csvDataTable, setCsvDataTable] = useState([]);

    useEffect(() => {
        if (adminCheck && auth.companyData && auth.companyData.orgNumber !== '924828099') {
            router.push('/');
        }

        fetchAllCompanyData().then(data => {
            if (isMounted.current) {
                setCompanies(data);
            }

            //getDataTable(data);
        });

        fetchAllUsers().then(data => {
            let newArr = [];

            for (const user of data) {
                let newObj = {
                    name: JSON.parse(user.name).name,
                    last_name: JSON.parse(user.name).lastName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                };

                newArr.push(newObj);
            }

            if (isMounted.current) {
                setCsvUsersData([...newArr]);
                setUsers(data);
            }
        });

        fetchCategories().then(data => {
            if (isMounted.current) {
                setCategories(data);
            }
        });
    }, [auth.companyData, isMounted, router]);

    /*const getDataTable = async companies => {
        let newArr = [];

        for (const company of companies) {
            let responses = company.certData.items[0].responses.items;
            let climateReports = await fetchClimateReports(company.id);
            let tasks = await fetchAllCompanyTasks(company.id);

            let criteriaAnswered = 0;

            for (const item of responses) {
                if (item.isAnswered === true) {
                    criteriaAnswered = criteriaAnswered + 1;
                }
            }

            let draftReports = 0;
            let finishedReports = 0;

            for (const item of climateReports) {
                if (item.status === 'Draft') {
                    draftReports = draftReports + 1;
                } else if (item.status === 'Completed') {
                    finishedReports = finishedReports + 1;
                }
            }

            let draftTasks = 0;
            let progressTasks = 0;
            let finishedTasks = 0;

            for (const item of tasks) {
                if (item.status === 'Not Started') {
                    draftTasks = draftTasks + 1;
                } else if (item.status === 'In Progress') {
                    progressTasks = progressTasks + 1;
                } else if (item.status === 'Completed') {
                    finishedTasks = finishedTasks + 1;
                }
            }

            let newObj = {
                bedrift: company.name,
                kriterier_besvart: criteriaAnswered,
                antall_klimaregnskap: climateReports.length,
                draft_klimaregnskap: draftReports,
                completed_klimaregnskap: finishedReports,
                antall_tasks: tasks.length,
                draft_tasks: draftTasks,
                in_progress_tasks: progressTasks,
                completed_tasks: finishedTasks,
            };

            newArr.push(newObj);
        }

        setCsvDataTable([...newArr]);
    };*/

    return (
        <>
            <Grid item xs={12} style={{ marginBottom: 20, marginTop: 20 }}>
                <Paper style={{ padding: 20 }}>
                    {/*<BenchmarkChart />*/}
                    <Typography variant="body2" style={{ fontSize: 20 }}>
                        Det er registrert {companies.length || 0} antall selskaper
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: 20 }}>
                        Det er registrert {users.length || 0} antall brukere
                    </Typography>
                    <CSVLink data={csvUsersData} filename={'ecofric-brukerinfo.csv'}>
                        {csvUsersData.length === 0
                            ? 'Laster inn .csv...'
                            : 'Last ned .csv fil med alle navn, mailer og tlf.nr. fra brukere'}
                    </CSVLink>
                    {/*<br />
                    <CSVLink data={csvDataTable} filename={'ecofric-bedrift-bruk-info.csv'}>
                        {csvUsersData.length === 0
                            ? 'Laster inn .csv...'
                            : 'Last ned .csv fil med info om bedrifter (antall kriterier, klimaregnskap, aktiviteter)'}
                        </CSVLink>*/}
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ padding: '20px' }}>
                    <TableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="left">Selskap</TableCell>
                                    <TableCell align="left">Org. nr.</TableCell>
                                    <TableCell align="left">Reg. dato</TableCell>
                                    <TableCell align="left">Kundestatus</TableCell>
                                    <TableCell align="center">Antall brukere</TableCell>
                                    <TableCell align="center">Sist oppdatert</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {companies &&
                                    companies
                                        .sort((a, b) => {
                                            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                                        })
                                        .map(data => {
                                            const brregData = JSON.parse(data.brregData);
                                            let userArr = [];

                                            if (users) {
                                                userArr = users.filter(user => user.companyId === data.id);
                                            }

                                            return (
                                                <CompanyInfo
                                                    key={data.id}
                                                    company={data}
                                                    brreg={brregData}
                                                    users={userArr}
                                                    categories={categories}
                                                />
                                            );
                                        })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </>
    );
}
