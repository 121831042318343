import React, { useState, useEffect } from 'react';
import { Grid, Button, ButtonGroup } from '@mui/material';
import SnackPopup from '../../../../../components/SnackPopup';
import useToggle from '../../../../../hooks/useToggle';
import CategoryListView from './CategoryListView';
import SubcategoryListView from './SubcategoryListView';
import QuestionListView from './QuestionListView';

import { API, graphqlOperation } from 'aws-amplify';
import { listCertCategorys } from '../../../../../graphql/queries';

export default function CertificationView() {
    const [view, setView] = useState('categories');

    const [popupState, setPopupState] = useToggle();
    const [popupMsg, setPopupMsg] = useState('');

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            updateCategories();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState();
    };

    const handleClosePopup = () => {
        setPopupState();
    };

    const fetchCategories = () => {
        return new Promise(async resolve => {
            await API.graphql(graphqlOperation(listCertCategorys)).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Fetched all categories: ', data);
                }

                resolve(data.data.listCertCategorys.items);
            });
        });
    };

    const updateCategories = async () => {
        await fetchCategories().then(data => {
            let sorted = data.sort((a, b) => {
                return a.order - b.order;
            });

            setCategories([...sorted]);
        });
    };

    // Logic for what views appear to the user based on URL criterias
    const checkForView = () => {
        if (view === 'categories') {
            return (
                <CategoryListView showPopup={showPopup} categories={categories} updateCategories={updateCategories} />
            );
        } else if (view === 'subcategories') {
            return (
                <SubcategoryListView
                    showPopup={showPopup}
                    categories={categories}
                    updateCategories={updateCategories}
                />
            );
        } else if (view === 'questions') {
            return (
                <QuestionListView showPopup={showPopup} categories={categories} updateCategories={updateCategories} />
            );
        }
    };

    return (
        <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
            <ButtonGroup>
                <Button variant="contained" onClick={() => setView('categories')}>
                    Kategorier
                </Button>
                <Button variant="contained" onClick={() => setView('subcategories')}>
                    Underkategorier
                </Button>
                <Button variant="contained" onClick={() => setView('questions')}>
                    Spørsmål
                </Button>
            </ButtonGroup>
            {checkForView()}
        </Grid>
    );
}
