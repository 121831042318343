import React, { useState, useEffect } from 'react';
import { EditorState, RichUtils, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import {
    Paper,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    TextField,
    InputLabel,
    InputBase,
    FormControl,
    Select,
    MenuItem,
    Divider,
    Box,
    Tabs,
    Tab,
} from '@mui/material';
import { useStyles } from '../ChoresStyle';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import nbLocale from 'date-fns/locale/nb';
import Autocomplete from '@mui/material/Autocomplete';
import { ChoresIcon } from '../../../../components/Icons';
import { useAuth } from '../../../../auth/useAuth';
import Tree from '../../../../assets/images/tree.svg';
import { UnderlineButton, BoldButton, ItalicButton, BlockButton } from './CommentSection/DraftStyles';
import { createTask } from '../choresFunctions';
import { createNotification } from '../../../../components/private/notificationsFunctions';
import PropTypes from 'prop-types';
import * as Text from '../../../../helpers/chores.json';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function NewChore(props) {
    const classes = useStyles();
    const auth = useAuth();

    const climateCriterias = [
        'Forbrenning og oppvarming',
        'Intern transport',
        '(Kjøle)gasser',
        'Strøm og fjernvarme',
        'Strømleverandør',
        'Vannforbruk',
        'Jobbreiser eksklusiv firma-kjøretøy',
        'Transport til og fra jobb',
        'Innkjøpt varetransport',
        'Innkjøpte materialer',
        'Innkjøp av inventar og utstyr',
        'Innleide produkter',
        'Solgte produkter',
        'Utleie av produkter',
        'Investeringer',
        'Restmaterialer og restråvarer',
        'Avfallsproduksjon',
        'Kjemikalier',
    ];

    const [openDialog, setOpenDialog] = useState(false);
    const [criterias, setCriterias] = useState([]);
    const [deadlineValue, setDeadlineValue] = useState(null);
    const [responsibleUsers, setResponsibleUsers] = useState([]);
    const [title, setTitle] = useState('');
    const [selectedCriteria, setSelectedCriteria] = useState(null);
    const [selectedClimateCritera, setSelectedClimateCriteria] = useState(null);
    const [hasSubCriteria, setHasSubCriteria] = useState(false);
    const [subCriterias, setSubCriterias] = useState([]);
    const [selectedSubCriteria, setSelectedSubCriteria] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const editor = React.useRef(null);

    function focusEditor() {
        editor.current.focus();
    }

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            let newArr = [];

            for (const category of props.criterias) {
                let categoryName = category.name;
                let catId = category.id;

                for (const sub of category.subcategories.items) {
                    for (const question of sub.questions.items) {
                        let newQuestion = question;
                        newQuestion.categoryName = categoryName;
                        newQuestion.catId = catId;

                        newArr.push(newQuestion);
                    }
                }
            }

            setCriterias([...newArr]);
        }

        return () => {
            isMounted = true;
        };
    }, [props.criterias]); // eslint-disable-line react-hooks/exhaustive-deps

    const createDelegatedNotifs = async (userArray, task) => {
        for (const userId of userArray) {
            if (userId !== auth.userData.id) {
                let dataObj = {
                    link: '/activities/' + task.id,
                    title: task.title,
                    taggedBy: JSON.parse(auth.userData.name).fullName,
                };

                let inputData = {
                    companyId: auth.companyData.companyId,
                    userId: userId,
                    type: 'DELEGATED_NOTIF',
                    markedAsRead: false,
                    archived: false,
                    data: JSON.stringify(dataObj),
                };

                await createNotification(inputData);
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCreateNewChore = async () => {
        let newHistoryObj = {
            userId: auth.userData.id,
            action: 'CREATE',
            timestamp: new Date(),
        };

        let historyArr = [];

        historyArr.push(newHistoryObj);

        let log = editorState.getCurrentContent();

        let inputData = {
            companyId: auth.companyData.companyId,
            type: 'CERT_CRITERIA',
            status: 'Not Started',
            history: JSON.stringify(historyArr),
            title: title,
            description: JSON.stringify(convertToRaw(log)),
            users: responsibleUsers,
        };

        if (deadlineValue) {
            inputData.dueDate = deadlineValue;
        }

        if (selectedCriteria && tabValue === 0) {
            inputData.criteria = selectedCriteria.id;
            inputData.type = 'CERT_CRITERIA';

            if (selectedSubCriteria) {
                inputData.subCriteria = selectedSubCriteria.id;
            }
        } else if (selectedClimateCritera && tabValue === 1) {
            inputData.climateCriteria = selectedClimateCritera;
            inputData.type = 'CLIMATE_CRITERIA';
        }

        await createTask(inputData).then(async data => {
            await props.updateTasks();

            await createDelegatedNotifs(responsibleUsers, data);
        });

        handleCloseDialog();
    };

    const handleChange = event => {
        const {
            target: { value },
        } = event;
        setResponsibleUsers(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);

        setTitle('');
        setEditorState(() => EditorState.createEmpty());
        setSelectedCriteria(null);
        setSelectedSubCriteria(null);
        setSelectedClimateCriteria(null);
        setSubCriterias([]);
        setDeadlineValue(null);
        setResponsibleUsers([]);
        setTabValue(0);
    };

    const handleSelectCriteria = value => {
        setSelectedCriteria(value);

        if (
            (value && value.type === 'MultipleChoice' && value.catId !== 'company') ||
            (value && value.type === 'RadioList' && value.catId !== 'company')
        ) {
            setSubCriterias(value.options.items);
            setHasSubCriteria(true);
        } else {
            setSubCriterias([]);
            setHasSubCriteria(false);
        }
    };

    const boldText = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
        setEditorState(nextState);
    };

    const underlineText = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE');
        setEditorState(nextState);
    };

    const italicText = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
        setEditorState(nextState);
    };

    const unorderedList = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleBlockType(editorState, 'unordered-list-item');
        setEditorState(nextState);
    };

    const orderedList = e => {
        e.preventDefault();
        let nextState = RichUtils.toggleBlockType(editorState, 'ordered-list-item');
        setEditorState(nextState);
    };

    const dialogPopup = (
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" TransitionComponent={Transition} fullWidth>
            <DialogTitle className={classes.dialogTitle}>Lag ny aktivitet</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', padding: '0px' }}>
                    <InputLabel>
                        Kriterie aktiviteten er knyttet til <i>(valgfritt)</i>
                    </InputLabel>
                    <Box className={classes.BoxStyle}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab className={classes.TabStyle} label="Egenerklæring" {...a11yProps(0)} />
                            <Tab className={classes.TabStyle2} label="Klimaregnskap" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <InputLabel>
                            Kriterier fra egenerklæringen <i>(valgfritt)</i>
                        </InputLabel>
                        <Autocomplete
                            options={criterias.sort((a, b) => a.question.localeCompare(b.question))}
                            groupBy={option => option.categoryName}
                            getOptionLabel={option => option.question}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            freeSolo
                            renderInput={params => (
                                <TextField {...params} label="" placeholder="Søk etter kriterie..." />
                            )}
                            value={selectedCriteria}
                            onChange={(e, value) => handleSelectCriteria(value)}
                        />
                        {hasSubCriteria ? (
                            <>
                                <InputLabel>
                                    Underkriterie aktiviteten er knyttet til <i>(valgfritt)</i>
                                </InputLabel>
                                <Autocomplete
                                    options={subCriterias.sort((a, b) => a.option.localeCompare(b.option))}
                                    getOptionLabel={option => option.option}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    freeSolo
                                    renderInput={params => (
                                        <TextField {...params} label="" placeholder="Søk etter kriterie..." />
                                    )}
                                    value={selectedSubCriteria}
                                    onChange={(e, value) => setSelectedSubCriteria(value)}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <InputLabel>
                            Kriterier fra klimaregnskapet <i>(valgfritt)</i>
                        </InputLabel>
                        <Autocomplete
                            options={climateCriterias.sort((a, b) => a.localeCompare(b))}
                            getOptionLabel={option => option}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            freeSolo
                            renderInput={params => (
                                <TextField {...params} label="" placeholder="Søk etter kriterie..." />
                            )}
                            value={selectedClimateCritera}
                            onChange={(e, value) => setSelectedClimateCriteria(value)}
                        />
                    </TabPanel>
                </Box>
                <InputLabel>Tittel</InputLabel>
                <InputBase
                    type="text"
                    name="title"
                    placeholder="Skriv inn tittel..."
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                />
                <InputLabel>
                    Beskrivelse <i>(valgfritt)</i>
                </InputLabel>
                <Grid className={classes.richEditGrid} onClick={focusEditor}>
                    <UnderlineButton variant="text" onMouseDown={underlineText}>
                        Understrek
                    </UnderlineButton>
                    <BoldButton variant="text" onMouseDown={boldText}>
                        Fet
                    </BoldButton>
                    <ItalicButton variant="text" onMouseDown={italicText}>
                        Kursiv
                    </ItalicButton>
                    <BlockButton variant="text" onMouseDown={unorderedList}>
                        Punkt liste
                    </BlockButton>
                    <BlockButton variant="text" onMouseDown={orderedList}>
                        Numerert liste
                    </BlockButton>
                    <Divider sx={{ marginBottom: '15px' }} />
                    <Editor
                        ref={editor}
                        editorState={editorState}
                        onChange={setEditorState}
                        placeholder="Skriv beskrivelsen her..."
                    />
                </Grid>
                <InputLabel>
                    Forfallsdato <i>(valgfritt)</i>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={nbLocale}>
                    <DatePicker
                        mask="__.__.____"
                        clearable
                        renderInput={params => {
                            return (
                                <TextField
                                    sx={{ width: '100%' }}
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'dd/mm/yyyy',
                                    }}
                                />
                            );
                        }}
                        value={deadlineValue}
                        onChange={newValue => {
                            setDeadlineValue(newValue);
                        }}
                    />
                </LocalizationProvider>
                <InputLabel>
                    Ansvarlig(e) <i>(valgfritt)</i>
                </InputLabel>
                <FormControl sx={{ width: '100%' }}>
                    <Select
                        multiple
                        value={responsibleUsers}
                        onChange={handleChange}
                        placeholder="Velg en eller flere ansvarlige..."
                        input={<InputBase placeholder="Velg en eller flere ansvarlige..." />}
                        MenuProps={MenuProps}
                    >
                        {props.users.map(user => (
                            <MenuItem key={user.id} value={user.id}>
                                {JSON.parse(user.name).fullName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    sx={{ width: '100px', height: '50px', backgroundColor: '#EBEBEB' }}
                    autoFocus
                    onClick={handleCloseDialog}
                >
                    Lukk
                </Button>
                <Button
                    variant="contained"
                    sx={{ width: '150px', height: '50px' }}
                    autoFocus
                    onClick={handleCreateNewChore}
                    disabled={title.length === 0 ? true : false}
                >
                    Lagre
                </Button>
            </DialogActions>
        </Dialog>
    );
    return (
        <Paper>
            <Typography variant="h3">{ChoresIcon}Ny aktivitet</Typography>
            <Grid item xs={12} sx={{ display: 'flex' }}>
                <Grid item xs={2} className={classes.imageSection}>
                    <img src={Tree} alt="Tree" sx={{ width: '100%' }}></img>
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={7} className={classes.section} sx={{ flexDirection: 'column' }}>
                    <Typography variant="h2" sx={{ alignSelf: 'flex-start' }}>
                        Ny aktivitet
                    </Typography>
                    <Typography variant="body1">{Text.newchore}</Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} className={classes.section}>
                    {dialogPopup}
                    <Button variant="contained" className={classes.startButton} onClick={handleOpenDialog}>
                        Lag ny aktivitet
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
