import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper } from '@mui/material';
import { ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import InputForm, { TextAreaInput, NumberInput } from '../../../../../components/InputForm';
import useToggle from '../../../../../hooks/useToggle';

import { API, graphqlOperation } from 'aws-amplify';
import { updateCertCategory /*deleteCertCategory*/ } from '../../../../../graphql/mutations';

export default function CategoryRowView(props) {
    const [rowOpen, setRowOpen] = useToggle();
    const [dialogOpen, setDialogOpen] = useToggle();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [order, setOrder] = useState(0);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            setName(props.data.name);
            setDescription(props.data.description);
            setOrder(props.data.order);
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateCategory = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateCertCategory, {
                    input: {
                        id: props.data.id,
                        name: name,
                        description: description,
                        order: order,
                    },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated category: ', data);
                }

                resolve();
            });
        });
    };

    /*const deleteCategory = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(deleteCertCategory, {
                    input: { id: props.data.id },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Deleted category: ', data);
                }

                resolve();
            });
        });
    };*/

    const handleUpdateCategory = async () => {
        if (name === '' || description === '') {
            props.showPopup('Navn og beskrivelse lenke må være fylt ut!');
            return;
        }

        await updateCategory().catch(() => {
            props.showPopup('En feil oppsto ved lagring av kategori');
            return;
        });

        props.showPopup('Kategorien har blitt lagret');

        await props.update();

        setDialogOpen();
    };

    const handleCloseDialog = () => {
        setDialogOpen();
        setName(props.data.name);
        setDescription(props.data.description);
        setOrder(props.data.order);
    };

    /*const handleDeleteCategory = async () => {
        if (props.data.subcategories.items && props.data.subcategories.items.length !== 0) {
            props.showPopup('Alle underkategorier må bli fjernet før du kan slette!');
            return;
        }

        await deleteCategory().catch(() => {
            props.showPopup('En feil oppsto ved sletting av kategori');
            return;
        });

        props.showPopup('Kategorien har blitt slettet');

        await props.update();
    };*/

    const updateCategoryDialog = () => {
        return (
            <Grid>
                <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Oppdater kategorien</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Oppdater feltene til kategorien.
                        </DialogContentText>
                        <InputForm
                            label="Navn på kategori"
                            placeholder="Skriv inn navn på kategorien"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Beskrivelse"
                            placeholder="Skriv beskrivelse på kategorien"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                        <NumberInput
                            label="Rekkefølge (lavere nummer betyr høyere på listen)"
                            placeholder="Skriv inn nummer på rekkefølge"
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleUpdateCategory}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid>
            {updateCategoryDialog()}
            <Paper sx={{ padding: '20px', marginBottom: '10px' }}>
                <ListItem disablePadding key={props.data.id}>
                    <ListItemButton onClick={setRowOpen}>
                        <ListItemIcon>
                            <DehazeIcon />
                        </ListItemIcon>
                        <ListItemText primary={props.data.name} />
                    </ListItemButton>
                </ListItem>
                {!rowOpen ? (
                    <></>
                ) : (
                    <Grid>
                        <Divider />
                        <Grid sx={{ margin: '15px' }}>
                            <b>ID:</b>
                            <br /> {props.data.id}
                            <br />
                            <br />
                            <b>Beskrivelse:</b>
                            <br /> {props.data.description}
                            <br />
                            <br />
                            <b>Rekkefølge:</b>
                            <br /> {props.data.order}
                        </Grid>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '200px' }}
                            onClick={setDialogOpen}
                        >
                            Oppdater kategori{' '}
                        </Button>
                        {/*<Button
                                variant="contained"
                                startIcon={<AddCircleOutlineIcon />}
                                sx={{ padding: '20px', margin: '10px', width: '200px' }}
                                onClick={handleDeleteCategory}
                            >
                                Slett kategori{' '}
                            </Button>*/}
                        <Divider />
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
}
