import { API, graphqlOperation } from 'aws-amplify';

import { listUsers, listCertCategorys, listTodoTasks, getTodoTask } from '../../../graphql/queries';
import {
    createTodoTask,
    updateTodoTask,
    deleteTodoTask,
    createTodoTaskComment,
    updateTodoTaskComment,
    deleteTodoTaskComment,
} from '../../../graphql/mutations';

/** LOCAL FUNCTIONS **/

export async function fetchCompanyTasks(companyId, token) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listTodoTasks, {
                filter: { companyId: { eq: companyId } },
                nextToken: token,
            }),
        )
            .then(data => {
                resolve(data.data.listTodoTasks);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function fetchCompanyTasksForClimate(companyId, climateId, token) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listTodoTasks, {
                filter: { companyId: { eq: companyId }, climateReportId: { eq: climateId } },
                nextToken: token,
            }),
        )
            .then(data => {
                resolve(data.data.listTodoTasks);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function fetchUsers(companyId, token) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(listUsers, { filter: { companyId: { eq: companyId } }, nextToken: token }))
            .then(data => {
                resolve(data.data.listUsers);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/** EXPORTED FUNCTIONS **/

export async function fetchCompanyUsers(companyId) {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchUsers(companyId, nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching users from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched users: ', dataArr);
        }

        resolve(dataArr);
    });
}

// Fetches a list of all categories and sorts them out based on the company's certification filter types
export async function fetchFilteredCategories(auth) {
    return new Promise(async resolve => {
        await API.graphql(graphqlOperation(listCertCategorys)).then(data => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Fetched all filtered categories: ', data);
            }

            let catData = data.data.listCertCategorys.items;
            let companyTypes = auth.companyData.companyData.certData.items[0].certFilterTypes;
            let employeeAmount = auth.companyData.companyData.certData.items[0].employeeAmount;

            for (const category of catData) {
                for (let i = category.subcategories.items.length - 1; i >= 0; i--) {
                    let subcatHasType = false;

                    for (let j = category.subcategories.items[i].questions.items.length - 1; j >= 0; j--) {
                        let questionHasType = false;

                        if (
                            category.subcategories.items[i].questions.items[j].filterType === 'None' ||
                            (category.subcategories.items[i].questions.items[j].filterType !== 'None' &&
                                companyTypes.includes(category.subcategories.items[i].questions.items[j].filterType)) ||
                            (category.subcategories.items[i].questions.items[j].filterType === 'Employees' &&
                                employeeAmount > 3)
                        ) {
                            subcatHasType = true;
                            questionHasType = true;
                        }

                        if (!questionHasType) {
                            category.subcategories.items[i].questions.items.splice(j, 1);
                        }
                    }

                    if (!subcatHasType) {
                        category.subcategories.items.splice(i, 1);
                    }
                }
            }

            resolve(catData);
        });
    });
}

export async function fetchAllCompanyTasks(companyId) {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchCompanyTasks(companyId, nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching tasks from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched all company tasks: ', dataArr);
        }

        resolve(dataArr);
    });
}

export async function fetchAllCompanyTasksForClimate(companyId, climateId) {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchCompanyTasksForClimate(companyId, climateId, nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching tasks from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched all company tasks: ', dataArr);
        }

        resolve(dataArr);
    });
}

export async function fetchTask(id) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(getTodoTask, { id: id }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully fetched task from backend: ', data.data.getTodoTask);
                }

                resolve(data.data.getTodoTask);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching task from backend: ', err);
                }

                reject(err);
            });
    });
}

export async function createTask(inputData) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createTodoTask, { input: inputData }))
            .then(data => {
                resolve(data.data.createTodoTask);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function updateTask(inputData) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateTodoTask, { input: inputData }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Succesfully updated task: ', data);
                }

                resolve(data.data.updateTodoTask);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to update task: ', err);
                }

                reject(err);
            });
    });
}

export async function deleteTask(id) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(deleteTodoTask, {
                input: { id: id },
            }),
        )
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Succesfully deleted task: ', data);
                }

                resolve();
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to delete task: ', err);
                }

                reject(err);
            });
    });
}

export async function createComment(inputData) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createTodoTaskComment, { input: inputData }))
            .then(data => {
                resolve(data.data.createTodoTaskComment);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function updateComment(inputData) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateTodoTaskComment, { input: inputData }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Succesfully updated task: ', data);
                }

                resolve(data.data.updateTodoTaskComment);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to update task: ', err);
                }

                reject(err);
            });
    });
}

export async function deleteComment(id) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(deleteTodoTaskComment, {
                input: { id: id },
            }),
        )
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Succesfully deleted task: ', data);
                }

                resolve();
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to delete task: ', err);
                }

                reject(err);
            });
    });
}
