import React, { useState, useEffect } from 'react';
import {
    Grid,
    NativeSelect,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    InputBase,
    IconButton,
    TextField,
} from '@mui/material';
import { Delete } from '../../../../../components/Icons';
import { withStyles } from '@mui/styles';

export const CustomRow = withStyles(() => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f3f3f3',
            borderTop: '1px solid #EBEBEB',
            borderBottom: '1px solid #EBEBEB',
        },
    },
}))(TableRow);

export const CustomCell = withStyles(() => ({
    root: {
        padding: '0px 5px',
        borderBottom: '0px',
    },
    head: {
        padding: '10px 10px 0px 10px',
        fontWeight: 'bold',
    },
    body: {
        borderRight: '1px solid #E8E8E8',
        '&:last-child': {
            borderRight: '0px',
        },
    },
}))(TableCell);

export default function MultiDropdownSelectWithInput4(props) {
    const {
        questionId,
        data,
        multiDropSelect,
        setMultiDropSelect,
        pointsEarned,
        setPointsEarned,
        setIsAnswered,
        minChars,
        maxChars,
    } = props;

    const [index, setIndex] = useState(-1);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (multiDropSelect.length > 0) {
                let reqInputChecks = 0;

                for (const item of multiDropSelect) {
                    if (item.input.length !== 0 && item.input.length >= minChars) {
                        reqInputChecks++;
                    }
                }

                if (reqInputChecks === multiDropSelect.length) {
                    setIsAnswered(true);
                } else {
                    setIsAnswered(false);
                }
            } else {
                setIsAnswered(false);
            }
        }

        return () => {
            isMounted = true;
        };
    }, [multiDropSelect]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddAnswer = () => {
        if (index === -1) {
            return;
        }

        let option = data[index];
        let newArr = multiDropSelect;

        if (newArr.some(item => item.id === option.id)) {
            return;
        }

        let newObj = {
            id: option.id,
            value: option.option,
            input: '',
        };

        newArr.push(newObj);

        setPointsEarned(pointsEarned + option.pointValue);
        setMultiDropSelect([...newArr]);
    };

    const handleRemoveAnswer = index => {
        let newArr = multiDropSelect;

        let newIndex = data.findIndex(item => item.id === newArr[index].id);

        newArr.splice(index, 1);

        setPointsEarned(pointsEarned - data[newIndex].pointValue);
        setMultiDropSelect([...newArr]);
    };

    const handleChangeInput = (value, index) => {
        let newArr = multiDropSelect;

        newArr[index].input = value;

        setMultiDropSelect([...newArr]);
    };

    const descHeaderView = () => {
        // 4.11 Hva er bedriftens mål for reduksjon av eget avfall i nåværende regnskapsår?
        if (questionId === '9363200c-a1f4-4c2d-af44-a5d2fc22cef8') {
            return <>Mål for avfallsreduksjon, i tonn</>;
            // 4.10 Hvor mye avfall produserte bedriften i fjor? (Forrige regnskapsår)
        } else if (questionId === '911076cc-3240-4745-b4b5-eeb72e14b39a') {
            return <>Legg til avfall, i tonn</>;
            // 2.13 Hvilke potensielle typer risiko kan bedriften stå overfor i fremtiden?
        } else if (questionId === '2dd9cffe-0d2d-4a43-b6b9-95df1ee798b2') {
            return <>Beskriv mulig konsekvens for dere</>;
        } else {
            return <>Beskriv hvorfor og/eller hvordan</>;
        }
    };

    return (
        <Grid item xs={12}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <NativeSelect
                    value={index}
                    onChange={e => setIndex(e.target.value)}
                    input={<InputBase />}
                    style={{ marginRight: 30 }}
                >
                    <option value={-1} disabled>
                        Velg et alternativ
                    </option>
                    {data &&
                        data
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map((option, index) => {
                                return (
                                    <option key={option.id} value={index}>
                                        {option.option}
                                    </option>
                                );
                            })}
                </NativeSelect>
                <Button variant="contained" sx={{ marginLeft: '20px' }} onClick={handleAddAnswer}>
                    Legg til
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <CustomRow>
                                <CustomCell sx={{ width: '30%' }}>Alternativ</CustomCell>
                                <CustomCell sx={{ width: '60%' }}>{descHeaderView()}</CustomCell>
                                <CustomCell align="center">Slett</CustomCell>
                            </CustomRow>
                        </TableHead>
                        <TableBody>
                            {multiDropSelect && multiDropSelect.length !== 0 ? (
                                multiDropSelect.map((answer, index) => {
                                    return (
                                        <CustomRow sx={{ borderTop: '1px solid #EBEBEB' }} key={answer.id}>
                                            <CustomCell>{answer.value}</CustomCell>
                                            <CustomCell>
                                                <TextField
                                                    id="description"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    type="text"
                                                    className="input"
                                                    name="description"
                                                    value={answer.input}
                                                    onChange={e => handleChangeInput(e.target.value, index)}
                                                    placeholder="Skriv her..."
                                                    inputProps={{ maxLength: maxChars }}
                                                />
                                            </CustomCell>
                                            <CustomCell align="center">
                                                <IconButton onClick={() => handleRemoveAnswer(index)}>
                                                    {Delete}
                                                </IconButton>
                                            </CustomCell>
                                        </CustomRow>
                                    );
                                })
                            ) : (
                                <CustomRow sx={{ marginTop: '10px' }}>
                                    <CustomCell>Du har ikke lagt til noen alternativ.</CustomCell>
                                </CustomRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
