import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "../TermsStyle";
import useRouter from "../../../../hooks/useRouter.jsx";

export default function NewCustomer() {
  const classes = useStyles();
  const router = useRouter();
  return (
    <Grid item xs={12} className={classes.content}>
      <Grid item xs={12}>
        <Typography variant="h1" style={{ textAlign: "left" }}>
          Velkommen som kunde i Ecofric!
        </Typography>
        <Typography variant="body1">
          Velkommen som kunde hos oss i Ecofric - din digitale
          bærekraftsavdeling. Dere har nå fått tilgang til alle verktøyene våre,
          eksport-muligheter og kan bli synlige i Ecofric-indeksen. Nå kan dere
          jobbe med bærekraft, dokumentere klima- og energiavtrykk, se
          forbedring over tid og delta i det grønne skiftet, uten å kaste tid og
          penger ut av vinduet.
        </Typography>
        <br />
        <Typography variant="body1">
          Vi legger stadig til nye funksjoner og tjenester, som gjør
          bærekraftsarbeidet mer relevant for dere. Er det noen funksjoner eller
          tjenester dere savner, vil vi gjerne høre om det!
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "10px", textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={() => router.push("/")}
          sx={{
            backgroundColor: "#F3CCD1",
            width: "100%",
            height: "50px",
          }}
        >
          Til portalen
        </Button>
      </Grid>
    </Grid>
  );
}
