import React, { useState, useEffect } from 'react';
import useIsMounted from '../../../hooks/useIsMounted';
import getDateFromTimeStamp from '../../../helpers/getDateFromTimestamp';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Paper,
    Typography,
} from '@mui/material';
import { Download, TemplatesIcon } from '../../../components/Icons';
import { s3Region, s3TemplateBucket } from '../../../helpers/globalValues';
import amplitude from 'amplitude-js';

import AWS from 'aws-sdk';
import { API, graphqlOperation } from 'aws-amplify';
import { listTemplateFiles } from '../../../graphql/queries';

export default function Templates() {
    const isMounted = useIsMounted();

    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        fetchAllTemplates().then(data => {
            if (isMounted.current) {
                setTemplates(data);
            }
        });
    }, [isMounted]);

    const myBucket = new AWS.S3({
        params: { Bucket: s3TemplateBucket },
        region: s3Region,
    });

    const fetchAllTemplates = () => {
        return new Promise(async resolve => {
            await API.graphql(graphqlOperation(listTemplateFiles)).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Fetched all template files: ', data);
                }

                resolve(data.data.listTemplateFiles.items);
            });
        });
    };

    const downloadFile = async fileKey => {
        // Fetch saved credentials for authentication
        AWS.config.credentials.get(err => {
            if (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                    console.error(err);
                }

                return;
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                }
            }

            const params = {
                Bucket: s3TemplateBucket,
                Key: fileKey,
            };

            // Fetches the file from backend
            myBucket.getObject(params, (err, data) => {
                if (err) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(err);
                    }
                } else {
                    // Fetch the filename after last '/' split
                    let parts = fileKey.split('/');
                    let fileName = parts[parts.length - 1];

                    if (process.env.NODE_ENV === 'development') {
                        console.log(data);
                    } else if (process.env.NODE_ENV !== 'development') {
                        amplitude.getInstance().logEvent('template_downloaded', { file: fileName });
                    }

                    // Create a data blob and download through frontend
                    let blob = new Blob([data.Body], { type: data.ContentType });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }
            });
        });
    };

    function getExtension(filename) {
        return filename.split('.').pop();
    }

    return (
        <Grid container>
            <Paper>
                <Grid item xs={12}>
                    <Typography variant="h3">{TemplatesIcon}Maler og verktøy</Typography>
                    <Grid item xs={3} sx={{ padding: '10px 40px 10px 40px' }}></Grid>
                    <Grid item xs={9} sx={{ padding: '10px 40px 10px 40px' }}>
                        <Typography variant="body1">
                            Her kan dere laste ned maler og verktøy som dere kan bruke i bærekraftsarbeidet deres. Vi
                            jobber for å tilby flere maler og verktøy. Gi oss gjerne en beskjed i chaten eller under
                            kontakt oss om dere har noen maler eller verktøy dere trenger, så skal vi se om vi kan få
                            lagt det til.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ padding: '10px 40px 40px 40px' }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '20%' }}>Tittel</TableCell>
                                    <TableCell sx={{ width: '50%' }}>Beskrivelse</TableCell>
                                    <TableCell sx={{ width: '10%' }}>Type fil</TableCell>
                                    <TableCell sx={{ width: '10%' }}>Sist oppdatert</TableCell>
                                    <TableCell sx={{ width: '10%' }}>Last ned</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {templates &&
                                    templates.length !== 0 &&
                                    templates.map(item => {
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell sx={{ width: '20%' }}>{item.title}</TableCell>
                                                <TableCell sx={{ width: '50%' }}>{item.description}</TableCell>
                                                <TableCell sx={{ width: '10%' }}>
                                                    {getExtension(item.fileName).toLowerCase()}
                                                </TableCell>
                                                <TableCell sx={{ width: '10%' }}>
                                                    {getDateFromTimeStamp(item.updatedAt)}
                                                </TableCell>
                                                <TableCell sx={{ width: '10%' }}>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => downloadFile(item.filePath + item.fileName)}
                                                    >
                                                        Last ned {Download}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Paper>
        </Grid>
    );
}
