import React from "react";
import { Grid, List, ListItem } from "@mui/material";
import { Facebook, Linkedin, Instagram } from "../Icons";
import { useStyles } from "./PrivateStyles";

export default function Footer() {
  const classes = useStyles();
  return (
    <Grid container direction="row" className={classes.footer}>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <List className={classes.socialWrapper}>
          <ListItem className={classes.socialIcon}>
            <a
              href="https://www.facebook.com/Ecofric"
              rel="noopener noreferrer"
              target="_blank"
              style={{ height: "28px" }}
            >
              {Facebook}
            </a>
          </ListItem>
          <ListItem className={classes.socialIcon}>
            <a
              href="https://www.linkedin.com/company/ecofric/"
              rel="noopener noreferrer"
              target="_blank"
              style={{ height: "24px" }}
            >
              {Linkedin}
            </a>
          </ListItem>
          <ListItem className={classes.socialIcon}>
            <a
              href="https://www.instagram.com/ecofric/"
              rel="noopener noreferrer"
              target="_blank"
              style={{ height: "22px" }}
            >
              {Instagram}
            </a>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
  );
}
