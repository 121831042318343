import React from 'react';
import { InputLabel, InputBase, Grid, Typography } from '@mui/material';
import { useStyles } from './../../NSRSStyles';

export default function Number(props) {
    const { data, input, setInput, isInputsDisabled, reportIsCompleted } = props;
    const classes = useStyles();

    const handleChange = ({ target }) => {
        if (target.value.length > target.maxLength) {
            target.value = target.value.slice(0, target.maxLength);
        }

        setInput({
            ...input,
            [target.id]: target.value,
        });
    };

    return (
        <Grid>
            <InputLabel>
                {data.label} {data.isRequired ? '*' : <></>}
            </InputLabel>
            <InputBase
                id={data.id}
                sx={{ padding: '5px' }}
                type="number"
                placeholder={!reportIsCompleted ? 'Skriv her...' : ''}
                value={input[data.id] || ''}
                onChange={handleChange}
                inputProps={{ maxLength: data.maxChars && data.maxChars !== 0 ? data.maxChars : 2000 }}
                disabled={isInputsDisabled}
                readOnly={reportIsCompleted}
            />
            {data.maxChars && data.maxChars !== 0 ? (
                <Typography variant="body2" className={classes.countText}>
                    {(input[data.id] && input[data.id].length) || 0}/{data.maxChars}
                </Typography>
            ) : (
                <></>
            )}
        </Grid>
    );
}
