import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPopUp(props) {
    return (
        <Dialog
            fullScreen={props.fullScreen}
            open={props.openDialog}
            onClose={props.handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
            maxWidth={props.maxWidth}
            TransitionComponent={Transition}
            fullWidth={props.fullWidth}
        >
            <DialogTitle
                sx={{
                    textAlign: 'left',
                    fontSize: '25px',
                    backgroundColor: '#E2EFF1',
                    padding: '7px 24px',
                    marginBottom: '10px',
                }}
            >
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{props.text}</DialogContentText>
                {props.content}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    sx={{ width: '70px', height: '40px', backgroundColor: '#ebebeb' }}
                    autoFocus
                    onClick={props.handleCloseDialog}
                >
                    Avbryt
                </Button>
                {props.addButton}
            </DialogActions>
        </Dialog>
    );
}
