import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Document, StyleSheet, Font, View, Text, Image, Svg, Line } from '@react-pdf/renderer';
import Bebas from '../../../../../assets/fonts/BebasNeue-Regular.ttf';
import FrontPage from '../../../../../assets/images/NSRS/Grey_FrontPage.jpg';
import Check from '../../../../../assets/images/NSRS/check.PNG';
import Grey1 from '../../../../../assets/images/NSRS/grey1.jpg';
import Grey2 from '../../../../../assets/images/NSRS/grey2.jpg';
import OpenSansTTF from '../../../../../assets/fonts/OpenSans.ttf';

Font.register({
    family: 'OpenSansCondensed',
    src: 'https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xEMwSSh38KQVJx4ABtsZTnA.ttf',
});

Font.register({
    family: 'OpenSansCondensedBold',
    src: 'http://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xBEM87DM3yorPOrvA-vB930.ttf',
});

Font.register({
    family: 'Bebas',
    format: 'truetype',
    src: Bebas,
});

Font.register({
    family: 'OpenSans',
    format: 'truetype',
    src: OpenSansTTF,
});

const themeColor = '#fdb645';

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
    },
    frontPageView: {
        position: 'relevant',
        width: '100%',
        height: '100%',
        top: '0px',
        right: '0px',
    },
    frontPageImage: {
        position: 'absolute',
        minWidth: '50%',
        minHeight: '50%',
        display: 'block',
        height: '100%',
        width: '100%',
    },
    titleBox: {
        position: 'absolute',
        top: '0px',
        left: '50px',
        display: 'flex',
        alignItems: 'left',
        backgroundColor: themeColor,
        justifyContent: 'space-between',
        height: '70%',
        opacity: '0.90',
        padding: '20px',
        width: '310',
    },
    titleBox2: {
        position: 'absolute',
        bottom: '0px',
        left: '50px',
        display: 'flex',
        alignItems: 'left',
        backgroundColor: themeColor,
        justifyContent: 'flex-start',
        height: '25%',
        opacity: '0.90',
        padding: '20px',
        width: '310',
    },
    title: {
        fontFamily: 'Bebas',
    },
    mainTitle: {
        fontFamily: 'Bebas',
        fontSize: '30px',
        borderBottomColor: themeColor,
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        marginTop: '20px',
    },
    h1: {
        fontFamily: 'Bebas',
        fontSize: '22px',
        marginTop: '20px',
    },
    h2: {
        fontFamily: 'OpenSansCondensed',
    },
    h3: {
        fontFamily: 'OpenSansCondensedBold',
        fontSize: '14px',
        margin: '10px 0px',
    },
    p: {
        fontFamily: 'OpenSans',
        fontSize: '12px',
        lineHeight: 1.8,
    },
    boldP: {
        fontFamily: 'OpenSansCondensedBold',
        fontSize: '12px',
        lineHeight: 1.8,
    },
    toppBar: {
        height: '40px',
        backgroundColor: themeColor,
        marginBottom: '20px',
    },
    footer: {
        height: '40px',
        backgroundColor: themeColor,
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: '20px',
    },
    pageNb: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '12px',
        padding: '10px 50px 0px 0px',
    },
    content: {
        padding: '0px 40px',
    },
    signature: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '18px',
    },
    contentRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '10px',
    },
    rightContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    contentNumber: {
        fontFamily: 'Bebas',
        fontSize: '24px',
    },
    contentPage: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '14px',
    },
    contentName: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '14px',
        lineHeight: '0px',
        margin: '3px 10px 0px 10px',
        width: '100%',
        padding: '0px',
        height: '20px',
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        width: '100%',
        borderBottom: '1px solid #EAEAEA',
        borderTop: '1px solid #EAEAEA',
        display: 'flex',
        flexDirection: 'row',
    },
    tableHead: {
        width: '100%',
        borderBottomColor: themeColor,
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        display: 'flex',
        flexDirection: 'row',
    },
    row2: {
        backgroundColor: '#EEEEEE',
        width: '100%',

        borderTop: '',
        display: 'flex',
        flexDirection: 'row',
    },
    cell1: {
        borderRight: '1px solid #EAEAEA',
        width: '40%',
        fontFamily: 'OpenSans',
        fontSize: '12px',
        padding: '5px',
    },
    cell2: {
        width: '60%',
        fontFamily: 'OpenSans',
        fontSize: '12px',
        padding: '5px',
    },
    headCell1: {
        borderRight: '',
        width: '40%',
        fontFamily: 'OpenSansCondensedBold',
        fontSize: '12px',
        padding: '5px',
    },
    headCell2: {
        width: '60%',
        fontFamily: 'OpenSansCondensedBold',
        fontSize: '12px',
        padding: '5px',
    },
    HeaderCell: {
        backgroundColor: themeColor,

        padding: '10px',
        width: '100%',
    },
    cellTitle: {
        fontFamily: 'OpenSansCondensedBold',
        fontSize: '12px',
        textAlign: 'center',
    },
    cellText: {
        fontFamily: 'OpenSans',
        fontSize: '10px',
        textAlign: 'left',
        paddingBottom: '5px',
    },
    MainCell: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    MainCellColumn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    IndexColumns: {
        display: 'flex',
        flexDirection: 'column',
        width: '85%',
    },
    cell: {
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '1px solid #EAEAEA',
        borderBottom: '1px solid #EAEAEA',
    },
    duoCells: {
        display: 'flex',
        flexDirection: 'column',
    },
    numberCells: {
        width: '55%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#F0F0F0',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    checkImage: {
        width: '15px',
        height: '15px',
    },
    columns: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    signatureContainer: {
        width: '40%',
    },
});

// Create Document Component
export const ModernLayout = props => {
    const { report, nsrsData } = props;

    const fetchAnswerByIds = (questionId, optionId, other) => {
        let responses = report.responses.items;

        // Find response based on question ID
        let singleResponseData = responses.find(item => item.questionId === questionId);

        if (singleResponseData) {
            // If question is not answered, return no answer
            if (!singleResponseData.isAnswered) {
                return <Text>N/A</Text>;
            }

            // Find question type from NSRS categories
            let category = nsrsData.find(item => item.id === singleResponseData.categoryId);
            let subcategory = category.subcategories.items.find(item => item.id === singleResponseData.subcategoryId);
            let question = subcategory.questions.items.find(item => item.id === singleResponseData.questionId);
            let questionData = JSON.parse(question.data);

            let optionData;

            for (const question of questionData) {
                for (const element of question.elements) {
                    if (element.id === optionId) {
                        optionData = element;
                        break;
                    }
                }
            }

            if (!optionData) {
                return <Text>N/A</Text>;
            }

            // Fetch answer data from chosen response
            let answerData = JSON.parse(singleResponseData.data);

            if (!answerData) {
                return <Text>N/A</Text>;
            }

            // Return answer based on the type
            if (questionId === '899a227c-d04b-4534-840d-31fb57f53100') {
                // 2.3.1 - NACE koder
                return (
                    answerData[optionId] &&
                    answerData[optionId].map((item, index) => {
                        return (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    marginBottom: 4,
                                }}
                                key={index}
                            >
                                <Text style={{ marginHorizontal: 3 }}>•</Text>
                                <Text style={[styles.p, { padding: '0px' }]}>
                                    M.{item[0].value} - {item[1].value}
                                </Text>
                            </View>
                        );
                    })
                );
            } else if (questionId === '3d8c7d30-be6f-4813-bc54-015cb7d6194e') {
                // 2.1.5 - foretningsadresser
                return (
                    answerData[optionId] &&
                    answerData[optionId].map((item, index) => {
                        return (
                            <View key={index} style={index % 2 === 0 ? styles.row : styles.row2}>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text style={styles.cellText}>{item[0].value}</Text>
                                </View>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text style={styles.cellText}>{item[1].value}</Text>
                                </View>
                                <View style={[styles.cell1, { width: '15%' }]}>
                                    <Text style={styles.cellText}>{item[2].value}</Text>
                                </View>
                                <View style={[styles.cell1, { width: '15%' }]}>
                                    <Text style={styles.cellText}>{item[3].value}</Text>
                                </View>
                                <View style={[styles.cell2, { width: '30%' }]}>
                                    <Text style={styles.cellText}>{item[4].value}</Text>
                                </View>
                            </View>
                        );
                    })
                );
            } else if (
                questionId === 'c8dc1965-8dfd-4781-9b1b-7128d3273f44' ||
                questionId === '8b712a8c-b012-48ae-b6c1-ec7d4d6fcc71'
            ) {
                // 3.3.2, 3.3.3 - komiteer
                return (
                    answerData[optionId] &&
                    answerData[optionId].map((item, index) => {
                        return (
                            <View key={index} style={index % 2 === 0 ? styles.row : styles.row2}>
                                <View style={styles.cell1}>
                                    <Text style={styles.cellText}>{item[0].value}</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text style={styles.cellText}>{item[1].value}</Text>
                                </View>
                            </View>
                        );
                    })
                );
            } else if (questionId === 'bc6961c6-0f57-4cfe-b437-6dc3aa2caee7') {
                // 3.3.3 - sertifiseringer
                return (
                    answerData[optionId] &&
                    answerData[optionId].map((item, index) => {
                        return (
                            <View key={index} style={index % 2 === 0 ? styles.row : styles.row2}>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text style={styles.cellText}>{item[0].value}</Text>
                                </View>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text style={styles.cellText}>{item[2].value}</Text>
                                </View>
                                <View style={[styles.cell2, { width: '60%' }]}>
                                    <Text style={styles.cellText}>{item[1].value}</Text>
                                </View>
                            </View>
                        );
                    })
                );
            } else if (questionId === '8a39f65f-1bf0-4cef-b0f6-40044f5df0eb') {
                // 3.1.1 - hovedleverandører
                return (
                    answerData[optionId] &&
                    answerData[optionId].map((item, index) => {
                        return (
                            <View key={index} style={index % 2 === 0 ? styles.row : styles.row2}>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text style={styles.cellText}>{item[0].value}</Text>
                                </View>
                                <View style={[styles.cell1, { width: '40%' }]}>
                                    <Text style={styles.cellText}>{item[1].value}</Text>
                                </View>
                                <View style={[styles.cell2, { width: '40%' }]}>
                                    <Text style={styles.cellText}>{item[2].value}</Text>
                                </View>
                            </View>
                        );
                    })
                );
            } else if (questionId === '01856bdd-63d1-4e57-880f-4a4497f962a3') {
                // 3.1.2 - interessenter
                return (
                    answerData[optionId] &&
                    answerData[optionId]
                        .sort((a, b) => (parseInt(a[1].value) > parseInt(b[1].value) ? 1 : -1))
                        .map((item, index) => {
                            if (item[1].value === other) {
                                return (
                                    <View key={index} style={index % 2 === 0 ? styles.row : styles.row2}>
                                        <View style={[styles.cell1, { width: '5%' }]}>
                                            <Text style={styles.cellText}>{item[2].value}</Text>
                                        </View>
                                        <View style={[styles.cell1, { width: '30%' }]}>
                                            <Text style={styles.cellText}>{item[0].value}</Text>
                                        </View>
                                        <View style={[styles.cell2, { width: '65%' }]}>
                                            <Text style={styles.cellText}>{item[3].value}</Text>
                                        </View>
                                    </View>
                                );
                            } else {
                                return <View key={index}></View>;
                            }
                        })
                );
            } else if (questionId === '249454ae-83ec-4c8e-92bc-928d2eca6993') {
                // 3.5.5 - kostnad sparing
                return (
                    answerData[optionId] &&
                    answerData[optionId].map((item, index) => {
                        return (
                            <View key={index} style={index % 2 === 0 ? styles.row : styles.row2}>
                                <View style={[styles.cell1, { width: '30%' }]}>
                                    <Text style={styles.cellText}>{item[0].value}</Text>
                                </View>
                                <View style={[styles.cell2, { width: '70%' }]}>
                                    <Text style={styles.cellText}>{item[1].value}</Text>
                                </View>
                            </View>
                        );
                    })
                );
            } else if (questionId === '666e1f7e-f739-4e0a-8b5b-ab0112b9b600') {
                // 3.5.2 - ikke-finansielle
                return (
                    answerData[optionId] &&
                    answerData[optionId]
                        .sort((a, b) => (parseInt(a[1].value) > parseInt(b[1].value) ? 1 : -1))
                        .map((item, index) => {
                            return (
                                <View key={index} style={index % 2 === 0 ? styles.row : styles.row2}>
                                    <View style={styles.cell1}>
                                        <Text style={styles.cellText}>#{item[1].value}</Text>
                                    </View>
                                    <View style={styles.cell2}>
                                        <Text style={styles.cellText}>{item[0].value}</Text>
                                    </View>
                                </View>
                            );
                        })
                );
            } else if (
                (questionId === 'ef82e3ae-1e04-48ef-bb6a-ca8f10c8ddb2' ||
                    questionId === '5cc1029c-920f-45b0-90d3-9e2143115abf' ||
                    questionId === '5f0207cf-9a2f-43de-9585-44d1f4e1e9b5' ||
                    questionId === '5949d11d-4fff-43db-9c66-2f937d5c6830') &&
                optionData.type === 'MultipleChoice'
            ) {
                // 4.2.2 - utvide aldersfordeling, 4.2.3 - økt mangfold, 4.1.3 - likestilling, 4.4.1 - attraktive vilkår
                return (
                    answerData[optionId] &&
                    answerData[optionId].checked.map((item, index) => {
                        let itemLabel = 'N/A';
                        let hasInput = false;
                        let inputValue = '';

                        for (const alt of optionData.alternatives) {
                            if (alt.id === item) {
                                itemLabel = alt.label;
                            }
                        }

                        if (answerData[optionId].inputs.length !== 0) {
                            for (const inputItem of answerData[optionId].inputs) {
                                if (inputItem.id === item) {
                                    inputValue = inputItem.input;
                                    hasInput = true;
                                }
                            }
                        }

                        return (
                            <View key={index} style={styles.row}>
                                <View
                                    style={[
                                        styles.cell1,
                                        {
                                            width: '15%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        },
                                    ]}
                                >
                                    <Image src={Check} style={styles.checkImage} />
                                </View>
                                <View style={[styles.cell2, { width: '85%' }]}>
                                    <Text style={styles.cellText}>
                                        {hasInput ? inputValue + ' (' + itemLabel + ')' : itemLabel}
                                    </Text>
                                </View>
                            </View>
                        );
                    })
                );
            } else if (questionId === 'fa3ea967-195f-4448-bd84-a7b79097c4cf' && other !== 'total') {
                return (
                    <View style={[styles.numberCells, { width: '85%' }]}>
                        <View style={{ display: 'flex', flexDirection: 'column' }}>
                            {answerData[optionId] &&
                                answerData[optionId].map((item, index) => {
                                    if (index + 1 === answerData[optionId].length) {
                                        return (
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                                key={index}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            backgroundColor: '#fff',
                                                            width: '25%',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>{item[0].value}</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            backgroundColor: '#fff',
                                                            width: '15%',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>{item[1].value}</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            backgroundColor: '#fff',
                                                            width: '25%',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>{item[2].value}</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            width: '35%',
                                                            borderRight: '0px',
                                                            backgroundColor: '#fff',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>{item[3].value}</Text>
                                                </View>
                                            </View>
                                        );
                                    } else {
                                        return (
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                                key={index}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            backgroundColor: '#fff',
                                                            width: '25%',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>{item[0].value}</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            backgroundColor: '#fff',
                                                            width: '15%',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>{item[1].value}</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            backgroundColor: '#fff',
                                                            width: '25%',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>{item[2].value}</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            backgroundColor: '#fff',
                                                            width: '35%',
                                                            borderRight: '0px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>{item[3].value}</Text>
                                                </View>
                                            </View>
                                        );
                                    }
                                })}
                        </View>
                    </View>
                );
            } else if (
                questionId === '7026fb7d-baf8-4c64-b1d3-feb41125f39d' &&
                optionId === 'c183c42-1ac2-c1d3-2ac-c85bfbe4fdea'
            ) {
                // 5.6.2 - klassifiseringer
                return (
                    <View style={[styles.numberCells, { width: '65%' }]}>
                        <View style={{ display: 'flex', flexDirection: 'column' }}>
                            {answerData[optionId] &&
                                answerData[optionId].map((item, index) => {
                                    return (
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                            key={index}
                                        >
                                            <View
                                                style={[
                                                    styles.cell,
                                                    {
                                                        width: '35%',
                                                    },
                                                ]}
                                            >
                                                <Text style={[styles.cellText, {}]}>{item[0].value}</Text>
                                            </View>
                                            <View
                                                style={[
                                                    styles.cell,
                                                    {
                                                        width: '65%',
                                                        borderRight: '0px',
                                                        backgroundColor: '#fff',
                                                    },
                                                ]}
                                                key={index}
                                            >
                                                <Text style={styles.cellText}>{item[1].value}</Text>
                                            </View>
                                        </View>
                                    );
                                })}
                        </View>
                    </View>
                );
            } else if (questionId === 'fa3ea967-195f-4448-bd84-a7b79097c4cf' && other === 'total') {
                let totalNumber = 0;

                for (const item of answerData[optionId]) {
                    totalNumber = totalNumber + parseInt(item[1].value);
                }

                return totalNumber;
            } else if (optionData.type === 'Text' || optionData.type === 'Textfield' || optionData.type === 'Number') {
                if (answerData[optionId] && answerData[optionId] !== '') {
                    return answerData[optionId];
                } else {
                    return <Text style={styles.cellText}>N/A</Text>;
                }
            } else if (optionData.type === 'Select') {
                if (answerData[optionId] && answerData[optionId] !== '') {
                    return optionData.alternatives.find(item => item.id === answerData[optionId]).label;
                } else {
                    return <Text style={styles.cellText}>N/A</Text>;
                }
            } else if (optionData.type === 'RadioGroup') {
                let itemLabel = 'N/A';

                for (const alt of optionData.alternatives) {
                    if (alt.id === answerData[optionId].id) {
                        itemLabel = alt.label;
                    }
                }

                return itemLabel;
            } else if (optionData.type === 'DateTime') {
                if (answerData[optionId] && answerData[optionId] !== '') {
                    let date = new Date(answerData[optionId]);

                    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                } else {
                    return <Text style={styles.cellText}>N/A</Text>;
                }
            }
        } else {
            return <Text style={styles.cellText}>N/A</Text>;
        }
    };

    const checkClimateCatAnswer = altId => {
        let responses = report.responses.items;

        // Find response based on question ID
        let singleResponseData = responses.find(item => item.questionId === '212ed41b-8647-4f90-9b02-4960eb2850a5');

        if (singleResponseData) {
            // If question is not answered, return no answer
            if (!singleResponseData.isAnswered) {
                return false;
            } else {
                // Fetch answer data from chosen response
                let answerData = JSON.parse(singleResponseData.data);

                let checkIfContainsAlt = answerData['fe71025-061-87c-aa-d8ce70f2bb'].checked.includes(altId);

                if (checkIfContainsAlt === true) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    };

    const checkForCustomRiskOppAnswer = (questionId, optionId, areaId) => {
        let responses = report.responses.items;

        // Find response based on question ID
        let singleResponseData = responses.find(item => item.questionId === questionId);

        if (singleResponseData) {
            // If question is not answered, return no answer
            if (!singleResponseData.isAnswered) {
                return false;
            }

            // Find question type from NSRS categories
            let category = nsrsData.find(item => item.id === singleResponseData.categoryId);
            let subcategory = category.subcategories.items.find(item => item.id === singleResponseData.subcategoryId);
            let question = subcategory.questions.items.find(item => item.id === singleResponseData.questionId);
            let questionData = JSON.parse(question.data);

            let optionData;

            for (const question of questionData) {
                for (const element of question.elements) {
                    if (element.id === optionId) {
                        optionData = element;
                        break;
                    }
                }
            }

            if (!optionData) {
                return false;
            }

            // Fetch answer data from chosen response
            let answerData = JSON.parse(singleResponseData.data);

            let foundArea = false;

            for (const item of answerData[optionId]) {
                if (item.area === areaId) {
                    foundArea = true;
                    break;
                }
            }

            if (foundArea) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const getCustomRiskOppAnswers = (questionId, optionId, areaId, term) => {
        let responses = report.responses.items;

        let failedToFind = false;
        let dataArr = [];

        // Find response based on question ID
        let singleResponseData = responses.find(item => item.questionId === questionId);

        if (singleResponseData) {
            // If question is not answered, return no answer
            if (!singleResponseData.isAnswered) {
                return <Text style={styles.cellText}>N/A</Text>;
            }

            // Find question type from NSRS categories
            let category = nsrsData.find(item => item.id === singleResponseData.categoryId);
            let subcategory = category.subcategories.items.find(item => item.id === singleResponseData.subcategoryId);
            let question = subcategory.questions.items.find(item => item.id === singleResponseData.questionId);
            let questionData = JSON.parse(question.data);

            let optionData;

            for (const question of questionData) {
                for (const element of question.elements) {
                    if (element.id === optionId) {
                        optionData = element;
                        break;
                    }
                }
            }

            if (!optionData) {
                failedToFind = true;
            }

            // Fetch answer data from chosen response
            let answerData = JSON.parse(singleResponseData.data);

            for (const item of answerData[optionId]) {
                if (item.area === areaId && item.timeframe === term) {
                    dataArr.push(item);
                }
            }

            if (dataArr.length === 0) {
                failedToFind = true;
            }
        } else {
            failedToFind = true;
        }

        if (!failedToFind) {
            return (
                <View>
                    <View style={[styles.tableRow, { backgroundColor: '#EEEEEE' }]} wrap={false}>
                        <View style={styles.MainCell}>
                            <View
                                style={[
                                    styles.cell,
                                    {
                                        width: '34%',
                                        alignItems: 'flex-start',
                                        borderRight: '0px',
                                    },
                                ]}
                            >
                                <Text style={styles.cellText}>
                                    {term === 'Shortterm'
                                        ? 'Kortsiktig risiko (0 - 2 år)'
                                        : 'Langsiktig risiko (3+ år)'}
                                </Text>
                            </View>
                        </View>
                    </View>
                    {dataArr.map((item, index) => {
                        return (
                            <View style={styles.tableRow} wrap={false} key={index}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '20%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>{item.category}</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '20%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>{item.influence}</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '12%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>{item.values.knowledge}</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '12%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>{item.values.relevance}</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '12%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>{item.values.influence}</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                width: '40%',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                            },
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                styles.cellText,
                                                {
                                                    textAlign: 'left',
                                                    paddingBottom: '20px',
                                                },
                                            ]}
                                        >
                                            {item.description}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        );
                    })}
                </View>
            );
        }
    };

    const getCustomBrandProduct = (questionId, optionId, type) => {
        let responses = report.responses.items;

        let failedToFind = false;
        let dataArr = [];

        // Find response based on question ID
        let singleResponseData = responses.find(item => item.questionId === questionId);

        if (singleResponseData) {
            // If question is not answered, return no answer
            if (!singleResponseData.isAnswered) {
                return <Text style={styles.cellText}>N/A</Text>;
            }

            // Find question type from NSRS categories
            let category = nsrsData.find(item => item.id === singleResponseData.categoryId);
            let subcategory = category.subcategories.items.find(item => item.id === singleResponseData.subcategoryId);
            let question = subcategory.questions.items.find(item => item.id === singleResponseData.questionId);
            let questionData = JSON.parse(question.data);

            let optionData;

            for (const question of questionData) {
                for (const element of question.elements) {
                    if (element.id === optionId) {
                        optionData = element;
                        break;
                    }
                }
            }

            if (!optionData) {
                failedToFind = true;
            }

            // Fetch answer data from chosen response
            let answerData = JSON.parse(singleResponseData.data);

            for (const item of answerData[optionId]) {
                if (item.type === type) {
                    dataArr.push(item);
                }
            }

            if (dataArr.length === 0) {
                failedToFind = true;
            }
        } else {
            failedToFind = true;
        }

        if (failedToFind) {
            return <Text style={styles.cellText}>N/A</Text>;
        } else {
            return dataArr.map((item, index) => {
                return (
                    <View key={index} style={index % 2 === 0 ? styles.row : styles.row2}>
                        <View style={styles.cell1}>
                            <Text style={styles.cellText}>{item.name}</Text>
                        </View>
                        <View style={styles.cell2}>
                            <Text style={styles.cellText}>{item.description}</Text>
                        </View>
                    </View>
                );
            });
        }
    };

    return (
        <Document>
            <Page size="A4" style={styles.frontPage} wrap={false}>
                <View style={styles.frontPageView}>
                    <Image src={FrontPage} style={styles.frontPageImage} />
                    <View style={styles.titleBox}>
                        <View>
                            <Text style={[styles.title, { fontSize: '160px', color: '#000000' }]}>
                                {/* 1.1.1 - årstall */}
                                {fetchAnswerByIds(
                                    '557a93de-e843-4d5f-8934-4fd8b1d38e37',
                                    '8a51bc6-ab3d-882e-a54b-28bda41e876',
                                )}
                            </Text>
                        </View>
                        <View>
                            <Text style={[styles.h2, { fontSize: '30px', marginBottom: '-10px' }]}>Rapport</Text>
                            <Text style={[styles.title, { fontSize: '71px' }]}>Bærekraft</Text>
                        </View>
                    </View>
                    <View style={styles.titleBox2}>
                        <Text style={[styles.h2, { fontSize: '48px', marginBottom: '-10px' }]}>
                            {/* 2.1.1 - bedriftnavn */}
                            {fetchAnswerByIds(
                                '6acc64ce-f77e-4917-9213-3b3510f8b657',
                                '0473f31-453d-703a-e037-3d22d5018dc',
                            )}
                        </Text>
                        <Text style={[styles.h2, { fontSize: '20' }]}>
                            {/* 2.1.4 - bedriftens nettside */}
                            {fetchAnswerByIds(
                                'e6ad4a06-3709-4d70-8ea5-787a5144d1b2',
                                '6f6171f-803c-e604-c7f8-e12fba7c825',
                            )}
                        </Text>
                        <Text style={[styles.h2, { fontSize: '15' }]}>
                            {/* 2.1.5 - kommune/by */}
                            {fetchAnswerByIds(
                                '75917bea-199c-4498-992b-8a6ed0129a17',
                                'e8be612-ed3-f3fb-ff0e-55b7aaf445f1',
                            )}
                            , {/* 2.1.5 - land */}
                            {fetchAnswerByIds(
                                '75917bea-199c-4498-992b-8a6ed0129a17',
                                '4dc821e-b183-10d6-dae-33a7f5cfa2',
                            )}
                        </Text>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.toppBar} />
                <View style={styles.content}>
                    <Text style={[styles.h1, { marginTop: '0px' }]}>
                        {/* 1.1.2 - overskrift */}
                        {fetchAnswerByIds('e9d095c6-95f2-4d7a-90ee-3361b8e52b68', 'f3b6dc-a3cc-acc-24-34b4d6106c46')}
                    </Text>
                    <Text style={[styles.p, { marginBottom: '5px' }]}>
                        {/* 1.1.2 - uttalelse */}
                        {fetchAnswerByIds('e9d095c6-95f2-4d7a-90ee-3361b8e52b68', '63e1f8f-d735-852-ab7-aba7d6f382')}
                    </Text>
                    <View style={styles.signatureContainer}>
                        <Text style={styles.signature}>
                            {/* 1.1.2 - navn */}
                            {fetchAnswerByIds(
                                'e9d095c6-95f2-4d7a-90ee-3361b8e52b68',
                                '3681c16-8a0e-ea1-cdc2-0245614754e',
                            )}
                        </Text>
                        <Text style={[styles.signature, { fontSize: '12px' }]}>
                            {/* 1.1.2 - tittel */}
                            {fetchAnswerByIds(
                                'e9d095c6-95f2-4d7a-90ee-3361b8e52b68',
                                'ca13a4b-5500-cbf0-8b33-1772855cb3cc',
                            )}
                        </Text>
                    </View>
                    <Text style={styles.h1}>Klimamål</Text>
                    <Text style={styles.boldP}>
                        {fetchAnswerByIds('4f1da433-2366-49a4-8666-0d5a5b037e5f', '436373-37-808-5ee-1d83023b35dd')}
                    </Text>
                    <Text style={styles.p}>
                        {/* 5.5.6 - klimamål beskrivelse */}
                        {fetchAnswerByIds('4f1da433-2366-49a4-8666-0d5a5b037e5f', '5841e4c-12e1-8066-cbe-8d6c1270b4a')}
                    </Text>
                    <Text style={[styles.boldP, { margin: '15px 0px 5px 0px' }]}>
                        År for måloppnåelse: {/* 5.5.6 - klimamål */}
                        {fetchAnswerByIds('4f1da433-2366-49a4-8666-0d5a5b037e5f', '588543-0eb-324-ba5-3e4d7c1a4377')}
                    </Text>
                    <Text style={[styles.boldP]}>
                        Basisår for oppstart måling: {/* 5.5.6 - klimamål */}
                        {fetchAnswerByIds('4f1da433-2366-49a4-8666-0d5a5b037e5f', '8ffa27e-f5fe-e87-348-84e2e36c20c2')}
                    </Text>
                    <Text style={styles.h1}>Kontakt oss</Text>
                    <Text style={[styles.p, { marginBottom: '5px' }]}>
                        Har du spørsmål eller tilbakemeldinger angående rapporten og/eller dens innhold kan kontakte oss
                        på telefon eller epost.
                    </Text>
                    <Text style={styles.signature}>
                        {/* 1.3.1 - kontaktinfo navn */}
                        {fetchAnswerByIds('d39ed5c8-616d-4705-8549-3a15c9dbc790', 'cb1035f-2371-b3b4-43c-fc04be3ad7bb')}
                    </Text>
                    <Text style={[styles.signature, { fontSize: '12px' }]}>
                        +47 {/* 1.3.1 - tlf. nummer */}
                        {fetchAnswerByIds('d39ed5c8-616d-4705-8549-3a15c9dbc790', '86b0302-342-a11f-7e80-bb227ef241d')}
                    </Text>
                    <Text style={[styles.signature, { fontSize: '12px', marginBottom: '20px' }]}>
                        {/* 1.3.1 - kontaktinfo email */}
                        {fetchAnswerByIds('d39ed5c8-616d-4705-8549-3a15c9dbc790', '7ee515d-2f08-812a-545-2af13f2fee7')}
                    </Text>
                </View>
                <View fixed={true} style={{ flexGrow: 1 }}></View>
                <Image src={Grey1} style={{}} />
                <View
                    fixed={true}
                    style={[
                        styles.footer,
                        {
                            marginTop: '0px',
                            position: 'fixed',
                            bottom: '0px',
                            left: '0px',
                        },
                    ]}
                >
                    <Text
                        style={[styles.pageNb, { marginRight: '-20px' }]}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.toppBar} />
                <View style={styles.content}>
                    <Text style={[styles.h1, { marginTop: '0px' }]}>Innhold</Text>
                    <View style={{ padding: '10px 0px 10px 0px' }}>
                        <View style={styles.contentRow}>
                            <View style={styles.rightContent}>
                                <Text style={styles.contentNumber}>1.0</Text>
                                <Text style={styles.contentName}>Profil</Text>
                            </View>
                            <View>
                                <Text style={[styles.contentPage, { justifyContent: 'flex-end' }]}></Text>
                            </View>
                        </View>
                        <View style={styles.contentRow}>
                            <View style={styles.rightContent}>
                                <Text style={styles.contentNumber}>2.0</Text>
                                <Text style={styles.contentName}>Styringsprofil</Text>
                            </View>
                            <View>
                                <Text style={[styles.contentPage, { justifyContent: 'flex-end' }]}></Text>
                            </View>
                        </View>
                        <View style={styles.contentRow}>
                            <View style={styles.rightContent}>
                                <Text style={styles.contentNumber}>3.0</Text>
                                <Text style={styles.contentName}>Økonomi</Text>
                            </View>
                            <View>
                                <Text style={[styles.contentPage, { justifyContent: 'flex-end' }]}></Text>
                            </View>
                        </View>
                        <View style={styles.contentRow}>
                            <View style={styles.rightContent}>
                                <Text style={styles.contentNumber}>4.0</Text>
                                <Text style={styles.contentName}>Sosiale forhold</Text>
                            </View>
                            <View>
                                <Text style={[styles.contentPage, { justifyContent: 'flex-end' }]}></Text>
                            </View>
                        </View>
                        <View style={styles.contentRow}>
                            <View style={styles.rightContent}>
                                <Text style={styles.contentNumber}>5.0</Text>
                                <Text style={styles.contentName}>Miljøfaktorer</Text>
                            </View>
                            <View>
                                <Text style={[styles.contentPage, { justifyContent: 'flex-end' }]}></Text>
                            </View>
                        </View>
                        <View style={styles.contentRow}>
                            <View style={styles.rightContent}>
                                <Text style={styles.contentNumber}>6.0</Text>
                                <Text style={styles.contentName}>Risiko og muligheter</Text>
                            </View>
                            <View>
                                <Text style={[styles.contentPage, { justifyContent: 'flex-end' }]}></Text>
                            </View>
                        </View>
                        <View style={styles.contentRow}>
                            <View style={styles.rightContent}>
                                <Text style={styles.contentNumber}>7.0</Text>
                                <Text style={styles.contentName}>Bærekraftsinitiativer</Text>
                            </View>
                            <View>
                                <Text style={[styles.contentPage, { justifyContent: 'flex-end' }]}></Text>
                            </View>
                        </View>
                        <View style={styles.contentRow}>
                            <View style={styles.rightContent}>
                                <Text style={styles.contentNumber}>8.0</Text>
                                <Text style={styles.contentName}>Bærekraftsmål</Text>
                            </View>
                            <View>
                                <Text style={[styles.contentPage, { justifyContent: 'flex-end' }]}></Text>
                            </View>
                        </View>
                        <View style={styles.contentRow}>
                            <View style={styles.rightContent}>
                                <Text style={styles.contentNumber}>8.0</Text>
                                <Text style={styles.contentName}>Signatur</Text>
                            </View>
                            <View>
                                <Text style={[styles.contentPage, { justifyContent: 'flex-end' }]}></Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.h1}>Om denne rapporten</Text>
                    <Text style={[styles.p, { marginBottom: '10px' }]}>
                        Denne bærekraftsrapporten er utarbeidet i samsvar med Nordic Sustainability Reporting Standard -
                        NSRS Nivå 1. Alle rettigheter forbeholdt. Les mer på www.nsrs.eu.
                    </Text>
                    <Text style={[styles.p, { marginBottom: '10px' }]}>
                        Denne rapporten er tilpasset Global Reporting Initiative (GRI), direktivet om ikke-finansiell
                        rapportering (NFRD) og Task-Force on Climate-Related Disclosures (TCFD). Dette betyr ikke at
                        rapporten er i tråd med disse rammene. Les mer på www.nsrs.eu.
                    </Text>
                    <Text style={styles.p}>
                        {/* 1.2.1 - påstand */}
                        {fetchAnswerByIds('b931f54e-ca88-4fb5-a381-b3fbdca8cefd', 'aee22-f8ba-08db-a3e2-8bd11b834f16')}
                    </Text>
                </View>
                <View fixed={true} style={{ flexGrow: 1 }}></View>
                <View
                    fixed={true}
                    style={[
                        styles.footer,
                        {
                            margin: '',
                            position: 'fixed',
                            bottom: '0px',
                            left: '0px',
                        },
                    ]}
                >
                    <Text
                        style={[styles.pageNb, { marginRight: '-20px' }]}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View fixed={true} style={styles.toppBar} />
                <View style={styles.content}>
                    {/* Profil */}
                    <View>
                        <Text style={[styles.mainTitle, { marginTop: '0px' }]}>Profil</Text>
                        <Text style={styles.h1}>Hvem er vi?</Text>
                        <View style={styles.table}>
                            <View style={styles.row}>
                                <View style={styles.cell1}>
                                    <Text>Organisasjonsnavn</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text>
                                        {/* 2.1.1 - bedriftsnavn */}
                                        {fetchAnswerByIds(
                                            '6acc64ce-f77e-4917-9213-3b3510f8b657',
                                            '0473f31-453d-703a-e037-3d22d5018dc',
                                        )}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={styles.cell1}>
                                    <Text>Organisasjonsnummer</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text>
                                        {/* 2.1.2 - org. nummer */}
                                        {fetchAnswerByIds(
                                            '71629657-58b3-411b-b77a-11783accd6fb',
                                            'df7ffe4-bf87-bb03-658f-2eab450f48',
                                        )}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.cell1}>
                                    <Text>Juridisk form</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text>
                                        {/* 2.1.3 - organisasjonsform */}
                                        {fetchAnswerByIds(
                                            '8c6beeef-b615-4aeb-9e3f-e292746e1435',
                                            'fa4c0b-5cfc-13bd-0815-736f37ac42b2',
                                        )}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={styles.cell1}>
                                    <Text>Organisasjonens webside</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text>
                                        {/* 2.1.4 - bedriftens nettside */}
                                        {fetchAnswerByIds(
                                            'e6ad4a06-3709-4d70-8ea5-787a5144d1b2',
                                            '6f6171f-803c-e604-c7f8-e12fba7c825',
                                        )}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.cell1}>
                                    <Text>Hovedkontorets lokasjon</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text>
                                        {/* 2.1.5 - gate */}
                                        {fetchAnswerByIds(
                                            '75917bea-199c-4498-992b-8a6ed0129a17',
                                            '2c51234-4c-056-af85-bedcc87f7832',
                                        )}
                                        , {/* 2.1.5 - poststed */}
                                        {fetchAnswerByIds(
                                            '75917bea-199c-4498-992b-8a6ed0129a17',
                                            '2e3337e-2144-3b21-1c7-b52d257aea',
                                        )}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={styles.cell1}>
                                    <Text>Organisasjonens størrelse</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text>
                                        {/* 2.2.1 - antall ansatte */}
                                        {fetchAnswerByIds(
                                            '5cd4c337-7d6f-4618-8f80-1b58a6b9dbd8',
                                            '545c4ef-77a-7a27-7f5d-20e24c4af6be',
                                        )}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.h3}>Andre adresser</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text>Gatenavn & nr</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text>Postnummer & sted</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '15%' }]}>
                                    <Text>Kommune</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '15%' }]}>
                                    <Text>Land</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '30%' }]}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                            {/* 2.1.5 - andre foretningsadresser */}
                            {fetchAnswerByIds(
                                '3d8c7d30-be6f-4813-bc54-015cb7d6194e',
                                '6fd205-b648-0d6f-7665-d1db7a2f321',
                            )}
                        </View>
                        <Text style={styles.h1}>Hva gjør vi?</Text>
                        <Text style={styles.h3}>Hovedaktiviteter klassifisert under NACE-koder</Text>
                        <View
                            style={{
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            {/* 2.3.1 - NACE koder */}
                            {fetchAnswerByIds(
                                '899a227c-d04b-4534-840d-31fb57f53100',
                                'ccf4f3-4a5-515-b2e0-b6c02e010be',
                            )}
                        </View>
                        <Text style={styles.h3}>Aktiviteter, varemerker, produkter og tjenester</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.headCell1}>
                                    <Text>Produkt</Text>
                                </View>
                                <View style={styles.headCell2}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                            {/* 2.3.2 - produkter */}
                            {getCustomBrandProduct(
                                '025c5118-3d13-4c85-a7c8-ebb5e9ed5cf1',
                                '2c20a-6fc1-7461-f30b-cbede6b8584b',
                                'Product',
                            )}
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.headCell1}>
                                    <Text>Varemerke</Text>
                                </View>
                                <View style={styles.headCell2}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                            {/* 2.3.2 - varemerker */}
                            {getCustomBrandProduct(
                                '025c5118-3d13-4c85-a7c8-ebb5e9ed5cf1',
                                '2c20a-6fc1-7461-f30b-cbede6b8584b',
                                'Brand',
                            )}
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.headCell1}>
                                    <Text>Tjeneste</Text>
                                </View>
                                <View style={styles.headCell2}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                            {/* 2.3.2 - tjenester */}
                            {getCustomBrandProduct(
                                '025c5118-3d13-4c85-a7c8-ebb5e9ed5cf1',
                                '2c20a-6fc1-7461-f30b-cbede6b8584b',
                                'Service',
                            )}
                        </View>
                        <Text style={styles.h3}>
                            Generell beskrivelse av produkter og tjenester som er forbudt i visse markeder
                        </Text>
                        <Text style={styles.p}>
                            {/* 2.3.2 - generell beskrivelse */}
                            {fetchAnswerByIds(
                                '025c5118-3d13-4c85-a7c8-ebb5e9ed5cf1',
                                'a3c4cc-0e4a-3fc-144b-35af223de36',
                            )}
                        </Text>
                        <Text style={styles.h1}>Vår verdikjede</Text>
                        <Text style={styles.h3}>Hovedleverandører</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text>Leverandør</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '40%' }]}>
                                    <Text>Beskrivelse</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '40%' }]}>
                                    <Text>Produkter/tjenester fra leverandør</Text>
                                </View>
                            </View>
                            {/* 3.1.1 - fem hovedleverandører */}
                            {fetchAnswerByIds(
                                '8a39f65f-1bf0-4cef-b0f6-40044f5df0eb',
                                '0367da7-8226-8d84-8010-7a86ffa52664',
                            )}
                        </View>
                        <Text style={styles.h3}>Interessenter - Interne</Text>
                        <Text style={styles.p}>Sortert fra viktigst til mindre viktig</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '5%' }]}>
                                    <Text>#</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '30%' }]}>
                                    <Text>Interessenter</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '65%' }]}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                            {/* 3.1.2 - fem interne interessenter */}
                            {fetchAnswerByIds(
                                '01856bdd-63d1-4e57-880f-4a4497f962a3',
                                'd4c1bfa-5868-3384-42c0-48753b0703d8',
                                'Intern',
                            )}
                        </View>
                        <Text style={styles.h3}>Interessenter - Eksterne</Text>
                        <Text style={styles.p}>Sortert fra viktigst til mindre viktig</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '5%' }]}>
                                    <Text>#</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '30%' }]}>
                                    <Text>Interessenter</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '65%' }]}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                            {/* 3.1.2 - fem eksterne interessenter */}
                            {fetchAnswerByIds(
                                '01856bdd-63d1-4e57-880f-4a4497f962a3',
                                'd4c1bfa-5868-3384-42c0-48753b0703d8',
                                'Ekstern',
                            )}
                        </View>
                        <Text style={styles.h3}>Kontroll av verdikjeden</Text>
                        <Text style={styles.p}>
                            {/* 3.1.3 - verdikjede */}
                            {fetchAnswerByIds(
                                'eaa5dedc-defc-426e-99a9-3a4fab0a3fed',
                                '8e7f88-be0e-26b6-2465-b03d148c3d5',
                            )}
                        </Text>
                        <Text style={styles.h3}>Kontroll av forsyningskjeden</Text>
                        <Text style={styles.p}>
                            {/* 3.1.4 - forsyningskjede */}
                            {fetchAnswerByIds(
                                'dc940dbe-93e6-444b-8d7c-2c70042c6a3f',
                                'c47fe-cd1f-04eb-c031-4c2b1cf677',
                            )}
                        </Text>
                    </View>
                    {/* Styringsprofil */}
                    <View>
                        <Text style={styles.mainTitle}>Styringsprofil</Text>
                        <Text style={styles.h1}>Våre hovedverdier</Text>
                        <Text style={styles.p}>
                            {/* 3.2.1 - hovedverdier */}
                            {fetchAnswerByIds(
                                'd0d5c667-4b4b-4317-bf40-f1af05635c08',
                                '4fac0e-d00f-fb8-1fe7-e37d63685035',
                            )}
                        </Text>
                        <Text style={styles.h1}>Styringsstruktur</Text>
                        <Text style={styles.p}>
                            {/* 3.3.1 - beskriv styringsstruktur */}
                            {fetchAnswerByIds(
                                'ee4eb612-5a4e-406f-a25e-cbc6dec5f9ee',
                                '2d6fec-df66-7d57-d17-42b5a0606adb',
                            )}
                        </Text>
                        <Text style={styles.h3}>Komiteer i det øverste styringsorganet.</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.headCell1}>
                                    <Text>Komitè</Text>
                                </View>
                                <View style={styles.headCell2}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                        </View>
                        {/* 3.3.2 - komiteer for øverste styringsorgan */}
                        {fetchAnswerByIds(
                            '8b712a8c-b012-48ae-b6c1-ec7d4d6fcc71',
                            '0e0bc2d-e021-2a63-61ae-4c6d1014b02e',
                        )}
                        <Text style={styles.h3}>
                            Komiteer som er ansvarlige for beslutningstaking om økonomiske, miljømessige, og sosiale
                            temaer.
                        </Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.headCell1}>
                                    <Text>Komitè</Text>
                                </View>
                                <View style={styles.headCell2}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                            {/* 3.3.3 - andre komiteer */}
                            {fetchAnswerByIds(
                                'c8dc1965-8dfd-4781-9b1b-7128d3273f44',
                                'd5c337-fbdc-a740-702f-7125eea7ff3',
                            )}
                        </View>
                        <Text style={styles.h1}>Våre sertifiseringer</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text>Sertifisering</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text>ID</Text>
                                </View>

                                <View style={[styles.headCell2, { width: '60%' }]}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                            {/* 3.4.1 - liste av miljø sertifiseringer */}
                            {fetchAnswerByIds(
                                'bc6961c6-0f57-4cfe-b437-6dc3aa2caee7',
                                '38fe30-dd10-5273-c62-1ab2e621634',
                            )}
                        </View>
                        <Text style={styles.h1}>Sirkulær forretningsmodell</Text>
                        <Text style={styles.p}>
                            {/* 3.5.1 - sirkulær forretningsmodell */}
                            {fetchAnswerByIds(
                                '958d1ab9-0592-4c57-9823-3a14c24f8cce',
                                '4a815e1-8d10-1c1e-410-0c6fa7f7484f',
                            )}
                        </Text>
                        <Text style={styles.h1}>Kontaktpersoner</Text>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <View style={{ width: '50%' }}>
                                <Text style={styles.h3}>Økonomiansvarlig</Text>
                                <Text style={styles.signature}>
                                    {/* 3.3.4 - økonomiansvarlig navn */}
                                    {fetchAnswerByIds(
                                        '2b4f1647-f489-4c0a-82d7-f7efe6b7c736',
                                        '0c1a76b-85a-a845-a35f-f1b4528a745',
                                    )}
                                </Text>
                                <Text style={[styles.signature, { fontSize: '12px' }]}>
                                    {/* 3.3.4 - økonomiansvarlig tittel */}
                                    {fetchAnswerByIds(
                                        '2b4f1647-f489-4c0a-82d7-f7efe6b7c736',
                                        '12a525c-0fce-fcea-e53b-706bac16f823',
                                    )}
                                </Text>
                                <Text style={[styles.signature, { fontSize: '12px' }]}>
                                    {/* 3.3.4 - økonomiansvarlig epost */}
                                    {fetchAnswerByIds(
                                        '2b4f1647-f489-4c0a-82d7-f7efe6b7c736',
                                        'a6ab43c-5bb5-2c56-c240-4037706d5faa',
                                    )}
                                </Text>
                            </View>
                            <View style={{ width: '50%' }}>
                                <Text style={styles.h3}>Rapportansvarlig</Text>
                                <Text style={styles.signature}>
                                    {/* 3.3.5 - rapportansvarlig navn */}
                                    {fetchAnswerByIds(
                                        '9f89d3b1-57a4-4f99-9c8e-e1f5d423d36d',
                                        '2610-2a60-2cf2-2d0d-81f1847e7a28',
                                    )}
                                </Text>
                                <Text style={[styles.signature, { fontSize: '12px' }]}>
                                    {/* 3.3.5 - rapportansvarlig tittel */}
                                    {fetchAnswerByIds(
                                        '9f89d3b1-57a4-4f99-9c8e-e1f5d423d36d',
                                        'd74af2d-5f18-d6e3-482c-342dedfe050',
                                    )}
                                </Text>
                                <Text style={[styles.signature, { fontSize: '12px' }]}>
                                    {/* 3.3.5 - rapportansvarlig epost */}
                                    {fetchAnswerByIds(
                                        '9f89d3b1-57a4-4f99-9c8e-e1f5d423d36d',
                                        '128f74-a2ba-83c1-8ad6-888c72d7ed6f',
                                    )}
                                </Text>
                            </View>
                        </View>
                    </View>
                    {/* Økonomi */}
                    <View>
                        <Text style={styles.mainTitle}>Økonomi</Text>
                        <Text style={styles.h1}>Økonomi- og bærekraftig balanse</Text>
                        <Text style={styles.p}>
                            {/* 3.5.3 - økonomi- og bærekraftig balanse */}
                            {fetchAnswerByIds(
                                'e7628419-c6d1-4e97-a90b-8fc0fbdf2d61',
                                '4cd7771-8502-3440-d86b-2f28b32ab7',
                            )}
                        </Text>
                        <Text style={styles.h1}>Vårt omfang</Text>
                        <Text style={styles.h3}>Omsetning</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '33%' }]}>
                                    <Text>Nettoomsetning/nettoinntekter</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '34%' }]}>
                                    <Text>Gjeld</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '33%' }]}>
                                    <Text>Egenkapital</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell1, { width: '33%' }]}>
                                    <Text>
                                        {/* 2.2.2 - netto */}
                                        {fetchAnswerByIds(
                                            '8c1d2e04-0512-4f96-b420-15d61e94ce30',
                                            'cda7cc0-fa1d-460-0b86-4eb811e54013',
                                        )}{' '}
                                        kr
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '34%' }]}>
                                    <Text>
                                        {/* 2.2.2 - gjeld */}
                                        {fetchAnswerByIds(
                                            '8c1d2e04-0512-4f96-b420-15d61e94ce30',
                                            '826ff1-77c-05e-38e3-103eff6d2dfb',
                                        )}{' '}
                                        kr
                                    </Text>
                                </View>
                                <View style={[styles.cell2, { width: '33%' }]}>
                                    <Text>
                                        {/* 2.2.2 - egenkapital */}
                                        {fetchAnswerByIds(
                                            '8c1d2e04-0512-4f96-b420-15d61e94ce30',
                                            'ce1a3f-2245-4565-5bd2-b37b12fbeb',
                                        )}{' '}
                                        kr
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.h1}>Stabil økonomi</Text>
                        <Text style={styles.p}>
                            {/* 3.5.4 - stabil økonomi */}
                            {fetchAnswerByIds(
                                '67629ac3-9b64-4b5f-89a1-f99a9fd4a985',
                                '01ea38a-b8a0-d3ee-eac-ff040317f',
                            )}
                        </Text>
                        <Text style={styles.h1}>Kostnadsbesparelser</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '30%' }]}>
                                    <Text>Område</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '70%' }]}>
                                    <Text>Beskrivelse</Text>
                                </View>
                            </View>
                            {/* 3.5.5 - kostnad sparing */}
                            {fetchAnswerByIds(
                                '249454ae-83ec-4c8e-92bc-928d2eca6993',
                                '66fc704-0af2-6704-d425-3ffb152a841',
                            )}
                        </View>
                        <Text style={styles.h1}>Ikke-finansielle opplysninger</Text>
                        <View style={styles.table}>
                            {/* 3.5.2 - ikke-finansielle */}
                            {fetchAnswerByIds(
                                '666e1f7e-f739-4e0a-8b5b-ab0112b9b600',
                                'c638aa4-831-2fd-b6bc-e0c8c203af',
                            )}
                        </View>
                    </View>
                    {/* Sosiale forhold */}
                    <View>
                        <Text style={styles.mainTitle}>Sosiale forhold</Text>
                        <Text style={styles.h1}>Mangfold</Text>
                        <Text style={styles.h3}>Aldersfordeling i bedriften</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '17%' }]}>
                                    <Text> - 18 år</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '16%' }]}>
                                    <Text>18 - 30 år</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '17%' }]}>
                                    <Text>31 - 40 år</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '16%' }]}>
                                    <Text>41 - 50 år</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '17%' }]}>
                                    <Text>51 - 60 år</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '16%' }]}>
                                    <Text>60 -</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell1, { width: '17%' }]}>
                                    <Text>
                                        {/* 4.2.1 - kjønnsfordeling */}
                                        {fetchAnswerByIds(
                                            '4afc2b19-eed0-4628-8ebd-1751b735433d',
                                            '86b267b-522d-125f-a681-0ba234d3dde0',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '16%' }]}>
                                    <Text>
                                        {/* 4.2.1 - kjønnsfordeling */}
                                        {fetchAnswerByIds(
                                            '4afc2b19-eed0-4628-8ebd-1751b735433d',
                                            '10404-a8e3-ea80-e887-21c8a1caad0a',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '17%' }]}>
                                    <Text>
                                        {/* 4.2.1 - kjønnsfordeling */}
                                        {fetchAnswerByIds(
                                            '4afc2b19-eed0-4628-8ebd-1751b735433d',
                                            '17e6241-12cb-3b1e-f76-6e74dac0d8',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '16%' }]}>
                                    <Text>
                                        {/* 4.2.1 - kjønnsfordeling */}
                                        {fetchAnswerByIds(
                                            '4afc2b19-eed0-4628-8ebd-1751b735433d',
                                            '88537fb-6f0-a240-e41d-a0adae141f37',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '17%' }]}>
                                    <Text>
                                        {/* 4.2.1 - kjønnsfordeling */}
                                        {fetchAnswerByIds(
                                            '4afc2b19-eed0-4628-8ebd-1751b735433d',
                                            '15e8437-7684-3a63-32-d1c6272bd2a8',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell2, { width: '16%' }]}>
                                    <Text>
                                        {/* 4.2.1 - kjønnsfordeling */}
                                        {fetchAnswerByIds(
                                            '4afc2b19-eed0-4628-8ebd-1751b735433d',
                                            '6bc2fa-c6-e1ef-c88f-428a14508132',
                                        )}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.h3}>Tiltak som er iverksatt for å utvide aldersfordelingen.</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '15%' }]}>
                                    <Text>Huket av</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '85%' }]}>
                                    <Text>Tiltak</Text>
                                </View>
                            </View>
                            {/* 4.2.2 - utvide aldersfordeling */}
                            {fetchAnswerByIds(
                                'ef82e3ae-1e04-48ef-bb6a-ca8f10c8ddb2',
                                '888b2c-518d-6016-1d8d-ddf55cc301c',
                            )}
                        </View>
                        <Text style={[styles.p, { marginTop: '10px' }]}>
                            {/* 4.2.2 - utvide aldersfordeling beskrivelse */}
                            {fetchAnswerByIds(
                                'ef82e3ae-1e04-48ef-bb6a-ca8f10c8ddb2',
                                'aceb2a-6a05-61c0-546d-60de8cdbe61',
                            )}
                        </Text>
                        <Text style={styles.h3}>Andre tiltak iverksatt for økt mangfold i arbeidsstyrken.</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '15%' }]}>
                                    <Text>Huket av</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '85%' }]}>
                                    <Text>Tiltak</Text>
                                </View>
                            </View>
                            {/* 4.2.3 - økt mangfold */}
                            {fetchAnswerByIds(
                                '5cc1029c-920f-45b0-90d3-9e2143115abf',
                                'f6dc87a-850-353c-a4f4-ed21451c60e',
                            )}
                        </View>
                        <Text style={[styles.p, { marginTop: '10px' }]}>
                            {/* 4.2.3 - økt mangfold beskrivelse */}
                            {fetchAnswerByIds(
                                '5cc1029c-920f-45b0-90d3-9e2143115abf',
                                'ff1058b-10e0-1386-10c-b84357c785d',
                            )}
                        </Text>
                        <Text style={styles.h1}>Lønn og likestilling</Text>
                        <Text style={styles.h3}>Antall fulltidsansatte fordelt per kjønn</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '33%' }]}>
                                    <Text>Menn</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '34%' }]}>
                                    <Text>Kvinner</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '33%' }]}>
                                    <Text>Annet</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell1, { width: '33%' }]}>
                                    <Text>
                                        {/* 4.1.1 - menn */}
                                        {fetchAnswerByIds(
                                            '1a8d4329-2024-4f09-a3e9-5be277e64c81',
                                            'f85527-341-741b-34f1-58c4b475b2d8',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '34%' }]}>
                                    <Text>
                                        {/* 4.1.1 - kvinner */}
                                        {fetchAnswerByIds(
                                            '1a8d4329-2024-4f09-a3e9-5be277e64c81',
                                            '761581-c83e-cff5-b5dd-2a3554d6f6ed',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell2, { width: '33%' }]}>
                                    <Text>
                                        {/* 4.1.1 - annet */}
                                        {fetchAnswerByIds(
                                            '1a8d4329-2024-4f09-a3e9-5be277e64c81',
                                            'd507432-63f1-a03-7e06-b3146034348',
                                        )}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.h3}>Tiltak som er iverksatt for å bedre likestilling</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '15%' }]}>
                                    <Text>Huket av</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '85%' }]}>
                                    <Text>Tiltak</Text>
                                </View>
                            </View>
                            {/* 4.1.3 - bedre likestilling */}
                            {fetchAnswerByIds(
                                '5f0207cf-9a2f-43de-9585-44d1f4e1e9b5',
                                'c4a0dd1-20a-ec28-33cc-567331facfdc',
                            )}
                        </View>
                        <Text style={[styles.p, { marginTop: '10px' }]}>
                            {/* 4.1.3 - bedre likestilling beskrivelse */}
                            {fetchAnswerByIds(
                                '5f0207cf-9a2f-43de-9585-44d1f4e1e9b5',
                                '55a7cff-4841-b141-0e7-bdcafb6eb2b6',
                            )}
                        </Text>
                        <Text style={styles.h3}>Forhold mellom grunnlønn for kvinner og menn.</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text style={{ color: '#fff' }}>.</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '26%' }]}>
                                    <Text>Kvinner</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '26%' }]}>
                                    <Text>Menn</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '27%' }]}>
                                    <Text>Differansen</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text>Ledere</Text>
                                </View>
                                <View style={[styles.cell1, { width: '26%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {fetchAnswerByIds(
                                            'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                            'c65fb4d-17c4-87ea-e687-3a80eba8867',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '26%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {fetchAnswerByIds(
                                            'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                            '47852e2-41ac-8452-5a65-e16cc126e0da',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell2, { width: '27%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {Math.abs(
                                            fetchAnswerByIds(
                                                'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                                'c65fb4d-17c4-87ea-e687-3a80eba8867',
                                            ) -
                                                fetchAnswerByIds(
                                                    'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                                    '47852e2-41ac-8452-5a65-e16cc126e0da',
                                                ),
                                        ) || 'N/A'}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text>Mellom ledere</Text>
                                </View>
                                <View style={[styles.cell1, { width: '26%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {fetchAnswerByIds(
                                            'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                            '411ebe-4c70-027b-f862-4673cb30f33',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '26%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {fetchAnswerByIds(
                                            'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                            '5bebc71-500e-0e-774d-d36ea1c2a3aa',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell2, { width: '27%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {Math.abs(
                                            fetchAnswerByIds(
                                                'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                                '411ebe-4c70-027b-f862-4673cb30f33',
                                            ) -
                                                fetchAnswerByIds(
                                                    'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                                    '5bebc71-500e-0e-774d-d36ea1c2a3aa',
                                                ),
                                        ) || 'N/A'}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text>Erfarne ansatte</Text>
                                </View>
                                <View style={[styles.cell1, { width: '26%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {fetchAnswerByIds(
                                            'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                            '3571a20-6fd4-712-647b-ae5a163eedcc',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '26%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {fetchAnswerByIds(
                                            'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                            '34ae4bb-1c20-1812-ea11-e5657a3',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell2, { width: '27%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {Math.abs(
                                            fetchAnswerByIds(
                                                'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                                '3571a20-6fd4-712-647b-ae5a163eedcc',
                                            ) -
                                                fetchAnswerByIds(
                                                    'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                                    '34ae4bb-1c20-1812-ea11-e5657a3',
                                                ),
                                        ) || 'N/A'}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text>Junior ansatte</Text>
                                </View>
                                <View style={[styles.cell1, { width: '26%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {fetchAnswerByIds(
                                            'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                            '5ac5af3-8c1c-26cd-0ece-f01c2f0ae2bf',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '26%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {fetchAnswerByIds(
                                            'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                            'ca5a5ea-badf-8ac8-aaf2-3b0588f687dc',
                                        )}
                                    </Text>
                                </View>
                                <View style={[styles.cell2, { width: '27%' }]}>
                                    <Text>
                                        {/* 4.1.2 - grunnlønn forhold */}
                                        {Math.abs(
                                            fetchAnswerByIds(
                                                'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                                '5ac5af3-8c1c-26cd-0ece-f01c2f0ae2bf',
                                            ) -
                                                fetchAnswerByIds(
                                                    'b9bfd839-0238-4b9d-9180-70d80c343e85',
                                                    'ca5a5ea-badf-8ac8-aaf2-3b0588f687dc',
                                                ),
                                        ) || 'N/A'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.h1}>Sykefravær</Text>
                        <Text style={styles.h3}>Prosent i rapporteringsperioden</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '33%' }]}>
                                    <Text>Arbeidsrelatert</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '34%' }]}>
                                    <Text>Ikke arbeidsrelatert</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '33%' }]}>
                                    <Text>Totalt</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell1, { width: '33%' }]}>
                                    <Text>
                                        {/* 4.3.1 - sykefravær */}
                                        {fetchAnswerByIds(
                                            'a97d06df-fec1-410d-abf2-eeeebab89d43',
                                            '0fbb47f-c70b-8f0a-53b-8af24671a861',
                                        )}
                                        %
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '34%' }]}>
                                    <Text>
                                        {/* 4.3.1 - sykefravær */}
                                        {fetchAnswerByIds(
                                            'a97d06df-fec1-410d-abf2-eeeebab89d43',
                                            '6a32a45-bc70-1ce0-afd7-3ecdeaae05a5',
                                        )}
                                        %
                                    </Text>
                                </View>
                                <View style={[styles.cell2, { width: '33%' }]}>
                                    <Text>
                                        {/* 4.3.1 - sykefravær */}
                                        {fetchAnswerByIds(
                                            'a97d06df-fec1-410d-abf2-eeeebab89d43',
                                            'b2d85f-376c-7ed7-381e-4e357d3abeff',
                                        )}
                                        %
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.h1}>Opplæring og utdanning</Text>
                        <View style={styles.table}>
                            <View style={styles.row}>
                                <View style={styles.cell1}>
                                    <Text>Hvor mange ansatte har deltatt på HMS-kurs de siste 3 år?</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text>
                                        {/* 4.5.2 - HMS kurs */}
                                        {fetchAnswerByIds(
                                            'de277460-95c5-4a6f-8ba9-9708ac95f953',
                                            'ab5abe7-74d-1c51-5ad-ba664df6e254',
                                        )}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={styles.cell1}>
                                    <Text>Antall timer ansattopplæring av ansatte for rapporteringsperioden</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text>
                                        {/* 4.5.1 - antall timer opplæring */}
                                        {fetchAnswerByIds(
                                            '67f5bb35-0043-4a72-9043-ae23616bbedf',
                                            'cb81c-60ea-515b-0b41-6dcd1d70b1f',
                                        )}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.cell1}>
                                    <Text>Antall timer videreutdanning av ansatte for rapporteringsperioden</Text>
                                </View>
                                <View style={styles.cell2}>
                                    <Text>
                                        {/* 4.5.1 - antall timer videreutdanning */}
                                        {fetchAnswerByIds(
                                            '67f5bb35-0043-4a72-9043-ae23616bbedf',
                                            'c3244e8-4ff1-fa2c-0a8-231dd605b0',
                                        )}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.h1}>Attraktive villkår for ansatte</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '15%' }]}>
                                    <Text>Huket av</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '85%' }]}>
                                    <Text>Tiltak</Text>
                                </View>
                            </View>
                            {/* 4.4.1 - attraktive vilkår */}
                            {fetchAnswerByIds(
                                '5949d11d-4fff-43db-9c66-2f937d5c6830',
                                '25ef2-bb86-7bf3-e5af-6232e70e17b',
                            )}
                        </View>
                        <Text style={[styles.p, { marginTop: '10px' }]}>
                            {/* 4.4.1 - attraktive beskrivelse */}
                            {fetchAnswerByIds(
                                '5949d11d-4fff-43db-9c66-2f937d5c6830',
                                'a82c2d6-ae5c-b244-7523-6247aaa5e1',
                            )}
                        </Text>
                    </View>
                    {/* Klimaeffekter */}
                    <View>
                        <Text style={styles.mainTitle}>Miljøfaktorer</Text>
                        <Text style={styles.h3}>Vesentlig emner (fokusområder) dekket i denne rapporten</Text>
                        <View style={[styles.table, { marginBottom: '20px' }]}>
                            <View style={styles.tableHead}>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text>Materialer</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text>Avfall</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text>Energi</Text>
                                </View>
                                <View style={[styles.headCell1, { width: '20%' }]}>
                                    <Text>Klimagassutslipp</Text>
                                </View>
                                <View style={[styles.headCell2, { width: '20%' }]}>
                                    <Text>EU-taksomonomi</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text>
                                        {checkClimateCatAnswer('07c662-7c2-2bd7-5d1-a40ad43f58f3') ? 'Ja' : 'Nei'}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text>
                                        {checkClimateCatAnswer('341c5f5-151a-176e-d05f-21640dfbdbb') ? 'Ja' : 'Nei'}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text>
                                        {checkClimateCatAnswer('7a087a-6ea5-2d0f-650d-473cf7bca1') ? 'Ja' : 'Nei'}
                                    </Text>
                                </View>
                                <View style={[styles.cell1, { width: '20%' }]}>
                                    <Text>
                                        {checkClimateCatAnswer('30ac6d0-1c3e-077b-a38-c1f0e4083e82') ? 'Ja' : 'Nei'}
                                    </Text>
                                </View>
                                <View style={[styles.cell2, { width: '20%' }]}>
                                    <Text>
                                        {checkClimateCatAnswer('28de7d1-aa77-616b-0e63-d6c58c01fc44') ? 'Ja' : 'Nei'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {checkClimateCatAnswer('07c662-7c2-2bd7-5d1-a40ad43f58f3') ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Fokusområde 1: Materialer</Text>
                                        <Text style={styles.cellText}>
                                            Materialer er råstoffene - dvs. treverk, plast, metall, glass eller tøystoff
                                            - som et produkt er laget av. Ressursutvinning for materialbruk er ansvarlig
                                            for halvparten av verdens karbonutslipp.
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={[styles.MainCell, { backgroundColor: '#ffffff' }]}>
                                        <View style={[styles.cell, { width: '50%' }]}>
                                            <Text style={[styles.cellText, { color: '#ffffff' }]}>.</Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '35%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>Kategori</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '30%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>Vekt</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '35%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={[styles.cell, { borderRight: '0px' }]}>
                                                    <Text style={styles.cellText}>Mål</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Total vekt eller volum av materialer som brukes til å produsere og pakke
                                                organisasjonens primære produkter og tjenester i løpet av
                                                rapporteringsperioden, herav:
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.cellText,
                                                    {
                                                        marginTop: '10px',
                                                        textAlign: 'left',
                                                    },
                                                ]}
                                            >
                                                i. ikke-fornybare materialer som brukes
                                            </Text>
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                ii. fornybare materialer som brukes
                                            </Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '60px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Ikke-fornybare materialer</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '60px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Fornybare materialer</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '30%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '60px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.1 - ikke fornybar tonn */}
                                                        {fetchAnswerByIds(
                                                            '74080137-e40c-4993-a0b0-436ea5aa36f9',
                                                            '777a327-54-7b4a-c6df-085c0ee07',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View style={[styles.cell, { height: '60px' }]}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.1 - fornybar tonn */}
                                                        {fetchAnswerByIds(
                                                            '74080137-e40c-4993-a0b0-436ea5aa36f9',
                                                            '5ef55b-1cb-1f61-482e-72725182d05c',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '60px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Tonn</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '60px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Tonn</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={[styles.MainCell, {}]}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Total vekt eller volum av materialer</Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Total vekt</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '30%' }]}>
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.1 - total forbruk tonn */}
                                                        {fetchAnswerByIds(
                                                            '74080137-e40c-4993-a0b0-436ea5aa36f9',
                                                            'c821fc-51b-02c7-bb78-075e3c1d36',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View style={[styles.cell, { borderRight: '0px' }]}>
                                                    <Text style={styles.cellText}>Tonn</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Prosent av fornybare og ikke-fornybare materialer som brukes til å
                                                produsere organisasjonens primære produkter og tjenester.
                                            </Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '47px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Ikke-fornybare materialer</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '47px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Fornybare materialer</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '30%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.1 - ikke fornybar prosent */}
                                                        {fetchAnswerByIds(
                                                            '74080137-e40c-4993-a0b0-436ea5aa36f9',
                                                            '5d6882-0d80-c277-6f56-5cfaa52648e8',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View style={[styles.cell, { height: '47px' }]}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.1 - fornybar prosent */}
                                                        {fetchAnswerByIds(
                                                            '74080137-e40c-4993-a0b0-436ea5aa36f9',
                                                            '2ffaee-4d35-7dad-f65c-42e0fc425e38',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Prosent</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Prosent</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Legg til andel resirkulerte materialer (%) og andel ikke resirkulerte
                                                materialer (%) som brukes til å produsere bedriftens primærprodukter og
                                                -tjenester. Identifiser resirkulert materialer brukt og jomfruelige
                                                materialer (nye råvarer/materialer) brukt.
                                            </Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '65%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        Andel resirkulerte materialer i primærprodukter/-tjenester (%)
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        Andel ikke-resirkulerte materialer i primærprodukter/-tjenester
                                                        (%)
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Råvarer i %</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Halvfabrikat og deler i %</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        Tilknyttede prosessmaterialer i %
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Materialer for emballasje i %</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '35%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '43.5px',
                                                            borderRight: '0px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.2 - andel resirkulert */}
                                                        {fetchAnswerByIds(
                                                            'cb7df75b-4e01-4f1c-9b76-3820d39ba2b6',
                                                            'cda0b-aa5f-6e2d-a130-dc60fbe5d5a6',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '43.5px',
                                                            borderRight: '0px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.2 - andel resirkulert */}
                                                        {fetchAnswerByIds(
                                                            'cb7df75b-4e01-4f1c-9b76-3820d39ba2b6',
                                                            'ba7e2c7-5cd8-f28-77da-642d53cee3ed',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.2 - andel resirkulert */}
                                                        {fetchAnswerByIds(
                                                            'cb7df75b-4e01-4f1c-9b76-3820d39ba2b6',
                                                            '7de470f-32ea-4c8-0be4-78e2a6beed',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.2 - andel resirkulert */}
                                                        {fetchAnswerByIds(
                                                            'cb7df75b-4e01-4f1c-9b76-3820d39ba2b6',
                                                            'ab3be2d-f10f-33ae-f1b-623428d78813',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.2 - andel resirkulert */}
                                                        {fetchAnswerByIds(
                                                            'cb7df75b-4e01-4f1c-9b76-3820d39ba2b6',
                                                            '85818eb-ae6b-1141-725c-5e57e7145d',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View style={[styles.cell, { borderRight: '0px' }]}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.2.2 - andel resirkulert */}
                                                        {fetchAnswerByIds(
                                                            'cb7df75b-4e01-4f1c-9b76-3820d39ba2b6',
                                                            '57fba48-5537-83be-2f33-6f53842362',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Beskriv metoden som brukes for å hente dataene som er samlet inn
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.2.3 - innsamling metode */}
                                                {fetchAnswerByIds(
                                                    '631594ab-4504-46c8-bcbe-2597fbff8169',
                                                    '7010057-7060-2f-1373-c1afda3c0820',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Beskriv eventuell usikkerhet i forbindelse med dataene som er samlet inn
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.2.4 - usikkerhet */}
                                                {fetchAnswerByIds(
                                                    '259d4a2f-5790-443d-bf02-c13b71c29ea5',
                                                    '2edab4-fe38-cb85-dc1a-f51345bde41',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkClimateCatAnswer('341c5f5-151a-176e-d05f-21640dfbdbb') ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Fokusområde 2: Avfall</Text>
                                        <Text style={styles.cellText}>
                                            Avfall er uønskede eller ubrukelige materialer, vanligvis kastet etter
                                            primær bruk. Eksempler inkluderer matavfall, farlig avfall og avløpsvann.
                                            Matavfall alene er ansvarlig for 6% av totale globale klimagasser
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={[styles.MainCell, { backgroundColor: '#ffffff' }]}>
                                        <View style={[styles.cell, { width: '20%' }]}>
                                            <Text style={[styles.cellText, { color: '#ffffff' }]}>.</Text>
                                        </View>
                                        <View style={[styles.numberCells, { width: '85%' }]}>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '25%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>Avfallstype</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '15%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>Mengde</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '25%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>Opphav</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '35%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={[styles.cell, { borderRight: '0px' }]}>
                                                    <Text style={styles.cellText}>Beskrivelse</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Total vekt av avfall som kastes etter bruk i rapporteringsperioden
                                            </Text>
                                        </View>
                                        {/* 5.3.1 - type avfaller */}
                                        {fetchAnswerByIds(
                                            'fa3ea967-195f-4448-bd84-a7b79097c4cf',
                                            'c5cc76d-22a0-ff41-e37d-31560a0d5d82',
                                        )}
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={[styles.MainCell, {}]}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Total vekt av avfall</Text>
                                        </View>
                                        <View style={[styles.numberCells, { width: '85%' }]}>
                                            <View style={[styles.duoCells, { width: '25%' }]}>
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>Total vekt</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '15%' }]}>
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.3.1 - type avfaller */}
                                                        {fetchAnswerByIds(
                                                            'fa3ea967-195f-4448-bd84-a7b79097c4cf',
                                                            'c5cc76d-22a0-ff41-e37d-31560a0d5d82',
                                                            'total',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '25%' }]}>
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>N/A</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View style={[styles.cell, { borderRight: '0px' }]}>
                                                    <Text style={styles.cellText}>N/A</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Beskriv metoden som brukes for å hente de innsamlede dataene
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.3.2 - avfall innsamling metode */}
                                                {fetchAnswerByIds(
                                                    '67b4418e-33f4-40b6-8ff8-b0b2c00a7c20',
                                                    '5da7102-d04-7dfc-1faa-3e2d4e46f575',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Begrunnelsen for valg av kategorier
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.3.3 - valg av avfallskategorier */}
                                                {fetchAnswerByIds(
                                                    '0472d68b-d194-4553-a8da-2610536ab70f',
                                                    '28781da-360-a2-35b7-df2bdd407222',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Beskriv eventuell usikkerhet i forbindelse med dataene som er samlet inn
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.3.4 - avfall usikkerhet */}
                                                {fetchAnswerByIds(
                                                    '5363d226-99b3-483d-8a41-f2db8c133e54',
                                                    '17cec7a-b215-f3a7-2343-eb2dc5aeed',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkClimateCatAnswer('7a087a-6ea5-2d0f-650d-473cf7bca1') ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Fokusområde 3: Energi</Text>
                                        <Text style={styles.cellText}>
                                            Energiforbruk refererer til all energien som brukes til å utføre en
                                            handling, produsere noe eller bare bo i en bygning. Eksempler inkluderer
                                            fossilt brensel, elektrisitet, vann og gass. Alle typer energiproduksjon
                                            står for 72 prosent av de totale globale utslippene.
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={[styles.MainCell, { backgroundColor: '#ffffff' }]}>
                                        <View style={[styles.cell, { width: '50%' }]}>
                                            <Text style={[styles.cellText, { color: '#ffffff' }]}>.</Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '35%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>Kategori</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '30%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>Forbruk</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '35%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={[styles.cell, { borderRight: '0px' }]}>
                                                    <Text style={styles.cellText}>Mål</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Totalt energiforbruk og/eller produksjon fra fornybare og ikke-fornybare
                                                kilder
                                            </Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Ikke-fornybare kilder</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '47px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Fornybare kilder</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '30%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.4.1 - ikke fornybar energi */}
                                                        {fetchAnswerByIds(
                                                            '6935c053-8e79-4570-abbc-a400f142dea5',
                                                            '813a58b-5b4-e434-a2a7-710d5ea65d7',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View style={[styles.cell, { height: '47px' }]}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.4.1 - fornybar energi */}
                                                        {fetchAnswerByIds(
                                                            '6935c053-8e79-4570-abbc-a400f142dea5',
                                                            'f8d6c44-2c8-135b-b464-be03065af842',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>KWh</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>kWh</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={[styles.MainCell, {}]}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Totalt forbruk energi</Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Totalt forbruk</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '30%' }]}>
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.4.1 - total energi forbruk */}
                                                        {fetchAnswerByIds(
                                                            '6935c053-8e79-4570-abbc-a400f142dea5',
                                                            '65f5b3-177-0337-6ec-633d5831acad',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View style={[styles.cell, { borderRight: '0px' }]}>
                                                    <Text style={styles.cellText}>kWh</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Total bruk av ikke-fornybare og fornybare kilder i prosent.
                                            </Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Ikke-fornybare kilder</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '47px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Fornybare kilder</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '30%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.4.1 - ikke fornybar kilder */}
                                                        {fetchAnswerByIds(
                                                            '6935c053-8e79-4570-abbc-a400f142dea5',
                                                            '3f368b8-816c-c0d7-e81-241c110c41f0',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View style={[styles.cell, { height: '47px' }]}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.4.1 - fornybar kilder */}
                                                        {fetchAnswerByIds(
                                                            '6935c053-8e79-4570-abbc-a400f142dea5',
                                                            '05ce6f6-0d1-78c-d04b-f457ac2c5d5b',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',

                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Prosent</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '47px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Prosent</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Hvor mange kWt energi bedriften produserte selv (forrige regnskapsår).
                                                Legg til både fornybar og ikke-fornybar strøm som ble produsert.
                                            </Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '65%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={[styles.cellText, {}]}>
                                                        kWt egenprodusert fornybar energi
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={[styles.cellText, {}]}>
                                                        kWt egenprodusert ikke-fornybar energi
                                                    </Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '35%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.4.2 - energi */}
                                                        {fetchAnswerByIds(
                                                            '74a8df12-e109-4152-93bd-9284e832750d',
                                                            'ae81d0-d84-f73d-db01-a3f31ba57bdd',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '43px',
                                                            borderRight: '0px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.4.2 - energi */}
                                                        {fetchAnswerByIds(
                                                            '74a8df12-e109-4152-93bd-9284e832750d',
                                                            '2742ee-4aac-fe6e-00b3-cd54d2bdc0fe',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Beskriv metoden som brukes for å hente dataene som er samlet inn
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.4.3 - innsamling metode energi */}
                                                {fetchAnswerByIds(
                                                    '65fc45d0-6cf6-4e2a-93af-de209c946eb5',
                                                    '0c5be-530-6eb6-65b4-d3d52cbbcb',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Beskriv eventuell usikkerhet i forbindelse med dataene som er samlet inn
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.4.4 - usikkerhet energi */}
                                                {fetchAnswerByIds(
                                                    '6d6d93c7-e04c-42c5-a667-94a90338a265',
                                                    'b52b53-4605-c8ee-726-b6b07e52760f',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkClimateCatAnswer('30ac6d0-1c3e-077b-a38-c1f0e4083e82') ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Fokusområde 4: Klimagassutslipp</Text>
                                        <Text style={styles.cellText}>
                                            Klimagasser (GHG) som karbondioksid og metan, er gasser som fanger varme
                                            eller langbølget stråling i atmosfæren. Akkumuleringen av klimagasser siden
                                            den industrielle revolusjonen har akselerert drivhuseffekten, forårsaket
                                            global oppvarming og klimaendringer.
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Scope 1: Direkte klimagassutslipp
                                            </Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                            height: '120px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        Direkte klimagassutslipp fra stasjonære forbrenningskilder som
                                                        eies eller kontrolleres av selskapet
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            alignItems: 'flex-start',
                                                            height: '110px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        Direkte klimagassutslipp fra mobilie forbrenningskilder som eies
                                                        eller kontrolleres av selskapet
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '40px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Totale utslipp Scope 1</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '30%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '120px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.5.1 - forbrenningskilder */}
                                                        {fetchAnswerByIds(
                                                            'e72db7db-3b5c-4c91-bdb2-23b3285d9726',
                                                            '6403da7-4ae-4f30-cda-4f0c3b17173',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '110px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.5.1 - mobilitetskilder */}
                                                        {fetchAnswerByIds(
                                                            'e72db7db-3b5c-4c91-bdb2-23b3285d9726',
                                                            '7ac5c2-0e3-a07b-64e3-04334888a14a',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View style={[styles.cell, { height: '40px' }]}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.5.1 - total scope 1 */}
                                                        {fetchAnswerByIds(
                                                            'e72db7db-3b5c-4c91-bdb2-23b3285d9726',
                                                            '64fd0e-73cf-bbc-a31-5007db51a2a',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',

                                                            height: '120px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>(Klimagassutslipp) kg CO2e</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '110px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>(Klimagassutslipp) kg CO2e</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '40px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>(Klimagassutslipp) kg CO2e</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={[styles.MainCell, {}]}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Scope 2: Indirekte klimagassutslipp fra generering av ervervet og
                                                konsumert elektrisitet, damp, varme eller kjøling (samlet omtalt som
                                                "elektrisitet")
                                            </Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '80px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        Indirekte klimagassutslipp fra forbrukt elektrisitet
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '90px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        Indirekte klimagassutslipp fra forbrukt damp, oppvarming eller
                                                        kjøling
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '40px',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>Totale utslipp Scope 2</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '30%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '80px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.5.2 - indirekte elektrisitet */}
                                                        {fetchAnswerByIds(
                                                            '28ac1290-11b1-4097-bcda-8e8a22ad1a1b',
                                                            '0e305-54c6-f1d-717-b032c7c7ec71',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            height: '90px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>
                                                        {/* 5.5.2 - indirekte damp, vann, kjøling */}
                                                        {fetchAnswerByIds(
                                                            '28ac1290-11b1-4097-bcda-8e8a22ad1a1b',
                                                            'ee85fe-253e-322-bfe7-67f31f6de43',
                                                        )}
                                                    </Text>
                                                </View>
                                                <View style={[styles.cell, { height: '40px' }]}>
                                                    <Text style={styles.cellText}>
                                                        {/* 5.5.2 - total scope 2 */}
                                                        {fetchAnswerByIds(
                                                            '28ac1290-11b1-4097-bcda-8e8a22ad1a1b',
                                                            'ab02e26-dcf6-1602-1c5-7765d24a16fc',
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',

                                                            height: '80px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>(Klimagassutslipp) kg CO2e</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '90px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>(Klimagassutslipp) kg CO2e</Text>
                                                </View>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            borderRight: '0px',
                                                            height: '40px',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={styles.cellText}>(Klimagassutslipp) kg CO2e</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Totalt forbruk fra scope 1 og scope 2
                                            </Text>
                                        </View>
                                        <View style={[styles.numberCells]}>
                                            <View style={[styles.duoCells, { width: '35%' }]}>
                                                <View
                                                    style={[
                                                        styles.cell,
                                                        {
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text style={[styles.cellText, {}]}>Totalt forbruk</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '65%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={[styles.cell, { borderRight: '0px' }]}>
                                                    <Text style={styles.cellText}>
                                                        {/* total scope 1 + 2 */}
                                                        {parseInt(
                                                            fetchAnswerByIds(
                                                                'e72db7db-3b5c-4c91-bdb2-23b3285d9726',
                                                                '64fd0e-73cf-bbc-a31-5007db51a2a',
                                                            ),
                                                        ) +
                                                            parseInt(
                                                                fetchAnswerByIds(
                                                                    '28ac1290-11b1-4097-bcda-8e8a22ad1a1b',
                                                                    'ab02e26-dcf6-1602-1c5-7765d24a16fc',
                                                                ),
                                                            ) || 'N/A'}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Beskriv metoden som brukes for å hente dataene som er samlet inn
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.5.4 - klima innsamling */}
                                                {fetchAnswerByIds(
                                                    '0ea199c1-3d7d-43c4-ac6b-4194845145fe',
                                                    '14320f0-1e8-62a5-3700-e0df28203c5',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Beskriv eventuell usikkerhet i forbindelse med dataene som er samlet inn
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.5.5 - klima usikkerhet */}
                                                {fetchAnswerByIds(
                                                    'c4cac4e9-cce9-41fb-a829-01ea5daf067a',
                                                    '2cc23fc-40a1-5266-be36-afd3326ca1fc',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkClimateCatAnswer('28de7d1-aa77-616b-0e63-d6c58c01fc44') ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Fokusområde 5: EUs Taksonomi</Text>
                                        <Text style={styles.cellText}>
                                            EU Taksonomi er et nytt klassifiseringsverktøy for bærekraftig virksomhet.
                                            Ved å gi et sett med bransjespesifikke tekniske screeningskriterier,
                                            dikterer taksonomien om en bestemt aktivitet er bærekraftig eller ikke.
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Er organisasjonen berørt av EUs Taksonomi?
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>
                                                {/* 5.6.1 - påvirkelse */}
                                                {fetchAnswerByIds(
                                                    '3fee6e5b-7630-4a2e-941c-9edea666f96f',
                                                    'd10ec6-c40-1501-cfdf-47765171f8f7',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={[styles.MainCell, { backgroundColor: '#ffffff' }]}>
                                        <View style={[styles.cell, { width: '40%' }]}>
                                            <Text style={[styles.cellText, { color: '#ffffff' }]}>.</Text>
                                        </View>
                                        <View style={[styles.numberCells, { width: '65%' }]}>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '35%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={styles.cell}>
                                                    <Text style={styles.cellText}>Klassifisering nummer</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[
                                                    styles.duoCells,
                                                    {
                                                        width: '65%',
                                                        backgroundColor: '#ffffff',
                                                    },
                                                ]}
                                            >
                                                <View style={[styles.cell, { borderRight: '0px' }]}>
                                                    <Text style={styles.cellText}>Påvirkning</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '40%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Dersom den er påvirket av taksonomien, gi en oversikt over
                                                klassifiseringene som påvirker organisasjonens kjerneaktiviteter
                                            </Text>
                                        </View>
                                        {/* 5.6.2 - klassifiseringer */}
                                        {fetchAnswerByIds(
                                            '7026fb7d-baf8-4c64-b1d3-feb41125f39d',
                                            'c183c42-1ac2-c1d3-2ac-c85bfbe4fdea',
                                        )}
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '50%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                Dersom den er påvirket av taksonomien, gi en skriftlig beskrivelse med
                                                refleksjoner om potensielle implikasjoner EUs taksonomi kan ha på
                                                organisasjonen
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '55%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                                {/* 5.6.3 - påvirkelse desc */}
                                                {fetchAnswerByIds(
                                                    '156f0937-e756-4a31-bc41-d2632aba56cf',
                                                    '7dfdadd-66b-ded-b7cb-f708b501458',
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View></View>
                        )}
                        <Text style={styles.h1}>Reduksjonsmål og forbruksmål</Text>
                        <Text style={styles.h3}>
                            Hvilke mål bedriften satt seg for å redusere eget forbruk og utslipp
                        </Text>
                        <View style={styles.table}>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                backgroundColor: themeColor,
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>Tema A: Materialer</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                alignItems: 'center',
                                                borderRight: '0px',
                                                width: '55%',
                                                backgroundColor: themeColor,
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>Materialer</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>Målet i tall</Text>
                                    </View>
                                    <View style={[styles.numberCells]}>
                                        <View style={[styles.duoCells, { width: '40%' }]}>
                                            <View style={[styles.cell, { borderBottom: '0px' }]}>
                                                <Text style={styles.cellText}>Numerisk verdi</Text>
                                            </View>
                                        </View>
                                        <View
                                            style={[
                                                styles.duoCells,
                                                {
                                                    width: '30%',
                                                    backgroundColor: '#ffffff',
                                                },
                                            ]}
                                        >
                                            <View style={[styles.cell, { borderBottom: '0px' }]}>
                                                <Text style={styles.cellText}>
                                                    {/* 5.2.5 - mål */}
                                                    {fetchAnswerByIds(
                                                        '7403fc71-ffc5-49af-a694-c971b16561d9',
                                                        'ff0b6b5-728-4bf1-3c50-c312cd27cc8e',
                                                    )}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={[styles.duoCells, { width: '30%' }]}>
                                            <View
                                                style={[
                                                    styles.cell,
                                                    {
                                                        borderBottom: '0px',
                                                        borderRight: '0px',
                                                    },
                                                ]}
                                            >
                                                <Text style={styles.cellText}>
                                                    {/* 5.2.5 - målenhet */}
                                                    {fetchAnswerByIds(
                                                        '7403fc71-ffc5-49af-a694-c971b16561d9',
                                                        'c2c6d3c-0a08-285a-45f-ce332fb78ba',
                                                    )}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            En beskrivelse av målet
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.2.5 - beskrivelse*/}
                                            {fetchAnswerByIds(
                                                '7403fc71-ffc5-49af-a694-c971b16561d9',
                                                '126e574-a843-75fe-a2d-d5f80018b427',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            Dato målet ble satt
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.2.5 - dato */}
                                            {fetchAnswerByIds(
                                                '7403fc71-ffc5-49af-a694-c971b16561d9',
                                                '7f8655c-265-2b82-e1d-37ff56bd1f1b',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            Dato målet skal være nådd
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.2.5 - dato */}
                                            {fetchAnswerByIds(
                                                '7403fc71-ffc5-49af-a694-c971b16561d9',
                                                '0c78b5-44-1762-0714-430cf7042aa5',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            En forklaring på hvordan organisasjonen forvalter temaet. En utdypning av
                                            fremdriftsplanen.
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.2.5 - beskrivelse */}
                                            {fetchAnswerByIds(
                                                '7403fc71-ffc5-49af-a694-c971b16561d9',
                                                '377df3c-cbfd-7fa8-c43e-58e671300d8b',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            {/*  */}
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                backgroundColor: themeColor,
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>Tema B: Avfall</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                alignItems: 'center',
                                                borderRight: '0px',
                                                width: '55%',
                                                backgroundColor: themeColor,
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>Avfall</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>Målet i tall</Text>
                                    </View>
                                    <View style={[styles.numberCells]}>
                                        <View style={[styles.duoCells, { width: '40%' }]}>
                                            <View style={[styles.cell, { borderBottom: '0px' }]}>
                                                <Text style={styles.cellText}>Numerisk verdi</Text>
                                            </View>
                                        </View>
                                        <View
                                            style={[
                                                styles.duoCells,
                                                {
                                                    width: '30%',
                                                    backgroundColor: '#ffffff',
                                                },
                                            ]}
                                        >
                                            <View style={[styles.cell, { borderBottom: '0px' }]}>
                                                <Text style={styles.cellText}>
                                                    {/* 5.3.5 - mål */}
                                                    {fetchAnswerByIds(
                                                        '46e9decc-23a8-4094-8a51-639dd9c452ee',
                                                        'bee5822-62c5-6c44-07e3-31a018cfe4e6',
                                                    )}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={[styles.duoCells, { width: '30%' }]}>
                                            <View
                                                style={[
                                                    styles.cell,
                                                    {
                                                        borderBottom: '0px',
                                                        borderRight: '0px',
                                                    },
                                                ]}
                                            >
                                                <Text style={styles.cellText}>
                                                    {/* 5.3.5 - målenhet */}
                                                    {fetchAnswerByIds(
                                                        '46e9decc-23a8-4094-8a51-639dd9c452ee',
                                                        '0ba85b8-e563-c25-3b1d-af887dd3bbf4',
                                                    )}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            En beskrivelse av målet
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.3.5 - beskrivelse */}
                                            {fetchAnswerByIds(
                                                '46e9decc-23a8-4094-8a51-639dd9c452ee',
                                                '20614f7-c35d-1647-6376-328c378a381c',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            Dato målet ble satt
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.3.5 - dato */}
                                            {fetchAnswerByIds(
                                                '46e9decc-23a8-4094-8a51-639dd9c452ee',
                                                'fe00ec-d2d3-b6a-eb7-8bea06ae06',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            Dato målet skal være nådd
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.3.5 - dato */}
                                            {fetchAnswerByIds(
                                                '46e9decc-23a8-4094-8a51-639dd9c452ee',
                                                '70dd83a-8f78-b83c-326e-4f06e3aa5d4',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            En forklaring på hvordan organisasjonen forvalter temaet. En utdypning av
                                            fremdriftsplanen.
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.3.5 - beskrivelse */}
                                            {fetchAnswerByIds(
                                                '46e9decc-23a8-4094-8a51-639dd9c452ee',
                                                '151f3-a570-8745-3027-ed87542f111',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            {/*  */}
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                backgroundColor: themeColor,
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>Tema C: Energi</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                alignItems: 'center',
                                                borderRight: '0px',
                                                width: '55%',
                                                backgroundColor: themeColor,
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>Energi</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>Målet i tall</Text>
                                    </View>
                                    <View style={[styles.numberCells]}>
                                        <View style={[styles.duoCells, { width: '40%' }]}>
                                            <View style={[styles.cell, { borderBottom: '0px' }]}>
                                                <Text style={styles.cellText}>Numerisk verdi</Text>
                                            </View>
                                        </View>
                                        <View
                                            style={[
                                                styles.duoCells,
                                                {
                                                    width: '30%',
                                                    backgroundColor: '#ffffff',
                                                },
                                            ]}
                                        >
                                            <View style={[styles.cell, { borderBottom: '0px' }]}>
                                                <Text style={styles.cellText}>
                                                    {/* 5.4.5 - mål */}
                                                    {fetchAnswerByIds(
                                                        '850d14b3-2ed2-4952-a7cd-29e3275d8997',
                                                        'b884a36-480a-a08d-283-54f1fe67f1',
                                                    )}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={[styles.duoCells, { width: '30%' }]}>
                                            <View
                                                style={[
                                                    styles.cell,
                                                    {
                                                        borderBottom: '0px',
                                                        borderRight: '0px',
                                                    },
                                                ]}
                                            >
                                                <Text style={styles.cellText}>
                                                    {/* 5.4.5 - målenhet */}
                                                    {fetchAnswerByIds(
                                                        '850d14b3-2ed2-4952-a7cd-29e3275d8997',
                                                        'fc67482-774e-b4ae-5318-06b5b02f6fc',
                                                    )}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            En beskrivelse av målet
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.4.5 - beskrivelse */}
                                            {fetchAnswerByIds(
                                                '850d14b3-2ed2-4952-a7cd-29e3275d8997',
                                                '27e5fb1-1aa0-4e48-10f5-1f61eb3ecc85',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            Dato målet ble satt
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.4.5 - dato */}
                                            {fetchAnswerByIds(
                                                '850d14b3-2ed2-4952-a7cd-29e3275d8997',
                                                '8314788-f6c-8102-d8a-34fb45d2fa5',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            Dato målet skal være nådd
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.4.5 - dato */}
                                            {fetchAnswerByIds(
                                                '850d14b3-2ed2-4952-a7cd-29e3275d8997',
                                                '0de81b5-c3f-8e3-8788-63608eca483',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow} wrap={false}>
                                <View style={styles.MainCell}>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                width: '45%',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.cellText, { textAlign: 'left' }]}>
                                            En forklaring på hvordan organisasjonen forvalter temaet. En utdypning av
                                            fremdriftsplanen.
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.cell,
                                            {
                                                backgroundColor: '#ffffff',
                                                alignItems: 'flex-start',
                                                borderRight: '0px',
                                                width: '55%',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.cellText}>
                                            {/* 5.4.5 - beskrivelse */}
                                            {fetchAnswerByIds(
                                                '850d14b3-2ed2-4952-a7cd-29e3275d8997',
                                                '725fb45-0ab8-1ab6-6b4e-344845247c58',
                                            )}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            {/* <View style={styles.tableRow} wrap={false}>
                <View style={styles.MainCell}>
                  <View
                    style={[
                      styles.cell,
                      {
                        width: "45%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        backgroundColor: themeColor,
                      },
                    ]}
                  >
                    <Text style={[styles.cellText, { textAlign: "left" }]}>
                      Tema D: Utslipp i klimagasser
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        alignItems: "center",
                        borderRight: "0px",
                        width: "55%",
                        backgroundColor: themeColor,
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>Utslipp i klimagasser</Text>
                  </View>
                </View>
              </View> */}
                            {/* <View style={styles.tableRow} wrap={false}>
                <View style={styles.MainCell}>
                  <View
                    style={[
                      styles.cell,
                      {
                        width: "45%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      },
                    ]}
                  >
                    <Text style={[styles.cellText, { textAlign: "left" }]}>
                      Målet i tall
                    </Text>
                  </View>
                  <View style={[styles.numberCells]}>
                    <View style={[styles.duoCells, { width: "40%" }]}>
                      <View style={[styles.cell, { borderBottom: "0px" }]}>
                        <Text style={[styles.cellText, { color: "#E3E3E3" }]}>
                          .
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.duoCells,
                        {
                          width: "30%",
                          backgroundColor: "#ffffff",
                        },
                      ]}
                    >
                      <View style={[styles.cell, { borderBottom: "0px" }]}>
                        <Text style={styles.cellText}>
                          
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.duoCells, { width: "30%" }]}>
                      <View
                        style={[
                          styles.cell,
                          {
                            borderBottom: "0px",
                            borderRight: "0px",
                          },
                        ]}
                      >
                        <Text style={[styles.cellText, { color: "#E3E3E3" }]}>
                          .
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View> */}
                            {/*  <View style={styles.tableRow} wrap={false}>
                <View style={styles.MainCell}>
                  <View
                    style={[
                      styles.cell,
                      {
                        width: "45%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      },
                    ]}
                  >
                    <Text style={[styles.cellText, { textAlign: "left" }]}>
                      En beskrivelse av målet
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: "#ffffff",
                        alignItems: "flex-start",
                        borderRight: "0px",
                        width: "55%",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>
                    </Text>
                  </View>
                </View>
              </View> */}
                            {/* <View style={styles.tableRow} wrap={false}>
                <View style={styles.MainCell}>
                  <View
                    style={[
                      styles.cell,
                      {
                        width: "45%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      },
                    ]}
                  >
                    <Text style={[styles.cellText, { textAlign: "left" }]}>
                      En forklaring på hvordan organisasjonen forvalter temaet.
                      En utdypning av fremdriftsplanen.
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: "#ffffff",
                        alignItems: "flex-start",
                        borderRight: "0px",
                        width: "55%",
                      },
                    ]}
                  >
                    <Text style={styles.cellText}>
                    </Text>
                  </View>
                </View>
              </View> */}
                        </View>
                    </View>
                    {/* Risiko */}
                    <View>
                        <Text style={styles.mainTitle}>Risiko og muligheter</Text>
                        <Text style={styles.h1}>Korupsjon og bestikkelser</Text>
                        <Text style={styles.boldP}>Risiko</Text>
                        <Text style={styles.p}>
                            {/* 6.1.2 - risiko */}
                            {fetchAnswerByIds(
                                'd91aa229-732b-4e3a-a7cb-b183121adb79',
                                '3c7532-0668-1daa-a3a-d0db70b41cf',
                            )}
                        </Text>
                        <Text style={styles.boldP}>Beskrivelse av tiltak for å kontrollere risiko</Text>
                        <Text style={styles.p}>
                            {/* 6.1.2 - tiltak for risiko */}
                            {fetchAnswerByIds(
                                'd91aa229-732b-4e3a-a7cb-b183121adb79',
                                'c0f85d-fc2a-347e-0cd7-fb845b6e0fe',
                            )}
                        </Text>
                        <Text style={styles.h1}>Risikovurderinger</Text>
                        {checkForCustomRiskOppAnswer(
                            '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                            'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                            '016ceb1-2f54-ba3f-fc86-fc882f4ef7b8',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Retningslinjer og lover</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Risiko</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '016ceb1-2f54-ba3f-fc86-fc882f4ef7b8',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '016ceb1-2f54-ba3f-fc86-fc882f4ef7b8',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkForCustomRiskOppAnswer(
                            '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                            'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                            '2be3cdc-6dfd-533-81fb-e1e3282caedb',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Teknologi</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Risiko</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '2be3cdc-6dfd-533-81fb-e1e3282caedb',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '2be3cdc-6dfd-533-81fb-e1e3282caedb',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkForCustomRiskOppAnswer(
                            '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                            'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                            '5c43fcd-410-d44-876c-3a2f3a42',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Marked</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Risiko</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '5c43fcd-410-d44-876c-3a2f3a42',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '5c43fcd-410-d44-876c-3a2f3a42',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkForCustomRiskOppAnswer(
                            '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                            'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                            'ca012e-b7dc-fe42-587-5ccd83d03a4d',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Omdømme</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Risiko</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    'ca012e-b7dc-fe42-587-5ccd83d03a4d',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    'ca012e-b7dc-fe42-587-5ccd83d03a4d',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkClimateCatAnswer(
                            '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                            'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                            '66d478d-d1ae-487d-88c0-c2e2280af',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Fysisk akutt risiko (kortsiktig)</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Risiko</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '66d478d-d1ae-487d-88c0-c2e2280af',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '66d478d-d1ae-487d-88c0-c2e2280af',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkClimateCatAnswer(
                            '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                            'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                            '6822b1-6268-2ebf-2b01-7638a6534b',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Fysisk kronisk risiko (langsiktig)</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Risiko</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '6822b1-6268-2ebf-2b01-7638a6534b',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '4e9af43a-d49d-43e2-b2d2-c79f54edccea',
                                    'f167da1-b162-8a3e-1dfb-86ccf8fc0a5',
                                    '6822b1-6268-2ebf-2b01-7638a6534b',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        <Text style={styles.h1}>Muligheter</Text>
                        {checkForCustomRiskOppAnswer(
                            '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                            'ee32be-788-37cd-15df-36c08b6784c2',
                            'ce6bfb0-a7b-05d3-8dde-3dc4d6fd6147',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Ressursoptimalisering</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulighet</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    'ce6bfb0-a7b-05d3-8dde-3dc4d6fd6147',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    'ce6bfb0-a7b-05d3-8dde-3dc4d6fd6147',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkForCustomRiskOppAnswer(
                            '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                            'ee32be-788-37cd-15df-36c08b6784c2',
                            'b6d10f4-fabb-a4e-eaa3-f6166628aee',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Energiressurser</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulighet</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    'b6d10f4-fabb-a4e-eaa3-f6166628aee',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    'b6d10f4-fabb-a4e-eaa3-f6166628aee',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkForCustomRiskOppAnswer(
                            '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                            'ee32be-788-37cd-15df-36c08b6784c2',
                            'af22d5-e313-100b-f620-3aacb0e04f6',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Produkter og tjenester</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulighet</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    'af22d5-e313-100b-f620-3aacb0e04f6',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    'af22d5-e313-100b-f620-3aacb0e04f6',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {getCustomRiskOppAnswers(
                            '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                            'ee32be-788-37cd-15df-36c08b6784c2',
                            '387082-e4f0-6f-b8-bb8ddc6c8d',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Marked</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulighet</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    '387082-e4f0-6f-b8-bb8ddc6c8d',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    '387082-e4f0-6f-b8-bb8ddc6c8d',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {checkForCustomRiskOppAnswer(
                            '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                            'ee32be-788-37cd-15df-36c08b6784c2',
                            '33de6e-87a-68-efea-8e5db06fd3bb',
                        ) ? (
                            <View style={styles.table}>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.HeaderCell}>
                                        <Text style={styles.cellTitle}>Motstandsdyktighet</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.MainCell}>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulighet</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Mulig finansiell påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Kunnskapsnivå</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Relevans</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    width: '12%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Påvirkning</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cell,
                                                {
                                                    backgroundColor: '#ffffff',
                                                    width: '40%',
                                                    alignItems: 'flex-start',
                                                    borderRight: '0px',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.cellText}>Beskrivelse</Text>
                                        </View>
                                    </View>
                                </View>
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    '33de6e-87a-68-efea-8e5db06fd3bb',
                                    'Shortterm',
                                )}
                                {getCustomRiskOppAnswers(
                                    '7c65ef1e-f384-4db8-8143-2cebba6ccbf2',
                                    'ee32be-788-37cd-15df-36c08b6784c2',
                                    '33de6e-87a-68-efea-8e5db06fd3bb',
                                    'Longterm',
                                )}
                            </View>
                        ) : (
                            <View></View>
                        )}
                    </View>
                    {/* Andre bærekraftsinitiativer */}
                    <View>
                        <Text style={styles.mainTitle}>Bærekraftsinitiativer</Text>
                        <Text style={styles.p}>
                            Beskrivelser av andre bærekraftsinitiativer som bedriften jobber med eller som bedriften har
                            planlagt
                        </Text>
                        <Text style={styles.h1}>Miljø</Text>
                        <Text style={styles.p}>
                            {/* 6.3.1 - bærekraftsinitiativ */}
                            {fetchAnswerByIds(
                                '2c8f95b8-f818-4eaf-91ac-024fbcf8747b',
                                '17c5851-e524-cda-d8b0-a1efc862bc',
                            )}
                        </Text>
                        <Text style={styles.h1}>Sosiale forhold</Text>
                        <Text style={styles.p}>
                            {/* 6.3.1 - bærekraftsinitiativ */}
                            {fetchAnswerByIds(
                                '2c8f95b8-f818-4eaf-91ac-024fbcf8747b',
                                '2572b-3b41-76e0-01f-52746c1dacd',
                            )}
                        </Text>
                        <Text style={styles.h1}>Økonomiske forhold/drift</Text>
                        <Text style={styles.p}>
                            {/* 6.3.1 - bærekraftsinitiativ */}
                            {fetchAnswerByIds(
                                '2c8f95b8-f818-4eaf-91ac-024fbcf8747b',
                                'd01c156-06dd-42c-54a-11c10833c7f',
                            )}
                        </Text>
                    </View>
                    {/* Bærekraftsmål */}
                    <View>
                        <Text style={styles.mainTitle}>Bærekraftsmål</Text>
                        <Text style={styles.h1}>Bærekraftsmål som er blitt berørt i denne standarden</Text>
                        <View style={styles.table}>
                            <View style={styles.row}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Ren energi for alle - FNs SDG 7.2</Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Anstendig arbeid og økonomisk vekst - FNs bærekraftsmål 8.4</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Anstendig arbeid og økonomisk vekst - FNs bærekraftsmål 8.4.1</Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Anstendig arbeid og økonomisk vekst - FNs bærekraftsmål 8.4.2</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Ansvarlig forbruk og produksjon - FNs bærekraftsmål 12.2</Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Ansvarlig forbruk og produksjon - FNs bærekraftsmål 12.5</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Ansvarlig forbruk og produksjon - FNs bærekraftsmål 12.6</Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Ansvarlig forbruk og produksjon - FNs bærekraftsmål 12.7</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Klimatiltak - FNs bærekraftsmål 13.1</Text>
                                </View>
                            </View>
                            <View style={styles.row2}>
                                <View style={[styles.cell2, { width: '100%' }]}>
                                    <Text>Klimatiltak - FNs bærekraftsmål 13.3</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View fixed={true} style={{ flexGrow: 1 }}></View>
                <View
                    fixed={true}
                    style={[
                        styles.footer,
                        {
                            position: 'fixed',
                            bottom: '0px',
                            left: '0px',
                        },
                    ]}
                >
                    <Text
                        style={[styles.pageNb, { marginRight: '-20px' }]}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={[styles.toppBar, { marginBottom: '0px' }]} />
                <Image src={Grey2} />
                <View style={styles.content}>
                    <Text style={[styles.h1, { marginBottom: '20px' }]}>Signatur</Text>
                    <View style={styles.columns}>
                        <View style={styles.signatureContainer}>
                            <Text style={[styles.boldP, { marginBottom: '80px' }]}>
                                Dato:{' '}
                                {fetchAnswerByIds(
                                    '2c94a1bd-5e8f-44a7-80fc-22ade3e41bf0',
                                    'e7c7cc3-d673-6fc-6cf8-de0ddf12a37',
                                )}
                            </Text>
                            <Svg height="10" width="250">
                                <Line x1="0" y1="0" x2="230" y2="0" strokeWidth={5} stroke={themeColor} />
                            </Svg>
                            <Svg height="10" width="250">
                                <Line x1="0" y1="0" x2="230" y2="0" strokeWidth={3} stroke="#EBEBEB" />
                            </Svg>
                            <Text style={[styles.boldP, { fontSize: '14px' }]}>
                                {/* 1.1.3 - signatur */}
                                {fetchAnswerByIds(
                                    '2c94a1bd-5e8f-44a7-80fc-22ade3e41bf0',
                                    'dd2f0a0-7c5-4f67-3e73-8d81647ce27',
                                )}
                                ,{' '}
                                {fetchAnswerByIds(
                                    '2c94a1bd-5e8f-44a7-80fc-22ade3e41bf0',
                                    '87413f3-aff-a2d8-ffb-6d1bccc5c67',
                                )}
                            </Text>
                            <Text style={styles.boldP}>
                                {fetchAnswerByIds(
                                    '2c94a1bd-5e8f-44a7-80fc-22ade3e41bf0',
                                    '7431c-c6a6-a85a-2fbb-15c0286a30e',
                                )}
                            </Text>
                            <Text style={styles.boldP}>
                                +47{' '}
                                {fetchAnswerByIds(
                                    '2c94a1bd-5e8f-44a7-80fc-22ade3e41bf0',
                                    'eb6b2cc-b40b-f72f-ecbf-be4245e16',
                                )}
                            </Text>
                        </View>
                    </View>
                </View>
                <View fixed={true} style={{ flexGrow: 1 }}></View>
                <View
                    fixed={true}
                    style={[
                        styles.footer,
                        {
                            margin: '',
                            position: 'fixed',
                            bottom: '0px',
                            left: '0px',
                        },
                    ]}
                >
                    <Text
                        style={[styles.pageNb, { marginRight: '-20px' }]}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );
};

export default function ModernPDF(props) {
    return (
        <PDFViewer style={{ height: '730px', width: '100%' }}>
            <ModernLayout report={props.report} nsrsData={props.nsrsData} />
        </PDFViewer>
    );
}
