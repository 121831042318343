import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  Button,
  Box,
  Collapse,
  Divider,
  TextField,
} from "@mui/material";
import { useStyles } from "../../ClimateStyles";
import { ArrowRight } from "../../../../../components/Icons";
import * as Text from "../../../../../helpers/climateAccounting.json";
import useRouter from "../../../../../hooks/useRouter";
import { updateClimateReport } from "../../climateFunctions";

export default function Statement(props) {
  const {
    updateReport,
    CollapseColor,
    CheckedColor,
    IconChange,
    index,
    open,
    changeOpen,
  } = props;
  const classes = useStyles();
  const router = useRouter();

  const [statement, setStatement] = useState("");
  const [isAnswered, setIsAnswered] = useState(false);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      fetchStatement();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      if (statement.length > 0) {
        setIsAnswered(true);
      } else {
        setIsAnswered(false);
      }
    }

    return () => {
      isMounted = true;
    };
  }, [statement]);

  const fetchStatement = () => {
    if (props.report && props.report.infoData) {
      let data = JSON.parse(props.report.infoData);

      if (data.statement) {
        setStatement(data.statement);

        if (data.statement.length !== 0) {
          setIsAnswered(true);
        }
      }
    }
  };

  const saveData = async () => {
    let data = null;

    if (props.report && props.report.infoData) {
      data = JSON.parse(props.report.infoData);

      data.statement = statement;
    } else {
      data = {
        statement: statement,
      };
    }

    const input = {
      id: props.report.id,
      infoData: JSON.stringify(data),
    };

    await updateClimateReport(input).then(async (data) => {
      router.push({
        state: {
          report: data,
        },
      });

      await updateReport();
    });
  };

  const handleSave = async () => {
    await saveData();

    changeOpen(index, false);
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.question}
        sx={{
          background: CollapseColor(isAnswered, open),
        }}
        onClick={() => changeOpen(index, !open)}
      >
        <TableCell
          className={classes.iconCell}
          style={{
            backgroundColor: CheckedColor(isAnswered),
            width: "7%",
          }}
          align="center"
        >
          {IconChange(isAnswered)}
        </TableCell>
        <TableCell component="th" scope="row" className={classes.questionRow}>
          <Typography variant="h3">Uttalelse om klimaregnskapet</Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: "20px 20px 0px 20px" }}>
              <Typography variant="body1">
                {Text.KeyInformation.Statement}
              </Typography>
              <Divider className={classes.divider} />
              <Grid item xs={12} sx={{ display: "flex" }}>
                <TextField
                  multiline
                  rows={5}
                  placeholder="Skriv uttalelse her..."
                  name="statement"
                  style={{ width: "100%" }}
                  value={statement}
                  onChange={(e) => setStatement(e.target.value)}
                />
              </Grid>
              <Grid container direction="row" className={classes.content}>
                <Button
                  disabled={false}
                  variant="contained"
                  onClick={handleSave}
                >
                  Lagre{ArrowRight}
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "30px" }}></Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: "20px" }} />
    </React.Fragment>
  );
}
