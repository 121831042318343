import React from 'react';
import { TableContainer, Table, TableBody } from '@mui/material';
import Question from './Question';

export default function QuestionList({
    responses,
    data,
    catOrder,
    subOrder,
    updateReport,
    reportIsCompleted,
    openStates,
    handleOpenQuestion,
    handleOpenNextQuestion,
}) {
    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableBody>
                    {data &&
                        data
                            .sort((a, b) => a.order - b.order)
                            .map((question, index) => {
                                return (
                                    <Question
                                        key={question.id}
                                        data={question}
                                        response={responses.find(response => {
                                            return response.questionId === question.id;
                                        })}
                                        catOrder={catOrder}
                                        subOrder={subOrder}
                                        index={index}
                                        updateReport={updateReport}
                                        reportIsCompleted={reportIsCompleted}
                                        openState={
                                            openStates &&
                                            openStates.find(state => {
                                                return state.id === question.id;
                                            })
                                        }
                                        handleOpenQuestion={handleOpenQuestion}
                                        handleOpenNextQuestion={handleOpenNextQuestion}
                                    />
                                );
                            })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
