import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { useAuth } from '../../auth/useAuth';
import { SiteLoading } from '../Loading';
import {
    Login,
    Confirm,
    ForgotPassword,
    Dashboard,
    Certification,
    Profile,
    Admin,
    Questionaire,
    CertificationIndex,
    Details,
    Register,
    Documents,
    MediaKit,
    Terms,
    ContactUs,
    EmailConfirmed,
    NewCustomer,
    Report,
    Climate,
    ClimateScopes,
    NewSession,
    Test,
    Chores,
    ChoreDetail,
    NSRS,
    NSRSOverview,
    NSRSForm,
    Templates,
} from '../../pages/index';
import PrivateView from '../private/PrivateView';
import PublicLoginView from '../public/PublicLoginView';
import PublicRegisterView from '../public/PublicRegisterView';

function Router() {
    const auth = useAuth();

    if (auth.isAuthenticated === null) {
        return <SiteLoading color="primary" />;
    } else {
        return (
            <BrowserRouter>
                <Switch>
                    {/* Public routes (Unauth) */}
                    <PublicRoute exact path="/login" auth={auth}>
                        <PublicLoginView>
                            <Login />
                        </PublicLoginView>
                    </PublicRoute>
                    <PublicRoute exact path="/forgot" auth={auth}>
                        <PublicLoginView>
                            <ForgotPassword />
                        </PublicLoginView>
                    </PublicRoute>
                    <PublicRoute exact path="/emailconfirmed" auth={auth}>
                        <PublicLoginView>
                            <EmailConfirmed />
                        </PublicLoginView>
                    </PublicRoute>
                    <PublicRoute exact path="/register" auth={auth}>
                        <PublicRegisterView>
                            <Register />
                        </PublicRegisterView>
                    </PublicRoute>
                    <PublicRoute exact path="/confirm" auth={auth}>
                        <PublicRegisterView>
                            <Confirm />
                        </PublicRegisterView>
                    </PublicRoute>
                    {/* Private routes (Auth) */}
                    <PrivateRoute exact path="/" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Dashboard />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/test" auth={auth} betaOnly={true}>
                        <PrivateView>
                            <Test />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/profile" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Profile />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Admin />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/declaration" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Certification />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/declaration/:id" auth={auth} betaOnly={false}>
                        <Questionaire />
                    </PrivateRoute>
                    <PrivateRoute exact path="/climate" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Climate />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/climate/:id" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <NewSession />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/climate/:id/information" auth={auth} betaOnly={false}>
                        <ClimateScopes form={'keyInfo'} />
                    </PrivateRoute>
                    <PrivateRoute exact path="/climate/:id/scope1" auth={auth} betaOnly={false}>
                        <ClimateScopes form={'scope1'} />
                    </PrivateRoute>
                    <PrivateRoute exact path="/climate/:id/scope2" auth={auth} betaOnly={false}>
                        <ClimateScopes form={'scope2'} />
                    </PrivateRoute>
                    <PrivateRoute exact path="/climate/:id/scope3" auth={auth} betaOnly={false}>
                        <ClimateScopes form={'scope3'} />
                    </PrivateRoute>
                    <PrivateRoute exact path="/documents" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Documents state={'overview'} />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/documents/detail" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Documents state={'detail'} />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/documents/overview" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Documents state={'overview'} />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/documents/climate" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Documents state={'climate'} />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/templates" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Templates />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/documents/NSRS" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Documents state={'NSRS'} />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/mediakit" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <MediaKit />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/contact" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <ContactUs />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/subscribe" auth={auth} betaOnly={false}>
                        <PublicLoginView>
                            <Terms view={'payment'} />
                        </PublicLoginView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/newcustomer" auth={auth} betaOnly={false}>
                        <PublicLoginView>
                            <NewCustomer />
                        </PublicLoginView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/activities" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <Chores />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/activities/:id" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <ChoreDetail />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/nsrs" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <NSRS />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/nsrs/:id" auth={auth} betaOnly={false}>
                        <PrivateView>
                            <NSRSOverview />
                        </PrivateView>
                    </PrivateRoute>
                    <PrivateRoute exact path="/nsrs/:id/:formId" auth={auth} betaOnly={false}>
                        <NSRSForm />
                    </PrivateRoute>
                    {/* Default routes (Accessible to all) */}
                    <Route exact path="/index">
                        <CertificationIndex />
                    </Route>
                    <Route exact path="/index/report">
                        <Report />
                    </Route>
                    <Route exact path="/index/:id">
                        <Details />
                    </Route>
                    {/* 404 */}
                    <Route exact path="*">
                        <div>No page found.</div>
                    </Route>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Router;
