import React, { useState } from 'react';
import { Grid, Button, Select, MenuItem } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { NumberInput } from '../../../../../components/InputForm';
import Subcategory from './Subcategory';

import { API, graphqlOperation } from 'aws-amplify';
import { createNSRSSubcategory } from '../../../../../graphql/mutations';

export default function SubcategoryList(props) {
    const { showPopup, updateCategories, categories } = props;

    const [open, setOpen] = useToggle();

    const [selectedCat, setSelectedCat] = useState('information');

    const [name, setName] = useState('');
    const [order, setOrder] = useState(1);

    const saveNewSubCat = async () => {
        return new Promise(async (resolve, reject) => {
            // Input data for creating a new CompanyData object
            let inputData = {
                categoryId: selectedCat,
                name: name,
                order: order,
            };

            await API.graphql(graphqlOperation(createNSRSSubcategory, { input: inputData }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new subcategory: ', data.data.createNSRSSubcategory);
                    }

                    resolve(data.data.createNSRSSubcategory);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Failed to create new subcategory: ', err);
                    }

                    reject(err);
                });
        });
    };

    const handleSaveNewSubCat = async () => {
        if (name === '') {
            showPopup('Alle felter må være fylt ut!');
            return;
        }

        await saveNewSubCat().catch(() => {
            showPopup('En feil oppsto ved lagring av underkategori');
            return;
        });

        showPopup('Underkategorien har blitt lagret');

        await updateCategories();

        handleOpenDialog();
    };

    const handleOpenDialog = () => {
        setName('');
        setOrder(1);

        setOpen();
    };

    const newSubCatDialog = () => {
        return (
            <Grid>
                <Dialog open={open} onClose={handleOpenDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Lag ny underkategori</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Lag en helt ny underkategori.
                        </DialogContentText>
                        <InputForm
                            label="Navn"
                            placeholder="Skriv inn navn..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <NumberInput
                            label="Listenummer (blir brukt for rekkefølge)"
                            placeholder="Skriv inn nummer..."
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleOpenDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleSaveNewSubCat}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid item xs={12}>
            {newSubCatDialog()}
            <h1>Liste over lagret NSRS underkategorier</h1>
            <Select value={selectedCat} onChange={e => setSelectedCat(e.target.value)} label="Velg en kategori">
                {categories.map(category => {
                    return (
                        <MenuItem value={category.id} key={category.id}>
                            {category.order + ' - ' + category.name}
                        </MenuItem>
                    );
                })}
            </Select>
            {selectedCat !== '' ? (
                <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{ padding: '20px', marginBottom: '20px', width: '250px' }}
                    onClick={setOpen}
                >
                    Legg til ny NSRS underkategori{' '}
                </Button>
            ) : (
                <></>
            )}
            <List>
                {categories.find(category => category.id === selectedCat).subcategories &&
                categories.find(category => category.id === selectedCat).subcategories.items.length === 0 ? (
                    <>Det er ingen lagret NSRS underkategorier.</>
                ) : (
                    categories.find(category => category.id === selectedCat).subcategories &&
                    categories
                        .find(category => category.id === selectedCat)
                        .subcategories.items.sort((a, b) => a.order - b.order)
                        .map(subcategory => {
                            return (
                                <Subcategory
                                    key={subcategory.id}
                                    showPopup={showPopup}
                                    data={subcategory}
                                    category={categories.find(category => category.id === selectedCat)}
                                    updateCategories={updateCategories}
                                />
                            );
                        })
                )}
            </List>
        </Grid>
    );
}
