import { API, graphqlOperation } from 'aws-amplify';
import { listCompanyDatas, listUsers, listCertCategorys } from '../../../../../graphql/queries';

const fetchUsers = async token => {
    return new Promise(async resolve => {
        await API.graphql(graphqlOperation(listUsers, { nextToken: token })).then(data => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Fetched list of all users', data);
            }

            resolve(data.data.listUsers);
        });
    });
};

const fetchCompanies = async token => {
    return new Promise(async resolve => {
        await API.graphql(graphqlOperation(listCompanyDatas, { nextToken: token })).then(data => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Fetched data of all companies', data);
            }

            resolve(data.data.listCompanyDatas);
        });
    });
};

// Fetches all available users
export async function fetchAllUsers() {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchUsers(nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching users from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched users: ', dataArr);
        }

        resolve(dataArr);
    });
}

export async function fetchAllCompanyData() {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchCompanies(nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching company data from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched company data: ', dataArr);
        }

        resolve(dataArr);
    });
}

export async function fetchCategories() {
    return new Promise(async resolve => {
        await API.graphql(graphqlOperation(listCertCategorys)).then(data => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Fetched all categories: ', data);
            }

            resolve(data.data.listCertCategorys.items);
        });
    });
}
