import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { useStyles } from '../DocumentsStyles';
import useRouter from '../../../../hooks/useRouter';

export default function NavigationBar() {
    const classes = useStyles();
    const router = useRouter();

    return (
        <ButtonGroup>
            <Button
                variant="contained"
                className={
                    router.pathname === '/documents' || router.pathname === '/documents/overview'
                        ? classes.activeButton
                        : classes.unActiveButton
                }
                onClick={() => router.push('/documents/overview')}
            >
                Oversiktsrapport
            </Button>
            <Button
                variant="contained"
                className={router.pathname === '/documents/detail' ? classes.activeButton : classes.unActiveButton}
                onClick={() => router.push('/documents/detail')}
            >
                Statusrapport
            </Button>
            <Button
                variant="contained"
                className={router.pathname === '/documents/climate' ? classes.activeButton : classes.unActiveButton}
                onClick={() => router.push('/documents/climate')}
            >
                Klimaregnskapsrapport
            </Button>
            <Button
                variant="contained"
                className={router.pathname === '/documents/NSRS' ? classes.activeButton : classes.unActiveButton}
                onClick={() => router.push('/documents/NSRS')}
            >
                NSRS Rapport
            </Button>
        </ButtonGroup>
    );
}
