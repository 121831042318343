import React, { useState } from 'react';
import { Grid, Button, Select, MenuItem } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { NumberInput } from '../../../../../components/InputForm';
import EmissionFactorRowView from './EmissionFactorRowView';

import { API, graphqlOperation } from 'aws-amplify';
import { createClimateEmissionFactor } from '../../../../../graphql/mutations';

export default function EmissionFactorListView(props) {
    const [open, setOpen] = useToggle();

    const [questionId, setQuestionId] = useState('');
    const [name, setName] = useState('');
    const [scope, setScope] = useState(1);

    const [selectedQuestion, setSelectedQuestion] = useState('(Kjøle)gasser');

    const saveNewFactor = async () => {
        return new Promise(async (resolve, reject) => {
            // Input data for creating a new CompanyData object
            let inputData = {
                questionId: questionId,
                name: name,
                scope: scope,
            };

            await API.graphql(graphqlOperation(createClimateEmissionFactor, { input: inputData }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new factor: ', data.data.createClimateEmissionFactor);
                    }

                    resolve(data.data.createClimateEmissionFactor);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Failed to create new factor: ', err);
                    }

                    reject(err);
                });
        });
    };

    const newFactorDialog = () => {
        return (
            <Grid>
                <Dialog open={open} onClose={handleDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Lag ny utslippsfaktor</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>Lag en ny utslippsfaktor</DialogContentText>
                        <InputForm
                            label="ID til spørsmål"
                            placeholder="Skriv inn ID..."
                            value={questionId}
                            onChange={e => setQuestionId(e.target.value)}
                        />
                        <InputForm
                            label="Navn på utslippsfaktoren"
                            placeholder="Skriv inn navn..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <NumberInput
                            label="Hvilke scope tilhører faktoren"
                            placeholder="Skriv inn nummer på scope..."
                            value={scope}
                            onChange={e => setScope(e.target.value)}
                            min={1}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleSaveNewFactor}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    const handleDialog = () => {
        setQuestionId('');
        setName('');
        setScope(1);

        setOpen();
    };

    const handleSaveNewFactor = async () => {
        if (questionId === '' || name === '') {
            props.showPopup('ID og navn må være fylt ut!');
            return;
        }

        await saveNewFactor().catch(() => {
            props.showPopup('En feil oppsto ved lagring av utslippsfaktoren');
            return;
        });

        props.showPopup('Utslippsfaktoren har blitt lagret');

        await props.updateFactors();

        handleDialog();
    };

    return (
        <Grid item xs={12}>
            {newFactorDialog()}
            <h1>Liste over lagret utslippsfaktorer</h1>
            <Select value={selectedQuestion} onChange={e => setSelectedQuestion(e.target.value)}>
                {props.questionFilters.map(question => {
                    return (
                        <MenuItem value={question} key={question}>
                            {question}
                        </MenuItem>
                    );
                })}
            </Select>
            <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                sx={{ padding: '20px', marginBottom: '20px', width: '200px' }}
                onClick={handleDialog}
            >
                Legg til ny utslippsfaktor{' '}
            </Button>
            <List>
                {props.factors && props.factors.length === 0 ? (
                    <>Det er ingen lagret utslippsfaktorer.</>
                ) : (
                    props.factors &&
                    props.factors
                        .filter(factor => factor.questionId === selectedQuestion)
                        .sort((a, b) => a.questionId.localeCompare(b.questionId) || a.name.localeCompare(b.name))
                        .map(factor => {
                            return (
                                <EmissionFactorRowView
                                    key={factor.id}
                                    showPopup={props.showPopup}
                                    data={factor}
                                    update={props.updateFactors}
                                />
                            );
                        })
                )}
            </List>
        </Grid>
    );
}
