import React, { useState, useEffect } from 'react';
import { Grid, Paper, Button, Typography, Divider, TextField } from '@mui/material';
import { useStyles } from '../DocumentsStyles';
import ClimateAccountingPDF, { ClimateAccounting as ClimatePDFExport } from './ClimateAccountingPDF';
import Autocomplete from '@mui/material/Autocomplete';
import { useAuth } from '../../../../auth/useAuth';
import useRouter from '../../../../hooks/useRouter';
import { fetchClimateReports } from '../../Climate/climateFunctions';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Download } from '../../../../components/Icons';
import amplitude from 'amplitude-js';
import Replacement from '../../../../assets/images/nocustomer_climate.svg';
import NavigationBar from './NavigationBar';

export default function ClimateAccounting() {
    const classes = useStyles();
    const router = useRouter();
    const auth = useAuth();

    const [reportList, setReportList] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            fetchClimateReports(auth.companyData.companyId).then(data => {
                let newData = data;

                for (const item of newData) {
                    if (item.title === '') {
                        item.title = 'Ingen tittel';
                    }
                }

                setReportList(newData);
            });
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const downloadDocument = () => {
        if (process.env.NODE_ENV !== 'development') {
            amplitude.getInstance().logEvent('climate_report_downloaded');
        }
    };

    return (
        <Grid item xs={12}>
            <NavigationBar />
            <Paper sx={{ padding: '40px' }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={6} className={classes.viewerSection}>
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            reportList.length !== 0 && selectedReport !== null ? (
                                <ClimateAccountingPDF
                                    report={selectedReport}
                                    companyName={auth.companyData.companyData.name}
                                />
                            ) : (
                                <Typography variant="h2">
                                    Du har ikke valgt en rapport. Søk etter en i feltet til høyre.
                                </Typography>
                            )
                        ) : (
                            <img src={Replacement} alt="Replacement for non customers"></img>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Typography variant="body2" className={classes.pdfWarning}>
                            PDF forhåndvisning er foreløpig kun tilgjenglig på PC.
                        </Typography>
                        <Typography variant="h1" sx={{ textAlign: 'left' }}>
                            Klimaregnskapsrapport
                        </Typography>
                        <Divider sx={{ margin: '10px 0px' }} />
                        <Typography variant="body1">
                            Her kan dere laste ned en klimaregnskapsrapport med resultater og status innen Scope 1, 2 og
                            3 i henhold til GHG standard for klimaregnskap. <br /> Rapporten kan brukes til innsikt og
                            aktiviteter som reduserer eget utslipp samt dokumentasjon som kan brukes til anbud,
                            lånesøknader, årsrapporter, reduksjon av kostnader og markedsføring m.m.
                            <br />
                            <br /> Velg hvilket klimaregnskap du vil laste ned i listen under. Til venstre ser dere en
                            forhåndsvisning av valgte klimaregnskap.
                        </Typography>
                        <br />
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            <>
                                <Autocomplete
                                    value={selectedReport}
                                    onChange={(e, value) => setSelectedReport(value)}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    options={reportList}
                                    getOptionLabel={option => option.title}
                                    freeSolo
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label=""
                                            placeholder="Søk eller skriv inn tittel på fullførte klimaregnskap..."
                                        />
                                    )}
                                />
                                {reportList.length !== 0 && selectedReport !== null ? (
                                    <PDFDownloadLink
                                        style={{
                                            color: '#000',
                                            textDecoration: 'none',
                                        }}
                                        document={
                                            <ClimatePDFExport
                                                report={selectedReport}
                                                companyName={auth.companyData.companyData.name}
                                            />
                                        }
                                        fileName={selectedReport.title + ' - Ecofric Klimaregnskapsrapport.pdf'}
                                    >
                                        {({ blob, url, loading, error }) =>
                                            loading ? (
                                                <Button
                                                    variant="contained"
                                                    disabled={true}
                                                    sx={{
                                                        height: '50px',
                                                        width: '100%',
                                                        marginTop: '30px',
                                                        color: '#000000',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    Laster inn dokument...
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    onClick={() => downloadDocument(blob)}
                                                    sx={{
                                                        height: '50px',
                                                        width: '100%',
                                                        marginTop: '30px',
                                                        color: '#000000',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    Last ned {Download}
                                                </Button>
                                            )
                                        }
                                    </PDFDownloadLink>
                                ) : (
                                    <Button
                                        variant="contained"
                                        disabled={true}
                                        sx={{
                                            height: '50px',
                                            width: '100%',
                                            marginTop: '30px',
                                            color: '#000000',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Velg en rapport
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{
                                    height: '50px',
                                    width: '100%',
                                    marginTop: '30px',
                                    color: '#000000',
                                    textDecoration: 'none',
                                }}
                                onClick={() => router.push('/subscribe')}
                            >
                                Bli kunde for å laste ned dokument
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
