/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      companyId
      username
      status
      name
      email
      phoneNumber
      position
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      companyId
      username
      status
      name
      email
      phoneNumber
      position
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      companyId
      username
      status
      name
      email
      phoneNumber
      position
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      companyId
      name
      orgNumber
      companyData {
        id
        status
        betaOptIn
        name
        orgNumber
        address
        invoice
        brregData
        certData {
          items {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            completedAt
            expDate
            history {
              items {
                id
                companyId
                companyCertDataId
                action
                timestamp
                userId
                description
                categoryId
                questionId
                newData
                oldData
                economyScore
                economyPercentScore
                socialScore
                socialPercentScore
                environmentalScore
                environmentalPercentScore
                totalScore
                totalPercentScore
                createdAt
                updatedAt
              }
              nextToken
            }
            responses {
              items {
                id
                companyId
                companyCertDataId
                categoryId
                questionId
                pointsEarned
                isAnswered
                answers
                text
                longText
                multiText
                multiDropSelect
                number
                multiNumber
                multiRows
                otherField
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            publicProfile {
              id
              companyId
              status
              certType
              certFilterTypes
              employeeAmount
              industryCodes
              isVisible
              completedAt
              expDate
              companyName
              orgNumber
              description
              contactPerson
              phoneNumber
              website
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        invitedUsers
        admins
        users {
          items {
            id
            companyId
            username
            status
            name
            email
            phoneNumber
            position
            createdAt
            updatedAt
          }
          nextToken
        }
        todoTasks {
          items {
            id
            companyId
            type
            status
            history
            dueDate
            users
            criteria
            subCriteria
            climateReportId
            climateCriteria
            climateScope
            title
            description
            result
            comments {
              items {
                id
                companyId
                todoTaskId
                userId
                type
                data
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      companyId
      name
      orgNumber
      companyData {
        id
        status
        betaOptIn
        name
        orgNumber
        address
        invoice
        brregData
        certData {
          items {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            completedAt
            expDate
            history {
              items {
                id
                companyId
                companyCertDataId
                action
                timestamp
                userId
                description
                categoryId
                questionId
                newData
                oldData
                economyScore
                economyPercentScore
                socialScore
                socialPercentScore
                environmentalScore
                environmentalPercentScore
                totalScore
                totalPercentScore
                createdAt
                updatedAt
              }
              nextToken
            }
            responses {
              items {
                id
                companyId
                companyCertDataId
                categoryId
                questionId
                pointsEarned
                isAnswered
                answers
                text
                longText
                multiText
                multiDropSelect
                number
                multiNumber
                multiRows
                otherField
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            publicProfile {
              id
              companyId
              status
              certType
              certFilterTypes
              employeeAmount
              industryCodes
              isVisible
              completedAt
              expDate
              companyName
              orgNumber
              description
              contactPerson
              phoneNumber
              website
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        invitedUsers
        admins
        users {
          items {
            id
            companyId
            username
            status
            name
            email
            phoneNumber
            position
            createdAt
            updatedAt
          }
          nextToken
        }
        todoTasks {
          items {
            id
            companyId
            type
            status
            history
            dueDate
            users
            criteria
            subCriteria
            climateReportId
            climateCriteria
            climateScope
            title
            description
            result
            comments {
              items {
                id
                companyId
                todoTaskId
                userId
                type
                data
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      companyId
      name
      orgNumber
      companyData {
        id
        status
        betaOptIn
        name
        orgNumber
        address
        invoice
        brregData
        certData {
          items {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            completedAt
            expDate
            history {
              items {
                id
                companyId
                companyCertDataId
                action
                timestamp
                userId
                description
                categoryId
                questionId
                newData
                oldData
                economyScore
                economyPercentScore
                socialScore
                socialPercentScore
                environmentalScore
                environmentalPercentScore
                totalScore
                totalPercentScore
                createdAt
                updatedAt
              }
              nextToken
            }
            responses {
              items {
                id
                companyId
                companyCertDataId
                categoryId
                questionId
                pointsEarned
                isAnswered
                answers
                text
                longText
                multiText
                multiDropSelect
                number
                multiNumber
                multiRows
                otherField
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            publicProfile {
              id
              companyId
              status
              certType
              certFilterTypes
              employeeAmount
              industryCodes
              isVisible
              completedAt
              expDate
              companyName
              orgNumber
              description
              contactPerson
              phoneNumber
              website
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        invitedUsers
        admins
        users {
          items {
            id
            companyId
            username
            status
            name
            email
            phoneNumber
            position
            createdAt
            updatedAt
          }
          nextToken
        }
        todoTasks {
          items {
            id
            companyId
            type
            status
            history
            dueDate
            users
            criteria
            subCriteria
            climateReportId
            climateCriteria
            climateScope
            title
            description
            result
            comments {
              items {
                id
                companyId
                todoTaskId
                userId
                type
                data
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyData = /* GraphQL */ `
  mutation CreateCompanyData(
    $input: CreateCompanyDataInput!
    $condition: ModelCompanyDataConditionInput
  ) {
    createCompanyData(input: $input, condition: $condition) {
      id
      status
      betaOptIn
      name
      orgNumber
      address
      invoice
      brregData
      certData {
        items {
          id
          companyId
          status
          certType
          certFilterTypes
          employeeAmount
          industryCodes
          completedAt
          expDate
          history {
            items {
              id
              companyId
              companyCertDataId
              action
              timestamp
              userId
              description
              categoryId
              questionId
              newData
              oldData
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
            nextToken
          }
          responses {
            items {
              id
              companyId
              companyCertDataId
              categoryId
              questionId
              pointsEarned
              isAnswered
              answers
              text
              longText
              multiText
              multiDropSelect
              number
              multiNumber
              multiRows
              otherField
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          publicProfile {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            isVisible
            completedAt
            expDate
            companyName
            orgNumber
            description
            contactPerson
            phoneNumber
            website
            economyScore
            economyPercentScore
            socialScore
            socialPercentScore
            environmentalScore
            environmentalPercentScore
            totalScore
            totalPercentScore
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      invitedUsers
      admins
      users {
        items {
          id
          companyId
          username
          status
          name
          email
          phoneNumber
          position
          createdAt
          updatedAt
        }
        nextToken
      }
      todoTasks {
        items {
          id
          companyId
          type
          status
          history
          dueDate
          users
          criteria
          subCriteria
          climateReportId
          climateCriteria
          climateScope
          title
          description
          result
          comments {
            items {
              id
              companyId
              todoTaskId
              userId
              type
              data
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyData = /* GraphQL */ `
  mutation UpdateCompanyData(
    $input: UpdateCompanyDataInput!
    $condition: ModelCompanyDataConditionInput
  ) {
    updateCompanyData(input: $input, condition: $condition) {
      id
      status
      betaOptIn
      name
      orgNumber
      address
      invoice
      brregData
      certData {
        items {
          id
          companyId
          status
          certType
          certFilterTypes
          employeeAmount
          industryCodes
          completedAt
          expDate
          history {
            items {
              id
              companyId
              companyCertDataId
              action
              timestamp
              userId
              description
              categoryId
              questionId
              newData
              oldData
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
            nextToken
          }
          responses {
            items {
              id
              companyId
              companyCertDataId
              categoryId
              questionId
              pointsEarned
              isAnswered
              answers
              text
              longText
              multiText
              multiDropSelect
              number
              multiNumber
              multiRows
              otherField
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          publicProfile {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            isVisible
            completedAt
            expDate
            companyName
            orgNumber
            description
            contactPerson
            phoneNumber
            website
            economyScore
            economyPercentScore
            socialScore
            socialPercentScore
            environmentalScore
            environmentalPercentScore
            totalScore
            totalPercentScore
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      invitedUsers
      admins
      users {
        items {
          id
          companyId
          username
          status
          name
          email
          phoneNumber
          position
          createdAt
          updatedAt
        }
        nextToken
      }
      todoTasks {
        items {
          id
          companyId
          type
          status
          history
          dueDate
          users
          criteria
          subCriteria
          climateReportId
          climateCriteria
          climateScope
          title
          description
          result
          comments {
            items {
              id
              companyId
              todoTaskId
              userId
              type
              data
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyData = /* GraphQL */ `
  mutation DeleteCompanyData(
    $input: DeleteCompanyDataInput!
    $condition: ModelCompanyDataConditionInput
  ) {
    deleteCompanyData(input: $input, condition: $condition) {
      id
      status
      betaOptIn
      name
      orgNumber
      address
      invoice
      brregData
      certData {
        items {
          id
          companyId
          status
          certType
          certFilterTypes
          employeeAmount
          industryCodes
          completedAt
          expDate
          history {
            items {
              id
              companyId
              companyCertDataId
              action
              timestamp
              userId
              description
              categoryId
              questionId
              newData
              oldData
              economyScore
              economyPercentScore
              socialScore
              socialPercentScore
              environmentalScore
              environmentalPercentScore
              totalScore
              totalPercentScore
              createdAt
              updatedAt
            }
            nextToken
          }
          responses {
            items {
              id
              companyId
              companyCertDataId
              categoryId
              questionId
              pointsEarned
              isAnswered
              answers
              text
              longText
              multiText
              multiDropSelect
              number
              multiNumber
              multiRows
              otherField
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          publicProfile {
            id
            companyId
            status
            certType
            certFilterTypes
            employeeAmount
            industryCodes
            isVisible
            completedAt
            expDate
            companyName
            orgNumber
            description
            contactPerson
            phoneNumber
            website
            economyScore
            economyPercentScore
            socialScore
            socialPercentScore
            environmentalScore
            environmentalPercentScore
            totalScore
            totalPercentScore
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      invitedUsers
      admins
      users {
        items {
          id
          companyId
          username
          status
          name
          email
          phoneNumber
          position
          createdAt
          updatedAt
        }
        nextToken
      }
      todoTasks {
        items {
          id
          companyId
          type
          status
          history
          dueDate
          users
          criteria
          subCriteria
          climateReportId
          climateCriteria
          climateScope
          title
          description
          result
          comments {
            items {
              id
              companyId
              todoTaskId
              userId
              type
              data
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserNotification = /* GraphQL */ `
  mutation CreateUserNotification(
    $input: CreateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    createUserNotification(input: $input, condition: $condition) {
      id
      companyId
      userId
      type
      markedAsRead
      archived
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateUserNotification = /* GraphQL */ `
  mutation UpdateUserNotification(
    $input: UpdateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    updateUserNotification(input: $input, condition: $condition) {
      id
      companyId
      userId
      type
      markedAsRead
      archived
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserNotification = /* GraphQL */ `
  mutation DeleteUserNotification(
    $input: DeleteUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    deleteUserNotification(input: $input, condition: $condition) {
      id
      companyId
      userId
      type
      markedAsRead
      archived
      data
      createdAt
      updatedAt
    }
  }
`;
export const createTodoTask = /* GraphQL */ `
  mutation CreateTodoTask(
    $input: CreateTodoTaskInput!
    $condition: ModelTodoTaskConditionInput
  ) {
    createTodoTask(input: $input, condition: $condition) {
      id
      companyId
      type
      status
      history
      dueDate
      users
      criteria
      subCriteria
      climateReportId
      climateCriteria
      climateScope
      title
      description
      result
      comments {
        items {
          id
          companyId
          todoTaskId
          userId
          type
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTodoTask = /* GraphQL */ `
  mutation UpdateTodoTask(
    $input: UpdateTodoTaskInput!
    $condition: ModelTodoTaskConditionInput
  ) {
    updateTodoTask(input: $input, condition: $condition) {
      id
      companyId
      type
      status
      history
      dueDate
      users
      criteria
      subCriteria
      climateReportId
      climateCriteria
      climateScope
      title
      description
      result
      comments {
        items {
          id
          companyId
          todoTaskId
          userId
          type
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodoTask = /* GraphQL */ `
  mutation DeleteTodoTask(
    $input: DeleteTodoTaskInput!
    $condition: ModelTodoTaskConditionInput
  ) {
    deleteTodoTask(input: $input, condition: $condition) {
      id
      companyId
      type
      status
      history
      dueDate
      users
      criteria
      subCriteria
      climateReportId
      climateCriteria
      climateScope
      title
      description
      result
      comments {
        items {
          id
          companyId
          todoTaskId
          userId
          type
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTodoTaskComment = /* GraphQL */ `
  mutation CreateTodoTaskComment(
    $input: CreateTodoTaskCommentInput!
    $condition: ModelTodoTaskCommentConditionInput
  ) {
    createTodoTaskComment(input: $input, condition: $condition) {
      id
      companyId
      todoTaskId
      userId
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateTodoTaskComment = /* GraphQL */ `
  mutation UpdateTodoTaskComment(
    $input: UpdateTodoTaskCommentInput!
    $condition: ModelTodoTaskCommentConditionInput
  ) {
    updateTodoTaskComment(input: $input, condition: $condition) {
      id
      companyId
      todoTaskId
      userId
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodoTaskComment = /* GraphQL */ `
  mutation DeleteTodoTaskComment(
    $input: DeleteTodoTaskCommentInput!
    $condition: ModelTodoTaskCommentConditionInput
  ) {
    deleteTodoTaskComment(input: $input, condition: $condition) {
      id
      companyId
      todoTaskId
      userId
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const createDiscountCode = /* GraphQL */ `
  mutation CreateDiscountCode(
    $input: CreateDiscountCodeInput!
    $condition: ModelDiscountCodeConditionInput
  ) {
    createDiscountCode(input: $input, condition: $condition) {
      id
      code
      percentDiscount
      durationInMonths
      codeExpDate
      isTimeBased
      createdAt
      updatedAt
    }
  }
`;
export const updateDiscountCode = /* GraphQL */ `
  mutation UpdateDiscountCode(
    $input: UpdateDiscountCodeInput!
    $condition: ModelDiscountCodeConditionInput
  ) {
    updateDiscountCode(input: $input, condition: $condition) {
      id
      code
      percentDiscount
      durationInMonths
      codeExpDate
      isTimeBased
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiscountCode = /* GraphQL */ `
  mutation DeleteDiscountCode(
    $input: DeleteDiscountCodeInput!
    $condition: ModelDiscountCodeConditionInput
  ) {
    deleteDiscountCode(input: $input, condition: $condition) {
      id
      code
      percentDiscount
      durationInMonths
      codeExpDate
      isTimeBased
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyCertData = /* GraphQL */ `
  mutation CreateCompanyCertData(
    $input: CreateCompanyCertDataInput!
    $condition: ModelCompanyCertDataConditionInput
  ) {
    createCompanyCertData(input: $input, condition: $condition) {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      completedAt
      expDate
      history {
        items {
          id
          companyId
          companyCertDataId
          action
          timestamp
          userId
          description
          categoryId
          questionId
          newData
          oldData
          economyScore
          economyPercentScore
          socialScore
          socialPercentScore
          environmentalScore
          environmentalPercentScore
          totalScore
          totalPercentScore
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          companyId
          companyCertDataId
          categoryId
          questionId
          pointsEarned
          isAnswered
          answers
          text
          longText
          multiText
          multiDropSelect
          number
          multiNumber
          multiRows
          otherField
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      publicProfile {
        id
        companyId
        status
        certType
        certFilterTypes
        employeeAmount
        industryCodes
        isVisible
        completedAt
        expDate
        companyName
        orgNumber
        description
        contactPerson
        phoneNumber
        website
        economyScore
        economyPercentScore
        socialScore
        socialPercentScore
        environmentalScore
        environmentalPercentScore
        totalScore
        totalPercentScore
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCompanyCertData = /* GraphQL */ `
  mutation UpdateCompanyCertData(
    $input: UpdateCompanyCertDataInput!
    $condition: ModelCompanyCertDataConditionInput
  ) {
    updateCompanyCertData(input: $input, condition: $condition) {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      completedAt
      expDate
      history {
        items {
          id
          companyId
          companyCertDataId
          action
          timestamp
          userId
          description
          categoryId
          questionId
          newData
          oldData
          economyScore
          economyPercentScore
          socialScore
          socialPercentScore
          environmentalScore
          environmentalPercentScore
          totalScore
          totalPercentScore
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          companyId
          companyCertDataId
          categoryId
          questionId
          pointsEarned
          isAnswered
          answers
          text
          longText
          multiText
          multiDropSelect
          number
          multiNumber
          multiRows
          otherField
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      publicProfile {
        id
        companyId
        status
        certType
        certFilterTypes
        employeeAmount
        industryCodes
        isVisible
        completedAt
        expDate
        companyName
        orgNumber
        description
        contactPerson
        phoneNumber
        website
        economyScore
        economyPercentScore
        socialScore
        socialPercentScore
        environmentalScore
        environmentalPercentScore
        totalScore
        totalPercentScore
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCompanyCertData = /* GraphQL */ `
  mutation DeleteCompanyCertData(
    $input: DeleteCompanyCertDataInput!
    $condition: ModelCompanyCertDataConditionInput
  ) {
    deleteCompanyCertData(input: $input, condition: $condition) {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      completedAt
      expDate
      history {
        items {
          id
          companyId
          companyCertDataId
          action
          timestamp
          userId
          description
          categoryId
          questionId
          newData
          oldData
          economyScore
          economyPercentScore
          socialScore
          socialPercentScore
          environmentalScore
          environmentalPercentScore
          totalScore
          totalPercentScore
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          companyId
          companyCertDataId
          categoryId
          questionId
          pointsEarned
          isAnswered
          answers
          text
          longText
          multiText
          multiDropSelect
          number
          multiNumber
          multiRows
          otherField
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      publicProfile {
        id
        companyId
        status
        certType
        certFilterTypes
        employeeAmount
        industryCodes
        isVisible
        completedAt
        expDate
        companyName
        orgNumber
        description
        contactPerson
        phoneNumber
        website
        economyScore
        economyPercentScore
        socialScore
        socialPercentScore
        environmentalScore
        environmentalPercentScore
        totalScore
        totalPercentScore
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCompanyCertPublicProfile = /* GraphQL */ `
  mutation CreateCompanyCertPublicProfile(
    $input: CreateCompanyCertPublicProfileInput!
    $condition: ModelCompanyCertPublicProfileConditionInput
  ) {
    createCompanyCertPublicProfile(input: $input, condition: $condition) {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      isVisible
      completedAt
      expDate
      companyName
      orgNumber
      description
      contactPerson
      phoneNumber
      website
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyCertPublicProfile = /* GraphQL */ `
  mutation UpdateCompanyCertPublicProfile(
    $input: UpdateCompanyCertPublicProfileInput!
    $condition: ModelCompanyCertPublicProfileConditionInput
  ) {
    updateCompanyCertPublicProfile(input: $input, condition: $condition) {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      isVisible
      completedAt
      expDate
      companyName
      orgNumber
      description
      contactPerson
      phoneNumber
      website
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyCertPublicProfile = /* GraphQL */ `
  mutation DeleteCompanyCertPublicProfile(
    $input: DeleteCompanyCertPublicProfileInput!
    $condition: ModelCompanyCertPublicProfileConditionInput
  ) {
    deleteCompanyCertPublicProfile(input: $input, condition: $condition) {
      id
      companyId
      status
      certType
      certFilterTypes
      employeeAmount
      industryCodes
      isVisible
      completedAt
      expDate
      companyName
      orgNumber
      description
      contactPerson
      phoneNumber
      website
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyCertHistoryData = /* GraphQL */ `
  mutation CreateCompanyCertHistoryData(
    $input: CreateCompanyCertHistoryDataInput!
    $condition: ModelCompanyCertHistoryDataConditionInput
  ) {
    createCompanyCertHistoryData(input: $input, condition: $condition) {
      id
      companyId
      companyCertDataId
      action
      timestamp
      userId
      description
      categoryId
      questionId
      newData
      oldData
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyCertHistoryData = /* GraphQL */ `
  mutation UpdateCompanyCertHistoryData(
    $input: UpdateCompanyCertHistoryDataInput!
    $condition: ModelCompanyCertHistoryDataConditionInput
  ) {
    updateCompanyCertHistoryData(input: $input, condition: $condition) {
      id
      companyId
      companyCertDataId
      action
      timestamp
      userId
      description
      categoryId
      questionId
      newData
      oldData
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyCertHistoryData = /* GraphQL */ `
  mutation DeleteCompanyCertHistoryData(
    $input: DeleteCompanyCertHistoryDataInput!
    $condition: ModelCompanyCertHistoryDataConditionInput
  ) {
    deleteCompanyCertHistoryData(input: $input, condition: $condition) {
      id
      companyId
      companyCertDataId
      action
      timestamp
      userId
      description
      categoryId
      questionId
      newData
      oldData
      economyScore
      economyPercentScore
      socialScore
      socialPercentScore
      environmentalScore
      environmentalPercentScore
      totalScore
      totalPercentScore
      createdAt
      updatedAt
    }
  }
`;
export const createCertResponse = /* GraphQL */ `
  mutation CreateCertResponse(
    $input: CreateCertResponseInput!
    $condition: ModelCertResponseConditionInput
  ) {
    createCertResponse(input: $input, condition: $condition) {
      id
      companyId
      companyCertDataId
      categoryId
      questionId
      pointsEarned
      isAnswered
      answers
      text
      longText
      multiText
      multiDropSelect
      number
      multiNumber
      multiRows
      otherField
      createdAt
      updatedAt
    }
  }
`;
export const updateCertResponse = /* GraphQL */ `
  mutation UpdateCertResponse(
    $input: UpdateCertResponseInput!
    $condition: ModelCertResponseConditionInput
  ) {
    updateCertResponse(input: $input, condition: $condition) {
      id
      companyId
      companyCertDataId
      categoryId
      questionId
      pointsEarned
      isAnswered
      answers
      text
      longText
      multiText
      multiDropSelect
      number
      multiNumber
      multiRows
      otherField
      createdAt
      updatedAt
    }
  }
`;
export const deleteCertResponse = /* GraphQL */ `
  mutation DeleteCertResponse(
    $input: DeleteCertResponseInput!
    $condition: ModelCertResponseConditionInput
  ) {
    deleteCertResponse(input: $input, condition: $condition) {
      id
      companyId
      companyCertDataId
      categoryId
      questionId
      pointsEarned
      isAnswered
      answers
      text
      longText
      multiText
      multiDropSelect
      number
      multiNumber
      multiRows
      otherField
      createdAt
      updatedAt
    }
  }
`;
export const createCertCategory = /* GraphQL */ `
  mutation CreateCertCategory(
    $input: CreateCertCategoryInput!
    $condition: ModelCertCategoryConditionInput
  ) {
    createCertCategory(input: $input, condition: $condition) {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              subcategoryId
              question
              description
              explanation
              type
              pointValue
              filterType
              industryCodes
              required
              order
              minChars
              maxChars
              createdAt
              updatedAt
              options {
                items {
                  id
                  questionId
                  pointValue
                  order
                  option
                  isOtherOption
                  isCustomMultiSelectOption
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateCertCategory = /* GraphQL */ `
  mutation UpdateCertCategory(
    $input: UpdateCertCategoryInput!
    $condition: ModelCertCategoryConditionInput
  ) {
    updateCertCategory(input: $input, condition: $condition) {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              subcategoryId
              question
              description
              explanation
              type
              pointValue
              filterType
              industryCodes
              required
              order
              minChars
              maxChars
              createdAt
              updatedAt
              options {
                items {
                  id
                  questionId
                  pointValue
                  order
                  option
                  isOtherOption
                  isCustomMultiSelectOption
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCertCategory = /* GraphQL */ `
  mutation DeleteCertCategory(
    $input: DeleteCertCategoryInput!
    $condition: ModelCertCategoryConditionInput
  ) {
    deleteCertCategory(input: $input, condition: $condition) {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              subcategoryId
              question
              description
              explanation
              type
              pointValue
              filterType
              industryCodes
              required
              order
              minChars
              maxChars
              createdAt
              updatedAt
              options {
                items {
                  id
                  questionId
                  pointValue
                  order
                  option
                  isOtherOption
                  isCustomMultiSelectOption
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createCertSubcategory = /* GraphQL */ `
  mutation CreateCertSubcategory(
    $input: CreateCertSubcategoryInput!
    $condition: ModelCertSubcategoryConditionInput
  ) {
    createCertSubcategory(input: $input, condition: $condition) {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          subcategoryId
          question
          description
          explanation
          type
          pointValue
          filterType
          industryCodes
          required
          order
          minChars
          maxChars
          createdAt
          updatedAt
          options {
            items {
              id
              questionId
              pointValue
              order
              option
              isOtherOption
              isCustomMultiSelectOption
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateCertSubcategory = /* GraphQL */ `
  mutation UpdateCertSubcategory(
    $input: UpdateCertSubcategoryInput!
    $condition: ModelCertSubcategoryConditionInput
  ) {
    updateCertSubcategory(input: $input, condition: $condition) {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          subcategoryId
          question
          description
          explanation
          type
          pointValue
          filterType
          industryCodes
          required
          order
          minChars
          maxChars
          createdAt
          updatedAt
          options {
            items {
              id
              questionId
              pointValue
              order
              option
              isOtherOption
              isCustomMultiSelectOption
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCertSubcategory = /* GraphQL */ `
  mutation DeleteCertSubcategory(
    $input: DeleteCertSubcategoryInput!
    $condition: ModelCertSubcategoryConditionInput
  ) {
    deleteCertSubcategory(input: $input, condition: $condition) {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          subcategoryId
          question
          description
          explanation
          type
          pointValue
          filterType
          industryCodes
          required
          order
          minChars
          maxChars
          createdAt
          updatedAt
          options {
            items {
              id
              questionId
              pointValue
              order
              option
              isOtherOption
              isCustomMultiSelectOption
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createCertQuestion = /* GraphQL */ `
  mutation CreateCertQuestion(
    $input: CreateCertQuestionInput!
    $condition: ModelCertQuestionConditionInput
  ) {
    createCertQuestion(input: $input, condition: $condition) {
      id
      subcategoryId
      question
      description
      explanation
      type
      pointValue
      filterType
      industryCodes
      required
      order
      minChars
      maxChars
      createdAt
      updatedAt
      options {
        items {
          id
          questionId
          pointValue
          order
          option
          isOtherOption
          isCustomMultiSelectOption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCertQuestion = /* GraphQL */ `
  mutation UpdateCertQuestion(
    $input: UpdateCertQuestionInput!
    $condition: ModelCertQuestionConditionInput
  ) {
    updateCertQuestion(input: $input, condition: $condition) {
      id
      subcategoryId
      question
      description
      explanation
      type
      pointValue
      filterType
      industryCodes
      required
      order
      minChars
      maxChars
      createdAt
      updatedAt
      options {
        items {
          id
          questionId
          pointValue
          order
          option
          isOtherOption
          isCustomMultiSelectOption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCertQuestion = /* GraphQL */ `
  mutation DeleteCertQuestion(
    $input: DeleteCertQuestionInput!
    $condition: ModelCertQuestionConditionInput
  ) {
    deleteCertQuestion(input: $input, condition: $condition) {
      id
      subcategoryId
      question
      description
      explanation
      type
      pointValue
      filterType
      industryCodes
      required
      order
      minChars
      maxChars
      createdAt
      updatedAt
      options {
        items {
          id
          questionId
          pointValue
          order
          option
          isOtherOption
          isCustomMultiSelectOption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCertQuestionOption = /* GraphQL */ `
  mutation CreateCertQuestionOption(
    $input: CreateCertQuestionOptionInput!
    $condition: ModelCertQuestionOptionConditionInput
  ) {
    createCertQuestionOption(input: $input, condition: $condition) {
      id
      questionId
      pointValue
      order
      option
      isOtherOption
      isCustomMultiSelectOption
      createdAt
      updatedAt
    }
  }
`;
export const updateCertQuestionOption = /* GraphQL */ `
  mutation UpdateCertQuestionOption(
    $input: UpdateCertQuestionOptionInput!
    $condition: ModelCertQuestionOptionConditionInput
  ) {
    updateCertQuestionOption(input: $input, condition: $condition) {
      id
      questionId
      pointValue
      order
      option
      isOtherOption
      isCustomMultiSelectOption
      createdAt
      updatedAt
    }
  }
`;
export const deleteCertQuestionOption = /* GraphQL */ `
  mutation DeleteCertQuestionOption(
    $input: DeleteCertQuestionOptionInput!
    $condition: ModelCertQuestionOptionConditionInput
  ) {
    deleteCertQuestionOption(input: $input, condition: $condition) {
      id
      questionId
      pointValue
      order
      option
      isOtherOption
      isCustomMultiSelectOption
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyClimateAccReport = /* GraphQL */ `
  mutation CreateCompanyClimateAccReport(
    $input: CreateCompanyClimateAccReportInput!
    $condition: ModelCompanyClimateAccReportConditionInput
  ) {
    createCompanyClimateAccReport(input: $input, condition: $condition) {
      id
      companyId
      status
      title
      year
      userCreatedBy
      infoData
      responses {
        items {
          id
          companyId
          climateAccReportId
          questionId
          scope
          data
          isRelevant
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyClimateAccReport = /* GraphQL */ `
  mutation UpdateCompanyClimateAccReport(
    $input: UpdateCompanyClimateAccReportInput!
    $condition: ModelCompanyClimateAccReportConditionInput
  ) {
    updateCompanyClimateAccReport(input: $input, condition: $condition) {
      id
      companyId
      status
      title
      year
      userCreatedBy
      infoData
      responses {
        items {
          id
          companyId
          climateAccReportId
          questionId
          scope
          data
          isRelevant
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyClimateAccReport = /* GraphQL */ `
  mutation DeleteCompanyClimateAccReport(
    $input: DeleteCompanyClimateAccReportInput!
    $condition: ModelCompanyClimateAccReportConditionInput
  ) {
    deleteCompanyClimateAccReport(input: $input, condition: $condition) {
      id
      companyId
      status
      title
      year
      userCreatedBy
      infoData
      responses {
        items {
          id
          companyId
          climateAccReportId
          questionId
          scope
          data
          isRelevant
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createClimateAccResponse = /* GraphQL */ `
  mutation CreateClimateAccResponse(
    $input: CreateClimateAccResponseInput!
    $condition: ModelClimateAccResponseConditionInput
  ) {
    createClimateAccResponse(input: $input, condition: $condition) {
      id
      companyId
      climateAccReportId
      questionId
      scope
      data
      isRelevant
      createdAt
      updatedAt
    }
  }
`;
export const updateClimateAccResponse = /* GraphQL */ `
  mutation UpdateClimateAccResponse(
    $input: UpdateClimateAccResponseInput!
    $condition: ModelClimateAccResponseConditionInput
  ) {
    updateClimateAccResponse(input: $input, condition: $condition) {
      id
      companyId
      climateAccReportId
      questionId
      scope
      data
      isRelevant
      createdAt
      updatedAt
    }
  }
`;
export const deleteClimateAccResponse = /* GraphQL */ `
  mutation DeleteClimateAccResponse(
    $input: DeleteClimateAccResponseInput!
    $condition: ModelClimateAccResponseConditionInput
  ) {
    deleteClimateAccResponse(input: $input, condition: $condition) {
      id
      companyId
      climateAccReportId
      questionId
      scope
      data
      isRelevant
      createdAt
      updatedAt
    }
  }
`;
export const createClimateEmissionFactor = /* GraphQL */ `
  mutation CreateClimateEmissionFactor(
    $input: CreateClimateEmissionFactorInput!
    $condition: ModelClimateEmissionFactorConditionInput
  ) {
    createClimateEmissionFactor(input: $input, condition: $condition) {
      id
      questionId
      name
      scope
      types {
        items {
          id
          type
          value
          emissionFactorID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateClimateEmissionFactor = /* GraphQL */ `
  mutation UpdateClimateEmissionFactor(
    $input: UpdateClimateEmissionFactorInput!
    $condition: ModelClimateEmissionFactorConditionInput
  ) {
    updateClimateEmissionFactor(input: $input, condition: $condition) {
      id
      questionId
      name
      scope
      types {
        items {
          id
          type
          value
          emissionFactorID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteClimateEmissionFactor = /* GraphQL */ `
  mutation DeleteClimateEmissionFactor(
    $input: DeleteClimateEmissionFactorInput!
    $condition: ModelClimateEmissionFactorConditionInput
  ) {
    deleteClimateEmissionFactor(input: $input, condition: $condition) {
      id
      questionId
      name
      scope
      types {
        items {
          id
          type
          value
          emissionFactorID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createClimateEmissionFactorType = /* GraphQL */ `
  mutation CreateClimateEmissionFactorType(
    $input: CreateClimateEmissionFactorTypeInput!
    $condition: ModelClimateEmissionFactorTypeConditionInput
  ) {
    createClimateEmissionFactorType(input: $input, condition: $condition) {
      id
      type
      value
      emissionFactorID
      createdAt
      updatedAt
    }
  }
`;
export const updateClimateEmissionFactorType = /* GraphQL */ `
  mutation UpdateClimateEmissionFactorType(
    $input: UpdateClimateEmissionFactorTypeInput!
    $condition: ModelClimateEmissionFactorTypeConditionInput
  ) {
    updateClimateEmissionFactorType(input: $input, condition: $condition) {
      id
      type
      value
      emissionFactorID
      createdAt
      updatedAt
    }
  }
`;
export const deleteClimateEmissionFactorType = /* GraphQL */ `
  mutation DeleteClimateEmissionFactorType(
    $input: DeleteClimateEmissionFactorTypeInput!
    $condition: ModelClimateEmissionFactorTypeConditionInput
  ) {
    deleteClimateEmissionFactorType(input: $input, condition: $condition) {
      id
      type
      value
      emissionFactorID
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyNSRSReport = /* GraphQL */ `
  mutation CreateCompanyNSRSReport(
    $input: CreateCompanyNSRSReportInput!
    $condition: ModelCompanyNSRSReportConditionInput
  ) {
    createCompanyNSRSReport(input: $input, condition: $condition) {
      id
      companyId
      status
      reportYear
      userCreatedBy
      responses {
        items {
          id
          companyId
          reportId
          categoryId
          subcategoryId
          questionId
          isAnswered
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyNSRSReport = /* GraphQL */ `
  mutation UpdateCompanyNSRSReport(
    $input: UpdateCompanyNSRSReportInput!
    $condition: ModelCompanyNSRSReportConditionInput
  ) {
    updateCompanyNSRSReport(input: $input, condition: $condition) {
      id
      companyId
      status
      reportYear
      userCreatedBy
      responses {
        items {
          id
          companyId
          reportId
          categoryId
          subcategoryId
          questionId
          isAnswered
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyNSRSReport = /* GraphQL */ `
  mutation DeleteCompanyNSRSReport(
    $input: DeleteCompanyNSRSReportInput!
    $condition: ModelCompanyNSRSReportConditionInput
  ) {
    deleteCompanyNSRSReport(input: $input, condition: $condition) {
      id
      companyId
      status
      reportYear
      userCreatedBy
      responses {
        items {
          id
          companyId
          reportId
          categoryId
          subcategoryId
          questionId
          isAnswered
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNSRSResponse = /* GraphQL */ `
  mutation CreateNSRSResponse(
    $input: CreateNSRSResponseInput!
    $condition: ModelNSRSResponseConditionInput
  ) {
    createNSRSResponse(input: $input, condition: $condition) {
      id
      companyId
      reportId
      categoryId
      subcategoryId
      questionId
      isAnswered
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateNSRSResponse = /* GraphQL */ `
  mutation UpdateNSRSResponse(
    $input: UpdateNSRSResponseInput!
    $condition: ModelNSRSResponseConditionInput
  ) {
    updateNSRSResponse(input: $input, condition: $condition) {
      id
      companyId
      reportId
      categoryId
      subcategoryId
      questionId
      isAnswered
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteNSRSResponse = /* GraphQL */ `
  mutation DeleteNSRSResponse(
    $input: DeleteNSRSResponseInput!
    $condition: ModelNSRSResponseConditionInput
  ) {
    deleteNSRSResponse(input: $input, condition: $condition) {
      id
      companyId
      reportId
      categoryId
      subcategoryId
      questionId
      isAnswered
      data
      createdAt
      updatedAt
    }
  }
`;
export const createNSRSCategory = /* GraphQL */ `
  mutation CreateNSRSCategory(
    $input: CreateNSRSCategoryInput!
    $condition: ModelNSRSCategoryConditionInput
  ) {
    createNSRSCategory(input: $input, condition: $condition) {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              categoryId
              subcategoryId
              name
              description
              explanation
              filterTypes
              industryCodes
              required
              order
              data
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateNSRSCategory = /* GraphQL */ `
  mutation UpdateNSRSCategory(
    $input: UpdateNSRSCategoryInput!
    $condition: ModelNSRSCategoryConditionInput
  ) {
    updateNSRSCategory(input: $input, condition: $condition) {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              categoryId
              subcategoryId
              name
              description
              explanation
              filterTypes
              industryCodes
              required
              order
              data
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteNSRSCategory = /* GraphQL */ `
  mutation DeleteNSRSCategory(
    $input: DeleteNSRSCategoryInput!
    $condition: ModelNSRSCategoryConditionInput
  ) {
    deleteNSRSCategory(input: $input, condition: $condition) {
      id
      name
      description
      order
      createdAt
      updatedAt
      subcategories {
        items {
          id
          categoryId
          name
          order
          createdAt
          updatedAt
          questions {
            items {
              id
              categoryId
              subcategoryId
              name
              description
              explanation
              filterTypes
              industryCodes
              required
              order
              data
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createNSRSSubcategory = /* GraphQL */ `
  mutation CreateNSRSSubcategory(
    $input: CreateNSRSSubcategoryInput!
    $condition: ModelNSRSSubcategoryConditionInput
  ) {
    createNSRSSubcategory(input: $input, condition: $condition) {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          categoryId
          subcategoryId
          name
          description
          explanation
          filterTypes
          industryCodes
          required
          order
          data
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateNSRSSubcategory = /* GraphQL */ `
  mutation UpdateNSRSSubcategory(
    $input: UpdateNSRSSubcategoryInput!
    $condition: ModelNSRSSubcategoryConditionInput
  ) {
    updateNSRSSubcategory(input: $input, condition: $condition) {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          categoryId
          subcategoryId
          name
          description
          explanation
          filterTypes
          industryCodes
          required
          order
          data
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteNSRSSubcategory = /* GraphQL */ `
  mutation DeleteNSRSSubcategory(
    $input: DeleteNSRSSubcategoryInput!
    $condition: ModelNSRSSubcategoryConditionInput
  ) {
    deleteNSRSSubcategory(input: $input, condition: $condition) {
      id
      categoryId
      name
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          categoryId
          subcategoryId
          name
          description
          explanation
          filterTypes
          industryCodes
          required
          order
          data
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createNSRSQuestion = /* GraphQL */ `
  mutation CreateNSRSQuestion(
    $input: CreateNSRSQuestionInput!
    $condition: ModelNSRSQuestionConditionInput
  ) {
    createNSRSQuestion(input: $input, condition: $condition) {
      id
      categoryId
      subcategoryId
      name
      description
      explanation
      filterTypes
      industryCodes
      required
      order
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateNSRSQuestion = /* GraphQL */ `
  mutation UpdateNSRSQuestion(
    $input: UpdateNSRSQuestionInput!
    $condition: ModelNSRSQuestionConditionInput
  ) {
    updateNSRSQuestion(input: $input, condition: $condition) {
      id
      categoryId
      subcategoryId
      name
      description
      explanation
      filterTypes
      industryCodes
      required
      order
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteNSRSQuestion = /* GraphQL */ `
  mutation DeleteNSRSQuestion(
    $input: DeleteNSRSQuestionInput!
    $condition: ModelNSRSQuestionConditionInput
  ) {
    deleteNSRSQuestion(input: $input, condition: $condition) {
      id
      categoryId
      subcategoryId
      name
      description
      explanation
      filterTypes
      industryCodes
      required
      order
      data
      createdAt
      updatedAt
    }
  }
`;
export const createTemplateFile = /* GraphQL */ `
  mutation CreateTemplateFile(
    $input: CreateTemplateFileInput!
    $condition: ModelTemplateFileConditionInput
  ) {
    createTemplateFile(input: $input, condition: $condition) {
      id
      title
      description
      accessLevel
      fileName
      filePath
      fileSize
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const updateTemplateFile = /* GraphQL */ `
  mutation UpdateTemplateFile(
    $input: UpdateTemplateFileInput!
    $condition: ModelTemplateFileConditionInput
  ) {
    updateTemplateFile(input: $input, condition: $condition) {
      id
      title
      description
      accessLevel
      fileName
      filePath
      fileSize
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemplateFile = /* GraphQL */ `
  mutation DeleteTemplateFile(
    $input: DeleteTemplateFileInput!
    $condition: ModelTemplateFileConditionInput
  ) {
    deleteTemplateFile(input: $input, condition: $condition) {
      id
      title
      description
      accessLevel
      fileName
      filePath
      fileSize
      fileType
      createdAt
      updatedAt
    }
  }
`;
