import { createTheme } from "@mui/material/styles";
import "./index.css";

const Yanone = "'Yanone Kaffeesatz', sans-serif";
const OpenSansCondensed = "'Open Sans Condensed', sans-serif";
const OpenSans = "'Open Sans', sans-serif";

export const theme = createTheme({
  palette: {
    action: {
      selected: "#FEF0D9",
      hover: "#FEF0D9",
      disabledBackground: "#eaeaea",
      disabled: "#c8c8c8",
    },
    primary: {
      main: "#C1E6EB",
      light: "#E0F3F5",
    },
    secondary: {
      main: "#C3E8D0",
      light: "#E1F4E8",
    },
    error: {
      main: "#FFD0BE",
      light: "#FFEEE7",
    },
    warning: {
      main: "#FDDFB0",
    },
    info: {
      main: "#BDCCD4",
    },
    success: {
      main: "#C3E8D0",
      light: "#E4F5EA",
    },
    background: {
      main: "#f5f5f5",
    },
    font: {
      main: "#000",
    },
  },
  typography: {
    body1: {
      fontFamily: OpenSans,
      fontSize: "15px",
      fontWeight: 100,
    },
    body2: {
      fontFamily: OpenSansCondensed,
      fontSize: "15px",
    },
    h1: {
      fontFamily: Yanone,
      fontSize: "40px",
      textAlign: "center",
    },
    h2: {
      fontFamily: Yanone,
      fontSize: "25px",
      marginBottom: "0px",
    },
    h3: {
      fontFamily: Yanone,
      fontWeight: 300,
      fontSize: "20px",
      textAlign: "left",
      backgroundColor: "#E2EFF1",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      display: "flex",
      padding: "10px 20px",
    },
    h4: {
      fontFamily: OpenSansCondensed,
      fontSize: "20px",
      fontWeight: 100,
    },
    h5: {
      fontFamily: OpenSansCondensed,
      fontSize: "30px",
      fontWeight: "bold",
      margin: "0px",
      width: "60px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    h6: {
      fontFamily: OpenSansCondensed,
      fontSize: "14px",
      textAlign: "center",
    },
    button: {
      fontFamily: OpenSansCondensed,
      fontSize: "1em",
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1em !important",
          background: "#f6f6f6",
          boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          width: "100%",
          border: "none",
          color: "#000000 !important",
          margin: "10px 0px",
          padding: "7px",
          fontFamily: OpenSansCondensed,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: OpenSansCondensed,
          color: "#000000",
        },
      },
    },
    MuiButton: {
      root: {
        color: "#000",
      },
      styleOverrides: {
        root: {
          color: "#000",
        },
        contained: {
          width: 150,
          height: 40,
          fontFamily: OpenSansCondensed,
          textTransform: "none",
          fontSize: 15,
          borderRadius: 5,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#eaeaea",
          },
        },
        text: {
          textDecoration: "underline",
          fontsize: 14,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          borderRadius: "5px 5px 0px 0px",
          color: "#000",
          opacity: 0.5,
          "&.Mui-selected": {
            color: "#000",
            opacity: 1,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          fontFamily: OpenSansCondensed + "!important",
          fontSize: "1em",
          background: "#F6F6F6",
          boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          width: "100%",
          height: "50px",
          border: "none",
          color: "#000",
          margin: "10px 0px",
          padding: " 0px 7px",
          '&&[class*="MuiInput-root"] $input': {
            padding: "12px 5px 12px !important",
            zIndex: "9999",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "none",
          padding: "5px 10px 5px 5px",
        },
        notchedOutline: {
          border: "none",
        },
        input: {
          padding: "7px",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: "hidden",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "4px solid #FDDFB0",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 10,
          borderBottom: "0px",
        },
        head: {
          padding: "10px 10px 0px 10px",
        },
        body: {
          borderRight: "1px solid #E8E8E8",
          "&:last-child": {
            borderRight: "0px",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(even)": {
            backgroundColor: "#f3f3f3",
            borderTop: "1px solid #EBEBEB",
            borderBottom: "1px solid #EBEBEB",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
          background: "#fff",
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          border: "0px",
        },
        docked: {
          width: 300,
          flexShrink: 0,
        },
        paper: {
          width: 300,
          height: "100%",
          border: "none",
          background: "#fff",
          boxShadow:
            "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
          display: "flex",
          justifyContent: "space-between",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          alignItems: "flex-start",
          padding: "10px 0px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: "100%",
          fontFamily: OpenSans,
          fontSize: "0.9em",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "#FEF0D9",
          },
          "&$selected": {
            backgroundColor: "#FDDFB0",
          },
          "&.Mui-selected": {
            backgroundColor: "#FEF0D9",
            borderRadius: "0px",
            "&.Mui-focusVisible": { background: "#FEF0D9" },
            "&:hover": {
              backgroundColor: "#FEF0D9",
            },
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: OpenSansCondensed,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: OpenSansCondensed,
          width: "80px",
        },
        label: {
          fontFamily: OpenSansCondensed,
          fontSize: "15px !important",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#000000",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#C1E6EB",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: OpenSans,
          fontWeight: 100,
          fontSize: "14px",
          padding: "2px 0px 2px 10px",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          marginTop: "5px",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        virtualScroller: {
          padding: "0px",
          backgroundColor: "#f4f4f4",
        },
        iconSeparator: {
          color: "#ffffff",
        },
        columnHeaders: {
          border: "0px",
          minHeight: "40px !important",
          maxHeight: "40px !important",
        },
        virtualScrollerContent: {},
        footerContainer: {
          maxHeight: "45px",
          minHeight: "30px",
        },
        editInputCell: {
          input: {
            padding: "13px 10px",
          },
        },
        columnHeaderTitle: {
          marginTop: "10px",
        },
        row: {
          "&.Mui-selected": {
            backgroundColor: "#ffffff",
            boxShadow: " 0px 0px 53px 31px rgba(125,125,125,0.39)",
            zIndex: 9999,
            "&:hover": {
              backgroundColor: "#ffffff",
            },
          },
        },
        cell: {
          borderBottom: "0px",
          "&:focus": {
            outline: "3px solid #f4f4f4",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {},
      },
    },
  },
});
