import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { useStyles } from "../../ClimateStyles";
import Leaves from "../../../../../assets/images/leaves.svg";
import * as Text from "../../../../../helpers/climateAccounting.json";
import BusinessTravel from "./BusinessTravel";
import TravelToFromWork from "./TravelToFromWork";
import PurchasedGoods from "./PurchasedGoods";
import PurchasedMaterials from "./PurchasedMaterials";
import InventoryEquipment from "./InventoryEquipment";
import RentedProducts from "./RentedProducts";
import SoldProducts from "./SoldProducts";
import ProductsRentedOut from "./ProductsRentedOut";
import Investments from "./Investments";
import ResidualMaterials from "./ResidualMaterials";
import WasteProduction from "./WasteProduction";
import Chemicals from "./Chemicals";
import {
  Finished,
  UnFinished,
  Download,
} from "../../../../../components/Icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ClimateAccountingPDF, {
  ClimateAccounting,
} from "../../../Documents/components/ClimateAccountingPDF";
import { updateClimateReport } from "../../climateFunctions";
import { useAuth } from "../../../../../auth/useAuth";
import useRouter from "../../../../../hooks/useRouter";
import amplitude from "amplitude-js";
import Replacement from "../../../../../assets/images/nocustomer_climate.svg";
import WaterConsumption from "./WaterConsumption";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Scope3(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();

  const [openDialog, setOpenDialog] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCompleteDocument = async () => {
    let newInfo = JSON.parse(props.report.infoData);

    let inputData = {
      id: props.report.id,
      status: "Completed",
      infoData: JSON.stringify(newInfo),
    };

    await updateClimateReport(inputData).then(() => {
      if (process.env.NODE_ENV !== "development") {
        amplitude.getInstance().logEvent("climate_report_completed");
      }

      router.push("/climate");
    });
  };

  const [openStates, setOpenStates] = useState(
    router.query.criteria
      ? [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ]
      : [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ]
  );

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      if (router.query.criteria) {
        if (router.query.criteria !== "Jobbreiser eksklusiv firma-kjøretøy") {
          changeOpen(0, false);
        }

        if (router.query.criteria !== "Transport til og fra jobb") {
          changeOpen(1, false);
        }

        if (router.query.criteria !== "Innkjøpt varetransport") {
          changeOpen(2, false);
        }

        if (router.query.criteria !== "Innkjøpte materialer") {
          changeOpen(3, false);
        }

        if (router.query.criteria !== "Innkjøp av inventar og utstyr") {
          changeOpen(4, false);
        }

        if (router.query.criteria !== "Innleide produkter") {
          changeOpen(5, false);
        }

        if (router.query.criteria !== "Solgte produkter") {
          changeOpen(6, false);
        }

        if (router.query.criteria !== "Utleie av produkter") {
          changeOpen(7, false);
        }

        if (router.query.criteria !== "Investeringer") {
          changeOpen(8, false);
        }

        if (router.query.criteria !== "Restmaterialer og restråvarer") {
          changeOpen(9, false);
        }

        if (router.query.criteria !== "Avfallsproduksjon") {
          changeOpen(10, false);
        }

        if (router.query.criteria !== "Kjemikalier") {
          changeOpen(11, false);
        }

        if (router.query.criteria !== "Vannforbruk") {
          changeOpen(12, false);
        }

        const findPosition = (obj) => {
          var currenttop = 0;
          if (obj.offsetParent) {
            do {
              currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
          }
        };

        if (document.getElementById(router.query.criteria)) {
          window.scrollTo(
            0,
            findPosition(document.getElementById(router.query.criteria))
          );
        }
      }
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ApplicableCheckBox = (check, handleCheck) => {
    return (
      <FormGroup sx={{ width: "125px" }}>
        <FormControlLabel
          sx={{ fontSize: "13px !important", margin: "0px" }}
          control={<Checkbox checked={!check} onChange={handleCheck} />}
          label="Ikke aktuelt"
        />
      </FormGroup>
    );
  };
  const CollapseColor = (isAnswered, open) => {
    if (isAnswered) {
      return "#7BE0CD";
    } else if (open) {
      return "#EBEBEB";
    } else {
      return "#FFFFFF";
    }
  };

  const CheckedColor = (isAnswered) => {
    if (isAnswered) {
      return "#7BE0CD";
    } else {
      return "#EBEBEB";
    }
  };

  const IconChange = (isAnswered) => {
    if (isAnswered) {
      return Finished;
    } else {
      return UnFinished;
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const changeOpen = (index, state) => {
    let newArr = openStates;

    newArr[index] = state;

    setOpenStates([...newArr]);
  };

  const openNext = (current) => {
    let newArr = openStates;

    newArr[current] = false;
    newArr[current + 1] = true;

    setOpenStates([...newArr]);
  };

  const downloadDocument = () => {
    if (process.env.NODE_ENV !== "development") {
      amplitude.getInstance().logEvent("climate_report_downloaded");
    }
  };

  const DialogPopUp = (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth="lg"
      TransitionComponent={Transition}
      fullWidth
    >
      <DialogTitle className={classes.popupTitle}>
        Sikker på at du er ferdig?
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={3}>
          <Grid
            item
            xs={6}
            sm={0}
            md={0}
            lg={0}
            className={classes.reportDownload}
          >
            {JSON.parse(auth.companyData.companyData.invoice).status !==
            "Trial" ? (
              <ClimateAccountingPDF
                report={props.report}
                companyName={auth.companyData.companyData.name}
              />
            ) : (
              <img src={Replacement} alt="replacement for none customers"></img>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography variant="body1">
              {Text.complete.p1}
              <br />
              <br />
              {Text.complete.p2}
              <br />
              <br />
              {Text.complete.p3}
            </Typography>
            <br />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label={
                  auth.companyData.companyData.name +
                  " bekrefter at opplysningene som gitt i denne rapporten er korrekte og tar avstand fra alle former for grønnvasking."
                }
              />
            </FormGroup>
            {JSON.parse(auth.companyData.companyData.invoice).status !==
            "Trial" ? (
              <PDFDownloadLink
                style={{ color: "#000", textDecoration: "none" }}
                document={
                  <ClimateAccounting
                    report={props.report}
                    companyName={auth.companyData.companyData.name}
                  />
                }
                fileName="Klimaregnskaprapport.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button
                      variant="contained"
                      disabled={true}
                      className={classes.popupButton}
                    >
                      Laster inn dokument...
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => downloadDocument(blob)}
                      className={classes.popupButton}
                    >
                      {Download} Last ned PDF
                    </Button>
                  )
                }
              </PDFDownloadLink>
            ) : (
              <Button
                variant="contained"
                onClick={() => router.push("/subscribe")}
                className={classes.popupButton}
              >
                Bli kunde for å laste ned dokument
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ width: "120px", height: "50px", backgroundColor: "#EBEBEB" }}
          autoFocus
          onClick={handleCloseDialog}
        >
          Lukk
        </Button>
        <Button
          variant="contained"
          sx={{ width: "150px", height: "50px", backgroundColor: "#C1E6EB" }}
          autoFocus
          onClick={handleCompleteDocument}
          disabled={checked ? false : true}
        >
          Fullfør
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Paper elevation={1}>
      <Grid item xs={12}>
        <Paper elevation={2} sx={{ display: "flex", flexDirection: "row" }}>
          <Grid item xs={2} className={classes.summaryContent}>
            <img
              src={Leaves}
              alt="Yellow and blue leaves"
              className="summaryImage"
            ></img>
          </Grid>
          <Grid item xs={10} className={classes.summaryDescription}>
            <Typography variant="body1">{Text.Scope3.Summary}</Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid>
        <Grid item xs={12}>
          <Typography id="transport" variant="h1" className={classes.title}>
            Transport
          </Typography>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              <BusinessTravel
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Jobbreiser eksklusiv firma-kjøretøy"}
                factorId={"Ekstern persontransport"}
                data={props.report.responses.items.find((response) => {
                  return (
                    response.questionId ===
                    "Jobbreiser eksklusiv firma-kjøretøy"
                  );
                })}
                index={0}
                open={openStates[0]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
              <TravelToFromWork
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Transport til og fra jobb"}
                factorId={"Ekstern persontransport"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Transport til og fra jobb";
                })}
                index={1}
                open={openStates[1]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
              <PurchasedGoods
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Innkjøpt varetransport"}
                factorId={"Ekstern varetransport"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Innkjøpt varetransport";
                })}
                index={2}
                open={openStates[2]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <Typography id="purchase" variant="h1" className={classes.title}>
            Innkjøp
          </Typography>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              <PurchasedMaterials
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Innkjøpte materialer"}
                factorId={"Materialer"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Innkjøpte materialer";
                })}
                index={3}
                open={openStates[3]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
              <InventoryEquipment
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Innkjøp av inventar og utstyr"}
                data={props.report.responses.items.find((response) => {
                  return (
                    response.questionId === "Innkjøp av inventar og utstyr"
                  );
                })}
                index={4}
                open={openStates[4]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
              <RentedProducts
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Innleide produkter"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Innleide produkter";
                })}
                index={5}
                open={openStates[5]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <Typography id="sale" variant="h1" className={classes.title}>
            Salg
          </Typography>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              <SoldProducts
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Solgte produkter"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Solgte produkter";
                })}
                index={6}
                open={openStates[6]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
              <ProductsRentedOut
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Utleie av produkter"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Utleie av produkter";
                })}
                index={7}
                open={openStates[7]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <Typography id="investments" variant="h1" className={classes.title}>
            Investeringer
          </Typography>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              <Investments
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Investeringer"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Investeringer";
                })}
                index={8}
                open={openStates[8]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <Typography id="residual" variant="h1" className={classes.title}>
            Restprodukter
          </Typography>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              <ResidualMaterials
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Restmaterialer og restråvarer"}
                data={props.report.responses.items.find((response) => {
                  return (
                    response.questionId === "Restmaterialer og restråvarer"
                  );
                })}
                index={9}
                open={openStates[9]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <Typography id="waste" variant="h1" className={classes.title}>
            Avfall
          </Typography>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              <WasteProduction
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Avfallsproduksjon"}
                factorId={"Avfall"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Avfallsproduksjon";
                })}
                index={10}
                open={openStates[10]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <Typography id="chemicals" variant="h1" className={classes.title}>
            Kjemikalier
          </Typography>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              <Chemicals
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Kjemikalier"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Kjemikalier";
                })}
                index={11}
                open={openStates[11]}
                changeOpen={changeOpen}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <Typography id="water" variant="h1" className={classes.title}>
            Vann
          </Typography>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              <WaterConsumption
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                ApplicableCheckBox={ApplicableCheckBox}
                updateReport={props.updateReport}
                reportId={props.report.id}
                questionId={"Vannforbruk"}
                data={props.report.responses.items.find((response) => {
                  return response.questionId === "Vannforbruk";
                })}
                index={12}
                open={openStates[12]}
                changeOpen={changeOpen}
                openNext={openNext}
                tasks={props.tasks}
                updateTasks={props.updateTasks}
                users={props.users}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Divider sx={{ margin: "30px 0px" }} />
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          sx={{ marginRight: "20px", backgroundColor: "#EBEBEB" }}
          onClick={scrollToTop}
          component={NavLink}
          to={"/climate/" + props.report.id + "/scope2"}
          exact
        >
          Tilbake
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "200px",
            marginRight: "20px",
            backgroundColor: "#FDDFB0",
          }}
          onClick={handleOpenDialog}
        >
          Fullfør klimaregnskapet
        </Button>
        {DialogPopUp}
      </Grid>
    </Paper>
  );
}
