import React, { useState, useEffect } from 'react';
import useToggle from '../../../../../hooks/useToggle';
import { Grid, Button, Divider, ListItem, ListItemButton, ListItemText, ListItemIcon, Paper } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputForm, { TextAreaInput } from '../../../../../components/InputForm';
import { s3Region, s3TemplateBucket } from '../../../../../helpers/globalValues';

import AWS from 'aws-sdk';
import { API, graphqlOperation } from 'aws-amplify';
import { updateTemplateFile, deleteTemplateFile } from '../../../../../graphql/mutations';

export default function TemplateItem({ showPopup, data, updateAllTemplates }) {
    const [open, setOpen] = useToggle();

    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');

    useEffect(() => {
        setTitle(data.title);
        setDesc(data.description);
    }, [data.title, data.description]);

    const myBucket = new AWS.S3({
        params: { Bucket: s3TemplateBucket },
        region: s3Region,
    });

    const updateTemplate = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateTemplateFile, {
                    input: {
                        id: data.id,
                        title: title,
                        description: desc,
                    },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated template file: ', data);
                }

                resolve();
            });
        });
    };

    const deleteTemplate = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(deleteTemplateFile, {
                    input: { id: data.id },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Deleted template file: ', data);
                }

                resolve();
            });
        });
    };

    const deleteFile = async fileKey => {
        return new Promise(async (resolve, reject) => {
            // Fetch saved credentials for authentication
            AWS.config.credentials.get(err => {
                if (err) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(AWS.config.credentials);
                        console.error(err);
                    }

                    reject(err);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(AWS.config.credentials);
                    }
                }

                const params = {
                    Bucket: s3TemplateBucket,
                    Key: fileKey,
                };

                // Deletes the file from the backend
                myBucket.deleteObject(params, (err, data) => {
                    if (err) {
                        if (process.env.NODE_ENV === 'development') {
                            console.error(err);
                        }

                        reject(err);
                    } else {
                        if (process.env.NODE_ENV === 'development') {
                            console.log(data);
                        }

                        resolve(data);
                    }
                });
            });
        });
    };

    const handleUpdate = async () => {
        if (title === '' || desc === '') {
            showPopup('Alle felter må være fylt ut!');
            return;
        }

        await updateTemplate().catch(() => {
            showPopup('En feil oppsto ved lagring. Last siden inn på nytt og prøv igjen.');
            return;
        });

        showPopup('Endringene har blitt lagret.');
        await updateAllTemplates();

        setOpen();
    };

    const handleDelete = async () => {
        await deleteFile(data.filePath + data.fileName).catch(() => {
            showPopup('En feil oppsto ved sletting av fil. Last siden inn på nytt og prøv igjen.');
            return;
        });

        await deleteTemplate().catch(() => {
            showPopup('En feil oppsto ved lagring. Last siden inn på nytt og prøv igjen.');
            return;
        });

        showPopup('Filen har blitt slettet.');
        await updateAllTemplates();
    };

    function getExtension(filename) {
        return filename.split('.').pop();
    }

    return (
        <Paper sx={{ padding: '20px', marginBottom: '10px' }}>
            <ListItem disablePadding>
                <ListItemButton onClick={setOpen}>
                    <ListItemIcon>
                        <DehazeIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            data.title + ' (' + data.fileName + ' - ' + getExtension(data.fileName).toLowerCase() + ')'
                        }
                    />
                </ListItemButton>
            </ListItem>
            {!open ? (
                <></>
            ) : (
                <Grid>
                    <Divider />
                    <Grid sx={{ margin: '15px' }}>
                        <Grid sx={{ marginBottom: '15px' }}>
                            <b>ID:</b>
                            <br /> {data.id}
                        </Grid>
                        <Grid sx={{ marginBottom: '15px' }}>
                            <InputForm
                                label="Tittel"
                                placeholder="Skriv inn tittel..."
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                            />
                        </Grid>
                        <Grid sx={{ marginBottom: '15px' }}>
                            <TextAreaInput
                                rows={4}
                                label="Beskrivelse"
                                placeholder="Skriv inn beskrivelse..."
                                value={desc}
                                onChange={e => setDesc(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ padding: '20px', margin: '10px', width: '200px' }}
                        onClick={handleUpdate}
                    >
                        Oppdater mal{' '}
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ padding: '20px', margin: '10px', width: '200px' }}
                        onClick={handleDelete}
                    >
                        Slett mal{' '}
                    </Button>
                    <Divider />
                </Grid>
            )}
        </Paper>
    );
}
