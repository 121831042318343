import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  Paper,
  InputBase,
  InputAdornment,
} from "@mui/material";
import { Coupon } from "../../../../components/Icons";
import { useStyles } from "../TermsStyle";
import { useAuth } from "../../../../auth/useAuth";
import useToggle from "../../../../hooks/useToggle";
import useRouter from "../../../../hooks/useRouter";
import SnackPopup from "../../../../components/SnackPopup";
import getDateFromTimestamp from "../../../../helpers/getDateFromTimestamp";
import {
  fetchDiscountCode,
  updateCompanyInvoiceEmail,
  updateCompanyToCustomer,
} from "../termsFunctions";
import { finishCertification } from "../../Certification/certificationFunctions.js";
import NewCustomer from "./NewCustomer";

export default function Trial() {
  const router = useRouter();
  const classes = useStyles();
  const auth = useAuth();
  const [isAcceptingTerms, setIsAcceptingTerms] = useToggle();

  const [isCompletingCert, setIsCompletingCert] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hasBecomeCustomer, setHasBecomeCustomer] = useState(false);

  const [discountCode, setDiscountCode] = useState("");
  const [discountExpDate, setDiscountExpDate] = useState("");
  const [hasDiscount, setHasDiscount] = useState(false);

  const [invoiceEmail, setInvoiceEmail] = useState("");
  const [invoiceExpDate, setInvoiceExpDate] = useState("");
  const [price, setPrice] = useState(250);

  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");

  const [popupState, setPopupState] = useToggle();
  const [popupMsg, setPopupMsg] = useState("");

  const showPopup = (message) => {
    setPopupMsg(message);
    setPopupState();
  };

  const handleClosePopup = () => {
    setPopupState();
  };

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      if (router.location && router.location.state) {
        setIsCompletingCert(router.location.state.certCompletion);
        setIsVisible(router.location.state.isVisible);
      }

      if (auth.companyData.companyData.invoice) {
        let invoiceData = JSON.parse(auth.companyData.companyData.invoice);

        if (invoiceData.status && invoiceData.status === "Subscribed") {
          router.push("/");
        } else {
          if (invoiceData.email) {
            setInvoiceEmail(invoiceData.email);
          }

          if (invoiceData.code) {
            checkAndApplyDiscountCode(invoiceData.code);
          }

          if (invoiceData.expDate) {
            let newExpDate = new Date(invoiceData.expDate);
            newExpDate.setMonth(newExpDate.getMonth() + 6);

            setInvoiceExpDate(newExpDate);
          }
        }
      }

      if (auth.companyData.companyData.address) {
        let addressData = JSON.parse(auth.companyData.companyData.address);

        setAddress(addressData.adresse[0]);
        setZipcode(addressData.postnummer);
        setState(addressData.kommune);
      }
    }

    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveInfo = async () => {
    setInvoiceEmail(invoiceEmail);

    await updateCompanyInvoiceEmail(invoiceEmail, auth);
  };

  const handleApplyDiscount = async () => {
    await checkAndApplyDiscountCode(discountCode);
  };

  const handleBecomeCustomer = async () => {
    if (!isAcceptingTerms) {
      showPopup("Du må godta prislisten og avtalen for å kunne fortsette.");
      return;
    }

    if (invoiceEmail === "") {
      showPopup("Du må ha en gyldig faktura epost for å fortsette!");
      return;
    }

    await handleSaveInfo();

    if (address === "" || zipcode === "" || state === "") {
      showPopup("Alle felter for adresse må være fylt ut.");
      return;
    }

    let invoiceObj = JSON.parse(auth.companyData.companyData.invoice);

    if (discountCode !== "" && discountExpDate !== "") {
      invoiceObj.discountCode = discountCode;
      invoiceObj.codeExpDate = discountExpDate;
    } else {
      invoiceObj.discountCode = null;
      invoiceObj.codeExpDate = null;
    }

    invoiceObj.startDate = new Date();
    invoiceObj.expDate = invoiceExpDate;
    invoiceObj.priceWithDiscount = price;
    invoiceObj.status = "Subscribed";
    invoiceObj.email = invoiceEmail;

    await updateCompanyToCustomer(invoiceObj, auth)
      .then(async () => {
        if (isCompletingCert) {
          await finishCertification(auth, isVisible).then(async () => {
            await auth.fetchCompanyInfo(auth.companyData.companyId).then(() => {
              router.push("/");
            });
          });
        } else {
          await auth.fetchCompanyInfo(auth.companyData.companyId).then(() => {
            setHasBecomeCustomer(true);
          });
        }
      })
      .catch(() => {
        showPopup(
          "En feil har oppstått ved abonnering. Vennligst prøv på nytt."
        );
      });
  };

  const checkAndApplyDiscountCode = async (code) => {
    await fetchDiscountCode(code)
      .then((data) => {
        if (data.length === 0) {
          showPopup("Koden er ikke gyldig. Prøv på nytt.");
        } else {
          setPrice(
            (price * ((100 - data[0].percentDiscount) / 100)).toFixed(0)
          );

          if (data[0].isTimeBased) {
            let invoiceData = JSON.parse(auth.companyData.companyData.invoice);
            let newExpDate = new Date(invoiceData.expDate);
            newExpDate.setMonth(
              newExpDate.getMonth() + data[0].durationInMonths
            );

            setDiscountExpDate(newExpDate);
          } else {
            setDiscountExpDate(data[0].codeExpDate);
          }

          setHasDiscount(true);
        }
      })
      .catch(() => {
        showPopup("Koden er ikke gyldig. Prøv på nytt.");
      });
  };

  const discountDisplay = () => {
    if (!hasDiscount) {
      return (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <InputBase
            id="discountCode"
            autoComplete="off"
            type="text"
            placeholder="Rabattkode..."
            sx={{ height: "50px" }}
            startAdornment={
              <InputAdornment position="start">{Coupon}</InputAdornment>
            }
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
          />
          <Button
            variant="contained"
            style={{ width: 120, marginLeft: 10, backgroundColor: "#E0F3F5" }}
            onClick={handleApplyDiscount}
          >
            Sjekk kode
          </Button>
        </Grid>
      );
    } else {
      return (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            className="text"
            sx={{ marginBottom: "5px", marginTop: "10px" }}
          >
            Koden er gyldig og prisen har blitt oppdatert.
          </Typography>
        </Grid>
      );
    }
  };

  if (hasBecomeCustomer) {
    return <NewCustomer />;
  } else if (auth.isAdmin) {
    return (
      <Grid item xs={12} className="content">
        <SnackPopup
          message={popupMsg}
          open={popupState}
          handleClose={handleClosePopup}
        />
        <Typography variant="h1">Bli kunde</Typography>
        <Typography className="text" sx={{ marginBottom: "10px" }}>
          Bli kunde i dag så kan dere bli synlige i Ecofric-indeksen, laste ned
          rapporter og måle progresjon over tid! Vi ruller ut nye funksjoner med
          gjevne mellomrom, og gleder oss til å vise dere alt vi jobber med.
        </Typography>
        <Box>
          <Paper>
            <Typography variant="h3">Avtale</Typography>
            <Grid>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Organisasjon</TableCell>
                      <TableCell>{auth.companyData.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pris</TableCell>
                      <TableCell>
                        {price},- per måned til{" "}
                        {getDateFromTimestamp(invoiceExpDate)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Adresse</TableCell>
                      <TableCell>
                        {address}, {zipcode} {state}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Faktura e-post</TableCell>
                      <TableCell sx={{ padding: "0px 5px" }}>
                        <InputBase
                          id="invoiceEmail"
                          type="text"
                          sx={{
                            width: "100%",
                            height: "50px",
                            backgroundColor: "#ffffff",
                          }}
                          placeholder={"Vennligst skriv inn faktura e-post..."}
                          value={invoiceEmail}
                          onChange={(e) => setInvoiceEmail(e.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>
        </Box>
        {discountDisplay()}
        <form autoComplete="off">
          <Grid item xs={12} style={{ margin: "10px 0px" }}>
            <FormControlLabel
              control={<Checkbox required />}
              checked={isAcceptingTerms}
              onChange={() => setIsAcceptingTerms()}
              label={
                <Typography variant="body2">
                  {" "}
                  Jeg godtar{" "}
                  <a
                    href="https://www.ecofric.com/prisliste/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#000000" }}
                  >
                    prislisten
                  </a>{" "}
                  og bekrefter at jeg kan inngå denne avtalen på vegne av{" "}
                  {auth.companyData.name}. Jeg forstår at Ecofric fakturerer for
                  6 måneder av gangen og at bindingstiden kun er ut inneværende
                  måned.
                </Typography>
              }
            />
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <Button
              variant="contained"
              className={classes.fiftyButton}
              onClick={handleBecomeCustomer}
            >
              Bli kunde
            </Button>
            <Button
              variant="contained"
              className={classes.greyButton}
              onClick={() => router.push("/")}
            >
              Avbryt
            </Button>
          </Grid>
        </form>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} className="content">
        <Typography variant="h1">Bli kunde</Typography>
        <Typography className="text" sx={{ marginBottom: "10px" }}>
          Fortsett å bruk Ecofric sine tjenester og ha muligheten til å bli
          sertifisert ved å bli kunde. Kontroller at informasjonen nedenfor
          stemmer.
        </Typography>
        <Box>
          <Paper>
            <Typography variant="h3">Avtale</Typography>
            <Grid>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Organisasjon</TableCell>
                      <TableCell>{auth.companyData.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pris</TableCell>
                      <TableCell>{price},-</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Addresse</TableCell>
                      <TableCell>
                        {address}, {zipcode} {state}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Faktura e-post</TableCell>
                      <TableCell>
                        {invoiceEmail !== "" ? (
                          invoiceEmail
                        ) : (
                          <>Epost for faktura er ikke satt.</>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>
        </Box>
        <form autoComplete="off">
          <Grid item xs={12} style={{ margin: "10px 0px" }}>
            <Typography variant="body1">
              Vennligst kontakt din admin om dere ønsker å bli kunde.
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <Button variant="contained" onClick={() => router.push("/")}>
              Gå tilbake
            </Button>
          </Grid>
        </form>
      </Grid>
    );
  }
}
