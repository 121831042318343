import { makeStyles } from "@mui/styles";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

export const useStyles = makeStyles((theme) => ({
  iconImg: {
    paddingRight: "10px",
    position: "relative",
    top: "-2px",
  },
  profileImage: {
    display: "flex",
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    backgroundColor: "#FEF0D9",
    color: "#000",
    fontSize: "40px",
    marginBottom: "20px",
    [breakpoints.down("lg")]: {
      width: "150px",
      height: "150px",
    },
  },
  companyImage: {
    width: "200px",
    height: "200px",
    borderRadius: "200px",
    backgroundColor: "#F9E5E8",
    color: "#000",
    fontSize: "40px",
    [breakpoints.down("lg")]: {
      width: "150px",
      height: "150px",
    },
  },
  hr: {
    border: "1px solid #F1F1F1",
  },
  top: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  spacer: {
    padding: "20px",
  },
  pageHeader: {
    justifyContent: "flex-start",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E2EFF1",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    padding: "10px 30px",
    position: "relative",
  },
  avatar: {
    backgroundColor: "#F3CCD1",
    fontSize: "14px",
    color: "#000000",
  },
  editbutton: {
    marginTop: "10px",
    marginRight: 10,
    width: 110,
    backgroundColor: "#FDDFB0",
  },
  editbutton2: {
    marginTop: "10px",
    marginRight: 10,
    width: 110,
    backgroundColor: "#F3CCD1",
  },
  editfield: {
    display: "flex",
    flexDirection: "row",
  },
  editlabel: {
    width: "120px",
    paddingTop: "20px",
    fontSize: "17px",
  },
  editinput: {
    padding: "5px 10px",
    fontSize: "15px",
  },
  rightSection: {
    borderRight: "3px solid rgb(253, 223, 176)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  leftSection: {
    height: "100%",
    position: "relative",
    padding: "0px 20px",
  },
  info: {
    margin: "10px 0px",
    borderBottom: "1px solid #EBEBEB",
  },
  backButton: {
    position: "absolute !important",
    left: "30px",
  },
  TableHeadLabel: {
    fontSize: "1.1em",
  },
  TableHeadSize: {
    width: "20%",
    fontSize: "1.1em",
  },
  profileheader: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#000000",
  },
  title: {
    textAlign: "left",
    [breakpoints.down("md")]: {
      fontSize: "33px",
    },
  },
}));
