import { Route, Redirect } from 'react-router-dom';
import React from 'react';

export default function PublicRoute({ children, auth, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !auth.isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
