import React, { useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';

const BenchmarkChart = props => {
    const ref = useRef();

    const data = {
        datasets: [
            {
                data: [props.points, props.total - props.points],
                backgroundColor: [props.color, '#373737'],
            },
        ],
        options: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom',
                },
                title: {
                    display: true,
                    text: 'Chart.js Doughnut Chart',
                },
            },
            animation: {
                duration: 0,
            },
        },
    };

    const config = {
        type: 'doughnut',
        data: data,
    };

    return (
        <Doughnut data={data} config={config} ref={ref} /> // Create the Bar graph element with data and options from above
    );
};

export default BenchmarkChart;
