import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import "../../../index.css";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

const OpenSansCondensed = "'Open Sans Condensed', sans-serif";
const OpenSans = "'Open Sans', sans-serif";

export const useStyles = makeStyles((theme) => ({
  //Chores list
  hiders: {
    backgroundColor: "#ffffff",
    width: "22px",
    height: "40px",
    position: "absolute",
    top: "0px",
    zIndex: 99,
  },
  formGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  hr2: {
    border: "2px solid #F3CCD1",
    width: "100%",
    margin: "3px 0px",
    display: "block",
  },
  chipInput: {
    padding: "0px",
    backgroundColor: "#ffffff",
    margin: "0px",
    boxShadow: "none",
    width: "145px",
    cursor: "pointer",
  },
  //New chore
  dialogTitle: {
    textAlign: "left",
    fontSize: "25px",
    backgroundColor: "#E2EFF1",
    marginBottom: "10px",
    padding: "10px 26px",
  },
  TabStyle: {
    padding: "0px",
    alignItems: "flex-start",
    marginRight: "10px",
    textDecoration: "underline",
  },
  TabStyle2: {
    padding: "0px",
    alignItems: "flex-start",
    textDecoration: "underline",
  },
  BoxStyle: {
    borderBottom: 1,
    borderColor: "divider",
    padding: "0px",
  },
  richEditGrid: {
    minHeight: "6em",
    cursor: "text",
    padding: "5px 15px 15px 15px",
    backgroundColor: "#F4F4F4",
    borderRadius: "5px",
    boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
  },
  section: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  startButton: {
    width: "200px",
    height: "50px",
    backgroundColor: "#FDDFB0",
  },
  imageSection: {
    padding: "10px 0px",
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
  //My chores
  header: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#E2EFF1",
  },
  deleteButton: {
    margin: "0px 10px 5px 0px",
    width: 130,
    backgroundColor: "#F3CCD1",
  },
  titles: {
    fontSize: 16,
    margin: "0px 10px 0px 0px",
  },
  descDivider: {
    border: "2px solid #FDDFB0",
    margin: "5px 0px 10px 0px",
  },
}));

export const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1em !important",
          background: "#f6f6f6",
          boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          width: "100%",
          border: "none",
          color: "#000000 !important",
          margin: "10px 0px",
          padding: "7px",
          fontFamily: OpenSansCondensed,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "none",
          padding: "0px",
          backgroundColor: "#ffffff",
          width: "40%",
          textDecoration: "underline",
          boxShadow: "none",
          color: "#000000",
          margin: "0px",
        },
        notchedOutline: {
          border: "none",
        },
        input: {
          padding: "0px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
  },
});

export const autoCompletetheme = createTheme({
  typography: {
    body1: {
      fontFamily: OpenSans,
      fontSize: "14px",
      fontWeight: 100,
    },
    body2: {
      fontFamily: OpenSansCondensed,
      fontSize: "14px",
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          backgroundColor: "#ffffff",
          boxShadow: "none",
          padding: "0px",
          fontFamily: OpenSansCondensed + "!important",
          fontSize: "1em",
          borderRadius: "5px",
          width: "100%",
          border: "none",
          color: "#000",
          '&&[class*="MuiInput-root"] $input': {
            padding: "12px 5px 12px !important",
            zIndex: "9999",
          },
        },
        input: {
          padding: "0px !important",
          "&::placeholder": {
            color: "#000000",
            opacity: 1,
          },
        },
        popper: {
          fontFamily: OpenSansCondensed,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1em !important",
          background: "#f6f6f6",
          boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          width: "100%",
          border: "none",
          color: "#000000 !important",
          margin: "10px 0px",
          padding: "0px",
          fontFamily: OpenSansCondensed,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "none",
          padding: "0px",
          backgroundColor: "#ffffff",
          textDecoration: "underline",
          boxShadow: "none",
          color: "#000000",
          margin: "0px",
        },
        notchedOutline: {
          border: "none",
        },
        input: {
          padding: "0px",
        },
      },
    },
  },
});
