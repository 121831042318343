import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { useStyles } from '../LoginStyle';
import { theme } from '../../PublicTheme.jsx';
import { ThemeProvider } from '@emotion/react';

export default function EmailConfirmed(props) {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.box} sx={{ backgroundColor: '#BBCBE4' }}>
                <Button variant="contained" disabled className={classes.topButton}>
                    Ny bruker
                </Button>
                <Grid item xs={12} className={classes.content}>
                    <Typography variant="h1" sx={{ textAlign: 'left', fontSize: '30px' }}>
                        Din epost har blitt bekreftet og du kan nå logge inn på portalen.
                    </Typography>
                    <Grid item xs={12} sx={{ paddingTop: '20px', textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={() => props.handleFinishConfirm()}
                            sx={{
                                backgroundColor: '#F3CCD1',
                            }}
                        >
                            Logg inn på portalen
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}
