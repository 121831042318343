import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import Leaves from "../../../../assets/images/leaves.svg";
import { useStyles } from "../CertificationStyles";

export default function Summary(props) {
  const classes = useStyles();
  const { data, responses } = props;
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0.0);
  const [pointsEarned, setPointsEarned] = useState(0.0);
  const [percentFinished, setPercentFinished] = useState(0);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      getAllValues();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAllValues();
  }, [responses]); // eslint-disable-line react-hooks/exhaustive-deps

  // Function that fetches and sets all required data
  const getAllValues = async () => {
    let questionAmount = 0;
    let totalPoints = 0;

    let questionsCompleted = 0;
    let points = 0;

    for (const subcategory of data.subcategories.items) {
      questionAmount = questionAmount + subcategory.questions.items.length;

      for (const question of subcategory.questions.items) {
        totalPoints = totalPoints + question.pointValue;

        let index = responses.findIndex(
          (response) => response.questionId === question.id
        );

        if (index !== -1) {
          if (responses[index].isAnswered) {
            questionsCompleted = questionsCompleted + 1;
          }

          if (responses[index].pointsEarned > question.pointValue) {
            points = points + question.pointValue;
          } else {
            points = points + responses[index].pointsEarned;
          }
        }
      }
    }

    let totalPercent = 100 / questionAmount;

    setTotalQuestions(questionAmount);
    setTotalPoints(totalPoints);

    setQuestionsAnswered(questionsCompleted);
    setPercentFinished(Math.ceil(totalPercent * questionsCompleted));
    setPointsEarned(points);
  };

  return (
    <Grid item xs={12}>
      <Paper elevation={2} sx={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={2}
          lg={2}
          className={classes.summaryContent}
        >
          <img
            src={Leaves}
            alt="Yellow and blue leaves"
            className={classes.summaryImage}
          ></img>
        </Grid>
        <Grid item xs={8} md={8} lg={7} className={classes.summaryDescription}>
          <Typography variant="body1">{data.description}</Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={3} className={classes.stats}>
          <Grid item xs={12} className={classes.statsBorder}>
            <Typography variant="body1" className={classes.summaryPoints}>
              Fullført: {percentFinished}%
            </Typography>
          </Grid>
          {data.id !== "company" ? (
            <Grid item xs={12} className={classes.statsBorder}>
              <Typography variant="body1" className={classes.summaryPoints}>
                Poengsum: {pointsEarned} av {totalPoints}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
          <Grid
            item
            xs={12}
            className={classes.statsBorder}
            sx={{ borderBottom: "none !important" }}
          >
            <Typography
              variant="body1"
              sx={{ padding: "20px 20px" }}
              className={classes.summaryPoints}
            >
              Spørsmål: {questionsAnswered}/{totalQuestions}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Typography variant="body2" sx={{ marginTop: "10px" }}>
        * betyr at spørsmålet er obligatorisk
        <br />
        (Valgfritt) trenger ikke besvares
      </Typography>
    </Grid>
  );
}
