import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Grid, Typography, Divider } from '@mui/material';
import Image1 from '../../../assets/images/pslide1.svg';
import Image2 from '../../../assets/images/pslide2.svg';
//import Image3 from "../../../assets/images/pslide3.svg";
import Image4 from '../../../assets/images/pslide4.svg';
import * as Text from '../../../helpers/tutorial.json';

export default function ProSlides() {
    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={10000}
            centerMode={false}
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024,
                    },
                    items: 1,
                },
                mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0,
                    },
                    items: 1,
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464,
                    },
                    items: 1,
                },
            }}
            showDots
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            <Grid container direction="row">
                <Grid item xs={6}>
                    <img
                        src={Image1}
                        alt="Temporary placeholder"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '87%',
                            marginLeft: 60,
                        }}
                    />
                </Grid>
                <Grid item xs={6} style={{ padding: '0px 78px 0px 20px' }}>
                    <Typography variant="h2">{Text.pslide1.title}</Typography>
                    <Divider />
                    <br />
                    <Typography style={{ fontSize: '14px' }}>{Text.pslide1.p1}</Typography>
                    <br />
                    <Typography style={{ fontSize: '14px' }}>{Text.pslide1.p2}</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xs={6}>
                    <img
                        src={Image2}
                        alt="Temporary placeholder"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '87%',
                            marginLeft: 60,
                        }}
                    />
                </Grid>
                <Grid item xs={6} style={{ padding: '0px 78px 0px 20px' }}>
                    <Typography variant="h2">{Text.pslide2.title}</Typography>
                    <Divider />
                    <br />
                    <Typography style={{ fontSize: '14px' }}>{Text.pslide2.p1}</Typography>
                    <br />
                    <Typography style={{ fontSize: '14px' }}>{Text.pslide2.p2}</Typography>
                    <br />
                    <Typography style={{ fontSize: '14px' }}>{Text.pslide2.p3}</Typography>
                    <br />
                    <Typography style={{ fontSize: '14px' }}>{Text.pslide2.p4}</Typography>
                </Grid>
            </Grid>
            {/*       <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image3}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.pslide3.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.pslide3.p1}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.pslide3.p2}
          </Typography>
        </Grid>
      </Grid> */}
            <Grid container direction="row">
                <Grid item xs={6}>
                    <img
                        src={Image4}
                        alt="Temporary placeholder"
                        style={{
                            display: 'block',
                            height: '100%',
                            margin: 'auto',
                            width: '87%',
                            marginLeft: 60,
                        }}
                    />
                </Grid>
                <Grid item xs={6} style={{ padding: '0px 78px 0px 20px' }}>
                    <Typography variant="h2">{Text.pslide4.title}</Typography>
                    <Divider />
                    <br />
                    <Typography style={{ fontSize: '14px' }}>{Text.pslide4.p1}</Typography>
                </Grid>
            </Grid>
        </Carousel>
    );
}
