import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, View, Document, StyleSheet, Font, Text, Image } from '@react-pdf/renderer';
import Leaf from '../../../../assets/images/leafs_yc.png';

Font.register({
    family: 'Yanone',
    src: 'https://fonts.gstatic.com/s/yanonekaffeesatz/v7/We_iSDqttE3etzfdfhuPRbq92v6XxU4pSv06GI0NsGc.ttf',
});
Font.register({
    family: 'OpenSansCondensed',
    src: 'https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xEMwSSh38KQVJx4ABtsZTnA.ttf',
});
Font.register({
    family: 'OpenSansCondensedItalic',
    src: 'https://fonts.gstatic.com/s/opensanscondensed/v10/jIXlqT1WKafUSwj6s9AzV4_LkTZ_uhAwfmGJ084hlvM.ttf',
});

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        padding: '20px',
        borderRight: '40px solid #F5F5F5',
        borderLeft: '40px solid #F5F5F5',
        position: 'relative',
    },
    page2: {
        backgroundColor: '#F5F5F5',
    },
    section: {
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        marginRight: 40,
        marginLeft: 40,
        padding: '20px',
    },
    h1: {
        fontFamily: 'Yanone',
        textAlign: 'center',
        marginTop: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontSize: 40,
        borderBottom: '5px solid #FDDFB0',
        fontWeight: 400,
        width: 400,
    },
    h2: {
        fontSize: '20px',
        textAlign: 'left',
        fontFamily: 'Yanone',
        borderBottom: '3px solid #FDDFB0',
        margin: '10px 0px',
    },
    h3: {
        fontSize: '16px',
        textAlign: 'left',
        fontFamily: 'Yanone',
        margin: '10px 0px 5px 0px',
        paddingBottom: '5px',
    },
    p: {
        fontFamily: 'OpenSans',
        fontSize: '10px',
        padding: '0px 0px 0px 0px',
        lineHeight: '1.8px',
        marginBottom: '10px',
    },
    date: {
        fontFamily: 'OpenSans',
        fontSize: '12px',
        marginTop: '20px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    toppBar: {
        height: '40px',
        backgroundColor: '#D7F5F0',
    },
    footer: {
        height: '40px',
        backgroundColor: '#D7F5F0',
        display: 'flex',
        alignItems: 'flex-end',
    },
    tableHead: {
        diplay: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        borderBottom: '1px solid #EBEBEB',
    },
    headCell: {
        fontFamily: 'OpenSansCondensedBold',
        fontSize: '11px',
        padding: '0px 5px',
    },
    bodyCell: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '11px',
        padding: '5px 5px',
    },
    bodyCell2: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '11px',
        padding: '5px 5px',
        borderLeft: '1px solid #EBEBEB',
    },
    tableBody: {
        diplay: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        borderBottom: '1px solid #EBEBEB',
    },
    tableBody2: {
        diplay: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        borderBottom: '1px solid #EBEBEB',
        backgroundColor: '#F3F3F3',
    },
    summary: {
        diplay: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        borderBottom: '1px solid #EBEBEB',
        borderTop: '2px solid #EBEBEB',
    },
    sumCell: {
        fontFamily: 'OpenSansCondensedBold',
        fontSize: '11px',
        padding: '10px 5px',
        borderBottom: '1px solid #EBEBEB',
        marginBottom: '2px',
    },
    sumCell2: {
        fontFamily: 'OpenSansCondensedBold',
        fontSize: '11px',
        padding: '10px 5px',
        borderLeft: '1px solid #EBEBEB',
        borderBottom: '1px solid #EBEBEB',
        marginBottom: '2px',
    },
    category: {
        borderBottom: '3px solid #EBEBEB',
    },
    catCell: {
        fontFamily: 'OpenSansCondensedBold',
        fontSize: '11px',
        padding: '10px 5px 0px 5px',
    },
    pageNb: {
        fontFamily: 'OpenSansCondensed',
        fontSize: '12px',
        padding: '10px 50px 0px 0px',
    },
    italic: {
        fontFamily: 'OpenSansCondensedItalic',
        fontSize: '11px',
        padding: '5px 0px 0px 0px',
    },
    columns: {
        display: 'flex',
        flexDirection: 'row',
    },
    leftColumn: {
        marginRight: '5px',
    },
    rightColumn: {
        marginLeft: '5px',
    },
});

// Create Document Component
export const ClimateAccounting = props => {
    const { report, companyName } = props;
    const responses = report.responses.items;

    const getScopeTotalEmission = scope => {
        if (responses.length === 0) {
            return 0;
        }

        let total = 0;

        for (const item of responses) {
            if (item.scope === scope) {
                let data = JSON.parse(item.data);

                if (data) {
                    for (const dataItem of data) {
                        if (dataItem && dataItem.emission) {
                            total = total + dataItem.emission;
                        }
                    }
                }
            }
        }

        return total;
    };

    const renderTotalEmission = totalEmission => {
        // If higher than 100, start displaying as ton instead of kg
        if (totalEmission >= 100) {
            let newTotalEmission = totalEmission / 1000;

            return newTotalEmission.toFixed(2) + ' tonn CO2e';
        } else {
            return totalEmission.toFixed(2) + ' kg CO2e';
        }
    };

    const getResponseEmission = questionId => {
        let response = responses.find(item => item.questionId === questionId);

        if (response) {
            let data = JSON.parse(response.data);

            if (data.length !== 0) {
                let total = 0;

                for (const dataItem of data) {
                    if (dataItem && dataItem.emission) {
                        total = total + dataItem.emission;
                    }
                }

                return total;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    const renderResponseEmission = totalEmission => {
        // If higher than 100, start displaying as ton instead of kg
        if (totalEmission >= 100) {
            let newTotalEmission = totalEmission / 1000;

            return newTotalEmission.toFixed(2) + ' tonn CO2e';
        } else {
            return totalEmission.toFixed(2) + ' kg CO2e';
        }
    };

    const displayKwt = questionId => {
        let response = responses.find(item => item.questionId === questionId);

        if (response) {
            let data = JSON.parse(response.data);

            if (data.length !== 0) {
                let total = 0;

                for (const dataItem of data) {
                    if (dataItem && dataItem.amountUsed) {
                        total = total + parseInt(dataItem.amountUsed);
                    }
                }

                return total;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    const displayDanger = dangers => {
        if (dangers.length === 1) {
            return dangers[0];
        } else if (dangers.length === 2) {
            return dangers[0] + ', ' + dangers[1];
        } else if (dangers.length === 3) {
            return dangers[0] + ', ' + dangers[1] + ', ' + dangers[2];
        }
    };

    const displayResponseData = questionId => {
        let response = responses.find(item => item.questionId === questionId);

        if (!response) {
            return <Text style={[styles.bodyCell, { width: '100%', textAlign: 'left' }]}>Ingen data.</Text>;
        } else {
            if (!response.isRelevant) {
                return (
                    <Text style={[styles.bodyCell, { width: '100%', textAlign: 'left' }]}>
                        Ikke relevant for selskapet.
                    </Text>
                );
            }

            let data = JSON.parse(response.data);

            let number = 0;

            if (data.length === 0) {
                return <Text style={[styles.bodyCell, { width: '100%', textAlign: 'left' }]}>Ingen data.</Text>;
            } else {
                return data.map((item, index) => {
                    number = number + 1;

                    if (
                        questionId === 'Innkjøpte materialer' ||
                        questionId === 'Innkjøp av inventar og utstyr' ||
                        questionId === 'Innleide produkter' ||
                        questionId === 'Solgte produkter' ||
                        questionId === 'Utleie av produkter'
                    ) {
                        return (
                            <View
                                style={number % 2 !== 0 ? styles.tableBody : styles.tableBody2}
                                wrap={false}
                                key={index}
                            >
                                <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                                    {item.factor}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                                    {item.amount.toString() + (item.type ? item.type : '')}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                                    {item.origin}
                                </Text>
                            </View>
                        );
                    } else if (questionId === 'Investeringer') {
                        return (
                            <View
                                style={number % 2 !== 0 ? styles.tableBody : styles.tableBody2}
                                wrap={false}
                                key={index}
                            >
                                <Text style={[styles.bodyCell, { width: '40%', textAlign: 'left' }]}>
                                    {item.company}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '40%', textAlign: 'left' }]}>
                                    {item.branch}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '30%', textAlign: 'left' }]}>
                                    {item.solution}
                                </Text>
                            </View>
                        );
                    } else if (questionId === 'Restmaterialer og restråvarer') {
                        return (
                            <View
                                style={number % 2 !== 0 ? styles.tableBody : styles.tableBody2}
                                wrap={false}
                                key={index}
                            >
                                <Text style={[styles.bodyCell, { width: '40%', textAlign: 'left' }]}>
                                    {item.factor}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '40%', textAlign: 'left' }]}>
                                    {item.amount.toString() + (item.type ? item.type : '')}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '30%', textAlign: 'left' }]}>
                                    {item.origin}
                                </Text>
                            </View>
                        );
                    } else if (questionId === 'Avfallsproduksjon') {
                        return (
                            <View
                                style={number % 2 !== 0 ? styles.tableBody : styles.tableBody2}
                                wrap={false}
                                key={index}
                            >
                                <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                                    {item.factor}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                                    {item.amount.toString() + (item.type ? item.type : '')}
                                </Text>
                            </View>
                        );
                    } else if (questionId === 'Kjemikalier') {
                        return (
                            <View
                                style={number % 2 !== 0 ? styles.tableBody : styles.tableBody2}
                                wrap={false}
                                key={index}
                            >
                                <Text style={[styles.bodyCell, { width: '30%', textAlign: 'left' }]}>
                                    {item.factor}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '20%', textAlign: 'left' }]}>
                                    {item.productName}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '10%', textAlign: 'left' }]}>
                                    {item.type ? item.type : 'Ingen type'}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '20%', textAlign: 'left' }]}>
                                    {displayDanger(item.dangers)}
                                </Text>
                                <Text style={[styles.bodyCell2, { width: '20%', textAlign: 'left' }]}>
                                    {item.contamination}
                                </Text>
                            </View>
                        );
                    } else {
                        return <></>;
                    }
                });
            }
        }
    };

    const displayStatement = () => {
        if (report.infoData) {
            let data = JSON.parse(report.infoData);

            if (data.statement && data.statement.length !== 0) {
                return data.statement;
            } else {
                return 'Ingen uttalelse skrevet.';
            }
        } else {
            return 'Ingen uttalelse skrevet.';
        }
    };

    return (
        <Document>
            <Page size="A4" style={styles.page2} wrap={false}>
                <View style={styles.toppBar}></View>
                <View style={styles.section}>
                    <Text
                        style={[
                            styles.h1,
                            {
                                borderBottom: 'none',
                                marginBottom: '0px',
                                fontSize: '30px',
                            },
                        ]}
                    >
                        {companyName}
                    </Text>
                    <Text style={[styles.h1, { marginTop: '0px' }]}>
                        {report.title !== '' ? report.title : 'Ingen tittel'}
                    </Text>
                    <Text style={styles.date}>Rapporteringsperiode: {report.year}</Text>
                    <Image
                        src={Leaf}
                        style={{
                            width: '30%',
                            marginTop: '150px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        }}
                    ></Image>
                </View>
                <View style={styles.footer}></View>
            </Page>
            <Page size="A4" style={styles.page2} wrap={false}>
                <View style={styles.toppBar}></View>
                <View style={styles.section}>
                    <Text style={[styles.h2, { marginTop: '0px' }]}>Om rapporten</Text>
                    <Text style={styles.p}>
                        Hensikten med denne rapporten er å vise oversikten over bedriften sine klimagassutslipp
                        (GHG-utslipp), som er en viktig del av bedriften sitt bærekraftsarbeid og rapportering på
                        bærekraft.
                    </Text>
                    <Text style={styles.p}>
                        Denne årlige rapporten gjør bedriften i stand til å måle nøkkeltall og dermed evaluere seg selv
                        over tid. Ecofric sitt forbruk- og klimaregnskap er basert på Greenhouse Gas Protocol (GHG), som
                        er den mest brukte og anerkjente standarden for rapportering av klimautslipp.
                    </Text>
                    <Text style={styles.p}>
                        Et klimaregnskap er et viktig verktøy i arbeidet med å få oversikt over bedriftens klimautslipp
                        og forbruk, og vise hvordan bedriftens aktiviteter påvirker klimaet og miljøet for å kunne jobbe
                        med realistiske målsetninger. For det vi kan måle, kan vi forbedre og ikke minst vise til ekte
                        progresjon.
                    </Text>
                    <Text style={styles.p}>Klimaregnskapet er delt i tre deler:</Text>
                    <Text style={styles.p}>- Scope 1 som er bedriftens direkte utslipp fra egne hovedaktiviteter.</Text>
                    <Text style={styles.p}>- Scope 2 som er indirekte utslipp fra strøm og vannforbruk.</Text>
                    <Text style={styles.p}>
                        - Scope 3 som er indirekte utslipp fra verdikjeden. Klimaregnskapsrapporten viser bedriftens
                        klimaavtrykk, forbruk og miljøavtrykk.
                    </Text>
                    <Text style={styles.p}>
                        Scope 3 har som hovedfokus å vise til forbruk. Det er per nå ikke alt innhold som direkte kan
                        konverteres til utslipp. Scope 3 viser utslipp fra ansattes transport til arbeidsplassen og
                        varetransport. Scope 3 synliggjør også bedriftens bruk av materialer og naturressurser fra både
                        innkjøp, produksjon, salg og avfallshåndtering.
                    </Text>
                    <Text style={styles.p}>
                        Utslippfaktorer brukt i klimaregnskapet er kg/tonn CO2e omregnet ihht AR5 i GWP gjennom GHG
                        Protocol.
                    </Text>
                    <Text style={[styles.h2, { marginTop: '0px' }]}>Kilder</Text>
                    <Text style={styles.p}>
                        Dette er et utdrag av kildene som brukes som grunnlag for dette klimaregnskapet, hvor de
                        viktigste kildene er inkludert her.
                    </Text>
                    <Text style={[styles.p, { textDecoration: 'underline' }]}>https://ghgprotocol.org/</Text>
                    <Text style={[styles.p, { textDecoration: 'underline' }]}>
                        https://ghgprotocol.org/sites/default/files/standards/Scope3_Calculation_Guidance_0.pdf
                    </Text>
                    <Text style={[styles.p, { textDecoration: 'underline' }]}>
                        https://www.miljodirektoratet.no/tjenester/klimagassutslipp-kommuner/beregne-effekt-av-ulike-klimatiltak/
                    </Text>
                    <Text style={[styles.p, { textDecoration: 'underline' }]}>
                        https://www.miljodirektoratet.no/ansvarsomrader/klima/for-myndigheter/kutte-utslipp-av-klimagasser/klima-og-energiplanlegging/tabeller-for-omregning-fra-energivarer-til-kwh/
                    </Text>
                </View>
                <View style={styles.footer}>
                    <Text
                        style={styles.pageNb}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <View fixed={true} style={[styles.toppBar, { margin: '-20px -80px 10px -80px' }]}></View>
                <Text style={[styles.h2, { marginTop: '0px' }]}>Oppsummering</Text>
                <Text style={styles.p}>Sum utslipp per scope:</Text>
                <View style={styles.table}>
                    <View style={styles.tableHead}>
                        <Text style={[styles.headCell, { width: '50%', textAlign: 'left' }]}>Scope</Text>
                        <Text style={[styles.headCell, { width: '50%', textAlign: 'left' }]}>utslipp</Text>
                    </View>
                    <View style={styles.tableBody} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                            Total Scope 1 - Egne direkte utslipp
                        </Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderTotalEmission(getScopeTotalEmission(1))}
                        </Text>
                    </View>
                    <View style={styles.tableBody2} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                            Total Scope 2 - Energi, varme og vannforbruk
                        </Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderTotalEmission(getScopeTotalEmission(2))}
                        </Text>
                    </View>
                    <View style={styles.tableBody} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                            Total Scope 3 - Indirekte utslipp
                        </Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderTotalEmission(getScopeTotalEmission(3))}
                        </Text>
                    </View>
                    <View style={styles.summary}>
                        <Text style={[styles.sumCell, { width: '50%', textAlign: 'left' }]}>Total:</Text>
                        <Text style={[styles.sumCell, { width: '50%', textAlign: 'left' }]}>
                            {renderTotalEmission(
                                getScopeTotalEmission(1) + getScopeTotalEmission(2) + getScopeTotalEmission(3),
                            )}
                        </Text>
                    </View>
                </View>
                <Text style={[styles.h2, {}]}>Scope 1 - Direkte</Text>
                <Text style={styles.p}>
                    Scope 1 inneholder områdene; oppvarming, egne kjøretøy og annet utslipp fra egne prosesser. Scope 1
                    omfatter alle direkte utslipp som bedriften generer innenfor forbrenning av fossilbasert gass for
                    oppvarmin av bygg, egen firmaeid transport eller utslipp fra indistruelle prosesser. Dette er
                    forbruk og utslipp som bedriften har direkte eierskap og kontroll over.
                </Text>
                <View style={styles.table}>
                    <View style={styles.tableHead}>
                        <Text style={[styles.headCell, { width: '50%', textAlign: 'left' }]}>Kriterie</Text>
                        <Text style={[styles.headCell, { width: '50%', textAlign: 'left' }]}>utslipp</Text>
                    </View>
                    <View style={styles.tableBody} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                            Oppvarming og fossil forbrenning
                        </Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(getResponseEmission('Forbrenning og oppvarming'))}
                        </Text>
                    </View>
                    <View style={styles.tableBody2} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                            Egne kjøretøy/maskiner og redskaper
                        </Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(getResponseEmission('Intern transport'))}
                        </Text>
                    </View>
                    <View style={styles.tableBody} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                            Utslipp av kjølegasser og andre gasser
                        </Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(getResponseEmission('(Kjøle)gasser'))}
                        </Text>
                    </View>
                    <View style={styles.summary}>
                        <Text style={[styles.sumCell, { width: '50%', textAlign: 'left' }]}>Total:</Text>
                        <Text style={[styles.sumCell, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(
                                getResponseEmission('Forbrenning og oppvarming') +
                                    getResponseEmission('Intern transport') +
                                    getResponseEmission('(Kjøle)gasser'),
                            )}
                        </Text>
                    </View>
                </View>
                <Text style={[styles.h2, {}]}>Scope 2 - Indirekte</Text>
                <Text style={styles.p}>
                    Scope 2 inneholder områdene; strøm, fjernvarme og vannforbruk, og omfatter indirekte utslipp fra
                    bedriftens forbruk kjøpt energi. Metoden som er brukt er markedsbasert strømmiks med og uten
                    opprinnelsesgaranti. Produserer dere fornybar strøm kan dere også inkludere det her som et +
                    regnskap, men det blir ikke trukket fra bedriftens klimautslipp.
                </Text>
                <View style={styles.table}>
                    <View style={styles.tableHead}>
                        <Text style={[styles.headCell, { width: '50%', textAlign: 'left' }]}>Kriterie</Text>
                        <Text style={[styles.headCell, { width: '50%', textAlign: 'left' }]}>utslipp</Text>
                        <Text style={[styles.headCell, { width: '20%', textAlign: 'left' }]}>antall</Text>
                    </View>
                    <View style={styles.tableBody} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>Strøm og fjernvarme</Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(getResponseEmission('Strøm og fjernvarme'))}
                        </Text>
                        <Text style={[styles.bodyCell, { width: '20%', textAlign: 'left' }]}>
                            {displayKwt('Strøm og fjernvarme')} kWt
                        </Text>
                    </View>
                    <View style={styles.summary}>
                        <Text style={[styles.sumCell, { width: '50%', textAlign: 'left' }]}>Total:</Text>
                        <Text style={[styles.sumCell, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(getResponseEmission('Strøm og fjernvarme'))}
                        </Text>
                        <Text style={[styles.sumCell, { width: '20%', textAlign: 'left' }]}>
                            {displayKwt('Strøm og fjernvarme')} kWt
                        </Text>
                    </View>
                </View>
                <Text style={[styles.h2, {}]} break>
                    Scope 3 - utslipp
                </Text>
                <Text style={styles.p}>
                    Scope 3 - utslipp omfatter indirekte utslipp fra verdikjeden, inkludert både oppstrømsaktiviteter og
                    nedstrømsaktiviteter. Oppstrømsaktiviteter og utslipp inkluderer; ansattes reiser til og fra jobb,
                    kjøpt transport og distribusjon.
                </Text>
                <View style={styles.table}>
                    <View style={styles.tableHead}>
                        <Text style={[styles.headCell, { width: '50%', textAlign: 'left' }]}>Kriterie</Text>
                        <Text style={[styles.headCell, { width: '50%', textAlign: 'left' }]}>utslipp</Text>
                    </View>
                    <View style={styles.tableBody} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                            Jobbreiser eksklusiv firma-kjøretøy
                        </Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(getResponseEmission('Jobbreiser eksklusiv firma-kjøretøy'))}
                        </Text>
                    </View>
                    <View style={styles.tableBody2} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                            Transport til og fra jobb
                        </Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(getResponseEmission('Transport til og fra jobb'))}
                        </Text>
                    </View>
                    <View style={styles.tableBody} wrap={false}>
                        <Text style={[styles.bodyCell, { width: '50%', textAlign: 'left' }]}>
                            Innkjøpt varetransport
                        </Text>
                        <Text style={[styles.bodyCell2, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(getResponseEmission('Innkjøpt varetransport'))}
                        </Text>
                    </View>
                    <View style={styles.summary}>
                        <Text style={[styles.sumCell, { width: '50%', textAlign: 'left' }]}>Total:</Text>
                        <Text style={[styles.sumCell, { width: '50%', textAlign: 'left' }]}>
                            {renderResponseEmission(
                                getResponseEmission('Jobbreiser eksklusiv firma-kjøretøy') +
                                    getResponseEmission('Transport til og fra jobb') +
                                    getResponseEmission('Innkjøpt varetransport'),
                            )}
                        </Text>
                    </View>
                </View>
                <Text style={[styles.h2, {}]}>Scope 3 - forbruk</Text>
                <Text style={styles.p}>
                    Scope 3 - forbruk omfatter all forbruk av naturressurser gjennom verdikjeden, både fra
                    oppstrømsaktiviteter som innkjøp og innleie av materialer, varer og tjenester. Nedstrømsaktiviteter
                    inkluderer alle solgte eller utleide varer og tjenester, kjemikalier i bruk og sluttbehandling av
                    solgte produkter (avfall eller nye kretsløp).
                </Text>
                <View style={styles.table}>
                    <View style={styles.category}>
                        <Text style={[styles.catCell, { width: '100%', textAlign: 'left' }]}>
                            Innkjøp - Innkjøpte materialer
                        </Text>
                    </View>
                    {displayResponseData('Innkjøpte materialer')}
                    <View style={styles.category}>
                        <Text style={[styles.catCell, { width: '100%', textAlign: 'left' }]}>
                            Innkjøp - Innkjøp av inventar og utstyr
                        </Text>
                    </View>
                    {displayResponseData('Innkjøp av inventar og utstyr')}
                    <View style={styles.category}>
                        <Text style={[styles.catCell, { width: '100%', textAlign: 'left' }]}>
                            Innkjøp - Innleide produkter
                        </Text>
                    </View>
                    {displayResponseData('Innleide produkter')}
                    <View style={styles.category}>
                        <Text style={[styles.catCell, { width: '100%', textAlign: 'left' }]}>
                            Salg - Solgte produkter
                        </Text>
                    </View>
                    {displayResponseData('Solgte produkter')}
                    <View style={styles.category}>
                        <Text style={[styles.catCell, { width: '100%', textAlign: 'left' }]}>
                            Salg - Utleie av produkter
                        </Text>
                    </View>
                    {displayResponseData('Utleie av produkter')}
                    <View style={styles.category}>
                        <Text style={[styles.catCell, { width: '100%', textAlign: 'left' }]}>Investeringer</Text>
                    </View>
                    {displayResponseData('Investeringer')}
                    <View style={styles.category}>
                        <Text style={[styles.catCell, { width: '100%', textAlign: 'left' }]}>
                            Restprodukter - Restmaterialer og restråvarer
                        </Text>
                    </View>
                    {displayResponseData('Restmaterialer og restråvarer')}
                    <View style={styles.category}>
                        <Text style={[styles.catCell, { width: '100%', textAlign: 'left' }]}>
                            Avfall - Avfallsproduksjon
                        </Text>
                    </View>
                    {displayResponseData('Avfallsproduksjon')}
                    <View style={styles.category}>
                        <Text style={[styles.catCell, { width: '100%', textAlign: 'left' }]}>Kjemikalier</Text>
                    </View>
                    {displayResponseData('Kjemikalier')}
                </View>
                <View fixed={true} style={{ flexGrow: 1, backgroundColor: '#ffffff' }}></View>
                <View
                    fixed={true}
                    style={[
                        styles.footer,
                        {
                            margin: '10px -80px -20px -80px',
                            position: 'fixed',
                            bottom: '0px',
                            left: '0px',
                        },
                    ]}
                >
                    <Text
                        style={styles.pageNb}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
            <Page size="A4" style={styles.page2} wrap={false}>
                <View style={styles.toppBar}></View>
                <View style={styles.section}>
                    <Text style={[styles.h2, { marginTop: '0px' }]}>Uttalelse</Text>
                    <Text style={styles.p}>{displayStatement()}</Text>
                    <Text style={[styles.h2, { marginTop: '0px' }]}>Bekreftelse</Text>
                    <Text style={styles.p}>
                        {companyName} bekrefter at opplysningene som gitt i denne rapporten er korrekte og tar avstand
                        fra alle former for grønnvasking. Ecofric og annen tredjepart gjennomgår og kontrollerer{' '}
                        {companyName} sine resultater, og Ecofric og annen tredjepart kan anmode om revisjoner eller
                        dokumentasjon for informasjonen som er lagt inn.
                    </Text>
                    {/* <Text style={[styles.h2, { marginTop: '0px' }]}>Signering</Text>
                    <View style={styles.columns}>
                        <View style={[styles.leftColumn, { width: '50%' }]}>
                            <Text style={[styles.italic, { marginBottom: '80px' }]}>Dato:</Text>
                            <Svg height="10" width="250">
                                <Line x1="0" y1="0" x2="230" y2="0" strokeWidth={5} stroke="#FDDFB0" />
                            </Svg>
                            <Svg height="10" width="250">
                                <Line x1="0" y1="0" x2="230" y2="0" strokeWidth={3} stroke="#EBEBEB" />
                            </Svg>
                            <Text style={[styles.italic, { fontSize: '14px' }]}>, Daglig leder</Text>
                            <Text style={styles.italic}></Text>
                            <Text style={styles.italic}>+47</Text>
                        </View>
                        <View style={[styles.rightColumn, { width: '50%' }]}>
                            <Text style={[styles.italic, { marginBottom: '80px' }]}>Dato:</Text>
                            <Svg height="10" width="250">
                                <Line x1="0" y1="0" x2="230" y2="0" strokeWidth={5} stroke="#FDDFB0" />
                            </Svg>
                            <Svg height="10" width="250">
                                <Line x1="0" y1="0" x2="230" y2="0" strokeWidth={3} stroke="#EBEBEB" />
                            </Svg>
                            <Text style={[styles.italic, { fontSize: '14px' }]}>, Styrets leder</Text>
                            <Text style={styles.italic}></Text>
                            <Text style={styles.italic}>+47</Text>
                        </View>
                    </View> */}
                    <Image
                        src={Leaf}
                        style={{
                            width: '30%',
                            marginTop: '40px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        }}
                    ></Image>
                </View>
                <View style={styles.footer}>
                    <Text
                        style={styles.pageNb}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );
};

export default function ClimateAccountingPDF(props) {
    return (
        <>
            <PDFViewer width="100%" height="730">
                <ClimateAccounting report={props.report} companyName={props.companyName} />
            </PDFViewer>
        </>
    );
}
