import React from "react";
import { Typography, Grid, Paper } from "@mui/material";
import BenchmarkChart from "../../../private/Certification/components/BenchmarkChart";
import {
  Environment,
  Profit,
  Social,
  Industry,
} from "../../../../components/Icons";
import { useStyles } from "../IndexStyles";

export default function Graphs(props) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={3}>
        <Paper>
          <Typography variant="h3" sx={{ backgroundColor: "#BBCBE4" }}>
            {Profit}Økonomi og drift
          </Typography>
          <Grid item style={{ padding: "20px 40px" }}>
            <BenchmarkChart
              color={"#BBCBE4"}
              total={100}
              points={props.data.economyPercentScore}
            />
            <br />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              Organisasjonen har oppnådd:
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              {props.data.economyPercentScore}% av 100%
            </Typography>
            <br />
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Her vil det kartlegges hvordan bedriften styres i dag og status på
              dagens bærekraftsarbeid. Kartleggingen gir innsikt i risikoer,
              utfordringer og muligheter med formål om å kunne identifisere
              fremtidige forbedringsområder og tiltak. God selskapsstyring er
              nødvendig for utvikling i en retning til det beste for både eiere,
              miljø og samfunn.
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper>
          <Typography variant="h3" sx={{ backgroundColor: "#F3CCD1" }}>
            {Social}Sosiale forhold
          </Typography>
          <Grid item style={{ padding: "20px 40px" }}>
            <BenchmarkChart
              color={"#F3CCD1"}
              total={100}
              points={props.data.socialPercentScore}
            />
            <br />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              Organisasjonen har oppnådd:
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              {props.data.socialPercentScore}% av 100%
            </Typography>
            <br />
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Gjennom å kartlegge sosiale forhold i bedriften og hvordan den
              ivaretar ansatte utover pålagte krav, får man innsikt i hva
              bedriften gjør for ansatte, lokalsamfunnet og andre berørte i
              verdikjeden. Dette synliggjør for bedriften hva de tar ansvar for
              i sine omgivelser, kan bidra til nye løsninger for sosiale
              forhold, samt bygge sterkere lokalsamfunn.
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper>
          <Typography variant="h3" sx={{ backgroundColor: "#C3E8D0" }}>
            {Environment}Miljø og klima
          </Typography>
          <Grid item style={{ padding: "20px 40px" }}>
            <BenchmarkChart
              color={"#C3E8D0"}
              total={100}
              points={props.data.environmentalPercentScore}
            />
            <br />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              Organisasjonen har oppnådd:
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              {props.data.environmentalPercentScore}% av 100%
            </Typography>
            <br />
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Hensikten er å kartlegge bedriftens negative økologiske
              fotavtrykk, identifisere tiltak som bidrar til å minimere disse,
              samt redusere sløsing. Bedriften må få oversikt og kontroll over
              eget forbruk, utslipp og negative økologiske fotavtrykk for å
              kunne jobbe mot gode løsninger. Målet å ta vare på natur, klima og
              jorden som fornybar ressurs.
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper>
          <Typography variant="h3" sx={{ backgroundColor: "#CEBEDD" }}>
            {Industry}Bransjespesifikke kriterier
          </Typography>
          <Grid item style={{ padding: "20px 40px", position: "relative" }}>
            <Grid className={classes.climateContent}>
              <Typography variant="h2">Kommer snart!</Typography>
            </Grid>
            <BenchmarkChart color={"#CEBEDD"} total={100} points={0} />
            <br />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              Organisasjonen har oppnådd:
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              0% av 100%
            </Typography>
            <br />
            <Typography
              variant="body1"
              sx={{ textAlign: "center" }}
            ></Typography>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
