import React, { useState, useEffect } from "react";
import { Grid, Hidden } from "@mui/material";
import AssignedCertification from "./components/AssignedCertification";
import Modules from "./components/Modules";
import Chores from "./components/Chores";
import { useAuth } from "../../../auth/useAuth";
import { ContentLoading } from "../../../components/Loading";

import {
  fetchCompanyUsers,
  fetchAllCompanyTasks,
  fetchFilteredCategories,
} from "../Chores/choresFunctions";

export default function Dashboard() {
  const auth = useAuth();

  const [tasks, setTasks] = useState(null);
  const [criterias, setCriterias] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      fetchAllCompanyTasks(auth.companyData.companyId).then((data) => {
        setTasks([...data]);
      });

      fetchCompanyUsers(auth.companyData.companyId).then((data) => {
        setUsers([...data]);
      });

      fetchFilteredCategories(auth).then((data) => {
        setCriterias([...data]);
      });
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateTasks = async () => {
    fetchAllCompanyTasks(auth.companyData.companyId).then((data) => {
      setTasks([...data]);
    });
  };

  if (users && criterias && tasks) {
    return (
      <Grid container spacing={3}>
        <Hidden lgUp>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <AssignedCertification />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Chores
              tasks={tasks}
              updateTasks={updateTasks}
              users={users}
              criterias={criterias}
            />
          </Grid>
        </Hidden>
        <Hidden lgDown>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Chores
              tasks={tasks}
              updateTasks={updateTasks}
              users={users}
              criterias={criterias}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <AssignedCertification />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Modules />
        </Grid>
      </Grid>
    );
  } else {
    return <ContentLoading />;
  }
}
