import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import MyChores from './components/MyChores';
import NewChore from './components/NewChore';
import { useAuth } from '../../../auth/useAuth';
import { ContentLoading } from '../../../components/Loading';

import { fetchCompanyUsers, fetchAllCompanyTasks, fetchFilteredCategories } from './choresFunctions';

export default function Chores() {
    const auth = useAuth();

    const [tasks, setTasks] = useState(null);
    const [criterias, setCriterias] = useState(null);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            fetchAllCompanyTasks(auth.companyData.companyId).then(data => {
                setTasks([...data]);
            });

            fetchCompanyUsers(auth.companyData.companyId).then(data => {
                setUsers([...data]);
            });

            fetchFilteredCategories(auth).then(data => {
                setCriterias([...data]);
            });
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateTasks = async () => {
        fetchAllCompanyTasks(auth.companyData.companyId).then(data => {
            setTasks([...data]);
        });
    };

    if (users && criterias && tasks) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <NewChore updateTasks={updateTasks} users={users} criterias={criterias} />
                </Grid>
                <Grid item xs={12}>
                    <MyChores tasks={tasks} updateTasks={updateTasks} users={users} criterias={criterias} />
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        );
    } else {
        return <ContentLoading />;
    }
}
