import React, { useEffect } from 'react';
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox, InputBase } from '@mui/material';

export default function MultipleChoice(props) {
    const {
        data,
        answers,
        setAnswers,
        otherField,
        setOtherField,
        pointsEarned,
        setPointsEarned,
        setIsAnswered,
        minChars,
        maxChars,
    } = props;

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (answers.length >= 1) {
                let otherOptionCheck = false;

                for (const answer of answers) {
                    let newOption = data.find(item => {
                        return item.id === answer;
                    });

                    if (newOption && newOption.isOtherOption) {
                        otherOptionCheck = true;
                    }
                }

                if (otherOptionCheck) {
                    if (otherField.length >= minChars && otherField.length !== 0) {
                        setIsAnswered(true);
                    } else {
                        setIsAnswered(false);
                    }
                }
            }
        }

        return () => {
            isMounted = true;
        };
    }, [otherField, answers]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectOption = option => {
        let newArr = answers;

        // Handle points and removal/addition of answers
        if (newArr.includes(option.id)) {
            let index = newArr.indexOf(option.id);
            newArr.splice(index, 1);
            subtractPoints(option.pointValue);

            if (option.isOtherOption) {
                setOtherField('');
            }
        } else {
            newArr.push(option.id);
            addPoints(option.pointValue);
        }

        if (newArr.length > 0) {
            // Check if the set of answers contains the option with 'other' field enabled
            let otherOptionCheck = false;

            for (const answer of answers) {
                let newOption = data.find(item => {
                    return item.id === answer;
                });

                if (newOption.isOtherOption) {
                    otherOptionCheck = true;
                }
            }

            // Set question as answered depending on text requirements for 'other' field
            if (otherOptionCheck && answers.length === 1) {
                if (otherField.length >= minChars && otherField.length !== 0) {
                    setIsAnswered(true);
                } else {
                    setIsAnswered(false);
                }
            } else {
                setIsAnswered(true);
            }
        } else {
            setIsAnswered(false);
        }

        setAnswers([...newArr]);
    };

    const addPoints = points => {
        setPointsEarned(pointsEarned + points);
    };

    const subtractPoints = points => {
        if (pointsEarned - points < 0) {
            setPointsEarned(0);
        } else {
            setPointsEarned(pointsEarned - points);
        }
    };

    if (data.length > 0) {
        return (
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormGroup>
                    {data &&
                        data
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map(option => {
                                return (
                                    <Grid key={option.id}>
                                        <FormControlLabel
                                            style={{ margin: '8px 0px' }}
                                            control={
                                                <Checkbox checked={answers.includes(option.id)} name={option.option} />
                                            }
                                            label={option.option}
                                            onChange={() => handleSelectOption(option)}
                                        />
                                        {option.isOtherOption &&
                                        option.isOtherOption === true &&
                                        answers.includes(option.id) ? (
                                            <InputBase
                                                sx={{ padding: '5px' }}
                                                id={option.id}
                                                type="text"
                                                className="input"
                                                name={option.option}
                                                value={otherField}
                                                onChange={e => setOtherField(e.target.value)}
                                                placeholder="Skriv her..."
                                                inputProps={{ maxLength: maxChars }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                );
                            })}
                </FormGroup>
            </FormControl>
        );
    } else {
        return <Grid>Spørsmålet har ingen valgalternativer.</Grid>;
    }
}
