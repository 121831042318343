import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useRouter from "../../hooks/useRouter.jsx";
import CertifySlide from "./Tutorial/Certification";
import DashSlide from "./Tutorial/Dashboard.jsx";
import ProSlides from "./Tutorial/Profile.jsx";
import DocRepSlide from "./Tutorial/DocumentReport.jsx";
import MarkSlide from "./Tutorial/Marketing.jsx";
import ClimateSlide from "./Tutorial/Climate.jsx";
import ActivitiesSlide from "./Tutorial/Activities.jsx";
import NSRS from "./Tutorial/NSRS.jsx";
import NSRSModule from "./Tutorial/NSRSModule.jsx";
import NSRSInfo from "./Tutorial/NSRSInfo";
import NSRSProfile from "./Tutorial/NSRSProfile";
import NSRSEconomy from "./Tutorial/NSRSEconomy";
import NSRSSocial from "./Tutorial/NSRSSocial";
import NSRSEnvironment from "./Tutorial/NSRSEnvironment";
import NSRSRiskOpportunity from "./Tutorial/NSRSRiskOpportunity";
import useLocalStorage from "../../hooks/useLocalStorage";
import amplitude from "amplitude-js";

const useStyles = makeStyles((theme) => ({
  carousel: {},
  Button: {
    width: 90,
    backgroundColor: "#C1E6EB",
    display: "flex",
    alignItems: "center",
  },
  title: {
    padding: "16px",
    fontSize: "30px",
  },
}));

export default function Tutorial(props) {
  const classes = useStyles();
  const router = useRouter();

  // Query for NSRS id
  const { id } = router.query;

  const [open, setOpen] = useState(false);

  const [dashOnePopup, setDashOnePopup] = useLocalStorage("DashOnePopup", true);
  const [dashTwoPopup, setDashTwoPopup] = useLocalStorage("DashTwoPopup", true);
  const [profilePopup, setProfilePopup] = useLocalStorage("ProfilePopup", true);
  const [activitiesPopup, setActivitiesPopup] = useLocalStorage(
    "ActivitiesPopup",
    true
  );
  const [documentationPopup, setDocumentationPopup] = useLocalStorage(
    "DocumentationPopup",
    true
  );
  const [mediakitPopup, setMediakitPopup] = useLocalStorage(
    "MediakitPopup",
    true
  );
  const [climatePopup, setClimatePopup] = useLocalStorage("ClimatePopup", true);
  const [nsrsPopup, setNsrsPopup] = useLocalStorage("nsrsPopup", true);
  const [nsrsModulePopup, setNsrsModulePopup] = useLocalStorage(
    "nsrsModulePopup",
    true
  );
  const [nsrsInfoPopup, setNsrsInfoPopup] = useLocalStorage(
    "nsrsInfoPopup",
    true
  );
  const [nsrsProfilePopup, setNsrsProfilePopup] = useLocalStorage(
    "nsrsProfilePopup",
    true
  );
  const [nsrsEconomyPopup, setNsrsEconomyPopup] = useLocalStorage(
    "nsrsEconomyPopup",
    true
  );
  const [nsrsSocialPopup, setNsrsSocialPopup] = useLocalStorage(
    "nsrsSocialPopup",
    true
  );
  const [nsrsEnvironmentPopup, setNsrsEnvironmentPopup] = useLocalStorage(
    "nsrsEnvironmentPopup",
    true
  );
  const [nsrsRiskOppPopup, setNsrsRiskOppPopup] = useLocalStorage(
    "nsrsRiskOppPopup",
    true
  );

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      if (router.pathname === "/") {
        setOpen(dashTwoPopup);
      } else if (router.pathname === "/certification") {
        setOpen(dashOnePopup);
      } else if (router.pathname === "/profile") {
        setOpen(profilePopup);
      } else if (router.pathname === "/activities") {
        setOpen(activitiesPopup);
      } else if (router.pathname === "/documents") {
        setOpen(documentationPopup);
      } else if (router.pathname === "/mediakit") {
        setOpen(mediakitPopup);
      } else if (router.pathname === "/climate") {
        setOpen(climatePopup);
      } else if (router.pathname === "/nsrs") {
        setOpen(nsrsPopup);
      } else if (router.pathname === "/nsrs/" + id) {
        setOpen(nsrsModulePopup);
      } else if (router.pathname === "/nsrs/" + id + "/information") {
        setOpen(nsrsInfoPopup);
      } else if (router.pathname === "/nsrs/" + id + "/company") {
        setOpen(nsrsProfilePopup);
      } else if (router.pathname === "/nsrs/" + id + "/economy") {
        setOpen(nsrsEconomyPopup);
      } else if (router.pathname === "/nsrs/" + id + "/social") {
        setOpen(nsrsSocialPopup);
      } else if (router.pathname === "/nsrs/" + id + "/climate") {
        setOpen(nsrsEnvironmentPopup);
      } else if (router.pathname === "/nsrs/" + id + "/risk") {
        setOpen(nsrsRiskOppPopup);
      }
    }

    return () => {
      isMounted = true;
    };
  }, [router.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickOpen = () => {
    setOpen(true);

    if (process.env.NODE_ENV !== "development") {
      amplitude
        .getInstance()
        .logEvent("user_clicked_help", { currentPath: router.pathname });
    }
  };

  const handleClose = () => {
    if (router.pathname === "/") {
      setDashTwoPopup(false);
    } else if (router.pathname === "/certification") {
      setDashOnePopup(false);
    } else if (router.pathname === "/profile") {
      setProfilePopup(false);
    } else if (router.pathname === "/activities") {
      setActivitiesPopup(false);
    } else if (router.pathname === "/documents") {
      setDocumentationPopup(false);
    } else if (router.pathname === "/mediakit") {
      setMediakitPopup(false);
    } else if (router.pathname === "/climate") {
      setClimatePopup(false);
    } else if (router.pathname === "/nsrs") {
      setNsrsPopup(false);
    } else if (router.pathname === "/nsrs/" + id) {
      setNsrsModulePopup(false);
    } else if (router.pathname === "/nsrs/" + id + "/information") {
      setNsrsInfoPopup(false);
    } else if (router.pathname === "/nsrs/" + id + "/company") {
      setNsrsProfilePopup(false);
    } else if (router.pathname === "/nsrs/" + id + "/economy") {
      setNsrsEconomyPopup(false);
    } else if (router.pathname === "/nsrs/" + id + "/social") {
      setNsrsSocialPopup(false);
    } else if (router.pathname === "/nsrs/" + id + "/climate") {
      setNsrsEnvironmentPopup(false);
    } else if (router.pathname === "/nsrs/" + id + "/risk") {
      setNsrsRiskOppPopup(false);
    }

    setOpen(false);
  };

  function ChangeTitle() {
    if (router.pathname === "/") {
      return "Dashboard";
    } else if (router.pathname === "/certification") {
      return "Egenerklæring";
    } else if (router.pathname === "/profile") {
      return "Profil";
    } else if (router.pathname === "/activities") {
      return "Aktiviteter";
    } else if (router.pathname === "/documents") {
      return "Dokumentasjon og rapport";
    } else if (router.pathname === "/mediakit") {
      return "Markedsføring";
    } else if (router.pathname === "/climate") {
      return "Klimaregnskap";
    } else if (router.pathname === "/nsrs") {
      return "NSRS";
    } else if (router.pathname === "/nsrs/" + id) {
      return "NSRS Moduler";
    } else if (router.pathname === "/nsrs/" + id + "/information") {
      return "Nøkkelinformasjon";
    } else if (router.pathname === "/nsrs/" + id + "/company") {
      return "Bedriftsprofil";
    } else if (router.pathname === "/nsrs/" + id + "/economy") {
      return "Økonomu & drift";
    } else if (router.pathname === "/nsrs/" + id + "/social") {
      return "Sosiale forhold";
    } else if (router.pathname === "/nsrs/" + id + "/climate") {
      return "Miljø & klima";
    } else if (router.pathname === "/nsrs/" + id + "/risk") {
      return "Risiko & muligheter";
    } else {
      return "Not working";
    }
  }

  function ChangeContent() {
    if (router.pathname === "/") {
      return <DashSlide />;
    } else if (router.pathname === "/certification") {
      return <CertifySlide />;
    } else if (router.pathname === "/profile") {
      return <ProSlides />;
    } else if (router.pathname === "/activities") {
      return <ActivitiesSlide />;
    } else if (router.pathname === "/documents") {
      return <DocRepSlide />;
    } else if (router.pathname === "/mediakit") {
      return <MarkSlide />;
    } else if (router.pathname === "/climate") {
      return <ClimateSlide />;
    } else if (router.pathname === "/nsrs") {
      return <NSRS />;
    } else if (router.pathname === "/nsrs/" + id) {
      return <NSRSModule />;
    } else if (router.pathname === "/nsrs/" + id + "/information") {
      return <NSRSInfo />;
    } else if (router.pathname === "/nsrs/" + id + "/company") {
      return <NSRSProfile />;
    } else if (router.pathname === "/nsrs/" + id + "/economy") {
      return <NSRSEconomy />;
    } else if (router.pathname === "/nsrs/" + id + "/social") {
      return <NSRSSocial />;
    } else if (router.pathname === "/nsrs/" + id + "/climate") {
      return <NSRSEnvironment />;
    } else if (router.pathname === "/nsrs/" + id + "/risk") {
      return <NSRSRiskOpportunity />;
    } else {
      return "Not working";
    }
  }

  return (
    <Grid>
      <Button
        variant="contained"
        sx={{
          width: props.width,
          backgroundColor: props.color,
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleClickOpen}
      >
        Hjelp?
      </Button>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle className={classes.title} variant="h3">
          {ChangeTitle()}
        </DialogTitle>
        <DialogContent>
          <Grid
            style={{
              paddingBottom: "30px",
              paddingTop: "10px",
              position: "relative",
            }}
          >
            {ChangeContent()}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
