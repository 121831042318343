export function getBadgeFromTotalEarnedPoints(totalPercent) {
    if (totalPercent >= 50 && totalPercent < 70) {
        return 'bronze';
    } else if (totalPercent >= 70 && totalPercent < 90) {
        return 'silver';
    } else if (totalPercent >= 90) {
        return 'gold';
    } else {
        return 'none';
    }
}

export function getRemainingPointsForNextBadge(totalPoints, totalPercentScore) {
    let totalPossible = (totalPoints / totalPercentScore) * 100;

    if (totalPercentScore >= 50 && totalPercentScore < 70) {
        return (totalPossible / 100) * 70 - totalPoints;
    } else if (totalPercentScore >= 70 && totalPercentScore < 90) {
        return (totalPossible / 100) * 90 - totalPoints;
    } else if (totalPercentScore >= 90) {
        return 0;
    } else {
        return (totalPossible / 100) * 50 - totalPoints;
    }
}
