import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    FormGroup,
    FormControlLabel,
    FormControl,
    Checkbox,
    Switch,
    InputLabel,
    Paper,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { TextAreaInput, NumberInput } from '../../../../../components/InputForm';
import QuestionLayoutEdit from './QuestionLayoutEdit';

import { API, graphqlOperation } from 'aws-amplify';
import { updateNSRSQuestion, deleteNSRSQuestion } from '../../../../../graphql/mutations';

export default function Question(props) {
    const { data, category, subcategory, showPopup, updateCategories } = props;

    const [rowOpen, setRowOpen] = useToggle();
    const [dialogOpen, setDialogOpen] = useToggle();

    const [code, setCode] = useState('');

    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [explanation, setExplanation] = useState('');
    const [filterTypes, setFilterTypes] = useState([]);
    const [industryCodes, setIndustryCodes] = useState([]);
    const [required, setRequired] = useState(false);
    const [order, setOrder] = useState(1);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            setName(data.name);
            setDesc(data.description);
            setExplanation(data.explanation);
            setFilterTypes(data.filterTypes);
            setIndustryCodes(data.industryCodes);
            setRequired(data.required);
            setOrder(data.order);
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateQuestion = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateNSRSQuestion, {
                    input: {
                        id: data.id,
                        name: name,
                        description: desc,
                        explanation: explanation,
                        filterTypes: filterTypes,
                        industryCodes: industryCodes,
                        required: required,
                        order: order,
                    },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated question: ', data);
                }

                resolve();
            });
        });
    };

    const deleteQuestion = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(deleteNSRSQuestion, {
                    input: { id: data.id },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Deleted question: ', data);
                }

                resolve();
            });
        });
    };

    const addCode = () => {
        if (industryCodes.includes(code)) {
            return;
        }

        if (code === '') {
            return;
        }

        let array = industryCodes;

        array.push(code);

        setIndustryCodes([...array]);
        setCode('');
    };

    const removeCode = index => {
        let array = industryCodes;

        array.splice(index, 1);

        setIndustryCodes([...array]);
    };

    const handleFilterChecked = value => {
        let newArr = filterTypes;

        if (newArr.includes(value)) {
            let index = newArr.indexOf(value);

            newArr.splice(index, 1);
        } else {
            newArr.push(value);
        }

        setFilterTypes([...newArr]);
    };

    const handleUpdateQuestion = async () => {
        if (name === '' || desc === '' || explanation === '') {
            showPopup('Alle felter må være fylt ut!');
            return;
        }

        await updateQuestion().catch(() => {
            showPopup('En feil oppsto ved lagring av spørsmål');
            return;
        });

        showPopup('Spørsmålet har blitt lagret');

        await updateCategories();

        setDialogOpen();
    };

    const handleCloseDialog = () => {
        setDialogOpen();

        setName(data.name);
        setDesc(data.description);
        setExplanation(data.explanation);
        setFilterTypes(data.filterTypes);
        setIndustryCodes(data.industryCodes);
        setRequired(data.required);
        setOrder(data.order);
    };

    const handleDeleteQuestion = async () => {
        await deleteQuestion().catch(() => {
            showPopup('En feil oppsto ved sletting av spørsmål');
            return;
        });

        showPopup('Spørsmålet har blitt slettet');

        await updateCategories();
    };

    const updateQuestionDialog = () => {
        return (
            <Grid>
                <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
                    <DialogTitle sx={{ fontSize: '2em' }}>Oppdater spørsmål</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Oppdater feltene til spørsmålet.
                        </DialogContentText>
                        <InputForm
                            label="Spørsmål"
                            placeholder="Skriv inn hva spørsmålet er..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Beskrivelse (Kort tekst)"
                            placeholder="Skriv inn beskrivelse av spørsmålet..."
                            value={desc}
                            onChange={e => setDesc(e.target.value)}
                        />
                        <TextAreaInput
                            rows={4}
                            label="Hjelpetekst (Lang tekst)"
                            placeholder="Skriv inn detaljert hjelptekst av spørsmålet..."
                            value={explanation}
                            onChange={e => setExplanation(e.target.value)}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={required} onChange={e => setRequired(e.target.checked)} />}
                                label="Er spørsmålet påkrevd?"
                            />
                        </FormGroup>
                        <InputLabel id="Type">
                            Filtreringstype (Velg kategoriene for selskapene som spørsmålet skal bli vist til, eller la
                            være tom hvis for alle)
                        </InputLabel>
                        <FormControl>
                            <FormGroup row defaultValue="None" onChange={e => handleFilterChecked(e.target.value)}>
                                <FormControlLabel
                                    value="Employees"
                                    control={<Checkbox checked={filterTypes.includes('Employees')} />}
                                    label="Flere ansatte"
                                />
                                <FormControlLabel
                                    value="Property"
                                    control={<Checkbox checked={filterTypes.includes('Property')} />}
                                    label="Bygg"
                                />
                                <FormControlLabel
                                    value="Production"
                                    control={<Checkbox checked={filterTypes.includes('Production')} />}
                                    label="Produsenter"
                                />
                                <FormControlLabel
                                    value="Trade"
                                    control={<Checkbox checked={filterTypes.includes('Trade')} />}
                                    label="Varehandel"
                                />
                            </FormGroup>
                        </FormControl>
                        <NumberInput
                            label="Listenummer (blir brukt for rekkefølge)"
                            placeholder="Skriv inn nummer..."
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                        <NumberInput
                            label="Næringskoder (Bare første 2 tall, la være tom hvis for alle)"
                            placeholder="Skriv og legg til næringskode..."
                            value={code}
                            onChange={e => setCode(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{
                                padding: '20px',
                                marginBottom: '20px',
                                width: '130px',
                            }}
                            onClick={addCode}
                        >
                            Legg til kode
                        </Button>
                        <Grid>
                            <b>Koder:</b>
                        </Grid>
                        {industryCodes &&
                            industryCodes.map((code, i) => {
                                return (
                                    <Grid key={code}>
                                        {code}
                                        <Button startIcon={<RemoveCircleOutlineIcon />} onClick={() => removeCode(i)} />
                                        <br />
                                    </Grid>
                                );
                            })}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleUpdateQuestion}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid>
            {updateQuestionDialog()}
            <Paper sx={{ padding: '20px', marginBottom: '10px' }}>
                <ListItem disablePadding key={data.id}>
                    <ListItemButton onClick={setRowOpen}>
                        <ListItemIcon>
                            <DehazeIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={category.order + '.' + subcategory.order + '.' + data.order + ' - ' + data.name}
                        />
                    </ListItemButton>
                </ListItem>
                {!rowOpen ? (
                    <></>
                ) : (
                    <Grid>
                        <Divider />
                        <Grid sx={{ margin: '15px' }}>
                            <Grid sx={{ marginBottom: '15px' }}>
                                <b>ID:</b>
                                <br /> {data.id}
                            </Grid>
                            <Grid sx={{ marginBottom: '15px' }}>
                                <b>Beskrivelse:</b>
                                <br /> {data.description}
                            </Grid>
                            <Grid sx={{ marginBottom: '15px' }}>
                                <b>Hjelpetekst:</b>
                                <br /> {data.explanation}
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '250px' }}
                            onClick={setDialogOpen}
                        >
                            Oppdater spørsmål{' '}
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '250px' }}
                            onClick={handleDeleteQuestion}
                        >
                            Slett spørsmål{' '}
                        </Button>
                        <QuestionLayoutEdit
                            id={data.id}
                            formData={data.data}
                            showPopup={showPopup}
                            updateCategories={updateCategories}
                        />
                        <Divider />
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
}
