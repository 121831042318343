import React, { useState, useEffect } from 'react';
import {
    Paper,
    Grid,
    Typography,
    Divider,
    Button,
    Dialog,
    Slide,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import Sidebar from './Sidebar';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../../Certification/theme';
import Summary from './Summary';
import QuestionList from './QuestionList';
import { ArrowRight, Arrow } from '../../../../components/Icons';
import { useStyles } from '../NSRSStyles';
import useRouter from '../../../../hooks/useRouter';
import { SiteLoading } from '../../../../components/Loading';
import { fetchSingleReport, fetchSingleCategory, fetchAllCategories, finishReport } from '../nsrsFunctions';
import cloneDeep from 'lodash/cloneDeep';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NSRSForm() {
    const classes = useStyles();
    const router = useRouter();

    const { id, formId } = router.query;

    const [report, setReport] = useState(null);
    const [category, setCategory] = useState(null);
    const [openStates, setOpenStates] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [canComplete, setCanComplete] = useState(false);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            initializeData();
        }

        return () => {
            isMounted = true;
        };
    }, [formId]); // eslint-disable-line react-hooks/exhaustive-deps

    const initializeData = async () => {
        let reportData = await fetchReport();
        let categoryData = await fetchCategory(formId);

        let newCategoryData = await filterCategory(reportData.responses.items, categoryData);

        await getOpenStates(newCategoryData);

        if (reportData.status === 'Draft' && formId === 'risk') {
            checkIfReqMet(reportData);
        }

        setCategory(newCategoryData);
        setReport(reportData);
    };

    const fetchReport = async () => {
        return await fetchSingleReport(id)
            .then(data => {
                if (!data) {
                    router.push('/nsrs');
                    return;
                }

                return data;
            })
            .catch(() => {
                router.push('/nsrs/');
            });
    };

    const fetchCategory = async newFormId => {
        if (router.location.state && router.location.state.categories) {
            return cloneDeep(router.location.state.categories.find(category => category.id === newFormId));
        } else {
            return await fetchSingleCategory(newFormId)
                .then(async data => {
                    return data;
                })
                .catch(() => {
                    router.push('/nsrs/' + id);
                });
        }
    };

    const filterCategory = async (responses, categoryData) => {
        let newCatData = categoryData;

        // Filter climate options
        if (formId === 'climate') {
            let climateIdArr = [];

            for (const subcategory of newCatData.subcategories.items) {
                if (!climateSubcategoryCheck(responses, subcategory.id)) {
                    climateIdArr.push(subcategory.id);
                }
            }

            for (const subId of climateIdArr) {
                let index = newCatData.subcategories.items.findIndex(item => item.id === subId);

                if (index !== -1) {
                    newCatData.subcategories.items.splice(index, 1);
                }
            }
        }

        // Filter social options
        if (formId === 'social') {
            let socialIdArr = [];

            for (const subcategory of newCatData.subcategories.items) {
                filterSocialQuestions(responses, subcategory);

                if (subcategory.questions.items.length === 0) {
                    socialIdArr.push(subcategory.id);
                }
            }

            for (const subId of socialIdArr) {
                let index = newCatData.subcategories.items.findIndex(item => item.id === subId);

                if (index !== -1) {
                    newCatData.subcategories.items.splice(index, 1);
                }
            }
        }

        return newCatData;
    };

    const filterSocialQuestions = (responses, subcategory) => {
        let employeeResponse = responses.find(response => {
            return response.questionId === '5cd4c337-7d6f-4618-8f80-1b58a6b9dbd8';
        });

        if (employeeResponse && employeeResponse.data) {
            let answerData = JSON.parse(employeeResponse.data);

            if (answerData && answerData['545c4ef-77a-7a27-7f5d-20e24c4af6be'] === '0') {
                let questionIdArr = [];

                for (const question of subcategory.questions.items) {
                    if (question.filterTypes.includes('Employees')) {
                        questionIdArr.push(question.id);
                    }
                }

                for (const questionId of questionIdArr) {
                    let index = subcategory.questions.items.findIndex(item => item.id === questionId);

                    if (index !== -1) {
                        subcategory.questions.items.splice(index, 1);
                    }
                }
            }
        }
    };

    const climateSubcategoryCheck = (responses, subId) => {
        if (formId !== 'climate' || subId === '13113522-0ba1-47de-ad5f-6a4cb6804f3f') {
            return true;
        }

        let findData = responses.find(response => {
            return response.questionId === '212ed41b-8647-4f90-9b02-4960eb2850a5';
        });

        if (findData) {
            let respData = JSON.parse(findData.data);

            if (respData['fe71025-061-87c-aa-d8ce70f2bb'] && respData['fe71025-061-87c-aa-d8ce70f2bb'].checked) {
                let climateCheckArr = respData['fe71025-061-87c-aa-d8ce70f2bb'].checked;

                // Materialer
                if (
                    climateCheckArr.includes('07c662-7c2-2bd7-5d1-a40ad43f58f3') &&
                    subId === '1d67ffdf-8f38-4446-9699-fd2beaf4aa91'
                ) {
                    return true;
                    // Avfall
                } else if (
                    climateCheckArr.includes('341c5f5-151a-176e-d05f-21640dfbdbb') &&
                    subId === 'cd3b3281-44cb-44f8-bf7e-76076d60ab22'
                ) {
                    return true;
                    // Energy
                } else if (
                    climateCheckArr.includes('7a087a-6ea5-2d0f-650d-473cf7bca1') &&
                    subId === 'a85f82bb-eb05-407a-a002-ce84ddc775d0'
                ) {
                    return true;
                    // Klima
                } else if (
                    climateCheckArr.includes('30ac6d0-1c3e-077b-a38-c1f0e4083e82') &&
                    subId === 'dd8ac392-6611-44c1-9dfe-7b53cbe29216'
                ) {
                    return true;
                    // EU-taksonomi
                } else if (
                    climateCheckArr.includes('28de7d1-aa77-616b-0e63-d6c58c01fc44') &&
                    subId === '8af23562-1361-4f41-a515-053049bd0578'
                ) {
                    return true;
                }
            }
        }

        return false;
    };

    const getOpenStates = async categoryData => {
        let newArr = [];

        for (const subcategory of categoryData.subcategories.items.sort((a, b) => {
            return a.order - b.order;
        })) {
            for (const question of subcategory.questions.items.sort((a, b) => {
                return a.order - b.order;
            })) {
                let newObj = {
                    id: question.id,
                    state: false,
                };

                if (openStates && openStates.length > 0) {
                    let index = openStates.findIndex(state => {
                        return state.id === question.id;
                    });

                    if (index !== -1) {
                        newObj.state = openStates[index].state;
                    }
                }

                if (router.query.criteria) {
                    if (router.query.criteria === question.id) {
                        newObj.state = true;
                    }
                }

                newArr.push(newObj);
            }
        }

        setOpenStates([...newArr]);
    };

    const checkEmployeeQuestion = (responses, question) => {
        if (!question.filterTypes.includes('Employees')) {
            return false;
        }

        let employeeResponse = responses.find(response => {
            return response.questionId === '5cd4c337-7d6f-4618-8f80-1b58a6b9dbd8';
        });

        if (employeeResponse && employeeResponse.data) {
            let answerData = JSON.parse(employeeResponse.data);

            if (answerData && answerData['545c4ef-77a-7a27-7f5d-20e24c4af6be'] === '0') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const climateSubQuestionCheck = (category, responses, subId) => {
        if (category.id !== 'climate' || subId === '13113522-0ba1-47de-ad5f-6a4cb6804f3f') {
            return true;
        }

        let findData = responses.find(response => {
            return response.questionId === '212ed41b-8647-4f90-9b02-4960eb2850a5';
        });

        if (findData) {
            let respData = JSON.parse(findData.data);

            if (respData['fe71025-061-87c-aa-d8ce70f2bb'] && respData['fe71025-061-87c-aa-d8ce70f2bb'].checked) {
                let climateCheckArr = respData['fe71025-061-87c-aa-d8ce70f2bb'].checked;

                // Materialer
                if (
                    climateCheckArr.includes('07c662-7c2-2bd7-5d1-a40ad43f58f3') &&
                    subId === '1d67ffdf-8f38-4446-9699-fd2beaf4aa91'
                ) {
                    return true;
                    // Avfall
                } else if (
                    climateCheckArr.includes('341c5f5-151a-176e-d05f-21640dfbdbb') &&
                    subId === 'cd3b3281-44cb-44f8-bf7e-76076d60ab22'
                ) {
                    return true;
                    // Energy
                } else if (
                    climateCheckArr.includes('7a087a-6ea5-2d0f-650d-473cf7bca1') &&
                    subId === 'a85f82bb-eb05-407a-a002-ce84ddc775d0'
                ) {
                    return true;
                    // Klima
                } else if (
                    climateCheckArr.includes('30ac6d0-1c3e-077b-a38-c1f0e4083e82') &&
                    subId === 'dd8ac392-6611-44c1-9dfe-7b53cbe29216'
                ) {
                    return true;
                    // EU-taksonomi
                } else if (
                    climateCheckArr.includes('28de7d1-aa77-616b-0e63-d6c58c01fc44') &&
                    subId === '8af23562-1361-4f41-a515-053049bd0578'
                ) {
                    return true;
                }
            }
        } else {
            return false;
        }
    };

    const checkIfReqMet = async reportData => {
        const categories = await fetchAllCategories();

        let responses = reportData.responses.items;

        let totalReqQuestions = 0;
        let totalReqAnswered = 0;

        for (const category of categories) {
            for (const sub of category.subcategories.items) {
                for (const question of sub.questions.items) {
                    if (
                        climateSubQuestionCheck(category, responses, sub.id) &&
                        !checkEmployeeQuestion(responses, question)
                    ) {
                        if (question.required) {
                            totalReqQuestions++;
                        }

                        let resp = responses.find(item => item.questionId === question.id);

                        if (resp && resp.isAnswered && question.required) {
                            totalReqAnswered++;
                        }
                    }
                }
            }
        }

        if (totalReqAnswered === totalReqQuestions) {
            setCanComplete(true);
        } else {
            setCanComplete(false);
        }
    };

    const handleOpenQuestion = id => {
        let index = openStates.findIndex(state => state.id === id);
        let newArr = openStates;

        newArr[index].state = !newArr[index].state;

        setOpenStates([...newArr]);
    };

    const handleOpenNextQuestion = id => {
        let index = openStates.findIndex(state => state.id === id);
        let newArr = openStates;

        newArr[index].state = !newArr[index].state;

        if (index + 1 !== openStates.length) {
            newArr[index + 1].state = !newArr[index + 1].state;
        }

        setOpenStates([...newArr]);
    };

    const handleNextButton = async () => {
        setReport(null);

        if (formId === 'information') {
            router.push('/nsrs/' + report.id + '/company');
            await fetchCategory('company');
        } else if (formId === 'company') {
            router.push('/nsrs/' + report.id + '/economy');
            await fetchCategory('economy');
        } else if (formId === 'economy') {
            router.push('/nsrs/' + report.id + '/social');
            await fetchCategory('social');
        } else if (formId === 'social') {
            router.push('/nsrs/' + report.id + '/climate');
            await fetchCategory('climate');
        } else if (formId === 'climate') {
            router.push('/nsrs/' + report.id + '/risk');
            await fetchCategory('risk');
        }

        await fetchReport();
    };

    const handlePreviousButton = async () => {
        setReport(null);

        if (formId === 'company') {
            router.push('/nsrs/' + report.id + '/information');
            await fetchCategory('information');
        } else if (formId === 'economy') {
            router.push('/nsrs/' + report.id + '/company');
            await fetchCategory('company');
        } else if (formId === 'social') {
            router.push('/nsrs/' + report.id + '/economy');
            await fetchCategory('economy');
        } else if (formId === 'climate') {
            router.push('/nsrs/' + report.id + '/social');
            await fetchCategory('social');
        } else if (formId === 'risk') {
            router.push('/nsrs/' + report.id + '/climate');
            await fetchCategory('climate');
        }

        await fetchReport();
    };

    const handleCloseDialog = () => {
        router.push({
            pathname: '/nsrs/' + report.id,
            state: { report: report },
        });
    };

    const handleOpenDialog = async () => {
        await finishReport(report.id);

        setOpenDialog(true);
    };

    const handleGoToDocuments = () => {
        router.push('/documents/NSRS');
    };

    const DialogPopUp = (
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" TransitionComponent={Transition} fullWidth>
            <DialogTitle className={classes.popupTitle} sx={{ fontSize: 32 }}>
                Din NSRS rapporten har blitt fullført
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Gratulerer! Du kan nå velge å gå tilbake til oversikten eller til dokumenter hvor du har muligheten
                    til å laste ned en fullverdig PDF av det du har fylt ut. Du kan alltids finne dine dokumenter senere
                    på sidemenyen av portalen.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#EBEBEB',
                    }}
                    onClick={() =>
                        router.push({
                            pathname: '/nsrs/' + report.id,
                            state: { report: report },
                        })
                    }
                >
                    Tilbake til oversikt
                </Button>
                <Button
                    variant="contained"
                    sx={{ width: '150px', height: '50px', backgroundColor: '#C1E6EB' }}
                    autoFocus
                    onClick={handleGoToDocuments}
                >
                    Gå til dokumenter
                </Button>
            </DialogActions>
        </Dialog>
    );

    if (!report || !category) {
        return <SiteLoading />;
    } else {
        return (
            <div className="root">
                <ThemeProvider theme={theme}>
                    <Sidebar report={report} category={category} />
                    <Grid container className={classes.mainContainer}>
                        <Grid item xs={12} className={classes.mainSection}>
                            <Paper elevation={1}>
                                <Summary report={report} category={category} />
                                {category.subcategories &&
                                    openStates &&
                                    category.subcategories.items
                                        .sort((a, b) => a.order - b.order)
                                        .map((subcategory, index) => {
                                            return (
                                                <Grid key={subcategory.id}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            id={index + 1}
                                                            variant="h1"
                                                            sx={{
                                                                textAlign: 'left',
                                                                margin: '40px 0px 20px 0px',
                                                                borderBottom: 'none',
                                                            }}
                                                        >
                                                            {subcategory.name}
                                                        </Typography>
                                                    </Grid>
                                                    <QuestionList
                                                        responses={report.responses.items}
                                                        data={subcategory.questions.items}
                                                        catOrder={category.order}
                                                        subOrder={subcategory.order}
                                                        updateReport={initializeData}
                                                        reportIsCompleted={report.status === 'Completed' ? true : false}
                                                        openStates={openStates}
                                                        handleOpenQuestion={handleOpenQuestion}
                                                        handleOpenNextQuestion={handleOpenNextQuestion}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                <Divider sx={{ margin: '30px 0px' }} />
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginRight: '20px',
                                            backgroundColor: '#EBEBEB',
                                        }}
                                        onClick={() =>
                                            router.push({
                                                pathname: '/nsrs/' + report.id,
                                                state: { report: report },
                                            })
                                        }
                                    >
                                        Tilbake til oversikt
                                    </Button>
                                    {formId === 'information' ? (
                                        <></>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            sx={{ marginRight: '20px' }}
                                            className={classes.nextButton}
                                            onClick={handlePreviousButton}
                                        >
                                            {Arrow}
                                            <Grid sx={{ marginLeft: '8px' }}>Forrige</Grid>
                                        </Button>
                                    )}
                                    {formId === 'risk' && report.status === 'Draft' ? (
                                        <>
                                            {' '}
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    width: '200px',
                                                    marginRight: '20px',
                                                    backgroundColor: '#FDDFB0',
                                                }}
                                                onClick={handleOpenDialog}
                                                disabled={!canComplete}
                                            >
                                                Fullfør NSRS rapport
                                            </Button>
                                            {DialogPopUp}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {formId === 'risk' ? (
                                        <></>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            className={classes.nextButton}
                                            onClick={handleNextButton}
                                        >
                                            Neste
                                            {ArrowRight}
                                        </Button>
                                    )}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </div>
        );
    }
}
