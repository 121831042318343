import { useEffect, useRef } from 'react';

// useEffect function to handle async operations at page load
export default function useIsMounted() {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);

    return isMounted;
}
