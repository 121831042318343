import { API, graphqlOperation } from 'aws-amplify';

import {
    listCompanyClimateAccReports,
    getCompanyClimateAccReport,
    listClimateEmissionFactors,
} from '../../../graphql/queries';
import {
    createCompanyClimateAccReport,
    updateCompanyClimateAccReport,
    deleteCompanyClimateAccReport,
    createClimateAccResponse,
    updateClimateAccResponse,
} from '../../../graphql/mutations';

/** LOCAL FUNCTIONS **/

// Creates a new climate report
const createResponse = async inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createClimateAccResponse, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Creates a new climate report
const updateResponse = async inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateClimateAccResponse, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

// Creates a new climate report
const createClimateAccReport = async inputData => {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(createCompanyClimateAccReport, { input: inputData }))
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export async function fetchClimateFactors(questionId, token) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listClimateEmissionFactors, {
                filter: { questionId: { eq: questionId } },
                nextToken: token,
            }),
        )
            .then(data => {
                resolve(data.data.listClimateEmissionFactors);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/** EXPORTED FUNCTIONS **/

export async function createNewClimateAccReport(companyId, title, year, userName) {
    return new Promise(async (resolve, reject) => {
        let input = {
            companyId: companyId,
            status: 'Draft',
            title: title,
            year: year,
            userCreatedBy: userName,
            infoData: JSON.stringify({ statement: '', heatedAreas: [] }),
        };

        await createClimateAccReport(input)
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully created new climate accounting report: ', data);
                }

                resolve(data.data.createCompanyClimateAccReport);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to create new climate accounting report: ', err);
                }

                reject(err);
            });
    });
}

export async function fetchSingleClimateReport(reportId) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(getCompanyClimateAccReport, { id: reportId }))
            .then(data => {
                resolve(data.data.getCompanyClimateAccReport);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function fetchClimateReports(companyId) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(listCompanyClimateAccReports, {
                filter: { companyId: { eq: companyId } },
            }),
        )
            .then(data => {
                resolve(data.data.listCompanyClimateAccReports.items);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export async function deleteClimateReport(id) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(
            graphqlOperation(deleteCompanyClimateAccReport, {
                input: { id: id },
            }),
        )
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Succesfully deleted climate accounting report: ', data);
                }

                resolve();
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to delete climate accounting report: ', err);
                }

                reject(err);
            });
    });
}

export async function updateClimateReport(inputData) {
    return new Promise(async (resolve, reject) => {
        await API.graphql(graphqlOperation(updateCompanyClimateAccReport, { input: inputData }))
            .then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Succesfully updated climate accounting report: ', data);
                }

                resolve(data.data.updateCompanyClimateAccReport);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to update climate accounting report: ', err);
                }

                reject(err);
            });
    });
}

export async function fetchAllClimateFactors(questionId) {
    return new Promise(async (resolve, reject) => {
        let dataArr = [];

        let nextToken = null;

        do {
            try {
                const resp = await fetchClimateFactors(questionId, nextToken);
                const data = resp.items;
                nextToken = resp.nextToken;

                data.forEach(item => {
                    dataArr.push(item);
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error while fetching all factors from backend: ', err);
                }

                reject(err);
            }
        } while (nextToken !== null);

        if (process.env.NODE_ENV === 'development') {
            console.log('Fetched all factors: ', dataArr);
        }

        resolve(dataArr);
    });
}

export async function newClimateResponse(companyId, reportId, questionId, scope, data, checked) {
    return new Promise(async (resolve, reject) => {
        let inputData = {
            companyId: companyId,
            climateAccReportId: reportId,
            questionId: questionId,
            scope: scope,
            data: JSON.stringify(data),
            isRelevant: checked,
        };

        await createResponse(inputData)
            .then(newData => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully created new climate response: ', newData);
                }

                resolve(newData);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to create new climate response: ', err);
                }

                reject(err);
            });
    });
}

export async function updateClimateResponse(id, companyId, reportId, questionId, scope, data, checked) {
    return new Promise(async (resolve, reject) => {
        let inputData = {
            id: id,
            companyId: companyId,
            climateAccReportId: reportId,
            questionId: questionId,
            scope: scope,
            data: JSON.stringify(data),
            isRelevant: checked,
        };

        await updateResponse(inputData)
            .then(newData => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Successfully updated climate response: ', newData);
                }

                resolve(data);
            })
            .catch(err => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to update climate response: ', err);
                }

                reject(err);
            });
    });
}

export async function reopenReport(id) {
    return new Promise(async (resolve, reject) => {
        let inputData = {
            id: id,
            status: 'Draft',
        };

        await updateClimateReport(inputData)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
}
