import React, { useState, useEffect } from 'react';
import {
    Grid,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableContainer,
    Collapse,
    IconButton,
    Box,
    TableBody,
    Typography,
    Avatar,
    Button,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import getDateFromTimestamp from '../../../../../helpers/getDateFromTimestamp';
import CertificationCheckDialog from './CertificationCheckDialog';
import { fetchClimateReports } from '../../../Climate/climateFunctions';

export default function CompanyInfo(props) {
    const [open, setOpen] = useState(false);
    const [dialogState, setDialogState] = useState(false);

    const [date, setDate] = useState('');

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            getLatestUpdateDate();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getLatestUpdateDate = async () => {
        let certData = props.company.certData.items[0];
        let responses = props.company.certData.items[0].responses.items;
        let climateReports = await fetchClimateReports(props.company.id);

        let updateDate = new Date(props.company.updatedAt);

        if (new Date(certData.updatedAt).getTime() > updateDate.getTime()) {
            updateDate = new Date(certData.updatedAt);
        }

        for (const item of responses) {
            if (new Date(item.updatedAt).getTime() > updateDate.getTime()) {
                updateDate = new Date(item.updatedAt);
            }
        }

        for (const item of climateReports) {
            if (new Date(item.updatedAt).getTime() > updateDate.getTime()) {
                updateDate = new Date(item.updatedAt);
            }

            for (const resp of item.responses.items) {
                if (new Date(resp.updatedAt).getTime() > updateDate.getTime()) {
                    updateDate = new Date(resp.updatedAt);
                }
            }
        }

        const day = updateDate.toLocaleString('default', { day: '2-digit' });
        const month = updateDate.toLocaleString('default', { month: 'short' });
        const year = updateDate.toLocaleString('default', { year: 'numeric' });

        setDate(day + ' ' + month + ' ' + year);
    };

    return (
        <React.Fragment>
            <CertificationCheckDialog
                company={props.company}
                categories={props.categories}
                open={dialogState}
                handleClose={() => setDialogState(false)}
            />
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {props.company.name}
                </TableCell>
                <TableCell align="left">{props.company.orgNumber}</TableCell>
                <TableCell align="left">{getDateFromTimestamp(props.company.createdAt)}</TableCell>
                <TableCell align="left">{JSON.parse(props.company.invoice).status}</TableCell>
                <TableCell align="center">{props.users.length || 0}</TableCell>
                <TableCell align="left">{date}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: '0px', backgroundColor: '#fff' }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Grid container direction="row">
                                <Grid
                                    item
                                    xs={3}
                                    style={{
                                        paddingRight: '20px',
                                        borderRight: '4px solid #FDDFB0',
                                    }}
                                >
                                    <Typography variant="h1" style={{ textAlign: 'left' }}>
                                        Informasjon
                                    </Typography>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell style={{ borderRight: 0 }}>{props.company.id}</TableCell>
                                                </TableRow>
                                                <TableRow style={{ backgroundColor: '#fff' }}>
                                                    <TableCell>Adresse</TableCell>
                                                    <TableCell style={{ borderRight: 0 }}>
                                                        {JSON.parse(props.company.address).adresse}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ backgroundColor: '#fff' }}>
                                                    <TableCell>Kommune</TableCell>
                                                    <TableCell style={{ borderRight: 0 }}>
                                                        {JSON.parse(props.company.address).postnummer +
                                                            ' ' +
                                                            JSON.parse(props.company.address).kommune}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ backgroundColor: '#fff' }}>
                                                    <TableCell>Status på egenærklering</TableCell>
                                                    <TableCell style={{ borderRight: 0 }}>
                                                        {props.company.certData.items[0].status}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ backgroundColor: '#fff' }}>
                                                    <TableCell>Utløpsdato på egenærklering</TableCell>
                                                    <TableCell style={{ borderRight: 0 }}>
                                                        {props.company.certData.items[0].expDate
                                                            ? getDateFromTimestamp(
                                                                  props.company.certData.items[0].expDate,
                                                              )
                                                            : 'Ingen'}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            padding: '20px',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                            width: '200px',
                                        }}
                                        onClick={() => setDialogState(true)}
                                    >
                                        Sjekk egenærklering
                                    </Button>
                                </Grid>
                                <Grid item xs={9} style={{ paddingLeft: '20px' }}>
                                    <Typography variant="h1" style={{ textAlign: 'left' }}>
                                        Brukere
                                    </Typography>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Avatar</TableCell>
                                                    <TableCell>Navn</TableCell>
                                                    <TableCell>Stilling</TableCell>
                                                    <TableCell>Epost</TableCell>
                                                    <TableCell>Tlf. nummer</TableCell>
                                                    <TableCell>Oppdatert</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.users &&
                                                    props.users.map(user => {
                                                        return (
                                                            <TableRow key={user.id}>
                                                                <TableCell>
                                                                    <Avatar>
                                                                        {JSON.parse(user.name)
                                                                            .fullName.match(/\b\w/g)
                                                                            .join('')}
                                                                    </Avatar>
                                                                </TableCell>
                                                                <TableCell>{JSON.parse(user.name).fullName}</TableCell>
                                                                <TableCell>{user.position}</TableCell>
                                                                <TableCell>{user.email}</TableCell>
                                                                <TableCell>{user.phoneNumber}</TableCell>
                                                                <TableCell>
                                                                    {getDateFromTimestamp(user.updatedAt)}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
