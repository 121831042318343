import React, { useState, useEffect } from 'react';
import { Grid, Paper, Button, Typography, Divider } from '@mui/material';
import DetailReportPDF, { DetailReportPDFExport } from './DetailReportPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useStyles } from '../DocumentsStyles';
import { useAuth } from '../../../../auth/useAuth';
import useRouter from '../../../../hooks/useRouter';
import { Download } from '../../../../components/Icons';
import amplitude from 'amplitude-js';
import Replacement from '../../../../assets/images/nocustomer_detail.svg';
import NavigationBar from './NavigationBar';

export default function DetailReport(props) {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();

    const [totalPoints, setTotalPoints] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(0);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial') {
                let newTotalPoints = 0;
                let newTotalQuestions = 0;

                for (const category of props.certData) {
                    for (const subcategory of category.subcategories.items) {
                        newTotalQuestions = subcategory.questions.items.length + newTotalQuestions;

                        for (const question of subcategory.questions.items) {
                            newTotalPoints = question.pointValue + newTotalPoints;
                        }
                    }
                }

                setTotalPoints(newTotalPoints);
                setTotalQuestions(newTotalQuestions);
            }
        }

        return () => {
            isMounted = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadDocument = () => {
        if (process.env.NODE_ENV !== 'development') {
            amplitude.getInstance().logEvent('detail_report_downloaded');
        }
    };

    return (
        <Grid item xs={12}>
            <NavigationBar />
            <Paper sx={{ padding: '40px' }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Typography variant="body2" className={classes.pdfWarning}>
                            PDF forhåndvisning er foreløpig kun tilgjenglig på PC.
                        </Typography>
                        <Typography variant="h1" sx={{ textAlign: 'left' }}>
                            Statusrapport
                        </Typography>
                        <Divider sx={{ margin: '10px 0px' }} />
                        <Typography variant="body1">
                            Her kan dere laste ned en statusrapport med alle resultater og status innen områdene;
                            sosiale forhold, miljø og klima, samt økonomi og drift. I rapporten er det oversikt over
                            alle indikatorer, og hvilken poengsum bedriften har oppnådd pr. indikator. Rapporten kan
                            deles både internt og eksternt. I tillegg kan rapporten brukes som vedlegg i anbud,
                            konkurranser, som dokumentasjon i søknader, m.m. Etterhvert vil også klimaregnskap og
                            bransjespesifikke forhold bli inkludert i rapporten.
                        </Typography>
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            <PDFDownloadLink
                                style={{ color: '#000', textDecoration: 'none' }}
                                document={
                                    <DetailReportPDFExport
                                        certData={props.certData}
                                        respData={props.respData}
                                        auth={auth}
                                        totalPoints={totalPoints}
                                        totalQuestions={totalQuestions}
                                    />
                                }
                                fileName="Statusrapport.pdf"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? (
                                        <Button
                                            variant="contained"
                                            disabled={true}
                                            sx={{
                                                height: '50px',
                                                width: '100%',
                                                marginTop: '30px',
                                                color: '#000000',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            Laster inn dokument...
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={() => downloadDocument(blob)}
                                            sx={{
                                                height: '50px',
                                                width: '100%',
                                                marginTop: '30px',
                                                color: '#000000',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            Last ned {Download}
                                        </Button>
                                    )
                                }
                            </PDFDownloadLink>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{
                                    height: '50px',
                                    width: '100%',
                                    marginTop: '30px',
                                    color: '#000000',
                                    textDecoration: 'none',
                                }}
                                onClick={() => router.push('/subscribe')}
                            >
                                Bli kunde for å laste ned dokument
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={6} className={classes.viewerSection}>
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            <DetailReportPDF certData={props.certData} respData={props.respData} auth={auth} />
                        ) : (
                            <img src={Replacement} alt="Replacement for non customers"></img>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
