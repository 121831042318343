import { createTheme } from "@mui/material/styles";
import "../../index.css";

const Yanone = "'Yanone Kaffeesatz', sans-serif";
const OpenSansCondensed = "'Open Sans Condensed', sans-serif";
const OpenSans = "'Open Sans', sans-serif";

export const theme = createTheme({
  palette: {
    action: {
      selected: "#FEF0D9",
      hover: "#FEF0D9",
      disabledBackground: "#eaeaea",
      disabled: "#c8c8c8",
    },
    primary: {
      main: "#BDCCD4",
      light: "#E2EFF1",
    },
    secondary: {
      main: "#FDDFB0",
      light: "#FEF0D9",
    },
    background: {
      main: "#f5f5f5",
    },
    font: {
      main: "#000",
    },
  },
  typography: {
    body1: {
      fontFamily: OpenSans,
      fontSize: "14px",
      fontWeight: 100,
    },
    body2: {
      fontFamily: OpenSansCondensed,
      fontSize: "14px",
    },
    h1: {
      fontFamily: Yanone,
      fontSize: "40px",
      textAlign: "center",
      margin: "10px 0px",
    },
    h2: {
      fontFamily: Yanone,
      fontSize: "25px",
      marginBottom: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
    },
    h3: {
      fontFamily: Yanone,
      fontWeight: 300,
      fontSize: "1.4em",
      textAlign: "left",
      backgroundColor: "#E2EFF1",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      display: "flex",
      padding: "10px 20px",
    },
    button: {
      fontFamily: OpenSansCondensed,
      fontSize: "1em",
      textTransform: "none",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "60px",
          fontSize: "1em !important",
          background: "#f6f6f6",
          boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          width: "100%",
          border: "none",
          color: "#000000 !important",
          margin: "10px 0px",
          padding: "15px",
          fontFamily: OpenSansCondensed,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: OpenSansCondensed,
        },
      },
    },
    MuiButton: {
      root: {
        color: "#000",
      },
      styleOverrides: {
        root: {
          color: "#000",
        },
        contained: {
          width: "100%",
          height: 50,
          fontFamily: OpenSansCondensed,
          textTransform: "none",
          fontSize: 15,
          borderRadius: 5,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#eaeaea",
          },
        },
        text: {
          textDecoration: "underline",
          fontsize: 14,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          fontFamily: OpenSansCondensed + "!important",
          fontSize: "1em",
          background: "#F6F6F6",
          boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          width: "100%",
          border: "none",
          color: "#000",
          margin: "10px 0px",
          padding: " 4px 7px",
          '&&[class*="MuiInput-root"] $input': {
            padding: "12px 5px 12px !important",
            zIndex: "9999",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "none",
        },
        notchedOutline: {
          border: "none",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: "hidden",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "4px solid #FDDFB0",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 10,
          borderBottom: "0px",
        },
        head: {
          padding: "10px 10px 0px 10px",
        },
        body: {
          borderRight: "1px solid #E8E8E8",
          "&:last-child": {
            borderRight: "0px",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(even)": {
            backgroundColor: "#f3f3f3",
            borderTop: "1px solid #EBEBEB",
            borderBottom: "1px solid #EBEBEB",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
          background: "#fff",
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: OpenSansCondensed,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: OpenSansCondensed,
          width: "80px",
        },
        label: {
          fontSize: "15px !important",
        },
      },
    },
  },
});
