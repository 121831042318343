import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Chip,
    AvatarGroup,
    Avatar,
    Grid,
    Select,
    InputBase,
    MenuItem,
    Box,
    NativeSelect,
    Hidden,
} from '@mui/material';
import {
    Business1,
    ArrowRight,
    Profit1,
    Social1,
    Environment1,
    ChoresIcon2,
    Climate1,
} from '../../../../components/Icons';
import { DataGrid, useGridApiContext } from '@mui/x-data-grid';
import useRouter from '../../../../hooks/useRouter';
import { useAuth } from '../../../../auth/useAuth';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { updateTask } from '../choresFunctions';

function EditResponsibleCell(props) {
    const { id, value, field, updateTaskData, users } = props;
    const apiRef = useGridApiContext();

    const handleChange = async event => {
        await apiRef.current.setEditCellValue({
            id,
            field,
            value: event.target.value,
        });

        let inputData = {
            id: id,
            users: event.target.value,
        };

        console.log(event.target.value);

        await updateTaskData(inputData);
    };

    return (
        <Select
            multiple
            value={value}
            onChange={handleChange}
            input={<InputBase sx={{ boxShadow: 'none', backgroundColor: '#ffffff' }} />}
            autoFocus
        >
            {users.map(user => (
                <MenuItem key={user.id} value={user.id}>
                    {JSON.parse(user.name).fullName}
                </MenuItem>
            ))}
        </Select>
    );
}

EditResponsibleCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any.isRequired,
};

function renderMultiSelectEditInputCell(params, updateTaskData, users) {
    return <EditResponsibleCell {...params} updateTaskData={updateTaskData} users={users} />;
}

function EditStatusCell(props) {
    const { id, value, field, updateTaskData } = props;
    const apiRef = useGridApiContext();

    const handleChange = async event => {
        let newState = 'Ikke påbegynt';
        let bgColor = '#FFFFFF';

        if (event.target.value === 'Not Started') {
            newState = 'Ikke påbegynt';
            bgColor = '#F3CCD1';
        } else if (event.target.value === 'In Progress') {
            newState = 'Påbegynt';
            bgColor = '#FDDFB0';
        } else if (event.target.value === 'Completed') {
            newState = 'Fullført';
            bgColor = '#C3E8D0';
        }

        let newValue = {
            id: event.target.value,
            state: newState,
            backgroundColor: bgColor,
        };

        const isValid = await apiRef.current.setEditCellValue({
            id,
            field,
            value: newValue,
        });

        let inputData = {
            id: id,
            status: event.target.value,
        };

        await updateTaskData(inputData);

        if (isValid) {
            apiRef.current.stopCellEditMode({ id, field });
        }
    };

    return (
        <NativeSelect
            value={value.id}
            onChange={handleChange}
            input={<InputBase sx={{ boxShadow: 'none', backgroundColor: '#ffffff' }} />}
        >
            <option value="Not Started">Ikke påbegynt</option>
            <option value="In Progress">Påbegynt</option>
            <option value="Completed">Fullført</option>
        </NativeSelect>
    );
}

EditStatusCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any.isRequired,
};

function renderStatusEditInputCell(params, updateTaskData) {
    return <EditStatusCell {...params} updateTaskData={updateTaskData} />;
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: '#756854',
    },
    '& .ant-empty-img-2': {
        fill: '#ffffff',
    },
    '& .ant-empty-img-3': {
        fill: '#FDDFB0',
    },
    '& .ant-empty-img-4': {
        fill: '#ffffff',
    },
    '& .ant-empty-img-5': {
        fillOpacity: '0.8',
        fill: '#f5f5f5',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false" style={{ zIndex: 99 }}>
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1, zIndex: 99 }}>Lista med aktiviteter er tom!</Box>
        </StyledGridOverlay>
    );
}

export default function ChoresList(props) {
    const auth = useAuth();
    const router = useRouter();
    const [tasksData, setTasksData] = useState([]);
    const [ownFilteredTasksData, setOwnFilteredTasksData] = useState([]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            createDataGridTasks();
        }

        return () => {
            isMounted = true;
        };
    }, [props.tasks]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateTaskData = async input => {
        await updateTask(input).then(async () => {
            await props.updateTasks();
        });
    };

    const createDataGridTasks = () => {
        if (!props.tasks) {
            return;
        }

        let newArr = [];

        for (const task of props.tasks) {
            let dataObj = {
                id: task.id,
                Title: task.title,
                DelegatedUsers: task.users,
                Made: new Date(task.createdAt).toLocaleDateString(),
                Go: task,
            };

            if (task.dueDate) {
                dataObj.DueDate = new Date(task.dueDate).toLocaleDateString();
            } else {
                dataObj.DueDate = 'Ingen';
            }

            setTaskStatus(task, dataObj);

            setCriteriaType(task, dataObj);

            newArr.push(dataObj);
        }

        setTasksData([...newArr.filter(data => data.Status.id !== 'Completed')]);

        let filtered = newArr.filter(data => data.DelegatedUsers.includes(auth.userData.id));

        setOwnFilteredTasksData([...filtered]);
    };

    const setTaskStatus = async (task, dataObj) => {
        if (task.status === 'Not Started') {
            dataObj.Status = {
                id: 'Not Started',
                state: 'Ikke påbegynt',
                backgroundColor: '#F3CCD1',
            };
        } else if (task.status === 'In Progress') {
            dataObj.Status = {
                id: 'In Progress',
                state: 'Påbegynt',
                backgroundColor: '#FDDFB0',
            };
        } else if (task.status === 'Completed') {
            dataObj.Status = {
                id: 'Completed',
                state: 'Fullført',
                backgroundColor: '#C3E8D0',
            };
        }
    };

    const setCriteriaType = (task, dataObj) => {
        // Task is from climate
        if (task.type === 'CLIMATE_CRITERIA') {
            dataObj.Criteria = {
                icon: Climate1,
                backgroundColor: '#AFECE1',
            };
        } else if (task.type === 'CERT_CRITERIA') {
            let criteriaFound = false;

            for (const category of props.criterias) {
                for (const sub of category.subcategories.items) {
                    for (const question of sub.questions.items) {
                        // If the task has a set criteria, figure out its category
                        if (task.criteria === question.id) {
                            if (category.id === 'company') {
                                dataObj.Criteria = {
                                    icon: Business1,
                                    backgroundColor: '#C1E6EB',
                                };
                            } else if (category.id === 'economy') {
                                dataObj.Criteria = {
                                    icon: Profit1,
                                    backgroundColor: '#BBCBE4',
                                };
                            } else if (category.id === 'social') {
                                dataObj.Criteria = {
                                    icon: Social1,
                                    backgroundColor: '#F3CCD1',
                                };
                            } else if (category.id === 'environment') {
                                dataObj.Criteria = {
                                    icon: Environment1,
                                    backgroundColor: '#C3E8D0',
                                };
                            } else if (category.id === 'climate') {
                                dataObj.Criteria = {
                                    icon: Climate1,
                                    backgroundColor: '#AFECE1',
                                };
                            }

                            criteriaFound = true;
                        }
                    }
                }
            }

            // If the task has no set criteria
            if (!criteriaFound) {
                dataObj.Criteria = {
                    icon: ChoresIcon2,
                    backgroundColor: '#FDDFB0',
                };
            }
        }
    };

    const renderResponsible = params => {
        return (
            <AvatarGroup max={3}>
                {params.value.map(userId => {
                    let user = props.users.find(user => {
                        return userId === user.id;
                    });

                    let userName = '';

                    if (user) {
                        userName = JSON.parse(user.name)
                            .fullName.match(/\b\w/g)
                            .join('');
                    }

                    return (
                        <Avatar
                            key={userId}
                            sx={{
                                backgroundColor: '#FDDFB0',
                                color: '#000000',
                                fontSize: '14px',
                            }}
                        >
                            {userName}
                        </Avatar>
                    );
                })}
            </AvatarGroup>
        );
    };

    const getRowSpacing = useCallback(params => {
        return {
            top: params.isFirstVisible ? 5 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
            }}
        >
            <Hidden lgDown>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        autoHeight
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        pagination
                        disableColumnMenu={true}
                        getRowSpacing={getRowSpacing}
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        getRowClassName={() => 'row-style'}
                        sx={{
                            border: 'none',
                            padding: '0px 20px 0px 20px',
                            backgroundColor: '#f4f4f4',
                            '& .cell-style': {
                                borderBottom: '0px',
                                padding: '0px',
                            },
                            '& .header-style': {
                                backgroundColor: '#ffffff',
                            },
                            '& .header-style-2': {
                                backgroundColor: '#ffffff',
                                color: '#ffffff',
                            },
                            '& .row-style': {
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)',
                                '&:hover': {
                                    backgroundColor: '#F6F6F6',
                                },
                            },
                        }}
                        columns={[
                            {
                                field: 'Criteria',
                                align: 'center',
                                headerName: 'Kriterie',
                                flex: 0.2,
                                cellClassName: 'cell-style',
                                headerClassName: 'header-style-2',
                                sortable: false,
                                renderCell: params => (
                                    <Grid
                                        sx={{
                                            backgroundColor: params.value.backgroundColor,
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {params.value.icon}
                                    </Grid>
                                ),
                            },
                            {
                                field: 'Title',
                                headerName: 'Tittel',
                                editable: true,
                                flex: 1,
                                headerClassName: 'header-style',
                                valueSetter: params => {
                                    let newValue = params.value;

                                    let inputData = {
                                        id: params.row.id,
                                        title: newValue,
                                    };

                                    updateTaskData(inputData);

                                    return { ...params.row, newValue };
                                },
                            },
                            {
                                field: 'DelegatedUsers',
                                headerName: 'Ansvarlig(e)',
                                headerClassName: 'header-style',
                                editable: true,
                                flex: 0.5,
                                renderCell: renderResponsible,
                                renderEditCell: params =>
                                    renderMultiSelectEditInputCell(params, updateTaskData, props.users),
                                sortComparator: (a, b) => a.length - b.length,
                            },
                            {
                                field: 'Made',
                                headerName: 'Opprettet',
                                flex: 0.3,
                                headerClassName: 'header-style',
                            },
                            {
                                field: 'DueDate',
                                headerName: 'Forfallsdato',
                                type: 'date',
                                editable: true,
                                flex: 0.3,
                                headerClassName: 'header-style',
                                valueSetter: params => {
                                    let newValue = params.value;

                                    if (params.value === 'Ingen') {
                                        newValue = null;
                                    }

                                    let inputData = {
                                        id: params.row.id,
                                        dueDate: newValue,
                                    };

                                    updateTaskData(inputData);

                                    if (params.value) {
                                        newValue = 'Ingen';
                                    }

                                    return { ...params.row, newValue };
                                },
                            },
                            {
                                field: 'Status',
                                headerName: 'Status',
                                align: 'center',
                                editable: true,
                                headerAlign: 'center',
                                headerClassName: 'header-style',
                                flex: 0.5,
                                renderCell: params => (
                                    <Chip
                                        label={params.value.state}
                                        sx={{
                                            width: '110px',
                                            backgroundColor: params.value.backgroundColor,
                                        }}
                                    ></Chip>
                                ),
                                renderEditCell: params => renderStatusEditInputCell(params, updateTaskData),
                                sortComparator: (a, b) =>
                                    (a.state === 'Fullført') - (b.state === 'Fullført') || a.state - b.state,
                            },
                            {
                                field: 'Go',
                                headerName: '->',
                                headerClassName: 'header-style-2',
                                flex: 0.3,
                                sortable: false,
                                renderCell: params => (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ backgroundColor: '#EBEBEB' }}
                                        onClick={() =>
                                            router.push({
                                                pathname: '/activities/' + params.value.id,
                                                state: {
                                                    data: params.value,
                                                    users: props.users,
                                                    criterias: props.criterias,
                                                },
                                            })
                                        }
                                    >
                                        {ArrowRight}
                                    </Button>
                                ),
                            },
                        ]}
                        rows={props.showMyTasks ? ownFilteredTasksData : tasksData}
                    />
                </div>
            </Hidden>
            <Hidden lgUp>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        autoHeight
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        pagination
                        disableColumnMenu={true}
                        getRowSpacing={getRowSpacing}
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        getRowClassName={() => 'row-style'}
                        sx={{
                            border: 'none',
                            padding: '0px 20px 0px 20px',
                            backgroundColor: '#f4f4f4',
                            '& .cell-style': {
                                borderBottom: '0px',
                                padding: '0px',
                            },
                            '& .header-style': {
                                backgroundColor: '#ffffff',
                            },
                            '& .header-style-2': {
                                backgroundColor: '#ffffff',
                                color: '#ffffff',
                            },
                            '& .row-style': {
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)',
                                '&:hover': {
                                    backgroundColor: '#F6F6F6',
                                },
                            },
                        }}
                        columns={[
                            {
                                field: 'Criteria',
                                align: 'center',
                                headerName: 'Kriterie',
                                flex: 0.2,
                                cellClassName: 'cell-style',
                                headerClassName: 'header-style-2',
                                sortable: false,
                                renderCell: params => (
                                    <Grid
                                        sx={{
                                            backgroundColor: params.value.backgroundColor,
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {params.value.icon}
                                    </Grid>
                                ),
                            },
                            {
                                field: 'Title',
                                headerName: 'Tittel',
                                editable: true,
                                flex: 0.6,
                                headerClassName: 'header-style',
                                valueSetter: params => {
                                    let newValue = params.value;

                                    let inputData = {
                                        id: params.row.id,
                                        title: newValue,
                                    };

                                    updateTaskData(inputData);

                                    return { ...params.row, newValue };
                                },
                            },
                            {
                                field: 'DelegatedUsers',
                                headerName: 'Ansvarlig(e)',
                                headerClassName: 'header-style',
                                editable: true,
                                flex: 0.4,
                                renderCell: renderResponsible,
                                renderEditCell: params =>
                                    renderMultiSelectEditInputCell(params, updateTaskData, props.users),
                                sortComparator: (a, b) => a.length - b.length,
                            },
                            {
                                field: 'DueDate',
                                headerName: 'Forfallsdato',
                                type: 'date',
                                editable: true,
                                flex: 0.3,
                                headerClassName: 'header-style',
                                valueSetter: params => {
                                    let newValue = params.value;

                                    if (params.value === 'Ingen') {
                                        newValue = null;
                                    }

                                    let inputData = {
                                        id: params.row.id,
                                        dueDate: newValue,
                                    };

                                    updateTaskData(inputData);

                                    if (params.value) {
                                        newValue = 'Ingen';
                                    }

                                    return { ...params.row, newValue };
                                },
                            },
                            {
                                field: 'Status',
                                headerName: 'Status',
                                align: 'center',
                                editable: true,
                                headerAlign: 'center',
                                headerClassName: 'header-style',
                                flex: 0.5,
                                renderCell: params => (
                                    <Chip
                                        label={params.value.state}
                                        sx={{
                                            width: '110px',
                                            backgroundColor: params.value.backgroundColor,
                                        }}
                                    ></Chip>
                                ),
                                renderEditCell: params => renderStatusEditInputCell(params, updateTaskData),
                                sortComparator: (a, b) =>
                                    (a.state === 'Fullført') - (b.state === 'Fullført') || a.state - b.state,
                            },
                            {
                                field: 'Go',
                                headerName: '->',
                                headerClassName: 'header-style-2',
                                flex: 0.3,
                                sortable: false,
                                renderCell: params => (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ backgroundColor: '#EBEBEB' }}
                                        onClick={() =>
                                            router.push({
                                                pathname: '/activities/' + params.value.id,
                                                state: {
                                                    data: params.value,
                                                    users: props.users,
                                                    criterias: props.criterias,
                                                },
                                            })
                                        }
                                    >
                                        {ArrowRight}
                                    </Button>
                                ),
                            },
                        ]}
                        rows={props.showMyTasks ? ownFilteredTasksData : tasksData}
                    />
                </div>
            </Hidden>
        </div>
    );
}
