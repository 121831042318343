import { createTheme } from "@mui/material/styles";
import "../../../index.css";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: "#fff",
  },
}));

const Yanone = "'Yanone Kaffeesatz', sans-serif";
const OpenSansCondensed = "'Open Sans Condensed', sans-serif";
const OpenSans = "'Open Sans', sans-serif";

export const theme = createTheme({
  palette: {
    action: {
      selected: "#FEF0D9",
      hover: "#FEF0D9",
      disabledBackground: "#eaeaea",
      disabled: "#c8c8c8",
    },
    primary: {
      main: "#FDDFB0",
      light: "#FEF0D9",
    },
    secondary: {
      main: "#FFD0BE",
      light: "#FFEEE7",
    },
    error: {
      main: "#FFD0BE",
      light: "#FFEEE7",
    },
    warning: {
      main: "#FDDFB0",
    },
    info: {
      main: "#BDCCD4",
    },
    success: {
      main: "#C3E8D0",
      light: "#E4F5EA",
    },
    background: {
      main: "#f5f5f5",
    },
    font: {
      main: "#000",
    },
  },
  typography: {
    body1: {
      fontFamily: OpenSans,
      fontSize: "15px",
      fontWeight: 100,
    },
    body2: {
      fontFamily: OpenSansCondensed,
      fontSize: "15px",
    },
    h1: {
      fontFamily: Yanone,
      fontSize: "30px",
      textAlign: "center",
      borderBottom: "2px solid #ffffff",
      margin: "10px 0px 20px 0px",
    },
    h2: {
      fontFamily: OpenSansCondensed,
      fontSize: "25px",
      marginBottom: "10px",
      textAlign: "left",
    },
    h3: {
      fontFamily: OpenSansCondensed,
      fontWeight: 300,
      fontSize: "1.6em",
      textAlign: "left",
      padding: "5px 0px",
    },
    h4: {
      fontFamily: OpenSansCondensed,
      fontSize: "20px",
      fontWeight: 100,
    },
    h5: {
      fontFamily: OpenSansCondensed,
      fontSize: "30px",
      fontWeight: "bold",
      margin: "0px",
      width: "60px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    h6: {
      fontFamily: OpenSansCondensed,
      fontSize: "14px",
      textAlign: "center",
    },
    button: {
      fontFamily: OpenSansCondensed,
      fontSize: "1em",
      textTransform: "none",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: OpenSans + "!important",
          fontSize: "1em",
          background: "#F6F6F6",
          boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          width: "100%",
          border: "none",
          color: "#000",
          margin: "10px 0px",
        },
        input: {
          padding: "10px",
          height: "30px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          fontFamily: OpenSans + "!important",
          fontSize: "1em",
          background: "#F6F6F6",
          boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          width: "100%",
          height: "49px",
          border: "none",
          color: "#000",
          margin: "10px 0px",
          padding: " 0px 7px",
          '&&[class*="MuiInput-root"] $input': {
            /* padding: "12px 5px 12px !important", */
            zIndex: "9999",
          },
        },
        input: {
          padding: "5.5px 4px 7.5px 6px !important",
        },
        paper: {
          backgroundColor: "#f6f6f6",
          padding: "10px",
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        root: {
          height: "50px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: OpenSans,
          fontSize: "1em",
          color: "#000000",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: OpenSans,
          fontSize: "1em",
        },
      },
    },
    MuiButton: {
      root: {
        color: "#000",
      },
      styleOverrides: {
        root: {
          color: "#000",
        },
        contained: {
          backgroundColor: "#FDDFB0",
          width: 150,
          height: 50,
          fontFamily: OpenSansCondensed,
          textTransform: "none",
          fontSize: 15,
          borderRadius: 5,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#FEF0D9",
          },
        },
        text: {
          textDecoration: "underline",
          fontsize: 14,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {},
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "3.5px 4px 3.5px 6px !important",
          border: "none",
          fontFamily: OpenSansCondensed,
        },
        notchedOutline: {
          border: "none",
        },
        input: {
          padding: "6px 5px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          backgroundColor: "#F5F5F5",
          borderRadius: 0,
          boxShadow: "none",
          padding: "40px",
        },
        elevation2: {
          boxShadow:
            "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
          background: "#fff",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          border: "0px",
        },
        docked: {
          width: 300,
          flexShrink: 0,
        },
        paper: {
          width: 300,
          border: "none",
          boxShadow:
            "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          alignItems: "flex-start",
          padding: "20px 0px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: "100%",
          fontFamily: OpenSans,
          fontSize: "0.9em",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "#FEF0D9",
          },
          "&$selected": {
            backgroundColor: "#FDDFB0",
          },
          "&.Mui-selected": {
            backgroundColor: "#FEF0D9",
            borderRadius: "0px",
            "&.Mui-focusVisible": { background: "#FEF0D9" },
            "&:hover": {
              backgroundColor: "#FEF0D9",
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: "10px",
        },
        paper: {
          boxShadow: "0px !important",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: "0px",
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: OpenSansCondensed,
          fontSize: "0.9em",
          width: 30,
          height: 30,
          backgroundColor: "#fff",
          color: "#000",
          marginRight: 20,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "12px",
          borderBottom: "none",
          fontFamily: OpenSans,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginBottom: "10px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        multiple: {
          height: "30px",
          display: "flex",
          alignItems: "center",
        },
        nativeInput: {
          padding: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: Yanone,
          fontWeight: 300,
          fontSize: "25px !important",
          textAlign: "left",
          backgroundColor: "#E2EFF1",
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          display: "flex",
          padding: "10px 20px",
          marginBottom: "10px",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "#000000",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        borderTop: "2px solid #EBEBEB",
        paddingTop: "10px",
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "13px",
        },
      },
    },
  },
});

export const dateTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1em !important",
          background: "#f6f6f6",
          boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          width: "100%",
          border: "none",
          color: "#000000 !important",
          margin: "10px 0px",
          padding: "7px",
          fontFamily: OpenSansCondensed,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: OpenSans,
          fontWeight: 100,
          fontSize: "14px",
          border: "none",
          backgroundColor: "#f6f6f6",
          textDecoration: "underline",
          color: "#000000",
          padding: "14.5px 12px",
          margin: "10px 0px",
        },
        notchedOutline: {
          border: "none",
        },
        input: {
          padding: "0px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "0px",
          marginRight: "0px !important",
        },
      },
    },
  },
});
