import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  Button,
  Divider,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import YearPeriodInput from "./YearPeriodInput";
import TitleInput from "./TitleInput";
import AreaInput from "./AreaInput";
import Statement from "./Statement";
import { useStyles } from "../../ClimateStyles";
import Leaves from "../../../../../assets/images/leaves.svg";
import * as Text from "../../../../../helpers/climateAccounting.json";
import {
  ArrowRight,
  Finished,
  UnFinished,
} from "../../../../../components/Icons";

export default function KeyInformationForm(props) {
  const classes = useStyles();

  const [openStates, setOpenStates] = useState([false, false, false]);

  const CollapseColor = (isAnswered, open) => {
    if (isAnswered) {
      return "#FEF0D9";
    } else if (open) {
      return "#EBEBEB";
    } else {
      return "#FFFFFF";
    }
  };

  const CheckedColor = (isAnswered) => {
    if (isAnswered) {
      return "#FEF0D9";
    } else {
      return "#EBEBEB";
    }
  };

  const IconChange = (isAnswered) => {
    if (isAnswered) {
      return Finished;
    } else {
      return UnFinished;
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const changeOpen = (index, state) => {
    let newArr = openStates;

    newArr[index] = state;

    setOpenStates([...newArr]);
  };

  const openNext = (current) => {
    let newArr = openStates;

    newArr[current] = false;
    newArr[current + 1] = true;

    setOpenStates([...newArr]);
  };

  return (
    <Paper elevation={1}>
      <Grid item xs={12}>
        <Paper elevation={2} sx={{ display: "flex", flexDirection: "row" }}>
          <Grid item xs={2} className={classes.summaryContent}>
            <img
              src={Leaves}
              alt="Yellow and blue leaves"
              className="summaryImage"
            ></img>
          </Grid>
          <Grid item xs={10} className={classes.summaryDescription}>
            <Typography variant="body1">
              {Text.KeyInformation.Summary}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            id="keyinformation"
            className={classes.title}
          >
            Nøkkelinformasjon
          </Typography>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              <TitleInput
                report={props.report}
                updateReport={props.updateReport}
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                index={0}
                open={openStates[0]}
                changeOpen={changeOpen}
                openNext={openNext}
              />
              <YearPeriodInput
                report={props.report}
                updateReport={props.updateReport}
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                index={1}
                open={openStates[1]}
                changeOpen={changeOpen}
                openNext={openNext}
              />
              <AreaInput
                report={props.report}
                updateReport={props.updateReport}
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                index={2}
                open={openStates[2]}
                changeOpen={changeOpen}
                openNext={openNext}
              />
              <Statement
                report={props.report}
                updateReport={props.updateReport}
                CollapseColor={CollapseColor}
                CheckedColor={CheckedColor}
                IconChange={IconChange}
                index={3}
                open={openStates[3]}
                changeOpen={changeOpen}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Divider sx={{ margin: "30px 0px" }} />
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          sx={{ marginRight: "20px", backgroundColor: "#FDDFB0" }}
          onClick={scrollToTop}
          component={NavLink}
          to={"/climate/" + props.report.id + "/scope1"}
          exact
        >
          Neste {ArrowRight}
        </Button>
      </Grid>
    </Paper>
  );
}
