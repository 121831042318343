import React, { useState } from 'react';
import { useAuth } from '../../../../auth/useAuth';
import useToggle from '../../../../hooks/useToggle';
import SnackPopup from '../../../../components/SnackPopup';
import { Loading } from '../../../../components/Loading';
import axios from 'axios';
import { Grid, Typography, TextField, InputAdornment, Button, Autocomplete } from '@mui/material';
import { Search } from '../../../../components/Icons';
import { checkForCompany, deleteAccount } from '../termsFunctions';
import { useStyles } from '../TermsStyle';
import DialogPopUp from '../../../../components/DialogPopup';
import { prodCheck } from '../../../../helpers/globalValues';

const dummyCompany = {
    organisasjonsnummer: '000000000',
    navn: 'TEST SELSKAP AS',
    organisasjonsform: {
        kode: 'AS',
        beskrivelse: 'Aksjeselskap',
        _links: {
            self: {
                href: 'https://data.brreg.no/enhetsregisteret/api/organisasjonsformer/AS',
            },
        },
    },
    registreringsdatoEnhetsregisteret: '2022-05-12',
    registrertIMvaregisteret: true,
    naeringskode1: {
        beskrivelse: 'Programmeringstjenester',
        kode: '62.010',
    },
    antallAnsatte: 1,
    forretningsadresse: {
        land: 'Norge',
        landkode: 'NO',
        postnummer: '3123',
        poststed: 'TESTPOSTSTED',
        adresse: ['Testveien 23'],
        kommune: 'TESTKOMMUNE',
        kommunenummer: '3123',
    },
    stiftelsesdato: '2022-05-12',
    institusjonellSektorkode: {
        kode: '2100',
        beskrivelse: 'Private aksjeselskaper mv.',
    },
    registrertIForetaksregisteret: true,
    registrertIStiftelsesregisteret: false,
    registrertIFrivillighetsregisteret: false,
    sisteInnsendteAarsregnskap: '2022',
    konkurs: false,
    underAvvikling: false,
    underTvangsavviklingEllerTvangsopplosning: false,
    maalform: 'Bokmål',
    _links: {
        self: {
            href: 'https://data.brreg.no/enhetsregisteret/api/enheter/924828099',
        },
    },
};

export default function SelectCompany(props) {
    const classes = useStyles();
    const auth = useAuth();

    const [isLoading, setIsLoading] = useToggle();
    const [isOpen, setIsOpen] = useToggle();

    const [popupState, setPopupState] = useToggle();
    const [popupMsg, setPopupMsg] = useState('');

    const [timerDelay, setTimerDelay] = useState(null);
    const [companies, setCompanies] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState('');

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState();
    };

    const handleClosePopup = () => {
        setPopupState();
    };

    // Function that runs when autocomplete is typed into it
    const handleGetCompanies = event => {
        let searchText = event.currentTarget.value;

        if (timerDelay) {
            clearTimeout(timerDelay);
        }

        setTimerDelay(
            setTimeout(() => {
                handleFetchCompanies(searchText);
            }, 1000),
        );
    };

    // Fetches the options from Brreg that is displayed to the user upon autocomplete input
    const handleFetchCompanies = async navn => {
        let url =
            'https://data.brreg.no/enhetsregisteret/api/enheter?konkurs=false&underAvvikling=false&underTvangsavviklingEllerTvangsopplosning=false';

        if (navn) {
            url = url + `&navn="${navn}"`;
        } else {
            return;
        }

        await axios.get(url).then(res => {
            if (
                res !== undefined &&
                res.data !== undefined &&
                res.data._embedded !== undefined &&
                res.data._embedded.enheter !== undefined
            ) {
                setCompanies([...res.data._embedded.enheter]);
            }
        });
    };

    const handleContinue = async event => {
        event.preventDefault();

        if (selectedCompany === '') {
            showPopup('Du må velge en organisasjon');
            return;
        }

        setIsLoading();

        // Send user to correct view based on if their selected company exists or not
        await checkForCompany(selectedCompany.organisasjonsnummer).then(companyExists => {
            if (companyExists) {
                props.updateBrregData(selectedCompany);
                props.setView('join');
            } else {
                props.updateBrregData(selectedCompany);
                props.setView('create');
            }
        });
    };

    const handleDummyContinue = async event => {
        event.preventDefault();

        let dummy = dummyCompany;

        dummy.organisasjonsnummer = Math.floor(100000000 + Math.random() * 900000000);

        setIsLoading();

        props.updateBrregData(dummy);
        props.setView('create');
    };

    const handleDeleteAccount = async () => {
        setIsLoading();

        await deleteAccount(auth).catch(() => {
            showPopup('En feil oppsto ved sletting av konto, vennligst prøv igjen');
            setIsLoading();
        });
    };

    return (
        <Grid item xs={12} className="content">
            {!prodCheck ? (
                <DialogPopUp
                    openDialog={isOpen}
                    handleCloseDialog={() => setIsOpen(false)}
                    fullWidth={true}
                    maxWidth="md"
                    title="Fiktiv organisasjon"
                    text="En fiktiv organisasjon vil ha tilfeldig data som ikke samsvarer med noen eksisterende bedrifter og er kun ment for testing av portalen. Vi benytter oss av Brønnøysundregisteret for å skaffe data, men det blir heller fylt inn med dummy-info. Hvis dette er forstått, så kan du klikke på fortsett."
                    addButton={
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                width: 130,
                                backgroundColor: '#F3CCD1',
                            }}
                            onClick={handleDummyContinue}
                        >
                            Fortsett
                        </Button>
                    }
                />
            ) : (
                <></>
            )}
            <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
            <Typography variant="h2">Finn organisasjonen din</Typography>
            <Typography variant="body1">Bruk søkerfeltet under til å finne din organisasjon.</Typography>
            <Grid item>
                <Autocomplete
                    autoComplete={false}
                    id="company-selector"
                    forcePopupIcon={true}
                    style={{ border: 'none' }}
                    sx={{ height: '50px' }}
                    options={companies}
                    getOptionLabel={option => option.navn + ' (Org. nr. ' + option.organisasjonsnummer + ')'}
                    onChange={(e, value) => setSelectedCompany(value)}
                    renderInput={params => (
                        <TextField
                            required
                            error
                            name="company"
                            type="text"
                            autoComplete="off"
                            onChange={e => handleGetCompanies(e)}
                            {...params}
                            style={{ border: 'none' }}
                            placeholder="Organisasjon..."
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: <InputAdornment position="start">{Search}</InputAdornment>,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item className="buttonGrid" sx={{ marginTop: '20px' }}>
                {isLoading ? (
                    <Loading color="primary" />
                ) : (
                    <>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Button
                                variant="contained"
                                className={classes.fiftyButton}
                                onClick={e => handleContinue(e)}
                            >
                                Gå videre
                            </Button>
                            <Button variant="contained" className={classes.greyButton} onClick={handleDeleteAccount}>
                                Slett konto
                            </Button>
                        </Grid>
                        {!prodCheck ? (
                            <Grid sx={{ display: 'flex', paddingTop: '10px' }}>
                                <Button
                                    variant="contained"
                                    className={classes.buttonFullWidth}
                                    onClick={() => setIsOpen(true)}
                                >
                                    Lag fiktiv organisasjon
                                </Button>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    );
}
