import { makeStyles, withStyles } from "@mui/styles";
import { MenuItem, TableCell, TableRow, TableHead } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

const OpenSans = "'Open Sans', sans-serif";
const OpenSansCondensed = "'Open Sans Condensed', sans-serif";

export const useStyles = makeStyles((theme) => ({
  //Sidebar
  active: {
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
    fontSize: "16px",
  },
  connector: {
    marginLeft: "30px",
    height: "14px",
    borderLeft: "1px dashed #000",
  },
  helpButton: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  //Modules
  modulesFooter: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  progressionText: {
    padding: "10px",
    textAlign: "center",
  },
  overlay: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "rgb(255,255,255,0.9)",
    borderRadius: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    top: "0px",
    left: "0px",
  },
  //Summary
  stats: {
    borderLeft: "1px solid #DCDCDC",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  statsBorder: {
    borderBottom: "1px solid #DCDCDC",
    padding: "20px 20px",
  },
  summaryContent: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
  summaryPoints: {
    backgroundColor: "#F2F2F2",
    padding: "10px",
    borderRadius: 100,
    textAlign: "center",
  },
  summaryDescription: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  //Form
  mainContainer: {
    display: "flex",
    backgroundColor: "#F1F1F1",
    height: "100%",
    [breakpoints.down("md")]: {
      marginTop: "60px",
    },
  },
  mainSection: {
    padding: "40px",
    [breakpoints.down("lg")]: {
      padding: "0px",
    },
  },
  iconCell: {
    borderBottom: "none",
    width: "65px",
    height: "65px",
    display: "flex",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    [breakpoints.down("lg")]: {
      width: "55px",
      height: "55px",
    },
  },
  questionRow: {
    width: "100%",
    padding: "0px 10px",
    [breakpoints.down("lg")]: {
      padding: "0px 10px",
    },
  },
  questionTitle: {
    padding: "0px",
    [breakpoints.down("lg")]: {
      fontSize: "18px",
    },
  },
  row: {
    background: "#fff",
    boxShadow:
      "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
  },
  content: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "25px 0px 0px 0px",
    borderTop: "2px solid #EBEBEB",
    padding: "20px 0px 0px 0px",
  },
  points: {
    backgroundColor: "#F2F2F2",
    padding: "10px",
    borderRadius: 100,
  },
  question: {
    boxShadow:
      "0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)",
  },
  nextButton: {
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FEF0D9",
    },
  },
  readmore: {
    fontSize: "18px",
    padding: "6px 0px",
  },
  countText: {
    margin: "0px 0px 25px 0px",
    fontsize: "16px",
    width: "100%",
    textAlign: "right",
  },
  // New Session
  section: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  logoSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
  startButton: {
    width: "200px",
    height: "50px",
    backgroundColor: "#FDDFB0",
  },
  newSesh: {
    display: "flex",
    flexDirection: "row",
  },
  step: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selected: {
    backgroundColor: "#FEF0D9 !important",
    borderRadius: "0px !important",
  },
  sessionButton: {
    width: "200px",
    height: "50px",
  },
  unitSelect: {
    width: "66px",
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  menuItem: {
    fontFamily: OpenSansCondensed,
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "&$selected": {
      backgroundColor: "#ffffff",
    },
    "&.Mui-selected": {
      backgroundColor: "#ffffff",
      borderRadius: "0px",
      "&.Mui-focusVisible": { background: "#ffffff" },
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    },
  },
}));

export const CustomRow = withStyles(() => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f3f3f3",
      borderTop: "1px solid #EBEBEB",
      borderBottom: "1px solid #EBEBEB",
    },
  },
}))(TableRow);

export const CustomCell = withStyles(() => ({
  root: {
    padding: "0px 5px",
    borderBottom: "0px",
    fontSize: "14px",
    height: "50px",
    fontWeight: 300,
  },
  head: {
    padding: "10px 10px 0px 10px",
    fontWeight: 300,
    fontSize: "15px",
  },
  body: {
    borderRight: "1px solid #E8E8E8",
    "&:last-child": {
      borderRight: "0px",
    },
  },
}))(TableCell);

export const TotalRow = withStyles(() => ({
  root: {
    borderTop: "2px solid #DBDBDB",
    borderBottom: "6px double #FDDFB0",
  },
}))(TableRow);

export const TotalCell = withStyles(() => ({
  root: {
    padding: "0px 10px",
    borderBottom: "0px",
    fontSize: "14px",
    height: "50px",
    fontFamily: OpenSans,
    fontWeight: 700,
  },
}))(TableCell);

export const CustomMenuItem = withStyles((props) => ({
  root: {
    width: "100%",
    fontFamily: OpenSans,
    fontSize: "14px",
    borderRadius: "0px",
    padding: "0px",
    "&:hover": {
      backgroundColor: "#FEF0D9",
    },
  },
}))(MenuItem);

export const ChoreRow = withStyles(() => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f3f3f3",
      borderTop: "1px solid #EBEBEB",
      borderBottom: "1px solid #EBEBEB",
    },
  },
}))(TableRow);

export const ChoreCell = withStyles(() => ({
  root: {
    padding: 10,
    borderBottom: "0px",
    fontWeight: "100",
    fontSize: "14px",
  },
  head: {
    padding: "10px 10px 0px 10px",
  },
  body: {
    borderRight: "1px solid #E8E8E8",
    "&:last-child": {
      borderRight: "0px",
    },
  },
}))(TableCell);

export const ChoreHead = withStyles(() => ({
  root: {
    borderBottom: "4px solid #FDDFB0",
  },
}))(TableHead);
