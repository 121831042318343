import React from 'react';
import { TextField } from '@mui/material';

export default function Textfield(props) {
    const { data, longText, setLongText, setPointsEarned, setIsAnswered, minChars, maxChars } = props;

    const handleInput = value => {
        if (value.length >= minChars && value.length > 0) {
            setIsAnswered(true);
            setPointsEarned(data.pointValue);
        } else {
            setIsAnswered(false);
            setPointsEarned(0);
        }

        setLongText(value);
    };

    return (
        <TextField
            id={data.id}
            type="text"
            multiline
            fullWidth
            rows={5}
            className="input"
            name={data.question}
            value={longText}
            onChange={e => handleInput(e.target.value)}
            placeholder="Skriv her..."
            inputProps={{ maxLength: maxChars }}
        />
    );
}
