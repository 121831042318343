import React from 'react';
import { InputLabel, Grid, TextField, Typography } from '@mui/material';
import { useStyles } from './../../NSRSStyles';

export default function Textfield(props) {
    const { data, input, setInput, isInputsDisabled, reportIsCompleted } = props;
    const classes = useStyles();

    const handleChange = ({ target }) => {
        setInput({
            ...input,
            [target.id]: target.value,
        });
    };

    return (
        <Grid>
            <InputLabel>
                {data.label} {data.isRequired ? '*' : <></>}
            </InputLabel>
            <TextField
                id={data.id}
                type="text"
                multiline
                fullWidth
                rows={5}
                className="input"
                placeholder={!reportIsCompleted ? 'Skriv her...' : ''}
                value={input[data.id] || ''}
                onChange={handleChange}
                inputProps={{
                    maxLength: data.maxChars && data.maxChars !== 0 ? data.maxChars : 2000,
                    readOnly: reportIsCompleted,
                }}
                disabled={isInputsDisabled}
            />
            {data.maxChars && data.maxChars !== 0 ? (
                <Typography variant="body2" className={classes.countText}>
                    {(input[data.id] && input[data.id].length) || 0}/{data.maxChars}
                </Typography>
            ) : (
                <></>
            )}
        </Grid>
    );
}
