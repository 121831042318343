import React, { useState } from "react";
import {
  Hidden,
  Drawer,
  Toolbar,
  Grid,
  MenuList,
  MenuItem,
  AppBar,
  IconButton,
  Button,
  Typography,
  Avatar,
} from "@mui/material";
import { Arrow, Hamburger } from "../../../../components/Icons";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useStyles } from "../CertificationStyles";

export default function CertSidebar(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (open) => {
    setMobileOpen(open);
  };

  return (
    <Grid>
      <Hidden mdDown>
        <Drawer variant="permanent" anchor="left">
          <Grid
            item
            xs={12}
            style={{ backgroundColor: props.colorCode, padding: "20px" }}
          >
            <Button
              type="text"
              startIcon={Arrow}
              component={NavLink}
              to="/declaration"
              exact
            >
              Gå tilbake
            </Button>
            <Typography variant="h1">{props.data.name}</Typography>
            <MenuList>
              {props.data.subcategories &&
                props.data.subcategories.items
                  .sort((a, b) => {
                    return a.order - b.order;
                  })
                  .map((sub, i, row) => {
                    if (i + 1 === row.length) {
                      // Last one.
                      return (
                        <Link
                          smooth
                          to={"/declaration/" + props.data.id + "#" + (i + 1)}
                          className={classes.link}
                          active={classes.active}
                          key={sub.id}
                        >
                          <MenuItem>
                            <Avatar>{i + 1}</Avatar>

                            {sub.name}
                          </MenuItem>
                        </Link>
                      );
                    } else {
                      // Not last one.
                      return (
                        <Grid key={sub.id}>
                          <Link
                            smooth
                            to={"/declaration/" + props.data.id + "#" + (i + 1)}
                            className={classes.link}
                            active={classes.active}
                          >
                            <MenuItem>
                              <Avatar>{i + 1}</Avatar>
                              {sub.name}
                            </MenuItem>
                          </Link>
                          <div className={classes.connector} />
                        </Grid>
                      );
                    }
                  })}
            </MenuList>
          </Grid>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <AppBar position="fixed">
          <Toolbar
            sx={{
              minHeight: "40px !important",
              backgroundColor: props.colorCode,
              padding: "10px 0px",
            }}
          >
            <IconButton onClick={() => handleDrawerToggle(true)}>
              {Hamburger}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          anchor="left"
          onClick={() => handleDrawerToggle(false)}
          onClose={() => handleDrawerToggle(false)}
        >
          <Grid
            item
            xs={12}
            style={{ backgroundColor: props.colorCode, padding: "20px" }}
          >
            <Button
              type="text"
              startIcon={Arrow}
              component={NavLink}
              to="/declaration"
              exact
            >
              Gå tilbake
            </Button>
            <Typography variant="h1">{props.data.name}</Typography>
            <MenuList>
              {props.data.subcategories &&
                props.data.subcategories.items
                  .sort((a, b) => {
                    return a.order - b.order;
                  })
                  .map((sub, i, row) => {
                    if (i + 1 === row.length) {
                      // Last one.
                      return (
                        <Link
                          smooth
                          to={"/declaration/" + props.data.id + "#" + (i + 1)}
                          className={classes.link}
                          active={classes.active}
                          key={sub.id}
                        >
                          <MenuItem>
                            <Avatar>{i + 1}</Avatar>

                            {sub.name}
                          </MenuItem>
                        </Link>
                      );
                    } else {
                      // Not last one.
                      return (
                        <Grid key={sub.id}>
                          <Link
                            smooth
                            to={"/declaration/" + props.data.id + "#" + (i + 1)}
                            className={classes.link}
                            active={classes.active}
                          >
                            <MenuItem>
                              <Avatar>{i + 1}</Avatar>
                              {sub.name}
                            </MenuItem>
                          </Link>
                          <div className={classes.connector} />
                        </Grid>
                      );
                    }
                  })}
            </MenuList>
          </Grid>
        </Drawer>
      </Hidden>
    </Grid>
  );
}
