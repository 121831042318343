import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid, Typography, Divider, List, ListItem } from "@mui/material";
import Image1 from "../../../assets/images/nsrs1.svg";
import * as Text from "../../../helpers/tutorial.json";

export default function NSRS() {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={10000}
      centerMode={false}
      containerClass="container"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
        },
      }}
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image1}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.nsrsSlide1.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.nsrsSlide1.p1}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.nsrsSlide1.p2}
          </Typography>
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.nsrsSlide1.p3}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <img
            src={Image1}
            alt="Temporary placeholder"
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "87%",
              marginLeft: 60,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 78px 0px 20px" }}>
          <Typography variant="h2">{Text.nsrsSlide2.title}</Typography>
          <Divider />
          <br />
          <Typography style={{ fontSize: "14px" }}>
            {Text.nsrsSlide2.p1}
          </Typography>
          <List>
            <ListItem>
              - Informasjon om selskapet; organisasjonsnummer og registre hvor
              bedriften er registrert, eierstruktur, nacekoder, komitèer etc.
            </ListItem>
            <ListItem>
              - Informasjon om de ansatte, personalpolitikk og
              rekrutteringspolitikk.
            </ListItem>
            <ListItem>- Oversikt over leverandører og interessenter</ListItem>
            <ListItem>
              - Bedriftens forbruk; strømforbruk, avfall, utslipp etc
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Carousel>
  );
}
