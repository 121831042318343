import React from "react";
import { Grid, Typography } from "@mui/material";

export default function WaitingOnApproval() {
  return (
    <Grid item xs={12}>
      <Typography variant="h1" style={{ textAlign: "left" }}>
        Forespørsel om tilgang er sendt
      </Typography>
      <Typography variant="body1" style={{ padding: "25px 0px" }}>
        Du får en epost så fort forespørselen er behandlet.
      </Typography>
    </Grid>
  );
}
