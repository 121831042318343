import { makeStyles } from "@mui/styles";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

export const useStyles = makeStyles(() => ({
  //Footer
  footer: {
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "#bdccd4",
  },
  socialWrapper: {
    display: "flex",
    flexDirection: "row !important",
    justifyContent: "center",
    padding: "0px !important",
  },
  socialIcon: {
    margin: "0px 5px",
    width: "50px !important",
    height: "50px !important",
    borderRadius: "100px",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center !important",
    alignItems: "center",
    padding: "0px",
  },
  //Header
  header: {
    backgroundColor: "#BDCCD4",
    width: "100%",
    minHeight: "60px",
    marginBottom: "20px",
    padding: "10px 30px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  //Sidebar
  centerGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    flexDirection: "column",
    padding: "20px 20px 0px 20px",
  },
  sidebarIcon: {
    paddingRight: "10px",
    position: "relative",
    top: "-2px",
  },
  sidebarAvatar: {
    width: "100px !important",
    height: "100px !important",
    backgroundColor: "#bdccd4 !important",
    borderRadius: "100px !important",
    fontSize: "40px !important",
    color: "#000 !important",
    textDecoration: "none",
    cursor: "pointer",
  },
  title: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#000000",
    fontSize: "33px",
  },
  menu: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
  },
  active: {
    backgroundColor: "#FEF0D9",
  },
  botMenu: {
    justifyContent: "flex-end",
    marginBottom: "10px !important",
    padding: "0px 20px",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
  avatar: {
    width: "35px",
    height: "35px",
    backgroundColor: "#FDDFB0;",
    color: "#000000",
    fontSize: "15px",
  },
  badge: {
    [breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
