import React, { useState, useEffect, useContext, createContext, useRef } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useIdleTimer } from 'react-idle-timer';
import moment from 'moment';
import Pool from './userPool';
import { setCredentials, clearCredentials } from './identityPool';

import { API, graphqlOperation } from 'aws-amplify';
import { updateUser, updateCompanyData } from '../graphql/mutations';
import { getUser, listCompanys } from '../graphql/queries';

import amplitude from 'amplitude-js';

const authContext = createContext();

// Provider component to wrap around app and make an auth object
export function AuthProvider({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object
export function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    // Values that updates state and rerenders site
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    // Data of user to keep track of between renders
    const isAdmin = useRef(null);
    const userDataRef = useRef(null);
    const companyDataRef = useRef(null);

    const [userData, setUserData] = useState(userDataRef.current);
    const [companyData, setCompanyData] = useState(companyDataRef.current);

    const userStatusRef = useRef(null);
    const companyStatusRef = useRef(null);

    const [userStatus, setUserStatus] = useState(userStatusRef.current);
    const [companyStatus, setCompanyStatus] = useState(companyStatusRef.current);

    // Local states for functions/logic
    const checkedSession = useRef(true);

    // Runs on app startup
    useEffect(() => {
        let isMounted = false;

        // Check if the user has a valid session
        if (checkedSession.current) {
            getSession()
                .then(session => {
                    if (!isMounted) {
                        if (process.env.NODE_ENV === 'development') {
                            console.log('User session initiated: ', session);
                        }

                        checkedSession.current = true;
                        setIsAuthenticated(true);
                    }
                })
                .catch(err => {
                    if (!isMounted) {
                        if (process.env.NODE_ENV === 'development') {
                            console.error('User session failed to be setup: ', err);
                        }

                        checkedSession.current = true;
                        setIsAuthenticated(false);
                    }
                });
        }

        return () => {
            isMounted = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* LOCAL FUNCTIONS */

    // Function that runs when idle activates
    const handleOnIdle = () => {
        if (process.env.NODE_ENV === 'development') {
            console.log('User session has been expired');
        }

        signOut();
    };

    // Start the idle timer for sessions, currently set to an hour
    const { start, pause, reset } = useIdleTimer({
        timeout: 1000 * 60 * 60,
        startManually: true,
        stopOnIdle: true,
        onIdle: handleOnIdle,
        debounce: 500,
    });

    const updateNewUserData = async inputData => {
        return new Promise(async (resolve, reject) => {
            // Update the user's fields in backend
            await API.graphql(
                graphqlOperation(updateUser, {
                    input: inputData,
                }),
            )
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Successfully updated user:', data);
                    }

                    resolve(data.data.updateUser);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error trying to update user:', err);
                    }

                    reject();
                });
        });
    };

    const updateNewCompanyData = async inputData => {
        return new Promise(async (resolve, reject) => {
            // Update the user's fields in backend
            await API.graphql(
                graphqlOperation(updateCompanyData, {
                    input: inputData,
                }),
            )
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Successfully updated company data:', data);
                    }

                    resolve(data.data.updateCompanyData);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error trying to update company data:', err);
                    }

                    reject();
                });
        });
    };

    // AWS method to get session, intended to only be used in useAuth.js useEffect on first render
    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const cogUser = Pool.getCurrentUser();

            if (cogUser) {
                cogUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        // Get the user's attributes
                        cogUser.getUserAttributes(async (err, attributes) => {
                            if (err) {
                                // Return error if the username or a code is wrong
                                reject(err);
                            } else {
                                setCredentials(session);

                                // Fetch user data from backend is session is successfully setup
                                await fetchUserData(session.idToken.payload.sub)
                                    .then(data => {
                                        userDataRef.current = data;
                                        setUserData(data);
                                        userStatusRef.current = data.status;
                                        setUserStatus(data.status);
                                    })
                                    .catch(err => {
                                        if (process.env.NODE_ENV === 'development') {
                                            console.error('User session failed to be setup: ', err);
                                        }

                                        reject(err);
                                    });

                                // Check for the user's permissions and fetch company data
                                await checkPermissions(session.idToken.payload['custom:tenantId']).catch(err => {
                                    if (process.env.NODE_ENV === 'development') {
                                        console.error('User session failed to be setup: ', err);
                                    }

                                    reject(err);
                                });

                                // Remember the user if their preference is set to true
                                if (attributes[5].Value === 'true') {
                                    resolve(session);
                                } else {
                                    // If user doesn't want to be remembered, we start the idle timer
                                    reset();
                                    start();

                                    resolve(session);
                                }
                            }
                        });
                    }
                });
            } else {
                let error = {
                    message: 'Missing user session',
                    code: 'NoSession',
                };

                reject(error);
            }
        });
    };

    // Updates the user's preferences for wanting to be remembered
    const updateRememberMe = async (cogUser, remember) => {
        return new Promise((resolve, reject) => {
            // If user doesn't want to be remembered, we start the idle timer
            if (!remember) {
                reset();
                start();
            }

            // Update the user's rememberMe preference
            let attributeList = [];

            let dRemember = {
                Name: 'custom:remember',
                Value: remember.toString(),
            };

            let attRemember = new CognitoUserAttribute(dRemember);
            attributeList.push(attRemember);

            cogUser.updateAttributes(attributeList, (err, result) => {
                if (err) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error("Failed to update the user's rememberMe preference: ", err);
                    }

                    reject(err);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.log("Successfully updated the user's rememberMe preference: ", result);
                    }

                    resolve(result);
                }
            });
        });
    };

    // Fetches data of the user from backend database
    const fetchUserData = async userId => {
        return new Promise(async (resolve, reject) => {
            await API.graphql(graphqlOperation(getUser, { id: userId }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Successfully fetched user data from backend: ', data.data.getUser);
                    }

                    resolve(data.data.getUser);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error while fetching user data from backend: ', err);
                    }

                    reject(err);
                });
        });
    };

    // Fetches the user's company
    const fetchCompany = async tenantId => {
        return new Promise(async (resolve, reject) => {
            await API.graphql(graphqlOperation(listCompanys, { filter: { companyId: { eq: tenantId } } }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(
                            'Successfully fetched company data from backend: ',
                            data.data.listCompanys.items[0],
                        );
                    }

                    resolve(data.data.listCompanys.items[0]);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error while fetching company data from backend: ', err);
                    }

                    reject(err);
                });
        });
    };

    // Local method to check the user's permissions based on their company fields
    const checkPermissions = async tenantId => {
        return new Promise(async (resolve, reject) => {
            if (userStatusRef.current === 'ExistingUser') {
                // Fetch user data from backend is session is successfully setup
                await fetchCompany(tenantId)
                    .then(data => {
                        companyDataRef.current = data;
                        setCompanyData(data);
                        companyStatusRef.current = data.companyData.status;
                        setCompanyStatus(data.companyData.status);

                        if (process.env.NODE_ENV !== 'development') {
                            amplitude.getInstance().setUserProperties({ companyId: tenantId });
                        }

                        // Check if the user is an admin
                        if (data.companyData.admins.some(id => userDataRef.current.id === id)) {
                            if (process.env.NODE_ENV === 'development') {
                                console.log('User is an ADMIN.');
                            }

                            isAdmin.current = true;
                        } else {
                            if (process.env.NODE_ENV === 'development') {
                                console.log('User is a NORMAL MEMBER.');
                            }

                            isAdmin.current = false;
                        }

                        // Check company status
                        if (data.companyData.status === 'Active') {
                            // Check the invoice status of the company
                            const invoice = JSON.parse(data.companyData.invoice);

                            if (invoice.status === 'Trial') {
                                // Check if their invoice has expired (based on days)
                                const expDate = moment(invoice.expDate);
                                const currDate = moment();
                                const diff = expDate.diff(currDate);
                                const diffDays = moment.duration(diff).days();

                                if (diffDays < 0) {
                                    companyStatusRef.current = 'ExpiredTrial';
                                    setCompanyStatus('ExpiredTrial');
                                }
                            }
                        }

                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            } else {
                if (tenantId === 'none') {
                    if (process.env.NODE_ENV !== 'development') {
                        amplitude.getInstance().setUserProperties({ companyId: 'none' });
                    }

                    isAdmin.current = false;

                    resolve();
                } else {
                    // Fetch user data from backend is session is successfully setup
                    await fetchCompany(tenantId)
                        .then(async data => {
                            // Check if the user is in the invited user list
                            if (
                                data.companyData.invitedUsers &&
                                data.companyData.invitedUsers.some(email => userDataRef.current.email === email)
                            ) {
                                companyDataRef.current = data;
                                setCompanyData(data);
                                companyStatusRef.current = data.companyData.status;
                                setCompanyStatus(data.companyData.status);

                                if (process.env.NODE_ENV === 'development') {
                                    console.log('User has been invited.');
                                }

                                // Update user info and remove user from company invited list here
                                let userDataInput = {
                                    id: userDataRef.current.id,
                                    status: 'ExistingUser',
                                    companyId: tenantId,
                                };

                                await updateNewUserData(userDataInput).then(async () => {
                                    await fetchUserInfo(userDataRef.current.id);
                                });

                                let newInvitedList = data.companyData.invitedUsers;
                                let index = newInvitedList.indexOf(userDataRef.current.email);

                                if (index !== -1) {
                                    newInvitedList.splice(index, 1);
                                }

                                let companyDataInput = {
                                    id: tenantId,
                                    invitedUsers: newInvitedList,
                                };

                                await updateNewCompanyData(companyDataInput).then(async () => {
                                    await fetchCompanyInfo(tenantId);
                                });

                                if (process.env.NODE_ENV !== 'development') {
                                    amplitude.getInstance().setUserProperties({ companyId: tenantId });
                                }

                                // Check company status
                                if (data.companyData.status === 'Active') {
                                    // Check the invoice status of the company
                                    const invoice = JSON.parse(data.companyData.invoice);

                                    if (invoice.status === 'Trial') {
                                        // Check if their invoice has expired (based on days)
                                        const expDate = moment(invoice.expDate);
                                        const currDate = moment();
                                        const diff = expDate.diff(currDate);
                                        const diffDays = moment.duration(diff).days();

                                        if (diffDays < 0) {
                                            companyStatusRef.current = 'ExpiredTrial';
                                            setCompanyStatus('ExpiredTrial');
                                        }
                                    }
                                }

                                isAdmin.current = false;

                                resolve();
                            } else {
                                if (process.env.NODE_ENV === 'development') {
                                    console.log('User has not been invited.');
                                }

                                if (process.env.NODE_ENV !== 'development') {
                                    amplitude.getInstance().setUserProperties({ companyId: 'none' });
                                }

                                isAdmin.current = false;

                                resolve();
                            }
                        })
                        .catch(() => {
                            if (process.env.NODE_ENV !== 'development') {
                                amplitude.getInstance().setUserProperties({ companyId: 'none' });
                            }

                            isAdmin.current = false;

                            resolve();
                        });
                }
            }
        });
    };

    /* EXPORTED FUNCTIONS */

    // Function for singing in an existing user
    const signIn = async (Username, Password, Remember) => {
        return new Promise((resolve, reject) => {
            const cogUser = new CognitoUser({ Username, Pool });
            const authDetails = new AuthenticationDetails({
                Username,
                Password,
            });

            cogUser.authenticateUser(authDetails, {
                // If login is successful
                onSuccess: async result => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Successfully logged in: ', result);
                    }

                    await updateRememberMe(cogUser, Remember).catch(err => {
                        reject(err);
                    });

                    setCredentials(result);

                    if (process.env.NODE_ENV !== 'development') {
                        amplitude.getInstance().setUserId(result.idToken.payload.sub);
                        amplitude.getInstance().logEvent('user_login_successful');
                    }

                    // Fetch user data from backend is session is successfully setup
                    await fetchUserData(result.idToken.payload.sub)
                        .then(data => {
                            userDataRef.current = data;
                            setUserData(data);
                            userStatusRef.current = data.status;
                            setUserStatus(data.status);
                        })
                        .catch(err => {
                            if (process.env.NODE_ENV === 'development') {
                                console.error('User session failed to be setup: ', err);
                            }

                            reject(err);
                        });

                    // Check for the user's permissions and fetch company data
                    await checkPermissions(result.idToken.payload['custom:tenantId'])
                        .then(() => {
                            setIsAuthenticated(true);
                            resolve(result.idToken.payload);
                        })
                        .catch(err => {
                            if (process.env.NODE_ENV === 'development') {
                                console.error('User session failed to be setup: ', err);
                            }

                            reject(err);
                        });
                },
                // If login fails
                onFailure: err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error at login: ', err);
                    }

                    if (process.env.NODE_ENV !== 'development') {
                        amplitude.getInstance().logEvent('user_login_failed', err);
                    }

                    setIsAuthenticated(false);
                    reject(err);
                },
                // If a new password is required
                newPasswordRequired: result => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('User requires new password: ', result);
                    }

                    setIsAuthenticated(false);
                    resolve('NewPassword');
                },
            });
        });
    };

    // Function for signing up a new user
    const signUp = async (name, familyName, email, phoneNr, password, tenantId) => {
        return new Promise((resolve, reject) => {
            // New array for Cognito attributes
            let attributeList = [];

            // All the fields we're going to push to the new user's attributes
            const dName = {
                Name: 'name',
                Value: name,
            };

            const dFamilyName = {
                Name: 'family_name',
                Value: familyName,
            };

            const dEmail = {
                Name: 'email',
                Value: email,
            };

            const dPhoneNr = {
                Name: 'phone_number',
                Value: '+47' + phoneNr,
            };

            const dOrgNr = {
                Name: 'custom:org_number',
                Value: 'none',
            };

            const dTenantId = {
                Name: 'custom:tenantId',
                Value: tenantId,
            };

            const dRemember = {
                Name: 'custom:remember',
                Value: 'false',
            };

            // Convert the data values to a CognitoUserAttribute
            const attName = new CognitoUserAttribute(dName);
            const attFamilyName = new CognitoUserAttribute(dFamilyName);
            const attEmail = new CognitoUserAttribute(dEmail);
            const attPhoneNr = new CognitoUserAttribute(dPhoneNr);
            const attOrgNr = new CognitoUserAttribute(dOrgNr);
            const attRemember = new CognitoUserAttribute(dRemember);
            const attTenantId = new CognitoUserAttribute(dTenantId);

            // Push them into array
            attributeList.push(attName, attFamilyName, attEmail, attPhoneNr, attOrgNr, attRemember, attTenantId);

            Pool.signUp(email, password, attributeList, null, (err, result) => {
                if (err) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error at sign up: ', err);
                    }

                    reject(err);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Successfully signed up: ', result);
                    }

                    if (process.env.NODE_ENV !== 'development') {
                        amplitude.getInstance().setUserId(result.userSub);
                        amplitude.getInstance().logEvent('user_created', result);
                    }

                    resolve(result);
                }
            });
        });
    };

    // Function for signing out the user and clearing session
    const signOut = async () => {
        // Try and get existing user
        const cogUser = Pool.getCurrentUser();

        // If they exist, clear their credentials and unauthorize
        if (cogUser) {
            pause();

            clearCredentials();
            cogUser.signOut();
        }

        setIsAuthenticated(false);
    };

    // Function for clearing session and logging out with cogUser
    const clearSession = async () => {
        pause();

        clearCredentials();
        setIsAuthenticated(false);
    };

    // Function for starting forgot password flow
    const startPasswordReset = async Username => {
        return await new Promise((resolve, reject) => {
            const cogUser = new CognitoUser({ Username, Pool });

            if (cogUser) {
                cogUser.forgotPassword({
                    onSuccess: result => {
                        if (process.env.NODE_ENV === 'development') {
                            console.log('Successfully started new password reset flow: ', result);
                        }

                        resolve(result);
                    },
                    onFailure: err => {
                        if (process.env.NODE_ENV === 'development') {
                            console.error('Failed to initiate new password reset flow for user: ', err);
                        }

                        reject(err);
                    },
                });
            } else {
                let error = {
                    message: 'Missing Cognito user',
                    code: 'NoUser',
                };

                reject(error);
            }
        });
    };

    // Function for finishing forgot password flow
    const finishPasswordReset = async (Username, code, password) => {
        return await new Promise((resolve, reject) => {
            const cogUser = new CognitoUser({ Username, Pool });

            if (cogUser) {
                cogUser.confirmPassword(code, password, {
                    onSuccess: result => {
                        if (process.env.NODE_ENV === 'development') {
                            console.log("Successfully reset the user's password: ", result);
                        }

                        resolve(result);
                    },
                    onFailure: err => {
                        if (process.env.NODE_ENV === 'development') {
                            console.error('Error trying to finish resetting password for user: ', err);
                        }

                        reject(err);
                    },
                });
            } else {
                let error = {
                    message: 'Missing Cognito user',
                    code: 'NoUser',
                };

                reject(error);
            }
        });
    };

    // Function to confirm an unverified user
    const confirmUser = async (Username, code) => {
        return await new Promise((resolve, reject) => {
            const cogUser = new CognitoUser({ Username, Pool });

            if (cogUser) {
                cogUser.confirmRegistration(code, true, (err, result) => {
                    if (err) {
                        if (process.env.NODE_ENV === 'development') {
                            console.error('Failed to confirm the user: ', err);
                        }

                        if (process.env.NODE_ENV !== 'development') {
                            amplitude.getInstance().logEvent('user_verification_failed', err);
                        }

                        reject(err);
                    } else {
                        if (process.env.NODE_ENV === 'development') {
                            console.log('Successfully confirmed user: ', result);
                        }

                        if (process.env.NODE_ENV !== 'development') {
                            amplitude.getInstance().logEvent('user_verification_successful', result);
                        }

                        resolve(result);
                    }
                });
            } else {
                let error = {
                    message: 'Missing Cognito user',
                    code: 'NoUser',
                };

                reject(error);
            }
        });
    };

    // Function to send a new verification email to the user
    const resendConfirmationLink = async Username => {
        return await new Promise((resolve, reject) => {
            const cogUser = new CognitoUser({ Username, Pool });

            if (cogUser) {
                cogUser.resendConfirmationCode((err, result) => {
                    if (err) {
                        if (process.env.NODE_ENV === 'development') {
                            console.error('Failed to send a new email to the user: ', err);
                        }

                        reject(err);
                    } else {
                        if (process.env.NODE_ENV === 'development') {
                            console.log('Successfully sent new email to the user: ', result);
                        }

                        resolve(result);
                    }
                });
            } else {
                let error = {
                    message: 'Missing Cognito user',
                    code: 'NoUser',
                };

                reject(error);
            }
        });
    };

    // Updates the tenantId user attribute (for permissions within a company)
    const updateTenantId = async (Username, tenantId) => {
        return await new Promise((resolve, reject) => {
            const cogUser = new CognitoUser({ Username, Pool });

            if (cogUser) {
                cogUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        // Update the user's tenantId
                        let attributeList = [];

                        let dataTenantId = {
                            Name: 'custom:tenantId',
                            Value: tenantId,
                        };

                        let attributeTenantId = new CognitoUserAttribute(dataTenantId);
                        attributeList.push(attributeTenantId);

                        cogUser.updateAttributes(attributeList, (err, result) => {
                            if (err) {
                                if (process.env.NODE_ENV === 'development') {
                                    console.error("Failed to update the user's tenantId: ", err);
                                }

                                reject(err);
                            } else {
                                if (process.env.NODE_ENV === 'development') {
                                    console.log("Successfully updated the user's tenantId: ", result);
                                }

                                resolve(session);
                            }
                        });
                    }
                });
            } else {
                let error = {
                    message: 'Missing Cognito user',
                    code: 'NoUser',
                };

                reject(error);
            }
        });
    };

    // Function to delete the user's account
    const deleteUser = async () => {
        return await new Promise((resolve, reject) => {
            const cogUser = Pool.getCurrentUser();

            if (cogUser) {
                cogUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        cogUser.deleteUser((err, result) => {
                            if (err) {
                                reject(err);
                            } else {
                                if (process.env.NODE_ENV === 'development') {
                                    console.log("Successfully deleted user's Cognito account: ", result);
                                }

                                resolve();
                            }
                        });
                    }
                });
            } else {
                let error = {
                    message: 'Missing Cognito user',
                    code: 'NoUser',
                };

                reject(error);
            }
        });
    };

    const fetchCompanyInfo = async tenantId => {
        return await new Promise(async (resolve, reject) => {
            await fetchCompany(tenantId)
                .then(async data => {
                    companyDataRef.current = data;
                    setCompanyData(data);
                    companyStatusRef.current = data.companyData.status;
                    setCompanyStatus(data.companyData.status);

                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    };

    const fetchUserInfo = async userId => {
        return await new Promise(async (resolve, reject) => {
            await fetchUserData(userId)
                .then(async data => {
                    userDataRef.current = data;
                    setUserData(data);
                    userStatusRef.current = data.status;
                    setUserStatus(data.status);

                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    };

    return {
        // Values
        isAuthenticated,
        isAdmin: isAdmin.current,
        userData: userData,
        companyData: companyData,
        userStatus: userStatus,
        companyStatus: companyStatus,
        // Functions
        signIn,
        signUp,
        signOut,
        clearSession,
        startPasswordReset,
        finishPasswordReset,
        confirmUser,
        resendConfirmationLink,
        updateTenantId,
        deleteUser,
        fetchCompanyInfo,
        fetchUserInfo,
    };
}
