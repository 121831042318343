import React from 'react';
import { InputLabel, InputBase, Grid, FormGroup, FormControl, Checkbox, FormControlLabel } from '@mui/material';

export default function MultipleChoice(props) {
    const { data, input, setInput, reportIsCompleted } = props;

    const handleChange = value => {
        let newInput = {};

        if (input[data.id]) {
            newInput = input[data.id];

            if (newInput.checked && newInput.checked.includes(value)) {
                newInput.checked.splice(newInput.checked.indexOf(value), 1);
            } else {
                newInput.checked.push(value);
            }
        } else {
            let checked = [];
            checked.push(value);

            newInput = {
                checked: checked,
                inputs: [],
            };
        }

        setInput({
            ...input,
            [data.id]: newInput,
        });
    };

    const handleChangeText = (altId, text) => {
        let newInput = input[data.id];

        let index = newInput.inputs.findIndex(item => item.id === altId);

        if (index !== -1) {
            newInput.inputs[index].input = text;
        } else {
            let newObj = {
                id: altId,
                input: text,
            };

            newInput.inputs.push(newObj);
        }

        setInput({
            ...input,
            [data.id]: newInput,
        });
    };

    return (
        <Grid>
            <InputLabel>{data.label} *</InputLabel>
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormGroup>
                    {data.alternatives &&
                        data.alternatives
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map(alt => {
                                let index =
                                    input[data.id] &&
                                    input[data.id].inputs &&
                                    input[data.id].inputs.findIndex(item => item.id === alt.id);

                                return (
                                    <Grid key={alt.id}>
                                        <FormControlLabel
                                            id={alt.id}
                                            style={{ margin: '8px 0px' }}
                                            control={
                                                <Checkbox
                                                    name={alt.label}
                                                    checked={
                                                        (input[data.id] &&
                                                            input[data.id].checked &&
                                                            input[data.id].checked.includes(alt.id)) ||
                                                        false
                                                    }
                                                    disabled={reportIsCompleted}
                                                />
                                            }
                                            label={alt.label}
                                            onChange={() => handleChange(alt.id)}
                                            readOnly={reportIsCompleted}
                                        />
                                        {alt.hasTextField &&
                                        input[data.id] &&
                                        input[data.id].checked.includes(alt.id) ? (
                                            <InputBase
                                                sx={{ padding: '5px' }}
                                                type="text"
                                                className="input"
                                                placeholder={!reportIsCompleted ? 'Skriv her...' : ''}
                                                value={index !== -1 ? input[data.id].inputs[index].input : ''}
                                                onChange={e => handleChangeText(alt.id, e.target.value)}
                                                readOnly={reportIsCompleted}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                );
                            })}
                </FormGroup>
            </FormControl>
        </Grid>
    );
}
