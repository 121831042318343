import React, { useState } from 'react';
import { Grid, Button, IconButton, Typography, List } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import useToggle from '../../../../../hooks/useToggle';
import InputForm, { NumberInput } from '../../../../../components/InputForm';
import EmissionFactorTypeRowView from './EmissionFactorTypeRowView';

import { API, graphqlOperation } from 'aws-amplify';
import { createClimateEmissionFactorType } from '../../../../../graphql/mutations';

export default function EmissionFactorTypeListView(props) {
    const [open, setOpen] = useToggle();

    const [type, setType] = useState('');
    const [value, setValue] = useState(0.0);

    const saveNewType = async () => {
        return new Promise(async (resolve, reject) => {
            // Input data for creating a new CompanyData object
            let inputData = {
                type: type,
                value: value,
                emissionFactorID: props.factorId,
            };

            await API.graphql(graphqlOperation(createClimateEmissionFactorType, { input: inputData }))
                .then(data => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Created new emission factor type: ', data.data.createClimateEmissionFactorType);
                    }

                    resolve(data.data.createCertQuestion);
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Failed to create new emission factor type: ', err);
                    }

                    reject(err);
                });
        });
    };

    const handleSaveNewType = async () => {
        if (type === '' || value === '') {
            props.showPopup('Type og verdien må være fylt ut!');
            return;
        }

        await saveNewType().catch(() => {
            props.showPopup('En feil oppsto ved lagring av utslippstype');
            return;
        });

        props.showPopup('Utslippstype har blitt lagret');

        await props.update();

        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        setType('');
        setValue(0.0);

        setOpen();
    };

    const newTypeDialog = () => {
        return (
            <Grid>
                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Legg til ny utslippstype</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Legg til et nytt utslippstype til faktoren.
                        </DialogContentText>
                        <InputForm
                            label="Type"
                            placeholder="Skriv inn..."
                            value={type}
                            onChange={e => setType(e.target.value)}
                        />
                        <NumberInput
                            label="Verdi til faktoren"
                            placeholder="Skriv inn..."
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            min={0.0}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleSaveNewType}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid sx={{ margin: '20px' }}>
            {newTypeDialog()}
            <Typography>
                <b>Utslippstyper</b>
                <IconButton onClick={setOpen}>
                    <AddCircleOutlineOutlinedIcon />
                </IconButton>
            </Typography>
            <List>
                {props.data &&
                    props.data.map(item => {
                        return (
                            <EmissionFactorTypeRowView
                                data={item}
                                key={item.id}
                                showPopup={props.showPopup}
                                update={props.update}
                            />
                        );
                    })}
            </List>
        </Grid>
    );
}
