import React from 'react';
import { InputBase } from '@mui/material';

export default function Text(props) {
    const { data, text, setText, setPointsEarned, setIsAnswered, minChars, maxChars } = props;

    const handleInput = value => {
        if (value.length >= minChars && value.length > 0) {
            setIsAnswered(true);
            setPointsEarned(data.pointValue);
        } else {
            setIsAnswered(false);
            setPointsEarned(0);
        }

        setText(value);
    };

    return (
        <InputBase
            sx={{ padding: '5px' }}
            id={data.id}
            type="text"
            className="input"
            name={data.question}
            value={text}
            onChange={e => handleInput(e.target.value)}
            placeholder="Skriv her..."
            inputProps={{ maxLength: maxChars }}
        />
    );
}
