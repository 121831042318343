import { makeStyles } from "@mui/styles";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

export const useStyles = makeStyles((theme) => ({
  //Register spesific styles
  regLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zindex: 1,
    padding: "80px",
  },
  regRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    position: "relative",
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
  regIllustration: {
    padding: "0px 0px",
    position: "absolute",
    top: "40px",
    left: "-40px",
  },
  regContent: {
    display: "flex",
    backgroundColor: "#E7DEEE",
    minHeight: "570px",
  },
  //Login spesific styles
  logLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zindex: 1,
    padding: "80px",
    [breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  logRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    position: "relative",
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
  logIllustration: {
    padding: "0px 0px",
    position: "absolute",
    bottom: "-80px",
  },
  logContent: {
    display: "flex",
    backgroundColor: "#DDE5F2",
    minHeight: "570px",
  },
  loginContainer: {
    padding: "40px",
    [breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  text: {
    marginRight: "20px",
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  //General styles
  contentDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  navbar: {
    height: "85px",
    padding: "0px 120px",
    display: "flex",
    justifyContent: "space-between",
    [breakpoints.down("sm")]: {
      padding: "0px 10px",
    },
  },
  logo: {
    width: "45px",
    padding: "20px",
    [breakpoints.down("sm")]: {
      padding: "20px 10px",
    },
  },
  customGrid: {
    display: "flex",
    alignItems: "center",
  },
  footer: {
    height: "200px",
    padding: "10px 40px",
    display: "flex",
    justifyContent: "space-between",
    [breakpoints.down("sm")]: {
      padding: "10px 0px",
    },
  },
}));
