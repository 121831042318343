import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  Button,
  Box,
  Collapse,
  Divider,
  InputLabel,
  InputBase,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TextField,
  InputAdornment,
  NativeSelect,
} from "@mui/material";
import { useStyles, CustomCell, CustomRow } from "../../ClimateStyles";
import { ArrowRight, Add, Delete } from "../../../../../components/Icons";
import * as Text from "../../../../../helpers/climateAccounting.json";
import {
  fetchAllClimateFactors,
  newClimateResponse,
  updateClimateResponse,
} from "../../climateFunctions";
import { Loading } from "../../../../../components/Loading";
import { useAuth } from "../../../../../auth/useAuth";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Chores from "../Chores";

const filter = createFilterOptions();

export default function ResidualMaterials(props) {
  const {
    CollapseColor,
    CheckedColor,
    IconChange,
    ApplicableCheckBox,
    updateReport,
    reportId,
    questionId,
    data,
    index,
    open,
    changeOpen,
    openNext,
    tasks,
    updateTasks,
    users,
  } = props;
  const classes = useStyles();
  const auth = useAuth();
  const [hasData, setHasData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isRelevant, setIsRelevant] = useState(true);
  const [factors, setFactors] = useState([]);
  const [areas, setAreas] = useState([]);
  const [suffixes, setSuffixes] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [selectedFactor, setSelectedFactor] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [amount, setAmount] = useState(0);
  const [origin, setOrigin] = useState("Gjenbruker selv");
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      checkForData();

      fetchAllClimateFactors("Restmaterialer").then((data) => {
        let sortedArr = data.sort((a, b) => a.name.localeCompare(b.name));

        setFactors(sortedArr);
      });

      fetchAllClimateFactors("Bruksområder").then((data) => {
        let sortedArr = data.sort((a, b) => a.name.localeCompare(b.name));

        setAreas(sortedArr);
      });
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkForData = async () => {
    if (data !== null && data !== undefined) {
      let dataObj = JSON.parse(data.data);
      setAnswers(dataObj);

      if (!data.isRelevant) {
        setIsAnswered(true);
        setIsRelevant(false);
      } else if (dataObj.length !== 0) {
        setIsAnswered(true);
      }

      setHasData(true);
    } else {
      setHasData(false);
    }
  };

  const saveData = async (check) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    let newCheck = check;

    if (newCheck === null || newCheck === undefined) {
      newCheck = true;
    }

    if (hasData) {
      await updateClimateResponse(
        data.id,
        auth.companyData.companyId,
        reportId,
        questionId,
        3,
        answers,
        newCheck
      ).then(async () => {
        await updateReport();

        setIsSaving(false);
      });
    } else {
      await newClimateResponse(
        auth.companyData.companyId,
        reportId,
        questionId,
        3,
        answers,
        newCheck
      ).then(async () => {
        await updateReport();

        setHasData(true);
        setIsSaving(false);
      });
    }
  };

  const handleSelectUnit = (event) => {
    setSelectedUnit(suffixes[event.target.value]);
    setSelectValue(event.target.value);
  };

  const handleAddAnswer = async () => {
    let input = {
      factor: selectedFactor.name,
      factorId: selectedFactor.id,
      usageArea: selectedArea.name,
      usageAreaId: selectedArea.id,
      type: selectedUnit.type,
      typeId: selectedUnit.id,
      amount: amount,
      origin: origin,
    };

    let newArr = answers;

    newArr.push(input);

    if (newArr.length !== 0) {
      setIsAnswered(true);
    }

    setAnswers([...newArr]);

    setSelectedFactor("");
    setSelectedArea("");
    setSelectedUnit("");
    setSuffixes([]);
    setOrigin("Gjenbruker selv");
    setAmount(0);

    setResetValue(!resetValue);

    await saveData();
  };

  const handleRemoveAnswer = async (index) => {
    let newArr = answers;

    newArr.splice(index, 1);

    if (newArr.length === 0) {
      setIsAnswered(false);
    }

    setAnswers([...newArr]);

    await saveData();
  };

  const handleCheck = async (e) => {
    setIsRelevant(!e.target.checked);

    if (e.target.checked) {
      setIsAnswered(true);
    } else {
      setIsAnswered(false);
    }

    await saveData(!e.target.checked);
  };

  const handleSave = async () => {
    await saveData(isRelevant);

    openNext(index);
  };

  return (
    <React.Fragment>
      <TableRow
        id={questionId}
        className={classes.question}
        sx={{
          background: CollapseColor(isAnswered, open),
        }}
      >
        <TableCell
          className={classes.iconCell}
          style={{
            backgroundColor: CheckedColor(isAnswered),
          }}
          align="center"
          onClick={() => changeOpen(index, !open)}
        >
          {IconChange(isAnswered)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          className={classes.questionRow}
          onClick={() => changeOpen(index, !open)}
        >
          <Typography variant="h3">Restmaterialer og restråvarer</Typography>
        </TableCell>
        <TableCell className={classes.applicable}>
          {ApplicableCheckBox(isRelevant, handleCheck)}
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: "20px" }}>
              <Typography variant="body1">{Text.Scope3.Residual}</Typography>
              <Divider className={classes.divider} />
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid item xs={6} sx={{ marginRight: "10px" }}>
                  <InputLabel>Type materiale</InputLabel>
                  <Autocomplete
                    value={selectedFactor}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setSelectedFactor({
                          id: "other",
                          name: newValue,
                        });

                        setSuffixes([
                          { id: "other", type: "kg" },
                          { id: "other", type: "liter" },
                          { id: "other", type: "tonn" },
                          { id: "other", type: "m3" },
                        ]);
                        setSelectedUnit("kg");
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setSelectedFactor({
                          id: "other",
                          name: newValue.inputValue,
                        });

                        setSuffixes([
                          { id: "other", type: "kg" },
                          { id: "other", type: "liter" },
                          { id: "other", type: "tonn" },
                          { id: "other", type: "m3" },
                        ]);
                        setSelectedUnit("kg");
                      } else {
                        setSelectedFactor(newValue);

                        if (
                          newValue === null ||
                          newValue === undefined ||
                          newValue === ""
                        ) {
                          setSuffixes([]);
                          setSelectedUnit("");
                        } else {
                          setSuffixes(newValue.types.items);
                          setSelectedUnit(newValue.types.items[0]);
                        }
                      }

                      if (
                        newValue === "" ||
                        newValue === undefined ||
                        newValue === null
                      ) {
                        setAmount(0);
                      } else {
                        setAmount("");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Legg til "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={factors}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder="Søk eller skriv inn type materiale..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Mengde/antall</InputLabel>
                  <TextField
                    type="text"
                    name="number"
                    sx={{ width: "100%" }}
                    placeholder="Skriv her..."
                    disabled={suffixes.length > 0 ? false : true}
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <NativeSelect
                            className={classes.unitSelect}
                            value={selectValue}
                            onChange={handleSelectUnit}
                            disabled={suffixes.length > 0 ? false : true}
                            input={<InputBase />}
                          >
                            {suffixes &&
                              suffixes.map((suffix, index) => {
                                return (
                                  <option key={index} value={index}>
                                    {suffix.type}
                                  </option>
                                );
                              })}
                          </NativeSelect>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid item xs={6} sx={{ marginRight: "10px" }}>
                  <InputLabel>Hva rest brukes til</InputLabel>
                  <NativeSelect
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                    input={<InputBase />}
                    style={{ marginRight: 30 }}
                  >
                    <option value="Gjenbruker selv">Gjenbruker selv</option>
                    <option value="Gis bort">Gis bort</option>
                    <option value="Selges">Selges</option>
                    <option value="Kastes">Kastes</option>
                  </NativeSelect>
                  <Typography variant="body2">
                    <i>Trykk "legg til" for å legge til i lista</i>
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ marginRight: "10px" }}>
                  <InputLabel>
                    Nytt bruksområde{" "}
                    <i style={{ fontSize: "10px" }}>(valgfri)</i>{" "}
                  </InputLabel>
                  <Autocomplete
                    value={selectedArea}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setSelectedArea({
                          id: "other",
                          name: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setSelectedArea({
                          id: "other",
                          name: newValue.inputValue,
                        });
                      } else {
                        setSelectedArea(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Legg til "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={areas}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder="Søk eller skriv inn nytt bruksområde..."
                      />
                    )}
                  />
                </Grid>
                <Grid item sx={{ marginTop: "32px" }}>
                  <Button
                    variant="contained"
                    sx={{ width: "130px" }}
                    onClick={handleAddAnswer}
                    disabled={
                      amount > 0 && amount !== "" && origin !== ""
                        ? false
                        : true
                    }
                  >
                    {Add}Legg til
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <CustomRow>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Type materiale
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Mengde/antall
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Hva rest brukes til
                        </CustomCell>
                        <CustomCell
                          align="left"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Nytt bruksområde
                        </CustomCell>
                        <CustomCell
                          align="center"
                          sx={{ borderBottom: "2px solid #FDDFB0 !important" }}
                        >
                          Slett
                        </CustomCell>
                      </CustomRow>
                    </TableHead>
                    <TableBody>
                      {answers &&
                        answers.map((answer, index) => {
                          return (
                            <CustomRow key={answer.factor}>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.factor}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.amount} {answer.type}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.origin}
                              </CustomCell>
                              <CustomCell
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                              >
                                {answer.usageArea}
                              </CustomCell>
                              <CustomCell
                                align="center"
                                sx={{ padding: "0px 10px 0px 10px !important" }}
                                onClick={() => handleRemoveAnswer(index)}
                              >
                                {Delete}
                              </CustomCell>
                            </CustomRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container direction="row" className={classes.content}>
                {isSaving ? (
                  <Loading />
                ) : (
                  <Button
                    disabled={false}
                    variant="contained"
                    onClick={handleSave}
                  >
                    Lagre{ArrowRight}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <Divider sx={{ border: "1px solid #E1E1E1" }} />
                <Typography variant="h2" sx={{ marginTop: "10px" }}>
                  Tilknyttede gjøremål
                </Typography>
                <Chores
                  tasks={tasks}
                  updateTasks={updateTasks}
                  users={users}
                  id={questionId}
                  reportId={reportId}
                  scope={3}
                />
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: "20px" }} />
    </React.Fragment>
  );
}
