import React, { useState } from "react";
import { Paper, Grid, Typography, Button } from "@mui/material";
import { useStyles } from "../ChoresStyle";
import ChoresList from "./ChoresList";
import { ChoresIcon } from "../../../../components/Icons";
import CompletedChores from "./CompletedChores";

export default function MyChores(props) {
  const classes = useStyles();

  const [showMyTasks, setShowMyTasks] = useState(false);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Grid className={classes.header}>
            <Typography variant="h3">{ChoresIcon}Mine aktiviteter</Typography>
            <Button
              variant="text"
              sx={{
                marginRight: "15px",
              }}
              onClick={() => setShowMyTasks(!showMyTasks)}
            >
              {!showMyTasks ? "Vis mine gjøremål" : "Vis alle gjøremål"}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ padding: "0px 20px 20px 20px", position: "relative" }}
          >
            <Grid className={classes.hiders}></Grid>
            <Grid
              className={classes.hiders}
              style={{
                right: "20px",
              }}
            ></Grid>
            <ChoresList
              tasks={props.tasks}
              updateTasks={props.updateTasks}
              users={props.users}
              criterias={props.criterias}
              showMyTasks={showMyTasks}
            />
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid className={classes.header}>
            <Typography variant="h3">
              {ChoresIcon}Mine fullførte aktiviteter
            </Typography>
            <Button
              variant="text"
              sx={{
                marginRight: "15px",
              }}
              onClick={() => setShowMyTasks(!showMyTasks)}
            >
              {!showMyTasks ? "Vis mine gjøremål" : "Vis alle gjøremål"}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ padding: "0px 20px 20px 20px", position: "relative" }}
          >
            <Grid className={classes.hiders}></Grid>
            <Grid
              className={classes.hiders}
              style={{
                right: "20px",
              }}
            ></Grid>
            <CompletedChores
              tasks={props.tasks}
              updateTasks={props.updateTasks}
              users={props.users}
              criterias={props.criterias}
              showMyTasks={showMyTasks}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
