import React, { useState, useEffect } from 'react';
import { Grid, InputLabel, InputBase, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

export default function CustomMultiWithSelect(props) {
    const {
        data,
        multiNumber,
        setMultiNumber,
        answers,
        setAnswers,
        pointsEarned,
        setPointsEarned,
        setIsAnswered,
        minChars,
        maxChars,
    } = props;

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (answers && answers.length > 0) {
                setIsChecked(true);
            }
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            if (answers.length > 0) {
                setIsAnswered(true);
            } else {
                if (multiNumber.length > 0) {
                    let isCheckOptionCount = 0;
                    let reqInputChecks = 0;

                    for (const item of data) {
                        if (item.isCustomMultiSelectOption) {
                            isCheckOptionCount++;
                        }
                    }

                    for (const item of multiNumber) {
                        if (item.hasEarnedPoints) {
                            reqInputChecks++;
                        }
                    }

                    if (reqInputChecks === data.length - isCheckOptionCount) {
                        setIsAnswered(true);
                    } else {
                        setIsAnswered(false);
                    }
                } else {
                    setIsAnswered(false);
                }
            }
        }

        return () => {
            isMounted = true;
        };
    }, [multiNumber, answers]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (option, value) => {
        let newArr = multiNumber;

        let index = multiNumber.findIndex(object => {
            return object.id === option.id;
        });

        if (index === -1 && value.length !== 0) {
            let earnedPointsCheck = false;

            if (value.length >= minChars && value.length !== 0) {
                earnedPointsCheck = true;
                setPointsEarned(pointsEarned + option.pointValue);
            }

            let newObj = {
                id: option.id,
                input: value,
                hasEarnedPoints: earnedPointsCheck,
            };

            newArr.push(newObj);
        } else {
            newArr[index].input = value;

            if (value === '') {
                newArr.splice(index, 1);
                setPointsEarned(pointsEarned - option.pointValue);
            } else if (!newArr[index].hasEarnedPoints && value.length >= minChars) {
                newArr[index].hasEarnedPoints = true;
                setPointsEarned(pointsEarned + option.pointValue);
            }
        }

        setMultiNumber([...newArr]);
    };

    const handleChecked = (checked, option) => {
        let newArr = answers;

        if (newArr.length > 0) {
            newArr.splice(0, 1);
            setPointsEarned(0);
        }

        if (checked) {
            newArr.push(option.id);
            setPointsEarned(option.pointValue);
            setMultiNumber([]);
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }

        setAnswers([...newArr]);
    };

    return (
        <Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                {data &&
                    data
                        .sort((a, b) => {
                            return a.order - b.order;
                        })
                        .map(option => {
                            let answerData = multiNumber.find(answer => {
                                return answer.id === option.id;
                            });

                            if (!answerData) {
                                answerData = {
                                    id: option.id,
                                    input: '',
                                    hasEarnedPoints: false,
                                };
                            }

                            if (!option.isCustomMultiSelectOption) {
                                return (
                                    <Grid item xs={12} sx={{ marginRight: '20px' }} key={option.id}>
                                        <InputLabel id={option.id}>{option.option}</InputLabel>
                                        <InputBase
                                            sx={{ height: '50px' }}
                                            id={option.id}
                                            type="text"
                                            name={option.option}
                                            value={answerData.input}
                                            onChange={e => handleChange(option, e.target.value)}
                                            disabled={isChecked}
                                            placeholder="Skriv her..."
                                            inputProps={{ min: props.min, maxLength: maxChars }}
                                        />
                                    </Grid>
                                );
                            } else {
                                return <Grid key={option.id}></Grid>;
                            }
                        })}
            </Grid>
            {data &&
                data
                    .sort((a, b) => {
                        return a.order - b.order;
                    })
                    .map(option => {
                        if (option.isCustomMultiSelectOption) {
                            return (
                                <FormGroup key={option.id}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        checked={answers.includes(option.id)}
                                        onChange={e => handleChecked(e.target.checked, option)}
                                        label={option.option}
                                    />
                                </FormGroup>
                            );
                        } else {
                            return <Grid key={option.id}></Grid>;
                        }
                    })}
        </Grid>
    );
}
