import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useAuth } from '../../auth/useAuth';
import useRouter from '../../hooks/useRouter';
import moment from 'moment';

export default function TrialCountDown() {
    const router = useRouter();
    const auth = useAuth();

    const invoiceData = JSON.parse(auth.companyData.companyData.invoice);
    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            const expDate = moment(invoiceData.expDate);
            const currDate = moment();
            const diff = expDate.diff(currDate);
            const diffDuration = moment.duration(diff);

            setCountdown(diffDuration.days());
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (invoiceData.status === 'Trial') {
        return (
            <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {countdown === 0 ? (
                    <Typography variant="body1">
                        Den gratis prøveperioden utgår <b style={{ textDecoration: 'underline' }}>i dag!</b>
                    </Typography>
                ) : (
                    <Typography variant="body1">
                        Den gratis prøveperioden utgår om{' '}
                        <b style={{ textDecoration: 'underline' }}>{countdown} dager</b>
                    </Typography>
                )}
                <Button
                    variant="contained"
                    style={{ backgroundColor: '#fff', marginLeft: 20 }}
                    onClick={() =>
                        router.push({
                            pathname: '/subscribe',
                            state: {
                                certCompletion: false,
                                isVisible: false,
                            },
                        })
                    }
                >
                    Bli kunde nå!
                </Button>
            </Grid>
        );
    } else {
        return <Grid></Grid>;
    }
}
