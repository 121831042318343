import React, { useMemo, useCallback, useState, useEffect } from "react";
import {
  ContentState,
  EditorState,
  RichUtils,
  Modifier,
  CompositeDecorator,
  convertToRaw,
} from "draft-js";
import {
  Button,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputBase,
  Tooltip,
} from "@mui/material";
import "draft-js/dist/Draft.css";
import { Linking } from "../../../../../components/Icons";
import {
  UnderlineButton,
  BoldButton,
  ItalicButton,
  BlockButton,
  IconButton,
  useStyles,
} from "./DraftStyles";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "@draft-js-plugins/mention";
import mentionsStyles from "./MentionsStyles.module.css";
import { useAuth } from "../../../../../auth/useAuth";
import Comments from "./Comments";
import FileUpload from "./FileUpload";
import { createComment } from "../../choresFunctions";
import { createNotification } from "../../../../../components/private/notificationsFunctions";

export default function Draft(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [linkInput, setLinkInput] = React.useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [openMentions, setOpenMentions] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [suggestions, setSuggestions] = useState(mentions);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      let newArr = [];

      for (const user of props.users) {
        let newObj = {
          userId: user.id,
          name: JSON.parse(user.name).fullName,
        };

        newArr.push(newObj);
      }

      setMentions([...newArr]);
      setSuggestions([...newArr]);
    }

    return () => {
      isMounted = true;
    };
  }, [props.users]); // eslint-disable-line react-hooks/exhaustive-deps

  const editor = React.useRef(null);
  function focusEditor() {
    if (openDialog !== true) {
      editor.current.focus();
    } else {
      return;
    }
  }

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: mentionsStyles,
      mentionPrefix: "@",
      supportWhitespace: true,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOpenMentions(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }) => {
      setSuggestions(defaultSuggestionsFilter(value, mentions));
    },
    [mentions]
  );

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleLinkChange = (event) => {
    setLinkInput(event.target.value);
  };

  const boldText = (e) => {
    e.preventDefault();
    let nextState = RichUtils.toggleInlineStyle(editorState, "BOLD");
    setEditorState(nextState);
  };

  const underlineText = (e) => {
    e.preventDefault();
    let nextState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");
    setEditorState(nextState);
  };

  const italicText = (e) => {
    e.preventDefault();
    let nextState = RichUtils.toggleInlineStyle(editorState, "ITALIC");
    setEditorState(nextState);
  };

  const unorderedList = (e) => {
    e.preventDefault();
    let nextState = RichUtils.toggleBlockType(
      editorState,
      "unordered-list-item"
    );
    setEditorState(nextState);
  };

  const orderedList = (e) => {
    e.preventDefault();
    let nextState = RichUtils.toggleBlockType(editorState, "ordered-list-item");
    setEditorState(nextState);
  };

  const deleteContent = (e) => {
    e.preventDefault();
    const nextState = EditorState.push(
      editorState,
      ContentState.createFromText("")
    );
    setEditorState(nextState);
  };

  const Link = ({ entityKey, contentState, children }) => {
    let { url } = contentState.getEntity(entityKey).getData();
    return (
      <a
        style={{ color: "#000000", textDecoration: "underline" }}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  };

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  };

  const createLinkDecorator = () =>
    new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ]);

  const onAddLink = () => {
    let linkUrl = linkInput;
    const decorator = createLinkDecorator();
    if (linkUrl) {
      let displayLink = linkUrl;
      if (displayLink) {
        const currentContent = editorState.getCurrentContent();
        const createEntity = currentContent.createEntity("LINK", "MUTABLE", {
          url: linkUrl,
        });
        console.log(createEntity);
        let entityKey = currentContent.getLastCreatedEntityKey();
        const selection = editorState.getSelection();
        const textWithEntity = Modifier.insertText(
          currentContent,
          selection,
          displayLink,
          null,
          entityKey
        );
        let newState = EditorState.createWithContent(textWithEntity, decorator);
        setEditorState(newState);
        setLinkInput("");
        setOpenDialog(false);
      }
    }
  };

  const handleSaveComment = async () => {
    let log = editorState.getCurrentContent();
    let commentData = convertToRaw(log);
    let entityMap = commentData.entityMap;

    let userArray = [];

    Object.entries(entityMap).map(([key, value]) => {
      if (
        entityMap[key].data.mention.userId !== auth.userData.id &&
        !userArray.includes(entityMap[key].data.mention.userId)
      ) {
        userArray.push(entityMap[key].data.mention.userId);
      }

      return value;
    });

    let inputData = {
      companyId: auth.companyData.companyId,
      todoTaskId: props.task.id,
      userId: auth.userData.id,
      type: "COMMENT",
      data: JSON.stringify(commentData),
    };

    await createComment(inputData).then(async () => {
      props.updateTask();

      const nextState = EditorState.push(
        editorState,
        ContentState.createFromText("")
      );
      setEditorState(nextState);

      for (const userId of userArray) {
        let dataObj = {
          link: "/activities/" + props.task.id,
          title: props.task.title,
          taggedBy: JSON.parse(auth.userData.name).fullName,
        };

        let inputData = {
          companyId: auth.companyData.companyId,
          userId: userId,
          type: "COMMENT_NOTIF",
          markedAsRead: false,
          archived: false,
          data: JSON.stringify(dataObj),
        };

        await createNotification(inputData);
      }
    });
  };

  const dialogPopUp = (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="responsive-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle className={classes.linkTitle}>
        {Linking} Legg til link
      </DialogTitle>
      <DialogContent sx={{ marginTop: "10px" }}>
        <InputBase
          id="link"
          name="link"
          placeholder="http://www.example.com"
          onChange={handleLinkChange}
          value={linkInput}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ width: "70px", height: "40px", backgroundColor: "#EBEBEB" }}
          onClick={handleCloseDialog}
        >
          Lukk
        </Button>
        <Button
          variant="contained"
          sx={{ width: "100px", height: "40px" }}
          onMouseDown={onAddLink}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Grid>
      <Button variant="contained" className={classes.writeButton}>
        Skriv
      </Button>
      <div className={classes.richEditStyle} onClick={focusEditor}>
        <UnderlineButton variant="text" onMouseDown={underlineText}>
          Understrek
        </UnderlineButton>
        <BoldButton variant="text" onMouseDown={boldText}>
          Fet
        </BoldButton>
        <ItalicButton variant="text" onMouseDown={italicText}>
          Kursiv
        </ItalicButton>
        <BlockButton variant="text" onMouseDown={unorderedList}>
          Punkt liste
        </BlockButton>
        <BlockButton variant="text" onMouseDown={orderedList}>
          Numerert liste
        </BlockButton>
        <Divider sx={{ marginBottom: "15px" }} />
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
          placeholder="Legg til kommentar, dokumenter, link eller tag andre brukere med @"
        />
        <MentionSuggestions
          open={openMentions}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
        />
        <Grid className={classes.bottomNav}>
          <Grid item sx={{ display: "flex" }}>
            <FileUpload
              task={props.task}
              updateTask={props.updateTask}
              users={props.users}
            />
            <Tooltip title="Legg til link" arrow>
              <IconButton onClick={handleOpenDialog}>{Linking}</IconButton>
            </Tooltip>
            {dialogPopUp}
          </Grid>
          <Grid item>
            <Button variant="text" onMouseDown={deleteContent}>
              Fjern
            </Button>
            <Button
              variant="contained"
              sx={{ width: "80px", height: "40px", backgroundColor: "#FDDFB0" }}
              onClick={handleSaveComment}
            >
              Lagre
            </Button>
          </Grid>
        </Grid>
      </div>
      <Comments
        task={props.task}
        updateTask={props.updateTask}
        users={props.users}
      />
    </Grid>
  );
}
