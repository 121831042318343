import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  //Logo
  link: {
    color: "#000000",
    textDecoration: "none",
  },
  button: {
    width: "100%",
    backgroundColor: "#BBCBE4",
  },
}));
