import React from 'react';
import { Grid, Paper, Button, Typography, Divider } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useStyles } from '../DocumentsStyles';
import { useAuth } from '../../../../auth/useAuth';
import useRouter from '../../../../hooks/useRouter';
import OnePagePDF, { OverviewDocument } from './OnePagePDF';
import { Download } from '../../../../components/Icons';
import amplitude from 'amplitude-js';
import Replacement from '../../../../assets/images/nocustomer_overview.svg';
import NavigationBar from './NavigationBar';

export default function OverviewReport() {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();

    const overviewData = auth.companyData.companyData.certData.items[0].publicProfile;

    const downloadDocument = () => {
        if (process.env.NODE_ENV !== 'development') {
            amplitude.getInstance().logEvent('overview_report_downloaded');
        }
    };

    return (
        <Grid item xs={12}>
            <NavigationBar />
            <Paper sx={{ padding: '40px' }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={6} className={classes.viewerSection}>
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            <OnePagePDF data={overviewData} />
                        ) : (
                            <img src={Replacement} alt="Replacement for non customers"></img>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Typography variant="body2" className={classes.pdfWarning}>
                            PDF forhåndvisning er foreløpig kun tilgjenglig på PC.
                        </Typography>
                        <Typography variant="h1" sx={{ textAlign: 'left' }}>
                            Oversiktsrapport
                        </Typography>
                        <Divider sx={{ margin: '10px 0px' }} />
                        <Typography variant="body1">
                            Her kan dere laste ned en oversiktsrapport med resultater og status innen områdene; sosiale
                            forhold, miljø og klima, samt økonomi og drift. Rapporten er enkelt bygget opp over en side,
                            og kan deles med styret, ledergruppen, ansatte og andre interessenter, som raskt får
                            oversikt over bedriftens bærekraftsarbeid. I tillegg kan rapporten brukes som vedlegg i
                            anbud, konkurranser, som dokumentasjon i søknader, m.m. Etterhvert vil også klimaregnskap og
                            bransjespesifikke forhold bli inkludert i rapporten.
                        </Typography>
                        {JSON.parse(auth.companyData.companyData.invoice).status !== 'Trial' ? (
                            <PDFDownloadLink
                                style={{ color: '#000', textDecoration: 'none' }}
                                document={<OverviewDocument data={overviewData} />}
                                fileName="Oversiktsrapport.pdf"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? (
                                        <Button
                                            variant="contained"
                                            disabled={true}
                                            sx={{ height: '50px', width: '100%', marginTop: '30px' }}
                                        >
                                            Laster inn dokument...
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={() => downloadDocument(blob)}
                                            sx={{ height: '50px', width: '100%', marginTop: '30px' }}
                                        >
                                            Last ned {Download}
                                        </Button>
                                    )
                                }
                            </PDFDownloadLink>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{
                                    height: '50px',
                                    width: '100%',
                                    marginTop: '30px',
                                    color: '#000000',
                                    textDecoration: 'none',
                                }}
                                onClick={() => router.push('/subscribe')}
                            >
                                Bli kunde for å laste ned dokument
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
