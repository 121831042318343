import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper } from '@mui/material';
import { ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import InputForm, { NumberInput } from '../../../../../components/InputForm';
import useToggle from '../../../../../hooks/useToggle';

import { API, graphqlOperation } from 'aws-amplify';
import { updateCertSubcategory, deleteCertSubcategory } from '../../../../../graphql/mutations';

export default function CategoryRowView(props) {
    const [rowOpen, setRowOpen] = useToggle();
    const [dialogOpen, setDialogOpen] = useToggle();

    const [name, setName] = useState('');
    const [order, setOrder] = useState(1);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            setName(props.data.name);
            setOrder(props.data.order);
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateSubcategory = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(updateCertSubcategory, {
                    input: { id: props.data.id, name: name, order: order },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Updated subcategory: ', data);
                }

                resolve();
            });
        });
    };

    const deleteSubcategory = () => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(deleteCertSubcategory, {
                    input: { id: props.data.id },
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Deleted subcategory: ', data);
                }

                resolve();
            });
        });
    };

    const handleUpdateSubcategory = async () => {
        if (name === '') {
            props.showPopup('Navn må være fylt ut!');
            return;
        }

        await updateSubcategory().catch(() => {
            props.showPopup('En feil oppsto ved lagring av underkategori');
            return;
        });

        props.showPopup('Underkategorien har blitt lagret');

        await props.update();

        setDialogOpen();
    };

    const handleDeleteSubcategory = async () => {
        if (props.data.questions.items && props.data.questions.items.length !== 0) {
            props.showPopup('Alle spørsmål må bli fjernet før du kan slette!');
            return;
        }

        await deleteSubcategory().catch(() => {
            props.showPopup('En feil oppsto ved sletting av underkategori');
            return;
        });

        props.showPopup('Kategorien har blitt slettet');

        await props.update();
    };

    const handleCloseDialog = () => {
        setDialogOpen();
        setName(props.data.name);
        setOrder(props.data.order);
    };

    const updateSubcategoryDialog = () => {
        return (
            <Grid>
                <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ fontSize: '2em' }}>Oppdater underkategorien</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: '20px' }}>
                            Oppdater feltene til underkategorien.
                        </DialogContentText>
                        <InputForm
                            label="Navn på underkategori"
                            placeholder="Skriv inn navn på underkategorien"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <NumberInput
                            label="Rekkefølge (lavere nummer betyr høyere på listen)"
                            placeholder="Skriv inn nummer på rekkefølge"
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                            min={1}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Avbryt
                        </Button>
                        <Button variant="primary" onClick={handleUpdateSubcategory}>
                            Lagre
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    return (
        <Grid>
            {updateSubcategoryDialog()}
            <Paper sx={{ padding: '20px', marginBottom: '10px' }}>
                <ListItem disablePadding key={props.data.id}>
                    <ListItemButton onClick={setRowOpen}>
                        <ListItemIcon>
                            <DehazeIcon />
                        </ListItemIcon>
                        <ListItemText primary={props.data.name} />
                    </ListItemButton>
                </ListItem>
                {!rowOpen ? (
                    <></>
                ) : (
                    <Grid>
                        <Divider />
                        <Grid sx={{ margin: '15px' }}>
                            <b>ID:</b>
                            <br /> {props.data.id}
                            <br />
                            <br />
                            <b>Rekkefølge:</b>
                            <br /> {props.data.order}
                        </Grid>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '200px' }}
                            onClick={setDialogOpen}
                        >
                            Oppdater underkategori{' '}
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ padding: '20px', margin: '10px', width: '200px' }}
                            onClick={handleDeleteSubcategory}
                        >
                            Slett underkategori{' '}
                        </Button>
                        <Divider />
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
}
