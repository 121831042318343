import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CertSidebar from "./CertSidebar";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../theme";
import Form from "./Form";
import { SiteLoading } from "../../../../components/Loading";
import { useAuth } from "../../../../auth/useAuth";
import useRouter from "../../../../hooks/useRouter";
import useToggle from "../../../../hooks/useToggle";
import {
  fetchFilteredCategory,
  getCategoryResponses,
} from "../certificationFunctions";
import {
  fetchCompanyUsers,
  fetchAllCompanyTasks,
} from "../../Chores/choresFunctions";
import { useStyles } from "../CertificationStyles";

export default function Questionaire() {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  let { id } = router.query;
  const [isLoading, setIsLoading] = useToggle(true);
  const [color, setColor] = useState("#AFEAF2");
  const [categories, setCategories] = useState({});
  const [responses, setResponses] = useState([]);
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      if (
        id !== "company" &&
        id !== "social" &&
        id !== "environment" &&
        id !== "economy"
      ) {
        router.push("/declaration");
      }

      getColor();
      getData();

      fetchCompanyUsers(auth.companyData.companyId).then((data) => {
        setUsers([...data]);
      });

      updateTasks();
    }

    return () => {
      isMounted = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getColor = () => {
    if (id === "company") {
      setColor("#C1E6EB");
    } else if (id === "social") {
      setColor("#F3CCD1");
    } else if (id === "environment") {
      setColor("#C3E8D0");
    } else if (id === "economy") {
      setColor("#BBCBE4");
    }
  };

  const getData = async () => {
    // If location doesn't carry props, we manually fetch from backend
    if (!router.location.state) {
      await fetchFilteredCategory(id, auth).then(async (category) => {
        setCategories(category);
        await loadResponses();
      });
    } else if (router.location.state) {
      setCategories(router.location.state.data);
      await loadResponses();
    }
  };

  const updateTasks = async () => {
    await fetchAllCompanyTasks(auth.companyData.companyId).then((data) => {
      setTasks([...data]);
    });
  };

  const loadResponses = async () => {
    // Fetch from backend
    await getCategoryResponses(id, auth.companyData.companyId).then(
      (responses) => {
        setResponses([...responses]);
        setIsLoading();
      }
    );
  };

  const fetchResponses = async () => {
    await getCategoryResponses(id, auth.companyData.companyId).then(
      (responses) => {
        setResponses([...responses]);
      }
    );
  };

  const addNewResponse = async (resp) => {
    let newArr = responses;

    newArr.push(resp);

    setResponses([...newArr]);
  };

  if (isLoading) {
    return <SiteLoading />;
  } else {
    return (
      <div className="root">
        <ThemeProvider theme={theme}>
          <CertSidebar data={categories} colorCode={color} />
          <Grid container className={classes.formContainer}>
            <Grid item xs={12} className={classes.mainContainer}>
              <Form
                categories={categories}
                responses={responses}
                fetchResponses={fetchResponses}
                colorCode={color}
                addNewResponse={addNewResponse}
                users={users}
                updateTasks={updateTasks}
                tasks={tasks}
              />
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    );
  }
}
