import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';

import useToggle from '../../../../../hooks/useToggle';
import SnackPopup from '../../../../../components/SnackPopup';

import EmissionFactorListView from './EmissionFactorListView';

import { API, graphqlOperation } from 'aws-amplify';
import { listClimateEmissionFactors } from '../../../../../graphql/queries';

export default function CertificationView() {
    const [factors, setFactors] = useState([]);

    const [popupState, setPopupState] = useToggle();
    const [popupMsg, setPopupMsg] = useState('');

    const [questionFilters, setQuestionFilters] = useState([]);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            updateFactors();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchFactors = token => {
        return new Promise(async resolve => {
            await API.graphql(
                graphqlOperation(listClimateEmissionFactors, {
                    nextToken: token,
                }),
            ).then(data => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Fetched factors: ', data);
                }

                resolve(data.data.listClimateEmissionFactors);
            });
        });
    };

    const fetchAllFactors = () => {
        return new Promise(async (resolve, reject) => {
            let dataArr = [];

            let nextToken = null;

            do {
                try {
                    const resp = await fetchFactors(nextToken);
                    const data = resp.items;
                    nextToken = resp.nextToken;

                    data.forEach(item => {
                        dataArr.push(item);
                    });
                } catch (err) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error while fetching all factors from backend: ', err);
                    }

                    reject(err);
                }
            } while (nextToken !== null);

            if (process.env.NODE_ENV === 'development') {
                console.log('Fetched all factors: ', dataArr);
            }

            let newArr = [];

            for (const factor of dataArr) {
                if (!newArr.includes(factor.questionId)) {
                    newArr.push(factor.questionId);
                }
            }

            setQuestionFilters([...newArr]);

            resolve(dataArr);
        });
    };

    const updateFactors = async () => {
        await fetchAllFactors().then(data => {
            setFactors([...data]);
        });
    };

    const showPopup = message => {
        setPopupMsg(message);
        setPopupState();
    };

    const handleClosePopup = () => {
        setPopupState();
    };

    return (
        <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <SnackPopup message={popupMsg} open={popupState} handleClose={handleClosePopup} />
            <EmissionFactorListView
                showPopup={showPopup}
                factors={factors}
                updateFactors={updateFactors}
                questionFilters={questionFilters}
            />
        </Grid>
    );
}
