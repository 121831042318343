import React from 'react';
import { Grid, List } from '@mui/material';
import TemplateItem from './TemplateItem';

export default function TemplatesList({ showPopup, templates, updateAllTemplates }) {
    return (
        <Grid item xs={12}>
            <List>
                {templates.length === 0 ? (
                    <>Det er ingen lagret maler.</>
                ) : (
                    templates &&
                    templates.map(item => {
                        return (
                            <TemplateItem
                                key={item.id}
                                showPopup={showPopup}
                                data={item}
                                updateAllTemplates={updateAllTemplates}
                            />
                        );
                    })
                )}
            </List>
        </Grid>
    );
}
