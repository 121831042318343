import React from "react";
import { Paper, Typography, Grid } from "@mui/material";
import Bronze from "../../../../assets/images/Bronze.svg";
import Silver from "../../../../assets/images/Silver.svg";
import Gold from "../../../../assets/images/Gold.svg";
import Start from "../../../../assets/images/start.svg";
import { Certificate } from "../../../../components/Icons";
import { useStyles } from "../DashboardStyles";
import { useAuth } from "../../../../auth/useAuth";
import {
  getBadgeFromTotalEarnedPoints,
  getRemainingPointsForNextBadge,
} from "../../../../helpers/badgeFunctions";
import getDateFromTimestamp from "../../../../helpers/getDateFromTimestamp";
import Replacement from "../../../../assets/images/nocustomer.svg";

export default function AssignedCertification() {
  const classes = useStyles();
  const auth = useAuth();

  const data = auth.companyData.companyData.certData.items[0].publicProfile;

  const badgeImage = () => {
    if (JSON.parse(auth.companyData.companyData.invoice).status === "Trial") {
      return (
        <img
          src={Replacement}
          alt="No customer badge"
          className={classes.badge}
        ></img>
      );
    }

    if (getBadgeFromTotalEarnedPoints(data.totalPercentScore) === "bronze") {
      return (
        <img
          src={Bronze}
          alt="Certification badge"
          className={classes.badge}
        ></img>
      );
    } else if (
      getBadgeFromTotalEarnedPoints(data.totalPercentScore) === "silver"
    ) {
      return (
        <img
          src={Silver}
          alt="Certification badge"
          className={classes.badge}
        ></img>
      );
    } else if (
      getBadgeFromTotalEarnedPoints(data.totalPercentScore) === "gold"
    ) {
      return (
        <img
          src={Gold}
          alt="Certification badge"
          className={classes.badge}
        ></img>
      );
    } else {
      return (
        <img
          src={Start}
          alt="Certification badge"
          className={classes.badge}
        ></img>
      );
    }
  };

  return (
    <Paper>
      <Typography variant="h3">{Certificate}Valør</Typography>
      <Grid item xs={12} className={classes.badgeContent}>
        {badgeImage()}
      </Grid>
      <Typography variant="body2" className={classes.form}>
        Egenerklæring oppdatert:{" "}
        {data.updatedAt ? getDateFromTimestamp(data.updatedAt) : "Ikke startet"}
      </Typography>
      <Typography variant="body2" className={classes.form2}>
        Poeng oppnådd: {data.totalScore ? data.totalScore : "Ingen"}
      </Typography>
      <Typography variant="body2" className={classes.form}>
        Poeng gjenstående for neste nivå:{" "}
        {data.totalScore && data.totalPercentScore
          ? Math.round(
              getRemainingPointsForNextBadge(
                data.totalScore ? data.totalScore : 0,
                data.totalPercentScore ? data.totalPercentScore : 0
              )
            )
          : "Krever svar på flere spørsmål"}
      </Typography>
    </Paper>
  );
}
