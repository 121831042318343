import React from 'react';
import AWS from 'aws-sdk';
import { Grid, Avatar, Box, Typography, Button, Tooltip } from '@mui/material';
import { useStyles } from './DraftStyles';
import { deleteComment } from '../../choresFunctions';
import { s3Bucket, s3Region } from '../../../../../helpers/globalValues';

export default function FileComment(props) {
    const classes = useStyles();

    // Create a new AWS.S3 object with bucket and region params that will be used in all file-related functions
    const myBucket = new AWS.S3({
        params: { Bucket: s3Bucket },
        region: s3Region,
    });

    const handleDeleteComment = async () => {
        await deleteFile(JSON.parse(props.comment.data).filePath + JSON.parse(props.comment.data).fileName);

        await deleteComment(props.comment.id).then(() => {
            props.updateTask();
        });
    };

    const renderName = () => {
        let user = props.users.find(user => {
            return props.comment.userId === user.id;
        });

        let userName = '';

        if (user) {
            userName = JSON.parse(user.name).fullName;
        }

        return userName;
    };

    const renderShortenedName = () => {
        let user = props.users.find(user => {
            return props.comment.userId === user.id;
        });

        let userName = '';

        if (user) {
            userName = JSON.parse(user.name)
                .fullName.match(/\b\w/g)
                .join('');
        }

        return userName;
    };

    const downloadFile = async fileKey => {
        // Fetch saved credentials for authentication
        AWS.config.credentials.get(err => {
            if (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                    console.error(err);
                }

                return;
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                }
            }

            const params = {
                Bucket: s3Bucket,
                Key: fileKey,
            };

            // Fetches the file from backend
            myBucket.getObject(params, (err, data) => {
                if (err) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(err);
                    }
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(data);
                    }

                    // Fetch the filename after last '/' split
                    let parts = fileKey.split('/');
                    let fileName = parts[parts.length - 1];

                    // Create a data blob and download through frontend
                    let blob = new Blob([data.Body], { type: data.ContentType });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }
            });
        });
    };

    const deleteFile = async fileKey => {
        // Fetch saved credentials for authentication
        AWS.config.credentials.get(err => {
            if (err) {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                    console.error(err);
                }

                return;
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.log(AWS.config.credentials);
                }
            }

            const params = {
                Bucket: s3Bucket,
                Key: fileKey,
            };

            // Deletes the file from the backend
            myBucket.deleteObject(params, (err, data) => {
                if (err) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(err);
                    }
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(data);
                    }
                }
            });
        });
    };

    const renderCreatedDate = () => {
        let date = new Date(props.comment.createdAt);

        return (
            date.getDate() +
            '/' +
            (date.getMonth() + 1) +
            '/' +
            date.getFullYear() +
            ' ' +
            (date.getHours() < 10 ? '0' : '') +
            date.getHours() +
            ':' +
            (date.getMinutes() < 10 ? '0' : '') +
            date.getMinutes()
        );
    };

    return (
        <Grid item xs={12} className={classes.commentsGrid}>
            <Grid className={classes.singleComment}>
                <Avatar className={classes.avatar}>{renderShortenedName()}</Avatar>
                <Typography variant="body2" sx={{ fontSize: '13px', textAlign: 'center' }}>
                    {renderCreatedDate()}
                </Typography>
            </Grid>
            <Box className={classes.box}>
                <Typography variant="body1">{renderName()}</Typography>
                <Grid
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="body1" sx={{ fontSize: '14px' }}>
                        Lastet opp en fil:{' '}
                    </Typography>
                    <Tooltip title="Last ned" arrow>
                        <Button
                            sx={{ padding: '0px 8px' }}
                            onClick={() =>
                                downloadFile(
                                    JSON.parse(props.comment.data).filePath + JSON.parse(props.comment.data).fileName,
                                )
                            }
                        >
                            {JSON.parse(props.comment.data).fileName}
                        </Button>
                    </Tooltip>
                </Grid>
                <Button variant="text" className={classes.commentButton} onClick={handleDeleteComment}>
                    Slett
                </Button>
            </Box>
        </Grid>
    );
}
