import React from "react";
import MailIcon from "../assets/icons/mail.svg";
import MailIcon2 from "../assets/icons/mail2.svg";
import KeyIcon from "../assets/icons/key.svg";
import PhoneIcon from "../assets/icons/phone.svg";
import PhoneIcon2 from "../assets/icons/phone2.svg";
import UserIcon from "../assets/icons/user.svg";
import ArrowIcon from "../assets/icons/arrow.svg";
import ArrowRightIcon from "../assets/icons/arrowRight.svg";
import FirmIcon from "../assets/icons/firm.svg";
import HamburgerIcon from "../assets/icons/hamburger.svg";
import AlertIcon from "../assets/icons/alert.svg";
import HomeIcon from "../assets/icons/home.svg";
import ActivityIcon from "../assets/icons/activity.svg";
import ProfileIcon from "../assets/icons/profile.svg";
import AdminIcon from "../assets/icons/admin.svg";
import FacebookIcon from "../assets/icons/facebook.svg";
import InstagramIcon from "../assets/icons/instagram.svg";
import LinkedinIcon from "../assets/icons/linkedin.svg";
import UnFinishedIcon from "../assets/icons/square.svg";
import FinishedIcon from "../assets/icons/squarecheck.svg";
import DeleteIcon from "../assets/icons/delete.svg";
import CertifyIcon from "../assets/icons/certify.svg";
import SocialIcon from "../assets/icons/social.svg";
import EnvironmentIcon from "../assets/icons/environment.svg";
import ProfitIcon from "../assets/icons/profit.svg";
import ClimateIcon from "../assets/icons/climate.svg";
import BusinessIcon from "../assets/icons/business.svg";
import ProgressIcon from "../assets/icons/progression.svg";
import CertificateIcon from "../assets/icons/certification.svg";
import ModulesIcon from "../assets/icons/modules.svg";
import NextStepIcon from "../assets/icons/nextstep.svg";
import AddIcon from "../assets/icons/add.svg";
import GoIcon from "../assets/icons/go.svg";
import GridIcon from "../assets/icons/grid.svg";
import ListIcon from "../assets/icons/list.svg";
import DocumentsIcon from "../assets/icons/documents.svg";
import MediaIcon from "../assets/icons/mediakit.svg";
import SearchIcon from "../assets/icons/search.svg";
import CouponIcon from "../assets/icons/coupon.svg";
import IndustryIcon from "../assets/icons/industry.svg";
import TrophyIcon from "../assets/icons/trophy.svg";
import DownloadIcon from "../assets/icons/download.svg";
import ArrowDownIcon from "../assets/icons/arrowDown.svg";
import AttachIcon from "../assets/icons/attach.SVG";
import LinkIcon from "../assets/icons/link.SVG";
import Chores from "../assets/icons/chores.SVG";
import ActionIcon from "../assets/icons/actions.svg";
import GeneralIcon from "../assets/icons/general.svg";
import EditIcon from "../assets/icons/edit.SVG";
import SaveIcon from "../assets/icons/save.SVG";
import NSRSIcons from "../assets/icons/nsrs.svg";
import KeysIcon from "../assets/icons/key.svg";
import RiskIcon from "../assets/icons/risk.SVG";
import ArchiveIcon from "../assets/icons/archive.SVG";
import BellIcon from "../assets/icons/bell.SVG";
import Templates from "../assets/icons/templates.SVG";

//General icons
export const Search = (
  <img src={SearchIcon} alt="Arrow icon used for back button"></img>
);

export const Arrow = (
  <img src={ArrowIcon} alt="Arrow icon used for back button"></img>
);

export const ArrowRight = (
  <img
    src={ArrowRightIcon}
    style={{ marginLeft: "8px" }}
    alt="Arrow right icon"
  ></img>
);
export const ArrowDown = (
  <img
    src={ArrowDownIcon}
    alt="Arrow down icon"
    style={{ width: "20px" }}
  ></img>
);

export const Delete = (
  <img src={DeleteIcon} alt="Trashcan icon for delete"></img>
);

export const Download = (
  <img
    src={DownloadIcon}
    style={{ marginLeft: "8px" }}
    alt="Download icon for download"
  ></img>
);

export const Archived = (
  <img
    src={ArchiveIcon}
    alt="Archive notification"
    style={{ width: "22px" }}
  ></img>
);

// Header icons

export const FirmHeader = (
  <img
    src={FirmIcon}
    alt="Firm icon used for organisastion select"
    className="sidebarIcon"
  ></img>
);

export const UserHeader = (
  <img
    src={UserIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const Social = (
  <img
    src={SocialIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const Environment = (
  <img
    src={EnvironmentIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const Profit = (
  <img
    src={ProfitIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const Climate = (
  <img
    src={ClimateIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const Business = (
  <img
    src={BusinessIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const Industry = (
  <img
    src={IndustryIcon}
    alt="Factory icon used for Industry module"
    className="sidebarIcon"
  ></img>
);

export const Progression = (
  <img
    src={ProgressIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const Certificate = (
  <img
    src={CertificateIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const Module = (
  <img
    src={ModulesIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const NextSteps = (
  <img
    src={NextStepIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const TemplatesIcon = (
  <img
    src={Templates}
    alt="Layout icon used for templates nav"
    className="sidebarIcon"
  ></img>
);

//Login icons

export const Mail = (
  <img src={MailIcon} alt="Letter icon used for mail input"></img>
);

export const Key = (
  <img src={KeyIcon} alt="Key icon used for password input"></img>
);

export const User = (
  <img src={UserIcon} alt="User icon used for username input"></img>
);

export const Phone = (
  <img src={PhoneIcon} alt="Phone icon used for phonenumber input"></img>
);

export const Firm = (
  <img src={FirmIcon} alt="Firm icon used for organisastion select"></img>
);

export const Coupon = (
  <img src={CouponIcon} alt="Coupon icon used for discount input"></img>
);
//Sidebar Icons

export const Hamburger = (
  <img src={HamburgerIcon} alt="Hamburger icon used for sidebar toggle"></img>
);

export const Alert = (
  <img src={AlertIcon} alt="Bell icon used for notifications button"></img>
);

export const Bell = (
  <img
    src={BellIcon}
    alt="Bell icon used for notifications button"
    style={{ width: "25px", color: "#ffffff" }}
  ></img>
);

export const Home = (
  <img
    src={HomeIcon}
    alt="House icon used for home navigation"
    className="sidebarIcon"
  ></img>
);

export const Certify = (
  <img
    src={CertifyIcon}
    alt="Paper and pen icon used for certification navigation"
    className="sidebarIcon"
  ></img>
);

export const Activity = (
  <img
    src={ActivityIcon}
    alt="Activity icon used for Activity navigation"
    className="sidebarIcon"
  ></img>
);

export const Profile = (
  <img
    src={ProfileIcon}
    alt="Profile icon used for profile navigation"
    className="sidebarIcon"
  ></img>
);

export const Admin = (
  <img
    src={AdminIcon}
    alt="Admin icon used for admin navigation"
    className="sidebarIcon"
  ></img>
);

export const Document = (
  <img
    src={DocumentsIcon}
    alt="Document with download used for document navigation"
    className="sidebarIcon"
  ></img>
);

export const Media = (
  <img
    src={MediaIcon}
    alt="Document with download used for document navigation"
    className="sidebarIcon"
  ></img>
);

export const Trophy = (
  <img
    src={TrophyIcon}
    alt="Document with download used for document navigation"
    className="sidebarIcon"
  ></img>
);

//Social media icons

export const Facebook = <img src={FacebookIcon} alt="Facebook icon"></img>;

export const Instagram = <img src={InstagramIcon} alt="Instagram Icon"></img>;

export const Linkedin = <img src={LinkedinIcon} alt="LinkedIn icon"></img>;

//Certify Icons

export const UnFinished = (
  <img
    src={UnFinishedIcon}
    alt="User has not selected an answer"
    style={{ width: "23px" }}
  ></img>
);

export const Finished = (
  <img
    src={FinishedIcon}
    alt="User has selected an answer"
    style={{ width: "23px" }}
  ></img>
);

export const Social1 = (
  <img src={SocialIcon} alt="User icon used for username input"></img>
);

export const Environment1 = (
  <img src={EnvironmentIcon} alt="User icon used for username input"></img>
);

export const Profit1 = (
  <img src={ProfitIcon} alt="User icon used for username input"></img>
);

export const Climate1 = (
  <img src={ClimateIcon} alt="User icon used for username input"></img>
);

export const Business1 = (
  <img src={BusinessIcon} alt="User icon used for username input"></img>
);

export const Industry1 = (
  <img src={IndustryIcon} alt="User icon used for username input"></img>
);

//Profile icons

export const PhoneUser = (
  <img
    src={PhoneIcon2}
    style={{ paddingRight: "10px", position: "relative", top: "2px" }}
    alt="Arrow icon for back button"
  ></img>
);

export const MailUser = (
  <img
    src={MailIcon2}
    style={{ paddingRight: "10px", position: "relative", top: "2px" }}
    alt="Arrow icon for back button"
  ></img>
);

// Activities Icons

export const Add = (
  <img
    src={AddIcon}
    style={{ paddingRight: "10px" }}
    alt="Pluss icon for add elements"
  ></img>
);

export const Go = (
  <img src={GoIcon} alt="Arrow icon for next page button"></img>
);

export const GridViewIcon = <img src={GridIcon} alt="Grid icon"></img>;

export const ListViewIcon = <img src={ListIcon} alt="List icon"></img>;

//Climate icons

export const Attach = (
  <img src={AttachIcon} style={{ width: "26px" }} alt="Grid icon"></img>
);

export const Linking = (
  <img src={LinkIcon} style={{ width: "26px" }} alt="List icon"></img>
);

//Chores icons

export const ChoresIcon = (
  <img
    src={Chores}
    style={{ width: "22px" }}
    className="sidebarIcon"
    alt="List icon"
  ></img>
);

export const ChoresIcon2 = (
  <img src={Chores} style={{ width: "22px" }} alt="List icon"></img>
);

export const Actions = (
  <img src={ActionIcon} className="sidebarIcon" alt="List icon"></img>
);

export const Generals = (
  <img src={GeneralIcon} className="sidebarIcon" alt="List icon"></img>
);

export const Edit = (
  <img
    src={EditIcon}
    style={{ width: "22px", marginLeft: "10px" }}
    alt="List icon"
  ></img>
);

export const Save = (
  <img
    src={SaveIcon}
    style={{ width: "22px", marginLeft: "10px" }}
    alt="List icon"
  ></img>
);

//NSRS

export const NSRSIcon = (
  <img
    src={NSRSIcons}
    alt="Profile icon used for profile navigation"
    className="sidebarIcon"
    style={{ width: "22px" }}
  ></img>
);

export const Keys = (
  <img
    src={KeysIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
  ></img>
);

export const Risk = (
  <img
    src={RiskIcon}
    alt="User icon used for username input"
    className="sidebarIcon"
    style={{ width: "22px" }}
  ></img>
);
