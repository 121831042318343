import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Scope1Module from './Scope1Module';
import Scope2Module from './Scope2Module';
import Scope3Module from './Scope3Module';
import StartModule from './StartModule';
import useRouter from '../../../../hooks/useRouter';
import { ContentLoading } from '../../../../components/Loading';
import { fetchSingleClimateReport } from '../climateFunctions.js';
import SessionOverview from './SessionOverview';

export default function NewSession() {
    const router = useRouter();
    const { id } = router.query;
    const [report, setReport] = useState(null);

    useEffect(() => {
        let isMounted = false;

        if (!isMounted) {
            fetchReport();
        }

        return () => {
            isMounted = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchReport = async () => {
        if (router.location.state) {
            setReport(router.location.state.report);

            if (router.location.state.report.status === 'Completed') {
                router.push('/climate');
            }
        } else {
            fetchSingleClimateReport(id).then((data) => {
                if (data.status === 'Completed') {
                    router.push('/climate');
                }

                setReport(data);
            });
        }
    };

    if (!report) {
        return <ContentLoading />;
    } else {
        return (
            <Grid container direction="row" spacing={3}>
                <SessionOverview report={report} setReport={setReport} />
                <Grid item xs={6}>
                    <StartModule report={report} />
                </Grid>
                <Grid item xs={6}>
                    <Scope1Module report={report} />
                </Grid>
                <Grid item xs={6}>
                    <Scope2Module report={report} />
                </Grid>
                <Grid item xs={6}>
                    <Scope3Module report={report} />
                </Grid>
            </Grid>
        );
    }
}
